import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { ErrorBox } from '../../components/ErrorBox'
import { Step } from '../../components/PlentyUI/wizard/Step'
import { StepIndicator } from '../../components/PlentyUI/wizard/StepIndicator'
import { useWizard } from '../../hooks/useWizard'
import { CreateDiscountApplyDiscountStep } from './merchant/components/CreateDiscountApplyDiscountStep'
import { CreateDiscountCampaignStep } from './merchant/components/CreateDiscountCampaignStep'
import { CreateDiscountDiscountStep } from './merchant/components/CreateDiscountDiscountStep'
import { CreateDiscountProductsStep } from './merchant/components/CreateDiscountProductsStep'

const stepsToParse: Step[] = [
  {
    key: 'campaign',
    label: 'Campaign',
  },
  {
    key: 'discount',
    label: 'Discount',
  },
  {
    key: 'products',
    label: 'Products',
  },
  {
    key: 'applydiscount',
    label: 'Apply & confirm',
  },
]

type CreateDiscountModalProps = {
  isOpen: boolean,
  setOpen: (value: boolean) => void
}

export const CreateDiscountModal = (props: CreateDiscountModalProps) => {
  const wizard = useWizard(stepsToParse)

  const ActiveStepContent = () => {
    switch (wizard.activeStep.key) {
      case 'campaign':
        return <CreateDiscountCampaignStep wizard={wizard} />
      case 'products':
        return <CreateDiscountProductsStep wizard={wizard} />
      case 'discount':
        return <CreateDiscountDiscountStep wizard={wizard} />
      case 'applydiscount':
        return <CreateDiscountApplyDiscountStep wizard={wizard} setOpen={props.setOpen} />
      default:
        return <ErrorBox message={'No content for this step'} />
    }
  }

  return (
    <Modal size={'6xl'} isOpen={props.isOpen} onClose={() => props.setOpen(false)}>
      <ModalOverlay />
      <ModalContent p={0}>
        <ModalHeader position={'relative'} borderBottom={'1px'} borderBottomColor={'beigeLight.500'}>
          <Flex alignItems={'center'} justifyContent={'center'} direction={'column'} gap={3}>
            <Flex mb={3}>
              <StepIndicator wizard={wizard} />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <ActiveStepContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
