import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { ColorPlentyVariant } from '../types'
import { useState } from 'react'
import { PlentyProduct } from '../../PLP/types'

type ColorStyleSelectorProps = {
  product: PlentyProduct
  filteredVariants: ColorPlentyVariant[] | undefined
  selectedColor: ColorPlentyVariant | undefined
  handleColorChange: (option: ColorPlentyVariant, product: PlentyProduct) => void

}
export const ColorStyleSelector = ({ product, filteredVariants, selectedColor, handleColorChange }: ColorStyleSelectorProps) => {
  const [expanded, setExpanded] = useState(false)
  const handleExpand = () => setExpanded(true)
  const [maxVisibleVariants, setMaxVisibleVariants] = useState(4)

  return (
    <Flex width={'100%'} direction={'column'} gap={1}>
      <Flex>
        <Text mr={1} fontWeight={'normal'} fontSize={'xl'}>
          {t('main_app.pdp.color')}
        </Text>
        <Text fontWeight={'bold'} fontSize={'xl'}>
          {selectedColor?.color}
        </Text>
      </Flex>
      <Box>
        {!expanded ? (
          <Grid justifyItems={'center'} templateColumns='repeat(auto-fill, minmax(70px, 1fr))' gap={4}>
            {' '}
            {filteredVariants &&
              filteredVariants
                .slice(0, maxVisibleVariants)
                .map((productOption: ColorPlentyVariant, index: number) => (
                  <Image
                    key={productOption.variants[0].id}
                    onClick={() => handleColorChange(productOption, product)}
                    height={'110px'}
                    width={'80px'}
                    border={selectedColor?.color === productOption.color ? '2px solid #0B1C12' : 'none'}
                    borderRadius={'8px'}
                    src={productOption?.variants[0].images[0]?.url}
                  />
                ))}
            {filteredVariants && filteredVariants.length > maxVisibleVariants && (
              <Box
                height={'110px'}
                borderRadius={'16px'}
                border={'2px solid #0B1C12'}
                onClick={handleExpand}
                display={'flex'}
                width={'77px'}
                justifyContent={'center'}
                alignItems={'center'}
                fontSize={'xl'}
                cursor={'pointer'}
              >
                +{filteredVariants.length - maxVisibleVariants}
              </Box>
            )}
          </Grid>
        ) : (
          <Grid justifyItems={'center'} templateColumns='repeat(auto-fill, minmax(70px, 1fr))' gap={4}>
            {filteredVariants &&
              filteredVariants.map((productOption: ColorPlentyVariant, index: number) => (
                <Image
                  key={productOption.variants[0].id}
                  onClick={() => handleColorChange(productOption, product)}
                  height={'110px'}
                  border={selectedColor?.color === productOption.color ? '2px solid #0B1C12' : 'none'}
                  borderRadius={'1rem'}
                  src={productOption?.variants[0].images[0]?.url}
                />
              ))}
          </Grid>
        )}
      </Box>
    </Flex>
  )
}
