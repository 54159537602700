import { Flex, FormControl, FormErrorMessage, Input, Select, Text } from '@chakra-ui/react'
import { useRef } from 'react'
import { DiscountBundleType } from '../../../types/enums/DiscountBundleType'

type MiddleLabelInput = {
  bundleInputForm: any
}

export const MiddleLabelInput = (props: MiddleLabelInput) => {
  const triggerQuantityInput = useRef(null)

  const Label = () => {
    switch (props.bundleInputForm.values.unit) {
      case 'PCS':
        return <Text>get</Text>
      case 'KR':
        return <Text>for</Text>
      default:
        return <Text>unknown</Text>
    }
  }

  return (
    <Flex direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
      <Flex
        cursor={'default'}
        h={'40px'}
        pl={4}
        borderLeftRadius={'16px'}
        fontWeight={'bold'}
        alignItems={'center'}
        justifyContent={'center'}
        borderTop={'1px'}
        borderLeft={'1px'}
        borderBottom={'1px'}
        borderColor={!props.bundleInputForm.isValid ? 'red.400' : 'darkGreen.500'}
      >
        Buy
      </Flex>
      <FormControl isInvalid={!props.bundleInputForm.isValid} w={'75px'}>
        <Input
          name='triggerQuantity'
          id='triggerQuantity'
          ref={triggerQuantityInput}
          value={props.bundleInputForm.values.triggerQuantity}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.bundleInputForm.handleChange(e)}
          type={'number'}
          variant={'simple'}
          borderRadius={0}
          borderRight={0}
          borderLeft={0}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              props.bundleInputForm.handleSubmit()
            }
          }}
          _invalid={{
            borderTop: '1px',
            borderBottom: '1px',
            borderColor: 'red.400',
          }}
        />
        <FormErrorMessage>{props.bundleInputForm.errors.triggerQuantity}</FormErrorMessage>
      </FormControl>
      <Flex
        cursor={'default'}
        h={'40px'}
        fontWeight={'bold'}
        alignItems={'center'}
        justifyContent={'center'}
        borderTop={'1px'}
        borderBottom={'1px'}
        borderColor={!props.bundleInputForm.isValid ? 'red.400' : 'darkGreen.500'}
      >
        <Label />
      </Flex>
      <FormControl isInvalid={!props.bundleInputForm.isValid} w={'75px'}>
        <Input
          name={'discountedQuantity'}
          value={props.bundleInputForm.values.discountedQuantity}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.bundleInputForm.handleChange(e)}
          type={'number'}
          w={'75px'}
          variant={'simple'}
          borderRadius={0}
          borderLeft={0}
          borderRight={0}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              props.bundleInputForm.handleSubmit()

              const currentTriggerQuantityInput: any = triggerQuantityInput.current

              if (currentTriggerQuantityInput != null) {
                currentTriggerQuantityInput.focus()
              }
            }
          }}
          _invalid={{
            borderTop: '1px',
            borderBottom: '1px',
            borderColor: 'red.400',
          }}
        />
        <FormErrorMessage>{props.bundleInputForm.errors.discountedQuantity}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!props.bundleInputForm.isValid} w={'60px'}>
        <Select
          name='unit'
          onChange={(e: any) => props.bundleInputForm.handleChange(e)}
          variant={'simple'}
          value={props.bundleInputForm.values.unit}
          borderLeft={0}
          borderLeftRadius={0}
          _invalid={{
            borderRight: '1px',
            borderTop: '1px',
            borderBottom: '1px',
            borderColor: 'red.400',
          }}
        >
          {Object.keys(DiscountBundleType)
            .filter((key) => isNaN(Number(key)))
            .map((value: any) => {
              return (
                <option key={value} value={value}>
                  {value.toLowerCase()}
                </option>
              )
            })}
        </Select>
        <FormErrorMessage>{props.bundleInputForm.errors.unit}</FormErrorMessage>
      </FormControl>
    </Flex>
  )
}
