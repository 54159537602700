import { Flex, HStack, StackDivider, Text } from '@chakra-ui/react'
import { useDiscountCreation } from '../../../context/DiscountCreationContext'
import { TableEntry } from '../../../types/TableEntry'
import { Step } from './Step'
import { StepCircle } from './StepCircle'

type StepIndicatorProps = {
  wizard: any
}

export const StepIndicator = (props: StepIndicatorProps) => {
  const discountCreation = useDiscountCreation()

  return (
    <HStack spacing={10} divider={<StackDivider borderColor={'beigeLight.500'} />}>
      {props.wizard.steps.map((value: Step, index: number) => {
        const checkIfIsCompleted = () => {
          const campaignValidation = discountCreation.campaignName != ''
          const discountValidation = discountCreation.discountTools.length > 0
          const productsValidation = discountCreation.tableEntries.length > 0
          const applyDiscountValidation = discountCreation.tableEntries.length > 0 && discountCreation.tableEntries.filter((te: TableEntry) => te.discount !== undefined).length >= discountCreation.tableEntries.length

          switch (value.key) {
            case 'campaign':
              return campaignValidation
            case 'discount':
              return campaignValidation && discountValidation
            case 'products':
              return campaignValidation && discountValidation && productsValidation
            case 'applydiscount':
              return campaignValidation && discountValidation && productsValidation && applyDiscountValidation
            default:
              return false
          }
        }
        
        const isActive = props.wizard.activeStep.key === value.key
        const isClickable = checkIfIsCompleted()

        return (
          <Flex
            key={value.key}
            p={1}
            onClick={() => {
              if (isClickable) {
                const indexToSet = props.wizard.steps.findIndex((step: Step) => step.key == value.key)
                props.wizard.setActiveStepIndex(indexToSet)
              }
            }}
            cursor={isClickable ? 'pointer' : 'default'}
            borderRadius={10}
            _hover={{ bgColor: isClickable ? 'beigeLight.300' : 'transparent' }}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            <StepCircle number={index + 1} isActive={isActive} isCompleted={isClickable} />
            <Text fontSize={'md'} fontWeight={'normal'} color={isActive ? 'darkGreen.500' : 'gray.500'}>
              {value.label}
            </Text>
          </Flex>
        )
      })}
    </HStack>
  )
}
