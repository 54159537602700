import { Flex, Heading, Icon, Stack, StackDivider, Text } from '@chakra-ui/react'

import { IconArrowLeft } from '@tabler/icons-react'
import { useCart } from '../../../context/CheckoutCartContext'
import { centPriceToPrice, moneyFormat } from '../../../utils/MoneyUtil'
import { CartItemContainer } from '../components/CartItemContainer'
import { StateItem } from '../components/StateItem'
import { CheckoutState } from '../enum/CheckoutState'
import { CheckoutDelivery } from './components/CheckoutDelivery'
import { CheckoutDeliverySummary } from './components/CheckoutDeliverySummary'
import { CheckoutPayment } from './components/CheckoutPayment'

export const CheckoutStep = () => {
  const { checkoutEditStep, setCheckoutEditStep, setStep, cart, calculateTotalLineItems } = useCart()

  return (
    <Flex direction={'column'} gap={'1.5rem'}>
      <Flex gap={'0.5rem'} direction={'column'}>
        <Flex gap={'1rem'} alignItems={'center'}>
          <Icon onClick={() => setStep(0)} _hover={{ cursor: 'pointer' }} h={6} w={6} as={IconArrowLeft} />
          <Heading fontWeight={'normal'} fontSize={'2xl'}>
            Check out
          </Heading>
        </Flex>
        <Text fontSize={'sm'}>
          {calculateTotalLineItems()} Items ·{' '}
          {moneyFormat(
            centPriceToPrice(cart.totalPrice?.centAmount ?? 0, cart.totalPrice?.fractionDigits ?? 0),
            cart.totalPrice?.currencyCode ?? 'DKK',
            'da-DK',
          )}
        </Text>
      </Flex>
      <Stack
        spacing={'0.25rem'}
        divider={<StackDivider margin={'0!important'} borderColor={'beigeDark.500'} />}
        borderTop={'1px'}
        borderBottom={'1px'}
        borderColor={'beigeDark.500'}
      >
        <StateItem heading={'In your bag'} state={CheckoutState.TOGGLEABLE} checkoutEditStep={'Delivery'} setCheckoutEditStep={setCheckoutEditStep}>
          <CartItemContainer cart={cart} hideControls />
        </StateItem>
        <StateItem
          heading={'Delivery options'}
          state={checkoutEditStep == 'Delivery' ? CheckoutState.ACTIVE : CheckoutState.DONE}
          checkoutEditStep={'Delivery'}
          setCheckoutEditStep={setCheckoutEditStep}
          summary={<CheckoutDeliverySummary />}
        >
          <CheckoutDelivery />
        </StateItem>
        <StateItem
          heading={'Payment'}
          state={checkoutEditStep == 'Payment' ? CheckoutState.ACTIVE : checkoutEditStep == 'Delivery' ? CheckoutState.TO_COME : CheckoutState.DONE}
          checkoutEditStep={'Payment'}
          setCheckoutEditStep={setCheckoutEditStep}
        >
          <CheckoutPayment />
        </StateItem>
      </Stack>
    </Flex>
  )
}
