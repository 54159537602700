import { Price, ProductVariant } from '@commercetools/platform-sdk'
import { GRAPHQL_MAX_INT } from 'graphql'
import { ColorPlentyVariant, PlentyStore } from '../pages/main_app/PDP/types'
import { PlentyProduct, PlentyVariant, ProductVariantPrice } from '../pages/main_app/PLP/types'
import { centPriceToPrice, moneyFormat } from './MoneyUtil'
import { findProductVariantAttributeDefaultEnglish } from './ProductUtil'

export const getLowestCentAmountFromPrice = (price: ProductVariantPrice | null) => {
  if (price) {
    return price.discounted ? price.discounted.value.centAmount : price.value.centAmount
  } else {
    return GRAPHQL_MAX_INT
  }
}

export const getCheapestProductPrice = (product: PlentyProduct, channelId?: string): ProductVariantPrice => {
  let cheapestPrice = product.masterVariant.prices[0]

  const variantToUse = product.masterVariant

  variantToUse.prices.forEach((price: ProductVariantPrice) => {
    if (channelId && price.channel) {
      if (channelId == price.channel.id) {
        if (getLowestCentAmountFromPrice(price) < getLowestCentAmountFromPrice(cheapestPrice)) {
          cheapestPrice = price
        }
      }
    } else if (getLowestCentAmountFromPrice(price) < getLowestCentAmountFromPrice(cheapestPrice)) {
      cheapestPrice = price
    }
  })

  return cheapestPrice
}

export const getCheapestVariant = (variants: PlentyVariant[], channelId?: string): PlentyVariant => {
  let cheapestVariant = variants[0]

  variants.forEach((variant: PlentyVariant) => {
    const currentCheapestPrice = getCheapestPriceFromVariant(cheapestVariant, channelId)
    const contestingCheapestPrice = getCheapestPriceFromVariant(variant, channelId)

    const currentCheapestPriceCentValue = getLowestCentAmountFromPrice(currentCheapestPrice)
    const contestingCheapestPriceCentValue = getLowestCentAmountFromPrice(contestingCheapestPrice)
    if (currentCheapestPriceCentValue > contestingCheapestPriceCentValue) {
      cheapestVariant = variant
    }
  })

  return cheapestVariant
}

export const getPriceByChannel = (variant: PlentyVariant, channelId: string): ProductVariantPrice | null => {
  return (
    variant.prices.find((price: ProductVariantPrice) => {
      if (!price.channel) {
        return false
      }

      return price.channel.id == channelId
    }) ?? null
  )
}

export const getCheapestPriceFromVariant = (variant: PlentyVariant, channelId?: string) => {
  if (channelId) {
    const priceByChannel = getPriceByChannel(variant, channelId)
    return priceByChannel
  } else {
    return variant.prices.reduce((min: ProductVariantPrice, current: ProductVariantPrice) => {
      if (!current.channel) {
        return min
      } else {
        const currentPrice = getLowestCentAmountFromPrice(current)
        const minPrice = getLowestCentAmountFromPrice(min)

        if (!min.channel && current.channel) {
          return current
        }

        return currentPrice <= minPrice ? current : min
      }
    }, variant.prices[0])
  }
}

export const getCheapestPriceFromVariantByChannel = (variant: PlentyVariant, channelId: string) => {
  const pricesWithChannel = variant.prices.filter((price: ProductVariantPrice) => price.channel)

  const filteredPrices = pricesWithChannel.filter((price: ProductVariantPrice) => {
    return price.channel.id == channelId
  })

  return filteredPrices.reduce((min: ProductVariantPrice, current: ProductVariantPrice) => {
    if (!current.channel) {
      return min
    } else {
      const currentPrice = getLowestCentAmountFromPrice(current)
      const minPrice = getLowestCentAmountFromPrice(min)

      if (!min.channel && current.channel) {
        return current
      }

      return currentPrice <= minPrice ? current : min
    }
  }, variant.prices[0])
}

export const getCheapestStoreByVariant = (variant: PlentyVariant, stores: PlentyStore[]): PlentyStore => {
  const cheapestPrice = getCheapestPriceFromVariant(variant)

  const storeToReturn = cheapestPrice?.channel ? stores.find((store: PlentyStore) => store.channelId == cheapestPrice.channel.id) : stores[0]

  if (!storeToReturn) {
    return stores[0]
  } else {
    return storeToReturn
  }
}

export const getCheapestPriceNotFromChannel = (variant: PlentyVariant, channelId: string) => {
  const prices = variant.prices.filter((price: ProductVariantPrice) => {
    if (!price?.channel?.obj) {
      return false
    }

    return price.channel.obj.id !== channelId
  })

  if (prices.length === 0) {
    return null
  }

  prices.sort((a, b) => a.value.centAmount - b.value.centAmount)

  return prices[0]
}

export const getCheapestPriceNotFromChannelFormatted = (variant: PlentyVariant, channelId: string) => {
  const prices = variant.prices.filter((price: ProductVariantPrice) => {
    if (!price?.channel?.obj) {
      return false
    }

    return price.channel.obj.id !== channelId
  })

  if (prices.length === 0) {
    return null
  }

  prices.sort((a, b) => a.value.centAmount - b.value.centAmount)

  return moneyFormat(centPriceToPrice(getLowestCentAmountFromPrice(prices[0]), prices[0].value.fractionDigits), prices[0].value.currencyCode, 'da-DK')
}

export const getAvailableInOtherStoresAmount = (stores: PlentyStore[], variant: PlentyVariant) => {
  let amount = 0

  stores.forEach((store: PlentyStore) => {
    if (getPriceByChannel(variant, store.channelId)) {
      amount++
    }
  })

  return amount - 1
}

export const permyriadToPercent = (permyriad: number) => {
  return permyriad / 100
}

export const getFirstAvailbleOptionByStore = (store: PlentyStore, variants: PlentyVariant[]): PlentyVariant => {
  return variants.find((variant: PlentyVariant) => variant.availability.channels![store.channelId]) ?? variants[0]
}

export const storeHasOptionInStock = (store: PlentyStore, variant: PlentyVariant) => {
  return variant.availability.channels![store.channelId] != undefined
}

export const getStoreByStoreKey = (stores: PlentyStore[], storeKey: string): PlentyStore | undefined => {
  return stores.find((store: PlentyStore) => store.key == storeKey)
}

export const allSizesAvailableAcrossStoresByColor = (product: PlentyProduct, selectedColor: ColorPlentyVariant): PlentyVariant[] => {
  return product.variants.filter((variant) => {
    return (
      variant.attributes.find((attr) => attr.name === 'size') &&
      variant.availability &&
      selectedColor.color == findProductVariantAttributeDefaultEnglish(variant, 'color')
    )
  })
}

export const findVariantWithPriceByChannelId = (variants: ProductVariant[], channelId: string) => {
  const variant = variants.find((variant: ProductVariant) => {
    const price = variant.prices?.find((price: Price) => price.channel?.id == channelId)
    return price
  })

  if (!variant) {
    return null
  }

  return variant.prices?.find((price: Price) => price.channel?.id == channelId)
}

export const findVariantWithDiscountedPriceByChannelId = (variants: ProductVariant[], channelId: string) => {
  const variant = variants.find((variant: ProductVariant) => {
    const price = variant.prices?.find((price: Price) => price.channel?.id == channelId && price.discounted)
    return price
  })

  if (!variant) {
    return null
  }

  return variant.prices?.find((price: Price) => price.channel?.id == channelId)
}
