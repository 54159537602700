/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Icon, ListItem, Text, UnorderedList, useDisclosure } from '@chakra-ui/react'
import { t } from 'i18next'
import { useState } from 'react'
import { TbBox, TbMapPin } from 'react-icons/tb'
import { Theme } from '../../../../ThemeVariables'
import { ReturnParcelShopPicker } from './ReturnParcelShopPicker'

export const PostalService = () => {
  const { onOpen } = useDisclosure()

  return (
    <>
      <Flex
        backgroundColor={'white'}
        borderRadius={Theme.spacing.borderRadius}
        p={Theme.spacing.padding.mobile}
        direction={'column'}
        w={'full'}
        boxShadow={'lg'}
        gap={4}
      >
        {' '}
        <Flex direction={'column'}>
          <Flex justifyContent={'space-between'}>
            <Flex alignItems={'center'}>
              <Icon fontSize={Theme.iconSize.xl} as={TbBox}></Icon>
              <Text ml={4} fontWeight={600}>
                {t('main_app.return.text_items_back')}
              </Text>
            </Flex>
            <Text>{t('main_app.return.text_price_shipping.0')}</Text>
          </Flex>
          <UnorderedList ml={16}>
            <ListItem>{t('main_app.return.list_drop_off_information.0')}</ListItem>
            <ListItem>{t('main_app.return.list_drop_off_information.1')}</ListItem>
            <ListItem>{t('main_app.return.list_drop_off_information.2')}</ListItem>
          </UnorderedList>
        </Flex>
        <Flex direction={'column'} onClick={onOpen}>
          <Flex alignItems={'center'}>
            <Icon fontSize={Theme.iconSize.xl} as={TbMapPin}></Icon>
            <Text p={4} fontWeight={600}>
              {t('main_app.return.text_find_postal')}
            </Text>
          </Flex>
          <Text ml={10}>{t('main_app.return.text_possible_postal_ways')}</Text>
        </Flex>
        <Flex>
          <ReturnParcelShopPicker />
        </Flex>
      </Flex>
    </>
  )
}
