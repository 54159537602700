import { useApolloClient } from '@apollo/client'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Toast } from '../../../../components/PlentyUI/Toast'
import { CampaignDiscount } from '../../../../generated/graphql'
import { CHANGE_NAME_OF_CAMPAIGN_DISCOUNT, GET_CAMPAIGN_DISCOUNTS } from '../../../../gql/discount'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'

type RenameCampaignModalProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => any
  campaignDiscount: CampaignDiscount
}

const renameFormSchema = Yup.object().shape({
  newName: Yup.string().required('A new name is required')
})

export const RenameCampaignModal = (props: RenameCampaignModalProps) => {
  const client = useApolloClient()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Formik
      validationSchema={renameFormSchema}
      initialValues={{
        newName: props.campaignDiscount.name,
      }}
      onSubmit={(values: any) => {
        setIsLoading(true)

        client
          .mutate({
            mutation: CHANGE_NAME_OF_CAMPAIGN_DISCOUNT,
            variables: {
              campaignDiscountId: props.campaignDiscount.id,
              newName: values.newName,
            },
          })
          .then((response: any) => {
            client.refetchQueries({ include: [GET_CAMPAIGN_DISCOUNTS] })
            toast({
              position: 'top',
              render: () => <Toast type='success' label={'The campaign was renamed successfully'} />,
            })
            setIsLoading(false)
            props.setIsOpen(false)
          })
          .catch((error: any) => {
            toast({
              position: 'top',
              render: () => <Toast type='error' label={error.message} />,
            })
            setIsLoading(false)
          })
      }}
    >
      {({ errors, touched, handleSubmit }) => (
        <Modal variant={'dialog'} isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Rename campaign</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={errors.newName != undefined && touched.newName}>
                <Field as={Input} id='newName' name='newName' placeholder={'Pick a new name...'} variant={'roundedOutlineWhite'} />
                {errors.newName && touched.newName ? <FormErrorMessage>{errors.newName}</FormErrorMessage> : null}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Flex gap={2}>
                <Button isLoading={isLoading} variant='outlineWhite' onClick={() => props.setIsOpen(false)}>
                  Cancel
                </Button>
                <Button isLoading={isLoading} onClick={() => handleSubmit()}>
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  )
}
