import { Flex, Icon } from '@chakra-ui/react'
import { IconType } from 'react-icons/lib'
import { Link, NavLink, useLocation } from 'react-router-dom'

type MenuItemProps = {
  label: string
  route: string
  icon: IconType
}

export const MenuItem = (props: MenuItemProps) => {
  const location = useLocation()

  const isActivePath = location.pathname == props.route

  return (
    <Flex m={2} bgColor={isActivePath ? 'darkGreen.500' : 'white.500'} color={isActivePath ? 'mintGreen.500' : 'black.500'} py={2} borderRadius={8} justifyContent={'center'} alignItems={'center'}>
      <Flex w={'75px'}>
        <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
          <Icon mr={2} ml={-2} w={5} h={5} as={props.icon} />
        </Flex>
        <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
          <NavLink to={props.route}>{props.label}</NavLink>
        </Flex>
      </Flex>
    </Flex>
  )
}
