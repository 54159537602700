import { Flex, Icon, Text } from "@chakra-ui/react"
import { IconX } from "@tabler/icons-react"

type CloseTagProps = {
    label: string
    closeAction: () => any
}

export const CloseTag = (props: CloseTagProps) => {
    return(
        <Flex gap={2} w={'fit-content'} justifyContent={'space-between'} alignItems={'center'} bgColor={'darkGreen.500'} color={'white.500'} py={2} px={4} borderRadius={'full'}>
            <Text>{props.label}</Text>
            <Icon onClick={() => props.closeAction()} cursor={'pointer'} as={IconX} w={5} h={5} />
        </Flex>
    )
}