import { FileSelector } from "../../../../../../components/fileManagement/FileSelector"
import { Button, Flex, ModalFooter, Textarea } from "@chakra-ui/react"
import axios from 'axios';
import { Dispatch, SetStateAction, useState } from "react";
import { CSVReadFile, CSVReadLine, parseCsvToArray, validateCSVContent } from "../../../../../../utils/CSVUtil";
import { useDiscountCreation } from "../../../../../../context/DiscountCreationContext";
import { UploadStatusTernary } from "../../../../../../utils/enums/UploadStatusTernary";
import { split } from "lodash";
import { SummaryBar } from "../../SummaryBar";
import { useApolloClient } from "@apollo/client";
import { ProductSearchResult, VariantSearchResult } from "../../../../../../generated/graphql";
import { PercentageDiscountTool } from "../../../../../../types/PercentageDiscountTool";
import { GET_PRODUCTS_BY_KEYS } from "../../../../../../gql/product";

export const CreateBulkDiscountUploadStep = (props: { 
                                                wizard: any,
                                                onSelect: (file: File) => void,
                                                file: File | null ,
                                                setUploadState: Dispatch<SetStateAction<UploadStatusTernary>>,
                                                setResponseError:  Dispatch<SetStateAction<string>>,
                                                setCampaignId: Dispatch<SetStateAction<string>>
                                            }) => {
    const discountCreation = useDiscountCreation()
    const client = useApolloClient()
    
    const [fileError, setFileError] = useState<string>("")

    const populateDiscountTable = async (file: File) => {
        if (file != null) {
            let tuple: string[] = []
            const tupleList: string[][] = []
            const csvFile = await CSVReadFile(file)
            const rows = parseCsvToArray(csvFile)
            rows.forEach((row, index) => {
                if (index > 0) {
                    if (tuple.length > 0) tuple = []
                    const line = CSVReadLine(row)
                    tuple.push(line[1]) // Product key
                    tuple.push(line[5]) // Store key
                    tupleList[index-1] = tuple
                }
            })

            client
                .query({
                    query: GET_PRODUCTS_BY_KEYS,
                    variables: {
                        productTuple: tupleList
                    }
                })
                .then((result: any) => {
                    const fetchedProducts = result.data.getProductsByKeys as ProductSearchResult[]
                    fetchedProducts.forEach((product) => {
                        const productDiscount: PercentageDiscountTool = { value: 0 }
                        rows.forEach((row) => {
                            const line = CSVReadLine(row)
                            if (+line[2] > 0 && line[1] == product.productKey) {
                                productDiscount.value = +line[2]
                            }
                        })

                        discountCreation.addTableEntry({
                            name: product.name,
                            imageUrl: product.imageUrl,
                            productEntry: {
                                productKey: product.productKey,
                                category: product.category,
                                price: product.price,
                                styleNumber: product.styleNumber,
                                discount: productDiscount,
                                variantEntries: product.variants.map((variant: VariantSearchResult) => {
                                    return {
                                        ean: variant.ean,
                                        imageUrl: variant.imageUrl,
                                        name: variant.name,
                                        isIncluded: true,
                                        price: variant.price,
                                        size: variant.size
                                    }
                                })
                            }
                        })
                    })
                })
        }
    }

    const uploadBulkFile = async (file: File) => {
        props.setUploadState(UploadStatusTernary.Uploading)
        props.setResponseError("")
        if(file) {
            try {
                const formData = new FormData()
                formData.append('file', file)
                formData.append('campaignName', discountCreation.campaignName)
               
                formData.append('validFrom', discountCreation.validFrom.toLocaleString("da-DK",{"day":"2-digit","month":"2-digit","year":"numeric","hour":"numeric","minute":"numeric","second":"numeric"}))
                if (discountCreation.validTo) formData.append('validTo', discountCreation.validTo.toLocaleString("da-DK",{"day":"2-digit","month":"2-digit","year":"numeric","hour":"numeric","minute":"numeric","second":"numeric"}))
                const response = await axios.post(process.env.REACT_APP_PRODUCTS_API_URL + "/discount/upload", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if (response.status == 200) {
                    const newVal = split(response.data, " ")
                    props.setCampaignId(newVal[newVal.length - 1])
                    props.setUploadState(UploadStatusTernary.Uploaded)
                    await populateDiscountTable(file)
                }
            } catch (error: any) {
                console.error('Could not upload file:', error)
                props.setUploadState(UploadStatusTernary.Error)
                props.setResponseError(error)
            }
        } else {
            console.error('File is null')
            props.setUploadState(UploadStatusTernary.Error)
        }
      }

    const handleFileSelect = async (file: File) => {
        setFileError("")
        const fileIsValid = await validateCSVContent(file, setFileError)
        if (fileIsValid.errorState) {
            props.onSelect(file)
            uploadBulkFile(file)
            props.wizard.advanceStep()
        } else {
            console.error("File is formatted incorrectly")
        }
    }

    return (
        <Flex direction={'column'} position={'relative'}>
            <SummaryBar
            info={[
                discountCreation.campaignName,
                discountCreation.validFrom.toDateString() + ' to ' + (discountCreation.validTo ? discountCreation.validTo.toDateString() : 'continuous'),
            ]}
            />
            {fileError.length > 0 ? <Textarea defaultValue={fileError} textColor="red" isInvalid isDisabled={true}/> : <div></div>}
            <ModalFooter borderTop={'1px'} borderTopColor={'beigeLight.500'}>
                <Button
                    isDisabled={!props.wizard.canReduce}
                    colorScheme='blue' mr={3}
                    onClick={() => props.wizard.reduceStep()}>
                    Previous
                </Button>
                <FileSelector wizard={props.wizard} onSelect={(file: File) => handleFileSelect(file)} file={props.file} />
            </ModalFooter>
        </Flex>
    )
}