import { Button, Flex, Icon } from '@chakra-ui/react'
import { t } from 'i18next'
import { useState } from 'react'
import { TbMapPin, TbTruckDelivery } from 'react-icons/tb'
import { useCart } from '../../../../context/CheckoutCartContext'
import { DeliveryOptions } from '../../../DeliveryOptions/DeliveryOptions'
import { InfoFormDelivery } from './InfoFormDelivery'
import { InfoFormPickup } from './InfoFormPickup'

export const CheckoutDelivery = () => {
  const { cartDeliveryOption, setCartDeliveryOption } = useCart()
  const [deliveryOption, setDeliveryOption] = useState<'Pickup' | 'Delivery'>(
    cartDeliveryOption == 'ParcelShop' || cartDeliveryOption == 'HomeDelivery' ? 'Delivery' : 'Pickup',
  )

  return (
    <Flex flex={1} direction={'column'} gap={'1.5rem'}>
      <Flex gap={'1rem'}>
        <Button
          onClick={() => {
            setDeliveryOption('Pickup')
            setCartDeliveryOption('Pickup')
          }}
          borderRadius={8}
          w={'full'}
          variant={deliveryOption == 'Pickup' ? 'solidGreen' : 'solidWhite'}
          fontWeight={'normal'}
          leftIcon={<Icon w={6} h={6} as={TbMapPin} />}
        >
          Click & Collect
        </Button>
        <Button
          onClick={() => {
            setDeliveryOption('Delivery')
            setCartDeliveryOption('HomeDelivery')
          }}
          borderRadius={8}
          w={'full'}
          variant={deliveryOption == 'Delivery' ? 'solidGreen' : 'solidWhite'}
          fontWeight={'normal'}
          leftIcon={<Icon w={6} h={6} as={TbTruckDelivery} />}
        >
          {t('components.button.delivery')}
        </Button>
      </Flex>
      <DeliveryOptions deliveryOption={deliveryOption} cartDeliveryOption={cartDeliveryOption} setCartDeliveryOption={setCartDeliveryOption} />
      <Flex>{cartDeliveryOption !== 'Pickup' ? <InfoFormDelivery /> : <InfoFormPickup />}</Flex>
    </Flex>
  )
}
