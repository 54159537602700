import { Cart, LineItem, Maybe, RetailPrice } from '../generated/graphql'
import { PlentyProduct, PlentyVariant, ProductVariantPrice } from '../pages/main_app/PLP/types'

export const centPriceToPrice = (centAmount: number, digits: number) => {
  const divideValue = Math.pow(10, digits ?? 0)
  return (centAmount ?? 0) / divideValue
}

export const PriceToCentPrice = (centAmount: number, digits: number) => {
  return centAmount * Math.pow(10, digits ?? 0)
}

export const getRetailPriceByCurrency = (retailPrices: Maybe<RetailPrice>[], currency: string) => {
  if (retailPrices) {
    return (
      retailPrices.filter((e) => e?.currency === currency)[0]?.recommendedRetailPrice + ' ' + retailPrices.filter((e) => e?.currency === currency)[0]?.currency
    )
  } else {
    return 'No price'
  }
}

export const getRetailPrice = (retailPrices: Maybe<RetailPrice>[], currency: string) => {
  if (retailPrices && retailPrices.length > 0) {
    const price = retailPrices.find((e) => e?.currency === currency)?.recommendedRetailPrice
    const curr = retailPrices.find((e) => e?.currency === currency)?.currency

    if (price && curr) {
      return `${price} ${curr}`
    } else {
      return `No price in ${currency}` // Indicate that there's no price in the specified currency
    }
  } else {
    return 'No price'
  }
}

export const getRetailPriceAsValue = (retailPrices: Maybe<RetailPrice>[], currency: string): number => {
  if (retailPrices) {
    const price = retailPrices.filter((e) => e?.currency === currency)[0]?.recommendedRetailPrice
    const curr = retailPrices.filter((e) => e?.currency === currency)[0]?.currency
    if (price && curr) return price
    return -1
  } else {
    return -1
  }
}

export const moneyFormat = (value: number, currency: string, language: string) => {
  const formatted = new Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(value)
  return formatted.endsWith('.') ? formatted.slice(0, -1) : formatted
}

export const getLowestCentAmountFromPrice = (price: ProductVariantPrice) => {
  return price.discounted ? price.discounted.value.centAmount : price.value.centAmount
}

export const getCheapestPrice = (product: PlentyProduct): ProductVariantPrice => {
  const variants = [...product.variants, product.masterVariant]

  let cheapestPrice = variants[0].prices[0]

  variants.forEach((variant: PlentyVariant) => {
    variant.prices.forEach((price: ProductVariantPrice) => {
      if (getLowestCentAmountFromPrice(price) < getLowestCentAmountFromPrice(cheapestPrice)) {
        cheapestPrice = price
      }
    })
  })

  return cheapestPrice
}

export const permyriadToPercent = (permyriad: number) => {
  return permyriad / 100
}

export const calculateTotalOriginalPriceAmount = (cart: Cart) => {
  let totalAmount = 0

  cart.lineItems.forEach((lineItem: Maybe<LineItem>) => {
    if (lineItem?.price) {
      const originalPrice = lineItem.price.value.centAmount * (lineItem?.quantity ?? 0)
      totalAmount += originalPrice
    }
  })

  return totalAmount
}