import { Flex } from '@chakra-ui/react'
import { AdvancedMarker, APIProvider, Map as GMap } from '@vis.gl/react-google-maps'

export type MapMarker = {
  id: string
  lat: number
  lng: number
} | null

type MapProps = {
  markers: MapMarker[]
  zoom: number
  centerAt: { lat: number; lng: number } | null
  highLightedMarkers: string[]
}

export const Map = ({ markers, zoom, centerAt, highLightedMarkers }: MapProps) => {
  return (
    <Flex width={'full'} height={'full'}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY as string}>
        <GMap zoom={zoom} center={centerAt} mapId={'primaryMap'}>
          {markers.map((value: MapMarker, index: number) => {
            if (highLightedMarkers.length > 0) {
              if (highLightedMarkers.includes(value?.id || '')) {
                return (
                  <AdvancedMarker key={index} position={{ lat: value?.lat, lng: value?.lng }} />
                )
              }
            } else {
              return (
                <AdvancedMarker key={index} position={{ lat: value?.lat, lng: value?.lng }} />
              )
            }
          })}
        </GMap>
      </APIProvider>
    </Flex>
  )
}
