import { Divider, Flex, Icon, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { ExclamationMark } from 'tabler-icons-react'
import { useReturn } from '../../../../context/OrderReturnContext'
import { centPriceToPrice } from '../../../../utils/MoneyUtil'
import { Theme } from '../../../../ThemeVariables'

export const ReturnOverviewByParcel = () => {
  const { returnItemListWithQuantity } = useReturn()

  const calculateSubTotal = () => {
    let subtotal = 0
    returnItemListWithQuantity.forEach((item) => {
      subtotal += item.orderLineItem.price * item.quantity
    })
    return subtotal
  }

  const calculateTotalMinusShipping = () => {
    let total = 0
    returnItemListWithQuantity.forEach((item) => {
      total += item.orderLineItem.price * item.quantity
    })
    return total - 2900
  }

  return (
    <Flex gap={4} direction={'column'}>
      <Text fontWeight={'bold'}>{t('main_app.return.text_items_back')}</Text>
      <Text>{t('main_app.return.components.return_overview_form.text_postnord_postal')}</Text>
      <Divider style={{ borderWidth: 1, borderColor: 'rgba(211, 211, 202, 1)' }} />

      <Flex gap={4} direction={'column'} alignItems={'flex-start'}>
        <Flex gap={4} direction={'column'} w={'full'}>
          <>
            <Text fontWeight={'bold'}>{t('main_app.return.components.return_overview_form.text_order_total')}</Text>
            <Flex direction={'column'}>
              <Flex justifyContent={'space-between'}>
                <Text>{t('main_app.return.components.return_overview_form.text_subtotal')}</Text>
                <Text>{centPriceToPrice(calculateSubTotal(), 2).toFixed(2) + ' ' + 'DKK'}</Text>
              </Flex>
              <Flex justifyContent={'space-between'}>
                <Text>{t('main_app.return.components.return_overview_form.text_shipping')}</Text>
                <Text>{t('main_app.return.text_price_shipping.0')}</Text>
              </Flex>
            </Flex>
            <Flex fontWeight={'bold'} justifyContent={'space-between'}>
              <Text>{t('main_app.return.text_total')}</Text>
              <Text>{centPriceToPrice(calculateTotalMinusShipping(), 2).toFixed(2) + ' ' + 'DKK'}</Text>
            </Flex>
          </>
        </Flex>
        <Divider style={{ borderWidth: 1, borderColor: 'rgba(211, 211, 202, 1)' }} />

        <Flex fontSize={14} p={2} alignItems={'center'} justifyContent={'flex-start'} borderRadius={24} bgColor={Theme.colors.plentyGreen} w={'full'}>
          <Icon bgColor={'transparent'} fontSize={'30px'} color={'black'} as={ExclamationMark} />
          <Text>
            <Trans
              i18nKey='main_app.return.components.return_overview_form.text_refund_transfer'
              values={{
                price: centPriceToPrice(calculateTotalMinusShipping(), 2).toFixed(2) + ' ' + 'DKK',
              }}
              components={[<b key='0' />]}
            />
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
