import { Flex, Box, Image, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { findProductVariantAttributeDefaultEnglish } from '../../../../utils/ProductUtil'

import { ProductPrice } from '../../PLP/components/ProductPrice'
import { ProductCardProps } from '../../PLP/types'

export const ProductCardSlider = ({ product, storeKey, hasDragged, channelId }: ProductCardProps & { hasDragged: boolean }) => {
  const navigate = useNavigate()
  const { masterVariant } = product
  const { key, images, attributes } = masterVariant
  const navigateToProduct = () => {
    const baseRoute = `/product/${product.key}?variant=${key}`
    const storeRoute = storeKey ? `&store=${storeKey}` : ''
    navigate(`${baseRoute}${storeRoute}`)
  }

  const handleCardClick = (e: React.MouseEvent, isImageClick: boolean) => {
    if (!hasDragged) {
      e.stopPropagation()
      navigateToProduct()
    }
  }
  const brand = findProductVariantAttributeDefaultEnglish(masterVariant, 'brand') ?? 'Unknown Brand'

  return (
    <Box minW={'18rem'} _hover={{ cursor: 'pointer' }} key={key} onClick={(e) => handleCardClick(e, false)}>
      {images.length > 0 && (
        <Image
          onClick={(e) => handleCardClick(e, true)}
          borderRadius={'1.5rem'}
          style={{ aspectRatio: '3 / 4' }}
          width='100%'
          maxWidth={'20rem'}
          objectFit='contain'
          src={images[0].url}
        />
      )}

      <Flex mt={4} direction={'column'} gap={1}>
        <Flex height='3rem' direction={'column'} lineHeight={'4'}>
          <Text variant={'textProductCardBrand'}>{brand}</Text>
          <Text variant={'textProductCardName'}>{product.name.en}</Text>
        </Flex>
        <ProductPrice product={product} channelId={channelId} />
      </Flex>
    </Box>
  )
}
