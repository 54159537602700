import { Flex } from '@chakra-ui/react'
import { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { getChannels, getProductsProjectionSearchForGenderSaleSlider } from '../../../../commercetools/requests'
import { PlentySlider } from '../../../../components/layout/PlentySlider/PlentySlider'
import { ProductCard } from '../../PLP/components/ProductCard'

import { PlentyProduct } from '../../PLP/types'
import { formatPlentyProduct } from '../../PLP/utils'

export const GenderSaleSlider = ({ gender, targetGroup, title }: { gender?: string; targetGroup: string; title: string }) => {
  const [products, setProducts] = useState<PlentyProduct[] | undefined>([])
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getChannels()
      .then((channels) => {
        const storesToFilter = channels?.map((channel) => channel.id)
        getProductsProjectionSearchForGenderSaleSlider(gender, targetGroup, storesToFilter)
          .then((productsProjection) => {
            const plentyProducts = formatPlentyProduct(productsProjection?.results)
            setProducts(plentyProducts)
          })
          .catch((error) => {
            console.error('Error for gender slider:', error)
          })
          .finally(() => {
            setIsLoadingProducts(false)
          })
      })
      .catch((e) => {
        console.log('Error: ', e)
      })
  }, [gender, targetGroup])

  const navigateToProduct = (product?: PlentyProduct) => {
    let baseUrl = ''
    const queryParams = new URLSearchParams()

    if (product) {
      baseUrl = `/product/${product.key}`
      queryParams.append('variant', product.masterVariant.key)
    }

    navigate(`${baseUrl}?${queryParams.toString()}`)
  }

  return (
    <PlentySlider fontSize='3xl' heading={title} title={title.toLowerCase()} navigateToProduct={navigateToProduct}>
      {' '}
      {products?.map((product) => (
        <Flex minWidth={'18rem'} key={product.key}>
          <ProductCard product={product} />
        </Flex>
      ))}
    </PlentySlider>
  )
}
