import React from 'react'
import { Box, Grid, Icon, Text } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'
import { mainColorsMapped } from '../utils'
import { useColorSelector } from '../hooks/useColorSelector'
import { useCTFilter } from '../../../../context/CTFilterContext'

export const ColorCircles = () => {
  const { colorFacets, selectedColors: colorsFromContext, setSelectedColors: updateGlobalColor } = useCTFilter()
  const [selectedColors, setSelectedColors] = useColorSelector(colorsFromContext, updateGlobalColor)
  const toggleColor = (colorName: string) => {
    const newSelectedColors = new Set(selectedColors)

    // Find the color facet that has been clicked on
    const clickedColorFacet = colorFacets.find((color) => color.term === colorName)

    // If this color has additional options, consider them as well
    const relatedOptions = clickedColorFacet ? clickedColorFacet.options : []

    if (newSelectedColors.has(colorName)) {
      newSelectedColors.delete(colorName)
      relatedOptions.forEach((option) => newSelectedColors.delete(option))
    } else {
      newSelectedColors.add(colorName)
      relatedOptions.forEach((option) => newSelectedColors.add(option))
    }

    setSelectedColors(newSelectedColors) // Assuming that setSelectedColors accepts Set<string>
  }

  if (mainColorsMapped.length === 0) return <Text>No colors available</Text>

  return (
    <Grid templateColumns='repeat(3, 1fr)' gap={4}>
      {colorFacets.map((color, index) => (
        <Box display='flex' alignItems='center' key={index} onClick={() => toggleColor(color.term)} cursor='pointer'>
          <Box position='relative' w='20px' h='20px' borderRadius='50%' bgColor={color.color}>
            {selectedColors?.has(color.term) && <Icon as={FaCheck} position='absolute' top='20%' left='15%' color='mintGreen.500' />}
          </Box>
          <Text fontSize='sm' ml={2}>
            {color.term}
          </Text>
        </Box>
      ))}
    </Grid>
  )
}
