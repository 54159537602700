import { Divider, Flex, Icon, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useReturn } from '../../../../context/OrderReturnContext'
import { centPriceToPrice } from '../../../../utils/MoneyUtil'

export const ReturnOverviewInStore = () => {
  const { order, returnItemListWithQuantity } = useReturn()

  const calculateSubTotal = () => {
    let subtotal = 0
    returnItemListWithQuantity.forEach((item) => {
      subtotal += item.orderLineItem.price * item.quantity
    })
    return subtotal
  }

  const calculateTotal = () => {
    let total = 0
    returnItemListWithQuantity.forEach((item) => {
      total += item.orderLineItem.price * item.quantity
    })
    return total
  }

  const groupStoresToReturnTo = () => {
    const stores: any[] = []
    returnItemListWithQuantity.forEach((item) => {
      if (!stores.includes(item.store)) {
        stores.push(item.store)
      }
    })
    return stores
  }
  //i18n todo
  return (
    <Flex gap={4} direction={'column'}>
      <Text fontWeight={'bold'}>{t('main_app.return.text_return_in_store')}</Text>
      {groupStoresToReturnTo().map((item) => {
        return (
          <>
            <Flex direction={'column'}>
              <Text>{item.storeName}</Text>
              <Text>{item.streetAndNumber}</Text>
              <Text>
                {item.postalCode}, {item.city}
              </Text>
            </Flex>
            <Divider style={{ borderWidth: 1, borderColor: 'rgba(211, 211, 202, 1)' }} />
          </>
        )
      })}

      <Flex gap={4} direction={'column'} alignItems={'flex-start'}>
        <Flex gap={4} direction={'column'} w={'full'}>
          <>
            <Text fontWeight={'bold'}>{t('main_app.return.components.return_overview_form.text_order_total')}</Text>
            <Flex direction={'column'}>
              <Flex justifyContent={'space-between'}>
                <Text>{t('main_app.return.components.return_overview_form.text_subtotal')}</Text>
                <Text>{centPriceToPrice(calculateSubTotal(), 2).toFixed(2) + ' ' + 'DKK'}</Text>
              </Flex>
              <Flex justifyContent={'space-between'}>
                <Text>{t('main_app.return.components.return_overview_form.text_return_store')}</Text>
                <Text>{t('main_app.return.text_price_shipping.1')}</Text>
              </Flex>
            </Flex>
            <Flex fontWeight={'bold'} justifyContent={'space-between'}>
              <Text>{t('main_app.return.text_total')}</Text>
              <Text>{centPriceToPrice(calculateTotal(), 2).toFixed(2) + ' ' + 'DKK'}</Text>
            </Flex>
          </>
        </Flex>
        {/*<Divider style={{ borderWidth: 1, borderColor: 'rgba(211, 211, 202, 1)' }} />*/}

        {/*<Flex fontSize={14} p={2} alignItems={'center'} justifyContent={'flex-start'} borderRadius={24} bgColor={'mintGreen.500'} w={'full'}>*/}
        {/*  <Icon bgColor={'transparent'} fontSize={'30px'} color={'black'} as={ExclamationMark} />*/}
        {/*  <Text>*/}
        {/*    <Trans*/}
        {/*      i18nKey='main_app.return.components.return_overview_form.text_refund_transfer'*/}
        {/*      values={{*/}
        {/*        price: order?.taxedPrice !== undefined ? centPriceToPrice(order.taxedPrice, 2) - 39 + ' ' + order.taxedPrice + ' ' : 'Not available',*/}
        {/*      }}*/}
        {/*      components={[<b key='0' />]}*/}
        {/*    />*/}
        {/*  </Text>*/}
        {/*</Flex>*/}
      </Flex>
    </Flex>
  )
}
