import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading } from '@chakra-ui/react'
import { t } from 'i18next'

export const PaymentsAccordion = () => {
  return (
    <Flex direction='column' w='full' alignItems={'start'} alignContent={'start'}>
      <Heading borderRadius={'20px'} fontSize={'3xl'} padding={4} width={'full'}>
        {t('main_app.faq.payments.headline_payments')}
      </Heading>

      <Accordion allowToggle w={'full'}>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.payments.accordion_button_extra_fee')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>
          <AccordionPanel>
            <Box>{t('main_app.faq.payments.accordion_panel_handling_fee')}</Box>
            <Box>{t('main_app.faq.payments.accordion_panel_shipping')}</Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}
