import { useApolloClient } from '@apollo/client'
import { Flex, Icon, IconButton, Img, Tag, Text } from '@chakra-ui/react'

import { IconMinus, IconPlus, IconTrash } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import {
  BundleDiscount,
  DiscountedLineItemPriceForQuantity,
  LineItem,
  useGetMostValuableBundleDiscountBySpecificStoreVariantLazyQuery,
} from '../../../generated/graphql'
import { GET_CART, UPDATE_CART_LINE_ITEM } from '../../../gql/cart'
import { VariantPrice } from '../../../pages/main_app/PDP/components/VariantPrice'
import { centPriceToPrice, moneyFormat } from '../../../utils/MoneyUtil'
import { findProductVariantAttributeDefaultEnglish, productVariantToPlentyVariant } from '../../../utils/ProductUtil'
import { ErrorBox } from '../../ErrorBox'

type CartItemProps = {
  lineItem: LineItem
  customDiscountedLineItemPriceForQuantities: DiscountedLineItemPriceForQuantity[]
  hideControls?: boolean
}

type Placement = {
  top: number | undefined
  left: number | undefined
  right: number | undefined
  bottom: number | undefined
}

const BundleTag = (props: { bundle: BundleDiscount | null; placement: Placement }) => {
  if (props.bundle?.bundle.__typename == 'QuantityBundle') {
    return (
      <Tag
        color={'darkGreen.500'}
        colorScheme={'green'}
        position={'absolute'}
        left={props.placement.left}
        bottom={props.placement.bottom}
        top={props.placement.top}
        right={props.placement.right}
        margin={'0 auto'}
        justifyContent={'center'}
        borderTopRadius={0}
      >
        Buy {props.bundle.bundle.discountedQuantity} get {props.bundle.bundle.triggerQuantity}
      </Tag>
    )
  } else if (props.bundle?.bundle.__typename == 'PriceBundle') {
    return (
      <Tag
        color={'darkGreen.500'}
        colorScheme={'green'}
        position={'absolute'}
        left={props.placement.left}
        bottom={props.placement.bottom}
        top={props.placement.top}
        right={props.placement.right}
        margin={'0 auto'}
        justifyContent={'center'}
      >
        Buy {props.bundle.bundle.triggerQuantity} for {props.bundle.bundle.totalPriceAfterDiscount} Kr
      </Tag>
    )
  } else {
    return <></>
  }
}

export const CartItem = ({ lineItem, customDiscountedLineItemPriceForQuantities, hideControls }: CartItemProps) => {
  const [cartLoading, setCartLoading] = useState(false)
  const [bundleDiscount, setBundleDiscount] = useState<BundleDiscount | null>(null)
  const client = useApolloClient()
  const [getMostValuableBundleDiscountBySpecificStoreVariant] = useGetMostValuableBundleDiscountBySpecificStoreVariantLazyQuery({
    variables: {
      productKey: lineItem.productKey,
      channelKey: lineItem.distributionChannel?.obj?.key,
      sku: lineItem.variant?.sku,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getMostValuableBundleDiscountBySpecificStoreVariant().then((response: any) => {
      setBundleDiscount(response.data.getMostValuableBundleDiscountBySpecificStoreVariant)
    })
  }, [])

  const isDiscounted = (): boolean => {
    return lineItem.price?.discounted != null || bundleDiscount != null
  }

  const quantityOrPriceBundle = (): 'quantity' | 'price' | null => {
    switch (bundleDiscount?.bundle.__typename) {
      case 'PriceBundle':
        return 'price'
      case 'QuantityBundle':
        return 'quantity'
      default:
        return null
    }
  }

  const getBundleUnitPrice = (): number => {
    return (
      relevantDiscountedPricePerQuantity
        .slice(0, 2)
        .map((dppq: DiscountedLineItemPriceForQuantity) => dppq.discountedPrice.value.centAmount * dppq.quantity)
        .reduce((acc, current) => acc + current, 0) / bundleQuantity
    )
  }

  const unitPrice = (): number => {
    const discounted = isDiscounted()
    const nonDiscountedUnitPrice = lineItem.price?.value.centAmount ?? 0

    if (discounted) {
      if (lineItem.price?.discounted) {
        return lineItem.price.discounted.value.centAmount
      } else if (bundleDiscount) {
        switch (quantityOrPriceBundle()) {
          case 'price':
            return getBundleUnitPrice()
          case 'quantity':
            return getBundleUnitPrice()
          default:
            return nonDiscountedUnitPrice
        }
      } else {
        return nonDiscountedUnitPrice
      }
    } else {
      return nonDiscountedUnitPrice
    }
  }

  const plentyVariant = lineItem?.variant ? productVariantToPlentyVariant(lineItem?.variant) : null

  const relevantDiscountedPricePerQuantity = customDiscountedLineItemPriceForQuantities
  const lineItemQuantity = relevantDiscountedPricePerQuantity
    .map((dppq: DiscountedLineItemPriceForQuantity) => dppq.quantity)
    .reduce((acc, current) => acc + current, 0)

  const getBundleQuantity = (): number => {
    return relevantDiscountedPricePerQuantity
      .slice(0, 2)
      .map((dppq: DiscountedLineItemPriceForQuantity) => dppq.quantity)
      .reduce((acc, current) => acc + current, 0)
  }
  const bundleQuantity = getBundleQuantity()
  const customUnitPrice = unitPrice()

  const isAboveBundleQuantity = () => {
    return lineItemQuantity > bundleQuantity
  }

  const shouldShowCustomUnitPrice = () => {
    return isDiscounted() && relevantDiscountedPricePerQuantity.length > 0 && lineItemQuantity >= bundleQuantity
  }

  const increaseQuantity = async () => {
    setCartLoading(true)
    client
      .mutate({
        mutation: UPDATE_CART_LINE_ITEM,
        variables: {
          cartId: localStorage.getItem('cartId'),
          lineItemId: lineItem?.id,
          quantity: (lineItem?.quantity ?? 0) + 1,
        },
      })
      .then((response: any) => {
        client.refetchQueries({
          include: [GET_CART],
        })
        setCartLoading(false)
      })
  }

  const decreaseQuantity = () => {
    setCartLoading(true)
    client
      .mutate({
        mutation: UPDATE_CART_LINE_ITEM,
        variables: {
          cartId: localStorage.getItem('cartId'),
          lineItemId: lineItem?.id,
          quantity: (lineItem?.quantity ?? 0) - 1,
        },
      })
      .then((response: any) => {
        client.refetchQueries({
          include: [GET_CART],
        })
        setCartLoading(false)
      })
  }

  const removeItem = () => {
    setCartLoading(true)
    client
      .mutate({
        mutation: UPDATE_CART_LINE_ITEM,
        variables: {
          cartId: localStorage.getItem('cartId'),
          lineItemId: lineItem?.id,
          quantity: 0,
        },
      })
      .then((response: any) => {
        client.refetchQueries({
          include: [GET_CART],
        })
        setCartLoading(false)
      })
  }

  if (!plentyVariant) {
    return <ErrorBox message='Variant not found' />
  }

  return (
    <Flex gap={'1rem'} direction={'column'}>
      <Flex gap={'1rem'}>
        <Flex w={'40%'} position={'relative'}>
          <Img objectFit={'cover'} borderRadius={'0.5rem'} src={lineItem.variant?.images[0]?.url ?? ''} />
          <Tag hidden={!hideControls} borderRadius={'full'} colorScheme={'green'} position={'absolute'} right={'-10px'} top={'-10px'}>
            {lineItem.quantity}
          </Tag>
          <BundleTag bundle={bundleDiscount} placement={{ bottom: 0, left: 0, right: 0, top: undefined }} />
        </Flex>
        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          <Flex direction={'column'}>
            <Flex>{findProductVariantAttributeDefaultEnglish(plentyVariant, 'brand')}</Flex>
            <Flex>{lineItem?.name.en}</Flex>
            {lineItem.distributionChannel?.id ? (
              <>
                <Flex gap={'1rem'}>
                  {isAboveBundleQuantity() ? <Text>{bundleQuantity + ' items'}</Text> : null}
                  <VariantPrice
                    customDiscountedPrice={shouldShowCustomUnitPrice() ? centPriceToPrice(customUnitPrice, 2) : undefined}
                    livePriceFontSize='16px'
                    oldPriceFontSize='14px'
                    channelId={lineItem?.distributionChannel?.id}
                    variant={plentyVariant}
                  />
                </Flex>
                {isAboveBundleQuantity() ? (
                  <Flex gap={'1rem'}>
                    <Text>{lineItemQuantity - bundleQuantity + ' items'}</Text>
                    <VariantPrice livePriceFontSize='16px' oldPriceFontSize='14px' channelId={lineItem?.distributionChannel?.id} variant={plentyVariant} />
                  </Flex>
                ) : null}
              </>
            ) : null}
          </Flex>
          <Flex direction={'column'} w={'100%'}>
            <Flex fontSize={'14px'} alignItems={'center'}>
              <Flex w={75}>Color: </Flex>
              <Flex>{findProductVariantAttributeDefaultEnglish(plentyVariant, 'color')}</Flex>
            </Flex>
            <Flex fontSize={'14px'} alignItems={'center'}>
              <Flex w={75}>Size: </Flex>
              <Flex>{findProductVariantAttributeDefaultEnglish(plentyVariant, 'size')}</Flex>
            </Flex>
            <Flex fontSize={'14px'} alignItems={'center'}>
              <Flex w={75}>Total: </Flex>
              <Flex>
                {moneyFormat(
                  centPriceToPrice(lineItem.totalPrice?.centAmount ?? 0, lineItem.totalPrice?.fractionDigits ?? 2),
                  lineItem.totalPrice?.currencyCode ?? 'DKK',
                  'da-DK',
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {hideControls ? null : (
        <Flex justifyContent={'space-between'}>
          <Flex gap={'8px'}>
            <IconButton
              borderColor={'beigeDark.500'}
              w={'40px'}
              minW={'40px'}
              h={'40px'}
              minH={'40px'}
              variant={'outlineWhite'}
              aria-label='delete'
              icon={<Icon w={6} h={6} as={IconTrash} />}
              isLoading={cartLoading}
              onClick={() => removeItem()}
            />
          </Flex>
          <Flex>
            <IconButton
              borderColor={'beigeDark.500'}
              w={'60px'}
              minW={'40px'}
              h={'40px'}
              minH={'40px'}
              borderTopLeftRadius={40}
              borderBottomLeftRadius={40}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
              borderRight={0}
              variant={'outlineWhite'}
              aria-label='delete'
              icon={<Icon w={6} h={6} as={IconMinus} />}
              isLoading={cartLoading}
              onClick={() => decreaseQuantity()}
            />
            <Flex
              borderColor={'beigeDark.500!important'}
              h={'40px'}
              w={'40px'}
              justifyContent={'center'}
              alignItems={'center'}
              bgColor={cartLoading ? 'beigeLight.300' : 'white.500'}
              borderTop={'1px'}
              borderBottom={'1px'}
            >
              {lineItem.quantity}
            </Flex>
            <IconButton
              borderColor={'beigeDark.500'}
              w={'60px'}
              minW={'40px'}
              h={'40px'}
              minH={'40px'}
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
              borderTopRightRadius={40}
              borderBottomRightRadius={40}
              borderLeft={0}
              variant={'outlineWhite'}
              aria-label='delete'
              icon={<Icon w={6} h={6} as={IconPlus} />}
              isLoading={cartLoading}
              onClick={() => increaseQuantity()}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
