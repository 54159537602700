import { Box, Button } from '@chakra-ui/react'
import { t } from 'i18next'
import { useProductSearch } from '../../../../context/ProductSearchContext'
import { useProductSearchUtilities } from '../SearchUtil'

const GenderSelector = () => {
  const { selectedGender } = useProductSearch()
  const { handleGenderClick } = useProductSearchUtilities()

  return (
    <Box>
      {['women', 'men', 'kids'].map((gender) => (
        <Button
          marginTop={'1rem'}
          fontSize={'22px'}
          pl={0}
          pr={'24px'}
          variant={'mediumTransparentButtonWithUnderline'}
          key={gender}
          cursor='pointer'
          textDecoration={selectedGender === gender ? 'underline' : 'normal'}
          textDecorationThickness={selectedGender === gender ? '0.2rem' : 'normal'}
          textDecorationColor={selectedGender === gender ? 'mintGreen.500' : 'normal'}
          textUnderlineOffset={selectedGender === gender ? '0.5rem' : 'normal'}
          fontWeight={selectedGender === gender ? 'semibold' : 'normal'}
          onClick={() => handleGenderClick(gender)}
        >
          {t(`search.text_${gender}`)}
        </Button>
      ))}
    </Box>
  )
}

export default GenderSelector
