/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, useContext, useCallback, ReactElement } from 'react'

interface BreadcrumbItem {
  title: any
  key?: number
  clickablePath?: string
  active?: boolean
}

interface BreadcrumbsContextProps {
  breadcrumbs: BreadcrumbItem[]
  setActiveBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void
  clearBreadcrumbs: () => void
}

const BreadcrumbsContext = createContext<BreadcrumbsContextProps>({
  breadcrumbs: [{ title: 'Home', active: true, clickablePath: '/', key: 0 }],
  setActiveBreadcrumbs: () => undefined,
  clearBreadcrumbs: () => undefined,
})

export default function BreadcrumbsProvider({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([])

  const setActiveBreadcrumbs = (breadcrumbs: BreadcrumbItem[]) => {
    setBreadcrumbs(breadcrumbs)
  }

  const clearBreadcrumbs = useCallback(() => {
    setBreadcrumbs([])
  }, [])

  return <BreadcrumbsContext.Provider value={{ breadcrumbs, setActiveBreadcrumbs, clearBreadcrumbs }}>{children}</BreadcrumbsContext.Provider>
}

export const useBreadcrumbs = (): BreadcrumbsContextProps => useContext(BreadcrumbsContext)
