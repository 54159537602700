import { Flex, Img, SkeletonText, Stack, Tag, Text, Tooltip } from '@chakra-ui/react'
import { t } from 'i18next'
import { centPriceToPrice, moneyFormat } from '../../../../../utils/MoneyUtil'

type ReturnLineItemProps = {
  imageUrl: string
  name: string
  styleNumber: string
  size: string
  color: string
  price: number
  ean: string
  orderLineItemStatus: string
  comment: string | null

  expanded: boolean
  returnedQuantity: number | null
}

export const ReturnItemLine = ({
  imageUrl,
  name,
  styleNumber,
  size,
  color,
  price,
  ean,
  expanded,
  orderLineItemStatus,
  returnedQuantity,
  comment,
}: ReturnLineItemProps) => {
  return (
    <Flex gap={10} backgroundColor={orderLineItemStatus === 'lineitem_return_requested' ? '#E5E5E5' : 'none'} borderRadius={'md'}>
      <Flex position={'relative'} p={2}>
        <Tag borderRadius={'full'} variant={'subtle'} position={'absolute'} right={-3} top={-3}>
          {returnedQuantity + 'x'}
        </Tag>
        <Img maxH={'36'} borderRadius={'md'} src={imageUrl || 'https://onlinepngtools.com/images/examples-onlinepngtools/doggie-bmp.png'}></Img>
      </Flex>
      <Flex>
        <Stack spacing={1} w={400} justifyContent={'space-between'}>
          <>
            <Flex direction={'column'}>
              <Tooltip
                label={
                  <Flex direction={'column'}>
                    <Text size={'m'} fontWeight={'bold'}>
                      Reason for Return
                    </Text>
                    <Text size={'xs'} fontWeight={'normal'}>
                      {'"'}
                      {comment}
                      {'"'}
                    </Text>
                  </Flex>
                }
                placement='top-end'
                isOpen={expanded}
                hasArrow
                arrowSize={8}
              >
                <Text fontSize={'sm'}>{name || <i>{t('partner_portal.merchant.orders.components.text_product_name')}</i>}</Text>
              </Tooltip>
              <Text fontSize={'sm'}>
                {t('partner_portal.merchant.orders.components.text_product_style_number.0')}{' '}
                {styleNumber || <i>{t('partner_portal.merchant.orders.components.text_product_style_number.1')}</i>}
              </Text>
              <Text fontSize={'sm'}>
                {t('partner_portal.merchant.orders.components.text_product_size.0')}{' '}
                {size || <i>{t('partner_portal.merchant.orders.components.text_product_size.1')}</i>}
              </Text>
              <Flex alignItems={'center'} gap={2}>
                <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_product_color.0')} </Text>
                <Tag variant={'subtle'} bgColor={'blackAlpha.50'} color={'black'}>
                  {color || <i>{t('partner_portal.merchant.orders.components.text_product_color.1')}</i>}
                </Tag>
              </Flex>
            </Flex>
            <Flex direction={'column'}>
              <Text fontSize={'sm'}>Price: {price} kr</Text>
              <Text fontWeight={'bold'} fontSize={'sm'}>
                {t('partner_portal.merchant.orders.components.text_ean')} {ean}
              </Text>
            </Flex>
          </>
        </Stack>
      </Flex>
    </Flex>
  )
}
