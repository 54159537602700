import { Box, Flex, Text, TextProps } from "@chakra-ui/react"

type UnderlineTextProps = {
    text: string
} & TextProps

export const UnderlineText = (props: UnderlineTextProps) => {
    return(
       <Flex direction={'column'}>
         <Text {...props}>{props.text}</Text>
         <Box w={'full'} bgColor={'mintGreen.500'} h={'3px'} borderRadius={'full'} />
       </Flex>
    )
}