import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ChakraThemeConstants } from '../ChakraThemeConstants'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys)

const solid = definePartsStyle({
  field: {
    color: 'black',
    backgroundColor: 'beigeLight.500',
    height: ChakraThemeConstants.componentHeight,
    _invalid: {
      border: '2px',
      borderColor: 'red.400',
    },
  },
})

const solidRounded = definePartsStyle({
  field: {
    borderRadius: 'full',
    color: 'blackAlpha.600',
    backgroundColor: 'beigeLight.500',
    fontSize: 'sm',
    px: 4,
    height: '35px',
    _invalid: {
      border: '2px',
      borderColor: 'red.400',
    },
  },
})

const simple = definePartsStyle({
  field: {
    p: 0,
    borderRadius: 16,
    color: 'black',
    backgroundColor: 'transparent',
    border: '1px',
    _invalid: {
      border: '1px',
      borderColor: 'red.400',
    },
  }
})

const solidWhite = definePartsStyle({
  field: {
    color: 'black',
    backgroundColor: 'white.500',
    height: ChakraThemeConstants.componentHeight,
    _invalid: {
      border: '2px',
      borderColor: 'red.400',
    },
  },
})

const alternativeGreen = definePartsStyle({
  field: {
    color: 'green.500',
    backgroundColor: 'green.100',
    borderRadius: 'full',
    px: 5,
    fontSize: 'sm',
    _invalid: {
      border: '2px',
      borderColor: 'red.400',
    },
  },
  icon: {
    color: 'green.500',
  },
})

const alternativeRed = definePartsStyle({
  field: {
    color: 'red.500',
    backgroundColor: 'red.100',
    borderRadius: 'full',
    px: 5,
    fontSize: 'sm',
    _invalid: {
      border: '2px',
      borderColor: 'red.400',
    },
  },
  icon: {
    color: 'red.500',
  },
})

export const selectTheme = defineMultiStyleConfig({
  variants: { solid, solidWhite, alternativeGreen, alternativeRed, simple, solidRounded },
})
