import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  label: {
    color: 'black.500',
  },
  control: {
    borderRadius: '25%',
    borderColor: 'beigeDark.500',
    _checked: {
      backgroundColor: 'black.500',
      borderColor: 'black.500',
      _hover: {
        backgroundColor: 'black.500',
        borderColor: 'black.500',
      },
    },
    bgColor: 'white.500'
  },
  icon: {
    backgroundColor: 'black.500',
    color: 'mintGreen.500',
  },
})

const whiteRoundedStyle = definePartsStyle({
  control: {
    borderRadius: '50%',
    borderColor: 'rgba(211, 211, 202, 1)',
    backgroundColor: 'white',

    _checked: {
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
  icon: {
    backgroundColor: 'black.500',
    color: 'mintGreen.500',
  },
})

const whiteSquareStyle = definePartsStyle({
  control: {
    borderRadius: '25%',
    borderColor: 'rgba(211, 211, 202, 1)',
    backgroundColor: 'white',

    _checked: {
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
  icon: {
    backgroundColor: 'black.500',
    color: 'mintGreen.500',
  },
})

export const checkboxTheme = defineMultiStyleConfig({
  variants: {
    baseStyle,
    whiteRoundedStyle,
    whiteSquareStyle,
  },
})
