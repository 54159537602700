import { Cart, LineItem, Maybe } from '../generated/graphql'
import { centPriceToPrice } from './MoneyUtil'

export const orderHasDuplicateStyle = (cart: Cart) => {
  const styleNumebers = cart.lineItems.map((lineItem: Maybe<LineItem>) => lineItem?.productKey)
  const styleNumbersAsSet = new Set(cart.lineItems.map((lineItem: Maybe<LineItem>) => lineItem?.productKey))

  return styleNumebers.length !== styleNumbersAsSet.size
}

export const orderSize = (cart: Cart) => {
    return cart.lineItems.reduce((accumulator, lineItem: Maybe<LineItem>) => accumulator + (lineItem?.quantity ?? 0), 0);
}

export const orderValue = (cart: Cart) => {
    return parseFloat(cart.lineItems.reduce((accumulator, lineItem: Maybe<LineItem>) => accumulator + (centPriceToPrice(lineItem?.totalPrice?.centAmount ?? 0, lineItem?.totalPrice?.fractionDigits ?? 0) ?? 0), 0).toFixed(2));
}

export const storeBasketAllocation = (cart: Cart) => {
    const channelIdsAsSet = new Set(cart.lineItems.map((lineItem: Maybe<LineItem>) => lineItem?.distributionChannel?.id))
    
    return channelIdsAsSet.size
}