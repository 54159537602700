import { Box, Button, Flex, Grid, GridItem, Icon, IconButton, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react'
import { t } from 'i18next'
import { useState } from 'react'
import { TbMapPin, TbSearch } from 'react-icons/tb'
import { ReturnParcelShopCard } from './ReturnParcelShopCard'
import { ParcelResponse, useGetParcelShopByGeolocationLazyQuery } from '../../../../generated/graphql'
import { Theme } from '../../../../ThemeVariables'
import { PageSpinner } from '../../../../components/PageSpinner'

export const ReturnParcelShopPicker = () => {
  const [getParcelShopByGeolocation, { loading }] = useGetParcelShopByGeolocationLazyQuery()

  const [streetAndNumber, setStreetAndNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')

  const [parcelShopResponses, setParcelShopResponses] = useState<ParcelResponse[]>([])

  const fetchParcelShops = async () => {
    await getParcelShopByGeolocation({
      variables: {
        parcelShopQuery: {
          address: streetAndNumber,
          carrier: 'Postnord',
          country: 'DK',
          postCode: postalCode,
        },
      },
    }).then((response: any) => {
      setParcelShopResponses(response.data.getParcelShopByGeolocation)
    })
  }

  return (
    <Box borderRadius={Theme.spacing.borderRadius} bgColor={'beigeLight.500'} w={'full'} p={4}>
      <Text fontSize='xl' fontWeight='bold'>
        {t('main_app.return.components.return_by_parcel_shop.text_pick_shop')}
      </Text>

      <Flex direction={'column'} gap={4} mt={4}>
        <Flex>
          <Grid w={'full'} templateColumns={'repeat(3, 1fr)'} templateRows={'repeat(2, 1fr)'} gap={4}>
            <GridItem colSpan={2}>
              <InputGroup variant={'solid'}>
                <InputLeftElement pointerEvents='none'>
                  <Icon as={TbMapPin} fontSize={'3xl'} />
                </InputLeftElement>
                <Input
                  variant={'roundedSolidWhite'}
                  value={streetAndNumber}
                  onChange={(e: any) => setStreetAndNumber(e.target.value)}
                  placeholder={t('main_app.return.components.return_by_parcel_shop.text_address') as string}
                />
              </InputGroup>
            </GridItem>
            <GridItem colSpan={1}>
              <Input
                variant={'roundedSolidWhite'}
                value={postalCode}
                onChange={(e: any) => setPostalCode(e.target.value)}
                placeholder={t('components.cart.components.placeholder_postal') as string}
              />
            </GridItem>
            <GridItem colStart={1} colSpan={3}>
              <Button
                onClick={() => fetchParcelShops()}
                variant={'solidBlackSelect'}
                leftIcon={<Icon as={TbSearch} fontSize={'2xl'} />}
                w={'full'}
                isDisabled={!streetAndNumber || streetAndNumber.trim() === '' || !/^\d+$/.test(postalCode)}
              >
                {t('components.button.search_parcel')}
              </Button>
            </GridItem>
          </Grid>
        </Flex>
        <Box maxH='60vh' overflowY='auto'>
          <VStack spacing={4}>
            {!loading ? (
              parcelShopResponses.map((value: ParcelResponse, index: number) => {
                return <ReturnParcelShopCard key={index} parcelShopResponse={value} />
              })
            ) : (
              <PageSpinner />
            )}
          </VStack>
        </Box>
      </Flex>
    </Box>
  )
}
