import { gql } from '@apollo/client'

export const GET_PRODUCTS_BY_CHANNEL_KEY = gql`
  query GetProductsByChannelKey($channelKey: UUID) {
    getProductsByChannelKey(channelKey: $channelKey) {
      name
      categories {
        obj {
          name
        }
      }
      masterVariant {
        id
        sku
        key
        prices {
          id
          key
          value
          country
          channel {
            obj
          }
        }
      }
    }
  }
`

export const GET_PRODUCT_BY_CHANNEL_KEY_AND_PRODUCT_ID = gql`
  query GetProductByChannelKeyAndProductId($channelKey: UUID, $productId: String) {
    getProductByChannelKeyAndProductId(channelKey: $channelKey, productId: $productId) {
      id
      masterData {
        current {
          name
          categories {
            obj {
              name
            }
          }
          masterVariant {
            id
            sku
            key
            prices {
              id
              key
              value
              country
              channel {
                obj
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_PRICE_OF_PRODUCT = gql`
  mutation UpdatePriceOfProduct($productKey: String, $channelKey: String, $newPrice: BigDecimal) {
    updatePriceOfProduct(productKey: $productKey, channelKey: $channelKey, newPrice: $newPrice)
  }
`

export const SEARCH_COMMERCETOOLS_PRODUCTS_AND_CATEGORIES = gql`
  query SearchCommercetoolsProductsAndCategories($channelKey: UUID, $searchTerm: String, $filter: ProductAndCategorySearchFilter!) {
    searchCommercetoolsProductsAndCategories(channelKey: $channelKey, searchTerm: $searchTerm, filter: $filter) {
      ... on CategorySearchResult {
        attributes
        categoryKey
        imageUrl
        name
      }
      ... on ProductSearchResult {
        attributes
        imageUrl
        name
        productKey
        category
        price
        styleNumber
        variants {
          ean
          imageUrl
          name
          price
          size
        }
      }
    }
  }
`

export const GET_PRODUCTS_BY_KEYS = gql`
  query GetProductsByKeys($productTuple: [[String]]) {
    getProductsByKeys(productTuple: $productTuple) {
      attributes
      category
      imageUrl
      name
      price
      productKey
      styleNumber
      variants {
        ean
        imageUrl
        name
        price
        size
      }
    }
  }
`
