import { ReactElement } from 'react'
import { isMobile } from 'react-device-detect'

type ResponsiveDeciderProps = {
  mobileComp: ReactElement
  desktopComp: ReactElement
}

export const ResponsiveDecider = (props: ResponsiveDeciderProps) => {
  if (isMobile) {
    return props.mobileComp
  } else {
    return props.desktopComp
  }
}
