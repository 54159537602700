import { Box, Button } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useProductSearch } from '../../../../context/ProductSearchContext'
import { SearchCategory } from '../types'

const CategorySelector = () => {
  const { searchTerm, setSearchTerm, setCategoryId, selectedGender, setGenderizedCategoryId, genderizedCategoryId, setOffset, topCategories } =
    useProductSearch()

  const handleCategoryClick = useCallback(
    (category: SearchCategory) => {
      if (selectedGender == 'Kids' && category.parentCategoryId == category.genderCategoryId && searchTerm != '') {
        setGenderizedCategoryId(category.categoryId)
        setOffset(0)
      } else {
        setSearchTerm(category.categoryName)
        setCategoryId(category.categoryId)
      }
    },
    [setSearchTerm, setGenderizedCategoryId, selectedGender, genderizedCategoryId, searchTerm],
  )
  return (
    <Box whiteSpace={'nowrap'} overflowX='auto'>
      {topCategories.map((category) => (
        <Button
          fontSize={'18px'}
          pl={0}
          mb={'1rem'}
          pr={'24px'}
          variant={'mediumTransparentButtonWithUnderline'}
          textDecoration={genderizedCategoryId === category.categoryId ? 'underline' : 'normal'}
          textDecorationThickness={genderizedCategoryId === category.categoryId ? '0.2rem' : 'normal'}
          textDecorationColor={genderizedCategoryId === category.categoryId ? 'mintGreen.500' : 'normal'}
          textUnderlineOffset={genderizedCategoryId === category.categoryId ? '0.5rem' : 'normal'}
          fontWeight={genderizedCategoryId === category.categoryId ? 'semibold' : 'normal'}
          key={category.categoryName}
          cursor='pointer'
          onClick={() => handleCategoryClick(category)}
        >
          {category.categoryName}
        </Button>
      ))}
    </Box>
  )
}

export default CategorySelector
