import * as XLSX from 'xlsx'

export async function ExcelToCSVConverter(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target?.result) {
        const binaryString = XLSX.read(event.target?.result, { type: 'binary' })
        const sheetName = binaryString.SheetNames[0]
        const csvData = XLSX.utils.sheet_to_csv(binaryString.Sheets[sheetName], { strip: true, skipHidden: true })
        resolve(csvData)
      } else {
        reject(new Error('Failed to read file'))
      }
    }

    reader.onerror = (event) => {
      reject(new Error('Error reading file: ' + (event.target?.error?.message || 'unknown error')))
    }

    reader.readAsBinaryString(file)
  })
}
