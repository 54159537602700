import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Stack,
  StackDivider,
  Tag,
  Text,
  Toast,
} from '@chakra-ui/react'
import { IconCheck, IconChevronDown, IconChevronUp, IconExclamationCircle, IconUser, IconX } from '@tabler/icons-react'
import { t } from 'i18next'
import moment from 'moment'
import { useState } from 'react'
import { DeliveryTag } from './OrderDeliveryTag'
import { centPriceToPrice, moneyFormat } from '../../../../../utils/MoneyUtil'
import { CustomerInfoModal } from '../../orders_page/components/CustomerInfoModal'
import { ReturnItemLine } from './ReturnLineItem'
import { calculateTotalPrice, formattetHoursSinceOrder } from '../../orders/utils'
import { LineItem, LineItemReturnItem } from '@commercetools/platform-sdk'
import { useUser } from '../../../../../context/Auth'
import { findVariantAttributeDefaultEnglish } from '../../../../../utils/ProductUtil'

type ReturnCardProps = {
  orderNumber?: string
  returnItems: LineItemReturnItem[]
  lineItems: LineItem[]
  shippingKey: string
  createdAt: string
  customerInfo?: any
  customerEmail: string
}

export const ReturnCard = ({ orderNumber, shippingKey, returnItems, lineItems, createdAt, customerInfo, customerEmail }: ReturnCardProps) => {
  const { distributionChannelKey } = useUser()
  const [expanded, setExpanded] = useState(false)
  const [customerModalOpen, setCustomerModalOpen] = useState(false)
  const [actionTaken, setActionTaken] = useState(false)

  const lineItemsInReturn: LineItem[] = lineItems.filter((item: LineItem) => {
    const lineItemIdsInReturn = returnItems.map((returnItem) => (returnItem as LineItemReturnItem).lineItemId)
    return item.distributionChannel?.obj?.key === distributionChannelKey && lineItemIdsInReturn.includes(item.id)
  })

  const returnItemsAction = async (merchantResponse: string) => {
    if (lineItems) {
      setActionTaken(true)
      const url = `${process.env.REACT_APP_ORDERS_API_URL}/return/${orderNumber}?merchantResponse=${merchantResponse}&createdAt=${createdAt}`

      try {
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        const responseData = await response
        Toast({ title: 'The return has been accepted and the refund has been sent', status: 'success', duration: 1500, isClosable: true })
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }

  //TODO I18n
  const OrderHeaderContent = () => {
    return (
      <Flex flex={1} justifyContent={'space-between'} alignItems={'center'}>
        <HStack spacing={14}>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_order')}</Text>
            <Text>{orderNumber || <i>{t('partner_portal.merchant.orders.components.text_no_order_number')}</i>}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_date')}</Text>
            <Text>{moment(createdAt).format('LL')}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_total')}</Text>
            {/*// TODO: Hardcoded currency*/}
            <Text>{moneyFormat(centPriceToPrice(calculateTotalPrice(lineItemsInReturn) || 0, 2), 'DKK', 'da-DK')}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_time')}</Text>
            {createdAt && (
              <Text>
                <Tag>{formattetHoursSinceOrder(createdAt)}</Tag>
              </Text>
            )}
          </Flex>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_delivery')}</Text>
            <DeliveryTag shippingKey={shippingKey} />
          </Flex>
        </HStack>

        <IconButton
          onClick={() => setExpanded(!expanded)}
          variant={'ghost'}
          aria-label='expand'
          icon={<Icon as={expanded ? IconChevronDown : IconChevronUp} />}
        />
      </Flex>
    )
  }

  const SplitReturnOrderHeaderContent = () => {
    return (
      <Flex>
        <HStack spacing={14}>
          <Stack direction={'row'} alignItems={'center'}>
            <Icon as={IconExclamationCircle} color={'mintGreen.700'} />
            <Text alignItems={'center'}>Split order</Text>
          </Stack>
          {lineItemsInReturn.map((value: any, index: number) => {
            return (
              <Checkbox
                isChecked={value.state === 'lineitem_partial_return_accepted' || value.state === 'lineitem_return_accepted'}
                variant={'baseStyle'}
                key={index}
              >
                {value.storeName} {value.state === 'lineitem_partial_return_accepted' || (value.state === 'lineitem_return_accepted' && '(Return Completed)')}{' '}
                {value.state === 'lineitem_partial_return_requested' || (value.state === 'lineitem_return_requested' && '(Awaiting Return)')}
              </Checkbox>
            )
          })}
        </HStack>
      </Flex>
    )
  }
  return (
    <>
      <Card>
        <CardHeader borderBottom={'1px solid'} borderColor={'blackAlpha.200'}>
          <Stack gap={2}>
            <OrderHeaderContent />
          </Stack>
        </CardHeader>
        <CardBody hidden={!expanded}>
          <Flex hidden={!expanded} justifyContent={'space-between'} p={6} bgColor={'white'}>
            <Stack wrap={'wrap'} gap={5} divider={<StackDivider />}>
              {lineItemsInReturn?.map((lineItem: LineItem | null) =>
                lineItem ? (
                  <ReturnItemLine
                    key={returnItems.find((item) => item.lineItemId == lineItem.id)?.id}
                    imageUrl={lineItem.variant?.images ? lineItem.variant.images[0]?.url || '' : ''}
                    name={lineItem.name?.en || ''}
                    styleNumber={lineItem.productSlug?.en || ''}
                    size={findVariantAttributeDefaultEnglish(lineItem.variant, 'size')}
                    color={findVariantAttributeDefaultEnglish(lineItem.variant, 'color')}
                    price={centPriceToPrice(calculateTotalPrice([lineItem]) || 0, 2)}
                    ean={lineItem.variant?.sku || ''}
                    orderLineItemStatus={lineItem.state[0]?.state?.obj?.key || ''}
                    comment={returnItems.find((item) => item.lineItemId == lineItem.id)?.comment || ''}
                    expanded={expanded}
                    returnedQuantity={returnItems.find((item) => item.lineItemId == lineItem.id)?.quantity || 0}
                  />
                ) : null,
              )}
            </Stack>
          </Flex>
        </CardBody>
        <CardFooter borderTop={expanded ? '1px solid' : ''} borderColor={'blackAlpha.200'} justifyContent={'end'}>
          <HStack spacing={5}>
            <Button onClick={() => setCustomerModalOpen(true)} leftIcon={<Icon as={IconUser} />} variant={'ghost'} size={'lg'}>
              {t('components.button.customer_information')}
            </Button>
            <Button
              leftIcon={<Icon h={6} w={6} color={'mintGreen.500'} as={IconCheck} />}
              onClick={() => returnItemsAction('accepted')}
              variant={'solid'}
              size={'lg'}
              isDisabled={actionTaken}
            ></Button>
            <Button
              leftIcon={<Icon h={6} w={6} color={'mintGreen.500'} as={IconX} />}
              onClick={() => returnItemsAction('rejected')}
              variant={'solid'}
              size={'lg'}
              isDisabled={actionTaken}
            ></Button>
          </HStack>
        </CardFooter>
      </Card>

      <CustomerInfoModal customerEmail={customerEmail} customerInfo={customerInfo} isOpen={customerModalOpen} setOpen={setCustomerModalOpen} />
    </>
  )
}
