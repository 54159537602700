import { Divider, Flex, HStack, Icon, IconButton, Img, Text, VStack } from '@chakra-ui/react'
import { t } from 'i18next'
import { TbArrowNarrowUp, TbBrandInstagram, TbBrandLinkedin } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'

import LanguageSelector from '../../../../I18n/LanguageSelector'

import { Theme } from '../../../../ThemeVariables'
export const Footer = () => {
  const BASE_URL = 'https://storage.googleapis.com/bucket-plenty-dev/PayingMethods'
  const FooterIcon = 'https://storage.googleapis.com/bucket-plenty-dev/Header%26Footer/FooterIcon.png'

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const navigate = useNavigate()

  return (
    <Flex direction={'column'}>
      <Divider mt={Theme.spacing.margin} borderBottomWidth={'2px'} borderColor={'rgba(211, 211, 202, 1)'} />
      <Flex p={Theme.spacing.padding.desktop} direction={'column'}>
        <Flex gap={Theme.gap.desktop * 5}>
          <Flex gap={Theme.gap.desktop * 4}>
            <Flex>
              <Img width={'50px'} height={'60px'} src={FooterIcon} onClick={() => navigate('/')} />
            </Flex>
            <VStack alignItems={'flex-start'}>
              <Flex fontWeight={'bold'}>Plenty&</Flex>
              <Flex onClick={() => navigate('/about')} _hover={{ color: '#434343', cursor: 'Pointer' }}>
                {t('components.layout.text_about')}
              </Flex>
            </VStack>
            <VStack alignItems={'flex-start'}>
              <Flex fontWeight={'bold'}>{t('components.layout.text_support')}</Flex>
              <Flex onClick={() => navigate('/customer_service')} _hover={{ color: '#434343', cursor: 'Pointer' }}>
                {t('components.layout.text_faq')}{' '}
              </Flex>

              <Flex onClick={() => navigate('/return/login')} _hover={{ color: '#434343', cursor: 'Pointer' }}>
                {t('components.layout.text_return_items')}
              </Flex>
            </VStack>
            <VStack alignItems={'flex-start'}>
              <Flex fontWeight={'bold'}>{t('components.layout.contact_us')}</Flex>
              <Flex> support@plentyand.dk</Flex>
            </VStack>

            <VStack alignItems={'flex-start'}>
              <Flex fontWeight={'bold'}>{t('components.layout.text_social')}</Flex>
              <HStack>
                <Icon
                  as={TbBrandInstagram}
                  _hover={{ color: '#434343', cursor: 'Pointer' }}
                  boxSize={18}
                  onClick={() => (window.location.href = 'https://www.instagram.com/plentyand_/')}
                />
                <Icon
                  as={TbBrandLinkedin}
                  _hover={{ color: '#434343', cursor: 'Pointer' }}
                  boxSize={18}
                  onClick={() => (window.location.href = 'https://www.linkedin.com/company/plentyand/')}
                />
              </HStack>
            </VStack>
          </Flex>
          <Flex justifyContent={'flex-end'}>
            <IconButton w={12} h={12} onClick={handleScrollToTop} aria-label={'Scroll to top'}>
              <TbArrowNarrowUp size={26} />
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
      <Flex py={4} justifyContent={'flex-end'}>
        <LanguageSelector />
      </Flex>
      <Flex justifyContent={'flex-end'}>
        <HStack gap={4}>
          <Img width={'65px'} height={'40px'} src={`${BASE_URL}/MobilePay.png`} />
          <Img width={'65px'} height={'40px'} src={`${BASE_URL}/Dankort.png`} />
          <Img width={'65px'} height={'40px'} src={`${BASE_URL}/Visa.png`} />
          <Img width={'65px'} height={'40px'} src={`${BASE_URL}/Mastercard.png`} />
        </HStack>
      </Flex>

      <Divider pt={Theme.spacing.padding.desktop} borderBottomWidth={'2px'} borderColor={'rgba(211, 211, 202, 1)'} />
      <Flex p={Theme.spacing.padding.desktop} gap={Theme.gap.desktop} justifyContent={'space-between'}>
        <Flex gap={Theme.gap.desktop}>
          <Link to={'/politics/privacy_policy'}>
            {' '}
            <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_privacy')}</Text>
          </Link>
          <Link to={'/politics/terms_of_use_policy'}>
            <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_terms')}</Text>
          </Link>
          <Link to={'/politics/cookie_policy'}>
            <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_cookies')}</Text>
          </Link>
        </Flex>
        <Flex gap={Theme.gap.desktop}>
          <Flex>Plenty&</Flex>
          <Flex>{t('components.layout.text_right_reserved')}</Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
