import { Box, Flex, Heading, Img, LinkBox, LinkOverlay, Text, useMediaQuery } from '@chakra-ui/react'
import { t } from 'i18next'
import { Theme } from '../../../../ThemeVariables'
import { FindTopCategoryPickImagesForStore } from '../../../../utils/GoogleCloudBucketUtil'
interface TopCategoryPickProps {
  storeByDisplayName: any
}
export const TopCategoryPick = ({ storeByDisplayName }: TopCategoryPickProps) => {
  const images = FindTopCategoryPickImagesForStore(storeByDisplayName)

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const renderImageWithOverlay = (src: string, label: { displayName: string; navParam: string }, gender: string) => (
    <LinkBox flex={1} maxWidth={isLargerThan768 ? '50%' : '100%'} height='auto' borderRadius={Theme.spacing.borderRadius} position='relative'>
      <LinkOverlay href={`/products/${gender.toLowerCase()}/${label.navParam.toLocaleLowerCase()}`}>
        <Img width='100%' height='auto' borderRadius={Theme.spacing.borderRadius} src={src} objectFit='contain' />
      </LinkOverlay>
      <Flex direction={'column'} bottom='5%' left='5%' position='absolute'>
        <Text color={'white'} fontSize={'24px'}>
          {t(label.displayName)}
        </Text>
      </Flex>
    </LinkBox>
  )
  return (
    <Flex width='100%' overflow='hidden' direction='column' alignItems='center' gap={4}>
      <Heading alignSelf={'start'}>{t('main_app.top_category_pick.heading_top_category_pick')}</Heading>
      {isLargerThan768 ? (
        <Flex cursor={'pointer'} width='100%' gap={4} direction='row'>
          {images.image1 && images.label1 && images.gender1 && renderImageWithOverlay(images.image1, images.label1, images.gender1)}
          {images.image2 && images.label2 && images.gender2 && renderImageWithOverlay(images.image2, images.label2, images.gender2)}
          {images.image3 && images.label3 && images.gender3 && renderImageWithOverlay(images.image3, images.label3, images.gender3)}
          {images.image4 && images.label4 && images.gender4 && renderImageWithOverlay(images.image4, images.label4, images.gender4)}
        </Flex>
      ) : (
        <>
          <Flex cursor={'pointer'} width='100%' gap={4} direction='row'>
            {images.image1 && images.label1 && images.gender1 && renderImageWithOverlay(images.image1, images.label1, images.gender1)}
            {images.image2 && images.label2 && images.gender2 && renderImageWithOverlay(images.image2, images.label2, images.gender2)}
          </Flex>
          <Flex cursor={'pointer'} width='100%' gap={4} direction='row'>
            {images.image3 && images.label3 && images.gender3 && renderImageWithOverlay(images.image3, images.label3, images.gender3)}
            {images.image4 && images.label4 && images.gender4 && renderImageWithOverlay(images.image4, images.label4, images.gender4)}
          </Flex>
        </>
      )}
    </Flex>
  )
}
