import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react'

type PdfSizeGuideModalProps = {
  base64: string
  isOpen: boolean
  onClose: () => void
}
const base64ToBlob = (base64: string) => {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return new Blob([bytes], { type: 'application/pdf' })
}

export const PdfSizeGuideModal = ({ base64, isOpen, onClose }: PdfSizeGuideModalProps) => {
  const blob = base64ToBlob(base64)
  const pdfUrl = URL.createObjectURL(blob) + '#toolbar=0'

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <iframe src={pdfUrl} width='100%' height='1000px'></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
