import { Box, Flex, Heading, Image, LinkBox, LinkOverlay, Text, useMediaQuery } from '@chakra-ui/react'
import { t } from 'i18next'
import { Theme } from '../../../../ThemeVariables'
import { FindSeasonalSaleCardImage } from '../../../../utils/GoogleCloudBucketUtil'
interface SeasonalSaleCardProps {
  gender: any
}
export const SeasonalSaleCard = ({ gender }: SeasonalSaleCardProps) => {
  const images = FindSeasonalSaleCardImage(gender)

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const renderImageWithOverlay = (src: string, label: { displayName: string; navParam: string }, heading: string) => (
    <LinkBox flex={1} maxWidth={isLargerThan768 ? '50%' : '100%'} height='auto' borderRadius={Theme.spacing.borderRadius} position='relative'>
      <LinkOverlay href={`/products/${gender.toLowerCase()}/${label.navParam.toLocaleLowerCase()}`}>
        <Box
          borderRadius={{ base: '46px', md: '36px', lg: '38px', xl: '42px' }}
          style={{
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 26.22%)',
          }}
          width='100%'
          height='100%'
          position='absolute'
          zIndex='2'
          top='0'
          left='0'
        />
        <Image width='100%' height='auto' borderRadius={'2rem'} src={src} objectFit='contain' zIndex='1' />{' '}
      </LinkOverlay>
      <Flex direction={'column'} bottom='5%' left='5%' position='absolute'>
        <Flex zIndex={10} alignItems={'self-start'} textAlign={'start'} direction={'column'} color={'white'}>
          <Text fontSize={{ base: '16px', md: '1vw' }} letterSpacing={{ base: '-0.24px', md: 'normal' }} color={'white'}>
            {t(label.displayName)}
          </Text>
          <Heading fontStyle={'normal'} fontWeight={'400'} fontSize={{ base: '24px', md: '1.5vw' }}>
            {t(heading)}
          </Heading>
        </Flex>
      </Flex>
    </LinkBox>
  )
  return (
    <Flex
      py={{ base: '4.5rem', md: '6.5rem' }}
      width='100%'
      overflow='hidden'
      direction='column'
      justifyContent={'center'}
      mx={'auto'}
      alignItems='center'
      gap={{ base: '1rem', md: '2rem' }}
    >
      <Flex gap={{ base: '0.25rem', md: '0.5rem' }} direction={'column'} textAlign={'start'} alignSelf={'flex-start'}>
        <Heading variant={'headingForComponentOnAMainPage'}>{t('main_app.seasonal_sale_card.heading_sale_card')}</Heading>
        <Text variant={'textForComponentOnAMainPageWhoHasAHeading'}>{t('main_app.seasonal_sale_card.text_sale_card')}</Text>
      </Flex>

      <Flex cursor={'pointer'} width='100%' gap={4} direction={{ base: 'column', md: 'row' }}>
        {images.image1 && images.label1 && images.heading1 && renderImageWithOverlay(images.image1, images.label1, images.heading1)}
        {images.image2 && images.label2 && images.heading2 && renderImageWithOverlay(images.image2, images.label2, images.heading2)}
        {images.image3 && images.label3 && images.heading3 && renderImageWithOverlay(images.image3, images.label3, images.heading3)}
        {images.image4 && images.label4 && images.heading4 && renderImageWithOverlay(images.image4, images.label4, images.heading4)}
      </Flex>
    </Flex>
  )
}
