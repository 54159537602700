import { useUser } from '../context/Auth'
import { MainRole, Role } from '../generated/graphql'

import React, { ComponentType, FC } from 'react'
import { Navigate } from 'react-router-dom'

const redirectByRole = {
  [MainRole.RoleAdmin]: '/merchant',
  [MainRole.RoleCustomer]: '/',
  [MainRole.RoleGuest]: '/login',
}

interface WithAuthProps {
  ProtectedComponent: any
  AllowedRoles: MainRole
}

export default function withAuth<T extends WithAuthProps = WithAuthProps>(
  WrappedComponent: React.ComponentType<T>,
  AllowedRoles: MainRole[] = [MainRole.RoleGuest],
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithAuth = (props: Omit<T, keyof WithAuthProps>) => {
    const { user, role, status } = useUser()
    if (typeof window !== 'undefined') {
      if (status !== 'finished') {
        return null
      }

      // if (!user && status === 'finished') {
      //     return <Navigate
      //         to={{
      //         pathname: '/signin',
      //         }}
      //     />
      // }

      if (status === 'finished' && !AllowedRoles.some((roleItem) => roleItem === role)) {
        return (
          <Navigate
            to={{
              pathname: redirectByRole[role],
            }}
          />
        )
      }
      return <WrappedComponent {...(props as T)} />
    }
    return null
  }

  ComponentWithAuth.displayName = `withAuth(${displayName})`

  return ComponentWithAuth
}
