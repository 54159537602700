import { Text, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, useDisclosure, CloseButton, Heading } from '@chakra-ui/react'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DanishSvg } from '../assets/svg/LanguageSvg/Danish.svg'
import { ReactComponent as EnglishSvg } from '../assets/svg/LanguageSvg/English.svg'

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const selectLanguage = (lang: any) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('languageSelected', lang)
    onClose()
  }

  const selectedLanguage = localStorage.getItem('languageSelected') || i18n.language

  return (
    <>
      <Flex alignItems='center' onClick={onOpen}>
        {selectedLanguage === 'DK' ? (
          <Flex>
            <DanishSvg />
          </Flex>
        ) : (
          <Flex>
            <EnglishSvg />
          </Flex>
        )}

        <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent gap={4} backgroundColor={'beigeLight.500'}>
            <DrawerHeader p={0} borderBottomWidth='1px'>
              <Flex>
                <CloseButton m={4} onClick={onClose} />
              </Flex>
            </DrawerHeader>
            <DrawerBody px={10} display={'flex'} gap={10} flexDirection={'column'}>
              <Heading fontSize={'2xl'}>{t('components.language_selector.heading_language_selector')}</Heading>
              <Flex alignItems='center' cursor='pointer' onClick={() => selectLanguage('EN')}>
                <EnglishSvg />

                <Text color={selectedLanguage === 'EN' ? 'currentColor' : 'blackAlpha.600'} fontWeight={selectedLanguage === 'EN' ? 'bold' : 'normal'} ml={3}>
                  {i18n.t('global.langauge_english')}
                </Text>
              </Flex>
              <Flex alignItems='center' cursor='pointer' onClick={() => selectLanguage('DK')}>
                <DanishSvg />

                <Text fontWeight={selectedLanguage === 'DK' ? 'bold' : 'normal'} color={selectedLanguage === 'DK' ? 'currentColor' : 'blackAlpha.600'} ml={3}>
                  {i18n.t('global.langauge_danish')}
                </Text>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  )
}

export default LanguageSelector
