import { t } from 'i18next'

const BASE_BUCKET_URL_FOR_STORE_HEROIMAGE = 'https://storage.googleapis.com/bucket-plenty-dev/StorePage/'
export const findHeroImageForStore = (storeName: string): string => {
  switch (storeName) {
    case 'bestseller_romerhus':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROIMAGE}Roemerhus/HeroImage/HeroImageRoemerhus.webp`
    case 'nameit_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROIMAGE}NameItBruuns/HeroImage/HeroImageNameItBruuns.webp`
    case 'nameit_storcenternord':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROIMAGE}NameItStorCenternord/HeroImage/NameItStorcenterNordHeroImage.webp`
    case 'selected_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROIMAGE}SelectedBruuns/HeroImage/SelectedBruunsHeroImage.webp`
    case 'selected_ryesgade':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROIMAGE}SelectedRyesgade/HeroImage/SelectedRyesgadeHeroImage.webp`
    case 'veromoda_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROIMAGE}VeroModaBruuns/HeroImage/VeroModaHeroImage.webp`
    default:
      return `${BASE_BUCKET_URL_FOR_STORE_HEROIMAGE}Roemerhus/HeroImage/HeroImageRoemerhus.webp`
  }
}
const BASE_BUCKET_URL_FOR_STORE_HEROVIDEO = 'https://storage.googleapis.com/bucket-plenty-dev/StorePage/'
export const findHeroVideoForStore = (storeName: string): string => {
  switch (storeName) {
    case 'bestseller_romerhus':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROVIDEO}Roemerhus/HeroVideo/HeroVideo`
    case 'nameit_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROVIDEO}NameItBruuns/HeroVideo/HeroVideo`
    case 'nameit_storcenternord':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROVIDEO}NameItStorCenternord/HeroVideo/HeroVideo`
    case 'selected_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROVIDEO}SelectedBruuns/HeroVideo/HeroVideo`
    case 'selected_ryesgade':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROVIDEO}SelectedRyesgade/HeroVideo/HeroVideo`
    case 'veromoda_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_HEROVIDEO}VeroModaBruuns/HeroVideo/HeroVideo`
    default:
      return `${BASE_BUCKET_URL_FOR_STORE_HEROVIDEO}Roemerhus/HeroVideo/HeroVideo`
  }
}

type GenderSelectorImages = {
  men?: string
  women?: string
  girl?: string
  boy?: string
  teens?: string
}

const BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE = 'https://storage.googleapis.com/bucket-plenty-dev/StorePage/'
export const FindGenderSaleImagesForStore = (storeName: string): GenderSelectorImages => {
  switch (storeName) {
    case 'bestseller_romerhus':
      return {
        men: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}Roemerhus/GenderSale/RoemerhusMen.webp`,
        women: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}Roemerhus/GenderSale/RoemerhusWomen.webp`,
      }
    case 'nameit_bruuns':
      return {
        girl: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}NameItBruuns/GenderSale/NameItBruunsGirl.webp`,
        boy: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}NameItBruuns/GenderSale/NameItBruunsBoy.webp`,
      }
    case 'nameit_storcenternord':
      return {
        girl: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}NameItStorCenternord/GenderSale/NameItStorcenterNordGirl.webp`,
        boy: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}NameItStorCenternord/GenderSale/NameItStorcenterNordBoy.webp`,
      }
    case 'selected_bruuns':
      return {
        men: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}SelectedBruuns/GenderSale/SelectedBruunsSaleMen.webp`,
        women: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}SelectedBruuns/GenderSale/SelectedBruunsSaleWomen.webp`,
      }
    case 'selected_ryesgade':
      return {
        men: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}SelectedRyesgade/GenderSale/SelectedRyesgadeMenSale.webp`,
        women: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}SelectedRyesgade/GenderSale/SelectedRyesgadeWomenSale.webp`,
      }
    case 'veromoda_bruuns':
      return {
        women: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}VeroModaBruuns/GenderSale/VeroModaWomen.webp`,
        teens: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}VeroModaBruuns/GenderSale/VeroModaTeens.webp`,
      }
    default:
      return {
        men: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}Roemerhus/GenderSale/RoemerhusMen.webp`,
        women: `${BASE_BUCKET_URL_FOR_STORE_BUCKET_IMAGE}Roemerhus/GenderSale/RoemerhusWomen.webp`,
      }
  }
}

type CategorySelectorImagesFromLeftToRight = {
  image1?: string
  image2?: string
  image3?: string
  image4?: string
  label1?: any
  label2?: any
  label3?: any
  label4?: any
  gender1?: string
  gender2?: string
  gender3?: string
  gender4?: string
}
const BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK = 'https://storage.googleapis.com/bucket-plenty-dev/StorePage/'
export const FindTopCategoryPickImagesForStore = (storeName: string): CategorySelectorImagesFromLeftToRight => {
  switch (storeName) {
    case 'bestseller_romerhus':
      return {
        image1: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}Roemerhus/TopCategoryPick/RoemerhusJackets.webp`,
        image2: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}Roemerhus/TopCategoryPick/RoemerhusSweather.webp`,
        image3: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}Roemerhus/TopCategoryPick/RoemerhusTailoring.webp`,
        image4: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}Roemerhus/TopCategoryPick/RoemerhusTops.webp`,
        label1: { displayName: t('main_app.plp.components.category_cloth.Shirts'), navParam: 'clothing/shirts' },
        label2: { displayName: t('main_app.plp.components.category_cloth.Knit'), navParam: 'clothing/knitwearandcardigans' },
        label3: { displayName: t('main_app.plp.components.category_cloth.Tailoring'), navParam: 'clothing/suitsandblazers' },
        label4: { displayName: t('main_app.plp.components.category_cloth.Trousers'), navParam: 'clothing/jeans' },
        gender1: 'Men',
        gender2: 'Women',
        gender3: 'Men',
        gender4: 'Women',
      }
    case 'nameit_bruuns':
      return {
        image1: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}NameItBruuns/TopCategoryPick/NameItBruunsBoyNew.webp`,
        image2: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}NameItBruuns/TopCategoryPick/NameItBruunsDresses.webp`,
        image3: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}NameItBruuns/TopCategoryPick/NameItBruunsGirlNew.webp`,
        image4: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}NameItBruuns/TopCategoryPick/NameItBruunsTops.webp`,
        label1: { displayName: t('main_app.plp.components.category_cloth.Outerwear'), navParam: 'Outerwear' },
        label2: { displayName: t('main_app.plp.components.category_cloth.Snowsuits'), navParam: 'outerwear/wholesuitsandsnowsuits' },
        label3: { displayName: t('main_app.plp.components.category_cloth.Dresses'), navParam: 'clothing/dressesandskirts' },
        label4: { displayName: t('main_app.plp.components.category_cloth.T-Shirts & Tops'), navParam: 'clothing/shirts' },
        gender1: 'kids/boy_2_8',
        gender2: 'kids/boy_2_8',
        gender3: 'kids/girl_2_8',
        gender4: 'kids/boy_2_8',
      }
    case 'nameit_storcenternord':
      return {
        image1: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}NameItStorCenternord/TopCategoryPick/NameItStorcenterNordDresses.webp`,
        image2: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}NameItStorCenternord/TopCategoryPick/NameItStorcenterNordNewBoy.webp`,
        image3: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}NameItStorCenternord/TopCategoryPick/NameItStorcenterNordNewGirl.webp`,
        image4: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}NameItStorCenternord/TopCategoryPick/NameItStorcenterNordTops.webp`,
        label1: { displayName: t('main_app.plp.components.category_cloth.T-Shirts & Tops'), navParam: 'clothing/shirts' },
        label2: { displayName: t('main_app.plp.components.category_cloth.Outerwear'), navParam: 'Outerwear' },
        label3: { displayName: t('main_app.plp.components.category_cloth.Outerwear'), navParam: 'Outerwear' },
        label4: { displayName: t('main_app.plp.components.category_cloth.Trousers'), navParam: 'clothing/trousersandpants' },
        gender1: 'kids/girl_2_8',
        gender2: 'kids/boy_2_8',
        gender3: 'kids/girl_2_8',
        gender4: 'kids/girl_2_8',
      }
    case 'selected_bruuns':
      return {
        image1: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}SelectedBruuns/TopCategoryPick/SelectedBruunsDresses.webp`,
        image2: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}SelectedBruuns/TopCategoryPick/SelectedBruunsNewMen.webp`,
        image3: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}SelectedBruuns/TopCategoryPick/SelectedBruunsNewWomen.webp`,
        image4: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}SelectedBruuns/TopCategoryPick/SelectedBruunsTops.webp`,
        label1: { displayName: t('main_app.plp.components.category_cloth.Dresses'), navParam: 'clothing/dressesandjumpsuits' },
        label2: { displayName: t('main_app.plp.components.category_cloth.Tailoring'), navParam: 'clothing/suitsandblazers' },
        label3: { displayName: t('main_app.plp.components.category_cloth.Skirts'), navParam: 'clothing/shortsandskirts' },
        label4: { displayName: t('main_app.plp.components.category_cloth.Trousers'), navParam: 'clothing/trousersandpants' },
        gender1: 'Women',
        gender2: 'Men',
        gender3: 'Women',
        gender4: 'Women',
      }
    case 'selected_ryesgade':
      return {
        image1: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}SelectedRyesgade/TopCategoryPick/SelectedRyesgadeDresses.webp`,
        image2: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}SelectedRyesgade/TopCategoryPick/SelectedRyesgadeNewMen.webp`,
        image3: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}SelectedRyesgade/TopCategoryPick/SelectedRyesgadeNewWomen.webp`,
        image4: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}SelectedRyesgade/TopCategoryPick/SelectedRyesgadeTops.webp`,
        label1: { displayName: t('main_app.plp.components.category_cloth.Dresses'), navParam: 'clothing/dressesandjumpsuits' },
        label2: { displayName: t('main_app.plp.components.category_cloth.Tailoring'), navParam: 'clothing/suitsandblazers' },
        label3: { displayName: t('main_app.plp.components.category_cloth.Shirts'), navParam: 'clothing/blousesandshirts' },
        label4: { displayName: t('main_app.plp.components.category_cloth.Trousers'), navParam: 'clothing/trousersandpants' },
        gender1: 'Women',
        gender2: 'Men',
        gender3: 'Women',
        gender4: 'Women',
      }
    case 'veromoda_bruuns':
      return {
        image1: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}VeroModaBruuns/TopCategoryPick/VeroModaDresses.webp`,
        image2: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}VeroModaBruuns/TopCategoryPick/VeroModaJackets.webp`,
        image3: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}VeroModaBruuns/TopCategoryPick/VeroModaTops.webp`,
        image4: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}VeroModaBruuns/TopCategoryPick/VeroModaWomenNew.webp`,
        label1: { displayName: t('main_app.plp.components.category_cloth.Skirts'), navParam: 'clothing/shortsandskirts' },
        label2: { displayName: t('main_app.plp.components.category_cloth.Outerwear'), navParam: 'clothing/outerwear' },
        label3: { displayName: t('main_app.plp.components.category_cloth.Knit'), navParam: 'clothing/knitwearandcardigans' },
        label4: { displayName: t('main_app.plp.components.category_cloth.Dresses'), navParam: 'clothing/dressesandjumpsuits' },
        gender1: 'Women',
        gender2: 'Women',
        gender3: 'Women',
        gender4: 'Women',
      }
    default:
      return {
        image1: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}Roemerhus/TopCategoryPick/RoemerhusJackets.webp`,
        image2: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}Roemerhus/TopCategoryPick/RoemerhusSweather.webp`,
        image3: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}Roemerhus/TopCategoryPick/RoemerhusTailoring.webp`,
        image4: `${BASE_BUCKET_URL_FOR_TOP_CATEGORY_PICK}Roemerhus/TopCategoryPick/RoemerhusTops.webp`,
        label1: { displayName: t('main_app.plp.components.category_cloth.Shirts'), navParam: 'clothing/shirts' },
        label2: { displayName: t('main_app.plp.components.category_cloth.Knit'), navParam: 'clothing/knitwearandcardigans' },
        label3: { displayName: t('main_app.plp.components.category_cloth.Tailoring'), navParam: 'clothing/suitsandblazers' },
        label4: { displayName: t('main_app.plp.components.category_cloth.Trousers'), navParam: 'clothing/jeans' },
        gender1: 'Men',
        gender2: 'Women',
        gender3: 'Men',
        gender4: 'Women',
      }
  }
}

export const findCircleText = (storeName: any): any => {
  const BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT = 'https://storage.googleapis.com/bucket-plenty-dev/CircleWithText/'

  switch (storeName) {
    case 'veromoda_bruuns':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}VeroModa_text.png`
    case 'bestseller_romerhus':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}Bestseller_text.png`
    case 'selected_bruuns':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}Selected_text.png`

    case 'selected_ryesgade':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}Selected_text.png`
    case 'nameit_bruuns':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}NameIt_text.png`

    case 'nameit_storcenternord':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}NameIt_text.png`
    // case 5684:
    //   return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}Pieces.png`; // Uncomment and modify this line if you have the image in the future
    default:
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}VeroModa_text.png`
  }
}

export const findStoreLabelIcon = (storeName: any): any => {
  const BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT = 'https://storage.googleapis.com/bucket-plenty-dev/BrandNameLabel/'
  switch (storeName) {
    case 'Vero Moda Bruuns Galleri':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}veromoda.png`
    case 'Bestseller Rømerhus':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}bestseller.png`
    case 'Selected Bruuns Galleri':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}selected.png`

    case 'Selected Ryesgade':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}selected.png`
    case 'NAME IT Bruuns Galleri':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}nameit.png`

    case 'NAME IT Storcenter Nord':
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}nameit.png`
    default:
      return `${BASE_BUCKET_URL_FOR_CIRCLE_WITH_TEXT}bestseller.png`
  }
}

export const findColoredCircleText = (storeName: string): string => {
  const BASE_BUCKET_URL_FOR_COLORED_CIRCLE_WITH_TEXT = 'https://storage.googleapis.com/bucket-plenty-dev/CircleWithText/'

  switch (storeName) {
    case 'veromoda_bruuns':
      return `${BASE_BUCKET_URL_FOR_COLORED_CIRCLE_WITH_TEXT}VeroModa_Color_text.png`
    case 'bestseller_romerhus':
      return `${BASE_BUCKET_URL_FOR_COLORED_CIRCLE_WITH_TEXT}Bestseller_Color_text.png`
    case 'selected_bruuns':
      return `${BASE_BUCKET_URL_FOR_COLORED_CIRCLE_WITH_TEXT}Selected_Color_text.png`

    case 'selected_ryesgade':
      return `${BASE_BUCKET_URL_FOR_COLORED_CIRCLE_WITH_TEXT}Selected_Color_text.png`
    case 'nameit_bruuns':
      return `${BASE_BUCKET_URL_FOR_COLORED_CIRCLE_WITH_TEXT}NameIt_Color_text.png`

    case 'nameit_storcenternord':
      return `${BASE_BUCKET_URL_FOR_COLORED_CIRCLE_WITH_TEXT}NameIt_Color_text.png`
    default:
      return `${BASE_BUCKET_URL_FOR_COLORED_CIRCLE_WITH_TEXT}VeroModa_Color_text.png`
  }
}

export const findStoreBanner = (storeName: string): string => {
  const BASE_BUCKET_URL_FOR_STORE_IMAGES = 'https://storage.googleapis.com/bucket-plenty-dev/StoreImages/'
  switch (storeName) {
    case 'Vero Moda Bruuns Galleri':
      return `${BASE_BUCKET_URL_FOR_STORE_IMAGES}VeroModa_Bruuns.webp`
    case 'Bestseller Rømerhus':
      return `${BASE_BUCKET_URL_FOR_STORE_IMAGES}Bestseller_Roemerhus.webp`
    case 'Selected Bruuns Galleri':
      return `${BASE_BUCKET_URL_FOR_STORE_IMAGES}Selected_Bruuns.webp`
    case 'Selected Ryesgade':
      return `${BASE_BUCKET_URL_FOR_STORE_IMAGES}Selected_Ryesgade.webp`
    case 'NAME IT Bruuns Galleri':
      return `${BASE_BUCKET_URL_FOR_STORE_IMAGES}NameIt_Bruuns.webp`
    case 'NAME IT Storcenter Nord':
      return `${BASE_BUCKET_URL_FOR_STORE_IMAGES}NameIt_StorcenterNord.webp`
    default:
      return `${BASE_BUCKET_URL_FOR_STORE_IMAGES}Selected_Ryesgade.webp`
  }
}

export const findHeroStoreBanner = (storeName: string): string => {
  const BASE_BUCKET_URL_FOR_STORE_BANNERS = 'https://storage.googleapis.com/bucket-plenty-dev/storesBanners/'

  switch (storeName) {
    case 'veromoda_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_BANNERS}StoreLanding_Bestseller_Vero_Moda_Bruuns.webp`
    case 'bestseller_romerhus':
      return `${BASE_BUCKET_URL_FOR_STORE_BANNERS}StoreLanding_Bestseller_Roemerhus.webp`
    case 'selected_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_BANNERS}StoreLanding_Selected_Bruuns.webp`
    case 'selected_ryesgade':
      return `${BASE_BUCKET_URL_FOR_STORE_BANNERS}StoreLanding_Selected_Ryesgade.webp`
    case 'nameit_bruuns':
      return `${BASE_BUCKET_URL_FOR_STORE_BANNERS}StoreLanding_Name_It_Bruuns.webp`
    case 'nameit_storcenternord':
      return `${BASE_BUCKET_URL_FOR_STORE_BANNERS}StoreLanding_Name_It_Storecenter_Nord.webp`
    default:
      return ``
  }
}

const BASE_BUCKET_URL_FOR_SUPPORT_LOCAL = 'https://storage.googleapis.com/bucket-plenty-dev/SupportLocals/'

export const findSupportLocal = (storeName: string): string => {
  switch (storeName) {
    case 'veromoda_bruuns':
      return `${BASE_BUCKET_URL_FOR_SUPPORT_LOCAL}VeroModa_Bruuns.webp`
    case 'bestseller_romerhus':
      return `${BASE_BUCKET_URL_FOR_SUPPORT_LOCAL}Bestseller_Roemerhus.webp`

    case 'selected_bruuns':
      return `${BASE_BUCKET_URL_FOR_SUPPORT_LOCAL}Selected_Bruuns.webp`
    case 'selected_ryesgade':
      return `${BASE_BUCKET_URL_FOR_SUPPORT_LOCAL}Selected_Ryesgade.webp`
    case 'nameit_bruuns':
      return `${BASE_BUCKET_URL_FOR_SUPPORT_LOCAL}NameIt_Bruuns.webp`
    case 'nameit_storcenternord':
      return `${BASE_BUCKET_URL_FOR_SUPPORT_LOCAL}NameIt_StorcenterNord.webp`
    default:
      return `${BASE_BUCKET_URL_FOR_SUPPORT_LOCAL}Bestseller_Roemerhus.webp`
  }
}

const BASE_BUCKET_URL_FOR_EMPLOYEE_SPOTLIGHT = 'https://storage.googleapis.com/bucket-plenty-dev/StorePage/'

export const findEmployeeSpotlight = (storeName: string): { imageUrl: string; styleNumbers: number[]; storeDisplayUrl: string } => {
  switch (storeName) {
    case 'veromoda_bruuns':
      return {
        imageUrl: `${BASE_BUCKET_URL_FOR_EMPLOYEE_SPOTLIGHT}VeroModaBruuns/EmployeeSpotlight/VeroModa.png`,
        styleNumbers: [
          10304338, 10302774, 10299026, 10305384, 10309038, 10282899, 10290541, 10298193, 10284343, 10298849, 10293241, 10298726, 10308736, 10292786,
        ],
        storeDisplayUrl: 'veromoda_bruuns',
      }
    case 'bestseller_romerhus':
      return {
        imageUrl: `${BASE_BUCKET_URL_FOR_EMPLOYEE_SPOTLIGHT}Roemerhus/EmployeeSpotlight/Roemerhus.png`,
        styleNumbers: [12243494, 12206024, 16084080, 12236082, 12219634, 12232815, 16081323, 22027150, 26031613, 23044248, 12203894, 23033769, 23044398],
        storeDisplayUrl: 'bestseller_romerhus',
      }

    case 'selected_bruuns':
      return {
        imageUrl: `${BASE_BUCKET_URL_FOR_EMPLOYEE_SPOTLIGHT}SelectedBruuns/EmployeeSpotlight/SelectedBruuns.png`,
        styleNumbers: [
          16091815, 16077359, 16090154, 16092579, 16087665, 16090493, 16090345, 16091002, 16092846, 16091321, 16091322, 16090416, 16090094, 16091089, 16091091,
          16091148,
        ],
        storeDisplayUrl: 'selected_bruuns',
      }
    case 'selected_ryesgade':
      return {
        imageUrl: `${BASE_BUCKET_URL_FOR_EMPLOYEE_SPOTLIGHT}SelectedRyesgade/EmployeeSpotlight/SelectedRyesgade.png`,
        styleNumbers: [
          16090338, 16090274, 16088419, 16063204, 16078946, 16091321, 16091322, 16088431, 16090094, 16091003, 16086175, 16084318, 16093600, 16081851, 16091025,
          16077359, 16092550, 16085258, 16085257, 16090493, 16090488, 16090154,
        ],
        storeDisplayUrl: 'selected_ryesgade',
      }
    case 'nameit_bruuns':
      return {
        imageUrl: `${BASE_BUCKET_URL_FOR_EMPLOYEE_SPOTLIGHT}NameItBruuns/EmployeeSpotlight/NameItBruuns.png`,
        styleNumbers: [
          13214889, 13217188, 13192399, 13217037, 13214454, 13214455, 13198040, 13214494, 13198041, 13220380, 13220417, 13216422, 13218544, 13219923, 13177337,
          13198423, 13147424, 13211632, 13211699, 13228801,
        ],
        storeDisplayUrl: 'nameit_bruuns',
      }
    case 'nameit_storcenternord':
      return {
        imageUrl: `${BASE_BUCKET_URL_FOR_EMPLOYEE_SPOTLIGHT}NameItStorCenternord/EmployeeSpotlight/NameItStorcenterNord.png`,
        styleNumbers: [
          13214449, 13213416, 13211699, 13218791, 13198041, 13218753, 13209038, 13220252, 13218873, 13218346, 13226604, 13219683, 13218804, 13222450, 13227536,
          13225281, 13218812, 13218544,
        ],
        storeDisplayUrl: 'nameit_storcenternord',
      }
    default:
      return {
        imageUrl: `${BASE_BUCKET_URL_FOR_EMPLOYEE_SPOTLIGHT}Roemerhus/EmployeeSpotlight/Roemerhus.png`,
        styleNumbers: [
          16092550, 16091815, 16090182, 16077359, 16090154, 16092579, 16090494, 16087665, 16090154, 16090488, 16087654, 16090338, 16090274, 16088419, 16063204,
          16091003,
        ],
        storeDisplayUrl: 'bestseller_romerhus',
      }
  }
}

export const findCategory = (category: string): string => {
  const BASE_BUCKET_URL_FOR_CATEGORY = 'https://storage.googleapis.com/bucket-plenty-dev/Categories'

  switch (category) {
    case t('components.category_cards.footwear'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Footwear.jpg`
    case t('components.category_cards.accessories'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Accessories.jpg`
    case t('components.category_cards.bags'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Bags.jpg`
    case t('components.category_cards.jeans'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Jeans.jpg`
    case t('components.category_cards.knit'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Knit.jpg`
    case t('components.category_cards.outerwear'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Outerwear.jpg`
    case t('components.category_cards.dresses'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Dresses.jpg`
    case t('components.category_cards.shirt'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Shirt.jpg`
    case t('components.category_cards.shorts'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Shorts.jpg`
    case t('components.category_cards.sweatshirt'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Sweatshirt.jpg`
    case t('components.category_cards.swimwear'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Swimwear.jpg`
    case t('components.category_cards.tshirts'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_TShirts.jpg`
    case t('components.category_cards.trousers'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Trousers.jpg`
    case t('components.category_cards.tailoring'):
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Tailoring.jpg`
    default:
      return ''
  }
}

export const findCategoryByGenderKey = (category: string): string => {
  const BASE_BUCKET_URL_FOR_CATEGORY = 'https://storage.googleapis.com/bucket-plenty-dev/Categories'

  switch (category) {
    case 'women-shoes':
    case 'men-shoes':
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Footwear.jpg`

    case 'women-accessories':
    case 'men-accessories':
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Accessories.jpg`
    case 'women-clothing':
    case 'men-clothing':
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Footwear.jpg`

    case 'women-bags':
      return `${BASE_BUCKET_URL_FOR_CATEGORY}/Shopping_Bags.jpg`

    default:
      return ''
  }
}
type ImageDictionary = {
  [gender: string]: {
    [itemName: string]: {
      imageUrl: string
      url: string
      label: string
      heading?: any
    }
  }
}

export const findImagesByGender = (gender: 'men' | 'women' | 'kids'): Record<string, any> => {
  const BASE_BUCKET_URL_FOR_CATEGORY = 'https://storage.googleapis.com/bucket-plenty-dev/gender_home_page'

  const images: ImageDictionary = {
    men: {
      Clothing: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Men/Clothing.webp`,
        url: '/products/men/clothing',
        label: t('main_app.find_images_by_gender.clothing'),
      },
      Accessories: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Men/Accessories.webp`,
        url: '/products/men/accessories',
        label: t('main_app.find_images_by_gender.accessories'),
      },
      Outerwear: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Men/Outerwear.webp`,
        url: '/products/men/outerwear',
        label: t('main_app.find_images_by_gender.outerwear'),
      },
      Shirts: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Men/Shirts.webp`,
        url: '/products/men/clothing/shirts',
        label: t('main_app.find_images_by_gender.shirts'),
      },
      Trousers: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Men/Trousers.webp`,
        url: '/products/men/clothing/jeans',
        label: t('main_app.find_images_by_gender.trousers'),
      },
    },
    women: {
      Clothing: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Women/Clothing1.webp`,
        url: '/products/women/clothing',
        label: t('main_app.find_images_by_gender.clothing'),
      },
      Accessories: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Women/Accessories.webp`,
        url: '/products/women/accessories',
        label: t('main_app.find_images_by_gender.accessories'),
      },
      Outerwear: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Women/Outerwear1.webp`,
        url: '/products/women/outerwear',
        label: t('main_app.find_images_by_gender.outerwear'),
      },
      Dresses: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Women/Dresses.webp`,
        url: '/products/women/clothing/dressesandjumpsuits',
        label: t('main_app.find_images_by_gender.dresses'),
      },
      Tops: {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Women/Tops.webp`,
        url: '/products/women/clothing/tshirtsandtops',
        label: t('main_app.find_images_by_gender.tops'),
      },
    },
    kids: {
      'Baby 0 2': {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Kids/Baby.webp`,
        url: '/products/kids/baby_0_2',
        heading: t('main_app.find_images_by_gender.baby'),
        label: t('main_app.find_images_by_gender.baby_age'),
      },
      'Girl 2 8': {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Kids/Girl2_8.webp`,
        url: '/products/kids/girl_2_8',
        heading: t('main_app.find_images_by_gender.girl_young'),
        label: t('main_app.find_images_by_gender.girl_young_age'),
      },
      'Boy 2 8': {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Kids/Boy2_8.webp`,
        url: '/products/kids/boy_2_8',
        heading: t('main_app.find_images_by_gender.boy_young'),
        label: t('main_app.find_images_by_gender.boy_young_age'),
      },
      'Girl 9 16': {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Kids/Girl9_16.webp`,
        url: '/products/kids/girl_9_16',
        heading: t('main_app.find_images_by_gender.girl_old'),
        label: t('main_app.find_images_by_gender.girl_old_age'),
      },
      'Boy 9 16': {
        imageUrl: `${BASE_BUCKET_URL_FOR_CATEGORY}/Kids/Boy9_16.webp`,
        url: '/products/kids/boy_9_16',
        heading: t('main_app.find_images_by_gender.boy_old'),
        label: t('main_app.find_images_by_gender.boy_old_age'),
      },
    },
  }

  return images[gender] || {}
}

type BrandDictionary = {
  [gender: string]: {
    [itemName: string]: {
      imageUrl: string
      url: string
      labelUrl: string
    }
  }
}

export const findBrandImageAndLabel = (gender: 'men' | 'women' | 'kids'): Record<string, any> => {
  const BASE_BUCKET_URL_FOR_BRAND_IMAGE = 'https://storage.googleapis.com/bucket-plenty-dev/BrandSlider'
  const BASE_BUCKET_URL_FOR_BRAND_LABEL = 'https://storage.googleapis.com/bucket-plenty-dev/BrandNameLabel'

  const images: BrandDictionary = {
    men: {
      'Jones&Jones': {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/men/jack&jones.webp`,
        url: '/products/men?brand=JACK%26JONES',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/jack&jones.png`,
      },
      RDD: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/men/rdd.webp`,
        url: '/products/men?brand=R.D.D. ROYAL DENIM DIVISION',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/rdd.png`,
      },
      Selected: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/men/selected.webp`,
        url: '/products/men?brand=SELECTED HOMME',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/selectedHomme.png`,
      },
      'only&sons': {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/men/only&sons.webp`,
        url: '/products/men?brand=ONLY%20%26%20SONS',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/only&sons.png`,
      },
    },
    women: {
      veromoda: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/veromoda.webp`,
        url: '/products/women?brand=VERO MODA',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/veromodaWhite.png`,
      },
      selectedFem: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/selectedFem.webp`,
        url: '/products/women?brand=SELECTED FEMME',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/selectedFem.png`,
      },
      pieces: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/pieces.webp`,
        url: '/products/women?brand=PIECES',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/pieces.png`,
      },
      noisyMay: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/noisyMay.webp`,
        url: '/products/women?brand=NOISY MAY',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/noisyMay.png`,
      },
      jdy: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/jdy.webp`,
        url: '/products/women?brand=JDY',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/jdy.png`,
      },
      somethingNew: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/somethingNew.webp`,
        url: '/products/women?brand=SOMETHINGNEW',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/somethingNew.png`,
      },
      yas: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/yas.webp`,
        url: '/products/women?brand=Y.A.S',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/yas.png`,
      },
      vila: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/vila.webp`,
        url: '/products/women?brand=VILA',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/vila.png`,
      },
      object: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/object.webp`,
        url: '/products/women?brand=OBJECT COLLECTORS ITEM',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/object.png`,
      },
      only: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/only.webp`,
        url: '/products/women?brand=ONLY',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/only.png`,
      },
      jjxx: {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/women/jjxx.webp`,
        url: '/products/women?brand=JJXX',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/jjxx.png`,
      },
    },
    kids: {
      'Baby 0 2': {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/kids/lilAtelier.webp`,
        url: '/products/kids?brand=Lil’%20Atelier',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/lilAtelier.png`,
      },
      'Girl 2 8': {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/kids/veromoda.webp`,
        url: '/products/kids?brand=VERO MODA GIRL',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/veromodaGirl.png`,
      },
      'Boy 2 8': {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/kids/nameit.webp`,
        url: '/products/kids?brand=NAME IT',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/nameitWhite.png`,
      },
      'Girl 9 16': {
        imageUrl: `${BASE_BUCKET_URL_FOR_BRAND_IMAGE}/kids/lmtd.webp`,
        url: '/products/kids?brand=LMTD',
        labelUrl: `${BASE_BUCKET_URL_FOR_BRAND_LABEL}/lmtd.png`,
      },
    },
  }

  return images[gender] || {}
}
export const getPaymentImageUrls = () => {
  const BASE_URL = 'https://storage.googleapis.com/bucket-plenty-dev/PayingMethods'
  const paymentMethods = ['MobilePay', 'Dankort', 'Visa', 'Mastercard']

  return paymentMethods.map((method) => `${BASE_URL}/${method}.png`)
}

type SeasonalSaleCardImagesFromLeftToRight = {
  image1?: string
  image2?: string
  image3?: string
  image4?: string
  label1?: any
  label2?: any
  label3?: any
  label4?: any
  heading1?: any
  heading2?: any
  heading3?: any
  heading4?: any
}

const BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD = 'https://storage.googleapis.com/bucket-plenty-dev/SeasonalSaleCard/'

export const FindSeasonalSaleCardImage = (gender: string): SeasonalSaleCardImagesFromLeftToRight => {
  switch (gender) {
    case 'men':
      return {
        image1: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}men/Jackets.webp`,
        image2: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}men/Denims.webp`,
        image3: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}men/Shirts.webp`,
        image4: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}men/Knitwear.webp`,
        label1: { displayName: t('main_app.seasonal_sale_card.men_cards.text_card_1'), navParam: 'outerwear' },
        label2: { displayName: t('main_app.seasonal_sale_card.men_cards.text_card_2'), navParam: 'clothing/jeans' },
        label3: { displayName: t('main_app.seasonal_sale_card.men_cards.text_card_3'), navParam: 'clothing/shirts' },
        label4: { displayName: t('main_app.seasonal_sale_card.men_cards.text_card_4'), navParam: 'clothing/knitwearandpullovers' },
        heading1: t('main_app.seasonal_sale_card.men_cards.heading_card_1'),
        heading2: t('main_app.seasonal_sale_card.men_cards.heading_card_2'),
        heading3: t('main_app.seasonal_sale_card.men_cards.heading_card_3'),
        heading4: t('main_app.seasonal_sale_card.men_cards.heading_card_4'),
      }
    case 'kids':
      return {
        image1: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}kids/Outdoor.webp`,
        image2: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}kids/Knitwear.webp`,
        image3: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}kids/Denim.webp`,
        image4: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}kids/Dresses.webp`,
        label1: { displayName: t('main_app.seasonal_sale_card.kids_cards.text_card_1'), navParam: 'girl_2_8/outerwear' },
        label2: { displayName: t('main_app.seasonal_sale_card.kids_cards.text_card_2'), navParam: 'girl_2_8/clothing/knitwearandcardigans' },
        label3: { displayName: t('main_app.seasonal_sale_card.kids_cards.text_card_3'), navParam: 'boy_2_8/clothing/trousersandpants' },
        label4: { displayName: t('main_app.seasonal_sale_card.kids_cards.text_card_4'), navParam: 'girl_2_8/clothing/dressesandskirts' },
        heading1: t('main_app.seasonal_sale_card.kids_cards.heading_card_1'),
        heading2: t('main_app.seasonal_sale_card.kids_cards.heading_card_2'),
        heading3: t('main_app.seasonal_sale_card.kids_cards.heading_card_3'),
        heading4: t('main_app.seasonal_sale_card.kids_cards.heading_card_4'),
      }

    case 'women':
      return {
        image1: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}women/Jackets.webp`,
        image2: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}women/Dresses.webp`,
        image3: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}women/Denims.webp`,
        image4: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}women/Knitwear.webp`,
        label1: { displayName: t('main_app.seasonal_sale_card.women_cards.text_card_1'), navParam: 'outerwear' },
        label2: { displayName: t('main_app.seasonal_sale_card.women_cards.text_card_2'), navParam: 'clothing/dressesandjumpsuits' },
        label3: { displayName: t('main_app.seasonal_sale_card.women_cards.text_card_3'), navParam: 'clothing/trousersandpants' },
        label4: { displayName: t('main_app.seasonal_sale_card.women_cards.text_card_4'), navParam: 'clothing/knitwearandcardigans' },
        heading1: t('main_app.seasonal_sale_card.women_cards.heading_card_1'),
        heading2: t('main_app.seasonal_sale_card.women_cards.heading_card_2'),
        heading3: t('main_app.seasonal_sale_card.women_cards.heading_card_3'),
        heading4: t('main_app.seasonal_sale_card.women_cards.heading_card_4'),
      }
    default:
      return {
        image1: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}women/jackets.webp`,
        image2: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}women/dresses.webp`,
        image3: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}women/trousers.webp`,
        image4: `${BASE_BUCKET_URL_FOR_SEASONAL_SALE_CARD}women/knitwear.webp`,
        label1: { displayName: t('main_app.seasonal_sale_card.women_cards.text_card_1'), navParam: 'clothing/coatsandjackets' },
        label2: { displayName: t('main_app.seasonal_sale_card.women_cards.text_card_2'), navParam: 'clothing/dressesandjumpsuits' },
        label3: { displayName: t('main_app.seasonal_sale_card.women_cards.text_card_3'), navParam: 'clothing/trousersandleggings' },
        label4: { displayName: t('main_app.seasonal_sale_card.women_cards.text_card_4'), navParam: 'clothing/knitwear' },
        heading1: t('main_app.seasonal_sale_card.women_cards.heading_card_1'),
        heading2: t('main_app.seasonal_sale_card.women_cards.heading_card_2'),
        heading3: t('main_app.seasonal_sale_card.women_cards.heading_card_3'),
        heading4: t('main_app.seasonal_sale_card.women_cards.heading_card_4'),
      }
  }
}


const BASE_BUCKET_URL_FOR_DROP_DOWN_MENU= 'https://storage.googleapis.com/bucket-plenty-dev/DropDownMenu/'
export const FindImageForDropDownMenu = (gender: string): string => {
  switch (gender) {
    case 'men':
      return `${BASE_BUCKET_URL_FOR_DROP_DOWN_MENU}Men.webp`
    case 'women':
      return `${BASE_BUCKET_URL_FOR_DROP_DOWN_MENU}Women.webp`
    case 'kids':
      return `${BASE_BUCKET_URL_FOR_DROP_DOWN_MENU}Kids.webp`
  
    default:
      return ''
  }
}