import { Box, Image, useMediaQuery } from '@chakra-ui/react'
import { ProductProjection, Store } from '@commercetools/platform-sdk'
import { PlentyVariant, ProductVariantAttribute } from '../PLP/types'
import { ColorPlentyVariant, PlentyProductWithAvailableVariants, PlentyStore } from './types'
import { centPriceToPrice } from '../../../utils/MoneyUtil'
import { useState } from 'react'
import { findProductVariantAttributeDefaultEnglish } from '../../../utils/ProductUtil'

const ZoomableImage = ({ src }: { src: string }) => {
  const [origin, setOrigin] = useState('0% 0%')
  const [isZoomed, setIsZoomed] = useState(false)
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)') // Check if screen width is larger than 768px (desktop)

  const handleImageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isLargerThan768) return // Do not execute zoom logic on smaller screens

    if (!isZoomed) {
      // Calculate origin only when zooming in
      const { left, top, width, height } = event.currentTarget.getBoundingClientRect()
      let x = ((event.clientX - left) / width) * 100
      let y = ((event.clientY - top) / height) * 100

      // Prevent zooming in on the very edges of the image
      y = Math.max(5, Math.min(95, y))
      x = Math.max(5, Math.min(95, x))

      setOrigin(`${x}% ${y}%`)
    }

    setIsZoomed(!isZoomed) // Toggle zoom state
  }

  return (
    <Box
      m={{ base: '1rem', sm: '1rem', md: '0' }}
      borderRadius={'1.5rem'}
      overflow='hidden'
      onClick={handleImageClick} // Add click handler
      cursor={isLargerThan768 ? (isZoomed ? 'zoom-out' : 'zoom-in') : 'default'} // Change cursor to zoom icon
    >
      <Box
        transition='transform 0.3s ease-in-out'
        transform={isZoomed ? 'scale(2.5)' : 'scale(1)'} // Apply zoom effect based on state
        transformOrigin={origin} // Maintain the same origin for zooming in and out
      >
        <Image src={src} />
      </Box>
    </Box>
  )
}
export const buildImages = (
  productImages: Array<{
    url: string
  }>,
) => {
  return productImages.map((productImage: { url: string }) => {
    return {
      original: productImage.url,
      thumbnail: productImage.url,
      renderItem: () => {
        return <ZoomableImage src={productImage.url} />
      },
      renderThumbInner: () => {
        return (
          <Box>
            <Image _hover={{ cursor: 'pointer' }} w={'95px'} borderRadius={'1.5rem'} src={productImage.url} />
          </Box>
        )
      },
    }
  })
}

export const parseCategorySlugToURL = (slug: string): string => {
  return slug.replace(/-/g, '/')
}

export const formatPlentyProduct = (product: ProductProjection): PlentyProductWithAvailableVariants => {
  const allVariants = product.variants.concat(product.masterVariant)
  const availableVariants = allVariants.filter(
    (variant) => variant.availability?.channels && Object.values(variant.availability.channels).some((channel) => channel.isOnStock),
  )
  const variant = allVariants.find((v) => v.isMatchingVariant === true)
  // Default to the existing masterVariant
  const masterVariant = variant ? formatPlentyVariant(variant) : formatPlentyVariant(product.masterVariant)

  return {
    id: product.id,
    key: product.key!,
    // name: product.name.en,
    name: { en: product.name.en },
    masterVariant,
    variants: product.variants.map((variant) => formatPlentyVariant(variant)),
    availableVariants: availableVariants.map((variant) => formatPlentyVariant(variant)),
  }
}

export const formatPlentyProductForSliders = (product: ProductProjection): PlentyProductWithAvailableVariants => {
  const allVariants = product.variants.concat(product.masterVariant)
  const availableVariants = allVariants.filter(
    (variant) => variant.availability?.channels && Object.values(variant.availability.channels).some((channel) => channel.isOnStock),
  )
  // Default to the existing masterVariant
  const masterVariant = formatPlentyVariant(availableVariants[0])

  return {
    id: product.id,
    key: product.key!,
    // name: product.name.en,
    name: { en: product.name.en },
    masterVariant,
    variants: product.variants.map((variant) => formatPlentyVariant(variant)),
    availableVariants: availableVariants.map((variant) => formatPlentyVariant(variant)),
  }
}

export const formatPlentyVariant = (variant: any): PlentyVariant => {
  return {
    id: variant.id,
    sku: variant.sku,
    key: variant.key,
    availability: variant.availability,
    images: variant.images,
    prices: variant.prices,
    attributes: variant.attributes,
  }
}
const sortOrder = ['XXS', 'XS', 'XS/S', 'S', 'S/M', 'M', 'M/L', 'L', 'L/XL', 'XL', 'XXL', '2XL', 'XXXL', '3XL']
const extractStartingNumber = (term: string) => {
  const match = term.match(/^(\d+)-?/)
  return match ? Number(match[1]) : null
}

export const sortPlentyVariantsBySize = (variants: PlentyVariant[] | undefined) => {
  return variants?.sort((a, b) => {
    const termA = findProductVariantAttributeDefaultEnglish(a, 'size')
    const termB = findProductVariantAttributeDefaultEnglish(b, 'size')

    const isNumericA = !isNaN(Number(termA))
    const isNumericB = !isNaN(Number(termB))

    const startingNumberA = extractStartingNumber(termA)
    const startingNumberB = extractStartingNumber(termB)

    if (startingNumberA !== null && startingNumberB !== null) {
      return startingNumberA - startingNumberB
    }

    if (isNumericA && isNumericB) {
      return Number(termA) - Number(termB)
    }

    if (!isNumericA && !isNumericB) {
      const indexA = sortOrder.indexOf(termA)
      const indexB = sortOrder.indexOf(termB)

      if (indexA === -1 && indexB === -1) {
        return termA.localeCompare(termB)
      }
      if (indexA === -1) return 1
      if (indexB === -1) return -1

      return indexA - indexB
    }

    return isNumericA ? 1 : -1
  })
}

export const getRetailPrice = (variant?: PlentyVariant): number => {
  const price = variant?.prices?.find((price) => price.value.currencyCode === 'DKK')
  if (!price) return -1
  return centPriceToPrice(price?.value.centAmount, 2) || 0
}

export const getAvailableStores = (product: PlentyProductWithAvailableVariants, stores: Store[]): PlentyStore[] => {
  const availableVariantsInStores: Record<string, string[]> = {}

  const variants = product.availableVariants

  for (const variant of variants) {
    const { availability } = variant

    if (availability && availability.channels) {
      for (const [storeId, channelAvailability] of Object.entries(availability.channels)) {
        if (channelAvailability.isOnStock) {
          availableVariantsInStores[storeId] = availableVariantsInStores[storeId] || []
          availableVariantsInStores[storeId].push(variant.key!)
        }
      }
    }
  }

  const mapStoreToPlentyStore = (store: Store): PlentyStore => {
    const distributionChannel = store.distributionChannels[0]
    const channelId = distributionChannel.id

    return {
      id: store.id,
      key: store.key,
      channelId,
      name: store.name!.en,
      variantsAvailable: availableVariantsInStores[channelId] || [],
      distributionChannel: {
        key: distributionChannel.obj?.key || '',
        address: {
          streetName: distributionChannel.obj?.address?.streetName || 'No address found',
          streetNumber: distributionChannel.obj?.address?.streetNumber || 'No street number found',
          city: distributionChannel.obj?.address?.city || 'No city found',
        },
      },
    }
  }

  return stores.map(mapStoreToPlentyStore).filter((store) => store.variantsAvailable.length > 0)
}

export const getVariantBasedOnMatching = (product: ProductProjection): PlentyVariant => {
  const allVariants = product.variants.concat(product.masterVariant)
  const variant = allVariants.find((v) => v.isMatchingVariant === true)
  if (!variant) return formatPlentyVariant(product.masterVariant)
  return formatPlentyVariant(variant)
}

/**
 * Filters stores based on the selected option.
 *
 * @param selectedOption - The currently selected product variant
 * @param allStores - A list of all stores
 * @returns A filtered list of stores that have the selected option available.
 */
export const filterStoresBasedOnColor = (selectedColor: ColorPlentyVariant, allStores: PlentyStore[]): PlentyStore[] => {
  // Assuming each store has a 'variantsAvailable' field that lists available variants.
  return allStores.filter((store) => {
    return selectedColor.storeChannelIds.includes(store.channelId)
  })
}

export const filterSizesBasedOnStore = (selectedStore: PlentyStore, allSizes: PlentyVariant[]): PlentyVariant[] => {
  // Assuming each store has a 'variantsAvailable' field that lists available variants.
  return allSizes.filter((size) => {
    return selectedStore.variantsAvailable.includes(size.key!)
  })
}

const isVariantAvailable = (variant: PlentyVariant): boolean => {
  const availability = variant.availability
  const isChannelStockAvailable = availability?.channels ? Object.values(availability.channels).some((channel) => channel.isOnStock) : false
  return isChannelStockAvailable
}

export const getAvailableVariantsAcrossAllStoresByColor = (product: PlentyProductWithAvailableVariants): ColorPlentyVariant[] => {
  const allVariants = product.variants.concat(product.masterVariant)
  return allVariants.reduce((acc: Array<ColorPlentyVariant>, variant: PlentyVariant) => {
    const available = isVariantAvailable(variant)

    // If variant is not available, skip the rest of the logic for this iteration
    if (!available) {
      return acc
    }

    const color = findProductVariantAttributeDefaultEnglish(variant, 'color')
    const variantId = findProductVariantAttributeDefaultEnglish(variant, 'variant_id')
    const stores = variant.availability?.channels ? Object.keys(variant.availability.channels) : []

    const existingEntry = acc.find((it: ColorPlentyVariant) => it.color === color && it.variantId === variantId)

    if (existingEntry) {
      existingEntry.variants.push(variant)

      // Merge existing storeChannelIds with new stores and remove duplicates
      existingEntry.storeChannelIds = Array.from(new Set([...existingEntry.storeChannelIds, ...stores]))
    } else {
      acc.push({ color, variantId, variants: [variant], storeChannelIds: stores })
    }

    return acc
  }, [])
}
