import { Flex, Icon, Table, Tag, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react'
import { findProductVariantAttributeDefaultEnglish } from '../../../../../utils/ProductUtil'
import { PlentyVariant } from '../../../../main_app/PLP/types'
import { ImageRow, PriceRow, ProductNameRow, SelectorRow, SizeRow } from './SubRow'

type ProductsTableProps = {
  columns: any
  table: any
  whoAmI: any
}

export const ProductsTable = (props: ProductsTableProps) => {
  const checkCriteria = (plentyVariant: PlentyVariant) => {
    const { availability } = plentyVariant

    if (availability?.availableQuantity) {
      return true
    }

    const channels = availability?.channels

    if (channels) {
      const channelId = props.whoAmI.data?.whoAmI?.commercetoolsDistributionChannelId ?? ''
      return channels[channelId] !== undefined
    }

    return false
  }

  const calculateStock = (plentyVariant: PlentyVariant) => {
    if (plentyVariant.availability && props.whoAmI.data?.whoAmI?.commercetoolsDistributionChannelId) {
      return plentyVariant.availability.channels![props.whoAmI.data?.whoAmI?.commercetoolsDistributionChannelId ?? ''].availableQuantity
    }
  }

  return (
    <Table {...props.table.getTableProps} variant={'unstyled'}>
      <Thead>
        {props.table.headerGroups.map((headerGroup: any, index: number) => {
          return (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any, index: number) => {
                return (
                  <Th h={'50px'} alignItems={'center'} {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                    <Flex justifyContent={'space-between'}>
                      {column.render('Header')}
                      <Flex h={5} w={5}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon h={5} w={5} as={IconSortDescending} />
                          ) : (
                            <Icon h={5} w={5} as={IconSortAscending} />
                          )
                        ) : null}
                      </Flex>
                    </Flex>
                  </Th>
                )
              })}
            </Tr>
          )
        })}
      </Thead>
      <Tbody bgColor={'#edede766'} border={0} color={'blackAlpha.600'} fontSize={'sm'} {...props.table.getTableBodyProps}>
        {props.table.page.map((row: any, index: number) => {
          props.table.prepareRow(row)
          return (
            <>
              <Tr
                key={index}
                _hover={{
                  bgColor: 'blackAlpha.50',
                }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <Td key={index} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
              </Tr>
              {row.isExpanded
                ? row.original.variants.map((variant: PlentyVariant) => {
                    if (checkCriteria(variant)) {
                      return (
                        <Tr
                          key={index}
                          bgColor={'#E9E9E4'}
                          _hover={{
                            bgColor: '#edede780',
                          }}
                        >
                          <Td></Td>
                          <Td>
                            <SelectorRow />
                          </Td>
                          <Td>
                            <ImageRow
                              src={variant.images[0]?.url ?? 'https://t3.ftcdn.net/jpg/05/03/24/40/360_F_503244059_fRjgerSXBfOYZqTpei4oqyEpQrhbpOML.jpg'}
                            />
                          </Td>
                          <Td>
                            <ProductNameRow productName={row.original.name.en + ' - ' + findProductVariantAttributeDefaultEnglish(variant, 'color')} />
                          </Td>
                          <Td>
                            <SizeRow size={findProductVariantAttributeDefaultEnglish(variant, 'size') ?? 'No size'} />
                          </Td>
                          <Td>
                            <PriceRow value={row.original.masterVariant.price.value.centAmount} />
                          </Td>
                          <Td>
                            <Tag colorScheme={'darkGreen'}>{calculateStock(variant)}</Tag>
                          </Td>
                          <Td>0%</Td>
                          <Td>{variant.sku}</Td>
                          <Td>{/* <StateRow /> */}</Td>
                        </Tr>
                      )
                    }
                  })
                : null}
            </>
          )
        })}
      </Tbody>
    </Table>
  )
}
