import { Flex, Img, Text } from '@chakra-ui/react'
import { useCart } from '../../../../context/CheckoutCartContext'
import { ChannelReference, LineItem, Maybe } from '../../../../generated/graphql'

type ClickAndCollectStoreCardProps = {
  distributionChannel: Maybe<ChannelReference> | undefined
}

export const ClickAndCollectStoreCard = ({ distributionChannel }: ClickAndCollectStoreCardProps) => {
  const { cart } = useCart()

  return (
    <Flex borderRadius={8} gap={'1rem'} bgColor={'beigeLight.400'} p={'1rem'} boxShadow={'0 4px 40px 0 #9da4a04f'} alignItems={'center'}>
      <Flex direction={'column'} flex={1}>
        <Text fontWeight={'bold'}>{distributionChannel?.obj?.name.en.toUpperCase()}</Text>
        <Flex direction={'column'} gap={'10px'}>
          <Text>
            {`${distributionChannel?.obj?.address.streetName} ${distributionChannel?.obj?.address.streetNumber}, ${distributionChannel?.obj?.address.postalCode} ${distributionChannel?.obj?.address.city}`}
          </Text>
          <Flex gap={'8px'}>
            {cart.lineItems
              .filter((lineItem: Maybe<LineItem>) => lineItem?.distributionChannel?.obj?.key == distributionChannel?.obj?.key)
              .map((lineItem: Maybe<LineItem>, index: number) => {
                const quantity = lineItem?.quantity ?? 1
                return Array.from({ length: quantity }, (_, quantityIndex) => (
                  <Img key={lineItem?.id} w={'2rem'} h={'43px'} borderRadius={8} src={lineItem?.variant?.images[0]?.url ?? ''} />
                ))
              })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
