import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useCTFilter } from '../../../../context/CTFilterContext'

import { DrawerContentComponent } from './DrawerContent'
import { useQueryParam } from '../../../../hooks/useQueryParam'

export const FilterDrawer = () => {
  const { isOpen, onClose, fetchProducts, resetFiltersAndFetch } = useCTFilter()
  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(5000)
  // const [selectedSizes, setSelectedSizes] = useState<string[]>([])
  const handlePriceChange = (value: number[]) => {
    setMinPrice(value[0])
    setMaxPrice(value[1])
  }
  const params = useQueryParam()

  return (
    <>
      {/* Drawer */}
      <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bgColor={'beigeLight.500'}>
            <DrawerCloseButton />
            <DrawerHeader>Filters</DrawerHeader>
            <DrawerBody>
              <DrawerContentComponent />
            </DrawerBody>
            <DrawerFooter>
              <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'}>
                <Text
                  textDecoration={'underline'}
                  textUnderlineOffset={8}
                  textDecorationColor={'mintGreen.500'}
                  textDecorationThickness={'3px'}
                  fontWeight={'normal'}
                  fontSize={'lg'}
                  onClick={() => resetFiltersAndFetch()}
                >
                  {t('components.filter_modal.text_reset_filters')}
                </Text>
                <Button
                  onClick={() => {
                    fetchProducts(undefined, 0, params.get('store'))
                    onClose() // This will close the drawer
                  }}
                  variant={'solid'}
                >
                  {t('main_app.plp.components.filter.show_items')}
                </Button>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}
