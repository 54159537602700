import { useApolloClient } from '@apollo/client'
import { Button, Container, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Img, Input, useToast } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Logo from '../../../assets/svg/Plenty&Svg/Plenty_Logo_Black.svg'
import { CREATE_MERCHANT_APPLICATION } from '../../../gql/onboarding'
import withAuth from '../../../utils/WithAuth'
import { MainRole } from '../../../generated/graphql'
import { INTEGRATION_DATE } from '@adyen/adyen-web/dist/types/components/PayPal/config'
import { useState } from 'react'
import { t } from 'i18next'

const OnboardingApplicationSchema = Yup.object().shape({
  storeName: Yup.string().required('Store name is required!'),
  storeNumber: Yup.number().required('Store number is required!'),
  countryName: Yup.string().required('Country name is required!'),
  postalCode: Yup.string().required('Postal code is required!'),
  city: Yup.string().required('City is required!'),
  streetAndNumber: Yup.string().required('Street & number is required!'),
  iban: Yup.string().required('IBAN is required!'),
  storeEmail: Yup.string().email().required('Email is required!'),
  storePhone: Yup.string().required('Phone is required!'),
  accountOwnerEmail: Yup.string().email().required('Account owner email is required!'),
})

const OnboardingPage = () => {
  const client = useApolloClient()
  const toast = useToast()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  return (
    <Formik
      initialValues={{
        storeName: '',
        storeNumber: 0,
        countryName: '',
        postalCode: '',
        city: '',
        streetAndNumber: '',
        iban: '',
        storeEmail: '',
        storePhone: '',
        accountOwnerEmail: '',
      }}
      validationSchema={OnboardingApplicationSchema}
      onSubmit={(values: any) => {
        setLoading(true)

        client
          .mutate({
            mutation: CREATE_MERCHANT_APPLICATION,
            variables: {
              storeInformation: {
                storeName: values.storeName,
                storeNumber: parseInt(values.storeNumber),
                countryName: values.countryName,
                postalCode: values.postalCode,
                city: values.city,
                streetAndNumber: values.streetAndNumber,
                iban: values.iban,
                storeEmail: values.storeEmail,
                storePhone: values.storePhone,
                accountOwnerEmail: values.accountOwnerEmail,
              },
            },
          })
          .then((response: any) => {
            navigate('/onboarding/' + response.data.createMerchantApplication.onboardingNumber)
          })
          .catch((error: any) => {
            setLoading(false)

            toast({
              title: 'Error',
              description: error.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
      }}
    >
      {({ errors, touched, handleSubmit, handleChange }) => (
        <Container py={10}>
          <Flex direction={'column'} gap={14}>
            <Flex direction={'column'} justifyContent={'center'} alignItems={'center'} gap={2}>
              <Img w={'50%'} src={Logo} />
              <Heading size={'md'}>{t('main_app.onboarding.heading_application_form')}</Heading>
            </Flex>
            <Flex direction={'column'} gap={10}>
              <Flex direction={'column'} gap={4}>
                <Heading>{t('main_app.onboarding.heading_store_information')}</Heading>
                <Grid templateColumns='repeat(8, 1fr)' gap={6}>
                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.storeName != undefined && touched.storeName}>
                      <FormLabel>{t('main_app.onboarding.text_store_name')}</FormLabel>
                      <Field as={Input} id='storeName' name='storeName' variant={'solidWhite'} />
                      {errors.storeName && touched.storeName ? <FormErrorMessage>{errors.storeName}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.storeNumber != undefined && touched.storeNumber}>
                      <FormLabel>{t('main_app.onboarding.text_store_number')}</FormLabel>
                      <Field as={Input} id='storeNumber' name='storeNumber' variant={'solidWhite'} />
                      {errors.storeNumber && touched.storeNumber ? <FormErrorMessage>{errors.storeNumber}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.streetAndNumber != undefined && touched.streetAndNumber}>
                      <FormLabel>{t('main_app.onboarding.text_street_and_number')}</FormLabel>
                      <Field as={Input} id='streetAndNumber' name='streetAndNumber' variant={'solidWhite'} />
                      {errors.streetAndNumber && touched.streetAndNumber ? <FormErrorMessage>{errors.streetAndNumber}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.postalCode != undefined && touched.postalCode}>
                      <FormLabel>{t('main_app.onboarding.text_postal_code')}</FormLabel>
                      <Field as={Input} id='postalCode' name='postalCode' variant={'solidWhite'} />
                      {errors.postalCode && touched.postalCode ? <FormErrorMessage>{errors.postalCode}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.city != undefined && touched.city}>
                      <FormLabel>{t('main_app.onboarding.text_city')}</FormLabel>
                      <Field as={Input} id='city' name='city' variant={'solidWhite'} />
                      {errors.city && touched.city ? <FormErrorMessage>{errors.city}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.countryName != undefined && touched.countryName}>
                      <FormLabel>{t('main_app.onboarding.text_country')}</FormLabel>
                      <Field as={Input} id='countryName' name='countryName' variant={'solidWhite'} />
                      {errors.countryName && touched.countryName ? <FormErrorMessage>{errors.countryName}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.iban != undefined && touched.iban}>
                      <FormLabel>IBAN</FormLabel>
                      <Field as={Input} id='iban' name='iban' variant={'solidWhite'} />
                      {errors.iban && touched.iban ? <FormErrorMessage>{errors.iban}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.storeEmail != undefined && touched.storeEmail}>
                      <FormLabel>{t('main_app.onboarding.text_store_email')}</FormLabel>
                      <Field as={Input} id='storeEmail' name='storeEmail' variant={'solidWhite'} />
                      {errors.storeEmail && touched.storeEmail ? <FormErrorMessage>{errors.storeEmail}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.storePhone != undefined && touched.storePhone}>
                      <FormLabel>{t('main_app.onboarding.text_store_phone')}</FormLabel>
                      <Field as={Input} id='storePhone' name='storePhone' variant={'solidWhite'} />
                      {errors.storePhone && touched.storePhone ? <FormErrorMessage>{errors.storePhone}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <FormControl isRequired isInvalid={errors.accountOwnerEmail != undefined && touched.accountOwnerEmail}>
                      <FormLabel>{t('main_app.onboarding.text_account_owner_email')}</FormLabel>
                      <Field as={Input} id='accountOwnerEmail' name='accountOwnerEmail' variant={'solidWhite'} />
                      {errors.accountOwnerEmail && touched.accountOwnerEmail ? <FormErrorMessage>{errors.accountOwnerEmail}</FormErrorMessage> : null}
                    </FormControl>
                  </GridItem>
                </Grid>
              </Flex>
            </Flex>
            <Button onClick={() => handleSubmit()} variant={'solidGreen'} isLoading={loading}>
              {t('components.button.submit_application')}
            </Button>
          </Flex>
        </Container>
      )}
    </Formik>
  )
}

export default withAuth(OnboardingPage, [MainRole.RoleAdmin, MainRole.RoleGuest])
