import { Formik } from "formik"
import { Button, Center, CircularProgress, Flex, ModalFooter, Textarea, VStack } from "@chakra-ui/react"
import { useDiscountCreation } from "../../../../../../context/DiscountCreationContext"
import { UploadStatusTernary } from "../../../../../../utils/enums/UploadStatusTernary"
import { Dispatch, SetStateAction } from "react"
import { SummaryBar } from "../../SummaryBar"

export const CreateBulkDiscountUploadingStep = (props: {
    wizard: any,
    selectedFile: File | null,
    uploadState: UploadStatusTernary,
    responseError: string,
    setCampaignId: Dispatch<SetStateAction<string>>
}) => {
    const discountCreation = useDiscountCreation()

    const onBackPress = () => {
        props.setCampaignId("")
        props.wizard.reduceStep()
        discountCreation.setTableEntries([])
    }

    return (
        <Formik
            initialValues={{
                file: null,
            }}
            onSubmit={ () => {
                discountCreation.setUploadComplete(true)
                props.wizard.advanceStep()
            }}
        >
            {({ handleSubmit, isValid }) => (
                <Flex direction={'column'} position={'relative'}>
                    <SummaryBar
                    info={[
                        discountCreation.campaignName,
                        discountCreation.validFrom.toDateString() + ' to ' + (discountCreation.validTo ? discountCreation.validTo.toDateString() : 'continuous'),
                    ]}
                    />
                    <Flex position="relative" alignItems="center" justifyContent="center" padding={"8"}>
                        <Center>
                            <VStack>
                                {props.uploadState == UploadStatusTernary.Uploaded ?
                                    <CircularProgress value={100} color={"green"} /> :
                                    props.uploadState == UploadStatusTernary.Error ?
                                        <CircularProgress value={100} color={"red"} /> :
                                        <CircularProgress isIndeterminate />}
                                {props.uploadState == UploadStatusTernary.Uploaded && props.selectedFile != null ?
                                    <div>File {props.selectedFile.name} has been uploaded successfully!</div> :
                                    props.uploadState == UploadStatusTernary.Uploading && props.selectedFile != null ?
                                        <div>Uploading {props.selectedFile.name} ...</div> :
                                        props.uploadState == UploadStatusTernary.Error && props.selectedFile != null ?
                                            <Textarea defaultValue={"File " + props.selectedFile.name + " failed to upload."} textColor="red" isInvalid isDisabled={true} resize={"none"} /> :
                                            <Textarea defaultValue={"File not found."} textColor="red" isInvalid isDisabled={true} resize={"none"} />}
                                {props.uploadState == UploadStatusTernary.Uploaded && props.selectedFile != null ?
                                    <div>Upload is completed, please proceed to confirmation</div> :
                                    props.uploadState == UploadStatusTernary.Uploading && props.selectedFile != null ?
                                        <div>Please don&#39;t leave the window while uploading is in progress</div> :
                                        props.uploadState == UploadStatusTernary.Error && props.selectedFile != null ?
                                            <Textarea defaultValue={props.responseError} textColor="red" isInvalid isDisabled={true} resize={"none"} /> :
                                            <Textarea defaultValue={"File is null."} textColor="red" isInvalid isDisabled={true} resize={"none"} />}
                            </VStack>
                        </Center>
                    </Flex>
                    <ModalFooter borderTop={'1px'} borderTopColor={'beigeLight.500'}>
                        <Button isDisabled={!props.wizard.canReduce} colorScheme='blue' mr={3} onClick={() => onBackPress()}>
                            Previous
                        </Button>
                        <Button isDisabled={!props.uploadState || !props.wizard.canAdvance || !isValid || props.uploadState != UploadStatusTernary.Uploaded} colorScheme='blue' mr={3} onClick={() => handleSubmit()}>
                            Next
                        </Button>
                    </ModalFooter>
                </Flex>
            )}
        </Formik>
    )
}
