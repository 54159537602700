import { Divider, Flex, Heading, Img } from '@chakra-ui/react'
import { Store } from '@commercetools/platform-sdk'
import { t } from 'i18next'
import { useState } from 'react'
import { Map } from '../../../../components/map/Map'
import { findCircleText, findHeroVideoForStore } from '../../../../utils/GoogleCloudBucketUtil'
import Video from './Video'
interface PostCardProps {
  StoreByDisplayName: any
  storeName: string
  storeBrand: any
  storeStreetAndNumber: string
  storePostalCode: string
  city: string
  store: Store | undefined
}
export const PostCard = ({ StoreByDisplayName, storeBrand, storeStreetAndNumber, storePostalCode, city, store }: PostCardProps) => {
  const [showMap, setShowMap] = useState(false)

  const extractGeoLocation = (distributionChannels: any[]): { lat: number; lng: number } | null => {
    if (!distributionChannels || distributionChannels.length === 0) return null

    const firstChannel = distributionChannels[0]
    const geoLocation = firstChannel.obj?.geoLocation

    if (!geoLocation || geoLocation.type !== 'Point' || geoLocation.coordinates.length !== 2) return null

    return {
      lat: geoLocation.coordinates[1],
      lng: geoLocation.coordinates[0],
    }
  }

  const makeMarker = (commercetoolsStore: Store) => {
    const geoLocation = extractGeoLocation(commercetoolsStore.distributionChannels)
    return { id: store?.id ?? '', lat: geoLocation?.lat ?? 0, lng: geoLocation?.lng ?? 0 }
  }

  return (
    <Flex>
      <Flex flex={1}>
        <Video src={findHeroVideoForStore(StoreByDisplayName)} />
      </Flex>
      <Flex width={'50%'} position={'relative'} flex={1} gap={4} backgroundColor='#D3D3CA' direction='column' justifyContent='center' alignItems='center'>
        {showMap ? (
          <Flex flex={1} width={'100%'} height={'100%'}>
            { store ? <Map highLightedMarkers={[]} zoom={15} markers={[makeMarker(store)]} centerAt={extractGeoLocation(store.distributionChannels)} /> : null }
          </Flex>
        ) : (
          <>
            <Img
              background='white.500'
              margin={'auto'}
              position={'absolute'}
              right={6}
              width={'15%'}
              fontSize={'20px'}
              top={6}
              src={findCircleText(StoreByDisplayName)}
              objectFit={'cover'}
              borderRadius={'full'}
            />
            <Flex direction='column' alignItems='center' width='100%'>
              <Flex direction='column' alignItems='start' width='60%'>
                <Heading mb='2'>{storeBrand}</Heading>
                <Divider width='100%' height='1px' borderColor='black' />
              </Flex>
            </Flex>

            <Flex direction='column' alignItems='center' width='100%'>
              <Flex direction='column' alignItems='start' width='60%'>
                <Flex mb='2'>
                  {storeStreetAndNumber}, {storePostalCode} {city}
                </Flex>
                <Divider width='100%' height='1px' borderColor='black' />
              </Flex>
            </Flex>

            <Flex direction='column' alignItems='center' width='100%'>
              <Flex direction='column' alignItems='start' width='60%'>
                <Flex
                  cursor={'pointer'}
                  mb='2'
                  onClick={() => setShowMap(true)}
                  textDecoration={'underline'}
                  textDecorationColor={'mintGreen.500'}
                  textDecorationThickness={'3px'}
                  textUnderlineOffset={'4px'}
                >
                  {t('main_app.post_card.see_store_on_map')}
                </Flex>
                <Divider width='100%' height='1px' borderColor='black' />
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  )
}
