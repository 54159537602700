import { Flex, Img } from '@chakra-ui/react'
import { findCircleText, findHeroVideoForStore } from '../../../../utils/GoogleCloudBucketUtil'
import Video from './Video'

interface StoreBannerMobileProps {
  storeName: any
}

export const StoreBannerMobile = ({ storeName }: StoreBannerMobileProps) => {
  return (
    <Flex position={'relative'}>
      <Video src={findHeroVideoForStore(storeName)} />

      <Img
        left={0}
        margin={'auto'}
        position={'absolute'}
        right={0}
        width={'20%'}
        bottom={'-10%'}
        src={findCircleText(storeName)}
        objectFit={'cover'}
        borderRadius={'full'}
      />
    </Flex>
  )
}
