import { Flex, Text } from '@chakra-ui/react'

type ToggleTagProps = {
  label: string
  isToggled: boolean
  action: () => any
}

export const ToggleTag = (props: ToggleTagProps) => {
  return (
    <Flex
      cursor={'pointer'}
      w={'fit-content'}
      justifyContent={'space-between'}
      alignItems={'center'}
      bgColor={'white.500'}
      color={'black.500'}
      border={'1px'}
      borderColor={props.isToggled ? 'darkGreen.500' : 'gray.300'}
      py={1}
      px={4}
      borderRadius={'full'}
      onClick={() => props.action()}
    >
      <Text>{props.label}</Text>
    </Flex>
  )
}
