import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys)

const baseStyle = definePartsStyle({
  list: {
    bgColor: 'white.500',
    borderRadius: 24,
    py: 5,
  },
  item: {
    px: 5,
    _hover: {
      bgColor: 'beigeLight.300',
    },
    _focus: {
      bgColor: 'beigeLight.300',
    },
  },
})

export const menuTheme = defineMultiStyleConfig({ variants: { baseStyle } })
