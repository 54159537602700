import { sliderAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(sliderAnatomy.keys)

const solid = definePartsStyle({
  track: {
    backgroundColor: 'white.500',
  },
  thumb: {
    backgroundColor: 'black.500',
  },
  filledTrack: {
    backgroundColor: 'beigeLight.500',
  },
  FilledDarkGreyTrack: {
    backgroundColor: 'rgba(211, 211, 202, 1)',
  },
})

export const sliderTheme = defineMultiStyleConfig({
  variants: { solid },
})
