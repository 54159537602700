import { useEffect, useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent as OriginalPopoverContent,
  PopoverBody,
  Text,
  VStack,
  HStack,
  GridItem,
  Grid,
  Box,
  Image,
  Flex,
  Button,
  Heading,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { getAllCategoriesSortedByOrderHint } from '../../../commercetools/requests'
import { useScrollbarWidth } from '../../../hooks/useScrollBarWidth'
import i18n from '../../../I18n/i18n'
import { useNavigate } from 'react-router-dom'
import { TbChevronRight } from 'react-icons/tb'
import { FindImageForDropDownMenu } from '../../../utils/GoogleCloudBucketUtil'
import { t } from 'i18next'
import './DropDownMenu.css'
const PopoverContent = motion(OriginalPopoverContent)
type Categories = {
  categoryId: string
  categoryName: string
  parentCategoryId: string | null
  key: string | undefined
  childCategories?: Categories[]
  categoryNameEn: string
  orderHint?: any
}
export const DropDownMenu = () => {
  const [openPopover, setOpenPopover] = useState<string | null>(null)
  const scrollbarWidth = useScrollbarWidth()
  const [allCategories, setAllCategories] = useState<Categories[]>([])
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null)
  const getNavigationPath = () => {
    switch (selectedCategoryType) {
      case 'women':
        return '/products/women/outerwear/winterjackets'
      case 'men':
        return '/products/men/clothing/jeans'
      case 'kids':
        return '/products/kids/boy_2_8'
      default:
        return '/'
    }
  }
  const [languageSelected, setLanguageSelected] = useState<string>(() => {
    const selectedLanguage = localStorage.getItem('languageSelected') ?? i18n.language
    return selectedLanguage === 'DK' ? 'da-DK' : 'en'
  })
  const [hoveredSubCategory, setHoveredSubCategory] = useState<string | null>(null)
  const navigate = useNavigate()
  const [hoveredSubCategoryName, setHoveredSubCategoryName] = useState<string | null>(null)

  const navigateToCategory = (categoryType: string) => {
    navigate(`/landing-page/${categoryType}`)
  }
  const menCategoryKey = 'men'
  const kidsCategoryKey = 'kids'
  const womenCategoryKey = 'women'
  const underLineStyle = {
    textDecoration: 'underline',
    textDecorationColor: 'mintGreen.500',
    textUnderlineOffset: '2px',
    textDecorationThickness: '2px',
    textShadow: '0px 0px 1px rgba(0, 0, 0, 0.8)',
  }
  const [selectedCategoryType, setSelectedCategoryType] = useState('')
  const [timerId, setTimerId] = useState<number | null>(null)
  const categories = [
    { key: 'women', title: t('global.title_women') },
    { key: 'men', title: t('global.title_men') },
    { key: 'kids', title: t('global.title_kids') },
  ]
  const childCategoriesMap = new Map<string, Categories[]>()

  const subSubChildCategoriesMap = new Map<string, Categories[]>()
  const subSubSubChildCategoriesMap = new Map<string, Categories[]>()
  const getCategoryPath = (gender: string, categoryName: string, subCategoryName?: string, subSubSubCategoryName?: string) => {
    const formatString = (str: any) => {
      const kidsCategoryRegex = /(boy|girl|baby)\s(\d+)\s(\d+)/i
      if (kidsCategoryRegex.test(str)) {
        return encodeURIComponent(str.replace(kidsCategoryRegex, '$1_$2_$3'))
      }
      return encodeURIComponent(str.replace(/&/g, 'and').replace(/\s+/g, ''))
    }

    const encodedGender = formatString(gender)
    const encodedCategoryName = formatString(categoryName)
    const encodedSubCategoryName = subCategoryName ? formatString(subCategoryName) : ''
    const encodedSubSubSubCategoryName = subSubSubCategoryName ? formatString(subSubSubCategoryName) : ''
    return subSubSubCategoryName
      ? `/products/${encodedGender.toLowerCase()}/${encodedCategoryName.toLowerCase()}/${encodedSubCategoryName.toLowerCase()}/${encodedSubSubSubCategoryName.toLowerCase()}`
      : subCategoryName
      ? `/products/${encodedGender.toLowerCase()}/${encodedCategoryName.toLowerCase()}/${encodedSubCategoryName.toLowerCase()}`
      : `/products/${encodedGender.toLowerCase()}/${encodedCategoryName.toLowerCase()}`
  }
  const fetchAllCategories = async () => {
    if (allCategories.length > 0) {
      return
    }

    const categoryLimit = 200
    let categoryOffset = 0

    const initialBatch = await getAllCategoriesSortedByOrderHint(categoryOffset, categoryLimit)
    const totalCategories = initialBatch?.total ?? 0
    const categories = initialBatch?.results ?? []
    const offsetCount = Math.ceil(totalCategories / categoryLimit) - 1
    for (let i = 0; i < offsetCount; i++) {
      categoryOffset = (i + 1) * categoryLimit
      const newCategories = await getAllCategoriesSortedByOrderHint(categoryOffset, categoryLimit)
      categories.push(...(newCategories?.results ?? []))
    }
    const womenCategory = categories.find((category) => category.key === womenCategoryKey)
    const menCategory = categories.find((category) => category.key === menCategoryKey)
    const kidsCategory = categories.find((category) => category.key === kidsCategoryKey)

    if (!kidsCategory || !womenCategory || !menCategory) {
      console.log('Categories not found')
      return
    }

    const childCategories = categories.filter(
      (category) => category.ancestors.some((ancestor) => ancestor.obj?.key === kidsCategoryKey) && category.ancestors.length <= 4,
    )
    const menAndChildrenCategories = [kidsCategory, ...childCategories]

    const womenChildCategories = categories.filter(
      (category) => category.ancestors.some((ancestor) => ancestor.obj?.key === womenCategoryKey) && category.ancestors.length <= 4,
    )
    const womenAndChildrenCategories = [womenCategory, ...womenChildCategories]

    const menChildCategories = categories.filter(
      (category) => category.ancestors.some((ancestor) => ancestor.obj?.key === menCategoryKey) && category.ancestors.length <= 4,
    )
    const menCategories = [menCategory, ...menChildCategories]
    setAllCategories([
      ...menAndChildrenCategories.map((category) => ({
        categoryName: category.name[languageSelected],
        categoryNameEn: category.name.en,
        categoryId: category.id,
        parentCategoryId: category.parent?.id ?? '',
        genderCategoryId: category.ancestors[0] ? category.ancestors[0].id : '',
        key: category.key,
      })),
      ...womenAndChildrenCategories.map((category) => ({
        categoryName: category.name[languageSelected],
        categoryNameEn: category.name.en,
        categoryId: category.id,
        parentCategoryId: category.parent?.id ?? '',
        genderCategoryId: category.ancestors[0] ? category.ancestors[0].id : '',
        key: category.key,
      })),
      ...menCategories.map((category) => ({
        categoryName: category.name[languageSelected],
        categoryNameEn: category.name.en,
        categoryId: category.id,
        parentCategoryId: category.parent?.id ?? '',
        genderCategoryId: category.ancestors[0] ? category.ancestors[0].id : '',
        key: category.key,
      })),
    ])
  }

  useEffect(() => {
    fetchAllCategories()
  }, [])

  const handleMouseEnter = (categoryKey: string) => {
    setSelectedCategoryType(categoryKey)
    setHoveredCategory(categoryKey)

    if (timerId) clearTimeout(timerId)

    const newTimerId = setTimeout(() => {
      setOpenPopover(categoryKey)
    }, 300) as unknown as number

    setTimerId(newTimerId)
  }

  const handleMouseLeave = () => {
    const dropdownContent = document.querySelector('.dropdown-content')

    if (dropdownContent) {
      dropdownContent.classList.add('closing')

      if (timerId) clearTimeout(timerId)

      setTimeout(() => {
        setOpenPopover(null)
        dropdownContent.classList.remove('closing')
      }, 350)
    }
  }

  const handleMouseEnterSubCategory = (subCategoryId: string, subCategoryName: string) => {
    setHoveredSubCategory(subCategoryId)
    setHoveredSubCategoryName(subCategoryName)
  }

  const renderAllCategories = (categories: Categories[]) => {
    let categoriesToShow: Categories[] = []
    if (selectedCategoryType === kidsCategoryKey) {
      categoriesToShow = categories.filter((category) => category.key === kidsCategoryKey)
    } else if (selectedCategoryType === womenCategoryKey) {
      categoriesToShow = categories.filter((category) => category.key === womenCategoryKey)
    } else if (selectedCategoryType === menCategoryKey) {
      categoriesToShow = categories.filter((category) => category.key === menCategoryKey)
    }

    categoriesToShow.forEach((parentCategory: Categories) => {
      const childCategories = categories.filter((category: Categories) => category.parentCategoryId === parentCategory.categoryId)
      childCategoriesMap.set(parentCategory.categoryId, childCategories)
      childCategories.forEach((childCategory) => {
        const subChildCategories = categories.filter((subChildCategory: Categories) => subChildCategory.parentCategoryId === childCategory.categoryId)
        subSubChildCategoriesMap.set(childCategory.categoryId, subChildCategories)
        subChildCategories.forEach((subChildCategory) => {
          const subSubChildCategories = categories.filter(
            (subSubChildCategory: Categories) => subSubChildCategory.parentCategoryId === subChildCategory.categoryId,
          )
          subSubSubChildCategoriesMap.set(subChildCategory.categoryId, subSubChildCategories)
        })
      })
    })

    const renderHoveredSubSubCategories = (subCategoryName: string) => {
      if (!hoveredSubCategory) return null
      return (
        <Grid templateColumns='repeat(3, 1fr)' gap={4} autoRows='auto' width={'100%'}>
          {subSubChildCategoriesMap.get(hoveredSubCategory)?.map((subSubCategory, index) => {
            let colSpan = 1
            let rowSpan = 1

            if (index === 0) {
              rowSpan = 2
            }

            if (index >= 1 && index <= 4) {
              colSpan = 1
              rowSpan = 1
            }

            return (
              <GridItem key={subSubCategory.categoryId} colSpan={colSpan} rowSpan={rowSpan} width={'100%'}>
                <VStack align='start'>
                  <Text
                    mb={'0.5rem'}
                    align='start'
                    variant={'textDropDownMenuMainCategories'}
                    onClick={() => navigate(getCategoryPath(selectedCategoryType, subCategoryName, subSubCategory.categoryNameEn))}
                    cursor='pointer'
                  >
                    {subSubCategory.categoryName}
                  </Text>
                  {subSubSubChildCategoriesMap.get(subSubCategory.categoryId)?.map((subSubSubCategory) => (
                    <Box
                      key={subSubSubCategory.categoryId}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover .text': {
                          textShadow: '0px 0px 1px rgba(0, 0, 0, 0.8)',
                          opacity: 1,
                        },
                        '& .icon': {
                          opacity: 0,
                          transition: 'opacity 0.3s',
                          marginLeft: '8px',
                          fontSize: '24px',
                        },
                        '&:hover .icon': {
                          opacity: 1,
                        },
                        '& .text': {
                          transition: 'text-shadow 0.3s ease',
                        },
                      }}
                      onClick={() =>
                        navigate(getCategoryPath(selectedCategoryType, subCategoryName, subSubCategory.categoryNameEn, subSubSubCategory.categoryNameEn))
                      }
                    >
                      <Text className='text' variant={'textDropDownMenuSubCategories'}>
                        {subSubSubCategory.categoryName}
                      </Text>
                      <TbChevronRight className='icon' />
                    </Box>
                  ))}
                </VStack>
              </GridItem>
            )
          })}
        </Grid>
      )
    }

    return (
      <HStack width={'100%'} height={'100%'} spacing={'4rem'}>
        {selectedCategoryType === kidsCategoryKey && (
          <HStack spacing={'3rem'} width={'100%'} align='start'>
            {categoriesToShow.map((category) => (
              <VStack gap={'0.5rem'} height={'100%'} alignItems={'start'} key={category.categoryId}>
                {childCategoriesMap.get(category.categoryId)?.map((subCategory) => (
                  <HStack width={'100%'} justifyContent={'space-between'} key={subCategory.categoryId} align='start'>
                    <Text
                      textAlign={'start'}
                      variant={'textDropDownMenuMainCategories'}
                      minW={'100px'}
                      onMouseEnter={() => handleMouseEnterSubCategory(subCategory.categoryId, subCategory.categoryName)}
                      onClick={() => navigate(getCategoryPath(selectedCategoryType, subCategory.categoryNameEn))}
                      cursor='pointer'
                      sx={hoveredSubCategory === subCategory.categoryId ? underLineStyle : {}}
                    >
                      {subCategory.categoryName}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            ))}
            {hoveredSubCategoryName && renderHoveredSubSubCategories(hoveredSubCategoryName)}
          </HStack>
        )}

        {(selectedCategoryType === womenCategoryKey || selectedCategoryType === menCategoryKey) && (
          <HStack width='100%' height={'100%'} justify='space-between' align='start'>
            {categoriesToShow.map((category) => (
              <HStack width='100%' height={'100%'} justify='space-between' alignItems={'start'} key={category.categoryId}>
                {childCategoriesMap.get(category.categoryId)?.map((subCategory) => (
                  <VStack key={subCategory.categoryId} align='start' gap={'0.5rem'}>
                    <Text
                      onClick={() => navigate(getCategoryPath(selectedCategoryType, subCategory.categoryNameEn))}
                      variant={'textDropDownMenuMainCategories'}
                      cursor='pointer'
                    >
                      {subCategory.categoryName}
                    </Text>
                    <VStack align='start'>
                      {subSubChildCategoriesMap.get(subCategory.categoryId)?.map((subSubCategory) => (
                        <Box
                          key={subSubCategory.categoryId}
                          onMouseEnter={() => setHoveredSubCategory(subSubCategory.categoryName)}
                          onClick={() => navigate(getCategoryPath(selectedCategoryType, subCategory.categoryNameEn, subSubCategory.categoryNameEn))}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            '&:hover .text': {
                              // Apply text-shadow to simulate bold
                              textShadow: '0px 0px 1px rgba(0, 0, 0, 0.8)',
                              opacity: 1,
                            },
                            '& .icon': {
                              opacity: 0,
                              transition: 'opacity 0.3s',
                              marginLeft: '8px',
                              fontSize: '24px',
                            },
                            '&:hover .icon': {
                              opacity: 1,
                            },
                            '& .text': {
                              transition: 'text-shadow 0.3s ease', // Smooth transition for text-shadow
                            },
                          }}
                        >
                          <Text className='text' variant={'textDropDownMenuSubCategories'}>
                            {subSubCategory.categoryName}
                          </Text>
                          <TbChevronRight className='icon' />
                        </Box>
                      ))}
                    </VStack>
                  </VStack>
                ))}
              </HStack>
            ))}
          </HStack>
        )}
      </HStack>
    )
  }

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Popover isOpen={!!openPopover}>
        <HStack pb={'1rem'} position={'relative'} top={'0.5rem'} spacing={'2.5rem'}>
          {categories.map(({ key, title }) => (
            <PopoverTrigger key={key}>
              <Text
                cursor={'pointer'}
                onMouseEnter={() => handleMouseEnter(key)}
                onClick={() => navigateToCategory(key)}
                sx={hoveredCategory === key && openPopover ? underLineStyle : {}}
              >
                {title}
              </Text>
            </PopoverTrigger>
          ))}
        </HStack>

        <div className={`dropdown-content ${openPopover ? 'open' : ''}`}>
          <PopoverContent
            width='100vw'
            maxWidth={`calc(100vw - ${scrollbarWidth}px)`}
            background='white'
            boxShadow='none'
            border={'none'}
            py={'2rem'}
            borderBottom='black 2px solid'
            _focus={{ boxShadow: 'none' }}
            display={'flex'}
            justifyItems={'center'}
            flexDirection={'row'}
            borderRadius={'none'}
          >
            <PopoverBody pt={'0'} alignSelf={'flex-start'} px={'2.5%'} flex={'3'} className='instant-content'>
              <div>{renderAllCategories(allCategories)}</div>
            </PopoverBody>
            <Flex alignItems={'flex-start'} flex={'1'} paddingX={'2rem'} mx={'auto'} position={'relative'}>
              <Flex position={'relative'} width={'100%'} height={'auto'} mx={'auto'}>
                <Image src={FindImageForDropDownMenu(selectedCategoryType)} width={'100%'} maxW={'350px'} height={'auto'} objectFit={'contain'} />

                <Flex position={'absolute'} width={'100%'} height={'100%'} left={0} top={0} justify={'space-between'}>
                  <Flex
                    color={'white'}
                    direction={'column'}
                    align={'start'}
                    position={'absolute'}
                    bottom={{ sm: '22.5%', md: '17.5%', xl: '20%' }}
                    left={'5%'}
                    width={'100%'}
                  >
                    <Text variant={'textCleanWhite'}>{t(`main_app.drop_down_menu.${selectedCategoryType}_text_image_text`)}</Text>
                    <Heading variant={'headingCleanWhiteSmallHeading'}>{t(`main_app.drop_down_menu.${selectedCategoryType}_heading_image_text`)}</Heading>
                  </Flex>

                  <Button
                    position={'absolute'}
                    bottom={'5%'}
                    left={'5%'}
                    width={'40%'}
                    backgroundColor={'white'}
                    color={'black'}
                    fontSize={'16px'}
                    onClick={() => navigate(getNavigationPath())}
                    _hover={{
                      bg: 'white',
                      color: 'primaryColor',
                    }}
                    _active={{
                      bg: 'white',
                      color: 'primaryColor',
                    }}
                  >
                    {t(`main_app.drop_down_menu.${selectedCategoryType}_button_text`)}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </PopoverContent>
        </div>
      </Popover>
    </div>
  )
}
