import { useState, useEffect, Dispatch, SetStateAction } from 'react'

type UpdateGlobalBrandStateType = (newState: string[] | null) => void

export const useBrandSelector = (
  selectedBrands: string[] | null,
  updateGlobalState: UpdateGlobalBrandStateType,
): [string[] | null, Dispatch<SetStateAction<string[] | null>>] => {
  const [localState, setLocalState] = useState<string[] | null>(selectedBrands)

  useEffect(() => {
    if (localState !== null) {
      updateGlobalState(localState)
    }
  }, [localState])

  useEffect(() => {
    setLocalState(selectedBrands)
  }, [selectedBrands])

  return [localState, setLocalState]
}
