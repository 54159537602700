import { Button, Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'

import { PostCard } from './components/PostCard'
import { GenderSale } from './components/GenderSale'
import { Theme } from '../../../ThemeVariables'

import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { TopCategoryPick } from './components/TopCategoryPick'
import { StoreBannerMobile } from './components/StoreBannerMobile'
import { TbMap2 } from 'react-icons/tb'
import Sheet from 'react-modal-sheet'
import { LeafLetMap } from '../../../components/LeafletMap/LeafletMap'
import i18n from '../../../I18n/i18n'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { GenderSlider } from '../../../components/Sliders/GenderSlider'
import { getChannels, getStoreByKey } from '../../../commercetools/requests'
import { Store } from '@commercetools/platform-sdk'
import { NotFoundPageDesktop } from '../not_found_page/desktop/NotFoundPageDesktop'
import { useScrollbarWidth } from '../../../hooks/useScrollBarWidth'
import { MainRole } from '../../../generated/graphql'
import withAuth from '../../../utils/WithAuth'

interface SliderProps {
  title: string
  storeRedirect: string
  store?: Store
  gender: string
  genderKids?: string | null
  category?: string
}

const BoySlider: React.FC<SliderProps> = ({ title, storeRedirect, gender, genderKids, store, category }) => (
  <GenderSlider title={title} gender={'Male'} targetGroup={'Kids'} store={store} category={'kids'} />
)

const GirlSlider: React.FC<SliderProps> = ({ title, storeRedirect, gender, genderKids, store, category }) => (
  <GenderSlider title={title} gender={'Female'} targetGroup={'Kids'} store={store} category={'kids'} />
)

const WomenSlider: React.FC<SliderProps> = ({ title, storeRedirect, gender, store, category }) => (
  <GenderSlider title={title} gender={'Female'} targetGroup={'Adult'} store={store} category={'women'} />
)

const MenSlider: React.FC<SliderProps> = ({ title, storeRedirect, gender, store, category }) => (
  <GenderSlider title={title} gender={'Male'} targetGroup={'Adult'} store={store} category={'men'} />
)

const NewStoreLandingPage = () => {
  const { storeDisplayNameParam } = useParams()
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const navigate = useNavigate()
  const [isMapSheetOpen, setMapSheetOpen] = useState(false)
  const [_isLocationPillSheetOpenSetterOnly, setLocationPillSheetOpen] = useState(false)
  const { setActiveBreadcrumbs } = useBreadcrumbs()
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [activeStore, setActiveStore] = useState<Store>()
  const [fetchedStores, setFetchedStores] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const scrollbarWidth = useScrollbarWidth()

  const handleClick = () => {
    if (isLargerThan768) {
      navigate('/store-map')
      window.scrollTo({ top: window.scrollY - 800, behavior: 'smooth' })
    } else {
      setMapSheetOpen(true)
    }
  }
  const selectedStoreSliders: Record<string, string[]> = {
    nameit: ['girlSlider', 'boySlider'],
    selected: ['womenSlider', 'menSlider'],
    bestseller: ['womenSlider', 'menSlider'],
    veromoda: ['womenSlider', 'girlSlider'],
  }

  useEffect(() => {
    getChannels()
      .then((stores) => {
        if (stores) {
          setFetchedStores(stores)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log('Error Fetching Stores:', error)
      })
  }, [])
  useEffect(() => {
    setLoading(true)
    if (storeDisplayNameParam) {
      getStoreByKey(storeDisplayNameParam)
        .then((store) => {
          if (store) {
            setActiveStore(store)
            setLoading(false)
          } else {
            setNotFound(true)
            setLoading(false)
          }
        })
        .catch((error) => {
          console.log('Error Fetching Store:', error)
          setLoading(false)
          setNotFound(true)
        })
    }
  }, [storeDisplayNameParam])

  useEffect(() => {
    setActiveBreadcrumbs([
      {
        title: <strong>{t('global.text_home')}</strong>,
        clickablePath: `/`,
        key: 0,
      },
      {
        title: `${activeStore?.name?.['en']}`,
        key: 1,
      },
    ])
  }, [activeStore?.key, i18n.language])

  type SliderKeys = 'boySlider' | 'girlSlider' | 'womenSlider' | 'menSlider'

  const getSelectedSlider: Record<SliderKeys, JSX.Element> = {
    boySlider: (
      <BoySlider
        title={t('components.store_slider.heading_boys') as string}
        storeRedirect={storeDisplayNameParam || ''}
        gender='Kids'
        genderKids='Boy'
        store={activeStore}
      />
    ),
    girlSlider: (
      <GirlSlider
        title={t('components.store_slider.heading_girl') as string}
        storeRedirect={storeDisplayNameParam || ''}
        gender='Kids'
        genderKids='Girl'
        store={activeStore}
      />
    ),
    womenSlider: (
      <WomenSlider
        title={t('components.store_slider.heading_women') as string}
        storeRedirect={storeDisplayNameParam || ''}
        gender='Women'
        store={activeStore}
      />
    ),
    menSlider: (
      <MenSlider title={t('components.store_slider.heading_men') as string} storeRedirect={storeDisplayNameParam || ''} gender='Men' store={activeStore} />
    ),
  }

  const selectTemplate = (templateObject: SliderKeys) => getSelectedSlider[templateObject] ?? null

  const getTemplatesByStore = (storeDisplayNameParam: any): string[] | null => {
    const storeDisplayName = storeDisplayNameParam.toLowerCase()

    for (const storeName in selectedStoreSliders) {
      if (storeDisplayName.includes(storeName)) {
        return selectedStoreSliders[storeName]
      }
    }

    return null
  }
  const specificActiveStore = fetchedStores.find((store) => store.name.en.toString() === activeStore?.name?.['en'])

  if (loading) {
    return <div></div> // Replace with your actual loading component
  }

  if (notFound) {
    return <NotFoundPageDesktop />
  }

  return (
    <MainPageResponsive loading={false}>
      <Flex mx={'auto'} mt={'1rem'} flex={1} gap={Theme.gap.BetweenComponents} direction={'column'}>
        <Flex gap={isLargerThan768 ? 10 : 16} direction={'column'}>
          {isLargerThan768 ? (
            <>
              {specificActiveStore && (
                <PostCard
                  key={specificActiveStore.id}
                  StoreByDisplayName={activeStore?.key}
                  storeName={specificActiveStore.name?.en}
                  storeBrand={activeStore?.name?.['en']}
                  storeStreetAndNumber={`${specificActiveStore.address.streetName} ${specificActiveStore.address.streetNumber}`}
                  storePostalCode={specificActiveStore.address.postalCode}
                  city={specificActiveStore.address.city}
                  store={activeStore}
                />
              )}
            </>
          ) : (
            <StoreBannerMobile storeName={activeStore?.key} />
          )}

          <Flex maxWidth={`calc(100vw - ${scrollbarWidth}px)`} px={Theme.PaddingOnSides.StorePages} mx={'auto'}>
            <GenderSale StoreByDisplayName={activeStore?.key} />
          </Flex>
        </Flex>
        <Flex direction={'column'} maxWidth={`calc(100vw - ${scrollbarWidth}px)`} px={Theme.PaddingOnSides.StorePages} mx={'auto'} gap={8}>
          <Heading textAlign={'start'} px={0}>
            {t('components.bestseller_store_products_slider.label_bestsellers')}
          </Heading>
          {getTemplatesByStore(storeDisplayNameParam)?.map((templateName) => {
            if (Object.keys(getSelectedSlider).includes(templateName)) {
              return <React.Fragment key={templateName}>{selectTemplate(templateName as SliderKeys)}</React.Fragment>
            }
            return null
          })}
        </Flex>
        <Flex direction={'column'} maxWidth={`calc(100vw - ${scrollbarWidth}px)`} px={Theme.PaddingOnSides.StorePages} mx={'auto'}>
          <TopCategoryPick storeByDisplayName={activeStore?.key} />
        </Flex>
        <Flex
          maxWidth={`calc(100vw - ${scrollbarWidth}px)`}
          px={Theme.PaddingOnSides.StorePages}
          mx={'auto'}
          direction={'column'}
          pb={'2.5rem'}
          gap={isLargerThan768 ? 0 : 10}
        >
          {/* <EmployeeSpotlight storeByDisplayName={activeStore?.key} /> */}
          <Flex justifyContent={'center'}>
            <Button variant={'solid'} justifyContent={'center'} gap={4} onClick={handleClick}>
              {t('components.button.show_stores_on_map')}
              <TbMap2 />
            </Button>
          </Flex>
        </Flex>

        <Sheet isOpen={isMapSheetOpen} onClose={() => setMapSheetOpen(false)} snapPoints={[1, 0]} initialSnap={0}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content
              style={{
                height: '90vh',
                overflowY: 'auto',
                overscrollBehavior: 'contain',
                WebkitOverflowScrolling: 'touch',
              }}
            >
              <Flex direction={'column'} gap={2}>
                <Flex direction={'column'} alignItems={'center'}>
                  <Text color={'#C1C1C1'} fontSize={'16px'}>
                    {t('components.leaflet_map.aarhus')}
                  </Text>
                  <Heading fontSize={Theme.heading.headerMobile30px}>{t('components.leaflet_map.stores')}</Heading>
                </Flex>
                <LeafLetMap closeSheet={() => setMapSheetOpen(false)} />
                <Button
                  onClick={() => {
                    setMapSheetOpen(false)
                    setLocationPillSheetOpen(true)
                  }}
                >
                  {t('components.button.show_stores_on_map')}
                </Button>{' '}
              </Flex>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </Flex>
    </MainPageResponsive>
  )
}

export default withAuth(NewStoreLandingPage, [MainRole.RoleGuest])
