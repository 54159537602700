import { Card, CardBody, CardHeader, Flex, Icon, IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { IconChevronRight, IconSortAscending, IconSortDescending } from '@tabler/icons-react'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { AdminPartnerPortalPage } from '../../../../components/layout/partnerPortal/admin/AdminPartnerPortalPage'
import { MainRole, useGetMerchantApplicationsQuery } from '../../../../generated/graphql'
import withAuth from '../../../../utils/WithAuth'

const AdminOnboardingOverviewPage = () => {
  const onboardingApplications = useGetMerchantApplicationsQuery()
  const navigate = useNavigate()

  const data = useMemo(() => (onboardingApplications.loading ? [] : onboardingApplications.data?.getMerchantApplications), [onboardingApplications])
  const columns: any = useMemo(
    () => [
      {
        Header: t('partner_portal.admin.heading_onboarding_number'),
        accessor: 'onboardingNumber',
      },
      {
        Header: t('partner_portal.admin.heading_store_name'),
        accessor: 'onboardingApplication.storeInformation.storeName',
      },
      {
        Header: t('partner_portal.admin.heading_store_number'),
        accessor: 'onboardingApplication.storeInformation.storeNumber',
      },
      {
        Header: t('partner_portal.admin.heading_store_email'),
        accessor: 'onboardingApplication.storeInformation.storeEmail',
      },
      {
        Header: t('partner_portal.admin.heading_store_phone'),
        accessor: 'onboardingApplication.storeInformation.storePhone',
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({ row }: any) => (
          <IconButton
            onClick={() => navigate('/admin/onboarding/' + row.original.onboardingNumber)}
            aria-label='navigate'
            variant={'ghost'}
            icon={<Icon as={IconChevronRight} />}
          />
        ),
      },
    ],
    [],
  )
  const table = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination)

  return (
    <AdminPartnerPortalPage pageHeaderLabel={t('partner_portal.admin.page_header_label') as string}>
      <Card>
        <CardHeader></CardHeader>
        <CardBody padding={0}>
          <Table {...table.getTableProps}>
            <Thead>
              {table.headerGroups.map((headerGroup: any, index: number) => {
                return (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column: any, index: number) => {
                      return (
                        <Th h={'50px'} alignItems={'center'} {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                          <Flex justifyContent={'space-between'}>
                            {column.render('Header')}
                            <Flex h={5} w={5}>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <Icon h={5} w={5} as={IconSortDescending} />
                                ) : (
                                  <Icon h={5} w={5} as={IconSortAscending} />
                                )
                              ) : null}
                            </Flex>
                          </Flex>
                        </Th>
                      )
                    })}
                  </Tr>
                )
              })}
            </Thead>
            <Tbody color={'blackAlpha.600'} fontSize={'sm'} {...table.getTableBodyProps}>
              {table.page.map((row: any, index: number) => {
                table.prepareRow(row)
                return (
                  <>
                    <Tr
                      key={index}
                      _hover={{
                        bgColor: 'blackAlpha.50',
                      }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <>
                            <Td key={index} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </Td>
                          </>
                        )
                      })}
                    </Tr>
                  </>
                )
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </AdminPartnerPortalPage>
  )
}

export default withAuth(AdminOnboardingOverviewPage, [MainRole.RoleAdmin])
