import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft } from 'tabler-icons-react'
import { useReturn } from '../../../../context/OrderReturnContext'
import { ReturnItemQuantity } from '../../../../types/ReturnItemQuantity'
import { ReturnOrderItemCard } from '../components/ReturnOrderItemCard'

export const ReturnedItems = () => {
  const { order, returnItemListWithQuantity, advanceStep } = useReturn()
  const navigate = useNavigate()
  const initialWidth = window.innerWidth
  const isLargerThan768 = initialWidth >= 768
  return (
    <Flex gap={4} mx={isLargerThan768 ? 'auto' : 4} direction={'column'} w={isLargerThan768 ? '25%' : '100%'}>
      {' '}
      <Flex gap={4} alignItems={'center'}>
        <Icon bgColor={'transparent'} onClick={() => navigate('/return/login')} fontSize={'30px'} color={'black'} as={ArrowLeft} />
        <Flex direction={'column'}>
          <Text fontSize={'2xl'} fontWeight={'500'}>
            {t('main_app.return.components.steps.text_order_number', { orderNumber: order?.orderNumber })}
          </Text>
          <Text>{t('main_app.return.components.steps.text_returning')}</Text>
        </Flex>
      </Flex>
      {returnItemListWithQuantity.map((value: ReturnItemQuantity, index: number) => (
        <ReturnOrderItemCard key={index} returnOrderLineItem={value} showQuantityButton={true} showStatusTag={true} />
      ))}
      <Flex gap={2}>
        <Button
          fontSize={'18px'}
          fontWeight={400}
          bgColor={'blackAlpha.900'}
          _hover={{ bgColor: 'blackAlpha.900' }}
          w={'full'}
          height={14}
          isDisabled={order == null || returnItemListWithQuantity.filter((item) => item.quantity >= 1).length === 0}
          onClick={() => {
            advanceStep()
          }}
        >
          {t('components.button.continue')}
        </Button>
      </Flex>
    </Flex>
  )
}
