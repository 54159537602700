import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import EnTranslations from './translations/EN.json'
import DkTranslations from './translations/DK.json'

// the translations
const resources = {
  EN: {
    translation: EnTranslations,
  },
  DK: {
    translation: DkTranslations,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'DK',
  detection: {
    order: ['domainLanguageDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    caches: ['domainLanguageDetector'],
  },
  fallbackLng: 'EN',
  interpolation: {
    escapeValue: false,
  },
})

// Add the TranslationKeys type
export type TranslationKeys = keyof typeof EnTranslations

export default i18n
