import { Flex, Heading, Img } from '@chakra-ui/react'
import { MainPageDesktop } from '../../../../components/layout/main/desktop/MainPageDesktop'
import HeaderImage1 from '../../../../assets/images/AboutUs/HeaderImage1.png'
import HeaderImage2 from '../../../../assets/images/AboutUs/HeaderImage2.png'
import HeaderImage3 from '../../../../assets/images/AboutUs/HeaderImage3.png'
import HeaderImage4 from '../../../../assets/images/AboutUs/HeaderImage4.png'
import HeaderImage5 from '../../../../assets/images/AboutUs/HeaderImage5.png'
import HeaderImage6 from '../../../../assets/images/AboutUs/HeaderImage6.png'
import PiecesTwoGirls from '../../../../assets/images/AboutUs/PiecesTwoGirls.png'
import Rømerhus01 from '../../../../assets/images/AboutUs/Rømerhus01.png'
import Rømerhus02 from '../../../../assets/images/AboutUs/Rømerhus02.png'
import Rømerhus03 from '../../../../assets/images/AboutUs/Rømerhus03.png'
import CartIcon from '../../../../assets/images/AboutUs/CartIcon.png'
import StoreIcon from '../../../../assets/images/AboutUs/StoreIcon.png'
import LocationPinIcon from '../../../../assets/images/AboutUs/LocationPinIcon.png'
import { Theme } from '../../../../ThemeVariables'
import { t } from 'i18next'
import { MainPageResponsive } from '../../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { useEffect } from 'react'
import { useBreadcrumbs } from '../../../../context/BreadcrumbsContext'
export const AboutUsDesktop = () => {
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  return (
    <MainPageResponsive loading={false}>
      <Flex gap={Theme.gap.desktop * 2} direction={'column'} w={'full'} py={"2.5rem"} alignItems={'center'}>
        <Flex backgroundColor={'blackAlpha.300'} width={'100%'} h={'auto'}>
          <Flex alignItems={'center'} mx={'auto'}>
            <Flex>
              <Img maxW='100%' height='auto' src={HeaderImage4} alt='Header Image 4' />
            </Flex>
            <Flex padding={4} gap={4} direction={'column'}>
              <Img maxW='100%' height='auto' src={HeaderImage2} alt='Header Image 2' />
              <Img maxW='100%' height='auto' src={Rømerhus03} alt='Rømerhus 03' />
            </Flex>
            <Flex>
              <Img maxW='100%' height='auto' src={HeaderImage5} alt='Header Image 5' />
            </Flex>
            <Flex padding={4} gap={4} direction={'column'}>
              <Img maxW='100%' height='auto' src={Rømerhus01} alt='Rømerhus 01' />
              <Img maxW='100%' height='auto' src={HeaderImage3} alt='Header Image 3' />
            </Flex>

            <Flex>
              <Img maxW='100%' height='auto' src={HeaderImage6} alt='Header Image 6' />
            </Flex>
          </Flex>
        </Flex>
        <Flex direction={'column'} width={'95%'} maxWidth={'53rem'} gap={Theme.gap.desktop}>
          <Heading>{t('main_app.about_us.heading_about')}</Heading>
          <Flex backgroundColor={'white'} boxShadow={'lg'} padding={Theme.spacing.padding.desktop} borderRadius={Theme.spacing.borderRadius}>
            {t('main_app.about_us.text_comment_about_plenty')}
          </Flex>
        </Flex>
        <Flex width={'95%'} maxWidth={'53rem'} gap={Theme.gap.desktop} direction={{ base: 'column', md: 'row' }}>
          <Flex flex='1' direction={'column'} gap={Theme.gap.desktop}>
            <Heading fontSize={'2xl'}>{t('main_app.about_us.heading_digital_age')}</Heading>
            <Flex>{t('main_app.about_us.text_plenty_born')}</Flex>
          </Flex>
          <Flex flex='1'>
            <Img width='100%' height='auto' src={Rømerhus02} alt='Rømerhus 02' />
          </Flex>
        </Flex>
        {/* <Flex justifyContent={'center'} width={'95%'} maxWidth={'53rem'} gap={Theme.gap.desktop}>
          <Flex>
            <Img width='100%' maxWidth={'30rem'} height='auto' src={HeaderImage1} alt='Header Image 1' />
          </Flex>
          <Flex direction={'column'} gap={Theme.gap.desktop}>
            <Img maxW='50px' height='auto' src={LocationPinIcon} alt='Location Pin Icon' />
            <Img maxW='50px' height='auto' src={StoreIcon} alt='Store Icon' />
            <Img maxW='50px' height='auto' src={CartIcon} alt='Cart Icon' />
          </Flex>
        </Flex> */}
        <Flex width={'95%'} maxWidth={'53rem'} gap={Theme.gap.desktop} direction={'column'}>
          <Heading>{t('main_app.about_us.heading_why_plenty')}</Heading>
          <Flex gap={Theme.gap.desktop} direction={{ base: 'column', md: 'row' }}>
            <Flex gap={Theme.gap.desktop}>
              <Img src={LocationPinIcon} minW={'50px'} height={'50px'}></Img>
              <Flex direction={'column'}>
                <Heading fontSize={'xl'}>{t('main_app.about_us.heading_any_city')}</Heading>
                <Flex>{t('main_app.about_us.text_we_got_you_covered')}</Flex>
              </Flex>
            </Flex>
            <Flex gap={Theme.gap.desktop}>
              <Img src={StoreIcon} minW={'50px'} height={'50px'}></Img>
              <Flex direction={'column'}>
                <Heading fontSize={'xl'}>{t('main_app.about_us.heading_shop_in_store')}</Heading>
                <Flex>{t('main_app.about_us.text_discover')}</Flex>
              </Flex>
            </Flex>
            <Flex gap={Theme.gap.desktop}>
              <Img src={CartIcon} minW={'50px'} height={'50px'}></Img>
              <Flex direction={'column'}>
                <Heading fontSize={'xl'}>{t('main_app.about_us.heading_shop_online')}</Heading>
                <Flex>{t('main_app.about_us.text_shop_whenever')}</Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex width={'95%'} maxWidth={'53rem'} direction={{ base: 'column', md: 'row' }}>
          <Flex flex={1} direction={'column'} gap={Theme.gap.desktop}>
            <Heading fontSize={'2xl'}>{t('main_app.about_us.heading_discover_neighbourhood')}</Heading>
            <Flex>{t('main_app.about_us.text_soul_of_shopping')}</Flex>
            <Flex>{t('main_app.about_us.text_join_us')}</Flex>
          </Flex>
          <Flex flex={1} justifyContent={'center'}>
            {/* <Img maxW='100%' height='auto' src={PiecesTwoGirls} alt='Pieces Two Girls' /> */}
            <Img width='100%' maxWidth={'30rem'} height='auto' src={HeaderImage1} alt='Header Image 1' />
          </Flex>{' '}
        </Flex>
      </Flex>
    </MainPageResponsive>
  )
}
