import { Flex, Heading, HStack, Img } from '@chakra-ui/react'
import React from 'react'
import { t } from 'i18next'
import CustomLink from '../CustomLink'

type StoreProps = {
  storeName: string
  storeAddress: string
  to?: string
  scrollRef?: any
  size: string
  dragging?: any
  storeImageUrl: string
  closeSheet: (() => void) | undefined
}

export const StorePopupCard = (props: StoreProps) => {
  const chooseStore = () => {
    return props.storeImageUrl
  }

  const OpeningHours = () => {
    const currentTime = new Date()
    const currentHour = currentTime.getHours()
    const isOpen = currentHour >= 10 && currentHour < 19
    const openStyle = { color: 'green' }
    const closeStyle = { color: 'red' }

    return (
      <Flex>
        {isOpen ? (
          <React.Fragment>
            <Flex style={openStyle} mr={1}>
              {t('components.store_card.text_open')}{' '}
            </Flex>

            <Flex>{t('components.store_card.text_closes_at')}</Flex>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Flex style={closeStyle} mr={1}>
              {t('components.store_card.text_closed')}
            </Flex>

            <Flex>{t('components.store_card.text_opens_at')}</Flex>
          </React.Fragment>
        )}
      </Flex>
    )
  }

  const formatStoreName = (storeName: string) => {
    if (/^NAME IT/i.test(storeName)) {
      return 'NAME IT'
    }

    if (/^VERO MODA/i.test(storeName)) {
      return 'VERO MODA'
    }

    const nameArray = storeName.split(' ')
    const firstWord = nameArray[0]

    return firstWord.toUpperCase()
  }

  return (
    <CustomLink to={props.to} draggable={true} dragging={props.dragging}>
      <Flex borderRadius={'24px'} onClick={props.closeSheet}>
        <HStack w='100%'>
          {' '}
          <Flex position={'relative'} h={'auto'} cursor={'pointer'} flex='3'>
            {' '}
            <Img borderRadius={'24px 0px 0px 24px'} draggable={false} maxWidth={props.size} width={props.size} objectFit={'cover'} src={chooseStore()} />
          </Flex>
          <Flex maxW='100%' flex='4' alignItems={'center'} justifyContent={'space-around'} pr={2}>
            <Flex flex={8} textAlign={'start'} direction={'column'} wrap={'wrap'}>
              <Heading fontWeight={'600'} fontSize={'lg'}>
                {formatStoreName(props.storeName)}
              </Heading>
              <Flex fontSize={'sm'} fontWeight={'400'}>
                {props.storeAddress}
              </Flex>
              <Flex fontSize={'sm'}>
                <OpeningHours />
              </Flex>
            </Flex>
          </Flex>
        </HStack>
      </Flex>
    </CustomLink>
  )
}
