import { Flex, Icon, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { ArrowLeft, ExclamationMark } from 'tabler-icons-react'
import { useReturn } from '../../../../context/OrderReturnContext'
import { FindStore } from '../components/FindStore'
import { PostalService } from '../components/PostalService'

export const ReturnStoreOrShipping = () => {
  const { previousStep, order } = useReturn()

  const initialWidth = window.innerWidth
  const isLargerThan768 = initialWidth >= 768

  return (
    <>
      <Flex gap={4} mx={isLargerThan768 ? 'auto' : 4} direction={'column'} w={isLargerThan768 ? '50%' : '100%'}>
        <Flex gap={4} alignItems={'center'}>
          <Icon
            bgColor={'transparent'}
            onClick={() => {
              previousStep()
            }}
            fontSize={'30px'}
            color={'black'}
            as={ArrowLeft}
          />
          <Text fontSize={'2xl'} fontWeight={'500'}>
            {t('main_app.return.components.steps.text_order_number', { orderNumber: order?.orderNumber })}
          </Text>
        </Flex>
        <Flex>{t('main_app.return.components.steps.text_way_of_returning')}</Flex>
        <Flex direction={isLargerThan768 ? 'row' : 'column'} gap={4}>
          <FindStore />
          <PostalService />
        </Flex>

        <Flex bgColor={'mintGreen.500'} borderRadius={'3xl'} p={4}>
          <Icon bgColor={'transparent'} fontSize={'30px'} color={'black'} as={ExclamationMark} />
          <Flex> {t('main_app.return.components.steps.text_return_withing')}</Flex>
        </Flex>
      </Flex>
    </>
  )
}
