import { Avatar, Flex, Icon, InputGroup, InputLeftElement, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import { IconLogout, IconSearch } from '@tabler/icons-react'
import { t } from 'i18next'
import { useUser } from '../../../../context/Auth'
// import SearchFieldPartner from '../../../TextField/SearchFieldPartner'

export const Header = () => {
  const { signOut, store, user } = useUser()
  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} w={'full'} h={'36'} p={10}>
      {/* <InputGroup variant={'solid'}>
        <InputLeftElement pointerEvents='none'>
          <Icon as={IconSearch} />
        </InputLeftElement>
        <SearchFieldPartner variant={'solidWhite'} placeholder={t('components.layout.placeholder_search_order_customer') as string} />
      </InputGroup> */}
      <Flex alignItems={'center'}>
        <Menu>
          <MenuButton as={Avatar} cursor={'pointer'} size={'sm'} />
          <MenuList alignItems={'center'}>
            <MenuItem>
              <Flex flexDirection='column' ml={2}>
                <Flex>{store}</Flex>
                <Flex>{user?.getUsername()}</Flex>
              </Flex>
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={signOut} icon={<Icon w={6} h={6} as={IconLogout} />}>
              {t('components.button.log_out')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  )
}
