/* eslint-disable @typescript-eslint/no-explicit-any */
import withAuth from '../../../utils/WithAuth'
import { MainRole } from '../../../generated/graphql'
import { Theme } from '../../../ThemeVariables'
import { Button, Flex, FormControl, FormErrorMessage, Input, Text, VStack } from '@chakra-ui/react'
import { t } from 'i18next'
import { Field, Form, Formik } from 'formik'
import { useReturn } from '../../../context/OrderReturnContext'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'

const ReturnLoginPage = () => {
  const navigate = useNavigate()
  const initialWidth = window.innerWidth
  const isLargerThan768 = initialWidth >= 768
  const { checkOrderBelongsToEmail } = useReturn()
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  function validateOrderNumber(value: string) {
    let error
    if (!value) {
      error = 'Order number is required'
    }
    return error
  }
  function validateEmail(value: string) {
    let error
    if (!value) {
      error = 'Email is required'
    }
    return error
  }
  return (
    <MainPageResponsive loading={false}>
      <Flex
        mx={isLargerThan768 ? 'auto' : 4}
        direction={'column'}
        w={isLargerThan768 ? '25%' : '100%'}
        minW={'350px'}
        mt={20}
        bgColor={'white'}
        p={10}
        borderRadius={Theme.spacing.borderRadius}
      >
        <Flex mx={'auto'} gap={6} direction={'column'}>
          <Text fontSize={'2xl'}>{t('main_app.return.heading_return')}</Text>
          <Formik
            initialValues={{ orderNumber: '', email: '' }}
            onSubmit={async (values, actions) => {
              const orderIsFromCustomer = await checkOrderBelongsToEmail(values.orderNumber, values.email)
              actions.setValues(values)
              if (orderIsFromCustomer) {
                navigate('/return/item_selection')
              }
            }}
          >
            {(props) => (
              <Form>
                <VStack spacing={4}>
                  <Field name='orderNumber' validate={validateOrderNumber}>
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.orderNumber && form.touched.orderNumber}>
                        <Input
                          {...field}
                          placeholder={t('main_app.return.placeholder_order_number')}
                          bgColor={'white'}
                          _placeholder={{ color: 'black', fontSize: '14px', fontWeight: 400 }}
                          focusBorderColor='rgba(247, 247, 247, 1)'
                        />
                        <FormErrorMessage>{form.errors.orderNumber}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='email' validate={validateEmail}>
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <Input
                          {...field}
                          bgColor={'white'}
                          _placeholder={{ color: 'black', fontSize: '14px', fontWeight: 400 }}
                          focusBorderColor='rgba(247, 247, 247, 1)'
                          placeholder={t('main_app.return.placeholder_email')}
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Button
                    type='submit'
                    fontSize={'18px'}
                    fontWeight={400}
                    color={'white'}
                    bgColor={'blackAlpha.900'}
                    w={'full'}
                    p={Theme.spacing.padding.mobile}
                    isLoading={props.isSubmitting}
                  >
                    {t('components.button.continue')}
                  </Button>
                </VStack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </MainPageResponsive>
  )
}

export default withAuth(ReturnLoginPage, [MainRole.RoleGuest])
