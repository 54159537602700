import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import { ApolloError, useApolloClient } from '@apollo/client'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { ampli } from '../../../../ampli'
import { useCart } from '../../../../context/CheckoutCartContext'
import { CREATE_CART } from '../../../../gql/cart'
import { CREATE_ORDER_FROM_CART } from '../../../../gql/orders'
import { CREATE_TRANSACTION_SESSION } from '../../../../gql/payment'
import { orderHasDuplicateStyle, orderSize, orderValue, storeBasketAllocation } from '../../../../utils/AmplitudeUtil'

export const CheckoutPayment = () => {
  const { toggleCartDrawer, setStep, cartDeliveryOption, resetCart, parcelResponse, customerInformation, cart } = useCart()
  const navigate = useNavigate()
  const client = useApolloClient()
  const toast = useToast()
  const [orderNumber, setOrderNumber] = useState<string | null>(null)
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false)
  const [cartOpened, setCartOpened] = useState(false)

  const resetCartAndAdyenSession = () => {
    client.mutate({ mutation: CREATE_CART }).then((response: any) => {
      ampli.cartCreated()
      localStorage.setItem('cartId', response.data.createCart.id)
    })

    toggleCartDrawer()

    resetCart()

    setStep(0)

    setCartOpened(!cartOpened)
  }

  const setOrderNumberInLocalStorage = (orderNumber: string) => {
    localStorage.setItem('tempOrderNumber', orderNumber)
  }

  const getParcelShopInput = () => {
    if (cartDeliveryOption === 'ParcelShop') {
      return {
        id: parcelResponse?.parcelShop?.id,
        name: parcelResponse?.parcelShop?.name,
        type: parcelResponse?.parcelShop?.type,
        carrier: parcelResponse?.parcelShop?.carrier,
        addressLine1: parcelResponse?.parcelShop?.addressLine1,
        postCode: parcelResponse?.parcelShop?.postCode,
        city: parcelResponse?.parcelShop?.city,
        country: parcelResponse?.parcelShop?.country,
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    if (orderNumber && paymentSuccess) {
      navigate('/order/' + orderNumber)
    }
  }, [orderNumber, paymentSuccess])

  const configuration = (sessionId: string, sessionData: string) => {
    return {
      environment: process.env.REACT_APP_ADYEN_ENVIRONMENT, //Change to one of the environment values specified in step 4.
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY, //Public key used for client-side authentication: https:docs.adyen.com/development-resources/client-side-authentication
      session: {
        id: sessionId,
        sessionData: sessionData,
      },
      onPaymentCompleted: (result: any, component: any) => {
        switch (result.resultCode) {
          case 'Authorised':
            setPaymentSuccess(true)
            break
          case 'Cancelled':
            console.log('The payment was cancelled before processing was completed')
            toast({
              title: t('components.toast.error'),
              description: 'The payment was cancelled before processing was completed',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            break
          case 'Error':
            console.error('There was an error when the payment was being processed')
            toast({
              title: t('components.toast.error'),
              description: 'There was an error when the payment was being processed',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            break
          case 'Pending':
            console.log('Its not possible to obtain the final status of the payment at this time')
            toast({
              title: t('components.toast.error'),
              description: 'Its not possible to obtain the final status of the payment at this time',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            break
          case 'Refused':
            console.log('The payment was refused')
            toast({
              title: t('components.toast.error'),
              description: 'The payment was refused',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            break
        }

        resetCartAndAdyenSession()
        try {
          ampli.orderPlacement({
            delivery_option: cartDeliveryOption,
            has_duplicate_style: orderHasDuplicateStyle(cart),
            order_size: orderSize(cart),
            order_value: orderValue(cart),
            store_basket_allocation: storeBasketAllocation(cart),
          })
        } catch (error) {
          console.error('An error occurred when calling triggerAmplitudeEnviromentAware:', error)
        }
      },
      beforeSubmit: (data: any, component: any, actions: any) => {
        const parcelShopInput = getParcelShopInput()
        client
          .mutate({
            mutation: CREATE_ORDER_FROM_CART,
            variables: {
              cartId: localStorage.getItem('cartId'),
              customerInformation: {
                email: customerInformation.email,
                phone: customerInformation.phone,
                firstName: customerInformation.firstName,
                lastName: customerInformation.lastName,
                streetName: customerInformation.street,
                streetNumber: customerInformation.number,
                apartment: customerInformation.apartment,
                postalCode: customerInformation.postalCode,
                city: customerInformation.city,
                country: customerInformation.country,
              },
              shippingMethod: cartDeliveryOption,
              parcelShopInput: parcelShopInput !== null ? JSON.stringify(parcelShopInput) : null,
              checkoutSessionId: sessionId,
              merchantReference: localStorage.getItem('cartId'),
            },
          })
          .then((response: any) => {
            setOrderNumber(response.data.createOrderFromCart)
            setOrderNumberInLocalStorage(response.data.createOrderFromCart)
            actions.resolve(data)
          })
          .catch((error: ApolloError) => {
            toast({
              title: t('components.toast.error'),
              description: error.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            actions.reject()
          })
      },
      onError: (error: any, component: any) => {
        console.error(error.name, error.message, error.stack, component)
      },
    }
  }

  const createComponent = async () => {
    client
      .mutate({
        mutation: CREATE_TRANSACTION_SESSION,
        variables: {
          cartId: localStorage.getItem('cartId'),
          shippingMethod: cartDeliveryOption,
          phoneNumber: customerInformation.phone,
        },
      })
      .then(async (response: any) => {
        // Create an instance of AdyenCheckout using the configuration object.
        const checkout = await AdyenCheckout(configuration(response.data.createTransactionSession.id, response.data.createTransactionSession.sessionData))

        // Create an instance of Drop-in and mount it to the container you created.
        const dropinComponent = checkout.create('dropin').mount('#dropin-container')
      })
      .catch((error: ApolloError) => {
        toast({
          title: t('components.toast.error'),
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }

  useEffect(() => {
    if (
      customerInformation.firstName != '' &&
      customerInformation.lastName != '' &&
      customerInformation.email != '' &&
      customerInformation.phone != '' &&
      customerInformation.country != ''
    ) {
      createComponent()
    }
  }, [])

  return (
    <Flex>
      <div id='dropin-container'></div>
    </Flex>
  )
}
