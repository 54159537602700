import { gql } from '@apollo/client'

export const GET_ORDERS = gql`
  query getAllOrders {
    getAllOrders {
      id
      createdAt
      customerId
      orderState
      orderNumber
      totalPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      taxedPrice {
        totalGross {
          centAmount
          currencyCode
          fractionDigits
        }
        totalNet {
          centAmount
          currencyCode
          fractionDigits
        }
      }
      lineItems {
        id
        productKey
        quantity
        totalPrice {
          centAmount
          currencyCode
          fractionDigits
        }
        taxedPrice {
          totalGross {
            centAmount
            currencyCode
            fractionDigits
          }
          totalNet {
            centAmount
            currencyCode
            fractionDigits
          }
        }
        variant {
          sku
          attributes {
            name
            value
          }
        }
      }
      shippingInfo {
        shippingMethodName
      }
      customerEmail
    }
  }
`

export const CREATE_ORDER_FROM_CART = gql`
  mutation createOrderFromCart(
    $cartId: String!
    $customerInformation: CustomerInformation!
    $shippingMethod: String!
    $parcelShopInput: String
    $checkoutSessionId: String!
    $merchantReference: String!
  ) {
    createOrderFromCart(
      cartId: $cartId
      customerInformation: $customerInformation
      shippingMethod: $shippingMethod
      parcelShopInput: $parcelShopInput
      checkoutSessionId: $checkoutSessionId
      merchantReference: $merchantReference
    )
  }
`

export const CHANGE_ORDER_LINE_STATE = gql`
  mutation ChangeOrderLineState(
    $orderNumber: String!
    $lineItemIds: [String]!
    $distributionChannelId: String
    $lineItemDeclineReasons: [DeclineReasonInput]
    $merchantResponse: String
  ) {
    changeOrderLineState(
      orderNumber: $orderNumber
      lineItemIds: $lineItemIds
      distributionChannelId: $distributionChannelId
      lineItemDeclineReasons: $lineItemDeclineReasons
      merchantResponse: $merchantResponse
    ) {
      id
    }
  }
`

export const ADD_LINE_ITEM_TO_CART = gql`
  mutation addLineItemToCart($cartId: String, $styleNumber: String, $sku: String, $quantity: Int, $storeKey: String) {
    addLineItemToCart(cartId: $cartId, styleNumber: $styleNumber, sku: $sku, quantity: $quantity, storeKey: $storeKey) {
      id
      createdAt
      lastModifiedAt
      lineItems {
        id
        name
        price {
          id
          value {
            centAmount
            currencyCode
            fractionDigits
          }
        }
        productKey
        quantity
        totalPrice {
          centAmount
          currencyCode
          fractionDigits
        }
      }
      totalPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      version
    }
  }
`

export const GET_ANALYTICS = gql`
  query GetAnalytics($toDate: String!, $fromDate: String!) {
    getAnalytics(toDate: $toDate, fromDate: $fromDate) {
      activeProducts
      averageBasketValue
      averageOrderFullfillmentTime
      itemCancellationRate
      netSales
      newOrders
      returnAmount
      returns
      salesAfterCancellation
      salesBeforeCancellation
    }
  }
`

export const GET_ORDER_BY_ID = gql`
  query getOrderById($id: String!) {
    getOrderById(orderId: $id) {
      id
      orderState
      createdAt
      totalPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      lineItems {
        id
        productKey
        quantity
        totalPrice {
          centAmount
          currencyCode
          fractionDigits
        }
        variant {
          sku
        }
      }
    }
  }
`

export const GET_ORDERS_BY_AUTHENTICATED_MERCHANT = gql`
  query GetOrdersByAuthenticatedMerchant {
    getOrdersByAuthenticatedMerchant {
      id
      createdAt
      customerId
      orderState
      orderNumber
      totalPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      taxedPrice {
        totalGross {
          centAmount
          currencyCode
          fractionDigits
        }
        totalNet {
          centAmount
          currencyCode
          fractionDigits
        }
      }
      lineItems {
        id
        productKey
        quantity
        totalPrice {
          centAmount
          currencyCode
          fractionDigits
        }
        taxedPrice {
          totalGross {
            centAmount
            currencyCode
            fractionDigits
          }
          totalNet {
            centAmount
            currencyCode
            fractionDigits
          }
        }
        variant {
          sku
        }
        distributionChannel {
          id
        }
        state {
          state {
            id
          }
        }
      }
      shippingInfo {
        shippingMethodName
      }
      customerEmail
    }
  }
`

export const GET_ORDER_BY_ORDER_NUMBER = gql`
  query GetOrderByOrderNumber($orderNumber: String!) {
    getOrderByOrderNumber(orderNumber: $orderNumber) {
      createdAt
      customerId
      customerEmail
      id
      lineItems {
        variant {
          attributes {
            name
            value
          }
          images {
            url
          }
          id
          sku
        }
        totalPrice {
          centAmount
          currencyCode
          fractionDigits
        }
        taxedPrice {
          totalGross {
            currencyCode
            centAmount
            fractionDigits
          }
          totalNet {
            centAmount
            currencyCode
            fractionDigits
          }
        }
        quantity
        productKey
        id
        distributionChannel {
          id
          obj {
            key
            name
            address {
              city
              country
              postalCode
              streetName
              streetNumber
            }
          }
        }
        state {
          state {
            id
          }
        }
      }
      orderState
      orderNumber
      taxedPrice {
        totalNet {
          centAmount
          currencyCode
          fractionDigits
        }
        totalGross {
          centAmount
          currencyCode
          fractionDigits
        }
      }
      totalPrice {
        fractionDigits
        currencyCode
        centAmount
      }
      shippingInfo {
        shippingMethodName
      }
    }
  }
`

export const GET_SPLIT_ORDER_INFO = gql`
  query GetSplitOrderInfo($orderNumber: String!) {
    getSplitOrderInfo(orderNumber: $orderNumber) {
      state
      storeName
    }
  }
`

export const CHANGE_RETURN_ITEMS_STATE = gql`
  mutation changeReturnItemState($orderNumber: String, $returnLineItemIds: [String], $merchantResponse: MerchantReturnResponse) {
    changeReturnItemState(orderNumber: $orderNumber, returnLineItemIds: $returnLineItemIds, merchantResponse: $merchantResponse)
  }
`

export const GET_PLENTY_ORDERS_BY_AUTHENTICATED_MERCHANT = gql`
  query GetPlentyOrdersByAuthenticatedMerchant {
    getPlentyOrdersByAuthenticatedMerchant {
      id
      customerEmail
      orderNumber
      totalPrice
      paymentReference
      shippingKey
      orderStatuses {
        status
      }
      commerceToolsCustomerId
      createdAt
      orderLineItems {
        id
        ean
        size
        styleNumber
        color
        imageUrl
        name
        price
        quantity
        distributionChannel
        commerceToolsId
        orderLineItemStatuses {
          itemStatus
          createdAt
          returnReason
          quantity
          shipmentStatus
          trackingNumber
          paymentStatus
        }
        createdAt
      }
    }
  }
`

export const GET_PLENTY_RETURNS_BY_AUTHENTICATED_MERCHANT = gql`
  query GetPlentyReturnsByAuthenticatedMerchant {
    getPlentyReturnsByAuthenticatedMerchant {
      id
      customerEmail
      orderNumber
      totalPrice
      paymentReference
      shippingKey
      commerceToolsCustomerId
      createdAt
      orderStatuses {
        status
        createdAt
      }
      orderLineItems {
        id
        ean
        size
        styleNumber
        color
        imageUrl
        name
        price
        quantity
        distributionChannel
        commerceToolsId
        createdAt
        orderLineItemStatuses {
          itemStatus
          createdAt
          returnReason
          quantity
          shipmentStatus
          trackingNumber
          paymentStatus
          createdAt
        }
      }
    }
  }
`

export const GET_ORDER_BY_ORDER_NUMBER_AND_CUSTOMER_EMAIL = gql`
  query GetOrderByOrderNumberAndCheckCustomerOwnership($email: String, $orderNumber: String) {
    getOrderByOrderNumberAndCheckCustomerOwnership(email: $email, orderNumber: $orderNumber) {
      id
      customerEmail
      orderNumber
      totalPrice
      taxedPrice
      orderStatuses {
        status
        createdAt
      }
      paymentReference
      shippingKey
      createdAt
      commerceToolsCustomerId
      orderLineItems {
        id
        name
        imageUrl
        ean
        distributionChannel
        commerceToolsId
        color
        price
        taxedPrice
        quantity
        size
        styleNumber
        createdAt
        orderLineItemStatuses {
          itemStatus
          lineDeclineReason
          createdAt
          paymentStatus
          quantity
          returnReason
          shipmentStatus
          trackingNumber
        }
      }
    }
  }
`

export const GET_CUSTOMER_BY_ORDERNUMBER = gql`
  query GetCustomerByOrderNumber($orderNumber: String) {
    getCustomerByOrderNumber(orderNumber: $orderNumber) {
      firstName
      lastName
      email
      customerNumber
      id
      createdAt
      addresses {
        phone
        postalCode
        region
        state
        streetName
        streetNumber
        id
        country
        city
      }
    }
  }
`
