import { useEffect } from 'react'
import { MainPageResponsive } from '../../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { useBreadcrumbs } from '../../../../context/BreadcrumbsContext'
import { TermsOfUseInformationText } from '../components/TermsOfUseInformationText'

export const TermsOfUseDesktop = () => {
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  return (
    <MainPageResponsive loading={false}>
      <TermsOfUseInformationText />
    </MainPageResponsive>
  )
}
