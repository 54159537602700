import { Box, Flex, Grid, Spinner, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useProductSearch } from '../../../../context/ProductSearchContext'
import { ProductCard } from '../../PLP/components/ProductCard'
import { PlentyProduct } from '../../PLP/types'
import { SortBy } from '../../PLP/components/SortBy'
import { useProductSearchUtilities } from '../SearchUtil'

const defineSortType = (priceSort: string | undefined) => {
  if (priceSort === 'asc') {
    return 'Lowest price'
  } else if (priceSort === 'desc') {
    return 'Highest price'
  } else {
    return 'Most popular'
  }
}
const ProductList = () => {
  const { getInterestedInProductsByGender, handleShowMore } = useProductSearchUtilities()
  const { searchTerm, searchResults, totalResults, isLoadingProducts, priceSort, setPriceSort } = useProductSearch()
  const [sortType, setSortType] = useState(defineSortType(priceSort))

  const renderProducts = (products: PlentyProduct[]) => {
    return products.map((product) => <ProductCard key={product.masterVariant.key} product={product} />)
  }

  const handleSortChange = (sortType: string) => {
    switch (sortType) {
      case 'Most popular':
        setPriceSort('none')
        break
      case 'Lowest price':
        setPriceSort('asc')
        break
      case 'Highest price':
        setPriceSort('desc')
        break

      default:
        break
    }
  }

  const loadMoreData = async () => {
    if (isLoadingProducts) return
    handleShowMore()
  }

  // Handles infinite scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop
      const windowHeight = window.innerHeight
      const height = document.documentElement.scrollHeight - windowHeight
      const scrollPosition = scrollTop / height
      if (scrollPosition > 0.7) {
        loadMoreData()
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [searchResults, isLoadingProducts])

  return (
    <Flex direction={'column'} justifyContent={'center'}>
      <Box display={'flex'} justifyContent='space-between' fontSize={'16px'} mb={'28px'}>
        <Text marginBottom={4} fontSize={'md'} alignSelf='flex-end' visibility={isLoadingProducts ? 'hidden' : 'visible'}>
          {!isLoadingProducts && searchTerm != '' && searchResults.length > 0 && (
            <Trans i18nKey='search.text_products_count' count={totalResults} components={[<b key='0' />, <b key='1' />]} />
          )}
          {!isLoadingProducts && searchResults.length == 0 && t('search.text_interested_in')}
        </Text>

        <Box visibility={searchResults.length > 0 && !isLoadingProducts ? 'visible' : 'hidden'}>
          <SortBy sortType={sortType} setSortType={handleSortChange} />
        </Box>
      </Box>
      <Grid templateColumns={{ base: 'repeat(2, 2fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={'1rem'}>
        {searchResults.length > 0 ? renderProducts(searchResults) : !isLoadingProducts && renderProducts(getInterestedInProductsByGender())}
      </Grid>
      <Box
        textAlign={'center'}
        mb='20'
        mt='20'
        visibility={searchResults.length > 0 && totalResults && totalResults > searchResults.length ? 'visible' : 'hidden'}
      >
        {isLoadingProducts ? <Spinner /> : <div />}
      </Box>
    </Flex>
  )
}

export default ProductList
