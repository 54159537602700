import { Flex } from "@chakra-ui/react";

export const ClusterOfPillsForTopOfSite = () => {
  const Pill = ({ left, top, height }: any) => (
    <Flex
      width="300px"
      height={height}
      flexShrink={0}
      bg="var(--primary-beige-10, #EDEDE7)"
      position="absolute"
      zIndex={0}
      left={left}
      top={top}
      transform="rotate(135deg)"
      borderRadius="full"
      overflow={"hidden"}
      display={{ base: 'none', lg: 'flex' }} 

    />
  );

  return (
    <>
      {/* First pill */}
      <Pill left="-100px" top={0} height={"400px"}/>
      {/* Second pill */}
      <Pill left="100" top={-50} height={"500px"}/> {/* Adjust the space between the pills if needed */}
      {/* Third pill */}
      <Pill left="300px" top={-100} height={"700px"}/> {/* Adjust the space between the pills if needed */}
    </>
  );
};
