import { Flex, FormControl, FormErrorMessage, Icon, IconButton, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { IconPercentage, IconPlus } from '@tabler/icons-react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ErrorBox } from '../../../../components/ErrorBox'
import { useDiscountCreation } from '../../../../context/DiscountCreationContext'
import { MiddleLabelInput } from '../MiddleLabelInput'

type DiscountInputProps = {
  discountCardChecked: 'percentage' | 'bundle'
}

const PercentageInputSchema = Yup.object().shape({
  percentage: Yup.number().integer().min(1, 'Value must be 1 or greater').max(99, 'Value must be 99 or less'),
})

const BundleInputSchema = Yup.object().shape({
  triggerQuantity: Yup.number().integer().positive().required('This field is required').when('unit', (unit: any, schema) => {
    return unit[0] === 'KR'
      ? schema.min(2, 'Value must be greater than or equal to 2')
      : schema;
  }),
  discountedQuantity: Yup.number().integer().positive().required('This field is required.').when('unit', (unit: any, schema) => {
    return unit[0] === 'PCS'
      ? schema.moreThan(
          Yup.ref('triggerQuantity'),
          'Discounted quantity must be greater than trigger quantity when unit is "pcs"'
        )
      : schema;
  }),
  unit: Yup.string().required(),
})

export const DiscountInput = (props: DiscountInputProps) => {
  const discountCreation = useDiscountCreation()

  const percentageInputForm = useFormik({
    initialValues: {
      percentage: 0,
    },
    validationSchema: PercentageInputSchema,
    onSubmit: (values, actions) => {
      if (values.percentage != undefined) {
        discountCreation.addDiscountTool({ value: values.percentage })
        actions.setFieldValue('percentage', 0)
        discountCreation.setPercentage(values.percentage)
      }
    },
  })

  const bundleInputForm = useFormik({
    initialValues: {
      triggerQuantity: discountCreation.triggerQuantity,
      discountedQuantity: discountCreation.discountedQuantity,
      unit: discountCreation.unit
    },
    validationSchema: BundleInputSchema,
    onSubmit: (values, actions) => {
      if (values.triggerQuantity != undefined && values.discountedQuantity != undefined) {
        discountCreation.addDiscountTool({
          triggerQuantity: values.triggerQuantity,
          discountedQuantity: values.discountedQuantity,
          unit: values.unit,
        })
        actions.setFieldValue('triggerQuantity', 0)
        actions.setFieldValue('discountedQuantity', 0)
        actions.setFieldTouched('triggerQuantity', false)
        actions.setFieldTouched('discountedQuantity', false)
        discountCreation.setTriggerQuantity(values.triggerQuantity)
        discountCreation.setDiscountedQuantity(values.discountedQuantity)
      }
    },
  })

  switch (props.discountCardChecked) {
    case 'percentage':
      return (
        <Flex gap={3}>
          <Flex direction={'column'}>
            <FormControl isInvalid={!percentageInputForm.isValid}>
              <InputGroup variant={'simple'} w={'100px'}>
                <InputLeftElement pointerEvents='none'>
                  <Icon as={IconPercentage} />
                </InputLeftElement>
                <Input
                  name={'percentage'}
                  id={'percentage'}
                  value={percentageInputForm.values.percentage}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => percentageInputForm.handleChange(e)}
                  type={'number'}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      percentageInputForm.handleSubmit()
                    }
                  }}
                />
              </InputGroup>
              <FormErrorMessage>{percentageInputForm.errors.percentage}</FormErrorMessage>
            </FormControl>
          </Flex>

          <IconButton onClick={() => percentageInputForm.handleSubmit()} variant={'simple'} size={'lg'} aria-label='add' icon={<Icon as={IconPlus} />} />
        </Flex>
      )
    case 'bundle':
    return (
        <Flex gap={3}>
          <MiddleLabelInput
            bundleInputForm={bundleInputForm}
          />

          <IconButton
            onClick={() => {
              bundleInputForm.handleSubmit()
            }}
            variant={'simple'}
            size={'lg'}
            aria-label='add'
            icon={<Icon as={IconPlus} />}
          />
        </Flex>
      )
    case undefined:
      return <ErrorBox message='No discount type' />
  }
}
