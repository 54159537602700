import { LineItem } from '@commercetools/platform-sdk'
import { List } from 'lodash'
import moment from 'moment/moment'

export const formattetHoursSinceOrder = (createdAt: string) => {
  const momentParse = moment(createdAt)
  const duration = moment.duration(moment().diff(momentParse))

  const days = Math.floor(duration.asDays())
  const hours = Math.floor(duration.asHours()) % 24
  const minutes = Math.floor(duration.asMinutes()) % 60

  const formattedTime = `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm' : ''}`

  return formattedTime.trim() // Trim to remove trailing space if any
}

export const calculateTotalPrice = (lineItems: LineItem[]): number => {
  return lineItems.reduce((total, { price, quantity }) => {
    const effectivePrice = price.discounted?.value.centAmount ?? price.value.centAmount
    return total + effectivePrice * quantity
  }, 0)
}
