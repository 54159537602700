import { Flex, Radio, Text } from '@chakra-ui/react'
import { t } from 'i18next'

import { useState } from 'react'
import { useCart } from '../../context/CheckoutCartContext'
import { ChannelReference, Maybe } from '../../generated/graphql'
import { ClickAndCollectStoreCard } from '../CartDrawer/CheckoutStep/components/ClickAndCollectStoreCard'
import { ErrorBox } from '../ErrorBox'
import { ParcelShopPickerModal } from './ParcelShopPickerModal'

type DeliveryOptionsProps = {
  deliveryOption: 'Pickup' | 'Delivery'
  cartDeliveryOption: 'Pickup' | 'HomeDelivery' | 'ParcelShop'
  setCartDeliveryOption: (option: 'Pickup' | 'HomeDelivery' | 'ParcelShop') => void
}

export const DeliveryOptions = ({ deliveryOption, cartDeliveryOption, setCartDeliveryOption }: DeliveryOptionsProps) => {
  const { parcelResponse, setParcelResponse, getUniqueDistributionChannels } = useCart()
  const [parcelPickerOpen, setParcelPickerOpen] = useState(false)
  const [parcelShopPickerState, setParcelShopPickerState] = useState<'Awaiting' | 'Success' | 'Error'>('Awaiting')

  const openParcelPicker = () => {
    setParcelPickerOpen(true)
  }

  switch (deliveryOption) {
    case 'Delivery':
      return (
        <Flex gap={'1rem'} direction={'column'}>
          <ParcelShopPickerModal
            parcelResponse={parcelResponse}
            setParcelResponse={setParcelResponse}
            isOpen={parcelPickerOpen}
            setOpen={setParcelPickerOpen}
            setParcelShopPickerState={setParcelShopPickerState}
            setCartDeliveryOption={setCartDeliveryOption}
          />
          <Flex
            borderRadius={8}
            gap={'1rem'}
            bgColor={'beigeLight.400'}
            p={'1rem'}
            boxShadow={'0 4px 40px 0 #9da4a04f'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Radio variant={'plentyStyle'} onClick={openParcelPicker} isChecked={cartDeliveryOption == 'ParcelShop' && parcelResponse != null} size={'lg'} />
            <Flex direction={'column'} flex={1}>
              <Flex direction={'column'}>
                <Text>Pick a parcel shop</Text>
                <Flex minH={'25px'} alignItems={'center'} justifyContent={'space-between'}>
                  <Text>Delivery time: 1 - 4 weekdays</Text>
                  <Text fontSize={'lg'} fontWeight={'bold'}>
                    29 kr
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            borderRadius={8}
            gap={'1rem'}
            bgColor={'beigeLight.400'}
            p={'1rem'}
            boxShadow={'0 4px 40px 0 #9da4a04f'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Radio variant={'plentyStyle'} onClick={() => setCartDeliveryOption('HomeDelivery')} isChecked={cartDeliveryOption == 'HomeDelivery'} size={'lg'} />
            <Flex direction={'column'} flex={1}>
              <Text>Home delivery</Text>
              <Flex minH={'25px'} alignItems={'center'} justifyContent={'space-between'}>
                <Text>Delivery time: 1 - 4 weekdays</Text>
                <Text fontSize={'lg'} fontWeight={'bold'}>
                  39 kr
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )
    case 'Pickup':
      return (
        <Flex gap={'0.5rem'} direction={'column'}>
          <Text>Free in store pick-up</Text>
          <Flex gap={'1rem'} direction={'column'}>
            {getUniqueDistributionChannels().map((value: Maybe<ChannelReference> | undefined, index: number) => {
              return <ClickAndCollectStoreCard key={value?.id} distributionChannel={value} />
            })}
          </Flex>
        </Flex>
      )
    default:
      return <ErrorBox message={t('components.cart.steps.error_delivery_step')} />
  }
}
