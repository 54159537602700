export const Theme = {
  colors: {
    bg: 'beigeLight.500',
    borderColor: 'blackAlpha.100',
    activeBorderColor: 'mintGreen.500',
    plentyGreen: 'mintGreen.500',
    colorSchemes: {
      blackMint: {
        bg: 'darkGreen.500',
        contentColor: 'white.500',
        fragranceColor: 'mintGreen.500',
        iconColor: 'white.500',
      },
      blackWhite: {
        bg: 'white.500',
        contentColor: 'black.500',
        fragranceColor: 'black.500',
        iconColor: 'black.500',
      },
    },
  },
  util: {
    cardShadow: 'drop-shadow(0px 8px 16px #000)',
  },
  spacing: {
    margin: 4,
    padding: {
      mobile: 4,
      desktop: 6,
    },
    headerHeight: '74px',
    borderRadius: '3xl',
  },
  heading: {
    headerMobile30px: '30px',
    headerMobile54px: '54px',
  },
  iconSize: {
    sm: '14px',
    md: '16px',
    lg: '20px',
    xl: '24px',
    xxl: '32px',
  },
  gap: {
    mobile: 4,
    desktop: 6,
    BetweenComponents: 24,
  },
  borderRadius: {
    button: '54px',
    Images: '24px'
  },
  PaddingOnSides: {
    LandingPages: ["1rem", "8.5%"], // first number is for mobile and second number is for desktop
    StorePages: ["1rem", "4%"],// first number is for mobile and second number is for desktop
    PDP: ["1rem", "2.5%"],// first number is for mobile and second number is for desktop
    PLP: ["1rem", "5.5%"],// first number is for mobile and second number is for desktop
    Footer: ["2.5rem", "2rem"],
    Header: ["1rem", "3.5rem "]
  },
  components: {
    Text: {
      baseStyle: {
        fontSize: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
    },
  },
}
