import { useState, useEffect, Dispatch, SetStateAction } from 'react'

type UpdateGlobalStateType = (newState: Set<string> | null) => void

export const useColorSelector = (
  selectedColors: Set<string> | null,
  updateGlobalState: UpdateGlobalStateType,
): [Set<string> | null, Dispatch<SetStateAction<Set<string> | null>>] => {
  const [localState, setLocalState] = useState<Set<string> | null>(selectedColors)

  useEffect(() => {
    if (localState !== null) {
      updateGlobalState(localState)
    }
  }, [localState])

  useEffect(() => {
    setLocalState(selectedColors)
  }, [selectedColors])

  return [localState, setLocalState]
}
