/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull plentysdk'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/plenty-and/Plenty%26/implementation/plentysdk)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '0b0ec98749b7143166f376ce3f68b45b',
  development: '1c1aac7299f8f88df175db26ca991735'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '2',
    branch: 'main',
    source: 'plentysdk',
    versionId: '4f7366f4-b037-4184-8b96-18e71b6e2894'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface FilterProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filters: string[];
}

export interface MenuItemClickProperties {
  item: string;
}

export interface OrderPlacementProperties {
  delivery_option: string;
  has_duplicate_style: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  order_size: number;
  /**
   * OBS!!! This is in Dansk Krone(DKK) currency
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  order_value: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  store_basket_allocation: number;
}

export interface PageViewPerspectiveProperties {
  perspective: string;
}

export interface PdpVisitProperties {
  product_id: string;
}

export interface SearchedForProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  color: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  offset: number;
  searchedCategory: string;
  searchTerm: string;
  selectedGender: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalResults: number;
}

export interface StoreViewProperties {
  store_name: string;
}

export class CartCreated implements BaseEvent {
  event_type = 'CartCreated';
}

export class Filter implements BaseEvent {
  event_type = 'Filter';

  constructor(
    public event_properties: FilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MenuItemClick implements BaseEvent {
  event_type = 'MenuItemClick';

  constructor(
    public event_properties: MenuItemClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrderPlacement implements BaseEvent {
  event_type = 'OrderPlacement';

  constructor(
    public event_properties: OrderPlacementProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PageViewPerspective implements BaseEvent {
  event_type = 'PageViewPerspective';

  constructor(
    public event_properties: PageViewPerspectiveProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PdpVisit implements BaseEvent {
  event_type = 'PDPVisit';

  constructor(
    public event_properties: PdpVisitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchedFor implements BaseEvent {
  event_type = 'searchedFor';

  constructor(
    public event_properties: SearchedForProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StoreView implements BaseEvent {
  event_type = 'StoreView';

  constructor(
    public event_properties: StoreViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * CartCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest/CartCreated)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  cartCreated(
    options?: EventOptions,
  ) {
    return this.track(new CartCreated(), options);
  }

  /**
   * Filter
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest/Filter)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filters)
   * @param options Amplitude event options.
   */
  filter(
    properties: FilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new Filter(properties), options);
  }

  /**
   * MenuItemClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest/MenuItemClick)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. item)
   * @param options Amplitude event options.
   */
  menuItemClick(
    properties: MenuItemClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new MenuItemClick(properties), options);
  }

  /**
   * OrderPlacement
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest/OrderPlacement)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. delivery_option)
   * @param options Amplitude event options.
   */
  orderPlacement(
    properties: OrderPlacementProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrderPlacement(properties), options);
  }

  /**
   * PageViewPerspective
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest/PageViewPerspective)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. perspective)
   * @param options Amplitude event options.
   */
  pageViewPerspective(
    properties: PageViewPerspectiveProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageViewPerspective(properties), options);
  }

  /**
   * PDPVisit
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest/PDPVisit)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product_id)
   * @param options Amplitude event options.
   */
  pdpVisit(
    properties: PdpVisitProperties,
    options?: EventOptions,
  ) {
    return this.track(new PdpVisit(properties), options);
  }

  /**
   * searchedFor
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest/searchedFor)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. color)
   * @param options Amplitude event options.
   */
  searchedFor(
    properties: SearchedForProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchedFor(properties), options);
  }

  /**
   * StoreView
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/plenty-and/Plenty%26/events/main/latest/StoreView)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. store_name)
   * @param options Amplitude event options.
   */
  storeView(
    properties: StoreViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new StoreView(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
