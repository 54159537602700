import { Flex, Icon, Radio, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'

type RadioCardProps = {
  icon: any
  radioLabel: string
  cardText: string
  isChecked: boolean
  onClick: () => any
  footer?: ReactElement
}

export const RadioCard = (props: RadioCardProps) => {
  return (
    <Flex
      h={'fit-content'}
      onClick={() => props.onClick()}
      cursor={'pointer'}
      direction={'column'}
      gap={3}
      borderWidth={1}
      borderColor={props.isChecked ? 'mintGreen.500' : 'darkGreen.500'}
      borderRadius={16}
      padding={'16px'}
      minW={'284px'}
    >
      <Icon as={props.icon} h={6} w={6} />
      <Radio variant={'plentyStyle'} size={'lg'} isChecked={props.isChecked}>
        {props.radioLabel}
      </Radio>
      <Text>{props.cardText}</Text>
      {props.footer}
    </Flex>
  )
}
