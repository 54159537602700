import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalFooter,
  Stack,
  Tag,
  Text,
  useToast
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight, IconPlus, IconTrash } from '@tabler/icons-react'
import { useMemo } from 'react'
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { Toast } from '../../../../components/PlentyUI/Toast'
import { useDiscountCreation } from '../../../../context/DiscountCreationContext'
import { VariantEntry } from '../../../../types/VariantEntry'
import { ProductsAndCategoriesTable } from './ProductsAndCategoriesTable'
import { ProductSelectionDropdown } from './ProductSelectionDropdown'
import { SummaryBar } from './SummaryBar'

type CreateDiscountProductsStepProps = {
  wizard: any
}

export const CreateDiscountProductsStep = (props: CreateDiscountProductsStepProps) => {
  const discountCreation = useDiscountCreation()
  const toast = useToast()

  const data = useMemo(() => discountCreation.tableEntries, [discountCreation.tableEntries])
  const columns: any = useMemo(
    () => [
      {
        id: 'expander',
        Cell: ({ row }: any) =>
          row.original.productEntry ? <Icon {...row.getToggleRowExpandedProps()} w={5} h={5} as={row.isExpanded ? IconChevronDown : IconChevronRight} /> : null,
      },
      {
        accessor: 'imageUrl',
        Cell: ({ row, value }: any) => {
          if (value) {
            return (
              <Flex position={'relative'} w={10}>
                <Img borderRadius={8} objectFit={'contain'} w={'40px'} h={'54px'} src={value} />
                {row.original.productEntry ? (
                  <Tag
                    position={'absolute'}
                    bottom={'12.5%'}
                    right={'0'}
                    size={'sm'}
                    h={'fit-content'}
                    w={'fit-content'}
                    variant={'subtle'}
                    colorScheme={'green'}
                  >
                    {row.original.productEntry.variantEntries.filter((ve: VariantEntry) => ve.isIncluded == true).length}
                  </Tag>
                ) : null}
              </Flex>
            )
          } else {
            return <Box w={'10px'} h={'30px'}></Box>
          }
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Category',
        Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.category}</Text> : null),
      },
      {
        Header: 'Price',
        Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.price}</Text> : null),
      },
      {
        Header: 'Style/ean number',
        Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.styleNumber}</Text> : null),
      },
      {
        Header: 'Action(s)',
        Cell: ({ row, value }: any) => {
          return (
            <HStack>
              <IconButton
                onClick={() => discountCreation.removeTableEntry(row.original)}
                variant={'ghost'}
                aria-label='delete'
                icon={<Icon color={'red.500'} w={5} h={5} as={IconTrash} />}
              />
              {row.original.productEntry ? (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label={'add'}
                    variant={'ghost'}
                    fontWeight={'normal'}
                    icon={<Icon color={'mintGreen.800'} as={IconPlus} />}
                    isDisabled={row.original.productEntry.variantEntries.filter((ve: VariantEntry) => ve.isIncluded === false).length == 0}
                  />
                  <MenuList>
                    {row.original.productEntry.variantEntries.map((value: VariantEntry, index: number) => {
                      return !value.isIncluded ? (
                        <MenuItem
                          key={index}
                          gap={3}
                          onClick={() => {
                            discountCreation.showVariantByEan(value.ean)
                            toast({
                              position: 'top',
                              render: () => <Toast type='success' label={'Variant with EAN ' + value.ean + ' added'} />,
                            })
                          }}
                        >
                          <Img borderRadius={8} w={10} src={value.imageUrl} />
                          <Stack>
                            <Heading size={'sm'}>{value.name}</Heading>
                            <Text>EAN: {value.ean}</Text>
                            <Text>Size: {value.size}</Text>
                          </Stack>
                        </MenuItem>
                      ) : null
                    })}
                  </MenuList>
                </Menu>
              ) : null}
            </HStack>
          )
        },
      },
    ],
    [data],
  )

  const tableOptions = {
    autoResetExpanded: false,
  }

  const table = useTable({ columns, data, ...tableOptions }, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination)

  return (
    <Flex direction={'column'} position={'relative'}>
      <SummaryBar
        info={[
          discountCreation.campaignName,
          discountCreation.validFrom.toDateString() + ' to ' + (discountCreation.validTo ? discountCreation.validTo.toDateString() : 'continuous'),
        ]}
      />
      <Flex minH={'500px'} gap={6} p={6} direction={'column'}>
        <Flex h={'125px'} direction={'column'} gap={6}>
          <Heading fontSize={'2xl'}>Select products</Heading>

          <Flex zIndex={'sticky'} position={'relative'} flex={1}>
            <ProductSelectionDropdown />
          </Flex>
        </Flex>

        <ProductsAndCategoriesTable columns={columns} table={table} subRow={<Flex>LMAO</Flex>} discountCreation={discountCreation} />
      </Flex>

      <ModalFooter borderTop={'1px'} borderTopColor={'beigeLight.500'}>
        <Button isDisabled={!props.wizard.canReduce} mr={3} onClick={() => props.wizard.reduceStep()}>
          Previous
        </Button>
        <Button isDisabled={!props.wizard.canAdvance || discountCreation.tableEntries.length === 0} mr={3} onClick={() => props.wizard.advanceStep()}>
          Next
        </Button>
      </ModalFooter>
    </Flex>
  )
}
