import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, ListItem, UnorderedList } from '@chakra-ui/react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

export const ReturnAndRefundsAccordion = () => {
  const navigate = useNavigate()

  return (
    <Flex direction='column' w='full' alignItems={'start'} alignContent={'start'}>
      <Heading borderRadius={'20px'} fontSize={'3xl'} padding={4} width={'full'}>
        {t('main_app.faq.return_and_refunds.headline_retrun_refunds')}
      </Heading>

      <Accordion allowToggle w={'full'}>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.return_and_refunds.accordion_button_how')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>
          <AccordionPanel>
            <Box display='inline' mr={1}>
              {t('main_app.faq.return_and_refunds.accordion_panel_1.0')}
            </Box>
            <Box as='span' onClick={() => navigate('/return/login')} cursor='pointer' color='#CC5F5F' display='inline'>
              {t('main_app.faq.return_and_refunds.accordion_panel_1.1')}
            </Box>
            <Box display='inline'>{t('main_app.faq.return_and_refunds.accordion_panel_1.2')}</Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.return_and_refunds.accordion_panel_2.0')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>
            <Box display='inline'> {t('main_app.faq.return_and_refunds.accordion_panel_2.0')}</Box>
            <Box as='span' onClick={() => navigate('/return/login')} cursor='pointer' color='#CC5F5F' display='inline'>
              {' '}
              {t('main_app.faq.return_and_refunds.accordion_panel_2.1')}
            </Box>
            <Box display='inline'>.</Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Accordion allowToggle w={'full'}>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.return_and_refunds.accordion_button_exchange')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>
          <AccordionPanel>{t('main_app.faq.return_and_refunds.accordion_panel_3')}</AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.return_and_refunds.accordion_button_incorrect_amount')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>
            <UnorderedList>
              <ListItem>{t('main_app.faq.return_and_refunds.accordion_panel_4.0')} </ListItem>
              <ListItem>{t('main_app.faq.return_and_refunds.accordion_panel_4.1')} </ListItem>
              <ListItem>{t('main_app.faq.return_and_refunds.accordion_panel_4.2')}</ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}
