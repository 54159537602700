import { createContext, Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type LocationContextType = {
  city: number | undefined | null
  cityName: string | undefined
  storeLocation: number | undefined | null
  storeLocationName: string | undefined
  storeLocationDisplayName: string | undefined | null
  pillType: string | undefined
  setCity: Dispatch<SetStateAction<number | undefined>>
  changeCity: (id: number | undefined, name: string | undefined) => void
  changeStore: (id: number | undefined, name: string | undefined, displayName: string | undefined | null) => void
  changeCityAndStore: (
    id: number | undefined,
    cityName: string | undefined,
    storeNumber: number | undefined | null,
    storeName: string | undefined,
    displayName: string | undefined | null,
  ) => void
  lego: () => void
  setStoreLocation: Dispatch<SetStateAction<number | undefined | null>>
}
type ContextStatusType = 'unset' | 'finished'

const LocationContext = createContext<LocationContextType>({
  city: undefined,
  cityName: undefined,
  storeLocation: undefined,
  storeLocationName: undefined,
  storeLocationDisplayName: undefined,
  pillType: undefined,
  setCity: () => undefined,
  setStoreLocation: () => undefined,
  changeCity: () => undefined,
  changeStore: () => undefined,
  changeCityAndStore: () => undefined,
  lego: () => undefined,
})
export default function LocationPillContext({ children }: { children: ReactElement | ReactElement[] }) {
  const [city, setCity] = useState<number | undefined>()
  const [cityName, setCityName] = useState<string | undefined>()
  const [storeLocation, setStoreLocation] = useState<number | undefined | null>()
  const [storeLocationName, setStoreLocationName] = useState<string | undefined>()
  const [storeLocationDisplayName, setStoreLocationDisplayName] = useState<string | undefined | null>()
  const [status, setStatus] = useState<ContextStatusType>('unset')
  const [pillType, setPillType] = useState<string | undefined>()
  const [startRedirect, setStartRedirect] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!cityName && !storeLocationName) setPillType('city')
    if (cityName && !storeLocationName) setPillType('store')
    if (cityName && storeLocationName) setPillType('both')
  }, [cityName, storeLocationName])

  useEffect(() => {
    if (startRedirect) navigateToPage()
    setStartRedirect(false)
  }, [startRedirect])
  const navigateToPage = () => {
    if (pillType === 'city' && cityName) navigate(`/cities/${cityName?.toLowerCase()}`)
    if (pillType === 'store' && storeLocationName) navigate(`/stores/${storeLocationDisplayName}`)
    if (pillType === 'both' && !storeLocationName) navigate(`/cities/${cityName?.toLowerCase()}`)
    if (pillType === 'both' && storeLocationName) navigate(`/stores/${storeLocationDisplayName}`)
    if (pillType === 'city' && !cityName && !storeLocationName) navigate('/')
  }

  const changeCity = (id: number | undefined, name: string | undefined) => {
    setCity(id)
    setCityName(name)
  }

  const changeStore = (storeNumber: number | undefined, name: string | undefined, displayName: string | undefined | null) => {
    setStoreLocation(storeNumber)
    setStoreLocationName(name)
    setStoreLocationDisplayName(displayName)
  }

  const changeCityAndStore = (
    id: number | undefined,
    cityName: string | undefined,
    storeNumber: number | null | undefined,
    storeName: string | undefined,
    displayName: string | undefined | null,
  ) => {
    setCity(id)
    setCityName(cityName)
    setStoreLocation(storeNumber)
    setStoreLocationName(storeName)
    setStoreLocationDisplayName(displayName)
  }

  const lego = () => {
    setStartRedirect(true)
  }

  return (
    <LocationContext.Provider
      value={{
        city,
        cityName,
        setCity,
        changeCity,
        changeStore,
        changeCityAndStore,
        lego,
        pillType,
        storeLocation,
        storeLocationName,
        storeLocationDisplayName,
        setStoreLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export const useLocationPill = () => useContext(LocationContext)
