import { Flex, Img, Tag, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import moment from 'moment'
import { Maybe, Order } from '../../../../../generated/graphql'

type LandingPageOrderLineProps = {
  order: Maybe<Order>
}

export const LandingPageOrderLine = (props: LandingPageOrderLineProps) => {
  return (
    <Flex justifyContent={'space-between'}>
      <Flex direction={'column'} gap={2}>
        <Flex fontWeight={'bold'}>
          <Tag gap={2}>
            {'Order#'}
            <b>{props.order?.orderNumber}</b>
          </Tag>
        </Flex>
        <Flex fontSize={'sm'} color={'blackAlpha.700'}>
          {props.order?.lineItems.length} {t('partner_portal.merchant.landing.components.text_products')}
        </Flex>
      </Flex>
      <Flex direction={'column'} gap={2} alignItems={'end'}>
        <Flex fontSize={'sm'} color={'blackAlpha.700'}>
          {moment(props.order?.createdAt).format('LL')}
        </Flex>
        <Flex fontSize={'sm'} color={'blackAlpha.700'}>
          {moment(props.order?.createdAt).format('HH:mm')}
        </Flex>
      </Flex>
    </Flex>
  )
}
