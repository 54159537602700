import { Flex, Heading, Text } from '@chakra-ui/react'
import { MainPageDesktop } from '../../../../components/layout/main/desktop/MainPageDesktop'
import { Theme } from '../../../../ThemeVariables'

import { GenderCategoryCardRows } from '../../../../components/categoryCard/GenderCategoryCard/GenderCategoryCardRows/GenderCategoryCardRows'
import { t } from 'i18next'
import { MainPageResponsive } from '../../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { useEffect } from 'react'
import { useBreadcrumbs } from '../../../../context/BreadcrumbsContext'

export const NotFoundPageDesktop = () => {
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  return (
    <MainPageResponsive loading={false}>
      <Flex overflow={'auto'} flex={1} direction={'column'} justifyContent={'space-between'} gap={Theme.gap.desktop * 1.5}>
        <Flex textAlign={'center'} direction={'column'} gap={Theme.gap.desktop} alignItems={'center'}>
          <Heading fontSize={'5xl'}>{t('main_app.not_found_page.heading_header')}</Heading>
          <Heading fontSize={'xl'}>{t('main_app.not_found_page.heading_message')}</Heading>
          <Flex>
            <GenderCategoryCardRows />
          </Flex>
        </Flex>
        <Flex bgColor='blackAlpha.900'>
          <Flex padding={'80px 56px'} direction='column'>
            <Heading color='mintGreen.500' fontSize='5xl'>
              {t('main_app.not_found_page.heading_need_help')}
            </Heading>
            <Text color='white'>{t('main_app.not_found_page.text_information_about_help')}</Text>
          </Flex>
        </Flex>
      </Flex>
    </MainPageResponsive>
  )
}
