import { useApolloClient } from '@apollo/client'
import { Flex, Image, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BundleDiscount } from '../../../../generated/graphql'
import { GET_MOST_VALUABLE_BUNDLE_DISCOUNT_BY_PRODUCT_KEY } from '../../../../gql/discount'
import { findProductVariantAttributeDefaultEnglish } from '../../../../utils/ProductUtil'
import { ProductCardProps } from '../types'
import { DiscountTag } from './DiscountTag'
import { ProductPrice } from './ProductPrice'

export const ProductCard = ({ product, storeKey, channelId }: ProductCardProps) => {
  const navigate = useNavigate()
  const client = useApolloClient()

  const [bundleDiscount, setBundleDiscount] = useState<BundleDiscount | null>(null)

  useEffect(() => {
    client
      .query({
        query: GET_MOST_VALUABLE_BUNDLE_DISCOUNT_BY_PRODUCT_KEY,
        variables: {
          productKey: product.key,
        },
      })
      .then((response: any) => {
        setBundleDiscount(response.data.getMostValuableBundleDiscountByProductKey)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [])

  const handleCardClick = () => {
    if (storeKey) {
      navigate(`/product/${product.key}?variant=${product.masterVariant.key}&store=${storeKey}`)
    } else {
      navigate(`/product/${product.key}?variant=${product.masterVariant.key}`)
    }
  }

  return (
    <Flex direction={'column'} gap={3} cursor={'pointer'} key={product.masterVariant.key} onClick={() => handleCardClick()}>
      {product.masterVariant.images[0] && (
        <Flex position={'relative'}>
          <Image borderRadius={'1.5rem'} style={{ aspectRatio: '3 / 4' }} width='100%' objectFit='cover' src={product.masterVariant.images[0].url} />
          <DiscountTag product={product} bundle={bundleDiscount} placement={{ left: 5, top: undefined, right: undefined, bottom: 5 }} />
        </Flex>
      )}

      <Flex direction={'column'} gap={'0.5rem'}>
        <Text variant={'textProductCardBrand'}>{findProductVariantAttributeDefaultEnglish(product.masterVariant, 'brand')}</Text>
        <Flex direction={'column'} gap={2}>
          <Text variant={'textProductCardName'}>{product.name.en}</Text>
          <ProductPrice product={product} channelId={channelId} />
        </Flex>
      </Flex>
    </Flex>
  )
}
