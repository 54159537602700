import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Category, ChannelReference } from '@commercetools/platform-sdk'
import { Box, VStack } from '@chakra-ui/react'
import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { useLocation, useParams } from 'react-router-dom'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { t } from 'i18next'
import { ProductsList } from './components/ProductsList'
import { CategorySelector } from './components/CategorySelector'
import { convertPathToKey, getCategoryWithIdAndName, getChannelIdByStoreKey } from './utils'
import { useCTFilter } from '../../../context/CTFilterContext'
import { useQueryParam } from '../../../hooks/useQueryParam'
import PageSkeleton from './components/PageSkeleton'
import { CurrentCategoriesProps } from './types'
import { NotFoundPageDesktop } from '../not_found_page/desktop/NotFoundPageDesktop'
import i18n from '../../../I18n/i18n'
import { Theme } from '../../../ThemeVariables'
import { MainRole } from '../../../generated/graphql'
import withAuth from '../../../utils/WithAuth'

const PLP = () => {
  const { setActiveBreadcrumbs } = useBreadcrumbs()
  const location = useLocation()
  const params = useQueryParam()

  const {
    fetchProducts,
    onOpen,
    products,
    fetchCategories,
    currentCategories,
    showMoreProducts,
    existMoreProducts,
    isLoadingProducts,
    isLoadingMoreProducts,
    setSelectedBrands,
    priceSort,
    setPriceSort,
  } = useCTFilter()

  const prevCategoryPath: MutableRefObject<string | undefined> = useRef()
  const prevCurrentCategories: MutableRefObject<CurrentCategoriesProps | undefined> = useRef()
  const [showNotFoundPage, setShowNotFoundPage] = useState(false)
  const [loadingChannelId, setLoadingChannelId] = useState(false)
  const [channelId, setChannelId] = useState<string | undefined>()

  useEffect(() => {
    const storeKey = params.get('store')
    if (storeKey) {
      getChannelIdByStoreKey(storeKey)
        .then((response: ChannelReference | undefined) => {
          if (response) {
            setChannelId(response.id)
          }
        })
        .finally(() => {
          setLoadingChannelId(false)
        })
    } else {
      setLoadingChannelId(false)
    }
  }, [channelId])

  const { '*': categoryPath } = useParams()

  const formatBreadcrumbTitle = (str: string) => {
    if (str.toLowerCase() === 'handbags') {
      return 'Handbags'
    }
    if (str.toLowerCase() === 'sandals') {
      return 'Sandals'
    }

    const categoryKeyRegex = /(girl|boy|baby)_(\d+)_(\d+)/i
    if (categoryKeyRegex.test(str)) {
      return str.replace(
        categoryKeyRegex,
        (match, category, startAge, endAge) => `${category.charAt(0).toUpperCase() + category.slice(1)} ${startAge}-${endAge}`,
      )
    }

    let formattedStr = str.replace(/and/g, ' & ')

    formattedStr = formattedStr
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')

    return formattedStr
  }

  useEffect(() => {
    const newSegments = location.pathname.split('/').slice(2)
    const breadcrumbItems: { title: JSX.Element; clickablePath: string; key: number }[] = []
    // Check and add gender-specific landing page
    const genderSegment = newSegments[0]
    if (['women', 'men', 'kids'].includes(genderSegment)) {
      breadcrumbItems.push({
        title: <strong>{t(`components.breadcrumbs.${genderSegment}_landing`)}</strong>,
        clickablePath: `/landing-page/${genderSegment}`,
        key: 0,
      })
    }

    // Proceed with other segments
    newSegments.forEach((segment, index) => {
      if (segment !== 'products') {
        const formattedSegment = formatBreadcrumbTitle(segment)

        breadcrumbItems.push({
          title: <>{formattedSegment}</>,
          clickablePath: `/products/${newSegments.slice(0, index + 1).join('/')}`,
          key: breadcrumbItems.length,
        })
      }
    })

    setActiveBreadcrumbs(breadcrumbItems)
  }, [location.pathname, i18n])

  const fetchCategoriesByName = async (categoryName: string) => {
    try {
      const categoryData = await getCategoryWithIdAndName(categoryName)
      await fetchCategories(categoryData)
    } catch (error) {
      console.error(error) // Handle this better in production
    }
  }

  useEffect(() => {
    if (categoryPath && categoryPath !== prevCategoryPath.current) {
      const categoryKey = convertPathToKey(categoryPath)
      fetchCategoriesByName(categoryKey)
    }

    prevCategoryPath.current = categoryPath
  }, [categoryPath])

  useEffect(() => {
    const brandFromParams = params.get('brand')
    if (brandFromParams) {
      setSelectedBrands([brandFromParams])
    }
  }, [params])

  useEffect(() => {
    if (currentCategories?.category && JSON.stringify(currentCategories) !== JSON.stringify(prevCurrentCategories.current)) {
      // If name is undefined for all languages, set the state variable to true
      const isNameUndefined = Object.values(currentCategories.category.name || {}).every((name) => name === undefined)
      if (isNameUndefined) {
        setShowNotFoundPage(true)
        return
      }

      // Fetch products if everything is fine
      fetchProducts(currentCategories?.category.id, 0, params.get('store'))
    }
    // Update the previous value for the next effect run
    prevCurrentCategories.current = currentCategories
  }, [currentCategories, priceSort])

  useEffect(() => {
    if (priceSort) fetchProducts(currentCategories?.category.id, 0, params.get('store'))
  }, [priceSort])

  const getSubcategoryLink = (subCategory: Category) => {
    let newPath = ''
    if (params.get('store')) {
      newPath = `${categoryPath}/${convertPathToKey(subCategory.name.en.toLowerCase())}?store=${params.get('store')}`
    } else {
      newPath = `${categoryPath}/${convertPathToKey(subCategory.name.en.toLowerCase())}`
    }
    return newPath
  }
  if (showNotFoundPage) {
    return <NotFoundPageDesktop />
  }

  return (
    <MainPageResponsive justify={'center'} mx={'auto'} loading={false}>
      {/* <HStack justifyContent={'center'} spacing={4} mt={4} overflowX={['scroll', 'initial']}> */}
      {/* Main Content */}
      <VStack w='100%' justifyContent={'center'} alignItems='center' spacing={4} mt={4} overflowX={['scroll', 'initial']}>
        {isLoadingProducts || loadingChannelId ? (
          <PageSkeleton />
        ) : (
          <>
            <Box px={Theme.PaddingOnSides.PLP} maxWidth={'100%'}>
              <CategorySelector currentCategories={currentCategories} handleSubCategoryClick={getSubcategoryLink} />
            </Box>
            <ProductsList
              onOpen={onOpen}
              products={products}
              moreProducts={existMoreProducts}
              showMoreProducts={showMoreProducts}
              isLoadingMoreProducts={isLoadingMoreProducts}
              storeKey={params.get('store')}
              priceSort={priceSort}
              setPriceSort={setPriceSort}
              channelId={channelId}
            />
          </>
        )}
      </VStack>
      {/* </HStack> */}
    </MainPageResponsive>
  )
}
export default withAuth(PLP, [MainRole.RoleGuest])
