import { createContext, useContext, ReactElement, useState } from 'react'
import React from 'react'
import {
  Maybe,
  Order,
  ParcelResponse,
  PlentyOrder,
  PlentyOrderLineItem,
  useGetOrderByOrderNumberAndCheckCustomerOwnershipLazyQuery,
  useGetStoreByCommercetoolsChannelKeyLazyQuery,
} from '../generated/graphql'
import { ReturnItemQuantity } from '../types/ReturnItemQuantity'

type OrderReturnContextType = {
  orderNumber: string
  email: string
  checkOrderBelongsToEmail: (orderNumber: string, email: string) => Promise<boolean>
  step?: number
  order?: PlentyOrder
  returnItemListWithQuantity: ReturnItemQuantity[]
  decreaseReturnItemQuantity: (orderLineItem: PlentyOrderLineItem) => void
  increaseReturnItemQuantity: (orderLineItem: PlentyOrderLineItem) => void
  advanceStep: () => void
  previousStep: () => void
  currentItemIndex: number
  setCurrentItemIndex: (index: number) => void
  setReturnReason: (orderLineItem: ReturnItemQuantity, reason: string) => void
  deliveryOption: 'inStore' | 'ParcelShop'
  setDeliveryOption: (option: 'inStore' | 'ParcelShop') => void
  parcelResponse: ParcelResponse | null
  setParcelResponse: (value: ParcelResponse) => void
  selectedOrderToReturn: Order | null
  setSelectedOrderToReturn: (order: Order | null) => void
  resetParcelResponse: () => void
}

const ReturnContext = createContext<OrderReturnContextType>({
  orderNumber: '',
  email: '',
  checkOrderBelongsToEmail: async (orderNumber: string, email: string) => true,
  step: 0,
  order: undefined,
  returnItemListWithQuantity: [],
  decreaseReturnItemQuantity: () => undefined,
  increaseReturnItemQuantity: () => undefined,
  advanceStep: () => undefined,
  previousStep: () => undefined,
  currentItemIndex: 0,
  setCurrentItemIndex: () => undefined,
  setReturnReason: () => undefined,
  deliveryOption: 'inStore',
  setDeliveryOption: () => undefined,
  parcelResponse: null,
  setParcelResponse: () => undefined,
  selectedOrderToReturn: null,
  setSelectedOrderToReturn: () => undefined,
  resetParcelResponse: () => undefined,
})

export default function OrderReturnContext({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  const [orderNumber, setOrderNumber] = useState('')
  const [email, setEmail] = useState('')
  const [order, setOrder] = useState<PlentyOrder | undefined>()
  const [getOrderForCustomer, { loading }] = useGetOrderByOrderNumberAndCheckCustomerOwnershipLazyQuery()
  const [getStoreByDistributionChannel] = useGetStoreByCommercetoolsChannelKeyLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    initialFetchPolicy: 'network-only',
    context: {
      skipDuplicates: false, // disables automatic query deduplication
    },
  })
  const [selectedOrderToReturn, setSelectedOrderToReturn] = useState<Order | null>(null)

  const [step, setStep] = useState(0)
  const [returnItemListWithQuantity, setReturnItemListWithQuantity] = useState<ReturnItemQuantity[]>([])
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [deliveryOption, setDeliveryOption] = useState<'inStore' | 'ParcelShop'>('inStore')
  const [parcelResponse, setParcelResponse] = useState<ParcelResponse | null>(null)
  const checkOrderBelongsToEmail = async (orderNumber: string, email: string): Promise<boolean> => {
    try {
      const order = await getOrderForCustomer({ variables: { orderNumber: orderNumber, email: email } })
      if (order?.data?.getOrderByOrderNumberAndCheckCustomerOwnership) {
        setOrder(order?.data?.getOrderByOrderNumberAndCheckCustomerOwnership)
        setOrderNumber(orderNumber)
        setEmail(email)
        const storesInvolved: any[] = []
        for (const value of order.data.getOrderByOrderNumberAndCheckCustomerOwnership.orderLineItems) {
          const store = await getStoreByDistributionChannel({
            variables: {
              commercetoolsChannelKey: value?.distributionChannel,
            },
            fetchPolicy: 'network-only',
            context: {
              skipDuplicates: false,
            },
          })
          storesInvolved.push(store?.data?.getStoreByCommercetoolsChannelKey)
        }
        const array = order.data.getOrderByOrderNumberAndCheckCustomerOwnership.orderLineItems.map((value: Maybe<PlentyOrderLineItem>): ReturnItemQuantity => {
          const itemStore = storesInvolved.find((e) => e?.commercetoolsChannelKey === value?.distributionChannel)
          return {
            orderLineItem: value!,
            quantity: 0,
            reason: '',
            store: itemStore,
          }
        })
        setReturnItemListWithQuantity(array)
        return true
      }
      return false
    } catch (e) {
      console.log(e)
      return false
    }
  }
  const resetParcelResponse = () => {
    setParcelResponse(null)
  }
  const decreaseReturnItemQuantity = (orderLineItem: PlentyOrderLineItem) => {
    const newArray = [...returnItemListWithQuantity]
    const index = newArray.findIndex((e: ReturnItemQuantity) => e.orderLineItem.id == orderLineItem.id)

    if (newArray[index].quantity > 0) {
      newArray[index].quantity = newArray[index].quantity - 1
    }

    setReturnItemListWithQuantity(newArray)
  }

  const increaseReturnItemQuantity = (orderLineItem: PlentyOrderLineItem) => {
    const newArray = [...returnItemListWithQuantity]
    const index = newArray.findIndex((e: ReturnItemQuantity) => e.orderLineItem.id === orderLineItem.id)

    const currentQuantity = newArray[index].quantity
    const maxQuantity = orderLineItem.quantity

    if (currentQuantity < maxQuantity) {
      newArray[index].quantity = currentQuantity + 1
      setReturnItemListWithQuantity(newArray)
    }
  }

  const setReturnReason = (returnItem: ReturnItemQuantity, reason: string) => {
    const index = returnItemListWithQuantity.indexOf(returnItem)
    if (index !== -1) {
      const updatedItem = { ...returnItem, reason: reason }
      setReturnItemListWithQuantity((prevState) => {
        const newList = [...prevState]
        newList[index] = updatedItem
        return newList
      })
    }
  }

  const advanceStep = () => {
    setStep(step + 1)
  }
  const previousStep = () => {
    setStep(step - 1)
  }
  return (
    <ReturnContext.Provider
      value={{
        currentItemIndex,
        setCurrentItemIndex,
        orderNumber,
        email,
        order,
        checkOrderBelongsToEmail,
        step,
        returnItemListWithQuantity,
        decreaseReturnItemQuantity,
        increaseReturnItemQuantity,
        advanceStep,
        previousStep,
        setReturnReason,
        deliveryOption,
        setDeliveryOption,
        parcelResponse,
        setParcelResponse,
        selectedOrderToReturn,
        setSelectedOrderToReturn,
        resetParcelResponse,
      }}
    >
      {children}
    </ReturnContext.Provider>
  )
}

export const useReturn = (): OrderReturnContextType => useContext(ReturnContext)
