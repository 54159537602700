import { t } from 'i18next'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import { MainRole } from '../../../../generated/graphql'
import withAuth from '../../../../utils/WithAuth'

const MerchantSupportPage = () => {
  return (
    <PartnerPortalPage pageHeaderLabel={t("partner_portal.merchant.support.page_header_support") as string}>
      
    </PartnerPortalPage>
  )
}

export default withAuth(MerchantSupportPage, [MainRole.RoleGuest])
