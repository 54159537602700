import { Button } from "@chakra-ui/react"
import { Formik } from "formik"
import * as Yup from 'yup'
import { useDiscountCreation } from "../../context/DiscountCreationContext";

const fileSelectionSchema = Yup.object().shape({
    file: Yup.mixed()
      .required('Please choose a file.')
      .test('fileType', 'Invalid file type. Please upload an Excel (.xls, .xlsx) or CSV file.', (file) => {
        if (!file) return true; // Skip validation if file is not provided
        const allowedFileTypes = ['xls', 'xlsx', 'csv'];
        const fileName = (file as File).name || '';
        const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
        return allowedFileTypes.includes(fileExtension.toLowerCase());
      }),
});

export const FileSelector = (props: {wizard: any, onSelect: any, file: File | null}) => {
    const discountCreation = useDiscountCreation()

    return (
        <Formik
        initialValues={{
          file: props.file,
          fileUploaded: discountCreation.fileUpload
        }}
        validationSchema={fileSelectionSchema}
        onSubmit={async (values) => {
            props.onSelect(values.file)
        }}
        >
            {({ handleSubmit, isValid, touched, errors, setFieldValue }) => (
            <>
                <label htmlFor="file">Choose file: </label>
                <input
                    id="file"
                    name="file"
                    type="file"
                    accept=".xlsx, .csv"
                    required
                    onChange={(event) => {
                        setFieldValue('file', event.target.files?.[0]) 
                        discountCreation.setFileUpload(true)
                    }}
                />
                {touched.file && errors.file && <div style={{ color: 'red' }}>{errors.file}</div>}
                <Button isDisabled={!props.wizard.canAdvance || !isValid} colorScheme='blue' mr={3} onClick={() => handleSubmit()}>
                    Upload
                </Button>
            </>
            )}
        </Formik>
    )
}
