import { ApolloError } from '@apollo/client'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { TbChevronRight } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { Theme } from '../../../ThemeVariables'
import { ErrorBox } from '../../ErrorBox'
import { PageSpinner } from '../../PageSpinner'
import { NewFooter } from './NewFooter'
import { NewHeader } from './NewHeader'

type MainPageResponsiveProps = {
  children: ReactElement
  loading: boolean
  error?: ApolloError
  shouldShowPill?: boolean
  mx?: string
  justify?: string
}

export const MainPageResponsive = (props: MainPageResponsiveProps) => {
  const { breadcrumbs } = useBreadcrumbs()
  const navigate = useNavigate()

  const navigateTo = (path: string | undefined) => {
    if (path) {
      navigate(path)
    }
  }
  const Render = () => {
    if (props.error) {
      return <ErrorBox message={props.error.message} />
    } else {
      if (props.loading) {
        return (
          <Flex w={'100dvw'}>
            <PageSpinner />
          </Flex>
        )
      } else {
        return <Flex justifyContent={props.justify}>{props.children}</Flex>
      }
    }
  }

  const showHeaderWithPill = props.shouldShowPill !== false

  return (
    <Flex direction={'column'} mx={props.mx} backgroundColor={'white'} w={'100dvw'}>
      <Box w={'100%'} zIndex={401} position='sticky' top='0'>
        <NewHeader headerWithPill={showHeaderWithPill} />
        {breadcrumbs.length > 0 && (
          <Breadcrumb
            separator={<TbChevronRight color='gray.500' />}
            pt={'1rem'}
            px={Theme.PaddingOnSides.PLP}
            backgroundColor={'white'}
            style={{
              whiteSpace: 'nowrap',
              overflowX: 'auto',
              overflowY: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {breadcrumbs.map((breadcrumb) => (
              <BreadcrumbItem key={breadcrumb.key} onClick={() => navigateTo(breadcrumb?.clickablePath)} isCurrentPage={breadcrumb?.active}>
                <BreadcrumbLink>{breadcrumb.title}</BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}
      </Box>

      <Render />
      <NewFooter />
    </Flex>
  )
}
