import { useApolloClient } from '@apollo/client'
import { Flex, Icon, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Spinner, Text } from '@chakra-ui/react'
import { IconSearch, IconX } from '@tabler/icons-react'
import { useRef, useState } from 'react'
import { ToggleTag } from '../../../../components/PlentyUI/components/ToggleTag'
import { useUser } from '../../../../context/Auth'
import { ProductAndCategorySearchFilter, ProductAndCategorySearchResult } from '../../../../generated/graphql'
import { SEARCH_COMMERCETOOLS_PRODUCTS_AND_CATEGORIES } from '../../../../gql/product'
import { SearchResult } from './SearchResult'

export const ProductSelectionDropdown = () => {
  const client = useApolloClient()

  const searchFieldRef = useRef(null)
  const containerRef = useRef(null)

  const [isFocused, setIsFocused] = useState(false)

  const user = useUser()

  const [searchString, setSearchString] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<ProductAndCategorySearchResult[] | undefined>()
  const [isProductFilterToggled, setIsProductFilterToggled] = useState(false)
  const [isCategoryFilterToggled, setIsCategoryFilterToggled] = useState(false)

  const search = (e: any, filter: ProductAndCategorySearchFilter) => {
    const value = typeof e === 'string' ? e : e.target.value

    if (value.length > 2) {
      setIsLoading(true)
      client
        .query({
          query: SEARCH_COMMERCETOOLS_PRODUCTS_AND_CATEGORIES,
          variables: {
            searchTerm: value,
            channelKey: user.distributionChannelKey,
            filter: filter,
          },
        })
        .then((result: any) => {
          setSearchResults(result.data.searchCommercetoolsProductsAndCategories)
          setIsLoading(false)
        })
    }

    setSearchString(value)
    setSearchResults(undefined)
  }

  const clearInput = () => {
    setSearchString('')
    setSearchResults(undefined)
  }

  const focusSearchInput = () => {
    const currentSearchFieldRef: any = searchFieldRef.current

    if (currentSearchFieldRef != null) {
      currentSearchFieldRef.focus()
    }
  }

  const ShowSearchResults = () => {
    if (searchResults) {
      if (searchResults.length > 0) {
        return (
          <>
            {searchResults.map((value: ProductAndCategorySearchResult, index: number) => (
              <SearchResult key={index} value={value} searchTerm={searchString} clearInput={clearInput} focusSearchInput={focusSearchInput} />
            ))}
          </>
        )
      } else {
        return (
          <Text px={6} pb={6}>
            No results found...
          </Text>
        )
      }
    } else {
      return <></>
    }
  }

  return (
    <Flex
      ref={containerRef}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
      w={'full'}
      position={'absolute'}
      borderRadius={'md'}
      boxShadow={'md'}
      direction={'column'}
    >
      <InputGroup variant={'solidWhite'}>
        <InputLeftElement pointerEvents='none'>
          {isLoading ? <Spinner size={'md'} color='mintGreen.500' /> : <Icon h={5} w={5} as={IconSearch} />}
        </InputLeftElement>
        <Input
          placeholder='Search for the product categories or product numbers you want to apply a discount to.'
          ref={searchFieldRef}
          value={searchString}
          onChange={(e: any) => {
            if (!isProductFilterToggled && !isCategoryFilterToggled) {
              search(e, { products: true, categories: true })
            } else {
              search(e, { products: isProductFilterToggled, categories: isCategoryFilterToggled })
            }
          }}
        />
        <InputRightElement>
          <IconButton
            onClick={() => {
              setSearchString('')
              setSearchResults(undefined)

              focusSearchInput()
            }}
            variant={'ghost'}
            aria-label='clear'
            icon={<Icon h={5} w={5} as={IconX} />}
          />
        </InputRightElement>
      </InputGroup>

      <Flex
        borderBottomRadius={'md'}
        direction={'column'}
        gap={6}
        bgColor={'white'}
        borderTop={'1px'}
        borderColor={'beigeLight.500'}
        hidden={!isFocused && searchResults === undefined}
        zIndex={10000}
        maxH={'500px'}
        overflowY={'auto'}
      >
        <Flex borderBottom={'1px'} borderColor={'beigeLight.500'}>
          <Flex px={6} py={4} gap={3}>
            <ToggleTag
              action={() => {
                setIsProductFilterToggled(!isProductFilterToggled)
                if (isProductFilterToggled && !isCategoryFilterToggled) {
                  search(searchString, { products: true, categories: true })
                } else {
                  search(searchString, { products: !isProductFilterToggled, categories: isCategoryFilterToggled })
                }
              }}
              isToggled={isProductFilterToggled}
              label='Products'
            />
            <ToggleTag
              action={() => {
                setIsCategoryFilterToggled(!isCategoryFilterToggled)
                if (!isProductFilterToggled && isCategoryFilterToggled) {
                  search(searchString, { products: true, categories: true })
                } else {
                  search(searchString, { products: isProductFilterToggled, categories: !isCategoryFilterToggled })
                }
              }}
              isToggled={isCategoryFilterToggled}
              label='Categories'
            />
          </Flex>
        </Flex>
        <ShowSearchResults />
      </Flex>
    </Flex>
  )
}
