import { Card, CardBody, Flex } from '@chakra-ui/react'
import { SignIn } from '../../components/SignIn'

export const LoginMobile = () => {
  const LoginImage = 'https://storage.googleapis.com/bucket-plenty-dev/LoginMerchantPortal/LoginImage.png'

  return (
    <Flex w={'100vw'} h={'100%'} backgroundImage={LoginImage} backgroundPosition={'center'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'}>
      <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
        <Card maxW={443} h={627}>
          <CardBody p={10}>
            <Flex flex={1} h={'full'} direction={'column'} justifyContent={'space-between'}>
              <SignIn></SignIn>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  )
}
