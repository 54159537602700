import { Heading, HStack, Icon, IconButton } from '@chakra-ui/react'
import { TbChevronLeft } from 'react-icons/tb'

type ContentHeaderProps = {
  label: string
  back?: any
}

export const ContentHeader = (props: ContentHeaderProps) => {
  return (
    <HStack spacing={'5'} alignItems={'center'}>
      { props.back ? <IconButton onClick={() => props.back()} variant={'ghost'} aria-label={'back'} icon={<Icon as={TbChevronLeft} />} /> : <></> }
      <Heading>{props.label}</Heading>
    </HStack>
  )
}
