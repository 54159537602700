import { Flex, Icon, Text } from '@chakra-ui/react'
import { IconCircleCheck, IconCircleX, IconExclamationCircle, IconInfoCircle } from '@tabler/icons-react'

type ToastProps = {
  type: 'success' | 'error' | 'info' | 'warning'
  label: string
}

export const Toast = (props: ToastProps) => {
  const ToastIcon = () => {
    switch (props.type) {
      case 'success':
        return <Icon as={IconCircleCheck} color={'mintGreen.500'} h={5} w={5} />
      case 'error':
        return <Icon as={IconCircleX} color={'red.500'} h={5} w={5} />
      case 'info':
        return <Icon as={IconInfoCircle} color={'blue.500'} h={5} w={5} />
      case 'warning':
        return <Icon as={IconExclamationCircle} color={'yellow.500'} h={5} w={5} />
    }
  }

  return (
    <Flex borderRadius={8} color='white' py={3} px={5} bg='darkGreen.500' alignItems={'center'} gap={3}>
      <ToastIcon />
      <Text>{props.label}</Text>
    </Flex>
  )
}
