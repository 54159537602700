import { gql } from '@apollo/client'

export const CREATE_CAMPAIGN_DISCOUNT = gql`
  mutation CreateCampaignDiscount($campaignDiscount: CampaignDiscountInput) {
    createCampaignDiscount(campaignDiscount: $campaignDiscount) {
      discounts {
        ... on BundleDiscount {
          bundle {
            ... on PriceBundle {
              totalPriceAfterDiscount
              triggerQuantity
            }
            ... on QuantityBundle {
              discountedQuantity
              triggerQuantity
            }
          }
          channelKey
          commercetoolsReference
          entityInfo {
            ... on CategoryInfo {
              categoryKey
            }
            ... on ProductInfo {
              excludedSkus
              productKey
            }
          }
        }
        ... on CategoryDiscount {
          categoryKey
          commercetoolsReference
          discountAmount
        }
        ... on ProductDiscount {
          channelKey
          commercetoolsReference
          discountAmount
          excludedSkus
          productKey
        }
      }
      id
      validFrom
      name
      validTo
    }
  }
`

export const GET_CAMPAIGN_DISCOUNTS = gql`
  query GetCampaignDiscounts($channelKey: String) {
    getCampaignDiscounts(channelKey: $channelKey) {
      discounts {
        ... on BundleDiscount {
          bundle {
            ... on PriceBundle {
              totalPriceAfterDiscount
              triggerQuantity
            }
            ... on QuantityBundle {
              discountedQuantity
              triggerQuantity
            }
          }
          channelKey
          commercetoolsReference
          entityInfo {
            ... on CategoryInfo {
              categoryKey
            }
            ... on ProductInfo {
              excludedSkus
              productKey
            }
          }
        }
        ... on CategoryDiscount {
          categoryKey
          commercetoolsReference
          discountAmount
        }
        ... on ProductDiscount {
          channelKey
          commercetoolsReference
          discountAmount
          excludedSkus
          productKey
          sortOrder
        }
      }
      id
      name
      validFrom
      validTo
    }
  }
`

export const DELETE_CAMPAIGN_DISCOUNT = gql`
  mutation DeleteCampaignDiscount($campaignDiscountId: UUID) {
    deleteCampaignDiscount(campaignDiscountId: $campaignDiscountId)
  }
`

export const GET_ALL_IMAGES_FROM_PRODUCTS_AND_CATEGORIES_FROM_CAMPAIGN_DISCOUNT = gql`
  query GetAllImagesFromProductsAndCategoriesFromCampaignDiscount($campaignDiscountId: UUID) {
    getAllImagesFromProductsAndCategoriesFromCampaignDiscount(campaignDiscountId: $campaignDiscountId)
  }
`

export const CHANGE_NAME_OF_CAMPAIGN_DISCOUNT = gql`
  mutation ChangeNameOfCampaignDiscount($campaignDiscountId: UUID!, $newName: String!) {
    changeNameOfCampaignDiscount(campaignDiscountId: $campaignDiscountId, newName: $newName)
  }
`

export const GET_MOST_VALUABLE_BUNDLE_DISCOUNT_BY_PRODUCT_KEY = gql`
  query GetMostValuableBundleDiscountByProductKey($productKey: String) {
    getMostValuableBundleDiscountByProductKey(productKey: $productKey) {
      bundle {
        ... on PriceBundle {
          totalPriceAfterDiscount
          triggerQuantity
        }
        ... on QuantityBundle {
          discountedQuantity
          triggerQuantity
        }
      }
      channelKey
      commercetoolsReference
      entityInfo {
        ... on CategoryInfo {
          categoryKey
        }
        ... on ProductInfo {
          excludedSkus
          productKey
        }
      }
    }
  }
`

export const GET_MOST_VALUABLE_BUNDLE_DISCOUNT_BY_SPECIFIC_STORE_VARIANT = gql`
  query GetMostValuableBundleDiscountBySpecificStoreVariant($productKey: String, $channelKey: String, $sku: String) {
    getMostValuableBundleDiscountBySpecificStoreVariant(productKey: $productKey, channelKey: $channelKey, sku: $sku) {
      bundle {
        ... on PriceBundle {
          totalPriceAfterDiscount
          triggerQuantity
        }
        ... on QuantityBundle {
          discountedQuantity
          triggerQuantity
        }
      }
      channelKey
      commercetoolsReference
      entityInfo {
        ... on CategoryInfo {
          categoryKey
        }
        ... on ProductInfo {
          excludedSkus
          productKey
        }
      }
    }
  }
`

export const GET_ALL_BUNDLE_DICSOUNTS_BY_PRODUCT_KEY = gql`
  query GetAllBundleDiscountByProductKey($productKey: String) {
    getAllBundleDiscountByProductKey(productKey: $productKey) {
      bundle {
        ... on PriceBundle {
          totalPriceAfterDiscount
          triggerQuantity
        }
        ... on QuantityBundle {
          discountedQuantity
          triggerQuantity
        }
      }
      channelKey
      commercetoolsReference
      entityInfo {
        ... on CategoryInfo {
          categoryKey
        }
        ... on ProductInfo {
          excludedSkus
          productKey
        }
      }
    }
  }
`
