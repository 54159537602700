import { Flex, Text, Link as ChakraLink, Stack, Grid } from '@chakra-ui/react'
import { t } from 'i18next'
import i18n from '../../../../I18n/i18n'
import FetchSubCategoriesForCategories from '../../gender_home_page/components/GetSubCategories'
import { convertPathToKey } from '../../PLP/utils'

export const SubCategoriesByMainCategory = ({
  header,
  fontSizeGender,
  fontWeightGender,
  fontSize,
  fontWeight,
  gapBetweenHeaderAndGender,
  gapBetweenGender,
  marginBottomBetweenHeaderAndGender,
}: any) => {
  const subCategories = FetchSubCategoriesForCategories() 
  const selectedLanguage = localStorage.getItem('languageSelected') ?? i18n.language
  const langKey = selectedLanguage === 'DK' ? 'da-DK' : 'en'

  return (
    <Flex textAlign={'start'} direction={'column'} px={gapBetweenHeaderAndGender}>
      <Text fontSize={fontSize} fontWeight={fontWeight} mb={marginBottomBetweenHeaderAndGender}>
        {header}
      </Text>
      <Grid templateColumns={{ base: '1fr 1fr', md: 'repeat(3, 1fr)' }} gap={gapBetweenGender}>
        {subCategories.map((subCategory, index) => (
          <Flex key={index} direction='column' alignItems='start'>
            <Text fontSize={fontSizeGender} fontWeight={fontWeightGender} marginBottom='1rem'>
              {t(`global.title_${subCategory.category}`)}
            </Text>
            <Stack spacing={'0.5rem'}>
              {subCategory.data.map((brand: any, brandIndex: any) => (
                <Text key={brandIndex}>
                  <ChakraLink href={`/products/${convertPathToKey(subCategory.category)}/${convertPathToKey(brand.name.en.toLowerCase())}`}>
                    {brand.name[langKey]}
                  </ChakraLink>
                </Text>
              ))}
            </Stack>
          </Flex>
        ))}
      </Grid>
    </Flex>
  )
}
