import { Flex, Icon, Tag, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import { useSearchParams } from 'react-router-dom'
import { BundleDiscount, ProductInfo } from '../../../../generated/graphql'
import { getCheapestPriceNotFromChannel, getPriceByChannel } from '../../../../utils/PDPUtil'
import { findProductVariantAttributeDefaultEnglish } from '../../../../utils/ProductUtil'
import { PlentyVariant } from '../../PLP/types'
import { PlentyStore } from '../types'
import { VariantPrice } from './VariantPrice'

type SizeSelectorProps = {
  filteredSizes: PlentyVariant[]
  filteredStores: PlentyStore[]
  selectedOption: PlentyVariant
  setSelectedOption: (option: PlentyVariant) => void
  setSelectedStore: (value: PlentyStore) => void
  selectedStoreId: string
  selectedStoreChannelKey: string
  bundleDiscounts: BundleDiscount[]
}

const BundleTag = (props: { bundle: BundleDiscount | null }) => {
  if (props.bundle?.bundle.__typename == 'QuantityBundle') {
    return (
      <Tag color={'darkGreen.500'} colorScheme={'green'}>
        Buy {props.bundle.bundle.discountedQuantity} get {props.bundle.bundle.triggerQuantity}
      </Tag>
    )
  } else if (props.bundle?.bundle.__typename == 'PriceBundle') {
    return (
      <Tag color={'darkGreen.500'} colorScheme={'green'}>
        Buy {props.bundle.bundle.triggerQuantity} for {props.bundle.bundle.totalPriceAfterDiscount} Kr
      </Tag>
    )
  } else {
    return <></>
  }
}

const renderPriceInfo = (colorVariant: PlentyVariant, selectedStoreId: string, bundleDiscounts: BundleDiscount[], selectedStoreChannelKey: string) => {
  if (!getPriceByChannel(colorVariant, selectedStoreId)) {
    const cheapestPriceChannel = getCheapestPriceNotFromChannel(colorVariant, selectedStoreId)

    if (!cheapestPriceChannel) {
      return <Text fontStyle={'italic'}>No price found</Text>
    }

    const bundle = bundleDiscounts.find((bundleDiscount: BundleDiscount) => bundleDiscount.channelKey == cheapestPriceChannel.channel.obj.key)
    const hasBundleDiscount = bundle && !(bundle?.entityInfo as ProductInfo).excludedSkus.includes(colorVariant.sku)

    return (
      <>
        <VariantPrice
          variant={colorVariant}
          channelId={cheapestPriceChannel?.channel.obj.id || ''}
          normalFontWeight
          livePriceFontSize='md'
          oldPriceFontSize='sm'
        />
        <Flex gap={3}>
          <Tag color={'darkGreen.500'} colorScheme={'green'}>
            Stock from {(cheapestPriceChannel?.channel.obj.name as any)['en']}
          </Tag>
          {bundle && hasBundleDiscount ? <BundleTag bundle={bundle} /> : null}
        </Flex>
      </>
    )
  } else {
    const bundle: any = bundleDiscounts.find(
      (bundleDiscount: BundleDiscount) =>
        bundleDiscount.channelKey == selectedStoreChannelKey && !(bundleDiscount?.entityInfo as ProductInfo).excludedSkus.includes(colorVariant.sku),
    )
    const hasBundleDiscount = bundle

    return (
      <>
        <VariantPrice variant={colorVariant} channelId={selectedStoreId} normalFontWeight livePriceFontSize='md' oldPriceFontSize='sm' />
        {bundle && hasBundleDiscount ? <BundleTag bundle={bundle} /> : null}
      </>
    )
  }
}

export const SizeSelector = (props: SizeSelectorProps) => {
  const [showSizeCollapse, setShowSizeCollapse] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <Flex cursor={'pointer'} w={'full'} position={'absolute'} direction={'column'} onMouseLeave={() => setShowSizeCollapse(false)}>
      <Flex
        h={'60px'}
        boxShadow={showSizeCollapse ? 'md' : 'none'}
        borderTopLeftRadius={showSizeCollapse ? '30px' : '56px'}
        borderTopRightRadius={showSizeCollapse ? '30px' : '56px'}
        borderBottomLeftRadius={showSizeCollapse ? 'none' : '56px'}
        borderBottomRightRadius={showSizeCollapse ? 'none' : '56px'}
        onClick={() => setShowSizeCollapse(!showSizeCollapse)}
        bgColor={'beigeLight.400'}
        px={6}
        py={4}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text>{props.selectedOption?.attributes.find((it) => it.name === 'size')?.value['en']}</Text>
        <Icon as={showSizeCollapse ? TbChevronUp : TbChevronDown} />
      </Flex>

      <Flex
        boxShadow={showSizeCollapse ? 'md' : 'none'}
        borderBottomLeftRadius={showSizeCollapse ? '30px' : '56px'}
        borderBottomRightRadius={showSizeCollapse ? '30px' : '56px'}
        direction={'column'}
        bgColor={'white'}
        gap={6}
        hidden={!showSizeCollapse}
        maxH={'500px'}
        overflowY={'auto'}
      >
        <Flex direction={'column'}>
          {props.filteredSizes.map((colorVariant: PlentyVariant, index: number) => {
            const sizeAmount = props.filteredSizes?.length || 0
            const currentSize = index + 1

            return (
              <Flex
                onClick={() => {
                  if (!getPriceByChannel(colorVariant, props.selectedStoreId)) {
                    const cheapestPriceChannel = getCheapestPriceNotFromChannel(colorVariant, props.selectedStoreId)
                    const cheapestPriceStore = props.filteredStores.find((store: PlentyStore) => store.channelId === cheapestPriceChannel?.channel.id)
                    if (cheapestPriceStore) {
                      props.setSelectedStore(cheapestPriceStore)
                    }

                    searchParams.delete('store')
                    setSearchParams(searchParams)
                  }

                  setShowSizeCollapse(false)
                  props.setSelectedOption(colorVariant)
                }}
                px={8}
                py={3}
                bgColor={colorVariant.id == props.selectedOption?.id ? 'beigeLight.400' : 'white.500'}
                _hover={{ bgColor: 'beigeLight.500' }}
                key={colorVariant.id}
                gap={6}
                alignItems={'center'}
                borderBottomColor={'beigeLight.500!important'}
                borderBottom={sizeAmount > currentSize ? '1px' : '0px'}
              >
                <Text fontWeight={'bold'}>{findProductVariantAttributeDefaultEnglish(colorVariant, 'size')}</Text>
                <Flex flex={1} justifyContent={'space-between'}>
                  {renderPriceInfo(colorVariant, props.selectedStoreId, props.bundleDiscounts, props.selectedStoreChannelKey)}
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}
