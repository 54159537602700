import { Icon, Tag } from '@chakra-ui/react'
import { IconBan, IconMoodCheck, IconShoppingBag, IconShoppingCart } from '@tabler/icons-react'

type OrderStateTagProps = {
  orderState?: string
}

export const OrderStateTag = ({ orderState }: OrderStateTagProps) => {
  switch (orderState) {
    case 'Open':
      return (
        <Tag size={'lg'} px={5} py={2} borderRadius={'full'} colorScheme={'orange'}>
          <Icon h={5} w={5} as={IconShoppingCart} />
        </Tag>
      )
    case 'Confirmed':
      return (
        <Tag size={'lg'} px={5} py={2} borderRadius={'full'} colorScheme={'blue'}>
          <Icon h={5} w={5} as={IconShoppingBag} />
        </Tag>
      )
    case 'Cancelled':
      return (
        <Tag size={'lg'} px={5} py={2} borderRadius={'full'} colorScheme={'red'}>
          <Icon h={5} w={5} as={IconBan} />
        </Tag>
      )
    case 'Complete':
      return (
        <Tag size={'lg'} px={5} py={2} borderRadius={'full'} colorScheme={'green'}>
          <Icon h={5} w={5} as={IconMoodCheck} />
        </Tag>
      )
    default:
      return (
        <Tag size={'lg'} px={5} py={2} borderRadius={'full'} colorScheme={'red'}>
          <Icon h={5} w={5} as={IconShoppingCart} />
        </Tag>
      )
  }
}
