import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  Img,
  DrawerFooter,
  DrawerBody,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import i18n from '../../I18n/i18n'
import LanguageSelector from '../../I18n/LanguageSelector'
import { Theme } from '../../ThemeVariables'
import { CategoryDrawer } from './components/CategoryDrawer'

export const BurgerMenu = ({ isOpen, onClose }: any) => {
  const navigate = useNavigate()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedGender, setSelectedGender] = useState('')
  const FooterIcon = 'https://storage.googleapis.com/bucket-plenty-dev/Header%26Footer/FooterIcon.png'
  const handleDrawerToggle = (type: string) => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const closeCategoryDrawer = () => {
    setIsDrawerOpen(false)
  }

  const closeAll = () => {
    closeCategoryDrawer()
    onClose()
  }
  const location = useLocation()

  useEffect(() => {
   
    closeCategoryDrawer()
    setIsDrawerOpen(false)
    onClose() 

    
  }, [location.pathname]) 
  const selectedLanguage = localStorage.getItem('languageSelected') || i18n.language

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size='xs'>
        <DrawerOverlay />
        <DrawerContent bgColor={'beigeLight.500'}>
          <DrawerBody p={0}>
            <Flex p={Theme.spacing.padding.mobile} justifyContent={'space-between'}>
              <Img src={FooterIcon} width={'30px'} height={'40px'} />
              <DrawerCloseButton position={'relative'} fontSize={'20px'} />
            </Flex>
            <Accordion p={4} allowMultiple>
              <AccordionItem>
                <AccordionButton
                  px={0}
                  justifyContent={'space-between'}
                  onClick={() => {
                    handleDrawerToggle('Men')
                    setSelectedGender('Men')
                  }}
                  fontSize={'xl'}
                >
                  {t('global.title_men')}
                </AccordionButton>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton
                  px={0}
                  justifyContent={'space-between'}
                  onClick={() => {
                    handleDrawerToggle('Women')
                    setSelectedGender('Women')
                  }}
                  fontSize={'xl'}
                >
                  {t('global.title_women')}
                </AccordionButton>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton
                  px={0}
                  justifyContent={'space-between'}
                  onClick={() => {
                    handleDrawerToggle('Kids')
                    setSelectedGender('Kids')
                  }}
                  fontSize={'xl'}
                >
                  {t('global.title_kids')}
                </AccordionButton>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton px={0} fontSize={'xl'} onClick={() => navigate('/customer_service')}>
                  {t('components.button.support')}
                </AccordionButton>
              </AccordionItem>
            </Accordion>
          </DrawerBody>
          <DrawerFooter p={0}>
            <Flex backgroundColor='#D3D3CA' width='full' height='50px' alignItems='center' gap={2} justifyContent='flex-start' px={8}>
              <LanguageSelector />
              <Flex>{selectedLanguage === 'EN' ? i18n.t('global.langauge_english') : i18n.t('global.langauge_danish')}</Flex>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <CategoryDrawer isOpen={isDrawerOpen} onClose={closeAll} onCloseCategoryDrawer={closeCategoryDrawer} selectedGender={selectedGender} />
    </>
  )
}
