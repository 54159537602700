import { Flex, Img, Box, Text, Heading, useMediaQuery, LinkBox, LinkOverlay } from '@chakra-ui/react'
import { Theme } from '../../../../ThemeVariables'
import { FindGenderSaleImagesForStore } from '../../../../utils/GoogleCloudBucketUtil'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

interface GenderSaleProps {
  StoreByDisplayName: any
}

export const GenderSale = ({ StoreByDisplayName }: GenderSaleProps) => {
  const images = FindGenderSaleImagesForStore(StoreByDisplayName)
  const navigate = useNavigate()
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const targetURL = (gender: string) => {
    switch (gender) {
      case 'boy':
      case 'girl':
        return `/products/kids?store=${StoreByDisplayName}`
      case 'teens':
        return `/products/kids/girl_9_16?store=${StoreByDisplayName}`
      case 'women':
        return `/products/women?store=${StoreByDisplayName}`
      case 'men':
        return `/products/men?store=${StoreByDisplayName}`
      default:
        return ''
    }
  }

  const getHeadingText = (type: string) => {
    switch (type) {
      case 'men':
        return t('main_app.gender_sale.men')
      case 'women':
        return t('main_app.gender_sale.women')
      case 'girl':
        return t('main_app.gender_sale.girl')
      case 'boy':
        return t('main_app.gender_sale.boy')
      case 'teens':
        return t('main_app.gender_sale.teens')
      default:
        return ''
    }
  }
  const renderImageWithOverlay = (src: string, type: string) => (
    <LinkBox flex={1} maxWidth={isLargerThan768 ? '50%' : '100%'} height='auto' borderRadius={Theme.spacing.borderRadius} position='relative'>
      <LinkOverlay href={targetURL(type)}>
        <Img width='100%' height='auto' borderRadius={Theme.spacing.borderRadius} src={src} objectFit='contain' />
        <Flex gap={3} direction={'column'} bottom='5%' left='5%' position='absolute'>
          <Box>
            <Text fontSize='24px' color={'white'}>
              {getHeadingText(type)}
            </Text>
          </Box>
        </Flex>
      </LinkOverlay>
    </LinkBox>
  )

  return (
    <Flex width='100%' overflow='hidden' direction='column' alignItems='center'>
      <Flex width='100%' gap={4} direction={isLargerThan768 ? 'row' : 'column'} wrap='nowrap'>
        {images.men && renderImageWithOverlay(images.men, 'men')}
        {images.women && renderImageWithOverlay(images.women, 'women')}
        {images.girl && renderImageWithOverlay(images.girl, 'girl')}
        {images.boy && renderImageWithOverlay(images.boy, 'boy')}
        {images.teens && renderImageWithOverlay(images.teens, 'teens')}
      </Flex>
    </Flex>
  )
}
