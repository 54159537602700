import { Flex, Grid, Link as ChakraLink, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import GetBrandFacetsForGender from '../../gender_home_page/components/GetBrandsFacets'

import { convertPathToKey } from '../../PLP/utils'

export const PopularBrandsByMainCategory = ({ fontSize, fontWeight }: any) => {
  const brandFacets = GetBrandFacetsForGender()

  return (
    <Flex gap={'1.5rem'} textAlign={'start'} direction={'column'}>
      <Text fontSize={fontSize} fontWeight={fontWeight}>
        {t('main_app.landing_page.heading_popular_brands')}
      </Text>
      <Grid templateColumns={{ base: '1fr 1fr', md: '1fr 1fr 1fr' }} gap={'1.5rem'}>
        <Flex direction={'column'} key='women'>
          {renderCategory('women')}
        </Flex>
        <Flex direction={'column'} gap={'2rem'} key='menAndKids' display={{ base: 'flex', md: 'none' }}>
          <Flex direction={'column'}>{renderCategory('men')}</Flex>
          <Flex direction={'column'}>{renderCategory('kids')}</Flex>
        </Flex>
        <Flex direction={'column'} key='men' display={{ base: 'none', md: 'flex' }}>
          {renderCategory('men')}
        </Flex>
        <Flex direction={'column'} key='kids' display={{ base: 'none', md: 'flex' }}>
          {renderCategory('kids')}
        </Flex>
      </Grid>
    </Flex>
  )

  function renderCategory(category: string) {
    return (
      <>
        <Text fontSize={fontSize} fontWeight={fontWeight} marginBottom='1rem'>
          {t(`global.title_${category}`)}
        </Text>
        <Flex direction={'column'} gap={'0.5rem'}>
          {brandFacets[category as keyof typeof brandFacets] &&
            brandFacets[category as keyof typeof brandFacets].map((facet: any, index: any) => (
              <Flex key={index}>
                <ChakraLink href={`/products/${convertPathToKey(category)}?brand=${encodeURIComponent(facet.term)}`}>{facet.term.toUpperCase()}</ChakraLink>
              </Flex>
            ))}
        </Flex>
      </>
    )
  }
}
