import withAuth from '../../../utils/WithAuth'
import { MainRole } from '../../../generated/graphql'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { ReturnedItems } from './steps/ReturnedItems'
import { ReturnReason } from './steps/ReturnReason'
import { ReturnStatus } from './steps/ReturnStatus'
import { useReturn } from '../../../context/OrderReturnContext'
import { ReturnStoreOrShipping } from './steps/ReturnStoreOrShipping'
import { ReturnOverview } from './steps/ReturnOverview'
import { ReturnConfirmation } from './steps/ReturnConfirmation'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'

const ReturnPage = () => {
  const navigate = useNavigate()
  const { email, orderNumber, step } = useReturn()
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  useEffect(() => {
    if (orderNumber == null) {
      navigate('/return/login')
    }
  }, [])

  const Step = () => {
    switch (step) {
      case 0:
        return <ReturnedItems />
      case 1:
        return <ReturnReason />
      case 2:
        return <ReturnStatus />
      case 3:
        return <ReturnStoreOrShipping />
      case 4:
        return <ReturnOverview />
      case 5:
        return <ReturnConfirmation />
    }
    return <></>
  }
  return (
    <MainPageResponsive loading={false}>
      <Step />
    </MainPageResponsive>
  )
}

export default withAuth(ReturnPage, [MainRole.RoleGuest])
