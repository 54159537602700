import { Checkbox, Flex, Img, Select } from '@chakra-ui/react'
import { t } from 'i18next'
import { centPriceToPrice, moneyFormat } from '../../../../../utils/MoneyUtil'

export const SelectorRow = () => {
  return <Checkbox variant={'baseStyle'} />
}

export const ProductNameRow = ({ productName }: any) => {
  return <Flex>{productName}</Flex>
}

export const ImageRow = ({ src }: any) => {
  return <Img borderRadius={8} w={10} src={src} />
}

export const SizeRow = ({ size }: any) => {
  return <Flex>{size}</Flex>
}

export const PriceRow = ({ value }: any) => {
  const currency = 'DKK'
  return <Flex>{moneyFormat(centPriceToPrice(value, 2), currency, 'da-DK')}</Flex>
}

export const StateRow = () => {
  return (
    <Select w={140} variant={'alternativeGreen'}>
      <option value='published'>{t("partner_portal.merchant.products.components.option_status[0]")}</option>
      <option value='published'>{t("partner_portal.merchant.products.components.option_status[1]")}</option>
    </Select>
  )
}

export const StockRow = ({ stock }: any) => {
    return <Flex>{stock}</Flex>
}
