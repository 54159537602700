import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { Order } from '@commercetools/platform-sdk'
import { Toast } from '@chakra-ui/react'

export const useFetchOrders = (distributionChannelKey: string) => {
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        const apiUrl = `${process.env.REACT_APP_ORDERS_API_URL}/orders?returns=true`
        const response = await fetch(apiUrl, {
          headers: { Authorization: `Bearer ${token}` },
        })

        if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`)
        const data = await response.json()

        // Filter orders here
        const filteredOrders = data.filter((order: Order) =>
          order.lineItems.some(
            (item) =>
              ['lineitem_partial_return_requested', 'lineitem_return_requested'].includes(item.state[0].state.obj?.key ?? '') &&
              item.distributionChannel?.obj?.key === distributionChannelKey,
          ),
        )

        setOrders(filteredOrders)
      } catch (error) {
        setError(error as Error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchOrders()
  }, [distributionChannelKey])

  return { orders, isLoading, error }
}
