import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Text, Flex, Link, LinkOverlay, LinkBox } from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Variant } from '../../../../generated/graphql'
import { PdfSizeGuideModal } from '../../../../utils/PdfSizeGuideUtil'
import { PlentyProduct } from '../../PLP/types'
import i18n from '../../../../I18n/i18n'
import { findProductVariantAttributeDefaultEnglish, findProductVariantAttributeValueFromSelectedLanguage } from '../../../../utils/ProductUtil'
type ProductInformationProps = {
  product: PlentyProduct | undefined
  variantHasBundle: boolean
}

export const ProductInformation = (props: ProductInformationProps) => {
  const chainName = findProductVariantAttributeDefaultEnglish(props.product?.masterVariant, 'brand')
  const selectedLanguage = (): 'da-DK' | 'en' => {
    const lang = localStorage.getItem('languageSelected') || i18n.language
    switch (lang) {
      case 'DK':
        return 'da-DK'
      case 'EN':
      default:
        return 'en'
    }
  }
  const language = selectedLanguage()
  const [base64, setBase64] = useState<any | null>(null)
  const [isPdfModalOpen, setIsPdfModalOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const isMobile = window.innerWidth <= 768
  const [pdfUrl, setPdfUrl] = useState<any>(null)

  const getLabel = async () => {
    setIsLoading(true)
    const filePath = '../../../../StoreSizeGuides/' + chainName + '.pdf'

    const base64 = await convertPdfToBase64(filePath)

    const blob = base64ToBlob(base64) // create blob here

    const pdfUrl = URL.createObjectURL(blob) // create object URL here

    setPdfUrl(pdfUrl) // set state here

    setBase64(base64)

    setIsPdfModalOpen(true)

    setIsLoading(false)
    setIsLoading(false)
  }

  const downloadLabel = (url = pdfUrl) => {
    const link = document.createElement('a')
    link.href = url
    link.download = `${chainName}.pdf`
    link.click()
  }

  const mobileDownloadLabel = async () => {
    setIsLoading(true)
    const filePath = '../../../../StoreSizeGuides/' + chainName + '.pdf'

    const base64 = await convertPdfToBase64(filePath)

    const blob = base64ToBlob(base64) // create blob here

    const pdfUrl = URL.createObjectURL(blob) // create object URL here

    setPdfUrl(pdfUrl) // set state here

    setBase64(base64)

    setIsPdfModalOpen(true)

    setIsLoading(false)

    downloadLabel(pdfUrl) // initiate download

    setIsLoading(false)
  }

  const base64ToBlob = (base64: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'application/pdf' })
    return blob
  }

  const convertPdfToBase64 = async (filePath: string): Promise<string> => {
    const response = await fetch(filePath)
    if (!response.ok) {
      throw new Error('Error fetching the PDF file')
    }
    const arrayBuffer = await response.arrayBuffer()
    const uint8Array = new Uint8Array(arrayBuffer)

    let binaryString = ''
    for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i])
    }
    const base64String = btoa(binaryString)
    return base64String
  }

  useEffect(() => {
    if (isMobile && pdfUrl && isPdfModalOpen) {
      const link = document.createElement('a')
      link.href = pdfUrl
      document.body.appendChild(link)
      document.body.removeChild(link)
    }
  }, [isMobile, pdfUrl, isPdfModalOpen])

  return (
    <Accordion allowMultiple flex={1} borderColor={'beigeDark.500'}>
      <AccordionItem py={2} borderColor={'beigeDark.500'}>
        <h2>
          <AccordionButton>
            <Text fontSize={'xl'} as='span' flex='1' textAlign='left'>
              {t('main_app.pdp.components.product_information.text_description')}
            </Text>
            <AccordionIcon fontSize={'2xl'} />
          </AccordionButton>
        </h2>
        <AccordionPanel whiteSpace={'pre-wrap'} pb={4}>
          {props.product?.masterVariant && findProductVariantAttributeValueFromSelectedLanguage(props.product.masterVariant, 'description', language)}
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem py={2}>
        <h2>
          <AccordionButton>
            <Text fontSize={'xl'} as='span' flex='1' textAlign='left'>
              {t('main_app.pdp.components.product_information.text_size_and_fit')}
            </Text>
            <AccordionIcon fontSize={'2xl'} />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} _hover={{ cursor: 'pointer' }}>
          {isMobile ? (
            <LinkBox
              _active={{
                textDecorationColor: 'transparent',
              }}
              _visited={{
                textDecorationColor: 'transparent',
              }}
              _focus={{
                textDecorationColor: 'transparent',
              }}
            >
              <LinkOverlay href={pdfUrl} download={`${chainName}.pdf`} onClick={mobileDownloadLabel}>
                <Text textDecoration={'underline'} textDecorationColor={'mintGreen.500'} textDecorationThickness={'2px'} textUnderlineOffset={8}>
                  {t('main_app.pdp.components.product_information.text_description_size')}
                </Text>
              </LinkOverlay>
            </LinkBox>
          ) : (
            <>
              <PdfSizeGuideModal base64={base64} isOpen={isPdfModalOpen} onClose={() => setIsPdfModalOpen(false)} />
              <Text
                onClick={getLabel}
                textDecoration={'underline'}
                textDecorationColor={'mintGreen.500'}
                textDecorationThickness={'2px'}
                textUnderlineOffset={8}
                cursor='pointer'
              >
                {t('main_app.pdp.components.product_information.text_description_size')}
              </Text>
            </>
          )}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem py={2}>
        <h2>
          <AccordionButton>
            <Text fontSize={'xl'} as='span' flex='1' textAlign='left'>
              {t('main_app.pdp.components.product_information.text_shipping')}
            </Text>
            <AccordionIcon fontSize={'2xl'} />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Flex direction={'column'} gap={4}>
            <Flex direction={'column'}>
              <Text fontWeight={'bold'}>{t('global.text_delivery_price.2')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_pick_up.0')}</Text>
            </Flex>

            <Flex direction={'column'}>
              <Text fontWeight={'bold'}>{t('global.text_delivery_price.1')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_pick_up.1')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_free_delivery')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_delivery_time')}</Text>
            </Flex>
            <Flex direction={'column'}>
              <Text fontWeight={'bold'}>{t('global.text_delivery_price.0')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_package_address')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_delivery_time')}</Text>
            </Flex>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem py={2}>
        <h2>
          <AccordionButton>
            <Text fontSize={'xl'} as='span' flex='1' textAlign='left'>
              {t('main_app.pdp.components.product_information.text_return')}
            </Text>
            <AccordionIcon fontSize={'2xl'} />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Flex direction={'column'} gap={4}>
            <Flex direction={'column'}>
              <Text fontWeight={'bold'}>{t('global.text_delivery_price.2')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_return_item.0')}</Text>
            </Flex>
            <Flex direction={'column'}>
              <Text fontWeight={'bold'}>{t('global.text_delivery_price.1')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_return_item.1')}</Text>
              <Text> {t('main_app.pdp.components.product_information.text_return_item.2')}</Text>
            </Flex>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      {props.variantHasBundle ? (
        <AccordionItem py={2}>
          <h2>
            <AccordionButton>
              <Text fontSize={'xl'} as='span' flex='1' textAlign='left'>
                Exclusive Store Discounts
              </Text>
              <AccordionIcon fontSize={'2xl'} />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Flex direction={'column'} gap={2}>
              <Text fontWeight={'bold'}>Exclusive Store Discounts</Text>
              <Text>
                This special offer is unique to this store and cannot be combined with discounts or bundles from other stores. To unlock this exclusive deal,
                simply add the item or bundle to your cart and proceed to checkout. For bundle discounts, ensure you include the correct number of items related
                to the bundle to activate the discount in your shopping cart.
              </Text>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      ) : null}
    </Accordion>
  )
}
