import { Box, Flex, Text, Img, LinkBox, LinkOverlay, Stack } from '@chakra-ui/react'
import React from 'react'
import { t } from 'i18next'
import { findStoreBanner, findStoreLabelIcon } from '../../utils/GoogleCloudBucketUtil'
import { useNavigate } from 'react-router-dom'

type StoreProps = {
  storeName: any
  storeAddress?: string
  to?: any
  scrollRef?: any
  size?: string
  storeIndex?: number
  storeNumber?: number
  storeDisplayNameUrl?: any
}

export const StoreCard = (props: StoreProps & { hasDragged?: boolean }) => {
  const navigate = useNavigate()
  const handleCardClick = () => {
    if (!props.hasDragged) {
      navigate(props.to)
    }
  }
  const OpeningHours = () => {
    const currentTime = new Date()
    const currentHour = currentTime.getHours()
    const isOpen = currentHour >= 10 && currentHour < 19
    const openStyle = { color: 'green' }
    const closeStyle = { color: 'red' }

    return (
      <Flex>
        {isOpen ? (
          <React.Fragment>
            <Text variant={'storeCardStoreAddresStyling'} style={openStyle} mr={1}>
              {t('components.store_card.text_open')}{' '}
            </Text>

            <Text variant={'storeCardStoreAddresStyling'}>{t('components.store_card.text_closes_at')}</Text>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Text variant={'storeCardStoreAddresStyling'} style={closeStyle} mr={1}>
              {t('components.store_card.text_closed')}
            </Text>

            <Text variant={'storeCardStoreAddresStyling'}>{t('components.store_card.text_opens_at')}</Text>
          </React.Fragment>
        )}
      </Flex>
    )
  }

  return (
    <LinkBox>
      <LinkOverlay onClick={handleCardClick}>
        <Flex direction={'column'} borderRadius={'24px 24px 0px 0px'}>
          <Stack gap={'1rem'}>
            <Flex position={'relative'} h={'auto'} cursor={'pointer'}>
              <Box
                position='absolute'
                left='0'
                bottom='5%'
                width='40%'
                borderRadius={'0 2rem 2rem 0'}
                height={'20%'}
                backgroundColor='white'
                zIndex='1'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Img src={findStoreLabelIcon(props.storeDisplayNameUrl)} alt='store icon' />
              </Box>

              <Img
                draggable={false}
                maxWidth={props.size}
                width={props.size}
                borderRadius={'24px'}
                objectFit={'contain'}
                src={findStoreBanner(props.storeDisplayNameUrl)}
                _hover={{ filter: 'brightness(60%)', cursor: 'pointer' }}
                zIndex='0'
              />
            </Flex>
            <Flex maxW={props.size} textAlign={'start'} direction={'column'} style={{ marginTop: '0' }} wrap={'wrap'}>
              <Text variant='storeCardStoreNameStyling'>{props.storeName}</Text>
              <Flex direction={'column'}>
                <Text variant={'storeCardStoreAddresStyling'}>{props.storeAddress}</Text>
                <OpeningHours />
              </Flex>
            </Flex>
          </Stack>
        </Flex>
      </LinkOverlay>
    </LinkBox>
  )
}
