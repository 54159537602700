import { gql } from '@apollo/client'

export const GET_PARCEL_SHOP_BY_GEOLOCATION = gql`
  query GetParcelShopByGeolocation($parcelShopQuery: ParcelShopQuery) {
    getParcelShopByGeolocation(parcelShopQuery: $parcelShopQuery) {
      distance
      parcelShop {
        id
        carrier
        city
        country
        name
        addressLine1
        openingTimes {
          closedDuring
          day
          from
          to
        }
        postCode
        type
      }
    }
  }
`

export const CREATE_AND_REGISTER = gql`
  mutation CreateAndRegister($orderNumber: String!) {
    createAndRegister(orderNumber: $orderNumber) {
      orderNumber
    }
  }
`

export const GET_SHIPMENT_LABEL = gql`
  query GetShipmentLabel($orderNumber: String, $distributionChannelId: String) {
    getShipmentLabel(orderNumber: $orderNumber, distributionChannelId: $distributionChannelId)
  }
`

export const GET_SHIPMENT_LABEL_BY_TRACKING_ID = gql`
  query ShipmentLabelByTracking($orderNumber: String, $trackingId: String) {
    getShipmentLabelByTrackingId(orderNumber: $orderNumber, trackingId: $trackingId)
  }
`
