import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth } from '@aws-amplify/auth'

const httpLink = createHttpLink({
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_GATEWAY_GRAPH_URI,
})

const authLink = setContext(async (_, { headers }) => {
  try {
    await Auth.currentAuthenticatedUser()
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    return {
      headers: {
        ...headers,
        ...(token ? { Authorization: `${token}` } : null),
      },
    }
  } catch (e) {
    return {
      headers: {
        ...headers,
      },
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default client
