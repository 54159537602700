import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  StackDivider,
  useToast,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { Maybe, useChangeOrderLineStateMutation } from '../../../../../generated/graphql'
import { OrderLineItemCard } from './OrderLineItemCard'
import { useEffect, useState } from 'react'
import { useUser } from '../../../../../context/Auth'
import { LineItem } from '@commercetools/platform-sdk'
import { findVariantAttributeDefaultEnglish } from '../../../../../utils/ProductUtil'

type OrderLineItemsModalProps = {
  isOpen: boolean
  orderNumber: string | undefined
  onClose: () => void
  lineItems: LineItem[]
  acceptedModal: boolean
}
type DeclineReason = {
  commercetoolsId: string
  reason: string
  toggled: boolean
}
export const OrderLineItemsModal = ({ orderNumber, lineItems, isOpen, onClose, acceptedModal }: OrderLineItemsModalProps) => {
  const [declineReasons, setDeclineReasons] = useState<any[]>([])
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({})

  const toast = useToast()
  const { distributionChannelKey } = useUser()
  const [changeOrderStatus, { loading }] = useChangeOrderLineStateMutation()

  useEffect(() => {
    const declineReasonArray: DeclineReason[] = []

    lineItems?.forEach((oli: Maybe<LineItem>) => declineReasonArray.push({ commercetoolsId: oli?.id || '', reason: '', toggled: false }))

    setDeclineReasons(declineReasonArray)
  }, [])
  const handleCheckboxChange = (commercetoolsId: string, isChecked: boolean) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [commercetoolsId]: isChecked,
    }))

    setDeclineReasons((prevDeclineReasons) =>
      prevDeclineReasons.map((reason) => {
        if (reason.commercetoolsId === commercetoolsId) {
          return {
            ...reason,
            toggled: isChecked,
          }
        }
        return reason
      }),
    )
  }
  const handleSelectChange = (commercetoolsId: string, reason: string) => {
    setDeclineReasons((prevDeclineReasons) =>
      prevDeclineReasons.map((item) => {
        if (item.commercetoolsId === commercetoolsId) {
          return {
            ...item,
            reason: reason,
          }
        }
        return item
      }),
    )
  }

  const disableButtonIfNoReasons = (): boolean => {
    return declineReasons.filter((reason) => reason.toggled && reason.reason !== '').length <= 0
  }

  const handleDeclineOrder = async () => {
    try {
      lineItems.forEach(async (item) => {
        const lineItemUrl = `${process.env.REACT_APP_ORDERS_API_URL}/state/lineitem/${orderNumber}/${item.id}?transitionToStateKey=lineitem_cancelled`
        await fetch(lineItemUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      })
    } catch (error) {
      console.error('Error loading orders:', error)
    }
  }

  const handleAcceptOrder = async () => {
    try {
      lineItems.forEach(async (item) => {
        const lineItemUrl = `${process.env.REACT_APP_ORDERS_API_URL}/state/lineitem/${orderNumber}/${item.id}?transitionToStateKey=lineitem_confirmed`
        await fetch(lineItemUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      })
    } catch (error) {
      console.error('Error loading orders:', error)
    }
  }

  return (
    <Modal size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{acceptedModal ? 'ACCEPT ORDER' : 'DECLINE ORDER'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {acceptedModal ? (
            <Flex direction={'column'} gap={5}>
              <Flex>{t('partner_portal.merchant.orders.components.text_following_items')}</Flex>
              <Stack divider={<StackDivider />}>
                {lineItems?.map((value: Maybe<LineItem>, index: number) => {
                  return (
                    <Flex key={index}>
                      <HStack spacing={5}>
                        <OrderLineItemCard
                          key={value?.id}
                          color={findVariantAttributeDefaultEnglish(value?.variant ?? null, 'color')}
                          size={findVariantAttributeDefaultEnglish(value?.variant ?? null, 'color')}
                          name={value?.name.en}
                          styleNumber={value?.productSlug?.en}
                          quantity={value?.quantity}
                          ean={value?.variant.sku}
                          imageUrl={value?.variant.images?.[0].url}
                          price={value?.price.value.centAmount}
                        />
                      </HStack>
                    </Flex>
                  )
                })}
              </Stack>
            </Flex>
          ) : (
            <Stack spacing={5} flex={1}>
              <Heading size={'sm'}>{t('partner_portal.merchant.orders.components.heading_select_reason')}</Heading>
              {lineItems?.map((value: Maybe<LineItem>, index: number) => {
                const commercetoolsId = value?.id || ''
                return (
                  <Flex key={index}>
                    <Stack spacing={5}>
                      <Flex flex={1} gap={4} justifyContent={'space-between'}>
                        <Checkbox isChecked={checkedItems[commercetoolsId]} onChange={(e) => handleCheckboxChange(commercetoolsId, e.target.checked)} />
                        <OrderLineItemCard
                          key={value?.id}
                          color={findVariantAttributeDefaultEnglish(value?.variant ?? null, 'color')}
                          size={findVariantAttributeDefaultEnglish(value?.variant ?? null, 'size')}
                          name={value?.name.en}
                          styleNumber={value?.productSlug?.en}
                          quantity={value?.quantity}
                          ean={value?.variant.sku}
                          imageUrl={value?.variant.images?.[0].url}
                          price={value?.price.value.centAmount}
                        />
                      </Flex>
                      <FormControl isRequired isDisabled={!checkedItems[commercetoolsId]}>
                        <FormLabel>{t('partner_portal.merchant.orders.components.form_label_decline_reason')}</FormLabel>
                        <Select defaultValue='' onChange={(e: any) => handleSelectChange(commercetoolsId, e.target.value)}>
                          <option value='' disabled>
                            {t('partner_portal.merchant.orders.components.option_select_reason')}
                          </option>
                          <option value='Unavailable'>{t('partner_portal.merchant.orders.components.option_options_for_product.0')}</option>
                          <option value='Damaged'>{t('partner_portal.merchant.orders.components.option_options_for_product.1')}</option>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Flex>
                )
              })}
            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          {acceptedModal ? (
            <>
              <Button lineHeight={0} onClick={onClose}>
                {t('components.button.cancel')}
              </Button>
              <Button isLoading={loading} onClick={handleAcceptOrder} lineHeight={0} ml={3} variant={'solidGreen'}>
                Accept Order
              </Button>
            </>
          ) : (
            <>
              <Button lineHeight={0} onClick={onClose}>
                {t('components.button.cancel')}
              </Button>
              <Button isLoading={loading} onClick={handleDeclineOrder} isDisabled={disableButtonIfNoReasons()} variant={'solidRed'} lineHeight={0} ml={3}>
                Confirm
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
