import { extendTheme } from '@chakra-ui/react'
import { buttonTheme } from './components/ButtonStyle'
import { inputTheme } from './components/InputStyle'
import { sliderTheme } from './components/SliderStyle'
import { checkboxTheme } from './components/CheckboxStyle'
import { radioTheme } from './components/RadioStyle'
import { selectTheme } from './components/SelectStyle'
import { cardTheme } from './components/CardStyle'
import { headingTheme } from './components/HeadingStyle'
import { menuTheme } from './components/MenuStyle'
import { modalTheme } from './components/Modal'
import { textTheme } from './components/TextStyle'

export const theme = extendTheme({
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Slider: sliderTheme,
    Checkbox: checkboxTheme,
    Menu: menuTheme,
    Radio: radioTheme,
    Select: selectTheme,
    Card: cardTheme,
    Heading: headingTheme,
    Modal: modalTheme,
    Text: textTheme,
    Tag: {
      baseStyle: {
        borderRadius: 'full',
        fontWeight: 'light',
      },
      variants: {
        black: {
          bgColor: 'black',
          color: 'white',
          p: 5,
        },
      },
    },
  },
  variants: {
    white: {
      field: {
        bgColor: 'white',
        color: 'black',
        borderRadius: 'full',
        p: 8,
      },
    },
  },

  fonts: {
    heading: `'Suisse Intl', sans-serif`,
    body: `'Suisse Intl', sans-serif`,
  },
  colors: {
    white: {
      100: '#FFFFFF',
      200: '#FFFFFF',
      300: '#FFFFFF',
      400: '#FFFFFF',
      500: '#FFFFFF',
      600: '#FFFFFF',
      700: '#FFFFFF',
      800: '#FFFFFF',
      900: '#FFFFFF',
    },
    black: {
      100: '#F3F3F3',
      200: '#E7E7E7',
      300: '#B7B7B7',
      400: '#707070',
      500: '#121212',
      600: '#0F0D0D',
      700: '#0C0909',
      800: '#0A0506',
      900: '#080305',
    },
    mintGreen: {
      100: '#E3FEE4',
      200: '#C8FECE',
      300: '#ADFCBD',
      400: '#98FAB5',
      500: '#76F8AA',
      600: '#56D595',
      700: '#3BB282',
      800: '#258F6F',
      900: '#167763',
    },
    darkGreen: {
      100: '#D6F3D6',
      200: '#AFE8B6',
      300: '#76BA85',
      400: '#407651',
      500: '#0B1C12',
      600: '#081810',
      700: '#05140E',
      800: '#03100C',
      900: '#020D0B',
    },
    beigeLight: {
      100: '#FEFEFC',
      200: '#FDFDFA',
      300: '#F9F9F5',
      400: '#F4F4EF',
      500: '#EDEDE7',
      600: '#CBCBA8',
      700: '#AAAA74',
      800: '#898949',
      900: '#71712C',
    },
    beigeDark: {
      100: '#FCFCFA',
      200: '#FAFAF6',
      300: '#F1F1EB',
      400: '#E4E4DD',
      500: '#D3D3CA',
      600: '#B5B593',
      700: '#979765',
      800: '#7A7A40',
      900: '#656526',
    },
  },
})
