import { useApolloClient } from '@apollo/client'
import { Button } from '@chakra-ui/react'
import { t } from 'i18next'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AdminPartnerPortalPage } from '../../../../components/layout/partnerPortal/admin/AdminPartnerPortalPage'
import { MainRole, useGetMerchantApplicationQuery } from '../../../../generated/graphql'
import { GET_MERCHANT_APPLICATION_BY_ONBOARDINGNUMBER, PLENTY_APPROVE_MERCHANT_APPLICATION } from '../../../../gql/onboarding'
import withAuth from '../../../../utils/WithAuth'

const AdminOnboardingPage = () => {
  const client = useApolloClient()
  const params = useParams()

  const [loading, setLoading] = useState(false)

  const onboardingApplication = useGetMerchantApplicationQuery({
    variables: {
      onboardingNumber: params.onboardingNumber,
    },
  })

  const approveApplication = () => {
    setLoading(true)

    client
      .mutate({
        mutation: PLENTY_APPROVE_MERCHANT_APPLICATION,
        variables: {
          onboardingNumber: onboardingApplication.data?.getMerchantApplication?.onboardingNumber,
        },
      })
      .then(() => {
        client.refetchQueries({
          include: [GET_MERCHANT_APPLICATION_BY_ONBOARDINGNUMBER],
        })
        setLoading(false)
      })
  }

  return (
    <AdminPartnerPortalPage pageHeaderLabel={t('partner_portal.admin.page_header_label') as string}>
      <Button
        isLoading={loading}
        loadingText={'Processing...'}
        isDisabled={onboardingApplication.data?.getMerchantApplication?.plentyApproved}
        variant={'solidGreen'}
        onClick={() => approveApplication()}
      >
        {onboardingApplication.data?.getMerchantApplication?.plentyApproved
          ? t('components.button.approve_application.0')
          : t('components.button.approve_application.1') + onboardingApplication.data?.getMerchantApplication?.id}
      </Button>
    </AdminPartnerPortalPage>
  )
}

export default withAuth(AdminOnboardingPage, [MainRole.RoleGuest])
