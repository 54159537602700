import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ChakraThemeConstants } from '../ChakraThemeConstants'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const solid = definePartsStyle({
  field: {
    color: 'black',
    backgroundColor: 'beigeLight.500',
    height: ChakraThemeConstants.componentHeight,
    _invalid: {
      border: '2px',
      borderColor: 'red.400',
    },
  },
})

const rounded = definePartsStyle({
  field: {
    borderRadius: 'full',
    color: 'black',
    backgroundColor: 'beigeLight.500',
    height: ChakraThemeConstants.componentHeight,
  },
})

const solidWhite = definePartsStyle({
  field: {
    color: 'black',
    backgroundColor: 'white.500',
    height: ChakraThemeConstants.componentHeight,
    _invalid: {
      border: '2px',
      borderColor: 'red.400',
    },
  },
})

const roundedSolidWhite = definePartsStyle({
  field: {
    borderRadius: 'full',
    color: 'black',
    backgroundColor: 'white.500',
    height: ChakraThemeConstants.componentHeight,
  },
})

const roundedSolidBeige = definePartsStyle({
  field: {
    borderRadius: 'full',
    color: 'black',
    backgroundColor: 'beigeLight.500',
    height: ChakraThemeConstants.componentHeight,
  },
})

const roundedOutlineWhite = definePartsStyle({
  field: {
    borderRadius: 'full',
    color: 'black',
    backgroundColor: 'white.500',
    height: ChakraThemeConstants.componentHeight,
    border: '1px',
    borderColor: 'beigeLight.500',
  },
})

const outlineWhite = definePartsStyle({
  field: {
    color: 'black',
    backgroundColor: 'white.500',
    height: ChakraThemeConstants.componentHeight,
    border: '1px',
    borderColor: 'beigeLight.500',
  },
})

const roundedSolidGrey = definePartsStyle({
  field: {
    borderRadius: 'full',
    color: 'black',
    backgroundColor: 'rgba(211, 211, 202, 1)',
    height: ChakraThemeConstants.componentHeight,
  },
})
const roundedBlack = definePartsStyle({
  field: {
    borderRadius: 'full',
    textColor: 'white',
    color: 'white',
    backgroundColor: 'black',
    height: ChakraThemeConstants.componentHeight,
    '::placeholder': {
      color: 'white',
    },
  },
})

const simple = definePartsStyle({
  field: {
    borderRadius: 16,
    textColor: 'darkGreen.500',
    color: 'darkGreen.500',
    backgroundColor: 'transparent',
    '::placeholder': {
      color: 'gray.500',
    },
    border: '1px',
    _invalid: {
      border: '1px',
      borderColor: 'red.400',
    },
  },
  element: {
    color: 'darkGreen.500',
  },
})

export const inputTheme = defineMultiStyleConfig({
  variants: { solid, solidWhite, rounded, roundedSolidWhite, roundedSolidBeige, roundedBlack, roundedSolidGrey, simple, roundedOutlineWhite, outlineWhite },
})
