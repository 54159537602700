import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { getChannels, getProductsProjectionSearchForSimilarItems } from '../../../../commercetools/requests'
import { PlentyProduct } from '../../PLP/types'
import { formatPlentyProductForSliders, parseCategorySlugToURL } from '../utils'

import { Flex, Spinner } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { PlentySlider } from '../../../../components/layout/PlentySlider/PlentySlider'
import { ProductCard } from '../../PLP/components/ProductCard'
import { findProductVariantAttributeDefaultEnglish } from '../../../../utils/ProductUtil'

export const SimilarItemsSlider = ({ product, category }: { product: PlentyProduct | undefined; category: string | undefined }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [products, setProducts] = useState<PlentyProduct[] | undefined>([])
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getChannels().then((channels) => {
      const storesToFilter = channels?.map((channel) => channel.id)
      getProductsProjectionSearchForSimilarItems(
        product?.name.en,
        findProductVariantAttributeDefaultEnglish(product?.masterVariant, 'gender'),
        findProductVariantAttributeDefaultEnglish(product?.masterVariant, 'target_group'),
        storesToFilter,
      )
        .then((productsProjection) => {
          const plentyProducts = productsProjection?.map((productProjection) => formatPlentyProductForSliders(productProjection))
          setProducts(plentyProducts)
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }, [])

  const navigateToViewAll = () => {
    let baseUrl = ''
    const queryParams = new URLSearchParams()

    if (category) {
      baseUrl = `/products/${parseCategorySlugToURL(category.toLowerCase())}`
    }

    navigate(`${baseUrl}?${queryParams.toString()}`)
  }

  const navigateToProduct = (product?: PlentyProduct) => {
    let baseUrl = ''
    const queryParams = new URLSearchParams()

    if (product) {
      baseUrl = `/product/${product.key}`
      queryParams.append('variant', product.masterVariant.key)
    }

    navigate(`${baseUrl}?${queryParams.toString()}`)
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <PlentySlider
      fontSize='3xl'
      viewAll
      navigateToViewAll={navigateToViewAll}
      navigateToProduct={navigateToProduct}
      heading={t('components.bestseller_store_products_slider.label_similar')}
    >
      {products?.map((product) => (
        <Flex minWidth={'18rem'} key={product.id}>
          <ProductCard product={product} />
        </Flex>
      ))}
    </PlentySlider>
  )
}
