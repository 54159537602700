import { Attribute, Image, Maybe, ProductVariant, ProductVariantPrice as PVP } from '../generated/graphql'
import { PlentyVariant, ProductVariantAttribute, ProductVariantPrice } from '../pages/main_app/PLP/types'
import { ProductVariant as CommercetoolsProductVariant } from '@commercetools/platform-sdk'

export const productVariantToPlentyVariant = (variant: ProductVariant): PlentyVariant => {
  const id = variant.id
  const key = variant.key
  const sku = variant.sku
  const images = variant.images.map((image: Maybe<Image>) => {
    return { url: image?.url ?? '' }
  })
  const attributes: ProductVariantAttribute[] = variant.attributes
    .map((attribute: Maybe<Attribute>) => {
      if (!attribute) return null

      return {
        name: attribute.name ?? '',
        value: attribute.value as unknown as Record<string, string>,
      }
    })
    .filter((attr): attr is ProductVariantAttribute => attr !== null)

  const prices: ProductVariantPrice[] = variant.prices.map((pvp: PVP) => {
    return {
      value: {
        centAmount: pvp.value.centAmount,
        currencyCode: pvp.value.currencyCode,
        fractionDigits: pvp.value.fractionDigits,
      },
      channel: {
        id: pvp.channel?.id ?? '',
        obj: {
          id: '',
          key: pvp.channel?.obj?.key ?? '',
          name: pvp.channel?.obj?.name ?? '',
          address: {
            city: pvp.channel?.obj?.address.city ?? '',
            country: pvp.channel?.obj?.address.country ?? '',
            postalCode: pvp.channel?.obj?.address.postalCode ?? '',
            streetName: pvp.channel?.obj?.address.streetName ?? '',
            streetNumber: pvp.channel?.obj?.address.streetNumber ?? '',
          },
        },
      },
      discounted: pvp.discounted
        ? {
            value: {
              centAmount: pvp.discounted?.value.centAmount ?? 0,
              currencyCode: pvp.discounted?.value.currencyCode ?? '',
              fractionDigits: pvp.discounted?.value.fractionDigits ?? 0,
            },
            discount: {
              id: pvp.discounted?.discount.id ?? '',
              obj: {
                value: {
                  type: 'relative',
                  permyriad: 0,
                },
              },
            },
          }
        : undefined,
    }
  })

  return {
    id: id,
    key: key,
    sku: sku,
    images: images,
    attributes: attributes,
    availability: {
      availableQuantity: 0,
      isOnStock: false,
      restockableInDays: 0,
    },
    prices: prices,
  }
}

export const findProductVariantAttributeDefaultEnglish = (variant: PlentyVariant | undefined, attribute: string) => {
  if (variant === undefined) return ''
  const attr = variant.attributes.find((attr) => attr?.name === attribute) as ProductVariantAttribute
  return attr.value['en'] ?? ''
}

export const findProductVariantAttributeValueFromSelectedLanguage = (variant: PlentyVariant | undefined, attribute: string, locale: string) => {
  if (variant === undefined) return ''
  const attr = variant.attributes.find((attr) => attr?.name === attribute) as ProductVariantAttribute
  return attr.value[locale] ?? ''
}

export const findVariantAttributeDefaultEnglish = (variant: CommercetoolsProductVariant | null, attribute: string) => {
  if (variant === null) return ''
  const attr = variant.attributes?.find((attr) => attr?.name === attribute) as ProductVariantAttribute
  return attr.value['en'] ?? ''
}
