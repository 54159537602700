import { Button, Divider, Flex, Icon, Text, useToast } from '@chakra-ui/react'
import { ArrowLeft } from 'tabler-icons-react'
import { t } from 'i18next'
import React from 'react'
import { useReturn } from '../../../../context/OrderReturnContext'
import { ReturnLineItemInput } from '../../../../generated/graphql'
import { ReturnItemQuantity } from '../../../../types/ReturnItemQuantity'
import { ReturnOrderItemCardFinal } from '../components/ReturnOrderItemCardFinal'
import { ReturnOverviewInStore } from '../components/ReturnOverviewInStore'
import { ReturnOverviewByParcel } from '../components/ReturnOverviewByParcel'
export const ReturnOverview = () => {
  const { order, deliveryOption, returnItemListWithQuantity, parcelResponse, advanceStep, previousStep, resetParcelResponse } = useReturn()
  const toast = useToast()

  const initialWidth = window.innerWidth
  const isLargerThan768 = initialWidth >= 768
  return (
    <Flex gap={4} mx={isLargerThan768 ? 'auto' : 4} direction={'column'} w={isLargerThan768 ? '50%' : '100%'}>
      <Flex gap={4} alignItems={'center'}>
        <Icon
          bgColor={'transparent'}
          onClick={() => {
            previousStep()
            resetParcelResponse()
          }}
          fontSize={'30px'}
          color={'black'}
          as={ArrowLeft}
        />
        <Text fontSize={'2xl'}>{t('main_app.return.components.steps.text_return_overview')}</Text>
      </Flex>
      <Text>{new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</Text>
      <Flex justifyContent={'flex-start'} borderRadius={'3xl'} direction={'column'} w={'full'}>
        <Flex gap={4} direction={'column'}>
          {returnItemListWithQuantity!
            .filter((value: ReturnItemQuantity) => value.quantity >= 1)
            .map((value: ReturnItemQuantity, index: number) => (
              <React.Fragment key={index}>
                <ReturnOrderItemCardFinal itemBroughtFrom={value.store.storeName} returnItemQuantity={value} />
              </React.Fragment>
            ))}
          <Divider style={{ borderWidth: 1, borderColor: 'rgba(211, 211, 202, 1)' }} />
          {parcelResponse?.parcelShop?.carrier === 'POSTNORD' ? (
            <Flex direction={'column'}>
              <ReturnOverviewByParcel />
            </Flex>
          ) : (
            <Flex direction={'column'}>
              <ReturnOverviewInStore />
            </Flex>
          )}
          <Divider style={{ borderWidth: 1, borderColor: 'rgba(211, 211, 202, 1)' }} />
        </Flex>
      </Flex>
    </Flex>
  )
}
