import { isEqual } from 'lodash'
import { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { ampli } from '../ampli'
import client from '../apollo-client'
import { Cart, ChannelReference, LineItem, Maybe, ParcelResponse, useAddLineItemToCartMutation, useGetCartLazyQuery } from '../generated/graphql'
import { CREATE_CART } from '../gql/cart'
import { CartCustomerInformation } from '../types/CartCustomerInformation'

type AddLineItemsProps = {
  styleNumber: string
  ean: string
  quantity: number
  storeKey: string
}

type CartContextType = {
  addLineItems: ({ styleNumber, ean, quantity, storeKey }: AddLineItemsProps) => Promise<void>
  step: number
  setStep: (step: number) => void
  checkoutEditStep: 'Delivery' | 'Payment' | 'Review'
  setCheckoutEditStep: (step: 'Delivery' | 'Payment' | 'Review') => void
  cartDeliveryOption: 'Pickup' | 'HomeDelivery' | 'ParcelShop'
  setCartDeliveryOption: (option: 'Pickup' | 'HomeDelivery' | 'ParcelShop') => void
  cartOpened: boolean
  toggleCartDrawer: () => void
  calculateTotalLineItems: () => number
  cart: Cart
  parcelResponse: ParcelResponse | null
  setParcelResponse: (parcelResponse: ParcelResponse | null) => void
  customerInformation: CartCustomerInformation
  setCustomerInformation: (customerInformation: CartCustomerInformation) => void
  resetCart: () => void
  getUniqueDistributionChannels: () => (Maybe<ChannelReference> | undefined)[]
}

const CartContext = createContext<CartContextType>({
  addLineItems: async () => {
    console.log()
  },
  step: 0,
  setStep: () => undefined,
  checkoutEditStep: 'Delivery',
  setCheckoutEditStep: () => undefined,
  cartDeliveryOption: 'Pickup',
  setCartDeliveryOption: () => undefined,
  cartOpened: false,
  toggleCartDrawer: () => undefined,
  calculateTotalLineItems: () => 0,
  cart: {} as Cart,
  parcelResponse: null,
  setParcelResponse: () => undefined,
  customerInformation: {
    city: '',
    country: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    postalCode: '',
    street: '',
    number: '',
    apartment: '',
    company: '',
  },
  setCustomerInformation: () => undefined,
  resetCart: () => undefined,
  getUniqueDistributionChannels: () => [],
})

export default function CheckoutCartContext({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  const [cartOpened, setCartOpened] = useState(false)
  const [step, setStep] = useState(0)
  const [checkoutEditStep, setCheckoutEditStep] = useState<'Delivery' | 'Payment' | 'Review'>('Delivery')
  const [cartDeliveryOption, setCartDeliveryOption] = useState<'Pickup' | 'HomeDelivery' | 'ParcelShop'>('Pickup')
  const [parcelResponse, setParcelResponse] = useState<ParcelResponse | null>(null)
  const [cart, setCart] = useState<Cart>({
    lineItems: [],
    createdAt: '',
    cartState: '',
    lastModifiedAt: '',
    totalPrice: { centAmount: 1000, currencyCode: 'DKK', fractionDigits: 2 },
    directDiscounts: [],
    version: 1,
    id: 1,
  })
  const [customerInformation, setCustomerInformation] = useState<CartCustomerInformation>({
    city: '',
    country: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    postalCode: '',
    street: '',
    number: '',
    apartment: '',
    company: '',
  })

  const [getCart, { data, loading }] = useGetCartLazyQuery({
    variables: {
      cartId: localStorage.getItem('cartId'),
    },
    fetchPolicy: 'no-cache',
  })
  const [addToCart, { data: addToCartResponse }] = useAddLineItemToCartMutation()

  useEffect(() => {
    const cartId = localStorage.getItem('cartId')

    if (cartId == null) {
      client.mutate({ mutation: CREATE_CART }).then((response: any) => {
        ampli.cartCreated()
        localStorage.setItem('cartId', response.data.createCart.id)
        getCart()
      })
    } else {
      getCart({ variables: { cartId: cartId } })
        .then((response) => {
          if (response?.data?.getCart?.cartState !== 'Active') {
            client.mutate({ mutation: CREATE_CART }).then((response: any) => {
              ampli.cartCreated()
              localStorage.setItem('cartId', response.data.createCart.id)
              getCart()
            })
          } else {
            getCart()
          }
        })
        .catch((error) => {
          client.mutate({ mutation: CREATE_CART }).then((response: any) => {
            ampli.cartCreated()
            localStorage.setItem('cartId', response.data.createCart.id)
            getCart()
          })
        })
    }
  }, [])

  useEffect(() => {
    if (!loading) {
      const cartParse = data?.getCart as Cart
      setCart(cartParse)
    }
  }, [data])

  const addLineItems = async ({ styleNumber, ean, quantity, storeKey }: AddLineItemsProps): Promise<void> => {
    await addToCart({
      variables: {
        cartId: localStorage.getItem('cartId'),
        styleNumber: styleNumber,
        sku: ean,
        quantity: quantity,
        storeKey: storeKey,
      },
    }).then(() => {
      getCart()
    })
  }

  const calculateTotalLineItems = () => {
    return cart?.lineItems?.reduce((total, item) => {
      if (item && item.quantity) {
        return total + item.quantity
      }
      return total
    }, 0)
  }

  const toggleCartDrawer = () => {
    setCartOpened(!cartOpened)
  }

  const resetCart = () => {
    setCart({
      lineItems: [],
      cartState: '',
      createdAt: '',
      lastModifiedAt: '',
      totalPrice: { centAmount: 1000, currencyCode: 'DKK', fractionDigits: 2 },
      directDiscounts: [],
      version: 1,
      id: 1,
    })
    setCheckoutEditStep('Delivery')
    setCustomerInformation({
      city: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      postalCode: '',
      street: '',
      number: '',
      apartment: '',
      company: '',
    })
    setCartDeliveryOption('Pickup')
    setParcelResponse(null)
  }

  const getUniqueDistributionChannels = () => {
    const distributionChannels = cart.lineItems.map((lineItem: Maybe<LineItem>) => lineItem?.distributionChannel)
    const uniqueDistributionChannels = distributionChannels.filter(
      (channel, index, array) => !array.slice(0, index).some((prevChannel) => isEqual(channel, prevChannel)),
    )

    return uniqueDistributionChannels
  }

  return (
    <CartContext.Provider
      value={{
        customerInformation,
        setCustomerInformation,
        parcelResponse,
        setParcelResponse,
        addLineItems,
        step,
        setStep,
        checkoutEditStep,
        setCheckoutEditStep,
        cartDeliveryOption,
        setCartDeliveryOption,
        cartOpened,
        toggleCartDrawer,
        calculateTotalLineItems,
        cart,
        resetCart,
        getUniqueDistributionChannels,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = (): CartContextType => useContext(CartContext)
