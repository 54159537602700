import React, { useContext, useState } from 'react'
import { Flex, Heading } from '@chakra-ui/react'

import { useReturn } from '../../../../context/OrderReturnContext'

export const ReturnInStoreConfirmation = () => {
  const { email } = useReturn()
  const [showOpeningTimes, setShowOpeningTimes] = useState(false)
  //i18n TODO

  return (
    <Flex width={'100%'} direction={'column'} gap={4}>
      {/* <Heading fontSize={'xl'}>{t('main_app.return.heading_return')}</Heading> */}
      <Heading fontSize={'xl'}>Items can now be returned in the store you brought them from</Heading>
      <Flex>Email sent to {email}</Flex>

      {/* <Flex bgColor={'white'} direction={'column'} padding={Theme.spacing.padding.mobile} borderRadius={Theme.spacing.borderRadius} boxShadow={'lg'}>
        <Heading fontSize={'xl'}>BESTSELLER RØMERHUS - 2.7 km</Heading>
        

        <Flex>Skt. Clemens Torv 8, 8000 Aarhus</Flex>
        <Flex>Open - Closes 19.00</Flex>
        <Stack width={'80%'}>
          <Flex fontWeight={'bold'}>Opening hours</Flex>
          <Flex justifyContent={'space-between'}>
            <Text>Monday</Text>
            <Text>10:00 - 19:00</Text>
          </Flex>

          <Flex justifyContent={'space-between'}>
            <Text>Tuesday</Text>
            <Text>10:00 - 19:00</Text>
          </Flex>
          <Flex justifyContent={'space-between'}>
            <Text>Wednesday</Text>
            <Text>10:00 - 19:00</Text>
          </Flex>
        </Stack>
      </Flex> */}
    </Flex>
  )
}
