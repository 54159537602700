import { Attribute, AttributeLocalizableTextType, ProductProjection } from '@commercetools/platform-sdk'
import { t } from 'i18next'
import { ampli } from '../../../ampli'
import { getProductsProjectionSearchGlobal } from '../../../commercetools/requests'
import { useProductSearch } from '../../../context/ProductSearchContext'
import { PlentyProduct, PlentyVariant } from '../../../pages/main_app/PLP/types'
import { mainColorsMapped } from '../PLP/utils'
import { SearchCategory } from './types'

export const useProductSearchUtilities = () => {
  const {
    offset,
    setOffset,
    searchTerm,
    setSearchResults,
    searchResults,
    totalResults,
    setTotalResults,
    setTopCategories,
    setSelectedGender,
    selectedGender,
    genderizedCategoryId,
    languageSelected,
    setGenderizedCategoryId,
    allStoreChannels,
    allCategories,
    setIsLoadingProducts,
    priceSort,
    interestedInProductsWomen,
    interestedInProductsMen,
    interestedInProductsKids,
  } = useProductSearch()

  const getSelectedGenderCategoryId = (): string => {
    return allCategories.find((category) => category.categoryKey === selectedGender)?.categoryId ?? ''
  }

  const getCategoriesByGender = () => {
    const selectedGenderCategoryId = getSelectedGenderCategoryId()

    return allCategories.filter((category) => category.genderCategoryId === selectedGenderCategoryId)
  }

  // Uses regex to determine if the search is for a style number. Style numbers are 8 digits long.
  const searchIsStyleNumber = (searchText: string): boolean => {
    return RegExp('^[0-9]{8}$').test(searchText)
  }

  const getInterestedInSearchTerm = (): string => {
    const month = new Date(Date.now()).getMonth()
    let fixedSearchTerm = ''

    switch (month) {
      case 0:
      case 1:
      case 2:
      case 3:
        fixedSearchTerm = t('search.text_season_search_term.0')
        break
      case 4:
      case 5:
        fixedSearchTerm = t('search.text_season_search_term.1')
        break
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        fixedSearchTerm = t('search.text_season_search_term.2')
        break
      default:
        fixedSearchTerm = t('search.text_season_search_term.3')
        break
    }

    return fixedSearchTerm
  }

  const levenshteinDistance = (a: string, b: string): number => {
    const matrix = Array.from({ length: a.length + 1 }, (_, i) => [i, ...Array(b.length).fill(0)])
    for (let i = 1; i <= b.length; i++) matrix[0][i] = i
    for (let i = 1; i <= a.length; i++)
      for (let j = 1; j <= b.length; j++)
        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1), matrix[i - 1][j] + 1, matrix[i][j - 1] + 1)
    return matrix[a.length][b.length]
  }

  const fuzzyMatch = (match: string, against: string, fuzzyScaling: number): boolean => {
    const lowerMatch = match.toLowerCase()
    const lowerAgainstList = against.toLowerCase().split(/\s+/)
    return lowerAgainstList.some(
      (lowerAgainst) => levenshteinDistance(lowerMatch, lowerAgainst) <= Math.max(lowerMatch.length, lowerAgainst.length) * fuzzyScaling,
    )
  }

  const findSearchedCategory = (): SearchCategory | undefined => {
    const categoriesByGender = getCategoriesByGender()

    const fuzzySearchedCategory = categoriesByGender.filter((category: SearchCategory) => {
      if (fuzzyMatch(category.categoryName, searchTerm, 0.3)) {
        if (selectedGender === 'kids' && genderizedCategoryId != '') {
          return category.parentCategoryId === genderizedCategoryId
        }
        return category
      }
    })

    if (fuzzySearchedCategory.length === 1) {
      return fuzzySearchedCategory[0]
    }

    return fuzzySearchedCategory.find((category: SearchCategory) => {
      if (fuzzyMatch(category.categoryName, searchTerm, 0.1)) {
        return category
      }
    })
  }

  const findColor = (): string[] => {
    const colors: string[] = mainColorsMapped.flatMap((color) => color.name.toLowerCase())
    const foundColor = colors.find((color) => {
      if (fuzzyMatch(color, searchTerm, 0.3)) {
        return color
      }
    })

    if (foundColor) {
      const formattedColor = foundColor.charAt(0).toUpperCase() + foundColor.slice(1).toLowerCase()
      return [formattedColor]
    }
    return []
  }

  const getTopCategories = (): SearchCategory[] => {
    const searchedCategory = findSearchedCategory()
    const allCategoriesByGender = getCategoriesByGender()

    const foundCategory = allCategoriesByGender.find((category) => category.categoryId == searchedCategory?.categoryId)
    const subCategories = allCategoriesByGender.filter((category) => category.parentCategoryId === foundCategory?.categoryId)

    if (subCategories.length > 0) {
      return subCategories
    }

    const formattedTopLevelCategories = allCategoriesByGender
      .filter((category) => category.parentCategoryId == category.genderCategoryId)
      .map((category) => {
        if (selectedGender == 'kids') {
          const parts = category.categoryName.split(' ')
          if (parts.length === 3 && !isNaN(parseFloat(parts[1])) && !isNaN(parseFloat(parts[2]))) {
            category.categoryName = `${parts[1]}-${parts[2]} ${parts[0]}`
          }
        }
        return category
      })
      .sort((a, b) => {
        return a.categoryName.localeCompare(b.categoryName)
      })

    return formattedTopLevelCategories
  }

  const formatPlentyProduct = (results: ProductProjection[]): PlentyProduct[] => {
    const colors = findColor()

    return results
      .map((product: any) => {
        let desiredVariant

        if (colors.length == 0) {
          desiredVariant = product.variants.find((variant: any) => isVariantAvailable(variant))
        } else {
          desiredVariant = product.variants.find(
            (variant: any) =>
              isVariantAvailable(variant) && variant.attributes.find((attr: Attribute) => attr.name === 'main_color' && colors.includes(attr.value)),
          )
        }

        if (!desiredVariant) {
          desiredVariant = isVariantAvailable(product.masterVariant) ? product.masterVariant : null
        }

        return desiredVariant
          ? {
              id: product.id,
              key: product.key,
              name: product.name,
              masterVariant: desiredVariant,
              variants: product.variants,
            }
          : null
      })
      .filter((product) => product !== null) as PlentyProduct[]
  }

  const isVariantAvailable = (variant: PlentyVariant): boolean => {
    const availability = variant.availability
    const isChannelStockAvailable = availability?.channels ? Object.values(availability.channels).some((channel) => channel.isOnStock) : false
    return isChannelStockAvailable
  }

  const fetchSearchedProducts = async () => {
    const fixedSearchTerm = searchTerm != '' && searchTerm.length > 2 ? searchTerm : getInterestedInSearchTerm()
    const selectedGenderCategoryId = getSelectedGenderCategoryId()
    const searchedCategory = findSearchedCategory()
    const color = findColor()

    setIsLoadingProducts(true)
    getProductsProjectionSearchGlobal(
      fixedSearchTerm,
      offset,
      searchedCategory?.categoryId,
      genderizedCategoryId,
      selectedGenderCategoryId,
      color,
      languageSelected,
      allStoreChannels,
      priceSort,
    )
      .then((response) => {
        response = response!
        setTopCategories(getTopCategories())
        const isStyleNumber = searchIsStyleNumber(searchTerm)
        const variants = formatPlentyProduct(response.results)
        const totalResults = response.total ? response.total : 0

        if (isStyleNumber) {
          const variantByStyleNumber = variants.filter((a) => a.key === searchTerm)
          setSearchResults(variantByStyleNumber)
          setTotalResults(variantByStyleNumber.length)
        } else if (searchTerm != '') {
          const deDuplicatedProducts = deduplicateResults([...searchResults, ...variants])
          offset > 0 ? setSearchResults(deDuplicatedProducts) : setSearchResults(variants)
          setTotalResults(totalResults)
        }

        if (searchTerm != '') {
          ampli.searchedFor({
            searchTerm: searchTerm,
            totalResults: totalResults,
            offset: offset,
            selectedGender: selectedGender,
            searchedCategory: searchedCategory?.categoryName ?? 'no searched category',
            color: color,
          })
        }
      })
      .finally(() => {
        setIsLoadingProducts(false)
      })
  }

  const deduplicateResults = (combinedResults: PlentyProduct[]) => {
    return combinedResults.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
  }

  const handleGenderClick = (gender: string) => {
    setSelectedGender(gender)
  }

  const getInterestedInProductsByGender = (): PlentyProduct[] => {
    switch (selectedGender) {
      case 'women': {
        return interestedInProductsWomen
      }
      case 'men': {
        return interestedInProductsMen
      }
      case 'kids': {
        return interestedInProductsKids
      }
      default: {
        break
      }
    }
    return []
  }

  const handleShowMore = () => {
    if (totalResults && offset <= totalResults) {
      setIsLoadingProducts(true)
      setOffset(offset + 30)
    }
  }

  const clearSearchStates = () => {
    setGenderizedCategoryId('')
    setOffset(0)
    setSearchResults([])
    setTopCategories([])
    setTotalResults(undefined)
  }

  return {
    handleShowMore,
    handleGenderClick,
    fetchSearchedProducts,
    clearSearchStates,
    getInterestedInSearchTerm,
    getSelectedGenderCategoryId,
    formatPlentyProduct,
    getInterestedInProductsByGender,
  }
}
