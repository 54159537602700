import { t } from 'i18next'
import { PlentySlider } from '../../../../components/layout/PlentySlider/PlentySlider'
import { PopularBrandCard } from './PopularBrandCard'

const headingMap = {
  women: 'main_app.popular_brand_slider.heading_brand_slider.0',
  men: 'main_app.popular_brand_slider.heading_brand_slider.1',
  kids: 'main_app.popular_brand_slider.heading_brand_slider.2',
}

type Gender = keyof typeof headingMap

const PopularBrandsSlider = ({ gender: rawGender }: { gender: any }) => {
  const gender = (Object.prototype.hasOwnProperty.call(headingMap, rawGender || '') ? rawGender : 'women') as Gender

  const headingKey = headingMap[gender] 

  return (
    <PlentySlider fontSize='3xl' heading={t(headingKey)}>
      <PopularBrandCard />
    </PlentySlider>
  )
}

export default PopularBrandsSlider
