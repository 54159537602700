import { Flex, HStack, StackDivider, Text } from '@chakra-ui/react'
import { useDiscountCreation } from '../../../context/DiscountCreationContext'
import { TableEntry } from '../../../types/TableEntry'
import { Step } from './Step'
import { StepCircle } from './StepCircle'

type StepIndicatorProps = {
  wizard: any
}

export const BulkStepIndicator = (props: StepIndicatorProps) => {
  const bulkDiscountCreation = useDiscountCreation()

  return (
    <HStack spacing={10} divider={<StackDivider borderColor={'beigeLight.500'} />}>
      {props.wizard.steps.map((value: Step, index: number) => {
        const checkIfIsCompleted = () => {
          const campaignValidation = bulkDiscountCreation.campaignName != ''
          const fileValidation = bulkDiscountCreation.fileUpload
          const uploadedValidation = bulkDiscountCreation.uploadComplete
          const confirmValidation = bulkDiscountCreation.uploadConfirmed

          switch (value.key) {
            case 'campaign':
              return campaignValidation
            case 'upload':
              return campaignValidation && fileValidation
            case 'uploading':
              return campaignValidation && fileValidation && uploadedValidation
            case 'confirm upload':
              return campaignValidation && fileValidation && uploadedValidation && confirmValidation
            default:
              return false
          }
        }
        
        const isActive = props.wizard.activeStep.key === value.key
        const isClickable = checkIfIsCompleted()

        return (
          <Flex
            key={value.key}
            p={1}
            onClick={() => {
              if (isClickable) {
                const indexToSet = props.wizard.steps.findIndex((step: Step) => step.key == value.key)
                props.wizard.setActiveStepIndex(indexToSet)
              }
            }}
            cursor={isClickable ? 'pointer' : 'default'}
            borderRadius={10}
            _hover={{ bgColor: isClickable ? 'beigeLight.300' : 'transparent' }}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            <StepCircle number={index + 1} isActive={isActive} isCompleted={isClickable} />
            <Text fontSize={'md'} fontWeight={'normal'} color={isActive ? 'darkGreen.500' : 'gray.500'}>
              {value.label}
            </Text>
          </Flex>
        )
      })}
    </HStack>
  )
}
