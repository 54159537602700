import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { renderToString } from 'react-dom/server'
import { Box, Flex, SimpleGrid, useMediaQuery } from '@chakra-ui/react'
import { IconMapPinFilled } from '@tabler/icons-react'
import { StorePopupCard } from './StorePopupCard'
import './LeaftletMap.css'
import { StoreVerticalSelector } from './StoreVerticalSelector'
import { t } from 'i18next'
import { findStoreBanner } from '../../utils/GoogleCloudBucketUtil'
import { getAllStoresWithExpand } from '../../commercetools/requests'
import { Store } from '@commercetools/platform-sdk'
import { useScrollbarWidth } from '../../hooks/useScrollBarWidth'

export const LeafLetMap = ({ closeSheet }: { closeSheet?: () => void }) => {
  const [clickedMarker, setClickedMarker] = React.useState<string | null>(null)
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)')
  const mapRef = React.useRef<L.Map | null>(null)
  const [stores, setStores] = useState<Store[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllStoresWithExpand()
      if (result?.results) {
        setStores(result.results)
      }
    }

    fetchData()
  }, [])
  const extractGeoLocation = (distributionChannels: any[]): { latitude: number; longitude: number } | null => {
    if (!distributionChannels || distributionChannels.length === 0) return null

    const firstChannel = distributionChannels[0]
    const geoLocation = firstChannel.obj?.geoLocation

    if (!geoLocation || geoLocation.type !== 'Point' || geoLocation.coordinates.length !== 2) return null

    return {
      latitude: geoLocation.coordinates[1],
      longitude: geoLocation.coordinates[0],
    }
  }
  const formatAddress = (distributionChannels: any[]) => {
    if (!distributionChannels || distributionChannels.length === 0) return 'Address not available'

    const firstChannel = distributionChannels[0]
    const address = firstChannel.obj?.address

    if (!address) return 'Address not available'

    const { streetName, streetNumber, city } = address
    let formattedAddress = ''

    if (streetName) formattedAddress += streetName
    if (streetNumber) formattedAddress += formattedAddress ? ' ' + streetNumber : streetNumber
    if (city) formattedAddress += formattedAddress ? ', ' + city : city

    return formattedAddress || 'Address not available'
  }
  const scrollbarWidth = useScrollbarWidth()

  const getCustomMarkerIcon = (isSelected: boolean) => {
    const iconHtml = renderToString(
      <div className={isSelected ? 'marker-bouncing' : ''}>
        <IconMapPinFilled
          size={isSelected ? '50' : '36'}
          style={{
            color: isSelected ? '#41AB6B' : '#000',
          }}
        />
      </div>,
    )

    return L.divIcon({
      html: iconHtml,
      iconSize: isSelected ? [50, 50] : [36, 36],
      className: 'custom-icon',
    })
  }

  const [selectedStoreName, setSelectedStoreName] = React.useState<string | null>(null)
  const [selectedStoreData, setSelectedStoreData] = React.useState<any | null>(null) // Track the currently selected store data.
  const zoomToMarker = (latitude: number, longitude: number, zoomLevel = 16) => {
    const map = mapRef.current
    if (!map) {
      return
    }

    const latlng = new L.LatLng(latitude, longitude)
    map.flyTo(latlng, zoomLevel, {
      animate: true,
      duration: 1.5,
    })
  }

  const MapInstance = ({ setMapRef }: any) => {
    const map = useMap()

    React.useEffect(() => {
      setMapRef(map)
    }, [map, setMapRef])

    return null
  }

  useEffect(() => {
    if (mapRef.current) {
      if (isLargerThan1024) {
        mapRef.current.dragging.enable()
      } else {
        mapRef.current.dragging.disable()
      }
    }
  }, [isLargerThan1024])
  const handleMarkerClick = (store: any) => {
    const location = extractGeoLocation(store.distributionChannels)
    if (!location) return

    // If the same marker is clicked again, reset the clicked marker state.
    if (clickedMarker === store.name?.en) {
      setClickedMarker(null)
      setSelectedStoreName(null)
      setSelectedStoreData(null)
      const map = mapRef.current
      if (map) {
        map.flyTo([56.16, 10.2], 13, {
          animate: true,
          duration: 1.5,
        })
      }
    } else {
      setClickedMarker(store.name?.en)
      setSelectedStoreName(store.name?.en)
      setSelectedStoreData(store)
      zoomToMarker(location.latitude, location.longitude)
    }
  }

  const sortedStores = stores.slice().sort((a, b) => (a.name?.en || '').localeCompare(b.name?.en || ''))

  return (
    <>
      <Flex height={'60vh'} width={`calc(100vw - ${scrollbarWidth}px)`} padding={'1rem'} gap={'2rem'} overflow={'hidden'}>
        {isLargerThan1024 && (
          <SimpleGrid
            paddingX={'1.5rem'}
            backgroundColor={'white'}
            columns={2}
            spacing={4}
            height='100%'
            overflow='auto'
            width={isLargerThan1300 ? '30%' : '35%'}
          >
            <Box fontSize={'2xl'} gridColumn='span 2' textAlign='start'>
              {t('components.leaflet_map.all_stores_in_aarhus')}
            </Box>
            {sortedStores?.map((store, index) => (
              <StoreVerticalSelector
                key={store.id}
                size={'auto'}
                storeName={store.name?.en}
                storeAddress={formatAddress(store.distributionChannels)}
                storeIndex={index}
                imageActive={selectedStoreName === store.name?.en ? 'brightness(60%)' : 'brightness(100%)'}
                onStoreClick={() => handleMarkerClick(store)}
                storeDisplayNameUrl={store.name?.en}
              />
            ))}
          </SimpleGrid>
        )}
        <Box width={isLargerThan1300 ? '70%' : isLargerThan1024 ? '65%' : '100%'} position='relative'>
          <MapContainer
            center={[56.16, 10.2]}
            zoom={13}
            style={{ width: '100%', height: '100%', borderRadius: '1.5rem' }}
            attributionControl={false}
            scrollWheelZoom={false}
            dragging={isLargerThan1024}
          >
            <MapInstance
              setMapRef={(map: L.Map | null) => {
                mapRef.current = map
              }}
            />{' '}
            <TileLayer
              url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
              attribution='&copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri Korea, Esri (Thailand), NGCC, (c) OpenStreetMap contributors, and the GIS User Community'
            />
            {stores.map((store) => {
              const location = extractGeoLocation(store.distributionChannels)
              if (!location) return null

              const isSelected = store.name?.en === clickedMarker

              return (
                <Marker
                  key={store.id}
                  position={[location.latitude, location.longitude]}
                  icon={getCustomMarkerIcon(isSelected)}
                  eventHandlers={{
                    click: () => handleMarkerClick(store),
                  }}
                />
              )
            })}
          </MapContainer>
          {selectedStoreData && (
            <Box
              borderRadius={'24px'}
              position='absolute'
              bottom={2}
              left={2}
              right={2}
              backgroundColor='white'
              maxWidth={'400px'}
              maxHeight={'200px'}
              zIndex={400}
            >
              <StorePopupCard
                closeSheet={closeSheet}
                key={selectedStoreData.id}
                size={'auto'}
                storeName={selectedStoreData.name?.en}
                storeAddress={formatAddress(selectedStoreData.distributionChannels)}
                to={`/stores/${selectedStoreData.key}`}
                storeImageUrl={findStoreBanner(selectedStoreData.name?.en)}
              />
            </Box>
          )}
        </Box>
      </Flex>
    </>
  )
}
