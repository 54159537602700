import { useEffect } from 'react'
import { MainPageDesktop } from '../../../../components/layout/main/desktop/MainPageDesktop'
import { MainPageResponsive } from '../../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { useBreadcrumbs } from '../../../../context/BreadcrumbsContext'
import { PrivacyTermsInformationText } from '../components/PrivacyTermsInformationText'

export const PrivacyTermsDesktop = () => {
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  return (
    <MainPageResponsive loading={false}>
      <PrivacyTermsInformationText />
    </MainPageResponsive>
  )
}
