import { Flex, Icon, Text } from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'

type StepCircleProps = {
  number: number
  isActive: boolean
  isCompleted: boolean
}

export const StepCircle = (props: StepCircleProps) => {
  return (
    <Flex
      borderRadius={'50%'}
      bgColor={props.isActive ? 'mintGreen.500' : 'darkGreen.500'}
      width={30}
      height={30}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {props.isCompleted ? (
        <Icon color={props.isActive ? 'darkGreen.500' : 'white.500'} as={IconCheck} />
      ) : (
        <Text color={props.isActive ? 'darkGreen.500' : 'white.500'} fontSize={'sm'} fontWeight={'normal'}>
          {props.number}
        </Text>
      )}
    </Flex>
  )
}
