import { Card, CardBody, CardHeader, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { IconType } from 'react-icons/lib'

type StatCardProps = {
  title: string
  titleSize?: string
  subTitle?: string
  subTitleSize?: string
  colorScheme: any
  icon?: IconType
  loading?: boolean
  action?: () => any
  leftHeaderElement?: ReactElement

  value: number | string
  textPlacement?: 'Left' | 'Right'
  additionalValueElement?: ReactElement
}

export const StatCard = (props: StatCardProps) => {
  const textPlacement = props.textPlacement ? props.textPlacement : 'Right'

  return (
    <Card
      cursor={props.action ? 'pointer' : 'default'}
      onClick={() => (props.action ? props.action() : null)}
      bgColor={props.colorScheme.bg}
      position={'relative'}
    >
      <CardHeader>
        <Flex justifyContent={'space-between'}>
          <Flex>
            <Text color={props.colorScheme.fragranceColor} fontSize={props.titleSize}>
              {props.title}
            </Text>
            {props.subTitle ? <Text fontSize={props.subTitleSize}>{props.subTitle}</Text> : null}
          </Flex>
          {props.leftHeaderElement}
        </Flex>
      </CardHeader>
      <CardBody pt={0}>
        <Flex flex={1} alignItems={'center'} justifyContent={'space-between'}>
          {props.additionalValueElement && textPlacement == 'Right' ? props.additionalValueElement : null}
          <Flex flex={1} justifyContent={props.textPlacement == 'Right' ? 'flex-end' : 'flex-start'}>
            <Heading my={2} color={props.colorScheme.contentColor} size={'3xl'}>
              {props.value}
            </Heading>
          </Flex>
          {props.additionalValueElement && textPlacement == 'Left' ? props.additionalValueElement : null}
        </Flex>
      </CardBody>
      {props.icon ? (
        <Icon
          color={'white.500'}
          opacity={'13%'}
          bottom={-5}
          left={0}
          w={'125px'}
          h={'125px'}
          transform={'rotate(-25deg)'}
          position={'absolute'}
          as={props.icon}
        />
      ) : null}
    </Card>
  )
}
