import { Button, Flex, Heading, useMediaQuery, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { TbMap2 } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { ClusterOfPillsForTopOfSite } from '../../../components/BackgroundPills/ClusterOfPillsForTopOfSite'
import { HorizontalBeigeLightPill } from '../../../components/BackgroundPills/HorizontalBeigeLightPill'
import { VerticalBackgroundPill } from '../../../components/BackgroundPills/VerticalGreenPill'
import Sheet from 'react-modal-sheet'

import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { LeafLetMap } from '../../../components/LeafletMap/LeafletMap'
import { NewStoreSlider } from '../../../components/newStoreSlider/NewStoreSlider'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { useScrollbarWidth } from '../../../hooks/useScrollBarWidth'
import { Theme } from '../../../ThemeVariables'
import { WhyShopHereBox } from '../landing_page/components/WhyShopHereBox'

import CategoryCard from './components/CategoryCard'
import { GenderSaleSlider } from './components/GenderSaleSlider'
import PopularBrandsSlider from './components/PopularBrandsSlider'
import { SeasonalSaleCard } from './components/SeasonalSaleCard'
import withAuth from '../../../utils/WithAuth'
import { MainRole } from '../../../generated/graphql'
const GenderHomePage = () => {
  const { gender } = useParams<{ gender: string }>()
  const { gender: genderFromUrl } = useParams<{ gender: string }>()
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [isMapSheetOpen, setIsMapSheetOpen] = useState(false)
  const { clearBreadcrumbs } = useBreadcrumbs()
  const scrollbarWidth = useScrollbarWidth()
  const [isLocationPillSheetOpen, setIsLocationPillSheetOpen] = useState(false)

  const validatedGender = gender && ['kids', 'women', 'men'].includes(gender) ? gender : undefined
  useEffect(() => {
    clearBreadcrumbs()
  }, [])

  const genderMapping: Record<string, string> = {
    men: 'Male',
    women: 'Female',
    kids: 'Female',
  }
  const navigate = useNavigate()
  const dynamicGender = genderMapping[genderFromUrl ?? ''] || 'Male'
  return (
    <MainPageResponsive loading={false} mx={'auto'}>
      <>
        <Flex zIndex={1} width={'100%'} alignItems={'center'} direction={'column'}>
          <ClusterOfPillsForTopOfSite />

          <Flex
            gap={{ base: '1rem', md: '2rem' }}
            alignItems={'flex-start'}
            alignSelf={'flex-start'}
            overflow={'auto'}
            direction={'column'}
            maxWidth={`calc(100vw - ${scrollbarWidth}px)`}
            px={Theme.PaddingOnSides.LandingPages}
            backgroundColor={'white'}
            textAlign={'start'}
            pt={{ base: '2rem', md: '7.125rem' }}
            whiteSpace={{ base: 'pre-wrap', md: 'normal' }}
          >
            <CategoryCard />
          </Flex>
          <Flex maxWidth={`calc(100vw - ${scrollbarWidth}px)`} px={Theme.PaddingOnSides.LandingPages} alignItems={'flex-start'} alignSelf={'flex-start'}>
            {gender && <SeasonalSaleCard gender={gender} />}
          </Flex>
          <Flex
            pt={{ base: '2rem', md: '3rem' }}
            pb={{ base: '4.5rem', md: '6.5rem' }}
            direction={'column'}
            maxWidth={`calc(100vw - ${scrollbarWidth}px)`}
            px={Theme.PaddingOnSides.LandingPages}
            gap={{ base: '1rem', md: '3rem' }}
            position='relative' // Set the Flex container to have a relative position
          >
            <VerticalBackgroundPill
              position='absolute' // Set absolute positioning
              top={'-70%'}
              left={0} // Adjust as necessary, for example, left: 0 will align it to the left
              right={0} // Adjust as necessary, you can also specify right to stretch it
              // Add other styling as needed
            />

            <Flex position={'relative'} direction={'column'} gap={{ base: '1rem', md: '2.5rem' }}>
              <HorizontalBeigeLightPill top={'-40%'} />
              <GenderSaleSlider
                title={t('main_app.gender_sale.heading_gender_sale')}
                gender={gender !== 'kids' ? dynamicGender : undefined}
                targetGroup={gender === 'kids' ? 'Kids' : 'Adult'}
              />
            </Flex>
            <Flex justifyContent={'center'}>
              <Button
                variant={'solid'}
                justifyContent={'center'}
                gap={4}
                onClick={() => {
                  navigate(`/products/${gender}`)
                }}
              >
                {t('components.button.see_discounted_products')}
              </Button>
            </Flex>
          </Flex>
          <Flex backgroundColor={'beigeLight.500'} width={'100%'}>
            <Flex
              direction={'column'}
              gap={{ base: '1rem', md: '3rem' }}
              py={{ base: '2.5rem', md: '3.5rem' }}
              overflowX={'scroll'}
              px={Theme.PaddingOnSides.LandingPages}
              textAlign={'center'}
              alignItems={'center'}
              css={{ '&::-webkit-scrollbar': { display: 'none' } }}
              width={'100%'}
            >
              <Flex gap={'1rem'} direction={'column'} alignItems={'flex-start'} alignSelf={'flex-start'}>
                <Flex gap={{ base: '0.25rem', sm: '0.25rem', md: '1rem' }} textAlign={'start'} direction={'column'} alignSelf={'start'}>
                  <Heading variant={'headingForComponentOnAMainPage'}> {t('main_app.homepage.heading_store_slider')}</Heading>
                  <Text variant={'textForComponentOnAMainPageWhoHasAHeading'}>{t('main_app.homepage.text_store_slider')}</Text>
                </Flex>

                <Flex maxWidth={'95vw'} direction={'column'}>
                  <NewStoreSlider gender={validatedGender as 'kids' | 'women' | 'men' | undefined} />
                </Flex>
              </Flex>
              <Flex justifyContent={'center'}>
                <Button
                  variant={'solid'}
                  justifyContent={'center'}
                  gap={4}
                  onClick={() => {
                    if (isLargerThan768) {
                      navigate('/store-map')
                    } else {
                      setIsMapSheetOpen(true)
                    }
                  }}
                >
                  {t('components.button.show_stores_on_map')}
                  <TbMap2 />
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Sheet isOpen={isMapSheetOpen} onClose={() => setIsMapSheetOpen(false)} snapPoints={[1, 0]} initialSnap={0}>
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content
                style={{
                  height: '90vh',
                  overflowY: 'auto',
                  overscrollBehavior: 'contain',
                  WebkitOverflowScrolling: 'touch',
                }}
              >
                <Flex direction={'column'} gap={2}>
                  <Flex direction={'column'} alignItems={'center'}>
                    <Text color={'#C1C1C1'} fontSize={'16px'}>
                      {t('components.leaflet_map.aarhus')}
                    </Text>
                    <Heading fontSize={Theme.heading.headerMobile30px}>{t('components.leaflet_map.stores')}</Heading>
                  </Flex>
                  <LeafLetMap />
                  <Button
                    onClick={() => {
                      setIsMapSheetOpen(false)
                      setIsLocationPillSheetOpen(true)
                    }}
                  >
                    {t('components.button.show_stores_on_map')}
                  </Button>
                </Flex>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>

          <Flex
            maxWidth={`calc(100vw - ${scrollbarWidth}px)`}
            px={Theme.PaddingOnSides.LandingPages}
            direction={'column'}
            alignSelf={'flex-start'}
            overflow={'hidden'}
            py={{ base: '4.5rem', md: '6.5rem' }}
            gap={'2.5rem'}
          >
            <PopularBrandsSlider gender={gender} />
          </Flex>
          <Flex width={'100%'}>
            <WhyShopHereBox />
          </Flex>
        </Flex>
      </>
    </MainPageResponsive>
  )
}
export default withAuth(GenderHomePage, [MainRole.RoleGuest])
