import { Flex } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { ContentHeader } from '../../../../pages/partner_portal/ContentHeader'
import { Header } from './Header'
import { SideNav } from './SideNav'

type PartnerPortalPageProps = {
  children: any
  pageHeaderLabel?: string
  rightHeaderContent?: ReactElement
  back?: any
}

export const PartnerPortalPage = (props: PartnerPortalPageProps) => {
  const RenderHeader = () => {
    return (
      <Flex justifyContent={'space-between'} alignItems={'top'} mb={10}>
        <ContentHeader back={props.back} label={props.pageHeaderLabel || ''} />
        { props.rightHeaderContent ? props.rightHeaderContent : <></> }
      </Flex>
    )
  }

  return (
    <Flex flex={1}>
      <SideNav />
      <Flex overflowY={'auto'} direction={'column'} w={'full'} h={'100vh'}>
        <Header />
        <Flex flex={1} direction={'column'} p={10}>
          {props.pageHeaderLabel ? <RenderHeader /> : null}
          {props.children}
        </Flex>
      </Flex>
    </Flex>
  )
}
