import { Button, Flex, HStack, Icon, ModalFooter, Text, Tooltip } from '@chakra-ui/react'
import { IconDiscount2, IconPercentage, IconQuestionCircle } from '@tabler/icons-react'
import { useState } from 'react'
import { CloseTag } from '../../../../components/PlentyUI/components/CloseTag'
import { RadioCard } from '../../../../components/PlentyUI/components/RadioCard'
import { useDiscountCreation } from '../../../../context/DiscountCreationContext'
import { BundleDiscountTool } from '../../../../types/BundleDiscountTool'
import { PercentageDiscountTool } from '../../../../types/PercentageDiscountTool'
import { DiscountInput } from './DiscountInput'
import { SummaryBar } from './SummaryBar'

type CreateDiscountDiscountStepProps = {
  wizard: any
}

export const CreateDiscountDiscountStep = (props: CreateDiscountDiscountStepProps) => {
  const discountCreation = useDiscountCreation()

  const [discountCardChecked, setDiscountCardChecked] = useState<'percentage' | 'bundle' | undefined>('percentage')

  return (
    <Flex direction={'column'} position={'relative'}>
      <SummaryBar
        info={[
          discountCreation.campaignName,
          discountCreation.validFrom.toDateString() + ' to ' + (discountCreation.validTo ? discountCreation.validTo.toDateString() : 'continuous'),
        ]}
      />
      <HStack p={6} spacing={10} width={'100%'} alignItems={'flex-start'}>
        <Flex flex={1} direction={'column'} gap={12}>
          <Flex direction={'column'} gap={6}>
            <Flex direction={'column'}>
              <Flex alignItems={'center'} gap={2}>
                <Text fontSize={'2xl'} fontWeight={'bold'}>
                  Discount type
                </Text>
                <Tooltip
                  label={
                    'Select a Percentage discount or X for Y discount. There are two variations of Buy X get Y discounts. When customers buy a specified number (X) of items, they receive a certain number (Y) of items for free or at a reduced price.'
                  }
                >
                  <Icon as={IconQuestionCircle} w={6} h={6} color={'gray.500'} />
                </Tooltip>
              </Flex>
              <Text>You can select multiple discount types at the same time.</Text>
            </Flex>

            <Flex gap={4}>
              <RadioCard
                onClick={() => setDiscountCardChecked('percentage')}
                icon={IconPercentage}
                radioLabel={'Percentage'}
                cardText={'Create a percentage discount'}
                isChecked={discountCardChecked == 'percentage'}
                footer={discountCardChecked == 'percentage' ? <DiscountInput discountCardChecked={discountCardChecked} /> : <></>}
              />
              <RadioCard
                onClick={() => setDiscountCardChecked('bundle')}
                icon={IconDiscount2}
                radioLabel={'Bundle'}
                cardText={'Create a buy X get X discount'}
                isChecked={discountCardChecked == 'bundle'}
                footer={discountCardChecked == 'bundle' ? <DiscountInput discountCardChecked={discountCardChecked} /> : <></>}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} justifyContent={'flex-end'}>
          <Flex gap={2} direction={'column'} p={6} mr={-6} borderTopLeftRadius={24} borderBottomLeftRadius={24} bgColor={'beigeLight.500'}>
            {/* <Heading hidden={discountCreation.campaignName === ''} alignSelf={'flex-start'} size={'md'}>
              {discountCreation.campaignName}
            </Heading> */}
            <Text>Selected discounts:</Text>
            {discountCreation.discountTools.map((value: PercentageDiscountTool | BundleDiscountTool, index: number) => (
              <CloseTag key={index} closeAction={() => discountCreation.removeDiscountTool(index)} label={discountCreation.renderDiscountLabel(value)} />
            ))}
          </Flex>
        </Flex>
      </HStack>

      <ModalFooter borderTop={'1px'} borderTopColor={'beigeLight.500'}>
        <Button isDisabled={!props.wizard.canReduce} colorScheme='blue' mr={3} onClick={() => props.wizard.reduceStep()}>
          Previous
        </Button>
        <Button
          isDisabled={!props.wizard.canAdvance || discountCreation.discountTools.length === 0}
          colorScheme='blue'
          mr={3}
          onClick={() => props.wizard.advanceStep()}
        >
          Next
        </Button>
      </ModalFooter>
    </Flex>
  )
}
