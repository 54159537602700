import { Flex, Box, Text } from '@chakra-ui/react'
import { TbShoppingCart, TbClock, TbTruckDelivery, TbArrowBack } from 'react-icons/tb'
import { t } from 'i18next'
import { Theme } from '../../../../ThemeVariables'

export const WhyShopHereBox = () => {
  const icons = [
    {
      icon: TbShoppingCart,
      heading: t('main_app.landing_page.why_shop_here_box.heading_basket_icon'),
      text: t('main_app.landing_page.why_shop_here_box.text_basket_icon'),
    },
    {
      icon: TbClock,
      heading: t('main_app.landing_page.why_shop_here_box.heading_clock_icon'),
      text: t('main_app.landing_page.why_shop_here_box.text_clock_icon'),
    },
    {
      icon: TbTruckDelivery,
      heading: t('main_app.landing_page.why_shop_here_box.heading_delivery_icon'),
      text: t('main_app.landing_page.why_shop_here_box.text_delivery_icon'),
    },
    {
      icon: TbArrowBack,
      heading: t('main_app.landing_page.why_shop_here_box.heading_return_icon'),
      text: t('main_app.landing_page.why_shop_here_box.text_return_icon'),
    },
  ]
  return (
    <Flex
      backgroundColor={'black'}
      py={'2.5rem'}
      gap={{ base: '2rem', md: '0' }}
      px={Theme.PaddingOnSides.LandingPages}
      width={'100%'}
      direction={{ base: 'column', md: 'row' }}
      justifyContent={'space-between'}
    >
      {icons.map((item, index) => (
        <Flex key={index} direction='column' alignItems='center'>
          <Box as={item.icon} color='white' fontSize='2xl' />
          <Text color='white' fontSize='2xl' mt={'0.5rem'}>
            {item.heading}
          </Text>
          <Text color='white' fontSize='md'>
            {item.text}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}
