import { useState, useEffect } from 'react'
import { getProductsProjectionSearchForSliderGender } from '../../commercetools/requests'

import { formatPlentyProduct } from '../../pages/main_app/PLP/utils'
import { PlentyProduct } from '../../pages/main_app/PLP/types'
import { Store } from '@commercetools/platform-sdk'

import { useNavigate } from 'react-router-dom'
import { ProductCardSlider } from '../../pages/main_app/PDP/components/ProductCardSlider'
import { PlentySlider } from '../layout/PlentySlider/PlentySlider'
import { parseCategorySlugToURL } from '../../pages/main_app/PDP/utils'

export const GenderSlider = ({
  gender,
  targetGroup,
  title,
  store,
  category,
}: {
  gender: string
  targetGroup: string
  title: string
  store?: Store
  category: string | undefined
}) => {
  const [products, setProducts] = useState<PlentyProduct[] | undefined>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setIsLoadingProducts(true)
    getProductsProjectionSearchForSliderGender(gender, targetGroup, store?.distributionChannels[0].id)
      .then((productsProjection) => {
        const plentyProducts = formatPlentyProduct(productsProjection?.results)
        setProducts(plentyProducts)
      })
      .finally(() => {
        setIsLoadingProducts(false)
      })
  }, [])

  const navigateToProduct = (product?: PlentyProduct) => {
    let baseUrl = ''
    const queryParams = new URLSearchParams()

    if (product) {
      baseUrl = `/product/${product.key}`
      queryParams.append('variant', product.masterVariant.key)
    }

    if (store?.key) {
      queryParams.append('store', store.key)
    }

    navigate(`${baseUrl}?${queryParams.toString()}`)
  }
  const navigateToViewAll = () => {
    let baseUrl = ''
    const queryParams = new URLSearchParams()
    if (category) {
      baseUrl = `/products/${parseCategorySlugToURL(category.toLowerCase())}`
    }
    navigate(`${baseUrl}?${queryParams.toString()}`)
  }
  return (
    <PlentySlider
      fontSize='3xl'
      heading={title}
      viewAll
      title={title.toLowerCase()}
      store={store}
      navigateToProduct={navigateToProduct}
      navigateToViewAll={navigateToViewAll}
    >
      {' '}
      {products?.map((product) => (
        <ProductCardSlider hasDragged key={product.key} product={product} storeKey={store?.key} />
      ))}
    </PlentySlider>
  )
}
