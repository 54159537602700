/* eslint-disable @typescript-eslint/no-extra-semi */
import withAuth from '../../../../utils/WithAuth'
import { MainRole } from '../../../../generated/graphql'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import { OrderCard } from './components/OrderCard'
import { Flex, HStack, Stack } from '@chakra-ui/react'
import { FilterButton } from '../orders_page/components/FilterButton'
import { t } from 'i18next'
import { useState } from 'react'
import { Order } from '@commercetools/platform-sdk'
import { Auth } from 'aws-amplify'

const OrdersPage = () => {
  const [orders, setOrders] = useState<Order[]>(() => {
    ;(async () => {
      try {
        await Auth.currentAuthenticatedUser()

        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        const apiUrl = `${process.env.REACT_APP_ORDERS_API_URL}/orders?returns=false`
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`)
        }

        const data = (await response.json()) as Order[]
        setOrders(data)
      } catch (error) {
        console.error('Error loading orders:', error)
      }
    })()

    return []
  })
  const [orderFilterSelected, setOrderFilterSelected] = useState('All')
  return (
    <PartnerPortalPage pageHeaderLabel={'Orders'}>
      <Stack spacing={10}>
        <Flex justifyContent={'space-between'}>
          <HStack spacing={2}>
            <FilterButton
              amount={orders.length || 0}
              isToggled={orderFilterSelected === 'All'}
              label={t('partner_portal.merchant.orders.label_all')}
              action={() => setOrderFilterSelected('All')}
              size={'md'}
              variant={'Solid'}
            />
            <FilterButton
              amount={orders.filter((e: Order) => e?.state?.obj?.key == 'Open').length || 0}
              isToggled={orderFilterSelected === 'Open'}
              label={t('partner_portal.merchant.orders.label_new_orders')}
              action={() => setOrderFilterSelected('Open')}
              size={'md'}
              variant={'Solid'}
              underlineColor={'orange.500'}
            />
            <FilterButton
              amount={orders.filter((e: Order) => e.orderState == 'Confirmed').length || 0}
              isToggled={orderFilterSelected === 'Confirmed'}
              label={t('partner_portal.merchant.orders.label_accepted')}
              action={() => setOrderFilterSelected('Confirmed')}
              size={'md'}
              variant={'Solid'}
              underlineColor={'blue.500'}
            />
            <FilterButton
              amount={orders.filter((e: Order) => e.orderState == 'Cancelled').length || 0}
              isToggled={orderFilterSelected === 'Cancelled'}
              label={t('partner_portal.merchant.orders.label_declined')}
              action={() => setOrderFilterSelected('Cancelled')}
              size={'md'}
              variant={'Solid'}
              underlineColor={'red.500'}
            />
            <FilterButton
              amount={orders.filter((e: Order) => e.orderState == 'Complete').length || 0}
              isToggled={orderFilterSelected === 'Complete'}
              label={t('partner_portal.merchant.orders.label_completed')}
              action={() => setOrderFilterSelected('Complete')}
              size={'md'}
              variant={'Solid'}
              underlineColor={'green.500'}
            />
          </HStack>
        </Flex>
        <Flex direction={'column'}>
          <Stack spacing={10}>
            {orders &&
              orders.map((order) => {
                if (orderFilterSelected === order.orderState || orderFilterSelected === 'All') {
                  return (
                    <OrderCard
                      key={order?.id}
                      orderNumber={order?.orderNumber}
                      orderState={order?.orderState}
                      lineItems={order?.lineItems}
                      shippingKey={order?.shippingKey}
                      createdAt={order?.createdAt}
                      customerId={order?.customerId}
                    ></OrderCard>
                  )
                }
              })}
          </Stack>
        </Flex>
      </Stack>
    </PartnerPortalPage>
  )
}

export default withAuth(OrdersPage, [MainRole.RoleAdmin])
