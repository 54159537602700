import { useApolloClient } from '@apollo/client'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Icon,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
  useToast
} from '@chakra-ui/react'
import { ProductProjection, ProductVariant } from '@commercetools/platform-sdk'
import { IconChevronDown, IconChevronLeft, IconChevronRight, IconSearch } from '@tabler/icons-react'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { getProductsByChannelKey } from '../../../../commercetools/requests'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import { PageSpinner } from '../../../../components/PageSpinner'
import { Toast } from '../../../../components/PlentyUI/Toast'
import { useUser } from '../../../../context/Auth'
import DiscountCreationProvider from '../../../../context/DiscountCreationContext'
import { MainRole, useWhoAmIQuery } from '../../../../generated/graphql'
import { UPDATE_PRICE_OF_PRODUCT } from '../../../../gql/product'
import { centPriceToPrice } from '../../../../utils/MoneyUtil'
import { findVariantWithDiscountedPriceByChannelId, findVariantWithPriceByChannelId } from '../../../../utils/PDPUtil'
import withAuth from '../../../../utils/WithAuth'
import { CreateDiscountModal } from '../../CreateDiscountModal'
import { ProductsTable } from './components/ProductsTable'

const MerchantProductsPage = () => {
  const navigate = useNavigate()
  const client = useApolloClient()
  const user = useUser()
  const toast = useToast()
  const whoAmI = useWhoAmIQuery()

  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)
  const [productsLoading, setProductsLoading] = useState(true)
  const [products, setProducts] = useState<ProductProjection[]>([])
  const [totalProductCount, setTotalProductCount] = useState(0)
  const [productOffset, setProductOffset] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const data = useMemo(() => products, [products])
  const columns: any = useMemo(
    () => [
      {
        id: 'toggle',
        Header: ({ getToggleAllRowsSelectedProps }: any) => <Checkbox variant={'baseStyle'} {...getToggleAllRowsSelectedProps} />,
        Cell: ({ row }: any) => <Checkbox variant={'baseStyle'} {...row.getToggleRowSelectedProps} />,
      },
      {
        id: 'expander',
        Cell: ({ row }: any) => <Icon {...row.getToggleRowExpandedProps()} w={5} h={5} as={row.isExpanded ? IconChevronDown : IconChevronRight} />,
      },
      {
        id: 'image',
        Cell: ({ row }: any) => {
          return (
            <Img
              borderRadius={8}
              w={10}
              src={row.original.masterVariant.images[0]?.url ?? 'https://t3.ftcdn.net/jpg/05/03/24/40/360_F_503244059_fRjgerSXBfOYZqTpei4oqyEpQrhbpOML.jpg'}
            />
          )
        },
      },
      {
        Header: t('partner_portal.merchant.products.header_product_name'),
        accessor: 'name.en',
      },
      {
        Header: t('partner_portal.merchant.products.header_category'),

        accessor: 'categories[0].obj.name.en',
      },
      {
        Header: 'Price',
        Cell: ({ row }: any) => {
          const variantPrice = findVariantWithPriceByChannelId(
            row.original.variants.concat(row.original.masterVariant),
            whoAmI.data?.whoAmI?.commercetoolsDistributionChannelId ?? '',
          )

          if (!variantPrice) {
            return <Text as={'i'}>No price found</Text>
          }

          return (
            <Flex gap={2} alignItems={'center'}>
              <Editable
                defaultValue={centPriceToPrice(variantPrice.value.centAmount, variantPrice.value.fractionDigits).toString()}
                onSubmit={(value: any) =>
                  client
                    .mutate({
                      mutation: UPDATE_PRICE_OF_PRODUCT,
                      variables: {
                        channelKey: '771aac7f-a3ea-4e5e-ae33-09c0602b7823',
                        productKey: 'integrationtest',
                        newPrice: value,
                      },
                    })
                    .then(() => {
                      toast({
                        position: 'bottom',
                        render: () => <Toast type='success' label='The price was changed successfully' />,
                      })
                    })
                    .catch(() => {
                      toast({
                        position: 'bottom',
                        render: () => <Toast type='error' label='An error occured when trying to change the price' />,
                      })
                    })
                }
              >
                <EditablePreview />
                <EditableInput type={'number'} />
              </Editable>
              <Text>kr</Text>
            </Flex>
          )
        },
        accessor: 'masterVariant.prices[0].value.centAmount',
      },
      {
        id: 'inStock',
        Header: 'In-Stock',
        Cell: ({ row }: any) => {
          const variants = row.original.variants.concat(row.original.masterVariant)
          const variantsMappedToAvailabilityArray = variants.map((variant: ProductVariant) => {
            const { availability } = variant

            if (availability) {
              const channelId = whoAmI.data?.whoAmI?.commercetoolsDistributionChannelId ?? ''
              const channel = availability.channels?.[channelId]

              return channel !== undefined ? channel.availableQuantity : 0
            }

            return 0
          })

          const totalQuantity = variantsMappedToAvailabilityArray.reduce((acc: number, current: number) => acc + current, 0)

          return totalQuantity <= 0 ? <Text color={'red.600'}>Out of Stock</Text> : <Text>{totalQuantity}</Text>
        },
      },
      {
        Header: 'Discount',
        Cell: ({ row }: any) => {
          const variantPrice = findVariantWithDiscountedPriceByChannelId(
            row.original.variants.concat(row.original.masterVariant),
            whoAmI.data?.whoAmI?.commercetoolsDistributionChannelId ?? '',
          )

          if (variantPrice) {
            return <Text>Discounted</Text>
          }

          return <Text></Text>
        },
      },
      {
        Header: 'Style number',

        accessor: 'key',
      },
      {
        Header: t('partner_portal.merchant.products.header_actions'),

        Cell: ({ row }: any) => {
          return <IconButton onClick={() => navigate(row.original.id)} variant={'ghost'} aria-label='navigate' icon={<Icon as={IconChevronRight} />} />
        },
      },
    ],
    [whoAmI],
  )

  const table = useTable(
    { columns, data, initialState: { pageIndex: controlledPageIndex }, manualPagination: true, pageCount: Math.ceil(totalProductCount / itemsPerPage) },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  )

  const { pageIndex } = table.state

  const onPageChange = (newPageIndex: number) => {
    setControlledPageIndex(newPageIndex)
    fetchProducts(itemsPerPage, newPageIndex)
  }

  const fetchProducts = (itemsPerPage: number, index: number) => {
    getProductsByChannelKey(user.distributionChannelKey ?? '', whoAmI.data?.whoAmI?.commercetoolsDistributionChannelId ?? '', itemsPerPage, index, searchValue).then(
      (response) => {
        setProducts(response.results)
        setTotalProductCount(response.total ?? 0)
        setProductOffset(response.offset)
        setItemsPerPage(response.limit)
        setProductsLoading(false)
      },
    )
  }

  useEffect(() => {
    fetchProducts(10, 0)
  }, [whoAmI])

  return (
    <PartnerPortalPage pageHeaderLabel={'Products'}>
      <DiscountCreationProvider>
        <CreateDiscountModal isOpen={isDiscountModalOpen} setOpen={setIsDiscountModalOpen} />
      </DiscountCreationProvider>

      <Card>
        <CardHeader borderBottom={'1px solid'} borderColor={'blackAlpha.200'}>
          <Flex gap={6}>
            <InputGroup variant={'outlineWhite'}>
              <InputLeftElement>
                <Icon as={IconSearch} />
              </InputLeftElement>
              <Input
                value={searchValue}
                onChange={(e: any) => {
                  fetchProducts(itemsPerPage, 0)
                  setSearchValue(e.target.value)
                  
                }}
                variant={'outlineWhite'}
                placeholder={t('partner_portal.merchant.products.components.placeholder_style_ean_number') as string}
              />
            </InputGroup>
            <Flex flex={1} onClick={() => setIsDiscountModalOpen(true)}>
              <Button>{t('components.button.add_discount')}</Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody padding={0}>
          {!productsLoading && !whoAmI.data?.whoAmI?.commercetoolsDistributionChannelId != undefined ? (
            <ProductsTable columns={columns} table={table} whoAmI={whoAmI} />
          ) : (
            <PageSpinner />
          )}
        </CardBody>
        <CardFooter>
          <Flex flex={1} alignItems={'center'} justifyContent={'space-between'}>
            <Flex gap={6} alignItems={'center'}>
              <Flex gap={3} alignItems={'center'}>
                <Select onChange={(e) => fetchProducts(parseInt(e.target.value), productOffset)} variant={'solidRounded'} size={'xs'} w={'fit-content'}>
                  <option value='10'>10</option>
                  <option value='15'>15</option>
                  <option value='20'>20</option>
                </Select>
                <Text>Items per page</Text>
              </Flex>
              <Flex>
                {productOffset}-{products.length + productOffset} of {totalProductCount} items
              </Flex>
            </Flex>
            <Flex alignItems={'center'} gap={4}>
              <Flex>
                Page {pageIndex + 1} of {table.pageOptions.length}
              </Flex>
              <Flex>
                <IconButton
                  disabled={!table.canPreviousPage}
                  onClick={async () => {
                    onPageChange(controlledPageIndex - 1)
                    table.previousPage()
                  }}
                  variant={'ghost'}
                  aria-label='previous'
                  icon={<Icon as={IconChevronLeft} />}
                />
                <IconButton
                  disabled={!table.canNextPage}
                  onClick={async () => {
                    onPageChange(controlledPageIndex + 1)
                    table.nextPage()
                  }}
                  variant={'ghost'}
                  aria-label='next'
                  icon={<Icon as={IconChevronRight} />}
                />
              </Flex>
            </Flex>
          </Flex>
        </CardFooter>
      </Card>
    </PartnerPortalPage>
  )
}

export default withAuth(MerchantProductsPage, [MainRole.RoleAdmin])
