import { createContext, ReactElement, useContext, useState } from 'react'
import { getChannels, getProductsProjectionSearch, getSubCategories } from '../commercetools/requests'
import { CategoryProps, ColorFacet, CurrentCategoriesProps, Facet, PlentyProduct } from '../pages/main_app/PLP/types'
import { formatPlentyProduct, getSameProductsDifferentVariants, mainColorsMapped, mapToPredefinedColors } from '../pages/main_app/PLP/utils'
import { Channel, FacetTerm, Store } from '@commercetools/platform-sdk'
import { useDisclosure } from '@chakra-ui/react'
import { useGetStoresLazyQuery } from '../generated/graphql'

type FilterContextType = {
  selectedColors: Set<string> | null
  setSelectedColors: (colors: Set<string> | null) => void
  selectedBrands: string[] | null
  setSelectedBrands: (brands: string[] | null) => void
  selectedSizes: string[] | null
  setSelectedSizes: (sizes: string[] | null) => void
  currentCategories: CurrentCategoriesProps | undefined
  setCurrentCategories: (categories: CurrentCategoriesProps) => void
  products: PlentyProduct[] | undefined
  fetchProducts: (categoryId: string | undefined, offset: number, store?: string | null) => Promise<void>
  fetchCategories: (category: CategoryProps) => Promise<void>
  showMoreProducts: () => void
  resetFiltersAndFetch: () => void
  resetFilters: () => void
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  brandFacets: Facet[]
  sizeFacets: Facet[]
  colorFacets: ColorFacet[]
  stores?: Store[]
  existMoreProducts: boolean
  isLoadingProducts: boolean
  isLoadingMoreProducts: boolean
  priceSort?: string
  setPriceSort: (priceSort: string | undefined) => void
}

const FilterContext = createContext<FilterContextType>({
  selectedColors: new Set(),
  setSelectedColors: () => undefined,
  selectedBrands: [],
  setSelectedBrands: () => undefined,
  selectedSizes: [],
  setSelectedSizes: () => undefined,
  currentCategories: {
    subCategories: undefined,
    category: { id: '', name: { en: '', 'da-DK': '' } },
  },
  setCurrentCategories: () => undefined,
  products: undefined,
  fetchProducts: async () => undefined,
  fetchCategories: async () => undefined,
  showMoreProducts: () => undefined,
  resetFiltersAndFetch: () => undefined,
  resetFilters: () => undefined,
  isOpen: false,
  onOpen: () => undefined,
  onClose: () => undefined,
  brandFacets: [],
  sizeFacets: [],
  colorFacets: [],
  stores: [],
  existMoreProducts: true,
  isLoadingProducts: false,
  isLoadingMoreProducts: false,
  priceSort: undefined,
  setPriceSort: () => undefined,
})

const sortOrder = ['XXS', 'XS', 'XS/S', 'S', 'S/M', 'M', 'M/L', 'L', 'L/XL', 'XL', 'XXL', '2XL', 'XXXL', '3XL']

export default function CTFilterContext({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  // const [colorFilter, setColorFilter] = useState<string[]>([])
  const [selectedColors, setSelectedColors] = useState<Set<string> | null>(new Set())
  const [channels, setChannels] = useState<Channel[] | undefined>([])
  const [stores, setStores] = useState<Store[] | undefined>([])
  const [selectedStore, setSelectedStore] = useState<Store | undefined>()
  const [colorFacets, setColorFacets] = useState<ColorFacet[]>([])
  const [brandFacets, setBrandFacets] = useState<Facet[]>([])
  const [sizeFacets, setSizeFacets] = useState<Facet[]>([])
  const [selectedBrands, setSelectedBrands] = useState<string[] | null>([])
  const [selectedSizes, setSelectedSizes] = useState<string[] | null>([])
  const [existMoreProducts, setExistMoreProducts] = useState<boolean>(true)
  const [currentCategories, setCurrentCategories] = useState<CurrentCategoriesProps | undefined>()
  const [products, setProducts] = useState<PlentyProduct[] | undefined>()
  const [offset, setOffset] = useState(80)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(true)
  const [isLoadingMoreProducts, setIsLoadingMoreProducts] = useState<boolean>(false)
  const [priceSort, setPriceSort] = useState<string>()
  const [getStores] = useGetStoresLazyQuery()

  const fetchCategories = async (category: CategoryProps) => {
    try {
      setIsLoadingProducts(true)
      const channelsFromCT = await getChannels()

      const storesFromCT = await getStores()

      setStores(storesFromCT.data?.getStores as Store[])
      setChannels(channelsFromCT)
      await getSubCategories(category.id!).then((categories) => {
        setCurrentCategories({ category: category, subCategories: categories })
        setOffset(80)
      })
    } catch (error) {
      // Handle error
    }
  }
  const fetchProducts = async (categoryId: string | undefined, offset: number, store?: string | null) => {
    try {
      setIsLoadingProducts(true)
      const storesToFilter = store ? stores?.find((it) => it.key == store)?.distributionChannels.map((it) => it.id) : channels?.map((channel) => channel.id)
      const storeFromUrl = store ? stores?.find((it) => it.key == store) : undefined
      setSelectedStore(storeFromUrl)
      const colorFilter = selectedColors ? Array.from(selectedColors) : undefined
      await getProductsProjectionSearch(currentCategories?.category.id, offset, colorFilter, selectedBrands, selectedSizes, storesToFilter, priceSort).then(
        (products) => {
          const plentyProducts = formatPlentyProduct(products?.results, colorFilter, storeFromUrl?.distributionChannels[0].id)
          setProducts(plentyProducts)
          setExistMoreProducts((products?.total || 80) > plentyProducts.length)

          if (products?.facets) {
            const brandFacet: any = products?.facets['variants.attributes.brand.en']
            const sizeFacet: any = products?.facets['variants.attributes.size.en']
            const colorFacet: any = products?.facets['variants.attributes.main_color.en']
            setBrandFacets(brandFacet.terms)
            setSizeFacets(sortPlentySizes(sizeFacet.terms))
            setColorFacets(mapToPredefinedColors(colorFacet.terms, mainColorsMapped))
          }
          setIsLoadingProducts(false)
        },
      )

      // Do your data formatting and manipulation here
    } catch (error) {
      // Handle error
    }
  }

  const resetFiltersAndFetch = async () => {
    try {
      setIsLoadingProducts(true)
      setPriceSort('none')
      setSelectedColors(new Set())
      setSelectedBrands([])
      setSelectedSizes([])
      const colorFilter = selectedColors ? Array.from(selectedColors) : undefined
      const storeToFilter = selectedStore ? selectedStore.distributionChannels.map((it) => it.id) : channels?.map((channel) => channel.id)
      await getProductsProjectionSearch(currentCategories?.category.id, 0, [], [], [], storeToFilter, priceSort)
        .then((products) => {
          const plentyProducts = formatPlentyProduct(products?.results, colorFilter, selectedStore?.distributionChannels[0].id)
          setSelectedColors(new Set())
          setSelectedBrands([])
          setSelectedSizes([])
          setProducts(plentyProducts)
          if (products?.facets) {
            const brandFacet: any = products?.facets['variants.attributes.brand.en']
            const sizeFacet: any = products?.facets['variants.attributes.size.en']
            setBrandFacets(brandFacet.terms)
            setSizeFacets(sortPlentySizes(sizeFacet.terms))
          }
        })
        .finally(() => setIsLoadingProducts(false))
      // Do your data formatting and manipulation here
    } catch (error) {
      // Handle error
    }
  }

  const resetFilters = () => {
    setSelectedColors(new Set())
    setSelectedBrands([])
    setSelectedSizes([])
    setPriceSort('none')
  }

  const showMoreProducts = () => {
    const newOffset = offset + 80
    const colorFilter = selectedColors ? Array.from(selectedColors) : undefined
    const storeToFilter = selectedStore ? selectedStore.distributionChannels.map((it) => it.id) : channels?.map((channel) => channel.id)
    setOffset(newOffset)
    setIsLoadingMoreProducts(true)
    getProductsProjectionSearch(currentCategories?.category.id, offset, colorFilter, selectedBrands, selectedSizes, storeToFilter, priceSort)
      .then((prods) => {
        const formattedProducts = formatPlentyProduct(prods?.results, colorFilter, selectedStore?.distributionChannels[0].id)

        setExistMoreProducts((prods?.total || 80) > formattedProducts.length + products!.length)
        setProducts([...products!, ...formattedProducts])
        if (prods?.facets) {
          const brandFacet: any = prods?.facets['variants.attributes.brand.en']
          const sizeFacet: any = prods?.facets['variants.attributes.size.en']
          setBrandFacets(brandFacet.terms)
          setSizeFacets(sortPlentySizes(sizeFacet.terms))
        }
      })
      .finally(() => setIsLoadingMoreProducts(false))
  }

  const extractStartingNumber = (term: string) => {
    const match = term.match(/^(\d+)-?/)
    return match ? Number(match[1]) : null
  }

  const sortPlentySizes = (sizes: FacetTerm[]) => {
    return sizes.sort((a, b) => {
      const termA = a.term
      const termB = b.term

      const isNumericA = !isNaN(Number(termA))
      const isNumericB = !isNaN(Number(termB))

      const startingNumberA = extractStartingNumber(termA)
      const startingNumberB = extractStartingNumber(termB)

      if (startingNumberA !== null && startingNumberB !== null) {
        return startingNumberA - startingNumberB
      }

      if (isNumericA && isNumericB) {
        return Number(termA) - Number(termB)
      }

      if (!isNumericA && !isNumericB) {
        const indexA = sortOrder.indexOf(termA)
        const indexB = sortOrder.indexOf(termB)

        if (indexA === -1 && indexB === -1) {
          return termA.localeCompare(termB)
        }
        if (indexA === -1) return 1
        if (indexB === -1) return -1

        return indexA - indexB
      }

      return isNumericA ? 1 : -1
    })
  }

  return (
    <FilterContext.Provider
      value={{
        setSelectedColors,
        selectedColors,
        selectedBrands,
        setSelectedBrands,
        selectedSizes,
        setSelectedSizes,
        currentCategories,
        setCurrentCategories,
        products,
        fetchProducts,
        fetchCategories,
        showMoreProducts,
        resetFiltersAndFetch,
        resetFilters,
        isOpen,
        onOpen,
        onClose,
        brandFacets,
        sizeFacets,
        colorFacets,
        stores,
        existMoreProducts,
        isLoadingProducts,
        isLoadingMoreProducts,
        priceSort,
        setPriceSort,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useCTFilter = (): FilterContextType => useContext(FilterContext)
