import { useApolloClient } from '@apollo/client'
import { Flex, Icon, IconButton, Img, Menu, MenuButton, MenuItem, MenuList, Spinner, Tag, Text, useToast } from '@chakra-ui/react'
import { IconDots } from '@tabler/icons-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { ErrorBox } from '../../../../components/ErrorBox'
import { Toast } from '../../../../components/PlentyUI/Toast'
import { CampaignDiscount } from '../../../../generated/graphql'
import {
  DELETE_CAMPAIGN_DISCOUNT,
  GET_ALL_IMAGES_FROM_PRODUCTS_AND_CATEGORIES_FROM_CAMPAIGN_DISCOUNT,
  GET_CAMPAIGN_DISCOUNTS
} from '../../../../gql/discount'
import { RenameCampaignModal } from './RenameCampaignModal'

type CampaignDiscountProps = {
  campaignDiscount: CampaignDiscount
}

export const CampaignDiscountCart = (props: CampaignDiscountProps) => {
  const client = useApolloClient()
  const toast = useToast()
  const [isLoadingEntity, setIsLoadingEntity] = useState(true)
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [productImages, setProuductImages] = useState<string[]>([])
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const shuffleArray = (array: string[]): string[] => {
    const originalList = [...array]
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[originalList[i], originalList[j]] = [originalList[j], originalList[i]]
    }

    return originalList
  }

  useEffect(() => {
    client
      .query({
        query: GET_ALL_IMAGES_FROM_PRODUCTS_AND_CATEGORIES_FROM_CAMPAIGN_DISCOUNT,
        variables: {
          campaignDiscountId: props.campaignDiscount.id,
        },
      })
      .then((result: any) => {
        const shuffledResults = shuffleArray(result.data.getAllImagesFromProductsAndCategoriesFromCampaignDiscount)
        setProuductImages(shuffledResults)
        setIsLoadingEntity(false)
      })
  }, [])

  const deleteCampaign = () => {
    setIsLoadingAction(true)

    client
      .mutate({
        mutation: DELETE_CAMPAIGN_DISCOUNT,
        variables: {
          campaignDiscountId: props.campaignDiscount.id,
        },
      })
      .then((result: any) => {
        client.refetchQueries({ include: [GET_CAMPAIGN_DISCOUNTS] })
        toast({
          position: 'top',
          render: () => <Toast type='success' label={'The campaign was deleted successfully'} />,
        })
        setIsLoadingAction(false)
      })
      .catch(() => {
        toast({
          position: 'top',
          render: () => <Toast type='error' label={'Something went wrong'} />,
        })
        setIsLoadingAction(false)
      })
  }

  const StateTag = () => {
    const today = moment()
    const validFrom = moment(props.campaignDiscount.validFrom)
    const validTo = moment(props.campaignDiscount.validTo)

    if (validFrom.isBefore(today) && props.campaignDiscount.validTo == null) {
      return (
        <Tag position={'absolute'} top={3} left={3} colorScheme={'green'}>
          Active
        </Tag>
      )
    }

    if (validFrom.isBefore(today) && validTo.isAfter(today)) {
      return (
        <Tag position={'absolute'} top={3} left={3} colorScheme={'green'}>
          Active
        </Tag>
      )
    }

    if (validFrom.isAfter(today)) {
      return (
        <Tag position={'absolute'} top={3} left={3} colorScheme={'blue'}>
          Scheduled
        </Tag>
      )
    }

    if (validTo.isBefore(today)) {
      return (
        <Tag position={'absolute'} top={3} left={3} colorScheme={'red'}>
          Expired
        </Tag>
      )
    }

    return (
      <Tag position={'absolute'} top={3} left={3} colorScheme={'gray'}>
        Unknown state
      </Tag>
    )
  }

  const LoadingOverlay = () => {
    return (
      <Flex flex={1} h={'full'} justifyContent={'center'} alignItems={'center'}>
        <Spinner size={'lg'} />
      </Flex>
    )
  }

  const CampaignImages = () => {
    if (productImages.length == 1) {
      return (
        <Flex flex={1}>
          <Img w={'full'} objectFit={'cover'} src={productImages[0]} />
        </Flex>
      )
    } else if (productImages.length > 1) {
      return (
        <>
          <Flex flex={1}>
            <Img
              w={'full'}
              objectFit={'cover'}
              src={productImages.length >= 1 ? productImages[0] : 'https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg'}
            />
          </Flex>
          <Flex flex={1}>
            <Img
              w={'full'}
              objectFit={'cover'}
              src={productImages.length >= 2 ? productImages[1] : 'https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg'}
            />
          </Flex>
        </>
      )
    } else {
      return <ErrorBox message='No images found' />
    }
  }

  return (
    <Flex h={'285px'} w={'24%'} position={'relative'} borderRadius={24} boxShadow={'lg'} bgColor={'white.500'} direction={'column'}>
      {isLoadingEntity ? (
        <LoadingOverlay />
      ) : (
        <>
          <Flex flex={1} borderTopRadius={24} overflow={'hidden'}>
            <CampaignImages />
          </Flex>
          <Flex direction={'row'} justifyContent={'space-between'} p={4}>
            <Flex direction={'column'} h={'fit-content'}>
              <Flex maxH={'21px'} h={'fit-content'} gap={2} alignItems={'center'}>
                <Text fontSize={'xs'}>{moment(props.campaignDiscount.validFrom).format('DD/MM/yyyy')}</Text>
                <Text>-</Text>
                <Text fontSize={'xs'}>
                  {props.campaignDiscount.validTo != null ? moment(props.campaignDiscount.validTo).format('DD/MM/yyyy') : 'Continuous'}
                </Text>
              </Flex>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {props.campaignDiscount.name}
              </Text>
              <Text fontSize={'sm'}>
                {props.campaignDiscount.discounts.length > 1
                  ? props.campaignDiscount.discounts.length + ' discounts'
                  : props.campaignDiscount.discounts.length + ' discount'}
              </Text>
            </Flex>
            <Menu variant={'baseStyle'}>
              <MenuButton
                as={IconButton}
                isLoading={isLoadingAction}
                aria-label='more options'
                variant={'ghost'}
                size={'lg'}
                icon={<Icon h={6} w={6} as={IconDots} />}
              />
              <MenuList>
                <MenuItem onClick={() => setIsRenameModalOpen(true)}>Rename</MenuItem>
                <MenuItem color={'red.500'} onClick={() => deleteCampaign()}>
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <StateTag />
          <RenameCampaignModal isOpen={isRenameModalOpen} setIsOpen={setIsRenameModalOpen} campaignDiscount={props.campaignDiscount} />
        </>
      )}
    </Flex>
  )
}
