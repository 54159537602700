import { Box, Button, Flex, Text, Wrap, WrapItem } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useCTFilter } from '../../../../context/CTFilterContext'
import { useBrandSelector } from '../hooks/useBrandSelector'

export const SizeSelector = () => {
  const [showAll, setShowAll] = useState(false)
  const { sizeFacets, selectedSizes: sizesFromContext, setSelectedSizes: updateGlobalSizes } = useCTFilter()
  const [selectedSizes, setSelectedSizes] = useBrandSelector(sizesFromContext, updateGlobalSizes)

  const displayFacets = showAll ? sizeFacets : sizeFacets.slice(0, 12)

  const toggleSize = (value: string) => {
    if (selectedSizes?.includes(value)) {
      setSelectedSizes(selectedSizes.filter((size) => size !== value))
    } else {
      setSelectedSizes([...selectedSizes!, value])
    }
  }

  return (
    <div>
      <Wrap spacing={2} justifyContent={'center'}>
        {displayFacets.map((facet, index) => (
          <WrapItem key={index}>
            <Box
              as='button'
              onClick={() => toggleSize(facet.term)}
              borderRadius='50%'
              width='50px'
              height='36px'
              bg={selectedSizes?.includes(facet.term) ? 'mintGreen.500' : 'white'}
              color={selectedSizes?.includes(facet.term) ? 'black' : 'black'}
              display='inline-flex'
              alignItems='center'
              justifyContent='center'
              margin='2px'
              boxShadow='md'
              // _hover={{ bg: 'gray.200' }}
              // _active={{ bg: 'gray.300' }}
            >
              <Text fontSize={'xs'}>{facet.term}</Text>
            </Box>
          </WrapItem>
        ))}
      </Wrap>
      <Flex justifyContent={'center'}>
        {sizeFacets.length > 12 && (
          <Button variant={'transparentWithUnderline'} onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Show Less' : 'Show All Sizes'}
          </Button>
        )}
      </Flex>
    </div>
  )
}
