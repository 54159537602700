import { Box, Divider, Flex, Heading, Img, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { Attribute, MainRole, Maybe, useGetCustomerByOrderNumberQuery, useGetOrderByOrderNumberQuery } from '../../../../../generated/graphql'
import { Theme } from '../../../../../ThemeVariables'
import { centPriceToPrice } from '../../../../../utils/MoneyUtil'
import { Capitalize } from '../../../../../utils/TextUtil'
import withAuth from '../../../../../utils/WithAuth'
import { useEffect } from 'react'
import { MainPageDesktop } from '../../../../../components/layout/main/desktop/MainPageDesktop'
import client from '../../../../../apollo-client'
import { CREATE_CART } from '../../../../../gql/cart'
import { useQueryParam } from '../../../../../hooks/useQueryParam'
import React from 'react'
import { ampli } from '../../../../../ampli'

const OrderConfirmationPageDesktop = () => {
  const params = useParams()
  const query = useQueryParam()
  const errorRedirect = query.get('err')
  const resetCartAndAdyenSession = () => {
    client.mutate({ mutation: CREATE_CART }).then((response: any) => {
      ampli.cartCreated()
      localStorage.setItem('cartId', response.data.createCart.id)
    })
    localStorage.removeItem('adyen-checkout__session')
  }

  const getCustomerInformation = useGetCustomerByOrderNumberQuery({
    variables: {
      orderNumber: params.orderNumber ? params.orderNumber : '',
    },
  })

  const order = useGetOrderByOrderNumberQuery({
    variables: {
      orderNumber: params.orderNumber ? params.orderNumber : '',
    },
  })

  const renderErrorMessage = () => {
    resetCartAndAdyenSession()

    switch (errorRedirect) {
      case 'cancelled':
        return (
          <Flex direction={'column'} gap={2} bgColor={'red.300'} p={Theme.spacing.padding.mobile * 1.5} borderRadius={'3xl'}>
            <Heading fontSize={'xl'}>{t('main_app.checkout.text_payment_canceled')}</Heading>
            <Text>{t('main_app.checkout.text_payment_canceled_description')}</Text>
          </Flex>
        )
      case 'failure':
        return (
          <Flex direction={'column'} gap={2} bgColor={'red.300'} p={Theme.spacing.padding.mobile * 1.5} borderRadius={'3xl'}>
            <Heading fontSize={'xl'}>{t('main_app.checkout.text_payment_error')}</Heading>
            <Text>{t('main_app.checkout.text_payment_error_description')}</Text>
          </Flex>
        )
      case 'pending':
        return (
          <Flex direction={'column'} gap={2} bgColor={'red.300'} p={Theme.spacing.padding.mobile * 1.5} borderRadius={'3xl'}>
            <Heading fontSize={'xl'}>{t('main_app.checkout.text_payment_pending')}</Heading>
            <Text>{t('main_app.checkout.text_payment_pending_description')}</Text>
          </Flex>
        )
      case 'refused':
        return (
          <Flex direction={'column'} gap={2} bgColor={'red.300'} p={Theme.spacing.padding.mobile * 1.5} borderRadius={'3xl'}>
            <Heading fontSize={'xl'}>{t('main_app.checkout.text_payment_refused')}</Heading>
            <Text>{t('main_app.checkout.text_payment_refused_description')}</Text>
          </Flex>
        )
      default:
        return <></>
    }
  }
  useEffect(() => {
    if (order.data && !order.error) {
      // If the Facebook Pixel script is loaded on your site

      // Extract products (content_ids) from the order.data
      const contentIds = order.data.getOrderByOrderNumber!.lineItems.map((item) => item!.id)

      const value = centPriceToPrice(
        order.data?.getOrderByOrderNumber?.totalPrice.centAmount || 0,
        order.data?.getOrderByOrderNumber?.totalPrice.fractionDigits || 0,
      ).toFixed(2)

      window.fbq('track', 'Purchase', {
        content_type: 'product',
        content_ids: contentIds,
        currency: order.data?.getOrderByOrderNumber?.totalPrice.currencyCode || 'DKK',
        value: parseFloat(value), // Convert the string value to a number
      })
    }
  }, [order.data, order.error])
  useEffect(() => {
    client.mutate({ mutation: CREATE_CART }).then((response: any) => {
      ampli.cartCreated()
      localStorage.setItem('cartId', response.data.createCart.id)
    })
  }, [])

  const groupedItems =
    order && order.data && order.data.getOrderByOrderNumber
      ? order.data.getOrderByOrderNumber.lineItems.reduce((groups, item) => {
          const groupName = item!.distributionChannel.obj?.name.en

          if (!groups[groupName]) {
            groups[groupName] = []
          }

          groups[groupName].push(item)

          return groups
        }, {} as { [key: string]: any })
      : {}
  return (
    <MainPageDesktop loading={order.loading} error={order.error}>
      <Flex direction={'column'} gap={6} m={4} flex={1} maxWidth={'500px'} mx={'auto'}>
        {errorRedirect ? (
          renderErrorMessage()
        ) : (
          <Flex direction={'column'} gap={6}>
            <Flex direction={'column'} gap={2} bgColor={'mintGreen.500'} p={Theme.spacing.padding.mobile * 1.5} borderRadius={'3xl'}>
              <Heading fontSize={'xl'}>{t('main_app.checkout.heading_order_confirmation')}</Heading>
              <Text>{t('main_app.checkout.text_recieved_order')}</Text>
              <Text>{t('main_app.checkout.text_recieve_email')}</Text>
            </Flex>
            <Flex direction={'column'} gap={4}>
              <Heading>
                {t('main_app.checkout.heading_order_number')}
                {order.data?.getOrderByOrderNumber?.orderNumber}
              </Heading>
              <Text> {moment(order.data?.getOrderByOrderNumber?.createdAt).format('LL')}</Text>
            </Flex>
            <Flex direction={'column'} gap={6}>
              {order.data?.getOrderByOrderNumber?.shippingInfo.shippingMethodName === 'Pickup' ? (
                <Flex direction={'column'} gap={4}>
                  <Flex>
                    <Heading fontSize={'2xl'}>{t('components.cart.text_pick_up')}</Heading>
                  </Flex>
                  {Object.entries(groupedItems).map(([groupName, items], index) => (
                    <Flex
                      key={index}
                      padding={4}
                      borderRadius={Theme.spacing.borderRadius}
                      boxShadow={'0px 8px 16px 0px rgba(0, 0, 0, 0.08) '}
                      gap={2}
                      direction={'column'}
                    >
                      <Heading fontSize={'xl'}>{groupName}</Heading>
                      {items[0] && (
                        <Flex>
                          {items[0]!.distributionChannel.obj?.address?.streetName} {items[0]!.distributionChannel.obj?.address?.streetNumber},{' '}
                          {items[0]!.distributionChannel.obj?.address?.postalCode} {items[0]!.distributionChannel.obj?.address?.city}{' '}
                        </Flex>
                      )}
                      <Flex direction={'column'} mt={6} gap={4}>
                        {items.map((item: any, itemIndex: any) => (
                          <React.Fragment key={itemIndex}>
                            {item!.variant.images && item!.variant.images.length > 0 && (
                              <Box key={index}>
                                <Flex gap={6}>
                                  <Box position='relative'>
                                    <Img borderRadius={'xl'} w={24} src={item?.variant.images![0]?.url || ''} />
                                    {item?.quantity && (
                                      <Box
                                        position='absolute'
                                        top='-8px'
                                        right='-8px'
                                        bg='mintGreen.500'
                                        borderRadius='full'
                                        width='24px'
                                        height='24px'
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                      >
                                        <span style={{ fontSize: '14px' }}>{item?.quantity}</span>
                                      </Box>
                                    )}
                                  </Box>
                                  <Flex direction={'column'} flex={1}>
                                    <Text>
                                      {centPriceToPrice(item?.totalPrice.centAmount || 0, item?.totalPrice.fractionDigits || 0).toFixed(2) +
                                        ' ' +
                                        item?.totalPrice.currencyCode}
                                    </Text>
                                    {item?.variant.attributes!.map((item: Maybe<Attribute>, index: number) => {
                                      return <Flex key={index}>{Capitalize(item?.name) + ': ' + item?.value['en']}</Flex>
                                    })}
                                    <Text>
                                      {t('main_app.checkout.text_store_order_summary')} {item?.distributionChannel.obj?.name.en}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Box>
                            )}
                          </React.Fragment>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              ) : (
                <Flex direction={'column'} gap={4}>
                  <Flex direction={'column'}>
                    <Heading fontSize={'2xl'}>{t('components.cart.text_shipping')}</Heading>
                  </Flex>
                  {Object.entries(groupedItems).map(([groupName, items], index) => (
                    <Flex
                      key={index}
                      padding={4}
                      borderRadius={Theme.spacing.borderRadius}
                      boxShadow={'0px 8px 16px 0px rgba(0, 0, 0, 0.08) '}
                      gap={2}
                      direction={'column'}
                    >
                      <Heading fontSize={'xl'}>{groupName}</Heading>
                      {items[0] && (
                        <Flex>
                          {items[0]!.distributionChannel.obj?.address?.streetName} {items[0]!.distributionChannel.obj?.address?.streetNumber},{' '}
                          {items[0]!.distributionChannel.obj?.address?.postalCode} {items[0]!.distributionChannel.obj?.address?.city}{' '}
                        </Flex>
                      )}
                      <Flex direction={'column'} mt={6} gap={4}>
                        {items.map((item: any, itemIndex: any) => (
                          <React.Fragment key={itemIndex}>
                            {item!.variant.images && item!.variant.images.length > 0 && (
                              <Box key={index}>
                                <Flex gap={6}>
                                  <Box position='relative'>
                                    <Img borderRadius={'xl'} w={24} src={item?.variant.images![0]?.url || ''} />
                                    {item?.quantity && (
                                      <Box
                                        position='absolute'
                                        top='-8px'
                                        right='-8px'
                                        bg='mintGreen.500'
                                        borderRadius='full'
                                        width='24px'
                                        height='24px'
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                      >
                                        <span style={{ fontSize: '14px' }}>{item?.quantity}</span>
                                      </Box>
                                    )}
                                  </Box>
                                  <Flex direction={'column'} flex={1}>
                                    <Text>
                                      {centPriceToPrice(item?.totalPrice.centAmount || 0, item?.totalPrice.fractionDigits || 0).toFixed(2) +
                                        ' ' +
                                        item?.totalPrice.currencyCode}
                                    </Text>
                                    {item?.variant.attributes!.map((item: Maybe<Attribute>, index: number) => {
                                      return <Flex key={index}>{Capitalize(item?.name) + ': ' + item?.value['en']}</Flex>
                                    })}
                                    <Text>
                                      {t('main_app.checkout.text_store_order_summary')} {item?.distributionChannel.obj?.name.en}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Box>
                            )}
                          </React.Fragment>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                  <Flex py={4} direction={'column'}>
                    <Flex fontWeight={'bold'}>{t('components.cart.text_delivery_address')}</Flex>
                    <Flex>
                      {order.data?.getOrderByOrderNumber?.shippingInfo.shippingMethodName === 'PostnordHomeDelivery'
                        ? `${t('components.cart.text_delivery_to')} ${getCustomerInformation.data?.getCustomerByOrderNumber?.addresses?.[0]?.streetName}`
                        : `${t('components.cart.text_delivery_to_postnord')}`}
                    </Flex>
                  </Flex>{' '}
                  <Flex direction={'column'}>
                    <Flex fontWeight={'bold'}>{t('main_app.checkout.text_shipping_method')}</Flex>
                    <Flex>
                      {(() => {
                        switch (order.data?.getOrderByOrderNumber?.shippingInfo.shippingMethodName) {
                          case 'Pickup':
                            return <Text>{t('main_app.checkout.text_parcel_pick_up')}</Text>
                          case 'PostnordParcelShopDelivery':
                            return <Text>{t('main_app.checkout.text_parcel_shop')}</Text>

                          case 'PostnordHomeDelivery':
                            return <Text>{t('main_app.checkout.text_parcel_home_delivery')}</Text>
                          default:
                            return <Text>invalid</Text>
                        }
                      })()}
                    </Flex>
                    <Flex>{t('components.cart.text_delivery_time')}</Flex>{' '}
                  </Flex>
                </Flex>
              )}
              <Flex direction={'column'} gap={4}>
                <Heading size={'md'}>{t('main_app.checkout.heading_order_total')}</Heading>
                <Flex direction={'column'} gap={2}>
                  <Flex justifyContent={'space-between'}>
                    <Text>{t('main_app.checkout.text_subtotal')}</Text>
                    <Text>
                      {order.data?.getOrderByOrderNumber?.lineItems
                        .reduce((total, item) => total + centPriceToPrice(item!.totalPrice.centAmount, item!.totalPrice.fractionDigits), 0)
                        .toFixed(2) +
                        ' ' +
                        order.data?.getOrderByOrderNumber?.totalPrice.currencyCode}
                    </Text>
                  </Flex>
                  <Flex justifyContent={'space-between'}>
                    <Flex>
                      {(() => {
                        switch (order.data?.getOrderByOrderNumber?.shippingInfo.shippingMethodName) {
                          case 'Pickup':
                            return <Text>{t('main_app.checkout.text_parcel_pick_up')}</Text>
                          case 'PostnordParcelShopDelivery':
                            return <Text>{t('main_app.checkout.text_parcel_shop')}</Text>

                          case 'PostnordHomeDelivery':
                            return <Text>{t('main_app.checkout.text_parcel_home_delivery')}</Text>
                          default:
                            return <Text>invalid</Text>
                        }
                      })()}
                    </Flex>{' '}
                    {(() => {
                      const totalOrderPrice = centPriceToPrice(
                        order.data?.getOrderByOrderNumber?.totalPrice.centAmount || 0,
                        order.data?.getOrderByOrderNumber?.totalPrice.fractionDigits || 0,
                      )

                      switch (order.data?.getOrderByOrderNumber?.shippingInfo.shippingMethodName) {
                        case 'Pickup':
                          return <Text>{t('main_app.checkout.text_free')}</Text>
                        case 'PostnordParcelShopDelivery':
                          return totalOrderPrice > 399 ? (
                            <Text>{t('main_app.checkout.text_free')}</Text>
                          ) : (
                            <Text>{t('main_app.checkout.text_shipping_price.0')}</Text>
                          )
                        case 'PostnordHomeDelivery':
                          return <Text>{t('main_app.checkout.text_shipping_price.1')}</Text>
                        default:
                          return <Text>invalid</Text>
                      }
                    })()}
                  </Flex>
                  <Divider borderColor={'beigeDark.500'} />
                  <Flex justifyContent={'space-between'} fontWeight={'bold'}>
                    <Text>{t('main_app.checkout.text_total')}</Text>
                    <Text>
                      {(() => {
                        const originalTotal = centPriceToPrice(
                          order.data?.getOrderByOrderNumber?.totalPrice.centAmount || 0,
                          order.data?.getOrderByOrderNumber?.totalPrice.fractionDigits || 0,
                        )

                        return originalTotal.toFixed(2) + ' ' + order.data?.getOrderByOrderNumber?.totalPrice.currencyCode
                      })()}
                    </Text>
                  </Flex>
                  <Text>{t('components.cart.steps.text_including_eur')}</Text>
                </Flex>
              </Flex>

              {/* this is failing half the time because of the endpoint */}
              <Flex my={10} direction={'column'}>
                <Heading fontSize={'2xl'}>{t('components.cart.text_contact_info')}</Heading>
                <Flex>
                  {getCustomerInformation.data?.getCustomerByOrderNumber?.firstName} {''} {getCustomerInformation.data?.getCustomerByOrderNumber?.lastName}
                </Flex>
                <Flex>{getCustomerInformation.data?.getCustomerByOrderNumber?.email}</Flex>
                <Flex>{getCustomerInformation.data?.getCustomerByOrderNumber?.addresses?.[0]?.phone}</Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </MainPageDesktop>
  )
}

export default withAuth(OrderConfirmationPageDesktop, [MainRole.RoleGuest])
