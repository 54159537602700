import { Flex } from '@chakra-ui/react'
import { ContentHeader } from '../../ContentHeader'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import withAuth from '../../../../utils/WithAuth'
import { MainRole } from '../../../../generated/graphql'
import { t } from 'i18next'

const MerchantSocialMediaPage = () => {
  return (
    <PartnerPortalPage pageHeaderLabel={t('partner_portal.merchant.so_me.page_header_so_me') as string}>
     
    </PartnerPortalPage>
  )
}

export default withAuth(MerchantSocialMediaPage, [MainRole.RoleGuest])
