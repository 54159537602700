import { Flex, Container, Heading, VStack, Text, OrderedList, ListItem, UnorderedList, Stack } from '@chakra-ui/react'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
export const PrivacyTermsInformationText = () => {
  return (
    <Container py={"2.5rem"}  maxW={'100rem'}>
      <Stack  spacing={14}>
        <Heading>{t('main_app.site_politics.privacy.heading_header')}</Heading>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading fontSize={'22px'}>{t('main_app.site_politics.privacy.heading_whis_is_our_policy')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_applies')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_bestseller_info')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_overview')}</Heading>
            <OrderedList style={{ marginInlineStart: 16, marginTop: 0 }}>
              <ListItem>{t('main_app.site_politics.privacy.list_table_of_contents.0')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_table_of_contents.1')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_table_of_contents.2')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_table_of_contents.3')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_table_of_contents.4')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_table_of_contents.5')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_table_of_contents.6')}</ListItem>
            </OrderedList>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading fontSize={'22px'}>{t('main_app.site_politics.privacy.heading_statement_on_privacy')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_great_fashion_experience')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_handling_personal_info')}</Text>
            <OrderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.privacy.list_collect_data_main_purposes.0')} </ListItem>
              <UnorderedList>
                <ListItem>{t('main_app.site_politics.privacy.list_collect_data_main_purposes.1')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_collect_data_main_purposes.2')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_collect_data_main_purposes.3')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_collect_data_main_purposes.4')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_collect_data_main_purposes.5')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_collect_data_main_purposes.6')}</ListItem>
              </UnorderedList>
              <ListItem>{t('main_app.site_politics.privacy.list_only_purposes_mentioned')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_delete_when_no_more_need')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_dont_sell_info')}</ListItem>
              <ListItem>{t('main_app.site_politics.privacy.list_store_data_secure')}</ListItem>
            </OrderedList>
            <Text>{t('main_app.site_politics.privacy.text_read_more_about_collecting_of_data')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_exercise_privacy_rights')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading fontSize={'22px'}>{t('main_app.site_politics.privacy.heading_collecting_and_using_your_data')}</Heading>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_fulfilment_of_your_order')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_fulfilment_of_your_order')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_customer_service')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_customer_service.0')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_customer_service.1')} </Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_improvement_of_user_experience')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_improvement_of_user_experience.0')}</Text>
            <Text>
              {t('main_app.site_politics.privacy.text_improvement_of_user_experience.1')}
              <Link style={{ textDecoration: 'underline' }} to={'/politics/cookie_policy'}>
                {t('main_app.site_politics.privacy.text_improvement_of_user_experience.2')}
              </Link>
            </Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_marketing')}</Heading>
            <Text>
              {t('main_app.site_politics.privacy.text_marketing.0')}
              <Link style={{ textDecoration: 'underline' }} to={'/politics/cookie_policy'}>
                {t('main_app.site_politics.privacy.text_marketing.1')}
              </Link>
            </Text>
            <Text>{t('main_app.site_politics.privacy.text_marketing.2')} </Text>
            <Text>{t('main_app.site_politics.privacy.text_marketing.3')} </Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_in_newsletter')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_in_newslettr')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_cookie_based_marketing')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_cookie_based_marketing.0')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_cookie_based_marketing.1')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_cookie_based_marketing.2')}</Text>
            <Text>
              {t('main_app.site_politics.privacy.text_cookie_based_marketing.3')}
              <Link style={{ textDecoration: 'underline' }} to={'/politics/cookie_policy'}>
                {t('main_app.site_politics.privacy.text_cookie_based_marketing.4')}
              </Link>
            </Text>
            <Text>{t('main_app.site_politics.privacy.text_cookie_based_marketing.5')}</Text>
            {/* these is static remember to add to english  */}
            <Text>
              <a href='http://www.networkadvertising.org/choices/' target='_blank' rel='noopener noreferrer'>
                http://www.networkadvertising.org/choices/
              </a>
            </Text>

            <Text>
              <a href='http://www.youronlinechoices.com/' target='_blank' rel='noopener noreferrer'>
                http://www.youronlinechoices.com/
              </a>
            </Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_my_account')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_my_account.0')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_my_account.1')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_my_account.2')} </Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_fraud_prevention')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_fraud_prevention')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_regulatory_requierments')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_regulatory_requierments')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_legal_bases_for_collection_and_using_data')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_legal_bases_for_collection_and_using_data')} </Text>
            <OrderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_b.0')}</ListItem>
              <UnorderedList>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_b.1')}</ListItem>
              </UnorderedList>
              <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_f.0')}</ListItem>
              <UnorderedList>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_f.1')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_f.2')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_f.3')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_f.4')}</ListItem>
              </UnorderedList>
              <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_a.0')}</ListItem>
              <UnorderedList>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_a.1')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_a.2')}</ListItem>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_a.3')}</ListItem>
              </UnorderedList>
              <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_c.0')}</ListItem>
              <UnorderedList>
                <ListItem>{t('main_app.site_politics.privacy.list_gdpr_article_6_1_c.1')} </ListItem>
              </UnorderedList>
            </OrderedList>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading fontSize={'22px'}>{t('main_app.site_politics.privacy.heading_sharing_personal_data_with_merchant')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_sharing_personal_data_with_merchant.0')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_sharing_personal_data_with_merchant.1')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading fontSize={'22px'}>{t('main_app.site_politics.privacy.heading_other_sharing_of_personal_data')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_other_sharing_of_personal_data')}</Text>
            <Text fontWeight={'bold'}>{t('main_app.site_politics.privacy.heading_data_processors')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_data_processors.0')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_data_processors.1')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_data_processors.2')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_data_processors.3')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_data_processors.4')}</Text>
            <Text fontWeight={'bold'}>{t('main_app.site_politics.privacy.heading_other_times_we_share_data')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_other_times_we_share_data')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading fontSize={'22px'}>{t('main_app.site_politics.privacy.heading_transfer_data_outside_eu')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_transfer_data_outside_eu.0')}</Text>
            {/* // Staic dont touch */}
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>Google Inc., USA </ListItem>
              <ListItem>Meta Inc., USA</ListItem>
              <ListItem>Drip Global Inc., USA</ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.privacy.text_transfer_data_outside_eu.1')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading fontSize={'22px'}>{t('main_app.site_politics.privacy.heading_how_long_we_store_your_data')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_how_long_we_store_your_data.0')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_how_long_we_store_your_data.1')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading fontSize={'22px'}>{t('main_app.site_politics.privacy.heading_your_rights')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_your_rights.0')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_your_rights.1')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_your_rights.2')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_right_to_withdraw_consent')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_right_to_withdraw_consent')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_right_of_access')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_right_of_access')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_right_to_be_forgotten')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_right_to_be_forgotten.0')}</Text>
            <Text>{t('main_app.site_politics.privacy.text_right_to_be_forgotten.1')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_right_to_object')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_right_to_object')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_right_to_rectification')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_right_to_rectification')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_right_to_restriction')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_right_to_restriction')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_right_to_data_portability')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_right_to_data_portability')}</Text>
            <Heading fontSize={'18px'}>{t('main_app.site_politics.privacy.heading_complaints')}</Heading>
            <Text>{t('main_app.site_politics.privacy.text_complaints')}</Text>
          </VStack>
        </Flex>
      </Stack>
    </Container>
  )
}
