import { Flex, Link as ChakraLink, Image } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { findBrandImageAndLabel } from '../../../../utils/GoogleCloudBucketUtil'

type PopularBrandCardProps = {
  hasDragged?: boolean
}

export const PopularBrandCard = ({ hasDragged }: PopularBrandCardProps) => {
  const handleHrefClick = (e: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    if (hasDragged) {
      e.preventDefault()
    }
  }
  const { gender } = useParams<{ gender: string }>()
  const currentGender = gender

  const imagesByGender = findBrandImageAndLabel(currentGender as 'men' | 'women' | 'kids')

  return (
    <Flex gap='1.5rem' textAlign='start'>
      {currentGender && renderCategory(imagesByGender)}
    </Flex>
  )

  function renderCategory(imageData: Record<string, any>) {
    if (!currentGender) return null

    return (
      <Flex justifyContent='center' gap='1rem'>
        {Object.keys(imageData).map((key) => (
          <ChakraLink href={imageData[key].url} onClick={(e) => handleHrefClick(e, imageData[key].url)} key={key}>
            <Flex
              position='relative'
              textAlign='center'
              width='250px'
              height='250px'
              borderRadius='1rem'
              alignItems='center'
              justifyContent='center'
              bgImage={`url(${imageData[key].imageUrl})`}
              bgSize='cover'
              bgPosition='center'
            >
              <Image src={imageData[key].labelUrl} alt={`${imageData[key].label} label`} position='absolute' zIndex='1' />
            </Flex>
          </ChakraLink>
        ))}
      </Flex>
    )
  }
}
