import { Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { IconCheck, IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { ReactElement, useState } from 'react'
import { CheckoutState } from '../enum/CheckoutState'

type StateItemProps = {
  state: CheckoutState
  heading: string
  children: ReactElement
  setCheckoutEditStep: (step: 'Delivery' | 'Payment' | 'Review') => void
  checkoutEditStep: 'Delivery' | 'Payment' | 'Review'
  summary?: ReactElement
}

export const StateItem = (props: StateItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const isToggleable = () => {
    return props.state == CheckoutState.TOGGLEABLE
  }

  const isState = (state: CheckoutState) => {
    return props.state == state
  }

  const isNotState = (state: CheckoutState) => {
    return props.state != state
  }

  return (
    <Flex
      direction={'column'}
      pt={'1rem'}
      pb={(isToggleable() && !isExpanded) || (isNotState(CheckoutState.ACTIVE) && isNotState(CheckoutState.TOGGLEABLE)) ? '1rem' : undefined}
    >
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        cursor={isToggleable() ? 'pointer' : 'default'}
        onClick={() => {
          if (isToggleable()) {
            setIsExpanded(!isExpanded)
          }
        }}
      >
        <Flex gap={'1rem'} alignItems={'center'} color={'mintGreen.700'}>
          {isState(CheckoutState.DONE) ? <Icon h={5} w={5} as={IconCheck} /> : null}
          <Heading
            flexGrow={1}
            size={'md'}
            color={isState(CheckoutState.TO_COME) || isState(CheckoutState.DONE) || (isToggleable() && !isExpanded) ? '#7c7474' : 'black.500'}
          >
            {props.heading}
          </Heading>
        </Flex>
        {isState(CheckoutState.TOGGLEABLE) ? <Icon as={isExpanded ? IconChevronDown : IconChevronUp} h={6} w={6} color={'#7c7474'} /> : null}
        {isState(CheckoutState.DONE) && props.checkoutEditStep === 'Delivery' && (
          <Text as='u' cursor={'pointer'} onClick={() => props.setCheckoutEditStep(props.checkoutEditStep)}>
            Edit
          </Text>
        )}
      </Flex>
      <Flex
        w={'full'}
        hidden={(isToggleable() && !isExpanded) || isState(CheckoutState.TO_COME) || isState(CheckoutState.DONE)}
        pt={'1.5rem'}
        pb={'1.5rem'}
      >
        {props.children}
      </Flex>
      <Flex w={'full'} hidden={!props.summary || isNotState(CheckoutState.DONE)} pt={'1.5rem'} pb={'1.5rem'}>
        {props.summary}
      </Flex>
    </Flex>
  )
}
