import { Input, InputGroup, InputLeftElement, Link as ChakraLink } from '@chakra-ui/react'
import { TbSearch as TbSearchIcon } from 'react-icons/tb'
import { useLocation } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'
import { t } from 'i18next'

const SearchInputField = () => {
  const location = useLocation()

  if (location.pathname === '/search') {
    return null
  }

  return (
    <ChakraLink
      as={ReactRouterLink}
      to={'/search'}
      maxW={'40rem'}
      width='100%'
      style={{ textDecoration: 'none' }}
      _hover={{
        textDecoration: 'none',
      }}
    >
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <TbSearchIcon color='gray.300' />
        </InputLeftElement>
        <Input
          borderRadius='40px'
          border={'transparent'}
          bgColor={'beigeLight.500'}
          width={'100%'}
          fontSize='16'
          placeholder={t('search.text_search_bar')!}
          readOnly={true}
          _hover={{ cursor: 'pointer' }}
        />
      </InputGroup>
    </ChakraLink>
  )
}

export default SearchInputField
