import { Flex } from '@chakra-ui/react'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import { ContentHeader } from '../../ContentHeader'
import withAuth from '../../../../utils/WithAuth'
import { MainRole } from '../../../../generated/graphql'
import { t } from 'i18next'

const SalePage = () => {
  return <PartnerPortalPage pageHeaderLabel={t('partner_portal.merchant.sale.page_header_sale') as string}>
    
  </PartnerPortalPage>
}

export default withAuth(SalePage, [MainRole.RoleGuest])
