import { useEffect } from 'react'
import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { CustomerServiceDesktop } from './Desktop/CustomerServiceDesktop'

export const CustomerService = () => {
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  return (
    <MainPageResponsive justify={'center'} mx={'auto'} loading={false}>
      <CustomerServiceDesktop />
    </MainPageResponsive>
  )
}
