import { Flex, Image, Box, LinkOverlay, LinkBox } from '@chakra-ui/react'
import { findImagesByGender } from '../../../../utils/GoogleCloudBucketUtil'

const SubCategoryDisplay = ({ gender, hasDragged }: any) => {
  const imagesByGender = findImagesByGender(gender)
  const handleHrefClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (hasDragged) {
      e.preventDefault()
    }
  }
  return (
    <Flex justifyContent='center' mb={4} gap={'1rem'}>
      {Object.keys(imagesByGender).map((key) => {
        const { imageUrl, url, label, heading } = imagesByGender[key]

        return (
          <LinkBox key={key}>
            <LinkOverlay href={url} isExternal={false} onClick={handleHrefClick}>
              <Flex borderRadius={'1.5rem'} align='center' justify='center' position='relative'>
                {imageUrl ? (
                  <Image
                    borderRadius={'1.5rem'}
                    src={imageUrl}
                    width={'auto'}
                    minW={'250px'}
                    objectFit='cover' 
                  />
                ) : (
                  <Box width='100%' height='100%' borderRadius={'1.5rem'} bg='gray.200' />
                )}

                <Box
                  position='absolute'
                  top='50%'
                  left='50%'
                  transform='translate(-50%, -50%)'
                  color='white'
                  fontSize={'1.5em'}
                  zIndex={1}
                  textAlign='center'
                  whiteSpace='nowrap'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  maxWidth='100%'
                >
                  {heading}
                  <br />
                  {typeof label === 'function' ? label() : label}
                </Box>
              </Flex>
            </LinkOverlay>
          </LinkBox>
        )
      })}
    </Flex>
  )
}

export default SubCategoryDisplay
