import { Flex, Img, Link, Text, useMediaQuery } from '@chakra-ui/react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { ampli } from '../../../ampli'
import Logo from '../../../assets/svg/Plenty&Svg/Plenty_Logo_Black.svg'
import { Theme } from '../../../ThemeVariables'
import { HeaderIconsDesktop } from '../HeaderIcons/desktop/HeaderIconsDesktop'
import { HeaderIconsMobile } from '../HeaderIcons/mobile/HeaderIconsMobile'
import { useCTFilter } from '../../../context/CTFilterContext'
import SearchInputField from '../../../pages/main_app/search_page/components/SearchInputField'
import { DropDownMenu } from './DropDownMenu'
type HeaderProps = {
  headerWithPill: boolean
}

export const NewHeader = (props: HeaderProps) => {
  const navigate = useNavigate()
  const { resetFilters } = useCTFilter()
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <Flex direction={'column'} backgroundColor={'white'}>
      {isLargerThan768 ? (
        <Flex w='full' justifyContent='space-between' px={Theme.PaddingOnSides.Header}>
          <Flex flex={4} alignItems='center' gap={'2.5rem'} position='relative' zIndex={3}>
            <Img h={7} src={Logo} _hover={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
            <DropDownMenu />
            <Text>
              <Link
                href={'/store-map'}
                onClick={() => {
                  ampli.menuItemClick({ item: 'Stores' })
                  resetFilters()
                }}
                cursor={'pointer'}
              >
                {t('global.title_stores')}
              </Link>
            </Text>
            <Flex flexGrow={1} flexShrink={1} alignItems='center'>
              <SearchInputField />
            </Flex>
          </Flex>
          <Flex flex={1} gap={6} justifyContent={'flex-end'}>
            <HeaderIconsDesktop />
          </Flex>
        </Flex>
      ) : (
        // mobile view
        <Flex pb={'0.5rem'} boxShadow={'lg'} direction={'column'} px={Theme.PaddingOnSides.Header}>
          <Flex py={Theme.spacing.padding.mobile} alignItems={'center'} justifyContent={'space-between'}>
            <Img h={7} src={Logo} onClick={() => navigate('/')} />
            <HeaderIconsMobile />
          </Flex>
          <Flex flexGrow={1} flexShrink={1} alignItems='center'>
            <SearchInputField />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
