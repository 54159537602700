import { Button, Flex, Heading, HStack, Icon, useMediaQuery, Text, LinkOverlay, LinkBox, useBreakpointValue } from '@chakra-ui/react'
import { t } from 'i18next'
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { TbArrowLeft, TbArrowRight } from 'react-icons/tb'
import { PlentyProduct, ProductCardProps } from '../../../pages/main_app/PLP/types'
import { Store } from '@commercetools/platform-sdk'
import React from 'react'
type HeadingType = ReactNode | { base: string; md: string }
const defaultBreakpointValue = { base: '', md: '' }

const useConditionalBreakpointValue = (value: HeadingType) => {
  const mergedValue = { ...defaultBreakpointValue, ...(typeof value === 'object' ? value : {}) }
  return useBreakpointValue(mergedValue as Record<string, string>)
}
interface PlentySliderProps {
  children: ReactNode
  fontSize: string
  heading?: HeadingType

  viewAll?: boolean
  title?: string
  store?: Store
  navigateToProduct?: (product?: PlentyProduct) => void
  navigateToViewAll?: () => void
}
export const PlentySlider = ({ children, fontSize, heading, viewAll, title, store, navigateToProduct, navigateToViewAll }: PlentySliderProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const breakpointValue = useConditionalBreakpointValue(heading)
  const resolvedHeading = typeof heading === 'object' ? breakpointValue : heading
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isAtStart, setIsAtStart] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const [startX, setStartX] = useState(0)
  const [dragleft, setdragleft] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [hasDragged, setHasDragged] = useState(false)

  const resetHasDragged = () => {
    setTimeout(() => setHasDragged(false), 50)
  }

  const onMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true)
    setStartX(e.pageX - scrollRef.current!.offsetLeft)
    setdragleft(scrollRef.current!.scrollLeft)
    setHasDragged(false) // Reset the flag
  }

  const onMouseLeave = () => {
    setIsDragging(false)
  }

  const onMouseUp = () => {
    setIsDragging(false)
    setTimeout(() => {
      resetHasDragged()
    }, 10)
  }

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return
    e.preventDefault()
    const x = e.pageX - scrollRef.current!.offsetLeft
    const walk = x - startX

    if (Math.abs(walk) < 5) return

    scrollRef.current!.scrollLeft = dragleft - walk
    setHasDragged(true)
  }
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement<ProductCardProps & { hasDragged: boolean }>(child)) {
      return React.cloneElement(child, { hasDragged })
    }
    return child
  })

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ top: 0, left: -400, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ top: 0, left: 400, behavior: 'smooth' })
    }
  }
  const checkOverflow = () => {
    if (scrollRef.current) {
      const target = scrollRef.current
      const isOverflow = target.scrollWidth > target.clientWidth

      setIsOverflowing(isOverflow)
    }
  }

  useLayoutEffect(() => {
    checkOverflow()
  }, [children])

  useEffect(() => {
    checkOverflow()
    const handleResize = () => {
      checkOverflow()
    }
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      {resolvedHeading && (
        <Flex zIndex={1} alignItems={'center'} pb={{ base: '1rem', md: '1.5rem' }} justifyContent={'space-between'}>
          <Flex flex={3}>
            <Heading variant={'headingForComponentOnAMainPage'}>{resolvedHeading}</Heading>
          </Flex>
          {viewAll && (
            <LinkBox as={Flex} alignItems={'flex-start'} textAlign={'end'} onClick={navigateToViewAll}>
              <LinkOverlay
                as={Text}
                textDecoration={'underline'}
                textUnderlineOffset={8}
                textDecorationColor={'mintGreen.500'}
                textDecorationThickness={'3px'}
                flexGrow={1}
                fontWeight={'normal'}
                fontSize={'lg'}
                cursor={'pointer'}
              >
                {t('global.text_view_all')}
              </LinkOverlay>
            </LinkBox>
          )}
        </Flex>
      )}
      <Flex zIndex={1} position='relative' width={'100%'}>
        {isLargerThan768 && (
          <Button
            backgroundColor='white'
            position='absolute'
            left='0px'
            top='40%'
            width='20px'
            borderRadius='full'
            transform='translateY(-40%)'
            zIndex={2}
            onClick={scrollLeft}
            style={{ opacity: isAtStart ? 0.5 : 1, display: isOverflowing ? 'flex' : 'none' }}
            _hover={{ backgroundColor: '#D3D3CA' }}
            _active={{ backgroundColor: 'white' }}
            _focus={{ backgroundColor: 'white' }}
          >
            <Icon as={TbArrowLeft} color='black' boxSize={7} />
          </Button>
        )}
        <HStack
          ref={scrollRef}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          overflowX={'auto'}
          overflowY={'hidden'}
          w='full'
          maxWidth='100%'
          css={{ '&::-webkit-scrollbar': { display: 'none' } }}
          gap={4}
          onScroll={(e) => {
            checkOverflow()
            const target = e.target as HTMLElement
            setIsAtStart(target.scrollLeft === 0)
            setIsAtEnd(Math.abs(target.scrollLeft + target.clientWidth - target.scrollWidth) < 5)
          }}
        >
          {childrenWithProps}
        </HStack>

        {isLargerThan768 && (
          <Button
            backgroundColor={'white'}
            position={'absolute'}
            right={'0px'}
            top={'40%'}
            width={'20px'}
            borderRadius={'full'}
            transform={'translateY(-40%)'}
            zIndex={2}
            _hover={{ backgroundColor: '#D3D3CA' }}
            _active={{ backgroundColor: 'white' }}
            _focus={{ backgroundColor: 'white' }}
            onClick={scrollRight}
            style={{ opacity: isAtEnd ? 0.5 : 1, display: isOverflowing ? 'flex' : 'none' }}
          >
            <Icon as={TbArrowRight} color='black' boxSize={7} />
          </Button>
        )}
      </Flex>
    </>
  )
}
