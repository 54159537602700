import {
  Divider,
  Flex,
  Link,
  HStack,
  Icon,
  IconButton,
  Img,
  Text,
  useMediaQuery,
  VStack,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Accordion,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { TbArrowNarrowUp, TbBrandFacebook, TbBrandInstagram, TbBrandLinkedin, TbBrandPinterest, TbChevronDown } from 'react-icons/tb'
import { Link as RouterLink } from 'react-router-dom'
import { Theme } from '../../../ThemeVariables'
import { getPaymentImageUrls } from '../../../utils/GoogleCloudBucketUtil'
export const NewFooter = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const imageUrls = getPaymentImageUrls()
  const renderAccordionItem = (title: any, links: any) => {
    return (
      <AccordionItem>
        <h2>
          <AccordionButton px={0}>
            <Box flex='1' textAlign='left' fontSize={'24px'}>
              {title}
            </Box>
            <TbChevronDown /> {/* Adjust boxSize to your preference */}
          </AccordionButton>
        </h2>
        <AccordionPanel px={'0'}>
          {links.map((link: any, index: any) => (
            <Link key={index} href={link.path} _hover={{ color: '#434343', cursor: 'pointer' }} marginBottom='0.5rem' display='block'>
              <Flex>
                {/* Check if label is present, otherwise use titleKey */}
                <Flex minWidth='2.5rem'>{link.label ? link.label : t(link.titleKey)}</Flex> {/* Adjust minWidth as needed */}
                {/* Display ageKey if present */}
                {link.ageKey && <Flex>{t(link.ageKey)}</Flex>}
              </Flex>
            </Link>
          ))}
        </AccordionPanel>
      </AccordionItem>
    )
  }
  const FooterDesktop = () => (
    <>
      <Flex direction={'column'} maxWidth={'100vw'}>
        <Flex backgroundColor={'beigeLight.500'} pt={'4.5rem'} pb={'3.5rem'} px={Theme.PaddingOnSides.Footer} direction={'column'}>
          <Flex>
            <Flex flex={99} justifyContent={'space-between'}>
              <VStack alignItems={'flex-start'}>
                <Flex fontSize={'24px'}>Plenty&</Flex>
                <Link href='/about' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('components.layout.text_about')}
                </Link>
                <Link href='/customer_service' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('components.layout.text_faq')}
                </Link>
                <Link href='/return/login' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('components.layout.text_return_items')}
                </Link>
                <Link href='/store-map' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('components.layout.text_stores')}
                </Link>
              </VStack>
              <VStack alignItems={'flex-start'}>
                <Flex fontSize={'24px'}>{t('global.title_women')}</Flex>
                <Link href='/products/women/clothing' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('global.title_clothing')}
                </Link>
                <Link href='/products/women/footwear' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('global.title_footwear')}
                </Link>
                <Link href='/products/women/outerwear' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('global.title_outerwear')}
                </Link>
                <Link href='/products/women/accessories' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('global.title_accessories')}
                </Link>
              </VStack>
              <VStack alignItems={'flex-start'}>
                <Flex fontSize={'24px'}>{t('global.title_men')}</Flex>
                <Link href='/products/men/clothing' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('global.title_clothing')}
                </Link>
                <Link href='/products/men/footwear' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('global.title_footwear')}
                </Link>
                <Link href='/products/men/outerwear' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('global.title_outerwear')}
                </Link>
                <Link href='/products/men/accessories' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  {t('global.title_accessories')}
                </Link>
              </VStack>

              <VStack alignItems={'flex-start'}>
                <Flex fontSize={'24px'}>{t('global.title_kids')}</Flex>
                <Link href='/products/kids/baby_0_2' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  <Flex>
                    <Flex width='4rem'>{t('global.title_baby')}</Flex>
                    <Flex>{t('global.age_baby')}</Flex>
                  </Flex>
                </Link>
                <Link href='/products/kids/girl_2_8' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  <Flex>
                    <Flex width='4rem'>{t('global.title_girls')}</Flex>
                    <Flex>{t('global.age_young_kid')}</Flex>
                  </Flex>
                </Link>
                <Link href='/products/kids/boy_2_8' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  <Flex>
                    <Flex width='4rem'>{t('global.title_boys')}</Flex>
                    <Flex>{t('global.age_young_kid')}</Flex>
                  </Flex>
                </Link>
                <Link href='/products/kids/girl_9_16' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  <Flex>
                    <Flex width='4rem'>{t('global.title_girls')}</Flex>
                    <Flex>{t('global.age_old_kid')}</Flex>
                  </Flex>
                </Link>
                <Link href='/products/kids/boy_9_16' _hover={{ color: '#434343', cursor: 'pointer' }}>
                  <Flex>
                    <Flex width='4rem'>{t('global.title_boy')}</Flex>
                    <Flex>{t('global.age_old_kid')}</Flex>
                  </Flex>
                </Link>
              </VStack>
              <VStack alignItems={'flex-start'}>
                <Flex fontSize={'24px'}>{t('global.text_contact')}</Flex>
                <Flex> Plenty&</Flex>

                <Flex> {t('global.title_address')}</Flex>
                <Flex> support@plentyand.dk</Flex>
                <Flex> +4599421094</Flex>
                <HStack>
                  <Icon
                    as={TbBrandFacebook}
                    _hover={{ color: '#434343', cursor: 'Pointer' }}
                    boxSize={'1.5rem'}
                    fill={'black'}
                    onClick={() => openInNewTab('https://www.facebook.com/profile.php?id=100089802061958')}
                  />
                  <Icon
                    as={TbBrandInstagram}
                    _hover={{ color: '#434343', cursor: 'Pointer' }}
                    boxSize={'1.5rem'}
                    onClick={() => openInNewTab('https://www.instagram.com/plentyand_/')}
                  />
                  <Icon
                    as={TbBrandLinkedin}
                    _hover={{ color: '#434343', cursor: 'Pointer' }}
                    boxSize={'1.5rem'}
                    onClick={() => openInNewTab('https://www.linkedin.com/company/plentyand/')}
                  />
                  <Icon
                    as={TbBrandPinterest}
                    _hover={{ color: '#434343', cursor: 'Pointer' }}
                    boxSize={'1.5rem'}
                    onClick={() => openInNewTab('https://www.pinterest.dk/plentyand/')}
                  />
                </HStack>

                <HStack pt={'4rem'} gap={'0.5rem'}>
                  {imageUrls.map((url) => (
                    <Img key={url} width={'65px'} height={'40px'} src={url} />
                  ))}
                </HStack>
              </VStack>
            </Flex>
            <Flex flex={1} justifyContent={'flex-end'}>
              <IconButton w={12} h={12} onClick={handleScrollToTop} aria-label={'Scroll to top'}>
                <TbArrowNarrowUp size={26} />
              </IconButton>
            </Flex>
          </Flex>
        </Flex>

        <Flex backgroundColor={'white'} py={'1rem'} px={Theme.PaddingOnSides.Footer} gap={Theme.gap.desktop} justifyContent={'space-between'}>
          <Flex gap={Theme.gap.desktop}>
            <Flex>Plenty&</Flex>
            <Flex>{t('components.layout.text_right_reserved')}</Flex>
          </Flex>
          <Flex gap={Theme.gap.desktop}>
            <Link as={RouterLink} to={'/politics/terms_of_use_policy'}>
              <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_terms')}</Text>
            </Link>
            <Divider borderColor='black' opacity={1} orientation='vertical' height={'1.5rem'} />
            <Link as={RouterLink} to={'/politics/privacy_policy'}>
              {' '}
              <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_privacy')}</Text>
            </Link>
            <Divider borderColor='black' opacity={1} height={'1.5rem'} orientation='vertical' />

            <Link as={RouterLink} to={'/politics/cookie_policy'}>
              <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_cookies')}</Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  )

  const FooterMobile = () => (
    <>
      <Flex direction={'column'} maxWidth={'100vw'}>
        <Flex backgroundColor={'beigeLight.500'} py={Theme.spacing.padding.desktop} px={Theme.PaddingOnSides.Footer} direction={'column'}>
          <Flex>
            <Flex direction={'column'} width={'100%'} justifyContent={'space-between'}>
              <Accordion allowToggle>
                {renderAccordionItem('Plenty&', [
                  { label: t('components.layout.text_about'), path: '/about' },
                  { label: t('components.layout.text_faq'), path: '/customer_service' },
                  { label: t('components.layout.text_return_items'), path: '/return/login' },
                  { label: t('components.layout.text_stores'), path: '/store-map' },
                ])}
                {renderAccordionItem(t('global.title_women'), [
                  { label: t('global.title_clothing'), path: '/products/women/clothing' },
                  { label: t('global.title_footwear'), path: '/products/women/footwear' },
                  { label: t('global.title_outerwear'), path: '/products/women/outerwear' },
                  { label: t('global.title_accessories'), path: '/products/women/accessories' },
                ])}
                {renderAccordionItem(t('global.title_men'), [
                  { label: t('global.title_clothing'), path: '/products/men/clothing' },
                  { label: t('global.title_footwear'), path: '/products/men/footwear' },
                  { label: t('global.title_outerwear'), path: '/products/men/outerwear' },
                  { label: t('global.title_accessories'), path: '/products/men/accessories' },
                ])}
                {renderAccordionItem(t('global.title_kids'), [
                  { titleKey: 'global.title_baby', ageKey: 'global.age_baby', path: '/products/kids/baby_0_2' },
                  { titleKey: 'global.title_girl', ageKey: 'global.age_young_kid', path: '/products/kids/girl_2_8' },
                  { titleKey: 'global.title_boy', ageKey: 'global.age_young_kid', path: '/products/kids/boy_2_8' },
                  { titleKey: 'global.title_girl', ageKey: 'global.age_old_kid', path: '/products/kids/girl_9_16' },
                  { titleKey: 'global.title_boy', ageKey: 'global.age_old_kid', path: '/products/kids/boy_9_16' },
                ])}
              </Accordion>
              <VStack mt={'2rem'} alignItems={'flex-start'}>
                <Flex fontSize={'24px'}>{t('global.text_contact')}</Flex>
                <Flex> Plenty&</Flex>

                <Flex> {t('global.title_address')}</Flex>
                <Flex> support@plentyand.dk</Flex>
                <Flex> +4599421094</Flex>
                <HStack pt={'2rem'}>
                  <Icon
                    as={TbBrandFacebook}
                    _hover={{ color: '#434343', cursor: 'Pointer' }}
                    boxSize={'1.5rem'}
                    fill={'black'}
                    onClick={() => openInNewTab('https://www.facebook.com/profile.php?id=100089802061958')}
                  />
                  <Icon
                    as={TbBrandInstagram}
                    _hover={{ color: '#434343', cursor: 'Pointer' }}
                    boxSize={'1.5rem'}
                    onClick={() => openInNewTab('https://www.instagram.com/plentyand_/')}
                  />
                  <Icon
                    as={TbBrandLinkedin}
                    _hover={{ color: '#434343', cursor: 'Pointer' }}
                    boxSize={'1.5rem'}
                    onClick={() => openInNewTab('https://www.linkedin.com/company/plentyand/')}
                  />
                  <Icon
                    as={TbBrandPinterest}
                    _hover={{ color: '#434343', cursor: 'Pointer' }}
                    boxSize={'1.5rem'}
                    onClick={() => openInNewTab('https://www.pinterest.dk/plentyand/')}
                  />
                </HStack>
                <HStack overflowX='auto' pt={'2rem'} gap={'0.5rem'}>
                  {imageUrls.map((url) => (
                    <Img key={url} width={'65px'} height={'40px'} src={url} />
                  ))}
                </HStack>
              </VStack>
            </Flex>
            {/* <Flex flex={1} justifyContent={'flex-end'}>
              <IconButton w={12} h={12} onClick={handleScrollToTop} aria-label={'Scroll to top'}>
                <TbArrowNarrowUp size={26} />
              </IconButton>
            </Flex> */}
          </Flex>
        </Flex>

        <Flex backgroundColor={'white'} p={'1rem'} direction={'column'} gap={Theme.gap.desktop} justifyContent={'space-between'}>
          <Flex gap={Theme.gap.desktop} flexWrap='wrap'>
            <Flex>Plenty&</Flex>
            <Flex>{t('components.layout.text_right_reserved')}</Flex>
          </Flex>
          <Flex
            gap={Theme.gap.desktop}
            flexWrap='nowrap' 
            overflowX='auto'
          >
            <Link as={RouterLink} to={'/politics/terms_of_use_policy'}>
              <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_terms')}</Text>
            </Link>
            <Divider borderColor='black' opacity={1} orientation='vertical' height={'1.5rem'} />
            <Link as={RouterLink} to={'/politics/privacy_policy'}>
              {' '}
              <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_privacy')}</Text>
            </Link>
            <Divider borderColor='black' opacity={1} height={'1.5rem'} orientation='vertical' />

            <Link as={RouterLink} to={'/politics/cookie_policy'}>
              <Text _hover={{ color: '#434343', cursor: 'Pointer' }}>{t('components.layout.link_cookies')}</Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  )

  return <>{isLargerThan768 ? <FooterDesktop /> : <FooterMobile />}</>
}
