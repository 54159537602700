import { Box, HStack, Icon, IconButton, useDisclosure } from '@chakra-ui/react'
import { TbMenu2 } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { MapPin, ShoppingBag } from 'tabler-icons-react'
import { BurgerMenu } from '../../../BurgerMenu/BurgerMenu'
import { useCart } from '../../../../context/CheckoutCartContext'

export const HeaderIconsMobile = () => {
  const { toggleCartDrawer, calculateTotalLineItems } = useCart()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  return (
    <>
      <HStack spacing={0}>
   
        <IconButton
          style={{ marginInlineStart: '0', minWidth: '0' }}
          size={'sm'}
          aria-label='button'
          variant={'ghost'}
          icon={
            <Box position='relative'>
              <Icon w={'2rem'} h={'2rem'} strokeWidth={'1.5'} as={MapPin} />
            </Box>
          }
          onClick={() => navigate('/store-map')}
        />

        <IconButton
          size={'sm'}
          aria-label='button'
          variant={'ghost'}
          icon={
            <Box position='relative'>
              <Icon w={8} h={8} strokeWidth={'1.5'} as={ShoppingBag} />
              {calculateTotalLineItems() > 0 && (
                <Box
                  position='absolute'
                  top='-8px'
                  right='-8px'
                  bg='mintGreen.500'
                  borderRadius='full'
                  width='24px'
                  height='24px'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <span style={{ fontSize: '14px' }}>{calculateTotalLineItems()}</span>
                </Box>
              )}
            </Box>
          }
          onClick={toggleCartDrawer}
        />
        <IconButton size={'sm'} aria-label='button' variant={'ghost'} icon={<Icon w={6} h={6} as={TbMenu2} />} onClick={onOpen} />
      </HStack>
      <BurgerMenu isOpen={isOpen} onClose={onClose} />
    </>
  )
}
