import { Flex, Icon, Img, Tag, Text, useBreakpointValue } from '@chakra-ui/react'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import { ErrorBox } from '../../../../components/ErrorBox'
import { BundleDiscount, ProductInfo } from '../../../../generated/graphql'
import { findCircleText } from '../../../../utils/GoogleCloudBucketUtil'
import {
  getAvailableInOtherStoresAmount,
  getCheapestPriceNotFromChannelFormatted,
  getFirstAvailbleOptionByStore,
  getPriceByChannel,
  getStoreByStoreKey,
  storeHasOptionInStock,
} from '../../../../utils/PDPUtil'
import { PlentyVariant } from '../../PLP/types'
import { PlentyStore } from '../types'
import { UnderlineText } from './UnderlineText'
import { VariantPrice } from './VariantPrice'

type StoreSelectorProps = {
  filteredStores: PlentyStore[]
  filteredSizes: PlentyVariant[]
  selectedStore: PlentyStore
  selectedOption: PlentyVariant
  storeFromURL: string | null
  setSelectedStore: (store: PlentyStore) => void
  setSelectedOption: (value: PlentyVariant) => void
  bundleDiscounts: BundleDiscount[]
}

export const StoreSelector = (props: StoreSelectorProps) => {
  const [showStoresCollapse, setShowStoresCollapse] = useState(false)
  const [otherStoresAmount, setOtherStoresAmount] = useState(getAvailableInOtherStoresAmount(props.filteredStores || [], props.selectedOption))
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false })

  if (!props.selectedOption) {
    return <ErrorBox message='No option selected' />
  }

  if (!props.selectedStore) {
    return <ErrorBox message='No store selected' />
  }

  if (props.storeFromURL) {
    const storeByUrl = getStoreByStoreKey(props.filteredStores, props.storeFromURL)

    return (
      <Flex w={'100%'} h={'92px'} borderRadius={'56px'} bgColor={'beigeLight.400'} px={6} py={4} justifyContent={'space-between'} alignItems={'center'}>
        <Flex alignItems={'center'} gap={2}>
          <Img src={findCircleText(props.selectedStore?.key || '')} borderRadius={'full'} boxSize='60px' mr={3} />
          <Flex direction={'column'}>
            <Text fontSize={'lg'}>
              <Trans i18nKey='main_app.pdp.text_stores_sell_this_from' values={{ storeName: storeByUrl ? storeByUrl.name : 'No stores' }} />
            </Text>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  const BundleTag = (props: { bundle: BundleDiscount | null }) => {
    if (props.bundle?.bundle.__typename == 'QuantityBundle') {
      return (
        <Tag color={'darkGreen.500'} colorScheme={'green'}>
          Buy {props.bundle.bundle.discountedQuantity} get {props.bundle.bundle.triggerQuantity}
        </Tag>
      )
    } else if (props.bundle?.bundle.__typename == 'PriceBundle') {
      return (
        <Tag color={'darkGreen.500'} colorScheme={'green'}>
          Buy {props.bundle.bundle.triggerQuantity} for {props.bundle.bundle.totalPriceAfterDiscount} Kr
        </Tag>
      )
    } else {
      return <></>
    }
  }

  const OtherStoresText = () => {
    if (otherStoresAmount == 0) {
      return <Text fontSize={isMobile ? 'xs' : 'sm'}>No other stores in Aarhus sell this size</Text>
    } else {
      return (
        <Text fontSize={isMobile ? 'xs' : 'sm'}>
          {otherStoresAmount} other {otherStoresAmount === 1 ? 'store' : 'stores'} in Aarhus sell this size {otherStoresAmount > 0 ? 'from' : ''}
        </Text>
      )
    }
  }

  return (
    <Flex cursor={'pointer'} w={'full'} position={'absolute'} direction={'column'} onMouseLeave={() => setShowStoresCollapse(false)}>
      <Flex
        h={isMobile ? '76px' : '92px'}
        boxShadow={showStoresCollapse ? 'md' : 'none'}
        borderTopLeftRadius={showStoresCollapse ? (isMobile ? '36px' : '45px') : '56px'}
        borderTopRightRadius={showStoresCollapse ? (isMobile ? '36px' : '45px') : '56px'}
        borderBottomLeftRadius={showStoresCollapse ? 'none' : '56px'}
        borderBottomRightRadius={showStoresCollapse ? 'none' : '56px'}
        onClick={() => setShowStoresCollapse(!showStoresCollapse)}
        bgColor={'beigeLight.400'}
        px={6}
        py={4}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex alignItems={'center'} gap={2}>
          <Img hidden={isMobile} src={findCircleText(props.selectedStore?.key || '')} borderRadius={'full'} boxSize='60px' mr={3} />
          <Flex direction={'column'}>
            <Flex fontSize={'xl'} alignItems={'center'} gap={2}>
              <Text fontSize={isMobile ? '0.875rem' : 'xl'} fontWeight={isMobile ? 'bold' : 'normal'}>
                <Trans i18nKey='main_app.pdp.text_stores_sell_this_from' values={{ storeName: props.selectedStore?.name }} />
              </Text>
              <Text hidden={isMobile}>·</Text>
              {props.selectedStore?.channelId && props.selectedOption && getPriceByChannel(props.selectedOption, props.selectedStore.channelId) ? (
                !isMobile && <VariantPrice channelId={props.selectedStore?.channelId} variant={props.selectedOption} />
              ) : (
                <Text fontStyle={'italic'}>Size not available in this store</Text>
              )}
            </Flex>
            <Flex gap={1} alignItems={'center'}>
              <OtherStoresText />
              <UnderlineText fontSize={'sm'} text={getCheapestPriceNotFromChannelFormatted(props.selectedOption, props.selectedStore?.channelId) as string} />
            </Flex>
          </Flex>
        </Flex>
        <Icon as={showStoresCollapse ? TbChevronUp : TbChevronDown} />
      </Flex>

      <Flex
        boxShadow={showStoresCollapse ? 'md' : 'none'}
        borderBottomLeftRadius={showStoresCollapse ? (isMobile ? '36px' : '45px') : '56px'}
        borderBottomRightRadius={showStoresCollapse ? (isMobile ? '36px' : '45px') : '56px'}
        direction={'column'}
        bgColor={'white'}
        gap={6}
        hidden={!showStoresCollapse}
        maxH={'500px'}
        overflowY={'auto'}
      >
        <Flex direction={'column'}>
          {props.filteredStores.map((store: PlentyStore, index: number) => {
            const sizeAmount = props.filteredStores?.length || 0
            const currentSize = index + 1

            const bundle = props.bundleDiscounts.find((bundleDiscount: BundleDiscount) => bundleDiscount.channelKey == store.distributionChannel.key)
            const hasBundleDiscount = bundle && !(bundle?.entityInfo as ProductInfo).excludedSkus.includes(props.selectedOption.sku)

            return (
              <Flex
                bgColor={store.channelId == props.selectedStore?.channelId ? 'beigeLight.400' : 'white.500'}
                onClick={() => {
                  setShowStoresCollapse(false)
                  const currentlySelectedOption = props.selectedOption

                  if (!storeHasOptionInStock(store, currentlySelectedOption)) {
                    const firstAvailableOptionByStore = getFirstAvailbleOptionByStore(store, props.filteredSizes)
                    props.setSelectedOption(firstAvailableOptionByStore)
                  }

                  props.setSelectedStore(store)
                }}
                px={isMobile ? 6 : 12}
                py={3}
                _hover={{ bgColor: 'beigeLight.500' }}
                key={store.id}
                gap={3}
                alignItems={'center'}
                borderBottomColor={'beigeLight.500!important'}
                borderBottom={sizeAmount > currentSize ? '1px' : '0px'}
                justifyContent={'space-between'}
              >
                <Flex gap={3} alignItems={'center'}>
                  <Text fontWeight={isMobile ? 'normal' : 'bold'} fontSize={isMobile ? '0.75rem' : 'md'}>{store.name}</Text>
                  <Text
                    hidden={isMobile}
                  >{`${store.distributionChannel.address.streetName} ${store.distributionChannel.address.streetNumber}, ${store.distributionChannel.address.city}`}</Text>
                  <Text fontSize={isMobile ? '0.75rem' : 'md'}>·</Text>
                  {props.selectedOption && getPriceByChannel(props.selectedOption, store.channelId) ? (
                    <VariantPrice oldPriceFontSize={isMobile ? '0.75rem' : 'md'} livePriceFontSize={isMobile ? '0.75rem' : 'xl'} channelId={store.channelId} variant={props.selectedOption} normalFontWeight />
                  ) : (
                    <Text fontStyle={'italic'} fontSize={isMobile ? '0.75rem' : 'md'}>Size not available in this store</Text>
                  )}
                </Flex>
                {bundle && hasBundleDiscount ? <BundleTag bundle={bundle} /> : null}
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}
