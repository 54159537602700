import { Store } from '@commercetools/platform-sdk'
import { getCategoryByKey, getStoreByKey } from '../../../commercetools/requests'
import { getCheapestVariant } from '../../../utils/PDPUtil'
import { findProductVariantAttributeDefaultEnglish } from '../../../utils/ProductUtil'
import { CategoryProps, ColorFacet, Facet, PlentyProduct, PlentyVariant, ProductVariantAttribute } from './types'

export const mainColorsMapped = [
  { name: 'Black', rgb: '#000000' },
  { name: 'White', rgb: '#FFFFFF' },
  { name: 'Beige', rgb: '#F5F5DC' },
  { name: 'Blue', rgb: '#0000FF' },
  { name: 'Grey', rgb: '#808080' },
  { name: 'Pink', rgb: '#FFC0CB' },
  { name: 'Green', rgb: '#008000' },
  { name: 'Brown', rgb: '#A52A2A' },
  { name: 'Red', rgb: '#FF0000' },
  { name: 'Purple', rgb: '#800080' },
  { name: 'Orange', rgb: '#FFA500' },
  { name: 'Yellow', rgb: '#FFFF00' },
  { name: 'Gold', rgb: '#FFD700' },
  { name: 'Silver', rgb: '#C0C0C0' },
  { name: 'Metallic', rgb: '#C0C0C0' },
]

const specialMapping: { [key: string]: string } = {
  rose: 'pink',
  gold: 'metallic',
  silver: 'metallic',
  turquoise: 'blue',
}

type ColorGrouping = {
  color: string
  variants: PlentyVariant[]
}

export const findUniqueColorsFromVariants = (variants: PlentyVariant[]) => {
  const uniqueColorsSet = new Set<string>()

  variants.forEach((variant) => {
    const colorAttr = variant.attributes.find((attr) => attr.name === 'hex_color')
    if (colorAttr) {
      uniqueColorsSet.add(colorAttr.value['en'])
    }
  })

  return uniqueColorsSet
}

export const uniqueHexColorVariants = (variants: PlentyVariant[], masterVariant: PlentyVariant, channelId?: string): PlentyVariant[] => {
  const colorFromMaster = findProductVariantAttributeDefaultEnglish(masterVariant, 'hex_color')

  const uniqueColors = Array.from(findUniqueColorsFromVariants(variants)).filter((color) => color !== colorFromMaster)
  const colorGroupings: ColorGrouping[] = []

  uniqueColors.forEach((color: string) => {
    colorGroupings.push({
      color: color,
      variants: variants.filter((variant: PlentyVariant) => findProductVariantAttributeDefaultEnglish(variant, 'hex_color') == color),
    })
  })

  return colorGroupings.map((colorGrouping: ColorGrouping) => {
    return getCheapestVariant(colorGrouping.variants, channelId)
  })
}

const isVariantAvailable = (variant: PlentyVariant, storeChannelId?: string): boolean => {
  const availability = variant.availability
  if (storeChannelId) {
    const isChannelStockAvailable = availability?.channels ? availability.channels[storeChannelId]?.isOnStock : false
    return isChannelStockAvailable || false
  }
  const isChannelStockAvailable = availability?.channels ? Object.values(availability.channels).some((channel) => channel.isOnStock) : false
  return isChannelStockAvailable
}

//TODO: This function could be better, instead of replacing master we can swap the variants, unify logic to handle colors and availability
export const formatPlentyProduct = (data: any, colors: string[] = [], channelId?: string): PlentyProduct[] => {
  return data.map((product: any) => {
    // Default to the existing masterVariant
    const masterVariant = product.masterVariant

    // Look for a variant that matches one of the provided hex codes
    if (colors.length > 0) {
      const matchingVariants = product.variants.filter((variant: PlentyVariant) => {
        const color = findProductVariantAttributeDefaultEnglish(variant, 'main_color')

        const isChannelStockAvailable = isVariantAvailable(variant, channelId)
        return colors.includes(color) && isChannelStockAvailable
      })

      const cheapestMatchingVariant = getCheapestVariant(matchingVariants, channelId)

      // If a matching variant is found, set it as the masterVariant
      if (cheapestMatchingVariant) {
        return {
          id: product.id,
          key: product.key,
          name: product.name,
          masterVariant: cheapestMatchingVariant,
          variants: product.variants,
        }
      }
    } else {
      const matchingVariants = product.variants.filter((variant: PlentyVariant) => {
        const isChannelStockAvailable = isVariantAvailable(variant, channelId)
        return isChannelStockAvailable
      })

      const cheapestMatchingVariant = getCheapestVariant(matchingVariants, channelId)

      // If a matching variant is found, set it as the masterVariant
      if (cheapestMatchingVariant) {
        return {
          id: product.id,
          key: product.key,
          name: product.name,
          masterVariant: cheapestMatchingVariant,
          variants: product.variants,
        }
      }
    }

    return {
      id: product.id,
      key: product.key,
      name: product.name,
      masterVariant,
      variants: product.variants,
    }
  })
}

const shuffleArray = (array: PlentyProduct[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}
export const getSameProductsDifferentVariants = (plentyProducts: PlentyProduct[], channelId?: string): PlentyProduct[] => {
  const similarVariants: Array<PlentyProduct> = []
  // Shuffle the array
  // const shuffledProducts = shuffleArray([...plentyProducts])
  // Take the first 20 elements
  const randomProducts = plentyProducts.slice(0, 80)
  for (let i = 0; i < randomProducts.length; i++) {
    const product = randomProducts[i]
    // Filter out variants that are not available
    const availableVariants = product.variants.filter((v) => isVariantAvailable(v, channelId))
    const uniqueVariants = uniqueHexColorVariants(availableVariants, product.masterVariant, channelId)
    if (uniqueVariants.length !== 0) {
      const newPlentyProduct = {
        id: product.id,
        key: product.key,
        name: product.name,
        masterVariant: uniqueVariants[0],
        variants: [],
      }
      similarVariants.push(newPlentyProduct)
    }
    if (similarVariants.length === 20) {
      break
    }
  }
  return similarVariants
}

// Assuming getCategoryByName returns a promise that resolves to the category data from commercetools
export const getCategoryWithIdAndName = async (name: string): Promise<CategoryProps> => {
  // Your fetch logic here
  const result = await getCategoryByKey(name)
  return { id: result?.id, name: { en: result?.name.en, 'da-DK': result?.name['da-DK'] } }
}

export const convertPathToKey = (path: string) => {
  const mainPath = path.split('?')[0]

  // Function to convert specific words into a corresponding string
  const convertSpecialTerms = (term: string) => {
    switch (term.toLowerCase()) {
      case 'boy 2 8':
        return 'boy_2_8'
      case 'girl 2 8':
        return 'girl_2_8'
      case 'boy 9 16':
        return 'boy_9_16'
      case 'girl 9 16':
        return 'girl_9_16'
      case 'baby 0 2':
        return 'baby_0_2'
      default:
        return term
    }
  }

  // First, perform your usual replacement
  let formattedPath = mainPath.replace(/\//g, '-').replace(/\s/g, '').replace(/&/g, 'and')

  // Then, loop over each of your special terms to see if they exist in the path
  ;['boy 2 8', 'girl 2 8', 'boy 9 16', 'girl 9 16', 'baby 0 2'].forEach((term) => {
    const convertedTerm = convertSpecialTerms(term)
    const regex = new RegExp(term.replace(/ /g, ''), 'gi') // Remove spaces to match your original regex
    formattedPath = formattedPath.replace(regex, convertedTerm)
  })

  return formattedPath
}

export const mapToPredefinedColors = (existingColors: Facet[], predefinedColors: { name: string; rgb: string }[]): ColorFacet[] => {
  const colorMap: { [key: string]: { count: number; options: string[] } } = {}

  // Aggregate the counts and options for each mapped color term
  existingColors.forEach((color) => {
    const normalizedTerm = color.term.toLowerCase()
    const mappedTerm = specialMapping[normalizedTerm] || normalizedTerm

    if (predefinedColors.some((predefined) => predefined.name.toLowerCase() === mappedTerm)) {
      colorMap[mappedTerm] = colorMap[mappedTerm] || { count: 0, options: [] }
      colorMap[mappedTerm].count += color.count
      colorMap[mappedTerm].options.push(color.term) // Add the original term as an option
    }
  })

  // Map them to the predefined color objects
  return Object.entries(colorMap).map(([term, { count, options }]) => {
    const predefinedColor = predefinedColors.find((predefined) => predefined.name.toLowerCase() === term)

    return {
      term: predefinedColor?.name || term, // Using the predefined format if exists
      count,
      color: predefinedColor?.rgb || '',
      options: options.sort(), // Sort for better readability
    }
  })
}

export const getChannelIdByStoreKey = (storeKey: string) => {
  return getStoreByKey(storeKey)
    .then((store: Store | undefined) => store?.distributionChannels[0])
    .catch((error) => {
      // Handle any errors here
      console.error(error)
      throw error // Optionally re-throw the error
    })
}
