import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import { PlentySlider } from '../../../../components/layout/PlentySlider/PlentySlider'
import i18n from '../../../../I18n/i18n'
import SubCategoryDisplay from './SubCategoryDisplay'

const headingMap = {
  women: 'main_app.category_card.heading.0',
  men: 'main_app.category_card.heading.1',
  kids: 'main_app.category_card.heading.2',
}

type Gender = keyof typeof headingMap

const CategoryCard = () => {
  const { gender: rawGender } = useParams<{ gender: string }>()

  const gender = (Object.prototype.hasOwnProperty.call(headingMap, rawGender || '') ? rawGender : 'women') as Gender

  const selectedLanguage = localStorage.getItem('languageSelected') ?? i18n.language
  const langKey = selectedLanguage === 'DK' ? 'da-DK' : 'en'
  const headingKey = headingMap[gender] 

  return (
    <PlentySlider
      fontSize='3xl'
      heading={t(headingKey)} 
    >
      <SubCategoryDisplay langKey={langKey} gender={gender} />
    </PlentySlider>
  )
}

export default CategoryCard
