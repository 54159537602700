import { Button, Flex, SimpleGrid, Stack, Text, Icon } from '@chakra-ui/react'
import { t } from 'i18next'
import { ArrowLeft } from 'tabler-icons-react'
import { useReturn } from '../../../../context/OrderReturnContext'
import { ReturnOrderItemCard } from '../components/ReturnOrderItemCard'

export const ReturnReason = () => {
  const { order, returnItemListWithQuantity, currentItemIndex, setReturnReason, setCurrentItemIndex, advanceStep, previousStep } = useReturn()

  if (order == null) return <Text>Null</Text>

  const itemsToReturn = returnItemListWithQuantity.filter((item) => item.quantity >= 1)

  const handleContinue = (reason: string | null = null) => {
    if (reason) {
      setReturnReason(itemsToReturn[currentItemIndex], reason)
    }

    let newIndex = currentItemIndex + 1
    while (newIndex < itemsToReturn.length && itemsToReturn[newIndex].quantity < 1) {
      newIndex += 1
    }

    if (newIndex < itemsToReturn.length) {
      setCurrentItemIndex(newIndex)
    } else {
      advanceStep()
    }
  }

  const returnReasons = [
    { id: 0, text: t('main_app.return.components.steps.return_reasons.0') },
    { id: 1, text: t('main_app.return.components.steps.return_reasons.1') },
    { id: 2, text: t('main_app.return.components.steps.return_reasons.2') },
    { id: 3, text: t('main_app.return.components.steps.return_reasons.3') },
    { id: 4, text: t('main_app.return.components.steps.return_reasons.4') },
    { id: 5, text: t('main_app.return.components.steps.return_reasons.5') },
    { id: 6, text: t('main_app.return.components.steps.return_reasons.6') },
    { id: 7, text: t('main_app.return.components.steps.return_reasons.7') },
    // Add more reasons in i18nd
  ]

  const initialWidth = window.innerWidth
  const isLargerThan768 = initialWidth >= 768
  return (
    <Flex gap={4} mx={isLargerThan768 ? 'auto' : 4} direction={'column'} w={isLargerThan768 ? '25%' : '100%'}>
      <Flex gap={4} mx='auto' direction='column' p={4} w='full'>
        <Flex gap={4} alignItems={'center'}>
          <Icon
            bgColor={'transparent'}
            onClick={() => {
              previousStep()
            }}
            fontSize={'30px'}
            color={'black'}
            as={ArrowLeft}
          />
          <Text fontSize={'2xl'} fontWeight={'500'}>
            {t('main_app.return.components.steps.text_order_number', { orderNumber: order?.orderNumber })}
          </Text>
        </Flex>
        <Flex>{t('main_app.return.components.steps.text_why_return')} </Flex>
        <Flex justifyContent={'flex-end'}>
          {t('main_app.return.components.steps.amount_returning', {
            currentItemIndex: currentItemIndex + 1,
            itemsToReturn: itemsToReturn.length,
          })}
        </Flex>
        <Stack>
          <>
            <ReturnOrderItemCard key={currentItemIndex} returnOrderLineItem={itemsToReturn[currentItemIndex]} />

            <SimpleGrid columns={2} spacing={2} width={'full'}>
              {returnReasons.map((reason) => (
                <Button
                  key={reason.id}
                  boxShadow={'lg'}
                  alignItems={'center'}
                  w={'full'}
                  backgroundColor={'white'}
                  color={'black'}
                  borderRadius={'3xl'}
                  h={'auto'}
                  _hover={{ backgroundColor: '#D3D3CA' }}
                  onClick={() => {
                    handleContinue(reason.text)
                  }}
                >
                  <Text p={4} m={4}>
                    {reason.text}
                  </Text>
                </Button>
              ))}
            </SimpleGrid>
          </>
        </Stack>
      </Flex>
    </Flex>
  )
}
