/* eslint-disable @typescript-eslint/no-extra-semi */
import { Flex, Heading, Spinner, Stack } from '@chakra-ui/react'
import { useState } from 'react'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import { MainRole } from '../../../../generated/graphql'
import withAuth from '../../../../utils/WithAuth'
import { t } from 'i18next'
import { ReturnCard } from './components/ReturnCard'
import { useUser } from '../../../../context/Auth'
import { LineItem, LineItemReturnItem, Order, ReturnInfo } from '@commercetools/platform-sdk'
import { useFetchOrders } from './components/FetchOrders'

const MerchantReturnsPage = () => {
  const [orderFilterSelected, setOrderFilterSelected] = useState('All')
  const { distributionChannelKey } = useUser()
  const { orders, isLoading, error } = useFetchOrders(distributionChannelKey as string)

  const returnInfoBelongsToDistributionChannel = (returnInfo: ReturnInfo, lineItems: Array<LineItem>) => {
    return returnInfo.items.some((item) => {
      const lineItemReturnItem = item as LineItemReturnItem

      const lineItem = lineItems.find((lineItem) => lineItem.id === lineItemReturnItem.lineItemId) ?? null

      if (!lineItem) return false

      return lineItem.distributionChannel?.obj?.key === distributionChannelKey
    })
  }

  const getAllOrdersAmountByFilter = () => {
    return orders.length || 0
  }

  const NoOrders = () => {
    if (getAllOrdersAmountByFilter() == 0 && orderFilterSelected !== 'All') {
      return <Heading color={'blackAlpha.500'}>{t('partner_portal.merchant.orders.heading_no_orders')}</Heading>
    } else {
      return <></>
    }
  }
  return (
    <PartnerPortalPage pageHeaderLabel={'Returns'}>
      <Stack spacing={10}>
        <Flex direction={'column'}>
          <NoOrders />
          <Stack spacing={10}>
            {isLoading && <Spinner />}

            {orders.map((order: Order) => {
              return order.returnInfo?.map((returnInfo: ReturnInfo) => {
                if (!returnInfoBelongsToDistributionChannel(returnInfo, order.lineItems)) {
                  return <></>
                }
                return (
                  <ReturnCard
                    key={returnInfo.returnDate as string}
                    orderNumber={order.orderNumber}
                    returnItems={returnInfo.items as LineItemReturnItem[]}
                    lineItems={order.lineItems}
                    shippingKey={returnInfo.returnTrackingId == 'inStore' ? 'InStore' : 'ParcelShopDelivery'}
                    createdAt={returnInfo.returnDate?.toString() as string}
                    customerInfo={order.billingAddress}
                    customerEmail={order.customerEmail ?? ''}
                  />
                )
              })
            })}
          </Stack>
        </Flex>
      </Stack>
    </PartnerPortalPage>
  )
}

export default withAuth(MerchantReturnsPage, [MainRole.RoleAdmin])
