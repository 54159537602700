import { Button, Checkbox, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, HStack, Input, ModalFooter } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as Yup from 'yup'
import { useDiscountCreation } from '../../../../../../context/DiscountCreationContext';
import { SummaryBar } from '../../SummaryBar';

const BulkCampaignSchema = Yup.object().shape({
    campaign_name: Yup.string().required('A name for the campaign is required!'),
  })

export const CreateBulkDiscountCampaignStep = (props: { wizard: any }) => {
    const discountCreation = useDiscountCreation()
  
    const [state, setState] = useState({
      selection: {
        startDate: discountCreation.validFrom,
        endDate: discountCreation.validTo,
        key: 'selection',
      },
    })
  
    const updateEndDate = (newEndDate: any) => {
      setState((prevState) => ({
        ...prevState, // Spread the previous state
        selection: {
          ...prevState.selection, // Spread the previous selection object
          endDate: newEndDate, // Update the endDate property
        },
      }))
      discountCreation.setValidTo(null)
    }
  
    return (
      <Formik
        initialValues={{
          campaign_name: discountCreation.campaignName,
        }}
        validationSchema={BulkCampaignSchema}
        onSubmit={async () => {
          props.wizard.advanceStep()
        }}
      >
        {({ errors, touched, setFieldValue, handleSubmit, isValid }) => (
        <Flex direction={'column'} position={'relative'}>
            <SummaryBar
            info={[
                discountCreation.campaignName,
                discountCreation.validFrom.toDateString() + ' to ' + (discountCreation.validTo ? discountCreation.validTo.toDateString() : 'continuous'),
            ]}
            />
            <HStack p={6} alignItems={'flex-start'} justifyContent={'center'}>
              <FormControl flex={1} isInvalid={errors.campaign_name != undefined && touched.campaign_name}>
                <FormLabel fontSize={'2xl'} fontWeight={'bold'}>
                  Campaign name
                </FormLabel>
                <Field
                  as={Input}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    discountCreation.setCampaignName(e.target.value)
                    setFieldValue('campaign_name', e.target.value)
                  }}
                  name='campaign_name'
                  w={'400px'}
                  placeholder={'E.g. Summer collection, Staff picks'}
                  variant={'rounded'}
                />
                <FormHelperText>The name of the campaign can be seen by customers.</FormHelperText>
                {errors.campaign_name && touched.campaign_name ? <FormErrorMessage>{errors.campaign_name}</FormErrorMessage> : null}
              </FormControl>
  
              <Flex direction={'column'} flex={1} gap={6}>
                <DateRangePicker
                  onChange={(item: any) => {
                    setState({ ...state, ...item })
                    discountCreation.setValidFrom(item.selection.startDate)
                    discountCreation.setValidTo(item.selection.endDate)
                  }}
                  months={1}
                  minDate={new Date()}
                  direction='vertical'
                  ranges={[state.selection as any]}
                  staticRanges={[]}
                  inputRanges={[]}
                  rangeColors={['#76f8aa3d']}
                />
  
                <Checkbox onChange={(e: any) => updateEndDate(null)} isChecked={state.selection.endDate == null} variant={'baseStyle'}>
                  No end date. Will end when discount is deleted.
                </Checkbox>
              </Flex>
            </HStack>
  
            <ModalFooter borderTop={'1px'} borderTopColor={'beigeLight.500'}>
              <Button isDisabled={!props.wizard.canReduce} colorScheme='blue' mr={3} onClick={() => props.wizard.reduceStep()}>
                Previous
              </Button>
              <Button 
              isDisabled={!props.wizard.canAdvance || !isValid}
              colorScheme='blue'
              mr={3}
              onClick={() => handleSubmit()}>
                Next
              </Button>
            </ModalFooter>
          </Flex>
        )}
      </Formik>
    )
}