import React from 'react'
import { Box, Skeleton, SkeletonText, Tabs, TabList, Grid, Flex } from '@chakra-ui/react'

// Skeleton for "Current Category" header
const CurrentCategorySkeleton = () => <Skeleton height='30px' width='200px' my={4} />

// Skeleton for category tabs
const CategoryTabsSkeleton = () => (
  <Tabs mb={4}>
    <TabList>
      <Skeleton height='30px' width={{ base: '70px', md: '100px' }} mx='5px' />
      <Skeleton height='30px' width={{ base: '70px', md: '100px' }} mx='5px' />
      <Skeleton height='30px' width={{ base: '70px', md: '100px' }} mx='5px' />
      <Skeleton height='30px' width={{ base: '70px', md: '100px' }} mx='5px' />
    </TabList>
  </Tabs>
)

// Skeleton for a single product card
const ProductCardSkeleton = () => (
  <Box padding={2} boxShadow='md' bg='white' width='100%' height='100%'>
    <Skeleton height='70%' width='100%' />
    <SkeletonText mt='4' noOfLines={3} spacing='4' />
  </Box>
)

// Modified Skeleton for the main content with product cards
const MainContentSkeleton = () => (
  <Grid mt={{ base: 2, md: 10 }} templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }} gap={6} height='75vh'>
    <ProductCardSkeleton />
    <ProductCardSkeleton />
    <ProductCardSkeleton />
    <ProductCardSkeleton />
    <ProductCardSkeleton />
    <ProductCardSkeleton />
    <ProductCardSkeleton />
    <ProductCardSkeleton />
  </Grid>
)

const PageSkeleton = () => (
  <Box w='100%' px='5%'>
    <Flex direction={'column'} alignItems={'center'}>
      <CurrentCategorySkeleton />
      <CategoryTabsSkeleton />
    </Flex>
    <MainContentSkeleton />
  </Box>
)

export default PageSkeleton
