import { split } from 'lodash'
import { Dispatch, SetStateAction } from 'react'
import { ExcelToCSVConverter } from './ExcelUtil'

interface ErrorReturnType {
  errorState: boolean
  errorMessage?: string
}

export async function CSVReadFile(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target?.result) {
        const data: string = event.target.result as string
        resolve(data)
      } else {
        reject(new Error('Failed to read file'))
      }
    }

    reader.onerror = (event) => {
      reject(new Error('Error reading file: ' + (event.target?.error?.message || 'unknown error')))
    }

    reader.readAsText(file)
  })
}

export function CSVReadLine(csvLine: string): string[] {
  if (csvLine.includes(';')) return split(csvLine, ';')
  return split(csvLine, ',')
}

export function parseCsvToArray(csv: string): string[] {
  const rows = split(csv, '\n')
  const cleanedRows = rows.map((row) => row.trim()) // Removes trailing newlines
  return cleanedRows
}

export async function validateCSVContent(file: File, setErrorMessage: Dispatch<SetStateAction<string>>): Promise<ErrorReturnType> {
  let csvFile = null
  let correctContent = true

  if (split(file.name, '.')[1] == 'xlsx') {
    //Convert excel file to csv
    csvFile = await ExcelToCSVConverter(file)
  } else {
    csvFile = await CSVReadFile(file)
  }

  if (csvFile) {
    const csvRows = parseCsvToArray(csvFile)
    let headerLabels: string[] | null = null

    csvRows.forEach((row, rowIndex) => {
      if (!correctContent) return
      if (rowIndex == 0) headerLabels = CSVReadLine(row)
      const fields = CSVReadLine(row)
      let rowHasValidDiscount = false

      fields.forEach((field, columnIndex) => {
        if (!correctContent) return
        if (headerLabels) {
          // The field has the wrong input
          const validationError = fieldContainsCorrectValueType(field, headerLabels[columnIndex])
          if (!validationError.errorState) {
            setErrorMessage(
              'File is formatted incorrectly: Error at column ' +
                columnIndex +
                ' {Header: ' +
                headerLabels[columnIndex] +
                '}; at row ' +
                rowIndex +
                ' {Value: ' +
                field +
                '}. ' +
                validationError.errorMessage,
            )
            console.error(
              'File is formatted incorrectly: Error at column ' +
                columnIndex +
                ' {Header: ' +
                headerLabels[columnIndex] +
                '}; at row ' +
                rowIndex +
                ' {Value: ' +
                field +
                '}. ' +
                validationError.errorMessage,
            )
            correctContent = false
          }

          // If this field has a valid discount, but there is already a valid discount recorded
          if (fieldHasDiscount(field, headerLabels[columnIndex]).errorState && rowHasValidDiscount) {
            setErrorMessage('File is formatted incorrectly: Error at row ' + rowIndex + '. Row contains multiple discounts.')
            console.error('File is formatted incorrectly: Error at row ' + rowIndex + '. Row contains multiple discounts.')
            correctContent = false
          } else if (fieldHasDiscount(field, headerLabels[columnIndex]).errorState) {
            rowHasValidDiscount = true
          }
        }
      })

      // There is no discount for the item
      if (!rowHasValidDiscount && rowIndex > 0 && correctContent) {
        setErrorMessage('File is formatted incorrectly: Error at row ' + rowIndex + '. Row does not contain a discount.')
        console.error('File is formatted incorrectly: Error at row ' + rowIndex + '. Row does not contain a discount.')
        correctContent = false
      }
    })
  } else {
    correctContent = false
  }

  return { errorState: correctContent }
}

export function fieldContainsCorrectValueType(field: string, column: string): ErrorReturnType {
  switch (column) {
    case 'style_name': {
      // Style name of the product
      // Is a non-empty string or
      // Is the header
      if (field.length > 0) return { errorState: true }
      return { errorState: false, errorMessage: 'Style Name is not valid.' }
    }
    case 'style_number': {
      // Style number of the product
      // Is a number or
      // Is the header
      if (!isNaN(Number(field)) || field == column) return { errorState: true }
      return { errorState: false, errorMessage: 'Style Number is not valid.' }
    }
    case 'percentage': {
      // Percentile discount if any
      // Is empty or
      // Is a number that is between 0 and 100 or
      // Is the header
      if (Number(field) == 0 || (!isNaN(Number(field)) && Number(field) > 0 && Number(field) < 96) || field == column) return { errorState: true }
      return { errorState: false, errorMessage: 'Percentage value is not valid.' }
    }
    case 'absolute': {
      // Absolute discount if any
      // Is empty or
      // Is a number above 0
      // Is the header
      if (Number(field) == 0 || (!isNaN(Number(field)) && Number(field) > 0) || field == column) return { errorState: true }
      return { errorState: false, errorMessage: 'Absolute value is not valid.' }
    }
    case 'bundle': {
      // Bundle discount if any
      //
      // TODO: Implement the bundle check properly
      //

      // Is empty or
      // Is a number above 0
      // Is the header
      if (field == '' || (!isNaN(Number(field)) && Number(field) > 0) || field == column) return { errorState: true }
      return { errorState: false, errorMessage: 'Bundle value is not valid.' }
    }
    case 'stores': {
      // Applicable stores id any
      // Is a non-empty string or
      // Is the header
      if (field.length > 0 || field == column) return { errorState: true }
      return { errorState: false, errorMessage: 'Store value is not valid.' }
    }
    default: {
      return { errorState: false, errorMessage: 'No matching header.' }
    }
  }
}

export function fieldHasDiscount(field: string, column: string): ErrorReturnType {
  switch (column) {
    case 'percentage': {
      // Between 0 and 95
      if (!isNaN(Number(field)) && Number(field) > 0 && Number(field) < 96) return { errorState: true }
      return { errorState: false }
    }
    case 'absolute': {
      // Has a non-zero value
      if (!isNaN(Number(field)) && Number(field) > 0) return { errorState: true }
      return { errorState: false }
    }
    default:
      return { errorState: false }
  }
}
