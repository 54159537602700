import { Flex, Heading, Grid, useMediaQuery } from '@chakra-ui/react'
import { GenderCategoryCard } from '../GenderCategoryCard'
import { t } from 'i18next'
interface GenderCategoryCardRowsProps {
  homePageText?: boolean
  paddingY?: any
}

export const GenderCategoryCardRows = ({ homePageText, paddingY }: GenderCategoryCardRowsProps) => {
  const categories = [
    { name: t('components.category_cards.women'), navParam: 'women' },
    { name: t('components.category_cards.men'), navParam: 'men' },
    { name: t('components.category_cards.kids'), navParam: 'kids' },
  ]
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <Flex py={{ base: '2rem', sm: '2rem', md: '7.125rem' }} direction={'column'} alignItems={'center'} gap={{ base: '1rem', md: '2rem' }}>
      <Flex zIndex={1} maxWidth={"80%"} direction={'column'} justifyContent={'start'} alignItems={'flex-start'} textAlign={'start'} alignSelf={'start'} width={'100%'}>
        {homePageText ? (
          <Heading variant={'headingForComponentOnAMainPage'}>{t('main_app.homepage.heading_cloth_category')}</Heading>
        ) : (
          <Heading variant={'headingForComponentOnAMainPage'}>{t('components.gender_category_card.heading_category_card')}</Heading>
        )}
      </Flex>
      <Grid templateColumns={isLargerThan768 ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'} gap={4} maxW='100%' maxH={'100%'}>
        {categories.map(({ name, navParam }) => (
          <GenderCategoryCard key={name} width={'auto'} label={name} borderRadius={'1.5rem'} to={'/landing-page/' + navParam} category={name} fontSize='xl' />
        ))}
      </Grid>
    </Flex>
  )
}
