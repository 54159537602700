import { Flex, HStack, StackDivider, Text } from "@chakra-ui/react"

type SummaryBarProps = {
    info: string[]
}

export const SummaryBar = (props: SummaryBarProps) => {
  return (
    <Flex position={'absolute'} alignSelf={'center'} top={'-20px'} gap={6} w={'fit-content'} bgColor={'beigeLight.300'} py={2} px={6} borderRadius={18}>
      <HStack spacing={3} divider={<StackDivider borderColor={'gray.200'} />}>
        {props.info.map((value: string, index: number) => {
            return <Text fontSize={'sm'} key={index}>{value}</Text>
        })}
      </HStack>
    </Flex>
  )
}
