import { Flex, Heading, Input, SimpleGrid, Stack } from '@chakra-ui/react'
import { t } from 'i18next'
import moment from 'moment'
import { useState } from 'react'
import { TbActivity } from 'react-icons/tb'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import { MainRole, useGetAnalyticsQuery } from '../../../../generated/graphql'
import { Theme } from '../../../../ThemeVariables'
import withAuth from '../../../../utils/WithAuth'
import { StatCard } from '../landing_page/components/StatCard'

const MerchantAnalyticsPage = () => {
  const [dateFrom, setDateFrom] = useState(moment().subtract(7, 'days'))
  const [dateTo, setDateTo] = useState(moment())

  const analytics = useGetAnalyticsQuery({
    variables: {
      fromDate: dateFrom.format('yyyy-MM-DD'),
      toDate: dateTo.format('yyyy-MM-DD'),
    },
  })

  const RenderDateRange = () => {
    return (
      <Flex justifyContent={'space-between'}>
        <Flex gap={5} alignItems={'center'}>
          <Input
            variant={'solidWhite'}
            onChange={(e: any) => {
              setDateFrom(moment(e.target.value))
            }}
            value={dateFrom.format('yyyy-MM-DD')}
            type={'date'}
          />
          -
          <Input variant={'solidWhite'} value={dateTo.format('yyyy-MM-DD')} type={'date'} />
        </Flex>
      </Flex>
    )
  }

  return (
    <PartnerPortalPage pageHeaderLabel={t('partner_portal.merchant.analytic.page_header_analytic') as string} rightHeaderContent={<RenderDateRange />}>
      <Stack spacing={10}>
        <Heading size={'md'}>{t('partner_portal.merchant.analytic.heading_sales')}</Heading>
        <SimpleGrid columns={3} spacing={10}>
          <StatCard
            textPlacement='Right'
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={TbActivity}
            action={() => {
              console.log(1)
            }}
            loading={analytics.loading}
            title={t('partner_portal.merchant.analytic.title_sales_before_cancel')}
            value={analytics.data?.getAnalytics?.salesBeforeCancellation + ' DKK'}
          />
          <StatCard
            textPlacement='Right'
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={TbActivity}
            action={() => {
              console.log(1)
            }}
            loading={analytics.loading}
            title={t('partner_portal.merchant.analytic.title_sales_after_cancel')}
            value={analytics.data?.getAnalytics?.salesAfterCancellation + ' DKK'}
          />
          <StatCard
            textPlacement='Right'
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={TbActivity}
            action={() => {
              console.log(1)
            }}
            loading={analytics.loading}
            title={t('partner_portal.merchant.analytic.title_returns')}
            value={analytics.data?.getAnalytics?.returns}
          />
          <StatCard
            textPlacement='Right'
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={TbActivity}
            action={() => {
              console.log(1)
            }}
            loading={analytics.loading}
            title={t('partner_portal.merchant.analytic.title_average_basket')}
            value={analytics.data?.getAnalytics?.averageBasketValue + ' DKK'}
          />
          <StatCard
            textPlacement='Right'
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={TbActivity}
            action={() => {
              console.log(1)
            }}
            loading={analytics.loading}
            title={t('partner_portal.merchant.analytic.title_net_sales')}
            value={analytics.data?.getAnalytics?.netSales + ' DKK'}
          />
        </SimpleGrid>

        <Heading size={'md'}>{t('partner_portal.merchant.analytic.heading_health')}</Heading>
        <SimpleGrid columns={3} spacing={10}>
          <StatCard
            textPlacement='Right'
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={TbActivity}
            action={() => {
              console.log(1)
            }}
            loading={analytics.loading}
            title={t('partner_portal.merchant.analytic.title_order_fulfilment')}
            value={analytics.data?.getAnalytics?.averageOrderFullfillmentTime}
          />
          <StatCard
            textPlacement='Right'
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={TbActivity}
            action={() => {
              console.log(1)
            }}
            loading={analytics.loading}
            title={t('partner_portal.merchant.analytic.title_cancel_rate')}
            value={10}
          />
        </SimpleGrid>
      </Stack>
    </PartnerPortalPage>
  )
}

export default withAuth(MerchantAnalyticsPage, [MainRole.RoleGuest])
