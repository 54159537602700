import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Flex,
  HStack,
  Icon,
  IconButton,
  Stack,
  StackDivider,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { LineItem } from '@commercetools/platform-sdk'
import { IconBan, IconCheck, IconChevronDown, IconChevronUp, IconExclamationCircle, IconPdf, IconTruckDelivery, IconUser } from '@tabler/icons-react'
import { t } from 'i18next'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import client from '../../../../../apollo-client'
import { useUser } from '../../../../../context/Auth'
import { Maybe, useGetCustomerByIdQuery } from '../../../../../generated/graphql'
import { GET_SHIPMENT_LABEL } from '../../../../../gql/logistics'
import { centPriceToPrice, moneyFormat } from '../../../../../utils/MoneyUtil'
import PdfModal from '../../../../../utils/PdfUtil'
import { findVariantAttributeDefaultEnglish } from '../../../../../utils/ProductUtil'
import { CustomerInfoModal } from '../../orders_page/components/CustomerInfoModal'
import { calculateTotalPrice, formattetHoursSinceOrder } from '../utils'
import { DeliveryTag } from './OrderDeliveryTag'
import { OrderLineItemCard } from './OrderLineItemCard'
import { OrderLineItemsModal } from './OrderLineItemsModal'
import { OrderStateTag } from './OrderStateTag'

type OrderCardProps = {
  orderNumber?: string
  lineItems?: LineItem[]
  orderState?: string
  shippingKey?: string
  createdAt?: string
  customerId?: string
}

export const OrderCard = ({ orderNumber, shippingKey, lineItems, orderState, createdAt, customerId }: OrderCardProps) => {
  const [expanded, setExpanded] = useState(false)
  const [customerModalOpen, setCustomerModalOpen] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { distributionChannelKey } = useUser()
  const [accceptedModal, setAcceptedModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorShipping, setErrorShipping] = useState(false)

  const [base64, setBase64] = useState<any | null>(null)
  const [isPdfModalOpen, setIsPdfModalOpen] = useState<boolean>(false)

  const customer = useGetCustomerByIdQuery({
    variables: {
      id: customerId,
    },
  })

  useEffect(() => {
    if (lineItems) {
      setErrorShipping(
        lineItems.filter((lineItem) => lineItem.state[0].state.obj?.key === 'lineitem_return_requested').length > 0 && orderState === 'Confirmed',
      )
    }
  }, [lineItems])

  const getLabel = async () => {
    setIsLoading(true)
    const result = await client.query({
      query: GET_SHIPMENT_LABEL,
      variables: {
        orderNumber: orderNumber,
        distributionChannelId: distributionChannelKey,
      },
    })

    setBase64(result.data.getShipmentLabel)

    setIsPdfModalOpen(true)
    setIsLoading(false)
  }
  const hiddenByOrderStates = (states: string[]) => {
    if (orderState && states.includes(orderState)) {
      return true
    } else {
      return false
    }
  }

  const disabledByOrderStates = (states: string[]) => {
    if (orderState && states.includes(orderState)) {
      return true
    } else {
      return false
    }
  }

  const isMyOrderDone = () => {
    return lineItems?.every((lineItem) => {
      return lineItem?.state[0].state.obj?.key === 'lineitem_waiting_completion'
    })
  }

  const orderAction = async (state: string) => {
    if (lineItems) {
      const url = `${process.env.REACT_APP_ORDERS_API_URL}/state/order/${orderNumber}?transitionToStateKey=${state}`

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        const responseData = await response.json()
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }

  const confirmOrder = async (accepted: boolean) => {
    setAcceptedModal(accepted)
    onOpen()
  }

  const SplitOrderHeaderContent = () => {
    return (
      <Flex>
        <HStack spacing={14}>
          <Stack direction={'row'} alignItems={'center'}>
            <Icon as={IconExclamationCircle} color={'mintGreen.700'} />
            <Text alignItems={'center'}>Split order</Text>
          </Stack>
          {lineItems?.map((value: LineItem, index: number) => {
            return (
              <Checkbox
                isChecked={value.state[0].state.obj?.key === 'lineitem_confirmed' || value.state[0].state.obj?.key === 'lineitem_completed'}
                variant={'baseStyle'}
                key={index}
              >
                {value.distributionChannel?.obj?.name?.en} {value.state[0].state.obj?.key === 'lineitem_completed' && '(Completed)'}
              </Checkbox>
            )
          })}
        </HStack>
      </Flex>
    )
  }

  const OrderHeaderContent = () => {
    return (
      <Flex flex={1} justifyContent={'space-between'} alignItems={'center'}>
        <HStack spacing={14}>
          <OrderStateTag orderState={orderState} />
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_order')}</Text>
            <Text>{orderNumber || <i>{t('partner_portal.merchant.orders.components.text_no_order_number')}</i>}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_date')}</Text>
            <Text>{moment(createdAt).format('LL')}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_total')}</Text>
            {/*// TODO: Hardcoded currency*/}
            <Text>{moneyFormat(centPriceToPrice(calculateTotalPrice(lineItems ?? []) || 0, 2), 'DKK', 'da-DK')}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_time')}</Text>
            {createdAt && (
              <Text>
                <Tag>{formattetHoursSinceOrder(createdAt)}</Tag>
              </Text>
            )}
          </Flex>
          <Flex direction={'column'}>
            <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_delivery')}</Text>
            <DeliveryTag shippingKey={shippingKey} />
          </Flex>
        </HStack>

        <IconButton
          onClick={() => setExpanded(!expanded)}
          variant={'ghost'}
          aria-label='expand'
          icon={<Icon as={expanded ? IconChevronUp : IconChevronDown} />}
        />
      </Flex>
    )
  }

  return (
    <>
      <OrderLineItemsModal orderNumber={orderNumber} isOpen={isOpen} onClose={onClose} lineItems={lineItems ?? []} acceptedModal={accceptedModal} />
      <Card>
        <CardHeader borderBottom={'1px solid'} borderColor={'blackAlpha.200'}>
          <Stack gap={2}>
            <OrderHeaderContent />
          </Stack>
        </CardHeader>
        <CardBody hidden={!expanded}>
          <Flex hidden={!expanded} justifyContent={'space-between'} p={6} bgColor={'white'}>
            <Stack wrap={'wrap'} gap={5} divider={<StackDivider />}>
              {lineItems?.map((value: Maybe<LineItem>) => (
                <OrderLineItemCard
                  key={value?.id}
                  color={findVariantAttributeDefaultEnglish(value?.variant ?? null, 'color')}
                  size={findVariantAttributeDefaultEnglish(value?.variant ?? null, 'size')}
                  name={value?.name.en}
                  styleNumber={value?.productSlug?.en}
                  quantity={value?.quantity}
                  ean={value?.variant.sku}
                  imageUrl={value?.variant.images?.[0].url}
                  price={value?.price.value.centAmount}
                />
              ))}
            </Stack>
            <Flex alignItems={'flex-start'}>
              <HStack spacing={3}>
                <Button
                  leftIcon={<Icon as={IconCheck} />}
                  variant={disabledByOrderStates(['Confirmed']) ? 'outlineGrey' : 'outlineGreen'}
                  isDisabled={disabledByOrderStates(['Confirmed'])}
                  hidden={hiddenByOrderStates(['Complete', 'Cancelled'])}
                  onClick={() => confirmOrder(true)}
                >
                  {t('components.button.accept')}
                </Button>
                <Button
                  onClick={() => confirmOrder(false)}
                  leftIcon={<Icon as={IconBan} />}
                  isDisabled={isMyOrderDone() || disabledByOrderStates(['Confirm'])}
                  variant={'outlineRed'}
                  hidden={hiddenByOrderStates(['order_completed', 'order_cancelled', 'order_returned'])}
                >
                  {t('components.button.decline')}
                </Button>
              </HStack>
            </Flex>
          </Flex>
        </CardBody>
        <CardFooter borderTop={expanded ? '1px solid' : ''} borderColor={'blackAlpha.200'} justifyContent={'end'}>
          <HStack spacing={5}>
            <Button hidden={!errorShipping} leftIcon={<Icon as={IconTruckDelivery} />} variant={'solidRed'} size={'lg'}>
              Error with shipping
            </Button>

            <PdfModal base64={base64} isOpen={isPdfModalOpen} onClose={() => setIsPdfModalOpen(false)} />
            <Button
              hidden={hiddenByOrderStates(['order_open', 'order_cancelled', 'order_confirmed']) || shippingKey === 'Pickup'}
              leftIcon={<Icon as={IconPdf} />}
              variant={'ghost'}
              size={'lg'}
              onClick={getLabel}
              isLoading={isLoading}
            >
              {t('components.button.shipping_label')}
            </Button>
            <Button onClick={() => setCustomerModalOpen(true)} leftIcon={<Icon as={IconUser} />} variant={'ghost'} size={'lg'}>
              {t('components.button.customer_information')}
            </Button>
            <Button
              leftIcon={<Icon h={6} w={6} color={'mintGreen.500'} as={IconCheck} />}
              onClick={() => orderAction('order_completed')}
              isDisabled={disabledByOrderStates(['order_open']) || isLoading || isMyOrderDone() || errorShipping}
              isLoading={isLoading}
              variant={'solid'}
              size={'lg'}
              hidden={hiddenByOrderStates(['order_completed', 'order_cancelled', 'order_returned', 'null'])}
            >
              {shippingKey !== 'Pickup' ? t('components.button.print.3') : 'Ready for pickup'}
            </Button>
            <PdfModal base64={base64} isOpen={isPdfModalOpen} onClose={() => setIsPdfModalOpen(false)} />
          </HStack>
        </CardFooter>
      </Card>

      <CustomerInfoModal customerEmail={''} customerInfo={customer} isOpen={customerModalOpen} setOpen={setCustomerModalOpen} />
    </>
  )
}
