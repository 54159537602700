import { useState } from 'react'
import { Step } from '../components/PlentyUI/wizard/Step'

export const useWizard = (steps: Step[]) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0)

  const canAdvance = steps.length > activeStepIndex + 1

  const advanceStep = () => {
    if (canAdvance) {
      setActiveStepIndex(activeStepIndex + 1)
    }
  }

  const canReduce = activeStepIndex > 0

  const reduceStep = () => {
    if (canReduce) {
      setActiveStepIndex(activeStepIndex - 1)
    }
  }

  const reduceStepTwice = () => {
    if (canReduce) {
      setActiveStepIndex(activeStepIndex - 2)
    }
  }

  const activeStep: Step = steps[activeStepIndex]

  return { steps, activeStep, canAdvance, advanceStep, canReduce, reduceStep, reduceStepTwice, setActiveStepIndex }
}
