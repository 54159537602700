import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex } from "@chakra-ui/react"

type ErrorBoxProps = {
    message: string
}

export const ErrorBox = (props: ErrorBoxProps) => {
    return(
        <Flex w={'full'}>
          <Alert
            w={'full'}
            status='error'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              Error
            </AlertTitle>
            <AlertDescription maxWidth='sm'>{props.message}</AlertDescription>
          </Alert>
        </Flex>
    )
} 