import { Flex, Img, Link } from '@chakra-ui/react'
import { IconArrowBack, IconDiscount2, IconHome, IconPackage, IconTag } from '@tabler/icons-react'
import { t } from 'i18next'
import { NavLink } from 'react-router-dom'
import Logo from '../../../../assets/svg/Plenty&Svg/Plenty_Logo_Black.svg'
import { MenuItem } from './MenuItem'

export const SideNav = () => {
  return (
    <Flex
      boxShadow={'lg'}
      flexShrink={0}
      direction={'column'}
      w={'275px'}
      h={'100vh'}
      bgColor={'white.500'}
      borderTopRightRadius={30}
      borderBottomRightRadius={30}
      px={5}
      justifyContent={'space-between'}
    >
      <Flex direction={'column'}>
        <NavLink to={'/merchant'}>
          <Flex alignItems={'center'} justifyContent={'center'} h={'36'} w={'full'}>
            <Img w={'60%'} src={Logo} />
          </Flex>
        </NavLink>

        <Flex direction={'column'} gap={4}>
          <MenuItem icon={IconHome} label={t('components.layout.label_home')} route={'/merchant'} />
          <MenuItem icon={IconPackage} label={t('components.layout.label_orders')} route={'/merchant/orders'} />
          <MenuItem icon={IconArrowBack} label={t('components.layout.label_returns')} route={'/merchant/returns'} />
          <MenuItem icon={IconTag} label={t('components.layout.label_product')} route={'/merchant/products'} />
          <MenuItem icon={IconDiscount2} label={t('components.layout.label_discounts')} route={'/merchant/discounts'} />
          {/* 
          <MenuItem icon={IconChartBar} label={t('components.layout.label_analytic')} route={'/merchant/analytics'} />
          <MenuItem icon={IconThumbUp} label={t('components.layout.label_so_me')} route={'/merchant/some'} />
          <MenuItem icon={IconQuestionCircle} label={t('components.layout.label_support')} route={'/merchant/support'} /> */}
        </Flex>
      </Flex>

      <Flex h={40} direction={'column'} justifyContent={'space-between'} py={8} alignItems={'center'} color={'ActiveBorder'}>
        <Flex direction={'column'} color={'blackAlpha.400'}>
          <Link>{t('components.layout.text_company_agreement')}</Link>
          <Flex>{t('components.layout.text_plenty_year')}</Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
