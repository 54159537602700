import { createContext, ReactElement, useContext, useState } from 'react'
import { EntityInfoType } from '../generated/graphql'
import { BundleDiscountTool } from '../types/BundleDiscountTool'
import { DiscountBundleType } from '../types/enums/DiscountBundleType'
import { PercentageDiscountTool } from '../types/PercentageDiscountTool'
import { TableEntry } from '../types/TableEntry'
import { VariantEntry } from '../types/VariantEntry'

interface DiscountCreationContextProps {
  fileUpload: boolean
  setFileUpload: (fileUploaded: boolean) => void
  uploadComplete: boolean
  setUploadComplete: (uploadComplete: boolean) => void
  uploadConfirmed: boolean
  setUploadConfirmed: (uploadConfirmed: boolean) => void
  discountTools: (PercentageDiscountTool | BundleDiscountTool)[]
  addDiscountTool: (discountTool: PercentageDiscountTool | BundleDiscountTool) => void
  removeDiscountTool: (indexToRemove: number) => void
  campaignName: string
  setCampaignName: (value: string) => void
  percentage: number | undefined
  setPercentage: (value: number | undefined) => void
  triggerQuantity: number | undefined
  setTriggerQuantity: (value: number | undefined) => void
  discountedQuantity: number | undefined
  setDiscountedQuantity: (value: number | undefined) => void
  unit: string
  setUnit: (value: string) => void
  isPercentageDiscount: (input: any) => boolean
  isBundleDiscount: (input: any) => boolean
  validFrom: Date
  setValidFrom: (value: Date) => void
  validTo: Date | null
  setValidTo: (value: Date | null) => void
  tableEntries: TableEntry[]
  setTableEntries: (tableEntries: TableEntry[]) => void
  addTableEntry: (value: TableEntry) => void
  removeTableEntry: (tableEntry: TableEntry) => void
  showVariantByEan: (ean: string) => void
  showAllVariantsByProductKey: (productKey: string) => void
  renderDiscountLabel: (value: PercentageDiscountTool | BundleDiscountTool) => string
  generateCampaignDiscountJSONOutout: (channelKey: string) => any
  resetDiscountCreation: () => any
}

const DiscountCreationContext = createContext<DiscountCreationContextProps>({
  fileUpload: false,
  setFileUpload: () => console.log(),
  uploadComplete: false,
  setUploadComplete: () => console.log(),
  uploadConfirmed: false,
  setUploadConfirmed: () => console.log(),
  discountTools: [],
  addDiscountTool: () => console.log(),
  removeDiscountTool: () => console.log(),
  campaignName: '',
  setCampaignName: () => console.log(),
  percentage: 0,
  setPercentage: () => console.log(),
  triggerQuantity: 0,
  setTriggerQuantity: () => console.log(),
  discountedQuantity: 0,
  setDiscountedQuantity: () => console.log(),
  unit: '',
  setUnit: () => console.log(),
  isPercentageDiscount: () => false,
  isBundleDiscount: () => false,
  validFrom: new Date(),
  setValidFrom: () => console.log(),
  validTo: new Date(),
  setValidTo: () => console.log(),
  tableEntries: [],
  setTableEntries: () => console.log(),
  addTableEntry: () => console.log(),
  removeTableEntry: () => console.log(),
  showVariantByEan: () => console.log(),
  showAllVariantsByProductKey: () => console.log(),
  renderDiscountLabel: () => '',
  generateCampaignDiscountJSONOutout: () => '',
  resetDiscountCreation: () => console.log(),
})

export default function DiscountCreationProvider({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  const [fileUpload, setFileUpload] = useState<boolean>(false)
  const [uploadComplete, setUploadComplete] = useState<boolean>(false)
  const [uploadConfirmed, setUploadConfirmed] = useState<boolean>(false)
  const [discountTools, setDiscountTools] = useState<(PercentageDiscountTool | BundleDiscountTool)[]>([])
  const [campaignName, setCampaignName] = useState<string>('')
  const [percentage, setPercentage] = useState<number | undefined>(undefined)
  const [triggerQuantity, setTriggerQuantity] = useState<number | undefined>(undefined)
  const [discountedQuantity, setDiscountedQuantity] = useState<number | undefined>(undefined)
  const [unit, setUnit] = useState<string>(DiscountBundleType.PCS)
  const [validFrom, setValidFrom] = useState<Date>(new Date())
  const [validTo, setValidTo] = useState<Date | null>(null)
  const [tableEntries, setTableEntries] = useState<TableEntry[]>([])

  const resetDiscountCreation = () => {
    setDiscountTools([])
    setCampaignName('')
    setPercentage(undefined)
    setTriggerQuantity(undefined)
    setDiscountedQuantity(undefined)
    setUnit(DiscountBundleType.PCS)
    setValidFrom(new Date())
    setValidTo(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000))
    setTableEntries([])
  }

  const isPercentageDiscount = (input: any): input is PercentageDiscountTool => {
    return typeof input == 'object' && typeof input.value == 'number'
  }

  const isBundleDiscount = (input: any): input is BundleDiscountTool => {
    return typeof input == 'object' && typeof input.triggerQuantity == 'number' && typeof input.discountedQuantity == 'number'
  }

  const addDiscountTool = (discountTool: PercentageDiscountTool | BundleDiscountTool) => {
    if (isPercentageDiscount(discountTool)) {
      const percentageDiscounts = discountTools.filter((value: any) => isPercentageDiscount(value))

      if (percentageDiscounts.filter((value: any) => value.value == discountTool.value).length == 0) {
        setDiscountTools((oldArray) => [...oldArray, discountTool])
      }
    }

    if (isBundleDiscount(discountTool)) {
      const bundleDiscounts = discountTools.filter((value: any) => isBundleDiscount(value))

      if (
        bundleDiscounts.filter(
          (value: any) =>
            value.discountedQuantity == discountTool.discountedQuantity &&
            value.triggerQuantity == discountTool.triggerQuantity &&
            value.unit == discountTool.unit,
        ).length == 0
      ) {
        setDiscountTools((oldArray) => [...oldArray, discountTool])
      }
    }
  }

  const isProductTableEntry = (tableEntry: TableEntry) => {
    return tableEntry.productEntry
  }

  const isCategoryTableEntry = (tableEntry: TableEntry) => {
    return tableEntry.categoryEntry
  }

  const removeDiscountTool = (indexToRemove: number) => {
    const updatedTools = [...discountTools]
    updatedTools.splice(indexToRemove, 1)
    setDiscountTools(updatedTools)
  }

  const addTableEntry = (tableEntry: TableEntry) => {
    setTableEntries((oldArray) => [...oldArray, tableEntry])
  }

  const isVariantEntry = (input: any): input is VariantEntry => {
    return (
      typeof input == 'object' &&
      typeof input.name == 'string' &&
      typeof input.imageUrl == 'string' &&
      typeof input.isIncluded == 'boolean' &&
      typeof input.ean == 'string'
    )
  }

  const removeTableEntry = (tableEntry: TableEntry | VariantEntry) => {
    if (isVariantEntry(tableEntry)) {
      handleVariantEntry(tableEntry)
    } else {
      handleTableEntry(tableEntry)
    }
  }

  const showVariantByEan = (ean: string) => {
    const updatedProducts = tableEntries.map((p: TableEntry) => {
      if (p.productEntry === undefined) {
        return p
      } else {
        p.productEntry?.variantEntries.map((ve: VariantEntry) => {
          if (ve.ean === ean) {
            ve.isIncluded = true
          }
          return ve
        })
        return p
      }
    })

    setTableEntries(updatedProducts)
  }

  const showAllVariantsByProductKey = (productKey: string) => {
    const updatedProducts = tableEntries.map((p: TableEntry) => {
      if (p.productEntry === undefined) {
        return p
      } else {
        if (p.productEntry.productKey == productKey) {
          p.productEntry?.variantEntries.map((ve: VariantEntry) => {
            ve.isIncluded = true
            return ve
          })
        }
        return p
      }
    })

    setTableEntries(updatedProducts)
  }

  const findProductByVariant = (variantEntry: VariantEntry): TableEntry | undefined => {
    return tableEntries.find((te: TableEntry) => {
      if (te.productEntry == undefined) {
        return false
      } else {
        return te.productEntry.variantEntries.some((ve: VariantEntry) => ve.ean == variantEntry.ean)
      }
    })
  }

  const handleVariantEntry = (variantEntry: VariantEntry) => {
    let shouldDelete = false

    const updatedProducts = tableEntries.map((p: TableEntry) => {
      if (p.productEntry === undefined) {
        return p
      } else {
        p.productEntry?.variantEntries.map((ve: VariantEntry) => {
          if (ve.ean === variantEntry.ean) {
            if (p.productEntry?.variantEntries.filter((ve: VariantEntry) => ve.isIncluded === true).length === 1) {
              shouldDelete = true
            } else {
              ve.isIncluded = false
            }
          }
          return ve
        })
        return p
      }
    })

    if (shouldDelete) {
      const product = findProductByVariant(variantEntry)
      if (product) {
        removeTableEntry(product)
      } else {
        alert('No product found')
      }
    } else {
      setTableEntries(updatedProducts)
    }
  }

  const handleTableEntry = (tableEntry: TableEntry) => {
    let updatedTableEntries = [...tableEntries]

    if (isCategoryTableEntry(tableEntry)) {
      updatedTableEntries = updatedTableEntries.filter((te: TableEntry) => {
        if (te.categoryEntry !== undefined) {
          return te.categoryEntry?.categoryKey !== tableEntry.categoryEntry?.categoryKey
        } else {
          return true
        }
      })
      setTableEntries(updatedTableEntries)
    } else if (isProductTableEntry(tableEntry)) {
      updatedTableEntries = updatedTableEntries.filter((te: TableEntry) => {
        if (te.productEntry !== undefined) {
          return te.productEntry?.productKey !== tableEntry.productEntry?.productKey
        } else {
          return true
        }
      })
      setTableEntries(updatedTableEntries)
    }
  }

  const renderDiscountLabel = (value: PercentageDiscountTool | BundleDiscountTool): any => {
    if (isPercentageDiscount(value)) {
      return (value as PercentageDiscountTool).value + '%'
    }

    if (isBundleDiscount(value)) {
      return (
        (value as BundleDiscountTool).triggerQuantity +
        ' for ' +
        (value as BundleDiscountTool).discountedQuantity +
        ' ' +
        (value as BundleDiscountTool).unit.toLowerCase()
      )
    }
  }

  const generateCampaignDiscountJSONOutout = (channelKey: string) => {
    return {
      id: null,
      name: campaignName,
      validFrom: validFrom.toISOString(),
      validTo: validTo?.toISOString() || null,
      discounts: tableEntries.map((te: TableEntry) => {
        if (isBundleDiscount(te.discount)) {
          return createBundleDiscount(te, channelKey)
        } else if (isPercentageDiscount(te.discount)) {
          return createPercentageDiscount(te, channelKey)
        }
      }),
    }
  }

  const createPercentageDiscount = (tableEntry: TableEntry, channelKey: string) => {
    const percentageDiscount = tableEntry.discount as PercentageDiscountTool

    if (isCategoryTableEntry(tableEntry)) {
      return {
        bundle: null,
        categoryKey: tableEntry.categoryEntry?.categoryKey,
        channelKey: channelKey,
        discountAmount: percentageDiscount.value,
        excludedSkus: tableEntry.productEntry?.variantEntries.filter((ve: VariantEntry) => ve.isIncluded == false).map((ve: VariantEntry) => ve.ean) || [],
        productKey: null,
        discountType: 'CATEGORY',
      }
    } else if (isProductTableEntry(tableEntry)) {
      return {
        bundle: null,
        categoryKey: null,
        channelKey: channelKey,
        discountAmount: percentageDiscount.value,
        excludedSkus: tableEntry.productEntry?.variantEntries.filter((ve: VariantEntry) => ve.isIncluded == false).map((ve: VariantEntry) => ve.ean) || [],
        productKey: tableEntry.productEntry?.productKey,
        discountType: 'PRODUCT',
      }
    }
  }

  const createBundleDiscount = (tableEntry: TableEntry, channelKey: string) => {
    const bundleDiscount = tableEntry.discount as BundleDiscountTool

    switch (bundleDiscount.unit) {
      case 'PCS':
        if (isProductTableEntry(tableEntry)) {
          return {
            bundle: {
              totalPriceAfterDiscount: null,
              discountedQuantity: bundleDiscount.triggerQuantity,
              triggerQuantity: bundleDiscount.discountedQuantity,
              entityInfo: {
                productKey: tableEntry.productEntry?.productKey,
                excludedSkus: tableEntry.productEntry?.variantEntries.filter((ve: VariantEntry) => ve.isIncluded == false).map((ve: VariantEntry) => ve.ean),
                categoryKey: null,
                entityInfoType: EntityInfoType.Product,
              },
              bundleType: 'QUANTITY',
            },
            categoryKey: null,
            channelKey: channelKey,
            discountAmount: null,
            excludedSkus: [],
            productKey: null,
            discountType: 'BUNDLE',
          }
        } else if (isCategoryTableEntry(tableEntry)) {
          return {
            bundle: {
              totalPriceAfterDiscount: null,
              discountedQuantity: bundleDiscount.triggerQuantity,
              triggerQuantity: bundleDiscount.discountedQuantity,
              entityInfo: {
                productKey: null,
                excludedSkus: [],
                categoryKey: tableEntry.categoryEntry?.categoryKey,
                entityInfoType: EntityInfoType.Category,
              },
              bundleType: 'QUANTITY',
            },
            categoryKey: null,
            channelKey: channelKey,
            discountAmount: null,
            excludedSkus: [],
            productKey: null,
            discountType: 'BUNDLE',
          }
        } else {
          break
        }
      case 'KR':
        if (isProductTableEntry(tableEntry)) {
          return {
            bundle: {
              totalPriceAfterDiscount: bundleDiscount.discountedQuantity,
              discountedQuantity: null,
              triggerQuantity: bundleDiscount.triggerQuantity,
              entityInfo: {
                productKey: tableEntry.productEntry?.productKey,
                excludedSkus: tableEntry.productEntry?.variantEntries.filter((ve: VariantEntry) => ve.isIncluded == false).map((ve: VariantEntry) => ve.ean),
                categoryKey: null,
                entityInfoType: EntityInfoType.Product,
              },
              bundleType: 'PRICE',
            },
            categoryKey: null,
            channelKey: channelKey,
            discountAmount: null,
            excludedSkus: [],
            productKey: null,
            discountType: 'BUNDLE',
          }
        } else if (isCategoryTableEntry(tableEntry)) {
          return {
            bundle: {
              totalPriceAfterDiscount: bundleDiscount.discountedQuantity,
              discountedQuantity: null,
              triggerQuantity: bundleDiscount.triggerQuantity,
              entityInfo: {
                productKey: null,
                excludedSkus: [],
                categoryKey: tableEntry.categoryEntry?.categoryKey,
                entityInfoType: EntityInfoType.Category,
              },
              bundleType: 'PRICE',
            },
            categoryKey: null,
            channelKey: channelKey,
            discountAmount: null,
            excludedSkus: [],
            productKey: null,
            discountType: 'BUNDLE',
          }
        } else {
          break
        }
      default:
        console.log('Unit of bundle discount unknown')
    }
  }

  return (
    <DiscountCreationContext.Provider
      value={{
        fileUpload: fileUpload,
        setFileUpload,
        uploadComplete: uploadComplete,
        setUploadComplete,
        uploadConfirmed: uploadConfirmed,
        setUploadConfirmed,
        discountTools: discountTools,
        addDiscountTool: addDiscountTool,
        removeDiscountTool: removeDiscountTool,
        campaignName: campaignName,
        setCampaignName: setCampaignName,
        percentage: percentage,
        setPercentage: setPercentage,
        triggerQuantity: triggerQuantity,
        setTriggerQuantity: setTriggerQuantity,
        discountedQuantity: discountedQuantity,
        setDiscountedQuantity: setDiscountedQuantity,
        unit: unit,
        setUnit: setUnit,
        isPercentageDiscount: isPercentageDiscount,
        isBundleDiscount: isBundleDiscount,
        validFrom: validFrom,
        setValidFrom: setValidFrom,
        validTo: validTo,
        setValidTo: setValidTo,
        tableEntries,
        setTableEntries: setTableEntries,
        addTableEntry,
        removeTableEntry,
        showVariantByEan,
        showAllVariantsByProductKey,
        renderDiscountLabel,
        generateCampaignDiscountJSONOutout: generateCampaignDiscountJSONOutout,
        resetDiscountCreation: resetDiscountCreation,
      }}
    >
      {children}
    </DiscountCreationContext.Provider>
  )
}

export const useDiscountCreation = (): DiscountCreationContextProps => useContext(DiscountCreationContext)
