import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { ArrowLeft } from 'tabler-icons-react'
import { useReturn } from '../../../../context/OrderReturnContext'
import { ReturnItemQuantity } from '../../../../types/ReturnItemQuantity'
import { ReturnOrderItemCard } from '../components/ReturnOrderItemCard'
export const ReturnStatus = () => {
  const { previousStep, returnItemListWithQuantity, order, advanceStep } = useReturn()
  const initialWidth = window.innerWidth
  const isLargerThan768 = initialWidth >= 768
  return (
    <Flex gap={4} mx={isLargerThan768 ? 'auto' : 4} direction={'column'} w={isLargerThan768 ? '25%' : '100%'}>
      <Flex gap={4} alignItems={'center'}>
        <Icon
          bgColor={'transparent'}
          onClick={() => {
            previousStep()
          }}
          fontSize={'30px'}
          color={'black'}
          as={ArrowLeft}
        />
        <Text fontSize={'2xl'} fontWeight={'500'}>
          {t('main_app.return.components.steps.text_order_number', { orderNumber: order?.orderNumber })}
        </Text>
      </Flex>
      <Flex>{t('main_app.return.components.steps.text_return_status')}</Flex>
      <Flex direction={'column'} gap={4}>
        {returnItemListWithQuantity!
          .sort((a: ReturnItemQuantity, b: ReturnItemQuantity) => b.quantity - a.quantity)
          .map((value: ReturnItemQuantity, index: number, arr: ReturnItemQuantity[]) => {
            return (
              <ReturnOrderItemCard
                key={index}
                returnOrderLineItem={value}
                returningItemBox={value.quantity >= 1 ? 'Returning' + ' ' + returnItemListWithQuantity[index].quantity + ' of this item' : undefined}
              />
            )
          })}
      </Flex>

      <Button
        fontSize={'18px'}
        fontWeight={400}
        bgColor={'blackAlpha.900'}
        w={'full'}
        height={14}
        onClick={() => {
          advanceStep()
        }}
      >
        {t('components.button.confirm_return')}
      </Button>
    </Flex>
  )
}
