import { Flex } from '@chakra-ui/react'
import React from 'react'
import CategorySelector from './CategorySelector'
import GenderSelector from './GenderSelector'
const InputSelector = () => {
  return (
    <Flex direction={'column'} alignSelf='self-start' width='100%'>
      <GenderSelector />
      <CategorySelector />
    </Flex>
  )
}

export default React.memo(InputSelector)
