import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, ListItem, UnorderedList } from '@chakra-ui/react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

export const DeliveryAccordion = () => {

  return (
    <Flex direction='column' w='full' alignItems={'start'} alignContent={'start'}>
      <Heading borderRadius={'20px'} fontSize={'3xl'} padding={4} width={'full'} >
        {t('main_app.faq.delivery.headline_delivery')}
      </Heading>
      <Accordion p={2} allowToggle w={'full'}>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.delivery.accordion_button_order')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>
          <AccordionPanel>
            <UnorderedList>
              <ListItem>{t('main_app.faq.delivery.list_item_1')}</ListItem>
              <ListItem>
                <Box display='inline' mr={1}>
                  {t('main_app.faq.delivery.list_item_3')}
                </Box>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.delivery.accordion_button_deliver')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>
          <AccordionPanel>{t('main_app.faq.delivery.accordion_panel_deliver_to')}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}
