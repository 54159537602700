import { Checkbox } from '@chakra-ui/react'
import React from 'react'
import { useCTFilter } from '../../../../context/CTFilterContext'
import { useBrandSelector } from '../hooks/useBrandSelector'

export const BrandSelector = () => {
  const { brandFacets, selectedBrands: brandsFromContext, setSelectedBrands: updateGlobalBrands } = useCTFilter()
  const [selectedBrands, setSelectedBrands] = useBrandSelector(brandsFromContext, updateGlobalBrands)

  const toggleBrand = (value: string) => {
    if (selectedBrands?.includes(value)) {
      setSelectedBrands(selectedBrands.filter((brand) => brand !== value))
    } else {
      setSelectedBrands([...selectedBrands!, value])
    }
  }
  return (
    <>
      {brandFacets.map((facet, index) => (
        <div key={index}>
          <Checkbox key={index} variant={'whiteSquareStyle'} isChecked={selectedBrands?.includes(facet.term)} onChange={() => toggleBrand(facet.term)}>
            {facet.term}
          </Checkbox>
        </div>
      ))}
    </>
  )
}
