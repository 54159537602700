import { HStack, Icon, IconButton, Box, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { MapPin, ShoppingBag } from 'tabler-icons-react'
import { useCart } from '../../../../context/CheckoutCartContext'
import LanguageSelector from '../../../../I18n/LanguageSelector'

export const HeaderIconsDesktop = () => {
  const { toggleCartDrawer, calculateTotalLineItems } = useCart()
  const navigate = useNavigate()
  return (
    <HStack gap={'1rem'}>
      <Flex cursor={'pointer'} py={4} justifyContent={'center'}>
        <LanguageSelector />
      </Flex>
      <IconButton
        style={{ marginInlineStart: '0', minWidth: '0' }}
        size={'sm'}
        aria-label='button'
        variant={'ghost'}
        icon={
          <Box position='relative'>
            <Icon w={'2rem'} h={'2rem'} strokeWidth={'1.5'} as={MapPin} />
          </Box>
        }
        onClick={() => navigate('/store-map')}
      />
      <IconButton
        style={{ marginInlineStart: '0', minWidth: '0' }}
        size={'sm'}
        aria-label='button'
        variant={'ghost'}
        icon={
          <Box position='relative'>
            <Icon w={'2rem'} h={'2rem'} strokeWidth={'1.5'} as={ShoppingBag} />
            {calculateTotalLineItems() > 0 && (
              <Box
                position='absolute'
                top='-8px'
                right='-8px'
                bg='mintGreen.500'
                borderRadius='full'
                width='24px'
                height='24px'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <span style={{ fontSize: '14px' }}>{calculateTotalLineItems()}</span>
              </Box>
            )}
          </Box>
        }
        onClick={toggleCartDrawer}
      />
    </HStack>
  )
}
