import { Button, Flex, FormControl, FormErrorMessage, Grid, GridItem, Input, InputGroup, Select, useToast } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { t } from 'i18next'
import * as Yup from 'yup'
import { useCart } from '../../../../context/CheckoutCartContext'

const InfoFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-ZæøåÆØÅ]+(\s[a-zA-ZæøåÆØÅ]+)*$/, 'First name must contain only letters and spaces, and cannot start or end with a space')
    .required(t('components.cart.components.info_form_first_name') as string),
  lastName: Yup.string()
    .matches(/^[a-zA-ZæøåÆØÅ]+$/, 'Last name must contain only letters')
    .required(t('components.cart.components.info_form_last_name') as string),
  street: Yup.string().required(t('components.cart.components.info_form_street') as string),
  number: Yup.string()
    .matches(/^[0-9]+$/, 'Number must only contains numbers')
    .required(t('components.cart.components.info_form_number') as string),
  apartment: Yup.string().optional(),
  postalCode: Yup.string()
    .matches(/^[0-9]{4}$/, 'Postal code must be exactly 4 numbers')
    .required(t('components.cart.components.info_form_postal') as string),
  city: Yup.string()
    .matches(/^[a-zA-ZæøåÆØÅ]+(\s[a-zA-ZæøåÆØÅ]+)*$/, 'City must contain only letters')
    .required(t('components.cart.components.info_form_city') as string),
  country: Yup.string().required(t('components.cart.components.info_form_country') as string),
  email: Yup.string()
    .matches(/^(?=.{1,256})(?=.{1,64}@.{1,255}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/, 'Invalid email address')
    .required(t('components.cart.components.info_form_email') as string),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone number must contain only numbers')
    .required(t('components.cart.components.info_form_phone') as string),

  company: Yup.string().optional(),
})

export const InfoFormDelivery = () => {
  const { setCustomerInformation, cartDeliveryOption, parcelResponse, setCheckoutEditStep, customerInformation } = useCart()
  const toast = useToast()

  return (
    <Formik
      initialValues={{
        firstName: customerInformation.firstName || '',
        lastName: customerInformation.lastName || '',
        street: customerInformation.street || '',
        number: customerInformation.number || '',
        apartment: customerInformation.apartment || '',
        postalCode: customerInformation.postalCode || '',
        city: customerInformation.city || '',
        country: customerInformation.country || 'Dk',
        email: customerInformation.email || '',
        phone: customerInformation.phone || '',
        company: customerInformation.company || '',
      }}
      validationSchema={InfoFormSchema}
      onSubmit={(values: any) => {
        setCustomerInformation({
          firstName: values.firstName,
          lastName: values.lastName,
          street: values.street,
          number: values.number,
          apartment: values.apartment,
          postalCode: values.postalCode,
          city: values.city,
          country: values.country,
          email: values.email,
          phone: values.phone,
          company: values.company,
        })

        if (cartDeliveryOption === 'ParcelShop') {
          if (parcelResponse != null) {
            setCheckoutEditStep('Payment')
          } else {
            toast({
              title: t('components.cart.components.placeholder_company'),
              description: 'Please pick a parcel shop',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          }
        } else {
          setCheckoutEditStep('Payment')
        }
      }}
    >
      {({ errors, touched, handleSubmit }) => (
        <Flex direction={'column'} gap={'1.5rem'}>
          <Grid w={'full'} templateRows='repeat(7, 1fr)' gap={'1rem'}>
            <GridItem rowSpan={1}>
              <Flex gap={4}>
                <FormControl isRequired isInvalid={errors.firstName != undefined && touched.firstName}>
                  <Field
                    as={Input}
                    id='firstName'
                    name='firstName'
                    placeholder={t('components.cart.components.placeholder_first_name')}
                    variant={'solidWhite'}
                  />
                  {errors.firstName && touched.firstName ? <FormErrorMessage>{errors.firstName}</FormErrorMessage> : null}
                </FormControl>

                <FormControl isInvalid={errors.lastName != undefined && touched.lastName}>
                  <Field as={Input} id='lastName' name='lastName' placeholder={t('components.cart.components.placeholder_last_name')} variant={'solidWhite'} />
                  {errors.lastName && touched.lastName ? <FormErrorMessage>{errors.lastName}</FormErrorMessage> : null}
                </FormControl>
              </Flex>
            </GridItem>
            <GridItem rowSpan={1}>
              <Flex gap={4}>
                <FormControl isInvalid={errors.street != undefined && touched.street}>
                  <Field as={InputGroup} variant={'solidWhite'}>
                    <Input id='street' name='street' placeholder={t('components.cart.components.placeholder_street') as string} />
                  </Field>
                  {errors.street && touched.street ? <FormErrorMessage>{errors.street}</FormErrorMessage> : null}
                </FormControl>

                <FormControl w={'25%'} isInvalid={errors.number != undefined && touched.number}>
                  <Field as={Input} id='number' name='number' placeholder={t('components.cart.components.placeholder_number')} variant={'solidWhite'} />
                  {errors.number && touched.number ? <FormErrorMessage>{errors.number}</FormErrorMessage> : null}
                </FormControl>
                <FormControl w={'25%'} isInvalid={errors.apartment != undefined && touched.apartment}>
                  <Field
                    as={Input}
                    id='apartment'
                    name='apartment'
                    placeholder={t('components.cart.components.placeholder_apartment')}
                    variant={'solidWhite'}
                  />
                  {errors.apartment && touched.apartment ? <FormErrorMessage>{errors.apartment}</FormErrorMessage> : null}
                </FormControl>
              </Flex>
            </GridItem>
            <GridItem rowSpan={1}>
              <Flex gap={4}>
                <FormControl isInvalid={errors.postalCode != undefined && touched.postalCode}>
                  <Field as={Input} id='postalCode' name='postalCode' placeholder={t('components.cart.components.placeholder_postal')} variant={'solidWhite'} />
                  {errors.postalCode && touched.postalCode ? <FormErrorMessage>{errors.postalCode}</FormErrorMessage> : null}
                </FormControl>

                <FormControl isInvalid={errors.city != undefined && touched.city}>
                  <Field as={Input} id='city' name='city' placeholder={t('components.cart.components.placeholder_city')} variant={'solidWhite'} />
                  {errors.city && touched.city ? <FormErrorMessage>{errors.city}</FormErrorMessage> : null}
                </FormControl>
              </Flex>
            </GridItem>
            <GridItem rowSpan={1}>
              <Flex gap={4}>
                <FormControl isInvalid={errors.country != undefined && touched.country}>
                  <Field as={Select} id='country' name='country' placeholder={t('components.cart.components.placeholder_country')} variant={'solidWhite'}>
                    <option value='DK'>{t('components.cart.components.option_country')}</option>
                  </Field>
                  {errors.country && touched.country ? <FormErrorMessage>{errors.country}</FormErrorMessage> : null}
                </FormControl>
              </Flex>
            </GridItem>
            <GridItem rowSpan={1}>
              <Flex gap={4}>
                <FormControl isInvalid={errors.email != undefined && touched.email}>
                  <Field as={Input} id='email' name='email' placeholder={t('components.cart.components.placeholder_email')} variant={'solidWhite'} />
                  {errors.email && touched.email ? <FormErrorMessage>{errors.email}</FormErrorMessage> : null}
                </FormControl>
              </Flex>
            </GridItem>
            <GridItem rowSpan={1}>
              <Flex gap={4}>
                <FormControl isInvalid={errors.phone != undefined && touched.phone}>
                  <Field as={Input} id='phone' name='phone' placeholder={t('components.cart.components.placeholder_phone')} variant={'solidWhite'} />
                  {errors.phone && touched.phone ? <FormErrorMessage>{errors.phone}</FormErrorMessage> : null}
                </FormControl>
              </Flex>
            </GridItem>
            <GridItem rowSpan={1}>
              <Flex gap={4}>
                <FormControl isInvalid={errors.company != undefined && touched.company}>
                  <Field as={Input} id='company' name='company' placeholder={t('components.cart.components.placeholder_company')} variant={'solidWhite'} />
                  {errors.company && touched.company ? <FormErrorMessage>{errors.company}</FormErrorMessage> : null}
                </FormControl>
              </Flex>
            </GridItem>
          </Grid>
          <Button w={'full'} onClick={() => handleSubmit()}>
            {t('components.button.continue_payment')}
          </Button>
        </Flex>
      )}
    </Formik>
  )
}
