import { useApolloClient } from '@apollo/client'
import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { t } from 'i18next'
import {  useState } from 'react'
import { TbMapPin, TbSearch } from 'react-icons/tb'
import moment from 'moment/moment'
import { GET_PARCEL_SHOP_BY_GEOLOCATION } from '../../gql/logistics'
import { Maybe, OpeningTime, ParcelResponse } from '../../generated/graphql'
import { Theme } from '../../ThemeVariables'
import { metersToKilometersFormatted, numberToDayString } from '../../utils/LogisticsUtil'
import { PageSpinner } from '../PageSpinner'

type ParcelShopPickerModalProps = {
  isOpen: boolean
  setOpen: (value: boolean) => any
  parcelResponse: ParcelResponse | null
  setParcelResponse: (value: ParcelResponse) => any
  setParcelShopPickerState: (state: 'Awaiting' | 'Success' | 'Error') => void
  setCartDeliveryOption: (option: 'Pickup' | 'HomeDelivery' | 'ParcelShop') => void
}

export const ParcelShopPickerModal = (props: ParcelShopPickerModalProps) => {
  const client = useApolloClient()

  const [streetAndNumber, setStreetAndNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')

  const [parcelShopResponses, setParcelShopResponses] = useState<ParcelResponse[]>([])
  const [loading, setLoading] = useState(false)

  const fetchParcelShops = () => {
    setLoading(true)

    client
      .query({
        query: GET_PARCEL_SHOP_BY_GEOLOCATION,
        variables: {
          parcelShopQuery: {
            address: streetAndNumber,
            carrier: 'Postnord',
            country: 'DK',
            postCode: postalCode,
          },
        },
      })
      .then((response: any) => {
        setLoading(false)
        setParcelShopResponses(response.data.getParcelShopByGeolocation)
        props.setParcelShopPickerState('Success')
      })
      .catch((error: any) => {
        setLoading(false)
        console.log(error)
        props.setParcelShopPickerState('Error')
      })
  }

  const onClose = () => {
    if (props.parcelResponse === null) {
      props.setCartDeliveryOption('HomeDelivery')
    }
    props.setOpen(false)
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onClose} size={'xl'} scrollBehavior={'inside'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('components.cart.components.modal_header_parcel_shop')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction={'column'} gap={4}>
            <Flex>
              <Grid w={'full'} templateColumns={'repeat(3, 1fr)'} templateRows={'repeat(2, 1fr)'} gap={4}>
                <GridItem colSpan={2}>
                  <InputGroup variant={'solid'}>
                    <InputLeftElement pointerEvents='none'>
                      <Icon boxSize={8} as={TbMapPin} />
                    </InputLeftElement>
                    <Input
                      border={'solid 1px'}
                      borderColor={'beigeLight.500'}
                      borderRadius={'16px'}
                      value={streetAndNumber}
                      onChange={(e: any) => setStreetAndNumber(e.target.value)}
                      placeholder={t('components.cart.components.placeholder_parcel_info.0') as string}
                    />
                  </InputGroup>
                </GridItem>
                <GridItem colSpan={1}>
                  <Input
                    type='number'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    border={'solid 1px'}
                    borderColor={'beigeLight.500'}
                    variant={'solid'}
                    borderRadius={'16px'}
                    value={postalCode}
                    onChange={(e: any) => setPostalCode(e.target.value)}
                    placeholder={t('components.cart.components.placeholder_parcel_info.1') as string}
                  />
                </GridItem>
                <GridItem colStart={1} colSpan={3}>
                  <Button
                    onClick={() => fetchParcelShops()}
                    variant={'outline'}
                    leftIcon={<Icon as={TbSearch} />}
                    w={'full'}
                    isDisabled={streetAndNumber === '' || !/^\d+$/.test(postalCode)}
                  >
                    {t('components.button.search_parcel')}
                  </Button>
                </GridItem>
              </Grid>
            </Flex>
            <Divider hidden={parcelShopResponses.length == 0} borderColor={'beigeDark.500'} />
            <Stack spacing={4} maxH={820} overflow={'auto'}>
              {!loading ? (
                parcelShopResponses.slice(0, 6).map((value: ParcelResponse, index: number) => {
                  return (
                    <Flex
                      key={index}
                      _hover={{
                        borderColor: 'beigeDark.500',
                        bgColor: 'beigeLight.400',
                      }}
                      onClick={() => {
                        props.setParcelResponse(value) // CONTEXT
                        // props.context.setDeliveryOption('ParcelShop')
                        props.setParcelShopPickerState('Success')
                        props.setCartDeliveryOption('ParcelShop')
                        props.setOpen(false)
                      }}
                      cursor={'pointer'}
                      border={'2px'}
                      borderColor={'transparent'}
                      borderRadius={5}
                      bgColor={'beigeLight.300'}
                      p={Theme.spacing.padding.mobile}
                      gap={4}
                      justifyContent={'space-between'}
                      direction={'column'}
                    >
                      <Flex justifyContent={'space-between'}>
                        <Flex gap={4}>
                          <Flex direction={'column'}>
                            <Text>{value.parcelShop?.name}</Text>
                            <Text fontSize={'sm'}>
                              {value.parcelShop?.addressLine1 || t('components.cart.components.text_unknown_address')}, {value.parcelShop?.postCode}{' '}
                              {value.parcelShop?.city}
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex minW={'100px'} alignItems={'center'} justifyContent={'end'}>
                          <Text>{metersToKilometersFormatted(value.distance || 0, 1)}</Text>
                        </Flex>
                      </Flex>

                      <Flex direction={'column'}>
                        {value.parcelShop
                          ?.openingTimes!.filter((e: Maybe<OpeningTime>) => parseInt(e?.day || '0') === moment().day())
                          .map((value: Maybe<OpeningTime>, index: number) => {
                            return (
                              <Flex key={index} gap={4}>
                                <Text fontWeight={'bold'} minW={'100px'}>
                                  {numberToDayString(parseInt(value?.day || '0') || 0)}:
                                </Text>
                                <Text>{value?.from + ' - ' + value?.to}</Text>
                              </Flex>
                            )
                          })}
                      </Flex>
                    </Flex>
                  )
                })
              ) : (
                <PageSpinner />
              )}
            </Stack>
          </Flex>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
