import { gql } from '@apollo/client'

export const CREATE_MERCHANT_APPLICATION = gql`
  mutation CreateMerchantApplication($storeInformation: StoreInformationInput) {
    createMerchantApplication(storeInformation: $storeInformation) {
      id
      onboardingNumber
      createdAt
      adyenApproved
      plentyApproved
      onboardingApplication {
        id
        storeInformation {
          city
          countryName
          id
          postalCode
          storeName
          storeNumber
          streetAndNumber
          iban
          storeEmail
          storePhone
          accountOwnerEmail
        }
      }
    }
  }
`

export const GET_MERCHANT_APPLICATION_BY_ONBOARDINGNUMBER = gql`
  query GetMerchantApplication($onboardingNumber: String) {
    getMerchantApplication(onboardingNumber: $onboardingNumber) {
      id
      onboardingNumber
      createdAt
      adyenApproved
      plentyApproved
      onboardingApplication {
        id
        storeInformation {
          city
          countryName
          id
          postalCode
          storeName
          storeNumber
          streetAndNumber
          storeEmail
          storePhone
          accountOwnerEmail
        }
      }
    }
  }
`

export const GET_MERCHANT_APPLICATIONS = gql`
  query GetMerchantApplications {
    getMerchantApplications {
      id
      adyenApproved
      plentyApproved
      createdAt
      onboardingNumber
      onboardingApplication {
        id
        storeInformation {
          id
          storeName
          storeNumber
          streetAndNumber
          postalCode
          city
          countryName
          storeEmail
          storePhone
          accountOwnerEmail
        }
      }
    }
  }
`

export const PLENTY_APPROVE_MERCHANT_APPLICATION = gql`
  mutation PlentyApproveMerchantApplication($onboardingNumber: String!) {
    plentyApproveMerchantApplication(onboardingNumber: $onboardingNumber) {
      id
    }
  }
`
