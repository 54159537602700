import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Input, InputGroup, InputLeftElement, Icon, Flex } from '@chakra-ui/react'
import { TbSearch } from 'react-icons/tb'
import { useProductSearch } from '../../../../context/ProductSearchContext'
import { debounce } from 'lodash'
import { t } from 'i18next'
import { useProductSearchUtilities } from '../SearchUtil'

const SearchInput = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fetchSearchedProducts, clearSearchStates } = useProductSearchUtilities()
  const { setSearchTerm, searchTerm, selectedGender, offset, priceSort, genderizedCategoryId } = useProductSearch()

  const [searchString, setSearchString] = useState(searchTerm)

  useEffect(() => {
    fetchSearchedProducts()
  }, [offset, genderizedCategoryId, priceSort])

  useEffect(() => {
    clearSearchStates()
    fetchSearchedProducts()
  }, [searchTerm, selectedGender])

  const debouncedSetSearchTerm = useCallback(
    debounce((value) => {
      setSearchTerm(value)
    }, 600),
    [],
  )

  useEffect(() => {
    setSearchString(searchTerm)
  }, [searchTerm])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          inputRef.current?.focus()
        }
      },
      { threshold: 0.1 },
    )

    const currentInputRef = inputRef.current
    if (currentInputRef) {
      observer.observe(currentInputRef)
    }

    return () => {
      if (currentInputRef) {
        observer.unobserve(currentInputRef)
      }
      debouncedSetSearchTerm.cancel()
    }
  }, [debouncedSetSearchTerm])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const value = event.target.value
    setSearchString(value)
    debouncedSetSearchTerm(value)
  }

  return (
    <Flex flex={1} w='100%' alignItems={'center'} justifyContent={'center'} marginTop={'1rem'}>
      <InputGroup variant={'roundedSolidBeige'} w='100%'>
        <InputLeftElement pointerEvents='none'>
          <Icon w='5' h='5' as={TbSearch} />
        </InputLeftElement>
        <Input ref={inputRef} onChange={handleInputChange} value={searchString} placeholder={t('search.text_search_bar')!} />
      </InputGroup>
    </Flex>
  )
}

export default SearchInput
