import { Checkbox, Flex, Icon, Img, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react'
import { TableEntry } from '../../../../types/TableEntry'
import { VariantEntry } from '../../../../types/VariantEntry'

type ApplyDiscountTableProps = {
  columns: any
  table: any
  subRow: any
  discountCreation: any
}

export const ApplyDiscountTable = (props: ApplyDiscountTableProps) => {
  return (
    <Table size={'sm'} {...props.table.getTableProps}>
      <Thead>
        {props.table.headerGroups.map((headerGroup: any, index: number) => {
          return (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any, index: number) => {
                return (
                  <Th
                    h={'50px'}
                    alignItems={'center'}
                    borderTop={'1px'}
                    borderColor={'gray.100'}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <Flex justifyContent={'space-between'}>
                      {column.render('Header')}
                      <Flex h={5} w={5}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon h={5} w={5} as={IconSortDescending} />
                          ) : (
                            <Icon h={5} w={5} as={IconSortAscending} />
                          )
                        ) : null}
                      </Flex>
                    </Flex>
                  </Th>
                )
              })}
            </Tr>
          )
        })}
      </Thead>
      <Tbody color={'blackAlpha.600'} fontSize={'sm'} {...props.table.getTableBodyProps}>
        {props.table.page.map((row: any, index: number) => {
          props.table.prepareRow(row)
          return (
            <>
              <Tr key={index} bgColor={row.original.discount ? 'green.50' : 'white.500'} borderBottom={'none'} {...row.getRowProps()}>
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <>
                      <Td key={index} borderBottom={'none'} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    </>
                  )
                })}
              </Tr>
              {row.isExpanded ? (
                row.original.productEntry.variantEntries.map((variantEntry: VariantEntry) => {
                  if (variantEntry.isIncluded) {
                    return (
                      <Tr key={variantEntry.ean} bgColor={'#edede766'}>
                        <Td borderBottom={'none'}></Td>
                        <Td borderBottom={'none'}></Td>
                        <Td borderBottom={'none'}>
                          <Img borderRadius={8} objectFit={'contain'} w={'40px'} h={'54px'} src={variantEntry.imageUrl} />
                        </Td>
                        <Td borderBottom={'none'}>{variantEntry.name}</Td>
                        <Td borderBottom={'none'}>{variantEntry.size}</Td>
                        <Td borderBottom={'none'}>{variantEntry.price}</Td>
                        <Td borderBottom={'none'}>{variantEntry.ean}</Td>
                        <Td borderBottom={'none'}>{props.discountCreation.renderDiscountLabel(row.original.productEntry.discount)}</Td>
                      </Tr>
                    )
                  }
                })
              ) : (
                <></>
              )}
            </>
          )
        })}
      </Tbody>
    </Table>
  )
}
