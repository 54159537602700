import { Flex } from "@chakra-ui/react";

export const HorizontalBeigeLightPill = ({top}: any) => {
  return (
    <Flex
      width="481px"
      height="847px"
      flexShrink={0}
      borderRadius="284px 284px 0px 0px"
      bg="var(--primary-beige-10, #EDEDE7)"
      position="absolute" // You can change it to 'fixed' if needed
      zIndex={"-1"} // Ensures it's behind all other content but in front of the background
      transform="rotate(90deg)" // Rotates the pill 90 degrees
      left={0} // Adjust as needed to place the pill where you want
      top={top}
      display={{ base: 'none', lg: 'flex' }} 

    />
  );
};
