import apiRoot from './client'
import { CategoryPagedQueryResponse, ProductProjectionPagedSearchResponse } from '@commercetools/platform-sdk'
import { PlentyCategory } from '../pages/main_app/PLP/types'

export const getProductsProjectionSearch = async (
  category: string | undefined,
  offset = 0,
  colors: string[] = [],
  brands: string[] | null = [],
  sizes: string[] | null = [],
  stores: string[] | null = [],
  sortPriceOrder: string | null = null,
): Promise<ProductProjectionPagedSearchResponse | undefined> => {
  const colorsForCT = colors?.map((item) => `"${item}"`).join(',')
  const brandsForCT = brands?.map((item) => `"${item}"`).join(',')
  const sizesForCT = sizes?.map((item) => `"${item}"`).join(',')
  const storesForCT = stores?.map((item) => `"${item}"`).join(',')
  const queryArgs: any = {
    filter: [`categories.id:subtree("${category}")`],
    staged: true,
    offset: offset,
    limit: 80,
    'filter.facets': [`categories.id:subtree("${category}")`],
    facet: ['variants.attributes.brand.en', 'variants.attributes.size.en', 'variants.attributes.length.en', 'variants.attributes.main_color.en'],
    sort: [],
    expand: [],
  }

  queryArgs.filter.push(`variants.price.centAmount:range (1 to *)`)

  if (sortPriceOrder && sortPriceOrder !== 'none') {
    queryArgs.sort.push(`price ${sortPriceOrder}`)
  } else {
    queryArgs.sort.push('variants.attributes.product_relevance desc')
  }

  if (stores && stores.length > 0) {
    queryArgs.filter.push(`variants.availability.isOnStockInChannels:${storesForCT}`) //TODO: What will happen with 100 stores?
    queryArgs['filter.facets'].push(`variants.availability.isOnStockInChannels:${storesForCT}`)
  }

  if (brands && brands.length > 0) {
    queryArgs.filter.push(`variants.attributes.brand.en:${brandsForCT}`)
  }

  if (colors && colors.length > 0) {
    queryArgs.filter.push(`variants.attributes.main_color.en:${colorsForCT}`)
  }

  if (sizes && sizes.length > 0) {
    queryArgs.filter.push(`variants.attributes.size.en:${sizesForCT}`)
  }

  queryArgs.expand.push('variants[*].prices[*].discounted.discount')
  queryArgs.expand.push('masterVariant.prices[*].discounted.discount')

  return await apiRoot
    .productProjections()
    .search()
    .get({
      queryArgs: queryArgs,
    })
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getChannels = async () => {
  return await apiRoot
    .channels()
    .get()
    .execute()
    .then((response) => {
      return response.body.results
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getCartById = async (id: string) => {
  return await apiRoot
    .carts()
    .withId({ ID: id })
    .get()
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getStoreByKey = async (key: string) => {
  return await apiRoot
    .stores()
    .withKey({ key: key })
    .get({
      queryArgs: {
        expand: ['distributionChannels[*]'],
      },
    })
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getAllStores = async () => {
  return await apiRoot
    .stores()
    .get()
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}
export const getAllStoresWithExpand = async () => {
  return await apiRoot
    .stores()
    .get({
      queryArgs: {
        expand: ['distributionChannels[*]'],
      },
    })
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getStoreById = async (id: string) => {
  return await apiRoot
    .stores()
    .withId({ ID: id })
    .get()
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getProductProjectionSearchByStyleNumber = async (styleNumber: string, sku?: string | null) => {
  const queryArgs: any = {
    filter: [`key:"${styleNumber}"`],
    staged: true,
    markMatchingVariants: true,
    expand: [
      'categories[*].ancestors[*]',
      'variants[*].prices[*].channel',
      'masterVariant.prices[*].channel',
      'variants[*].prices[*].discounted.discount',
      'masterVariant.prices[*].discounted.discount',
    ],
  }
  if (sku) {
    queryArgs.filter.push(`variants.key:"${sku}"`)
  }
  return await apiRoot
    .productProjections()
    .search()
    .get({ queryArgs })
    .execute()
    .then((response) => {
      return response.body
    })
}

export const getProductsProjectionSearchForSliderGender = async (
  gender = '',
  targetGroup?: string,
  storeChannel?: string,
): Promise<ProductProjectionPagedSearchResponse | undefined> => {
  const queryArgs: any = {
    filter: [`variants.attributes.gender.en:"${gender}"`],
    staged: true,
    sort: ['variants.attributes.product_relevance desc'],
    limit: 10,
  }
  queryArgs.filter.push(`variants.price.centAmount:range (1 to *)`)
  if (storeChannel) {
    queryArgs.filter.push(`variants.availability.isOnStockInChannels:"${storeChannel}"`)
  }

  if (targetGroup) {
    queryArgs.filter.push(`variants.attributes.target_group.en:"${targetGroup}"`)
  }

  return await apiRoot
    .productProjections()
    .search()
    .get({ queryArgs })
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error, 'error for gender slider')
      return undefined
    })
}

export const getProductsProjectionSearchForSimilarItems = async (name?: string, gender?: string, targetGroup?: string, stores: string[] | null = []) => {
  const storesForCT = stores?.map((item) => `"${item}"`).join(',')
  const queryArgs: any = {
    filter: [`variants.attributes.gender.en:"${gender}"`],
    staged: true,
    limit: 10,
    'text.en': `${name} similar`,
    fuzzy: true,
    sort: ['variants.attributes.product_relevance desc'],
    expand: ['variants[*].prices[*].discounted.discount', 'masterVariant.prices[*].discounted.discount'],
  }
  queryArgs.filter.push(`variants.price.centAmount:range (1 to *)`)

  if (stores && stores.length > 0) {
    queryArgs.filter.push(`variants.availability.isOnStockInChannels:${storesForCT}`)
  }

  if (targetGroup) {
    queryArgs.filter.push(`variants.attributes.target_group.en:"${targetGroup}"`)
  }

  return await apiRoot
    .productProjections()
    .search()
    .get({ queryArgs })
    .execute()
    .then((response) => {
      return response.body.results
    })
    .catch((error) => {
      console.log(error, 'error for similar items')
      return undefined
    })
}

export const getInventoryForProduct = async (storeKey: string, sku?: string | null) => {
  return await apiRoot
    .inventory()
    .get({
      queryArgs: {
        where: `supplyChannel(id="${storeKey}") and sku="${sku}"`,
      },
    })
    .execute()
    .then((response) => {
      return response.body.results
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getMainCategories = async () => {
  return await apiRoot
    .categories()
    .get({
      queryArgs: {
        where: 'ancestors is empty',
      },
    })
    .execute()
    .then((response) => {
      return response.body.results
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getSubCategories = async (category: string): Promise<PlentyCategory[] | undefined> => {
  try {
    // Fetch the subcategories as before
    const subcategoriesResponse = await apiRoot
      .categories()
      .get({
        queryArgs: {
          where: `parent(id="${category}")`,
          withTotal: true,
          sort: 'orderHint asc',
        },
      })
      .execute()
    const subcategories = subcategoriesResponse.body.results

    const productCountsResponse = await apiRoot
      .productProjections()
      .search()
      .get({
        queryArgs: {
          'filter.query': [`categories.id:subtree("${category}")`],
          facet: ['categories.id counting products'],
          staged: true,
          limit: 0, // We don't need any products, just the counts
        },
      })
      .execute()

    // Map the facet results (product counts) back to the subcategories
    const facets: any = productCountsResponse.body.facets['categories.id']
    const plentySubcategories = subcategories as PlentyCategory[]
    plentySubcategories.forEach((subcat) => {
      const facet = facets.terms.find((f: any) => f.term === subcat.id)
      subcat.productCount = facet ? facet.productCount : 0 // Assign the product count to each subcategory
    })

    return subcategories as PlentyCategory[]
  } catch (error) {
    console.error(error)
    return undefined
  }
}

export const getCategoryByKey = async (categoryKey: string) => {
  return await apiRoot
    .categories()
    .withKey({ key: categoryKey })
    .get()
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getAllCategories = async (offset: number, limit: number): Promise<CategoryPagedQueryResponse | undefined> => {
  return await apiRoot
    .categories()
    .get({
      queryArgs: {
        limit: limit,
        offset: offset,
        expand: ['ancestors[*]', 'parent'],
      },
    })
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getAllCategoriesSortedByOrderHint = async (offset: number, limit: number): Promise<CategoryPagedQueryResponse | undefined> => {
  try {
    const response = await apiRoot
      .categories()
      .get({
        queryArgs: {
          limit: limit,
          offset: offset,
          expand: ['ancestors[*]', 'parent'],
        },
      })
      .execute()

    const sortedCategories = response.body.results
      .sort((a, b) => {
        const orderA = a.orderHint ? parseFloat(a.orderHint) : Number.MAX_VALUE
        const orderB = b.orderHint ? parseFloat(b.orderHint) : Number.MAX_VALUE
        return orderA - orderB
      })
      .filter((category) => category.name['en'].toLowerCase().trim() !== 'beachwear') // Exclude 'Beachwear'
    return {
      ...response.body,
      results: sortedCategories,
    }
  } catch (error) {
    console.log(error)
    return undefined
  }
}

export const getProductsProjectionSearchGlobal = async (
  searchTerm: string,
  offset = 0,
  category: string | undefined,
  genderizedCategory: string | undefined,
  genderCategory: string,
  colors: string[],
  language: string,
  stores: string[] | null = [],
  sortPriceOrder: string | null = null,
): Promise<ProductProjectionPagedSearchResponse | undefined> => {
  const colorsForCT = colors?.map((item) => `"${item}"`).join(',')
  const storesForCT = stores?.map((item) => `"${item}"`).join(',')
  const queryArgs: any = {
    [`text.${language}`]: `${searchTerm}`,
    expand: ['categories[*].ancestors[*]', 'categories[*].parent', 'variants[*].prices[*].discounted.discount', 'masterVariant.prices[*].discounted.discount'],
    filter: [`variants.price.centAmount:range (1 to *)`],
    staged: true,
    fuzzy: true,
    fuzzyLevel: 1,
    limit: 30,
    sort: [],
    offset: 1 * offset,
    markMatchingVariants: true,
  }

  if (stores && stores.length > 0) {
    queryArgs.filter.push(`variants.availability.isOnStockInChannels:${storesForCT}`)
  }
  if (category && category != '') {
    queryArgs.filter.push(`categories.id:subtree("${category}")`)
  }

  if (genderizedCategory && genderizedCategory != '') {
    queryArgs.filter.push(`categories.id:subtree("${genderizedCategory}")`)
  }

  if (colors && colors.length > 0) {
    queryArgs.filter.push(`variants.attributes.main_color.en:${colorsForCT}`)
  }

  if (genderCategory && genderCategory != '') {
    queryArgs.filter.push(`categories.id:subtree("${genderCategory}")`)
  }
  if (sortPriceOrder && sortPriceOrder !== 'none') {
    queryArgs.sort.push(`price ${sortPriceOrder}`)
  } else {
    queryArgs.sort.push('score desc')
    queryArgs.sort.push('variants.attributes.product_relevance desc')
  }

  return await apiRoot
    .productProjections()
    .search()
    .get({
      queryArgs: queryArgs,
    })
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getBrandsAssociatedToCategoryById = async (categoryId: string) => {
  const queryArgs: any = {
    filter: [`categories.id:subtree("${categoryId}")`],
    staged: true,
    limit: 0,
    expand: ['categories[*].ancestors[*]'],
    markMatchingVariants: true,
    facet: ['variants.attributes.brand.en'],
    'filter.facets': [`categories.id:subtree("${categoryId}")`],
  }
  return await apiRoot
    .productProjections()
    .search()
    .get({
      queryArgs: queryArgs,
    })
    .execute()
    .then((response) => {
      return response.body
    })
    .catch((error) => {
      console.log(error)
      return undefined
    })
}

export const getProductsProjectionSearchForGenderSaleSlider = async (
  gender = '',
  targetGroup?: string,
  stores: string[] | null = [],
): Promise<ProductProjectionPagedSearchResponse | undefined> => {
  const storesForCT = stores?.map((item) => `"${item}"`).join(',')

  const queryArgs: any = {
    filter: [],
    staged: true,
    limit: 10,
    priceCountry: 'DK',
    priceCurrency: 'DKK',
    sort: ['variants.attributes.product_relevance desc'],
  }
  queryArgs.filter.push(`variants.prices.discounted.value.centAmount:range(1 to *)`)

  if (stores && stores.length > 0) {
    queryArgs.filter.push(`variants.availability.isOnStockInChannels:${storesForCT}`)
  }
  if (gender && gender !== 'kids') {
    queryArgs.filter.push(`variants.attributes.gender.en:"${gender}"`)
  }
  if (targetGroup) {
    queryArgs.filter.push(`variants.attributes.target_group.en:"${targetGroup}"`)
  }

  const initialResponse = await apiRoot
    .productProjections()
    .search()
    .get({ queryArgs })
    .execute()
    .catch((error) => {
      console.error(error, 'error for gender slider')
      return undefined
    })

  if (!initialResponse) return undefined

  let allResults = initialResponse.body.results
  const allProductIds = new Set(allResults.map((product) => product.id))

  if (initialResponse.body.count < 10) {
    const secondQueryArgs: any = {
      filter: ['categories:exists'],
      staged: true,
      limit: 10,
    }
    const remaining = 10 - initialResponse.body.count
    secondQueryArgs.limit = remaining
    secondQueryArgs.filter.push(`variants.price.centAmount:range (1 to *)`)
    if (stores && stores.length > 0) {
      secondQueryArgs.filter.push(`variants.availability.isOnStockInChannels:${storesForCT}`)
    }
    if (gender && gender !== 'kids') {
      secondQueryArgs.filter.push(`variants.attributes.gender.en:"${gender}"`)
    }
    if (targetGroup) {
      secondQueryArgs.filter.push(`variants.attributes.target_group.en:"${targetGroup}"`)
    }

    const additionalResponse = await apiRoot
      .productProjections()
      .search()
      .get({ queryArgs: secondQueryArgs })
      .execute()
      .catch((error) => {
        console.error(error, 'error fetching additional products for gender slider')
        return undefined
      })

    if (additionalResponse && additionalResponse.body.results) {
      const additionalResults = additionalResponse.body.results.filter((product) => !allProductIds.has(product.id))
      allResults = [...allResults, ...additionalResults]
    }
  }

  return {
    ...initialResponse.body,
    results: allResults,
    count: allResults.length,
  }
}

export const getSubSubCategoriesFromCommercetoolsForMainCategories = async (categoryId: string): Promise<any[]> => {
  try {
    const response = await apiRoot
      .categories()
      .get({
        queryArgs: {
          where: `parent(id="${categoryId}")`,
        },
      })
      .execute()

    const subCategories = response.body.results

    for (const subCategory of subCategories) {
      const deeperSubCategories = await apiRoot
        .categories()
        .get({
          queryArgs: {
            where: `parent(id="${subCategory.id}")`,
          },
        })
        .execute()
      ;(subCategory as any).subCategories = deeperSubCategories.body.results
    }

    return subCategories
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getProductsByChannelKey = (channelKey: string, channelId: string, limit: number, offset: number, searchValue: string) => {
  const queryArgs = {
    filter: [`variants.availability.channels.${channelId}.availableQuantity:range (0 to *)`],
    staged: true,
    limit: limit,
    offset: offset * limit,
    priceChannel: channelKey,
    priceCountry: 'DK',
    priceCurrency: 'DKK',
    expand: ['categories[*].ancestors[*]'],
    sort: ['variants.attributes.product_relevance desc'],
    [`text.en`]: `${searchValue}`,
    fuzzy: true,
  }

  return apiRoot
    .productProjections()
    .search()
    .get({ queryArgs })
    .execute()
    .then((response) => {
      return response.body
    })
}
