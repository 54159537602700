import { Flex, Heading, Text } from '@chakra-ui/react'
import { useCart } from '../../../context/CheckoutCartContext'
import { Cart, DiscountedLineItemPriceForQuantity, LineItem, Maybe } from '../../../generated/graphql'
import { CartItem } from './CartItem'

type CartItemContainerProps = {
  cart: Cart
  hideControls?: boolean
}

export const CartItemContainer = ({ cart, hideControls }: CartItemContainerProps) => {
  const { getUniqueDistributionChannels } = useCart()

  const getRelevantCartItems = (channelId: string, productKey: string) => {
    return cart.lineItems.filter((lineItem: Maybe<LineItem>) => {
      if (lineItem?.distributionChannel && lineItem.productKey) {
        return lineItem?.distributionChannel.id == channelId && lineItem.productKey == productKey
      } else {
        return false
      }
    })
  }

  const groupByTarget = (dppqs: DiscountedLineItemPriceForQuantity[], triggerQuantity: number, discountedQuantity: number) => {
    const amountInOthers = triggerQuantity - discountedQuantity

    const [firstDiscountedLineItemPriceForQuantity, ...otherDiscountedLineItemPriceForQuantitys] = dppqs

    const modifyedFirstDiscountedLineItemPriceForQuantity = { ...firstDiscountedLineItemPriceForQuantity }
    modifyedFirstDiscountedLineItemPriceForQuantity.quantity = discountedQuantity

    const totalOthers = otherDiscountedLineItemPriceForQuantitys
      .map((dppq: DiscountedLineItemPriceForQuantity) => dppq.quantity)
      .reduce((acc, current) => acc + current, 0)

    const hasOthers = otherDiscountedLineItemPriceForQuantitys.length > 0

    const indexOne = hasOthers ? { ...otherDiscountedLineItemPriceForQuantitys[0] } : { ...firstDiscountedLineItemPriceForQuantity }
    indexOne.quantity = amountInOthers
    const indexTwo = hasOthers ? { ...otherDiscountedLineItemPriceForQuantitys[0] } : { ...firstDiscountedLineItemPriceForQuantity }
    indexTwo.quantity = totalOthers - amountInOthers

    const returnArray = [modifyedFirstDiscountedLineItemPriceForQuantity, indexOne, indexTwo]

    return returnArray
  }

  const makeDiscountedLineItemPriceForQuantityArray = (
    productKey: string,
    channelId: string,
    discountedLineItemPriceForQuantityArray: DiscountedLineItemPriceForQuantity[],
  ) => {
    if (discountedLineItemPriceForQuantityArray.length === 0) {
      return []
    }

    // Extract trigger and discounted quantities from the first element
    const triggerQuantity = discountedLineItemPriceForQuantityArray[0].discountedPrice.includedDiscounts[0].discount.obj?.target.triggerQuantity ?? 0
    const discountedQuantity = discountedLineItemPriceForQuantityArray[0].discountedPrice.includedDiscounts[0].discount.obj?.target.discountedQuantity ?? 0

    // Get relevant cart items
    const relevantCartItems = getRelevantCartItems(channelId, productKey)

    // Initialize an array to store discounted line item prices for quantity
    const dppqs: DiscountedLineItemPriceForQuantity[] = []

    // Loop through relevant cart items
    for (const lineItem of relevantCartItems) {
      const discountedPricePerQuantity = lineItem?.discountedPricePerQuantity

      if (discountedPricePerQuantity) {
        // Logic to add items to dppqs array
        discountedPricePerQuantity.forEach((dppq: DiscountedLineItemPriceForQuantity) => {
          if (dppqs.find((existingDppq) => existingDppq.discountedPrice.value.centAmount == 0)) {
            if (dppq.discountedPrice.value.centAmount > 0) {
              dppqs.push({ ...dppq })
            }
          } else {
            dppqs.push(dppq)
          }
        })
      }
    }

    return groupByTarget(dppqs, triggerQuantity, discountedQuantity)
  }

  return (
    <Flex gap={'1rem'} direction={'column'} h={'full'} justifyContent={'space-between'}>
      {getUniqueDistributionChannels().map((value: any, index: number) => {
        const cartItemAmount = cart.lineItems
          .filter((lineItem: Maybe<LineItem>) => lineItem?.distributionChannel?.obj?.key == value.obj.key)
          .map((lineItem: Maybe<LineItem>) => lineItem?.quantity)
          .reduce((acc, current) => (acc ?? 0) + (current ?? 0), 0)

        return (
          <Flex
            key={value.id}
            borderRadius={'1rem'}
            padding={'1rem'}
            bgColor={'#F1F1ED'}
            boxShadow={'0 4px 40px 0 #9da4a04f'}
            flex={1}
            direction={'column'}
            gap={'1rem'}
          >
            <Flex direction={'column'}>
              <Heading fontSize={'lg'}>Store · {value.obj?.name.en}</Heading>
              <Text>
                {cartItemAmount} {cartItemAmount == 1 ? 'item' : 'items'}
              </Text>
            </Flex>
            {cart.lineItems
              .filter((lineItem: Maybe<LineItem>) => lineItem?.distributionChannel?.obj?.key == value.obj.key)
              .map((lineItem: Maybe<LineItem>, index: number) => {
                return (
                  lineItem && (
                    <CartItem
                      key={value.id}
                      lineItem={lineItem}
                      customDiscountedLineItemPriceForQuantities={makeDiscountedLineItemPriceForQuantityArray(
                        lineItem.productKey,
                        lineItem.distributionChannel?.id ?? '',
                        lineItem.discountedPricePerQuantity,
                      )}
                      hideControls={hideControls}
                    />
                  )
                )
              })}
          </Flex>
        )
      })}
    </Flex>
  )
}
