import { Flex, Stack, StackDivider, Text, useBreakpointValue } from '@chakra-ui/react'
import { Store } from '@commercetools/platform-sdk'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllStoresWithExpand } from '../../commercetools/requests'
import { Map } from './Map'

export const StoreLocationList = () => {
  const navigate = useNavigate()
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false })

  const [stores, setStores] = useState<Store[]>([])
  const [highlightedMarkerIds, setHighlightedMarkerIds] = useState<string[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllStoresWithExpand()
      if (result?.results) {
        setStores(result.results)
      }
    }

    fetchData()
  }, [])

  const extractGeoLocation = (distributionChannels: any[]): { lat: number; lng: number } | null => {
    if (!distributionChannels || distributionChannels.length === 0) return null

    const firstChannel = distributionChannels[0]
    const geoLocation = firstChannel.obj?.geoLocation

    if (!geoLocation || geoLocation.type !== 'Point' || geoLocation.coordinates.length !== 2) return null

    return {
      lat: geoLocation.coordinates[1],
      lng: geoLocation.coordinates[0],
    }
  }

  const makeMarkers = () => {
    return stores.map((value: Store) => {
      const geoLocation = extractGeoLocation(value.distributionChannels)
      return { id: value.id, lat: geoLocation?.lat ?? 0, lng: geoLocation?.lng ?? 0 }
    })
  }

  const addHighlightedMarkerId = (idToAdd: string) => {
    setHighlightedMarkerIds((prevIds) => [...prevIds, idToAdd])
  }

  const removeHighlightedMarkerId = (idToRemove: string) => {
    setHighlightedMarkerIds((prevIds) => prevIds.filter((id) => id !== idToRemove))
  }

  const paddingLeft = isMobile ? '64px' : '128px'

  return (
    <Flex width={'full'} gap={isMobile ? '34px' : 3} py={'80px'} direction={isMobile ? 'column-reverse' : 'row'}>
      <Flex flex={isMobile ? '0' : '1'} direction={'column'} gap={'34px'}>
        <Text paddingLeft={paddingLeft} fontSize={'48px'}>
          Find a store near you
        </Text>
        <Stack divider={<StackDivider m={'0!important'} borderColor={'beigeLight.500'} />}>
          <Text pb={'24px'} paddingLeft={paddingLeft} color={'#9DA4A0'} fontSize={'24px'}>
            {stores.length} stores near you
          </Text>
          {stores.map((value: Store, index: number) => {
            return (
              <Flex
                paddingY={'24px'}
                onMouseEnter={() => addHighlightedMarkerId(value.id)}
                onMouseLeave={() => removeHighlightedMarkerId(value.id)}
                onClick={() => navigate('/stores/' + value.key)}
                paddingLeft={paddingLeft}
                direction={'column'}
                gap={'8px'}
                _hover={{ bgColor: 'beigeLight.300', cursor: 'pointer' }}
                key={index}
              >
                <Text fontSize={'24px'}>{value.name?.en}</Text>
                <Text fontSize={'18px'}>
                  {value.distributionChannels[0].obj?.address?.streetName +
                    ' ' +
                    value.distributionChannels[0].obj?.address?.streetNumber +
                    ', ' +
                    value.distributionChannels[0].obj?.address?.city}
                </Text>
              </Flex>
            )
          })}
        </Stack>
      </Flex>
      <Flex
        flex={isMobile ? 'none' : 1}
        width={'100dvw'}
        height={isMobile ? '300px' : '100%'}
        borderTopLeftRadius={isMobile ? 0 : 24}
        borderBottomLeftRadius={isMobile ? 0 : 24}
        overflow={'hidden'}
      >
        <Map markers={makeMarkers()} zoom={12} centerAt={{ lat: 56.1629, lng: 10.2039 }} highLightedMarkers={highlightedMarkerIds} />
      </Flex>
    </Flex>
  )
}
