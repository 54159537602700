import { Button, Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { TbMap2 } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { GenderCategoryCardRows } from '../../../components/categoryCard/GenderCategoryCard/GenderCategoryCardRows/GenderCategoryCardRows'
import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { LeafLetMap } from '../../../components/LeafletMap/LeafletMap'
import { NewStoreSlider } from '../../../components/newStoreSlider/NewStoreSlider'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { Theme } from '../../../ThemeVariables'
import Sheet from 'react-modal-sheet'
import { PopularBrandsByMainCategory } from './components/PopularBrandsByMainCategory'
import { SubCategoriesByMainCategory } from './components/SubcategoriesByMainCategory'
import { WhyShopHereBox } from './components/WhyShopHereBox'
import { useScrollbarWidth } from '../../../hooks/useScrollBarWidth'
import { ClusterOfPillsForTopOfSite } from '../../../components/BackgroundPills/ClusterOfPillsForTopOfSite'
import withAuth from '../../../utils/WithAuth'
import { MainRole } from '../../../generated/graphql'

const LandingPage = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [isMapSheetOpen, setIsMapSheetOpen] = useState(false)
  const { clearBreadcrumbs } = useBreadcrumbs()
  const navigate = useNavigate()
  const scrollbarWidth = useScrollbarWidth()

  const [isLocationPillSheetOpen, setIsLocationPillSheetOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    clearBreadcrumbs()

    setIsLoading(false)
  }, [])
  if (isLoading) {
    return <div></div>
  }
  return (
    <MainPageResponsive loading={false}>
      <Flex width={'100%'} textAlign={'center'} alignItems={'center'} direction={'column'}>
        <ClusterOfPillsForTopOfSite />

        <Flex
          mx={{ base: 'auto', md: '0' }}
          backgroundColor={'white'}
          alignSelf={'flex-start'}
          justifyContent={'center'}
          px={Theme.PaddingOnSides.LandingPages}
        >
          <GenderCategoryCardRows homePageText={true} />
        </Flex>
        <Flex backgroundColor={'beigeLight.500'} width={'100%'}>
          <Flex
            direction={'column'}
            gap={{ base: '1rem', md: '3rem' }}
            py={{ base: '2.5rem', md: '3.5rem' }}
            maxWidth={`calc(100vw - ${scrollbarWidth}px)`}
            px={Theme.PaddingOnSides.LandingPages}
            overflowX={'scroll'}
            css={{ '&::-webkit-scrollbar': { display: 'none' } }}
          >
            <Flex gap={'2rem'} direction={'column'}>
              <Flex gap={{ base: '0.25rem', sm: '0.25rem', md: '1rem' }} textAlign={'start'} direction={'column'} alignSelf={'start'}>
                <Heading variant={'headingForComponentOnAMainPage'}> {t('main_app.homepage.heading_store_slider')}</Heading>
                <Text variant={'textForComponentOnAMainPageWhoHasAHeading'}>{t('main_app.homepage.text_store_slider')}</Text>
              </Flex>
              <NewStoreSlider />
            </Flex>
            <Flex justifyContent={{ base: 'center', md: 'center' }}>
              <Button
                variant={'solid'}
                justifyContent={'center'}
                gap={4}
                onClick={() => {
                  if (isLargerThan768) {
                    navigate('/store-map')
                  } else {
                    setIsMapSheetOpen(true)
                  }
                }}
              >
                {t('components.button.show_stores_on_map')}
                <TbMap2 />
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Sheet isOpen={isMapSheetOpen} onClose={() => setIsMapSheetOpen(false)} snapPoints={[1, 0]} initialSnap={0}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content
              style={{
                height: '90vh',
                overflowY: 'auto',
                overscrollBehavior: 'contain',
                WebkitOverflowScrolling: 'touch',
              }}
            >
              <Flex direction={'column'} gap={2}>
                <Flex direction={'column'} alignItems={'center'}>
                  <Text color={'#C1C1C1'} fontSize={'16px'}>
                    {t('components.leaflet_map.aarhus')}
                  </Text>
                  <Heading fontSize={Theme.heading.headerMobile30px}>{t('components.leaflet_map.stores')}</Heading>
                </Flex>
                <LeafLetMap />
                <Button
                  onClick={() => {
                    setIsMapSheetOpen(false)
                    setIsLocationPillSheetOpen(true)
                  }}
                >
                  {t('components.button.show_stores_on_map')}
                </Button>
              </Flex>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

        <Flex
          backgroundColor={'white'}
          py={{ base: '4.5rem', md: '6.5rem' }}
          px={Theme.PaddingOnSides.LandingPages}
          width={'100%'}
          direction={'column'}
          justifyContent={'center'}
        >
          <Flex maxW={{ base: '100%', md: '70%' }} direction={'column'} gap={{ base: '2rem', md: '9rem' }}>
            <PopularBrandsByMainCategory fontSize={'24px'} fontWeight={'400'} />
            <SubCategoriesByMainCategory
              fontSizeGender={'24px'}
              gapBetweenGender={'5rem'}
              header={t('main_app.landing_page.heading_categories')}
              fontSize={'24px'}
              fontWeight={'400'}
              marginBottomBetweenHeaderAndGender={{ base: '1rem', md: '1.5rem' }}
            />
          </Flex>
        </Flex>
        <Flex width={'100%'}>
          <WhyShopHereBox />
        </Flex>
      </Flex>
    </MainPageResponsive>
  )
}
export default withAuth(LandingPage, [MainRole.RoleGuest])
