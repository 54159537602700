import { defineStyle, defineStyleConfig } from '@chakra-ui/react'


const textCleanBlack = defineStyle({
  color: 'var(--black, #000)',
  textAlign: 'center',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
})
const textCleanWhite = defineStyle({
    color: 'var(--black, #FFF)',
    fontFamily: '"Suisse Intl", sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    '@media (max-width: 779px)': {
      fontSize: '16px',
    },
    '@media (min-width: 780px) and (max-width: 1399px)': {
      fontSize: '14px',
    },
    '@media (min-width: 1400px)': {
      fontSize: '16px',
    },
  })

  
const textForComponentOnAMainPageWhoHasAHeading = defineStyle({
    color: 'var(--black, #000)',
    fontFamily: '"Suisse Intl", sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '28px',
  '@media (max-width: 768px)': {  
    fontSize: '16px',
    letterSpacing: "-0.56px"
  },
});
const textProductCardBrand = defineStyle({
  color: 'var(--black, #0B1C12)',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.75rem',
})

const textProductCardName = defineStyle({
  color: 'var(--black, #0B1C12)',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1.375rem',
  overflow: "hidden",
  
})
const textProductCardPrice = defineStyle({
  color: 'var(--black, #0B1C12)',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '1.375rem',
})
const storeCardStoreNameStyling = defineStyle({
  color: 'var(--interface-colors-black-90, #121212)',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '-0.84px',
});
const storeCardStoreAddresStyling = defineStyle({
  color: 'var(--interface-colors-black-90, #121212)',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '22px', 
  letterSpacing: '-0.27px',
  '@media (max-width: 768px)': {  
    fontSize: '16px',
    letterSpacing: "-0.24px",
  },
});
  const textDropDownMenuSubCategories = defineStyle({
    color: 'var(--interface-colors-black-90, #121212)',
    fontFamily: '"Suisse Intl", sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.375rem',
    letterSpacing: "-0.24px ",
    opacity: "0.7"
  })
  const textDropDownMenuMainCategories = defineStyle({
    color: 'var(--black, #000)',
    textAlign: 'center',
    fontFamily: '"Suisse Intl", sans-serif',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  })



export const textTheme = defineStyleConfig({
  variants: {
    storeCardStoreAddresStyling,
    storeCardStoreNameStyling,
    textCleanBlack,
    textForComponentOnAMainPageWhoHasAHeading,
    textCleanWhite,
    textProductCardBrand,
    textProductCardName,
    textProductCardPrice,
    textDropDownMenuSubCategories,
    textDropDownMenuMainCategories
  },
})
