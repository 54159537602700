import { ApolloError } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { ErrorBox } from '../../../ErrorBox'
import { PageSpinner } from '../../../PageSpinner'
import { Footer } from './Footer'
import { Header } from './Header'

type MainPageDesktopProps = {
  children: ReactElement
  loading: boolean
  error?: ApolloError
}

export const MainPageDesktop = (props: MainPageDesktopProps) => {
  const Render = () => {
    if (props.error) {
      return <ErrorBox message={props.error.message} />
    } 
    
    if (props.loading) {
      return (
        <Flex>
          <PageSpinner />
        </Flex>
      )
    } else {
      return <Flex flex={1}>{props.children}</Flex>
    }
  }

  return (
    <Flex maxW={'80%'} mx={'auto'} flex={1} direction={'column'}>
      <Header />
      {/* <Box w={'100%'} zIndex={9}>
        <Breadcrumb separator={<TbChevronRight color='gray.500' />} spacing='8px'>
          {breadcrumbs.map((breadcrumb) => (
            <BreadcrumbItem key={breadcrumb.title} onClick={() => navigateTo(breadcrumb?.clickablePath)} isCurrentPage={breadcrumb?.active}>
              <BreadcrumbLink>{breadcrumb.title}</BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Box> */}
      <Render />
      <Footer />
    </Flex>
  )
}
