import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: 28,
    padding: 2,
  },
  closeButton: {
    top: 5,
    right: 5,
  },
})

const dialog = definePartsStyle({
  header: {
    px: 4
  },
  body: {
    px: 4,
    py: 0
  },
  footer: {
    px: 4
  }
})

export const modalTheme = defineMultiStyleConfig({
  variants: { dialog },
  baseStyle,
})
