import { Flex } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { ContentHeader } from '../../../../pages/partner_portal/ContentHeader'
import { Header } from '../merchant/Header'
import { SideNav } from '../merchant/SideNav'
import { AdminSideNav } from './AdminSideNav'

type AdminPartnerPortalPageProps = {
  children: any
  pageHeaderLabel?: string
  rightHeaderContent?: ReactElement
  back?: any
}

export const AdminPartnerPortalPage = (props: AdminPartnerPortalPageProps) => {
  const RenderHeader = () => {
    return (
      <Flex justifyContent={'space-between'} alignItems={'top'} mb={10}>
        <ContentHeader back={props.back} label={props.pageHeaderLabel || ''} />
        { props.rightHeaderContent ? props.rightHeaderContent : <></> }
      </Flex>
    )
  }

  return (
    <Flex flex={1}>
      <AdminSideNav />
      <Flex overflowY={'auto'} direction={'column'} w={'full'} h={'100vh'}>
        <Header />
        <Flex flex={1} direction={'column'} p={10}>
          {props.pageHeaderLabel ? <RenderHeader /> : null}
          {props.children}
        </Flex>
      </Flex>
    </Flex>
  )
}
