import { Flex, Img, SkeletonText, Stack, Tag, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { centPriceToPrice, moneyFormat } from '../../../../../utils/MoneyUtil'

type OrderLineItemProps = {
  quantity?: number
  imageUrl?: string
  name?: string
  styleNumber?: string
  size?: string
  color?: string
  price?: number
  ean?: string
}
export const OrderLineItemCard = ({ quantity, imageUrl, name, styleNumber, size, color, price, ean }: OrderLineItemProps) => {
  return (
    <Flex gap={10}>
      <Flex position={'relative'}>
        <Tag borderRadius={'full'} variant={'subtle'} position={'absolute'} right={-3} top={-3}>
          {quantity + 'x'}
        </Tag>
        <Img maxH={'36'} borderRadius={'md'} src={imageUrl || 'https://onlinepngtools.com/images/examples-onlinepngtools/doggie-bmp.png'}></Img>
      </Flex>
      <Flex>
        <Stack spacing={1} w={400} justifyContent={'space-between'}>
          <>
            <Flex direction={'column'}>
              <>
                <Text fontSize={'sm'}>{name || <i>{t('partner_portal.merchant.orders.components.text_product_name')}</i>}</Text>
                <Text fontSize={'sm'}>
                  {t('partner_portal.merchant.orders.components.text_product_style_number.0')}{' '}
                  {styleNumber || <i>{t('partner_portal.merchant.orders.components.text_product_style_number.1')}</i>}
                </Text>
                {/*<Text fontSize={'sm'}>*/}
                {/*  {t('partner_portal.merchant.orders.components.text_product_brand.0')}{' '}*/}
                {/*  {chainName || (*/}
                {/*    <i>{t('partner_portal.merchant.orders.components.text_product_brand.1')}</i>*/}
                {/*  )}*/}
                {/*</Text>*/}
                <Text fontSize={'sm'}>
                  {t('partner_portal.merchant.orders.components.text_product_size.0')}{' '}
                  {size || <i>{t('partner_portal.merchant.orders.components.text_product_size.1')}</i>}
                </Text>
                <Flex alignItems={'center'} gap={2}>
                  <Text fontSize={'sm'}>{t('partner_portal.merchant.orders.components.text_product_color.0')} </Text>
                  <Tag variant={'subtle'} bgColor={'blackAlpha.50'} color={'black'}>
                    {color || <i>{t('partner_portal.merchant.orders.components.text_product_color.1')}</i>}
                  </Tag>
                </Flex>
              </>
            </Flex>
            <Flex direction={'column'}>
              {/*//TODO: NEED TO TAKE THE DIGITS FROM CT*/}
              <Text fontSize={'sm'}>Price: {moneyFormat(centPriceToPrice(price || 0, 2), 'DKK', 'da-DK')}</Text>
              <Text fontWeight={'bold'} fontSize={'sm'}>
                {t('partner_portal.merchant.orders.components.text_ean')} {ean}
              </Text>
            </Flex>
          </>
        </Stack>
      </Flex>
    </Flex>
  )
}
