import React, { useState, useEffect } from 'react'
import { Box, Flex, Image, LinkBox, LinkOverlay, Skeleton, Text } from '@chakra-ui/react'
import { t } from 'i18next'

const BASE_URL = 'https://storage.googleapis.com/bucket-plenty-dev/GenderCategoryImages'

const IMAGES = {
  Men: `${BASE_URL}/male.webp`,
  Women: `${BASE_URL}/female.webp`,
  Girls: `${BASE_URL}/Kids.webp`,
}
type GenderCategoryCardProps = {
  label: string
  width?: string
  borderRadius: string
  to: string
  category: 'Men' | 'Women' | 'Kids' | string
  fontSize: string
}

export const GenderCategoryCard = (props: GenderCategoryCardProps) => {
  const [imageIsLoaded, setImageIsLoaded] = useState(false)

  const chooseCategory = () => {
    switch (props.category) {
      case t('components.category_cards.women'):
        return IMAGES.Women
      case t('components.category_cards.men'):
        return IMAGES.Men
      case t('components.category_cards.kids'):
        return IMAGES.Girls
      default:
        return undefined
    }
  }

  useEffect(() => {
    setImageIsLoaded(false) 
  }, [props.category])

  return (
    <LinkBox>
      <LinkOverlay href={props.to}>
        <Flex width={props.width} justifyContent={'center'} position='relative' direction={'column'}>
          <Skeleton isLoaded={imageIsLoaded}>
            <Box borderRadius={props.borderRadius} overflow='hidden' position='relative'>
              <Image
                onLoad={() => setImageIsLoaded(true)}
                objectFit={'contain'}
                src={chooseCategory()}
                zIndex='1' 
                maxW={'auto'}
              />
            </Box>
          </Skeleton>

          <Box
            position='absolute'
            left={'15%'}
            bottom={0}
            transform='translate(-50%, -50%)'
            borderRadius='56px'
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
          >
            <Text color={'white'} fontSize='24px'>
              {props.label}
            </Text>
          </Box>
        </Flex>
      </LinkOverlay>
    </LinkBox>
  )
}
