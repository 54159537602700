import { Flex, Text } from '@chakra-ui/react'
import { centPriceToPrice, moneyFormat } from '../../../../utils/MoneyUtil'
import { getCheapestProductPrice, getLowestCentAmountFromPrice } from '../../../../utils/PDPUtil'
import { PlentyProduct, ProductVariantPrice } from '../types'

type ProductPriceProps = {
  product: PlentyProduct
  channelId: string | undefined
}

export const ProductPrice = (props: ProductPriceProps) => {
  const price: ProductVariantPrice = getCheapestProductPrice(props.product, props.channelId)
  const lowestCentAmount = getLowestCentAmountFromPrice(price)
  const centPrice = centPriceToPrice(lowestCentAmount, 2)
  const absoluteCentPrice = Math.abs(centPrice)
  const priceFormatted = moneyFormat(absoluteCentPrice, 'DKK', 'da-DK')

  const discount = price.discounted

  if (discount) {
    return (
      <Flex gap={2} alignItems={'center'}>
        <Text fontSize={'xl'} fontWeight={'bold'} color={'red.600'}>
          {priceFormatted}
        </Text>
        <Text fontSize={'md'} decoration={'line-through'} color={'gray.500'}>
          {moneyFormat(Math.abs(centPriceToPrice(price.value.centAmount, price.value.fractionDigits)), 'DKK', 'da-DK')}
        </Text>
      </Flex>
    )
  } else {
    return <Text variant={'textProductCardPrice'}>{priceFormatted}</Text>
  }
}
