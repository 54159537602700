import { useEffect } from 'react'
import { MainPageResponsive } from '../../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { useBreadcrumbs } from '../../../../context/BreadcrumbsContext'
import { CookiePoliticInformationText } from '../components/CookiePoliticInformationText'

export const CookiePolicyDesktop = () => {
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  return (
    <MainPageResponsive loading={false}>
      <CookiePoliticInformationText />
    </MainPageResponsive>
  )
}
