import { Button, Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'

type FilterButtonProps = {
  label: string
  icon?: any
  size?: string
  variant: 'Solid' | 'Red' | 'Green'
  action: () => any
  isToggled: boolean
  isDisabled?: boolean
  hidden?: boolean
  amount: number
  underlineColor?: string
}

export const FilterButton = (props: FilterButtonProps) => {
  const chooseVariant = () => {
    if (props.isToggled) {
      switch (props.variant) {
        case 'Solid':
          return 'solid'
        case 'Green':
          return 'solidGreen'
        case 'Red':
          return 'solidRed'
      }
    } else {
      switch (props.variant) {
        case 'Solid':
          return 'solidWhite'
        case 'Green':
          return 'outlineGreen'
        case 'Red':
          return 'outlineRed'
      }
    }
  }

  if (props.icon) {
    return (
      <Button
        hidden={props.hidden ? props.hidden : false}
        isDisabled={props.isDisabled}
        onClick={() => {
          props.action()
        }}
        size={props.size ? props.size : 'lg'}
        leftIcon={props.icon}
        variant={chooseVariant()}
      >
        {props.label}
      </Button>
    )
  } else {
    return (
      <Button
        hidden={props.hidden ? props.hidden : false}
        isDisabled={props.isDisabled}
        onClick={() => {
          props.action()
        }}
        size={props.size ? props.size : 'lg'}
        variant={chooseVariant()}
      >
        <Flex gap={1}>
          <Flex direction={'column'}>
            <Text>{props.label}</Text>
            {props.underlineColor ? <Flex bgColor={props.underlineColor} w={'full'} h={0.5} borderRadius={'full'}></Flex> : null}
          </Flex>
          <Text fontSize={'xs'}>{props.amount}</Text>
        </Flex>
      </Button>
    )
  }
}
