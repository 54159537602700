import { Flex, Text } from '@chakra-ui/react'
import { useCart } from '../../../../context/CheckoutCartContext'

export const CheckoutDeliverySummary = () => {
  const { customerInformation, cartDeliveryOption, getUniqueDistributionChannels } = useCart()

  const getDeliveryPrice = () => {
    switch (cartDeliveryOption) {
      case 'HomeDelivery':
        return '39 kr'
      case 'ParcelShop':
        return '29 kr'
      case 'Pickup':
        return 'Free'
    }
  }

  const getDeliveryText = () => {
    switch (cartDeliveryOption) {
      case 'HomeDelivery':
        return 'Package to your address'
      case 'ParcelShop':
        return 'Package to the parcel shop of your choice'
      case 'Pickup':
        return getUniqueDistributionChannels().length == 1 ? 'Pick up at store' : 'Pick up at stores'
    }
  }

  const getDeliveryTime = () => {
    switch (cartDeliveryOption) {
      case 'HomeDelivery':
        return 'Delivery time 1 - 4 weekdays'
      case 'ParcelShop':
        return 'Delivery time 1 - 4 weekdays'
      case 'Pickup':
        return 'Swift pickup'
    }
  }

  return (
    <Flex direction={'column'} gap={'1rem'}>
      <Flex gap={'0.25rem'} direction={'column'}>
        <Text fontWeight={'bold'}>Delivery address</Text>
        <Text>{`${customerInformation.firstName} ${customerInformation.lastName}`}</Text>
        <Text hidden={cartDeliveryOption === 'Pickup'}>
          {`${customerInformation.street} ${customerInformation.number} ${customerInformation.apartment}, ${customerInformation.postalCode} ${customerInformation.city}`}
        </Text>
        <Text>{customerInformation.email}</Text>
        <Text>{customerInformation.phone}</Text>
      </Flex>
      <Flex gap={'0.25rem'} direction={'column'}>
        <Text fontWeight={'bold'}>Delivery option</Text>
        <Text>{getDeliveryPrice()}</Text>
        <Text>{getDeliveryText()}</Text>
        <Text hidden={cartDeliveryOption == 'Pickup'}>{getDeliveryTime()}</Text>
      </Flex>
    </Flex>
  )
}
