import { Flex, Heading } from '@chakra-ui/react'
import { t } from 'i18next'
// import SearchField from '../../../../components/TextField/SearchField'
import { Theme } from '../../../../ThemeVariables'
import { DeliveryAccordion } from '../components/DeliveryAccordion'
import { OrdersAccordion } from '../components/OrdersAccordion'
import { PaymentsAccordion } from '../components/PaymentsAccordion'
import { ReturnAndRefundsAccordion } from '../components/ReturnAndRefundsAccordion'

export const CustomerServiceDesktop = () => {
  return (
    <Flex gap={Theme.gap.desktop} direction={'column'} width={'95vw'} pb={'3.5rem'} maxWidth={'53rem'} mx={'auto'}>
      <Heading textAlign={'center'}>{t('components.layout.text_faq')}</Heading>
      <Flex w={'full'} direction={'column'} gap={'60px'} mb={10}>
        <DeliveryAccordion />
        <OrdersAccordion />
        <PaymentsAccordion />
        <ReturnAndRefundsAccordion />
      </Flex>
    </Flex>
  )
}
