import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  Img,
  DrawerBody,
  Box,
} from '@chakra-ui/react'
import { Theme } from '../../../ThemeVariables'

import { useEffect, useState } from 'react'
import { TbArrowLeft } from 'react-icons/tb'
import { getAllCategoriesSortedByOrderHint } from '../../../commercetools/requests'
import i18n from '../../../I18n/i18n'
import { Level3CategoriesCategoryDrawer } from './SubSubCategoryDrawer'
type Categories = {
  categoryId: string
  categoryName: string
  categoryNameEn: string
  parentCategoryId: string | null
  key: string | undefined
  childCategories?: Categories[]
  orderHint?: any
}
export const CategoryDrawer = ({ isOpen, onClose, selectedGender, onCloseCategoryDrawer }: any) => {
  const FooterIcon = 'https://storage.googleapis.com/bucket-plenty-dev/Header%26Footer/FooterIcon.png'
  const [selectedSubSubCategories, setSelectedSubSubCategories] = useState<Categories[]>([])

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const [isSubSubCategoryDrawerOpen, setIsSubSubCategoryDrawerOpen] = useState(false)
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null)

  const [childCategoriesMap, setChildCategoriesMap] = useState(new Map())
  const [subSubChildCategoriesMap, setSubSubChildCategoriesMap] = useState(new Map())
  const [subSubSubChildCategoriesMap, setSubSubSubChildCategoriesMap] = useState(new Map())
  const genderDisplayMap = {
    Men: 'Men',
    Women: 'Women',
    Kids: 'Kids',
  }
  const menCategoryKey = 'men'
  const kidsCategoryKey = 'kids'
  const womenCategoryKey = 'women'
  const [allCategories, setAllCategories] = useState<Categories[]>([])
  const [languageSelected, setLanguageSelected] = useState<string>(() => {
    const selectedLanguage = localStorage.getItem('languageSelected') ?? i18n.language
    return selectedLanguage === 'DK' ? 'da-DK' : 'en'
  })

  const fetchAllCategories = async () => {
    if (allCategories.length > 0) {
      return
    }

    const categoryLimit = 200
    let categoryOffset = 0

    const initialBatch = await getAllCategoriesSortedByOrderHint(categoryOffset, categoryLimit)
    const totalCategories = initialBatch?.total ?? 0
    const categories = initialBatch?.results ?? []

    const offsetCount = Math.ceil(totalCategories / categoryLimit) - 1
    for (let i = 0; i < offsetCount; i++) {
      categoryOffset = (i + 1) * categoryLimit
      const newCategories = await getAllCategoriesSortedByOrderHint(categoryOffset, categoryLimit)
      categories.push(...(newCategories?.results ?? []))
    }
    const womenCategory = categories.find((category) => category.key === womenCategoryKey)
    const menCategory = categories.find((category) => category.key === menCategoryKey)
    const kidsCategory = categories.find((category) => category.key === kidsCategoryKey)

    if (!kidsCategory || !womenCategory || !menCategory) {
      console.log('Categories not found')
      return
    }

    const childCategories = categories.filter(
      (category) => category.ancestors.some((ancestor) => ancestor.obj?.key === kidsCategoryKey) && category.ancestors.length <= 4,
    )
    const menAndChildrenCategories = [kidsCategory, ...childCategories]

    const womenChildCategories = categories.filter(
      (category) => category.ancestors.some((ancestor) => ancestor.obj?.key === womenCategoryKey) && category.ancestors.length <= 4,
    )
    const womenAndChildrenCategories = [womenCategory, ...womenChildCategories]

    const menChildCategories = categories.filter(
      (category) => category.ancestors.some((ancestor) => ancestor.obj?.key === menCategoryKey) && category.ancestors.length <= 4,
    )
    const menCategories = [menCategory, ...menChildCategories]
    setAllCategories([
      ...menAndChildrenCategories.map((category) => ({
        categoryName: category.name[languageSelected],
        categoryNameEn: category.name.en,

        categoryId: category.id,
        parentCategoryId: category.parent?.id ?? '',
        genderCategoryId: category.ancestors[0] ? category.ancestors[0].id : '',
        key: category.key,
      })),
      ...womenAndChildrenCategories.map((category) => ({
        categoryName: category.name[languageSelected],
        categoryNameEn: category.name.en,

        categoryId: category.id,
        parentCategoryId: category.parent?.id ?? '',
        genderCategoryId: category.ancestors[0] ? category.ancestors[0].id : '',
        key: category.key,
      })),
      ...menCategories.map((category) => ({
        categoryName: category.name[languageSelected],
        categoryNameEn: category.name.en,

        categoryId: category.id,
        parentCategoryId: category.parent?.id ?? '',
        genderCategoryId: category.ancestors[0] ? category.ancestors[0].id : '',
        key: category.key,
      })),
    ])
  }

  useEffect(() => {
    fetchAllCategories()
  }, [])

  const mapThoughAllCategoriesUntilLevel4OfCategories = () => {
    const categoriesToShow = allCategories.filter((category) => category.key === selectedGender.toLowerCase())

    const newChildCategoriesMap = new Map<string, Categories[]>()
    const newSubSubChildCategoriesMap = new Map<string, Categories[]>()
    const newSubSubSubChildCategoriesMap = new Map<string, Categories[]>()

    categoriesToShow.forEach((parentCategory: Categories) => {
      const childCategories = allCategories.filter((category: Categories) => category.parentCategoryId === parentCategory.categoryId)
      newChildCategoriesMap.set(parentCategory.categoryId, childCategories)

      childCategories.forEach((childCategory: Categories) => {
        const subChildCategories = allCategories.filter((subChildCategory: Categories) => subChildCategory.parentCategoryId === childCategory.categoryId)
        newSubSubChildCategoriesMap.set(childCategory.categoryId, subChildCategories)

        subChildCategories.forEach((subChildCategory) => {
          const subSubChildCategories = allCategories.filter(
            (subSubChildCategory: Categories) => subSubChildCategory.parentCategoryId === subChildCategory.categoryId,
          )
          newSubSubSubChildCategoriesMap.set(subChildCategory.categoryId, subSubChildCategories)
        })
      })
    })

    setChildCategoriesMap(newChildCategoriesMap)
    setSubSubChildCategoriesMap(newSubSubChildCategoriesMap)
    setSubSubSubChildCategoriesMap(newSubSubSubChildCategoriesMap)
  }
  useEffect(() => {
    mapThoughAllCategoriesUntilLevel4OfCategories()
  }, [allCategories, selectedGender])

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size='xs'>
        <DrawerOverlay />
        <DrawerContent bgColor={'beigeLight.500'}>
          <DrawerBody p={0}>
            <Flex p={Theme.spacing.padding.mobile} justifyContent={'space-between'}>
              <Img src={FooterIcon} width={'30px'} height={'40px'} />
              <DrawerCloseButton onClick={onClose} position={'relative'} fontSize={'20px'} />
            </Flex>
            <Flex px={Theme.spacing.padding.mobile} onClick={onCloseCategoryDrawer} gap={Theme.gap.mobile} fontSize={'xl'} alignItems={'center'}>
              <TbArrowLeft />
              <Flex>{selectedGender}</Flex>
            </Flex>
            <Accordion p={4} allowMultiple>
              {Array.from(childCategoriesMap.values()).flatMap((childCategories: Categories[]) =>
                childCategories.map((subCategory: Categories) => (
                  <AccordionItem key={subCategory.categoryId}>
                    <h2>
                      <AccordionButton
                        px={0}
                        justifyContent={'space-between'}
                        fontSize={'xl'}
                        onClick={() => {
                          const selectedSubSubCategories = subSubChildCategoriesMap.get(subCategory.categoryId)
                          setSelectedSubSubCategories(selectedSubSubCategories)
                          setIsSubSubCategoryDrawerOpen(true)
                          setSelectedSubCategory(subCategory.categoryNameEn)
                        }}
                      >
                        <Box flex='1' textAlign='left'>
                          {subCategory.categoryName}
                        </Box>
                      </AccordionButton>
                    </h2>
                  </AccordionItem>
                )),
              )}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Level3CategoriesCategoryDrawer
        isOpen={isSubSubCategoryDrawerOpen}
        selectedGender={selectedGender}
        subCategory={selectedSubCategory}
        onClose={() => setIsSubSubCategoryDrawerOpen(false)}
        subSubCategories={selectedSubSubCategories}
        subSubSubCategories={subSubSubChildCategoriesMap}
        onCloseParentDrawer={() => setIsDrawerOpen(false)}
      />
    </>
  )
}
