import { Flex, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { TbAddressBook, TbMail, TbPhone, TbUser } from 'react-icons/tb'

type CustomerInfoModelProps = {
  customerInfo: any
  customerEmail: string
  isOpen: boolean
  setOpen: (value: boolean) => any
}

export const CustomerInfoModal = ({ customerInfo, isOpen, setOpen, customerEmail }: CustomerInfoModelProps) => {
  const Content = () => {
    if (customerInfo === undefined) {
      return <Text fontStyle={'italic'}>{t('partner_portal.merchant.orders.components.text_no_customer')}</Text>
    } else {
      return (
        <Flex gap={2} direction={'column'}>
          <Flex gap={2} alignItems={'center'}>
            <Icon as={TbMail} />
            <Text fontWeight={'bold'}>{t('partner_portal.merchant.orders.components.text_email')} </Text>
            <Text>{customerEmail}</Text>
          </Flex>
          <Flex gap={2} alignItems={'center'}>
            <Icon as={TbUser} />
            <Text fontWeight={'bold'}>{t('partner_portal.merchant.orders.components.text_name')} </Text>
            <Text>{customerInfo.firstName + ' ' + customerInfo.lastName}</Text>
          </Flex>
          <Flex gap={2} alignItems={'center'}>
            <Icon as={TbAddressBook} />
            <Text fontWeight={'bold'}>{t('partner_portal.merchant.orders.components.text_address')} </Text>
            <Text>{customerInfo.streetName + ', ' + customerInfo.postalCode + ' ' + customerInfo.city + ', ' + customerInfo.country}</Text>
          </Flex>
          <Flex gap={2} alignItems={'center'}>
            <Icon as={TbPhone} />
            <Text fontWeight={'bold'}>{t('partner_portal.merchant.orders.components.text_Phone')} </Text>
            <Text>{customerInfo.phone}</Text>
          </Flex>
        </Flex>
      )
    }
  }

  return (
    <Modal size={'2xl'} isOpen={isOpen} onClose={() => setOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('partner_portal.merchant.orders.components.modal_header_customer')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Content />
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
