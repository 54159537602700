import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Stack, Text } from '@chakra-ui/react'
import { ColorCircles } from './ColorCircles'
import { BrandSelector } from './BrandSelector'
import { SizeSelector } from './SizeSelector'
import React from 'react'

export const DrawerContentComponent = () => {
  return (
    <Flex gap={10} direction={'column'}>
      <Accordion p={0} defaultIndex={[0, 1, 2, 3]} allowMultiple>
        {/*<AccordionItem borderColor={'blackAlpha.200'}>*/}
        {/*  <h2>*/}
        {/*     <AccordionButton py={2} px={0}>*/}
        {/*      <Box display={'flex'} as='span' flex='1' textAlign='left'>*/}
        {/*        <Text>Price</Text>*/}
        {/*      </Box>*/}
        {/*      <AccordionIcon />*/}
        {/*    </AccordionButton>*/}
        {/*  </h2>*/}
        {/*  <AccordionPanel pb={4} pt={4}>*/}
        {/*    <RangeSlider*/}
        {/*      variant={'FilledDarkGreyTrack'}*/}
        {/*      onChange={handlePriceChange}*/}
        {/*      aria-label={['min', 'max']}*/}
        {/*      value={[minPrice, maxPrice]}*/}
        {/*      min={0}*/}
        {/*      max={5000}*/}
        {/*      defaultValue={[0, 5000]}*/}
        {/*      step={1}*/}
        {/*      mb={10}*/}
        {/*    >*/}
        {/*      <RangeSliderTrack backgroundColor={'rgba(211, 211, 202, 1)'}>*/}
        {/*        <RangeSliderFilledTrack backgroundColor={'rgba(211, 211, 202, 1)'} />*/}
        {/*      </RangeSliderTrack>*/}
        {/*      <RangeSliderThumb backgroundColor={'black'} index={0}></RangeSliderThumb>*/}
        {/*      <Flex mt={2} position={'absolute'}>*/}
        {/*        <Flex gap={1}>*/}
        {/*          <Flex>{minPrice}</Flex>*/}
        {/*          <Flex>{t('global.text_currency.0')}</Flex>*/}
        {/*        </Flex>*/}
        {/*      </Flex>*/}
        {/*      <RangeSliderThumb backgroundColor={'black'} index={1}></RangeSliderThumb>*/}
        {/*      <Flex mt={2} position={'absolute'} right={0}>*/}
        {/*        <Flex gap={1}>*/}
        {/*          <Flex>{maxPrice}</Flex>*/}
        {/*          <Flex>{t('global.text_currency.0')}</Flex>*/}
        {/*        </Flex>*/}
        {/*      </Flex>*/}
        {/*    </RangeSlider>*/}
        {/*  </AccordionPanel>*/}
        {/*</AccordionItem>*/}
        <AccordionItem py={4} borderTop={'none'}>
          <h2>
            <AccordionButton py={2} px={0}>
              <Box display={'flex'} as='span' flex='1' textAlign='left'>
                <Text>Color</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0}>
            <Stack w={'full'} spacing={5} flex={1}>
              <ColorCircles />
            </Stack>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem py={4} borderColor={'blackAlpha.200'}>
          <h2>
            <AccordionButton py={2} px={0}>
              <Box display={'flex'} as='span' flex='1' textAlign='left'>
                <Text>Brand</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0}>
            <Stack w={'full'} spacing={5} flex={1}>
              <BrandSelector />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={4} borderColor={'blackAlpha.200'}>
          <h2>
            <AccordionButton py={2} px={0}>
              <Box display={'flex'} as='span' flex='1' textAlign='left'>
                <Text>Size</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0}>
            <Stack w={'full'} spacing={5} flex={1}>
              <SizeSelector />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}
