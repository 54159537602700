import { Global } from '@emotion/react'

export const Fonts = () => {
  return (
    <Global
      styles={`
        /* latin */
        @font-face {
          font-family: 'Suisse Intl';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('/fonts/SuisseIntl-Regular.woff2') format('woff2'),
         url('/fonts/SuisseIntl-Regular.woff') format('woff');
        },
        @font-face {
            font-family: 'Suisse Intl';
            font-weight: 700;
            font-display: swap;
            src: url('/fonts/SuisseIntl-Bold.woff2') format('woff2'),
            url('/fonts/SuisseIntl-Bold.woff') format('woff');
          }
        `}
    />
  )
}
