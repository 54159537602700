import { Formik } from "formik"
import { useDiscountCreation } from "../../../../../../context/DiscountCreationContext"
import { Box, Button, Flex, Icon, Img, ModalFooter, Text, Tag, useToast } from "@chakra-ui/react"
import axios from 'axios';
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { SummaryBar } from "../../SummaryBar"
import { VariantEntry } from "../../../../../../types/VariantEntry"
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react"
import { ConfirmUploadTable } from "./ConfirmUploadTable"
import { Toast } from "../../../../../../components/PlentyUI/Toast";
import { useApolloClient } from "@apollo/client";
import { GET_CAMPAIGN_DISCOUNTS } from "../../../../../../gql/discount";

export const CreateBulkDiscountConfirmUploadStep = (props: { wizard: any, setOpen: Dispatch<SetStateAction<boolean>>, campaignId: string, setCampaignId: Dispatch<SetStateAction<string>>}) => {
    const discountCreation = useDiscountCreation()
    const client = useApolloClient()
    const toast = useToast()
    const [disabled, setDisabled] = useState(false)

    const createDiscounts = async () => {
        try {
            const formData = new FormData()
            formData.append("campaignID", props.campaignId)
            const response = await axios.post(process.env.REACT_APP_PRODUCTS_API_URL + "/discount/confirm", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if (response.status > 200) {
                toast({
                    position: 'top',
                    render: () => <Toast type='success' label={response.data} />
                })
                discountCreation.resetDiscountCreation()
                props.wizard.setActiveStepIndex(0)
                props.setOpen(false)
                client.refetchQueries({ include: [GET_CAMPAIGN_DISCOUNTS] })
            }
        } catch (error: any) {
            toast({
                position: 'top',
                render: () => <Toast type='success' label={error} />
            })
            console.error(error)
        }
    }

    const onBackPress = () => {
        props.setCampaignId("")
        props.wizard.reduceStepTwice()
        discountCreation.setTableEntries([])
        discountCreation.setUploadComplete(false)
    }

    const tableOptions = {
        autoResetExpanded: false,
    }

    const data = useMemo(() => discountCreation.tableEntries, [discountCreation.tableEntries])

    const columns: any = useMemo(
        () => [
            {
                id: 'expander',
                Cell: ({ row }: any) =>
                    row.original.productEntry ? <Icon {...row.getToggleRowExpandedProps()} w={5} h={5} as={row.isExpanded ? IconChevronDown : IconChevronRight} /> : null,
            },
            {
                accessor: 'imageUrl',
                Cell: ({ row, value }: any) => {
                    if (value) {
                        return (
                            <Flex position={'relative'} w={10}>
                                <Img borderRadius={8} objectFit={'contain'} w={'40px'} h={'54px'} src={value} />
                                {row.original.productEntry ? (
                                    <Tag
                                        position={'absolute'}
                                        bottom={'12.5%'}
                                        right={'0'}
                                        size={'sm'}
                                        h={'fit-content'}
                                        w={'fit-content'}
                                        variant={'subtle'}
                                        colorScheme={'green'}
                                    >
                                        {row.original.productEntry.variantEntries.filter((ve: VariantEntry) => ve.isIncluded == true).length}
                                    </Tag>
                                ) : null}
                            </Flex>
                        )
                    } else {
                        return <Box w={'10px'} h={'30px'}></Box>
                    }
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Category',
                Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.category}</Text> : null),
            },
            {
                Header: 'Price',
                Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.price}</Text> : null),
            },
            {
                Header: 'Style/ean number',
                Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.styleNumber}</Text> : null),
            },
            {
                id: 'discount',
                Header: 'Discount',
                sortType: (rowA: any, rowB: any) => {
                    const discountA = rowA.original.discount // Access discount property, handling undefined with optional chaining
                    const discountB = rowB.original.discount // Access discount property, handling undefined with optional chaining

                    // Custom sorting logic
                    if (discountA === undefined && discountB !== undefined) {
                        return 1 // Put rows with undefined discount at the bottom
                    }
                    if (discountA !== undefined && discountB === undefined) {
                        return -1 // Put rows with defined discount at the bottom
                    }
                    // Continue with your sorting logic (e.g., sorting by discount values)
                    // Example: sorting by discount values in ascending order
                    if (discountA < discountB) {
                        return -1
                    }
                    if (discountA > discountB) {
                        return 1
                    }
                    return 0
                },
                Cell: ({ row }: any) => (row.original.productEntry.discount.value ? <Tag colorScheme={'green'}>{discountCreation.renderDiscountLabel(row.original.productEntry.discount)}</Tag> : null),
            },
        ],
        [data, discountCreation.tableEntries],
    )

    const table = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    // You can specify the sorting rule here
                    {
                        id: 'discount', // Use the accessor of the "Discount" column
                        desc: true, // Set to true if you want descending order, false for ascending
                    },
                ],
            },
            ...tableOptions,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    )

    return (
        <Formik
            initialValues={{
                confirmation: false
            }}
            onSubmit={async () => {
                setDisabled(true)
                discountCreation.setUploadConfirmed(true)
                props.wizard.advanceStep()
                createDiscounts()
            }}
        >
            {({ handleSubmit, isValid }) => (
                <Flex direction={'column'} position={'relative'}>
                    <SummaryBar
                    info={[
                        discountCreation.campaignName,
                        discountCreation.validFrom.toDateString() + ' to ' + (discountCreation.validTo ? discountCreation.validTo.toDateString() : 'continuous'),
                    ]}
                    />
                    <ConfirmUploadTable columns={columns} table={table} subRow={<></>} discountCreation={discountCreation} />
                    <ModalFooter borderTop={'1px'} borderTopColor={'beigeLight.500'}>
                        <Button isDisabled={!props.wizard.canReduce || disabled} colorScheme='blue' mr={3} onClick={() => onBackPress()}>
                            Previous
                        </Button>
                        <Button isDisabled={disabled} colorScheme='blue' mr={3} onClick={() => handleSubmit()}>
                            Confirm
                        </Button>
                    </ModalFooter>
                </Flex>
            )}
        </Formik>
    )
}