import { Flex, Icon, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { t } from 'i18next'

import { TbBuildingStore } from 'react-icons/tb'
import { useReturn } from '../../../../context/OrderReturnContext'

export const FindStore = () => {
  const { advanceStep } = useReturn()
  return (
    <>
      <Flex
        backgroundColor={'white'}
        borderRadius={'3xl'}
        p={4}
        direction={'column'}
        w={'full'}
        onClick={() => {
          advanceStep()
        }}
        cursor={'pointer'}
      >
        <Flex justifyContent={'space-between'}>
          <Flex alignItems={'center'}>
            <Icon fontSize={'24px'} as={TbBuildingStore}></Icon>
            <Text fontWeight={600} ml={6}>
              {t('main_app.return.text_return_in_store')}
            </Text>
          </Flex>
          <Text>{t('main_app.return.text_return_price')}</Text>
        </Flex>
        <UnorderedList ml={16}>
          <ListItem>{t('main_app.return.list_return_information.0')}</ListItem>
          <ListItem>{t('main_app.return.list_return_information.1')}</ListItem>
          <ListItem>{t('main_app.return.list_return_information.2')}</ListItem>
        </UnorderedList>
      </Flex>
    </>
  )
}
