import { useEffect, useState } from 'react';
import { getSubCategories } from '../../../../commercetools/requests';
import { getCategoryWithIdAndName } from '../../PLP/utils';

const SubCategories = () => {
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const categories = ['women', 'men', 'kids'];

  const currentPath = location.pathname.split('/')[2];

  const fetchSubCategoryData = async (category: string) => {
    try {
      const categoryData = await getCategoryWithIdAndName(category);
      if (categoryData && categoryData.id) {
        const subCategoryData = await getSubCategories(categoryData.id);
        return { category, data: subCategoryData };
      }
    } catch (error) {
      console.error(`Error fetching sub-categories for ${category}:`, error);
    }
    return null;
  };

  useEffect(() => {
    const fetchSubCategories = async () => {
      const fetchTasks = categories.map(category => 
        currentPath && currentPath !== category ? null : fetchSubCategoryData(category)
      ).filter(Boolean);

      const subCategoriesData = await Promise.all(fetchTasks);
      setSubCategories(subCategoriesData.filter(Boolean));
    };

    fetchSubCategories();
  }, [currentPath]);

  return subCategories;
};

export default SubCategories;