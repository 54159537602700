import { Flex, Img, Input, Text, Button } from '@chakra-ui/react'
import { useReturn } from '../../../../context/OrderReturnContext'
import { centPriceToPrice } from '../../../../utils/MoneyUtil'
import { QuantityButton } from '../../../../components/QuantityButton'
import client from '../../../../apollo-client'
import { GET_SHIPMENT_LABEL_BY_TRACKING_ID } from '../../../../gql/logistics'
import PdfModal from '../../../../utils/PdfUtil'
import { useState } from 'react'
import { t } from 'i18next'

type ReturnOrderItemCardProps = {
  returnOrderLineItem?: any
  returningItemBox?: string
  showQuantityButton?: boolean
  showStatusTag?: boolean
}
export const ReturnOrderItemCard = (props: ReturnOrderItemCardProps) => {
  const { decreaseReturnItemQuantity, increaseReturnItemQuantity, orderNumber } = useReturn()
  const shouldShowBorder = props.returnOrderLineItem.quantity > 0
  const [base64, setBase64] = useState<any | null>(null)
  const [isPdfModalOpen, setIsPdfModalOpen] = useState<boolean>(false)

  const getLabel = async (trackingId: string) => {
    const result = await client.query({
      query: GET_SHIPMENT_LABEL_BY_TRACKING_ID,
      variables: {
        orderNumber: orderNumber,
        trackingId: trackingId,
      },
    })

    setBase64(result.data.getShipmentLabelByTrackingId)
    setIsPdfModalOpen(true)
  }

  const showLabel = async () => {
    if (enableViewLabel()) {
      getLabel(props.returnOrderLineItem.orderLineItem.orderLineItemStatuses[0].trackingNumber)
    }
  }

  const isReturnable = (): boolean => {
    if (statusOfItem() === 'Returned') {
      // We need to check quantity
      return false
    }
    if (statusOfItem() === 'Return requested') {
      // We need to check quantity
      return false
    }
    if (statusOfItem() === 'Return cancelled') {
      return false
    }
    return true
  }

  const statusOfItem = () => {
    switch (props.returnOrderLineItem.orderLineItem.orderLineItemStatuses[0].itemStatus) {
      case 'RETURNED':
        return 'Returned'
      case 'RETURNED_REQUESTED':
        return 'Return requested'
      case 'RETURN_CANCELLED':
        return 'Return cancelled'
      case 'COMPLETED':
        return 'Able to return'
      default:
        return 'Completed'
    }
  }

  const enableViewLabel = () => {
    if (
      props.returnOrderLineItem.orderLineItem.orderLineItemStatuses[0].trackingNumber &&
      props.returnOrderLineItem.orderLineItem.orderLineItemStatuses[0].shipmentStatus === 'RETURN_STARTED' &&
      props.returnOrderLineItem.orderLineItem.orderLineItemStatuses[0].itemStatus === 'RETURNED_REQUESTED'
    ) {
      return true
    }
    return false
  }

  const isReturnableBorder = isReturnable() ? (shouldShowBorder ? 'mintGreen.500' : 'transparent') : 'red.500'

  return (
    <Flex boxShadow={'lg'} direction={'column'} border={'2px'} backgroundColor={'white'} borderRadius={'3xl'} borderColor={isReturnableBorder}>
      <PdfModal base64={base64} isOpen={isPdfModalOpen} onClose={() => setIsPdfModalOpen(false)} />
      <Flex gap={4} p={4} alignItems='center' position={'relative'}>
        <Flex maxW={'105px'}>
          <Img
            borderRadius={'3xl'}
            src={
              props.returnOrderLineItem.orderLineItem.imageUrl ||
              'https://images.jackjones.com/12212256/3910070/003/jackjones-uldblandingjakke-graa.jpg?v=f81f8a04c6789b46cdf14ba20125f606&width=1920&quality=90'
            }
          />
          {props.showStatusTag && (
            <Flex
              zIndex={10}
              borderRadius={'full'}
              bgColor={'mintGreen.500'}
              position={'absolute'}
              top={-3}
              padding={2}
              right={-8}
              alignItems='center'
              justifyContent='center'
              onClick={showLabel}
              _hover={enableViewLabel() ? { cursor: 'pointer' } : undefined}
            >
              <Text as={enableViewLabel() ? 'u' : undefined} fontSize='md'>
                {statusOfItem()}
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex flexGrow={1} direction={'column'} gap={4}>
          <Flex direction='column'>
            <Text>{props.returnOrderLineItem.orderLineItem.name}</Text>
            <Text>
              {t('global.text_color')} {props.returnOrderLineItem.orderLineItem.color}
            </Text>
            <Text>
              {t('global.text_size')} {props.returnOrderLineItem.orderLineItem.size}
            </Text>
            <Text>
              {t('global.text_quantity')} {props.returnOrderLineItem.orderLineItem.quantity}
            </Text>
            <Text>{props.returnOrderLineItem.orderLineItem.price ? centPriceToPrice(props.returnOrderLineItem.orderLineItem.price, 2) + ' ' + 'DKK' : ''}</Text>
          </Flex>
        </Flex>
        {props.showQuantityButton && isReturnable() && (
          <QuantityButton
            buttonSize='lg'
            buttonVariant='solidWhite'
            loading={false}
            onDecrease={() => decreaseReturnItemQuantity(props.returnOrderLineItem.orderLineItem)}
            onIncrease={() => increaseReturnItemQuantity(props.returnOrderLineItem.orderLineItem)}
            quantity={props.returnOrderLineItem.quantity}
            border={'1px solid'}
            borderRadius={'3xl'}
          />
        )}
      </Flex>
      {props.returnOrderLineItem.quantity >= 1 && props.returningItemBox && (
        <Flex backgroundColor={'mintGreen.500'} justifyContent='center' padding={2} borderRadius='0px 0px 24px 24px'>
          {props.returningItemBox}
        </Flex>
      )}
    </Flex>
  )
}
