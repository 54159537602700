import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Img,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useState } from 'react'
import { TbArrowLeft } from 'react-icons/tb'
import {  useNavigate } from 'react-router-dom'
import { Theme } from '../../../ThemeVariables'
import { SubSubSubCategoryDrawer } from './SubSubSubCategoryDrawerForKids'

type Categories = {
  categoryId: string
  categoryName: string
  categoryNameEn: string

  parentCategoryId: string | null
  key: string | undefined
  childCategories?: Categories[]
  orderHint?: any
}

export const Level3CategoriesCategoryDrawer = ({ isOpen, onClose, subSubCategories, selectedGender, subCategory, onCloseParentDrawer, subSubSubCategories }: any) => {
  const FooterIcon = 'https://storage.googleapis.com/bucket-plenty-dev/Header%26Footer/FooterIcon.png'
  const navigate = useNavigate()
  const [isSubSubSubCategoryDrawerOpen, setIsSubSubSubCategoryDrawerOpen] = useState(false)
  const [selectedSubSubCategories, setSelectedSubSubCategories] = useState<Categories[]>([])
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState<Categories | null>(null)
  const handleCategoryClick = (categoryName: string, subSubCategory: Categories) => {
    if (selectedGender === 'Kids') {
      setSelectedSubSubCategory(subSubCategory)
      setIsSubSubSubCategoryDrawerOpen(true)
    } else {
      const genderSegment = formatUrlSegment(selectedGender);
      const subCategorySegment = formatUrlSegment(subCategory);
      const categoryNameSegment = formatUrlSegment(categoryName);
    
      const completePath = `/products/${genderSegment}/${subCategorySegment}/${categoryNameSegment}`;
    
      closeAllDrawers();
      navigate(completePath); // Directly navigate to completePath
    }
  }

  const formatUrlSegment = (segment: string, isKidsCategory = false) => {
    segment = segment.toLowerCase().replace(/&/g, 'and')

    // Check if the segment belongs to the 'Kids' category
    if (isKidsCategory) {
      // Replace spaces with underscores for 'Kids' category segments
      return segment.replace(/\s+/g, '_').replace(/[^a-z0-9_]/g, '')
    } else {
      // Remove spaces for other categories
      return segment.replace(/\s+/g, '').replace(/[^a-z0-9]/g, '')
    }
  }

  const handleAllCategoryClick = () => {
    const isKidsCategory = selectedGender.toLowerCase() === 'kids'
    const genderSegment = formatUrlSegment(selectedGender, isKidsCategory)
    const subCategorySegment = formatUrlSegment(subCategory, isKidsCategory)

    const routePath = `/products/${genderSegment}/${subCategorySegment}`

    closeAllDrawers()
    navigate(routePath)
  }

  const closeAllDrawers = () => {
    onClose()
    if (onCloseParentDrawer) {
      onCloseParentDrawer()
    }
  }

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='xs'>
        <DrawerOverlay />
        <DrawerContent bgColor={'beigeLight.500'}>
          <DrawerBody p={0}>
            <Flex p={Theme.spacing.padding.mobile} justifyContent={'space-between'}>
              <Img src={FooterIcon} width={'30px'} height={'40px'} />
              <DrawerCloseButton onClick={onClose} position={'relative'} fontSize={'20px'} />
            </Flex>
            <Flex px={Theme.spacing.padding.mobile} onClick={onClose} gap={Theme.gap.mobile} fontSize={'xl'} alignItems={'center'}>
              <TbArrowLeft />
              <Flex>{selectedGender === 'Men' ? 'Men' : selectedGender === 'Women' ? 'Women' : 'Kids'}</Flex>
            </Flex>
            <Accordion p={4} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton px={0} justifyContent={'space-between'} fontSize={'xl'} onClick={() => handleAllCategoryClick()}>
                    <Flex>
                      {t('components.burger_menu.text_all')} {subCategory}
                    </Flex>
                  </AccordionButton>
                </h2>
              </AccordionItem>
              {subSubCategories &&
                subSubCategories.map((subSubCategory: Categories) => (
                  <AccordionItem key={subSubCategory.categoryId}>
                    <h2>
                      <AccordionButton
                        px={0}
                        justifyContent={'space-between'}
                        fontSize={'xl'}
                        onClick={() => handleCategoryClick(subSubCategory.categoryNameEn, subSubCategory)}
                      >
                        <Box flex='1' textAlign='left'>
                          {subSubCategory.categoryName}
                        </Box>
                      </AccordionButton>
                    </h2>
                  </AccordionItem>
                ))}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {selectedGender === 'Kids' && (
        <SubSubSubCategoryDrawer
          isOpen={isSubSubSubCategoryDrawerOpen}
          onClose={() => setIsSubSubSubCategoryDrawerOpen(false)}
          selectedGender={selectedGender}
          subCategory={subCategory}
          subSubCategories={selectedSubSubCategories}
          selectedSubSubCategory={selectedSubSubCategory}
          subSubSubCategories={subSubSubCategories}
          onCloseParentDrawer={onClose}
        />
      )}
    </>
  )
}
