import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Icon, IconButton, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { useState } from 'react'
import { TbEye, TbEyeOff } from 'react-icons/tb'

type AuthTextFieldProps = {
  field?: any
  form?: any
  label?: string
  helperText?: string
  placeholder: string
  type: string
  icon: any
  value?: string
  onChange?: () => any
}

export const AuthTextField = ({ field, form, label, helperText, type, placeholder, icon }: AuthTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <FormControl isInvalid={form.errors[field.name] && form.touched[field.name]}>
      <FormLabel>{label}</FormLabel>
      <InputGroup variant={'solid'}>
        <InputLeftElement pointerEvents='none'>
          <Icon h={5} w={5} as={icon} />
        </InputLeftElement>
        <Input {...field} variant={'solid'} placeholder={placeholder} type={showPassword ? 'text' : type} />
        {type === 'password' && (
          <InputRightElement>
            <IconButton
              variant={'unstyled'}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
              icon={showPassword ? <TbEye /> : <TbEyeOff />}
              onClick={handleTogglePassword}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
    </FormControl>
  )
}
