import { useEffect, useState } from 'react'
import { getBrandsAssociatedToCategoryById } from '../../../../commercetools/requests'
import { getCategoryWithIdAndName } from '../../PLP/utils'

const GetBrandFacetsForGender = () => {
  const [brandFacets, setBrandFacets] = useState({
    men: [],
    women: [],
    kids: [],
  })

  const categories = ['women', 'men', 'kids']

  useEffect(() => {
    const fetchBrandFacets = async (categoryKey: string) => {
      try {
        const categoryData = await getCategoryWithIdAndName(categoryKey)
        if (categoryData && categoryData.id) {
          const response = await getBrandsAssociatedToCategoryById(categoryData.id)
          if (response && response.facets && response.facets['variants.attributes.brand.en']) {
            setBrandFacets((prevState) => ({
              ...prevState,
              [categoryKey]: (response.facets['variants.attributes.brand.en'] as any).terms,
            }))
          }
        }
      } catch (error) {
        console.error(`Error fetching brand facets for ${categoryKey}:`, error)
      }
    }

    categories.forEach((category) => {
      fetchBrandFacets(category)
    })
  }, [])

  return brandFacets
}

export default GetBrandFacetsForGender
