import { VStack } from '@chakra-ui/react'
import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import InputSelector from './components/InputSelector'
import ProductList from './components/ProductList'
import NoResults from './components/NoResults'
import SearchInput from './components/SearchInput'
import { useEffect } from 'react'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { MainRole } from '../../../generated/graphql'
import withAuth from '../../../utils/WithAuth'

export const SearchPage = () => {
  const { clearBreadcrumbs } = useBreadcrumbs()
  useEffect(() => {
    clearBreadcrumbs()
  }, [])

  return (
    <MainPageResponsive justify={'center'} mx={'auto'} loading={false}>
      <VStack px={'5%'} w='100%' justifyContent={'center'} alignItems='center' overflowX={['scroll', 'initial']}>
        <SearchInput />
        <InputSelector />
        <NoResults />
        <ProductList />
      </VStack>
    </MainPageResponsive>
  )
}
export default withAuth(SearchPage, [MainRole.RoleGuest])
