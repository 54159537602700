import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
  label: {
    color: 'black.500',
  },
  control: {
    borderColor: 'beigeDark.500',
    _checked: {
      backgroundColor: 'black.500',
      borderColor: 'black.500',
      _hover: {
        backgroundColor: 'black.500',
        borderColor: 'black.500',
      },
    },
  },
  icon: {
    backgroundColor: 'black.500',
    color: 'mintGreen.500',
  },
})

const plentyStyle = definePartsStyle({
  control: {
    _checked: {
      borderColor: 'mintGreen.500',

      boxShadow: '0 0 0 4px white inset',
      '&::before': {
        content: '""',
        display: 'block',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: 'rgba(11, 28, 18, 1)',
        position: 'absolute',
      },
    },
  },
})

export const radioTheme = defineMultiStyleConfig({ variants: { baseStyle, plentyStyle } })
