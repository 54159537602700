import { css, defineStyle, defineStyleConfig } from '@chakra-ui/react'

const headingCleanWhite = defineStyle({
  color: 'white',
  textAlign: 'center',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '48px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '50px',
})
const headingCleanBlack = defineStyle({
  color: 'var(--black, #000)',
  textAlign: 'center',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '48px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '50px',
})
const headingCleanWhiteSmallHeading = defineStyle({
  color: 'white',
  textAlign: 'center',
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '50px',
  '@media (max-width: 779px)': {
    fontSize: '24px',
  },
  '@media (min-width: 780px) and (max-width: 1049px)': {
    fontSize: '16px',
  },
  '@media (min-width: 1050px) and (max-width: 1399px)': {
    fontSize: '20px',
  },
  '@media (min-width: 1400px)': {
    fontSize: '24px',
  },
});

const headingForComponentOnAMainPage = css({
  color: 'var(--black, #000)',  
  fontFamily: '"Suisse Intl", sans-serif',
  fontSize: '48px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: "-1.68px",
  '@media (max-width: 768px)': {  
    fontSize: '24px',
    letterSpacing: "-0.84px",
  },
});



export const headingTheme = defineStyleConfig({
  variants: {
    headingCleanWhite,
    headingCleanBlack,
    headingCleanWhiteSmallHeading,
    headingForComponentOnAMainPage
  },
})
