import { Flex, Img } from '@chakra-ui/react'
import { t } from 'i18next'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../../../assets/svg/Plenty&Svg/Plenty_Logo_Black.svg'
import { Theme } from '../../../../ThemeVariables'
import { HeaderIconsDesktop } from '../../HeaderIcons/desktop/HeaderIconsDesktop'

export const Header = () => {
  const navigate = useNavigate()

  return (
    <Flex direction={'column'}>
      <Flex w='full' justifyContent='space-between' py={Theme.spacing.padding.mobile}>
        <Flex alignItems='center' gap={6} position='relative' zIndex={2}>
          <Img h={7} src={Logo} _hover={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
          <Link to='/products/?gender=Women'>{t('global.title_women')}</Link>
          <Link to='/products/?gender=Men'>{t('global.title_men')}</Link>
          <Link to='/products/?gender=Kids&genderKids=Boy'>{t('global.title_boy')}</Link>
          <Link to='/products/?gender=Kids&genderKids=Girl'>{t('global.title_girl')}</Link>
          <Link to='/cities/aarhus#LeafLetMap'>{t('global.title_stores')}</Link>
        </Flex>
        <Flex gap={6}>
          <HeaderIconsDesktop />
        </Flex>
      </Flex>
    </Flex>
  )
}
