import { createContext, useContext, ReactElement, useState, useEffect, SetStateAction, Dispatch } from 'react'
import { CognitoUser } from '@aws-amplify/auth'
import { Auth, Hub } from 'aws-amplify'
import { MainRole } from '../generated/graphql'
import React from 'react'

type UserContextType = {
  user: CognitoUser | null
  setUser: Dispatch<SetStateAction<CognitoUser | null>>
  role: MainRole
  store: string | null
  distributionChannelKey: string | null
  status: ContextStatusType
  signOut: Dispatch<any>
}

type ContextStatusType = 'unset' | 'finished'

const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => undefined,
  role: MainRole.RoleGuest,
  store: '',
  distributionChannelKey: '',
  status: 'unset',
  signOut: () => undefined,
})

export default function AuthContext({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [status, setStatus] = useState<ContextStatusType>('unset')
  const [role, setRole] = useState<MainRole>(MainRole.RoleGuest)
  const [store, setStore] = useState<string | null>(null)
  const [distributionChannelKey, setDistributionChannelKey] = useState<string | null>(null)

  useEffect(() => {
    checkUser()
  }, [])

  useEffect(() => {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
        case 'tokenRefresh':
        case 'configured':
          checkUser()
          break
        case 'signUp':
        case 'signOut':
        case 'signIn_failure':
        case 'tokenRefresh_failure':
          setUser(null)
          break
      }
    }
    Hub.listen('auth', listener)
  }, [])

  async function signOut() {
    return Auth.signOut()
      .then(() => {
        setUser(null)
        setRole(MainRole.RoleGuest)
        setStore(null)
      })
      .catch(() => {
        setUser(null)
        setRole(MainRole.RoleGuest)
      })
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        setUser(user)
        setRole(MainRole.RoleAdmin)
        //   setRole(user.signInUserSession.idToken.payload.role)
        setStore(user?.signInUserSession.idToken.payload.storeName)
        setDistributionChannelKey(user?.signInUserSession.idToken.payload.distributionChannelKey)
      }
    } catch (e) {
      // Removed because it throws the error into prod and dont need to show user the error
      // console.error(e)
      setUser(null)
      setRole(MainRole.RoleGuest)
    } finally {
      setStatus('finished')
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        role,
        store,
        distributionChannelKey,
        status,
        setUser,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = (): UserContextType => useContext(UserContext)
