import { Flex, Text } from '@chakra-ui/react'
import { Trans } from 'react-i18next'
import { useProductSearch } from '../../../../context/ProductSearchContext'
import { Theme } from '../../../../ThemeVariables'

const NoResults = () => {
  const { searchTerm, isLoadingProducts, totalResults } = useProductSearch()

  return (
    <>
      {!isLoadingProducts && searchTerm != '' && totalResults == 0 ? (
        <Flex overflow={'auto'} alignSelf='self-start'>
          <Flex flexDirection={'column'} gap={Theme.gap.desktop}>
            <Text fontSize={'15px'}>
              <Trans i18nKey='search.list_text_no_products_found' values={{ searchTerm: searchTerm }} />
            </Text>
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </>
  )
}

export default NoResults
