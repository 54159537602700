import { Store } from '@commercetools/platform-sdk'
import { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { getAllCategories, getMainCategories, getProductsProjectionSearchGlobal } from '../commercetools/requests'
import { PlentyProduct } from '../pages/main_app/PLP/types'
import { useGetStoresLazyQuery } from '../generated/graphql'
import { useProductSearchUtilities } from '../pages/main_app/search_page/SearchUtil'
import { SearchCategory } from '../pages/main_app/search_page/types'
import i18n from '../I18n/i18n'

type SearchContextType = {
  offset: number
  setOffset: (offset: number) => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  searchResults: PlentyProduct[]
  setSearchResults: (searchResults: PlentyProduct[]) => void
  totalResults: number | undefined
  setTotalResults: (totalResults: number | undefined) => void
  categoryId: string
  setCategoryId: (category: string) => void
  genderizedCategoryId: string
  setGenderizedCategoryId: (category: string) => void
  topCategories: SearchCategory[]
  setTopCategories: (topCategories: SearchCategory[]) => void
  isLoadingProducts: boolean
  setIsLoadingProducts: (isLoadingProducts: boolean) => void
  selectedGender: string
  setSelectedGender: (selectedGender: string) => void
  languageSelected: string
  setLanguageSelected: (languageSelected: string) => void
  allStoreChannels: string[]
  setAllStoreChannels: (allStoreChannels: string[]) => void
  allCategories: SearchCategory[]
  priceSort: string
  setPriceSort: (priceSort: string) => void
  interestedInProductsWomen: PlentyProduct[]
  interestedInProductsMen: PlentyProduct[]
  interestedInProductsKids: PlentyProduct[]
}

const SearchContext = createContext<SearchContextType>({
  offset: 0,
  setOffset: () => undefined,
  searchTerm: '',
  setSearchTerm: () => undefined,
  searchResults: [],
  setSearchResults: () => undefined,
  totalResults: undefined,
  setTotalResults: () => undefined,
  categoryId: '',
  setCategoryId: () => undefined,
  genderizedCategoryId: '',
  setGenderizedCategoryId: () => undefined,
  topCategories: [],
  setTopCategories: () => undefined,
  isLoadingProducts: true,
  setIsLoadingProducts: () => undefined,
  selectedGender: '',
  setSelectedGender: () => undefined,
  languageSelected: '',
  setLanguageSelected: () => undefined,
  allStoreChannels: [],
  setAllStoreChannels: () => undefined,
  allCategories: [],
  priceSort: '',
  setPriceSort: () => undefined,
  interestedInProductsWomen: [],
  interestedInProductsMen: [],
  interestedInProductsKids: [],
})

export default function ProductSearchContext({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  const { getInterestedInSearchTerm, formatPlentyProduct } = useProductSearchUtilities()
  const [offset, setOffset] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResults, setSearchResults] = useState<PlentyProduct[]>([])
  const [totalResults, setTotalResults] = useState<number | undefined>(undefined)
  const [categoryId, setCategoryId] = useState<string>('')
  const [genderizedCategoryId, setGenderizedCategoryId] = useState<string>('')
  const [topCategories, setTopCategories] = useState<SearchCategory[]>([])
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(true)
  const [languageSelected, setLanguageSelected] = useState<string>(() => {
    const selectedLanguage = localStorage.getItem('languageSelected') ?? i18n.language
    return selectedLanguage === 'DK' ? 'da-DK' : 'en'
  })

  const [selectedGender, setSelectedGender] = useState<string>('women')
  const [allCategories, setAllCategories] = useState<SearchCategory[]>([])
  const [priceSort, setPriceSort] = useState<string>('none')
  const [allStoreChannels, setAllStoreChannels] = useState<string[]>([])
  const [interestedInProductsWomen, setInterestedInProductsWomen] = useState<PlentyProduct[]>([])
  const [interestedInProductsMen, setInterestedInProductsMen] = useState<PlentyProduct[]>([])
  const [interestedInProductsKids, setInterestedInProductsKids] = useState<PlentyProduct[]>([])
  const [getStores] = useGetStoresLazyQuery()

  useEffect(() => {
    fetchStores()
    fetchAllCategories()
    fetchInterestedInProducts()
  }, [])

  const fetchInterestedInProducts = async () => {
    if (interestedInProductsWomen.length === 0 && interestedInProductsMen.length === 0 && interestedInProductsKids.length === 0) {
      const genderCategories = await getMainCategories()

      genderCategories?.forEach(async (genderCategory) => {
        const selectedGenderCategoryId = genderCategory.id
        const fixedSearchTerm = getInterestedInSearchTerm()

        const response = await getProductsProjectionSearchGlobal(
          fixedSearchTerm,
          0,
          '',
          '',
          selectedGenderCategoryId,
          [],
          languageSelected,
          allStoreChannels,
          null,
        )
        const variants = response ? formatPlentyProduct(response.results) : []

        switch (genderCategory.key) {
          case 'women': {
            setInterestedInProductsWomen(variants)
            break
          }
          case 'men': {
            setInterestedInProductsMen(variants)
            break
          }
          case 'kids': {
            setInterestedInProductsKids(variants)
            break
          }
          default: {
            break
          }
        }
      })
    }
  }

  const fetchStores = async () => {
    const stores: Store[] = await getStores().then((resStores: any) => {
      return resStores.data.getStores as Store[]
    })
    const channels = stores.flatMap((store) => store.distributionChannels.flatMap((channel) => channel.id)) ?? []
    setAllStoreChannels(channels)
  }

  const fetchAllCategories = async () => {
    if (allCategories.length > 0) {
      return
    }

    const categoryLimit = 200
    let categoryOffset = 0

    const initialBatch = await getAllCategories(categoryOffset, categoryLimit)
    const totalCategories = initialBatch?.total ?? 0
    const categories = initialBatch?.results ?? []

    const offsetCount = Math.ceil(totalCategories / categoryLimit) - 1

    for (let i = 0; i < offsetCount; i++) {
      categoryOffset = (i + 1) * categoryLimit
      const newCategories = await getAllCategories(categoryOffset, categoryLimit)
      categories.push(...(newCategories?.results ?? []))
    }

    const parsedCategories = []

    for (const category of categories) {
      const categoryName = category.name[languageSelected] ?? category.name['en']

      if (categoryName && categoryName.includes('&')) {
        const splitNames = categoryName.split('&').map((name) => name.trim())

        splitNames.forEach((name) => {
          parsedCategories.push({
            categoryName: name,
            categoryId: category.id,
            categoryKey: category.key ?? '',
            parentCategoryId: category.parent?.id ?? '',
            genderCategoryId: category.ancestors[0] ? category.ancestors[0].id : '',
          })
        })
      } else {
        parsedCategories.push({
          categoryName: categoryName,
          categoryId: category.id,
          categoryKey: category.key ?? '',
          parentCategoryId: category.parent?.id ?? '',
          genderCategoryId: category.ancestors[0] ? category.ancestors[0].id : '',
        })
      }
    }
    setAllCategories(parsedCategories)
  }

  return (
    <SearchContext.Provider
      value={{
        offset,
        setOffset,
        searchTerm,
        setSearchTerm,
        searchResults,
        setSearchResults,
        totalResults,
        setTotalResults,
        categoryId,
        setCategoryId,
        genderizedCategoryId,
        setGenderizedCategoryId,
        topCategories,
        setTopCategories,
        isLoadingProducts,
        setIsLoadingProducts,
        selectedGender,
        setSelectedGender,
        languageSelected,
        setLanguageSelected,
        allStoreChannels,
        setAllStoreChannels,
        allCategories,
        priceSort,
        setPriceSort,
        interestedInProductsWomen,
        interestedInProductsMen,
        interestedInProductsKids,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useProductSearch = (): SearchContextType => useContext(SearchContext)
