import { Button, Flex } from '@chakra-ui/react'

type QuantityButtonProps = {
  quantity: number
  onIncrease: () => any
  onDecrease: () => any
  loading: boolean
  buttonSize: string
  buttonVariant: string
  border?: string
  borderRadius?: string
  stockLeft?: number
}

export const QuantityButton = (props: QuantityButtonProps) => {
  return (
    <Flex border={props.border} borderRadius={props.borderRadius}>
      <Button
        variant={props.buttonVariant}
        size={props.buttonSize}
        isLoading={props.loading}
        onClick={() => props.onDecrease()}
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
      >
        -
      </Button>
      <Button variant={props.buttonVariant} size={props.buttonSize} flex={1} borderRadius={0}>
        {props.quantity}
      </Button>
      <Button
        variant={props.buttonVariant}
        size={props.buttonSize}
        isLoading={props.loading}
        onClick={() => props.onIncrease()}
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        isDisabled={props.stockLeft ? props.stockLeft < 1 : false}
      >
        +
      </Button>
    </Flex>
  )
}
