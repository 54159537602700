import { Button, Flex, Icon, Menu, MenuButton, MenuList, MenuItem, Radio, RadioGroup } from '@chakra-ui/react'
import { t } from 'i18next'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import { useState } from 'react'

export const SortBy = ({ sortType, setSortType }: any) => {
  const [selectedValue, setSelectedValue] = useState(sortType)

  const handleOnChange = (value: string) => {
    setSelectedValue(value)
    setSortType(value)
  }

  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            _active={{
              bg: 'black',
              color: 'white',
            }}
            _hover={{
              bg: 'black',
              color: 'white',
            }}
            color={isOpen ? 'black' : 'white'}
            as={Button}
            isActive={isOpen}
            variant='solid'
          >
            <Flex alignItems={'center'}>
              {t('components.button.sort_by')}
              <Icon ml={2} w={5} h={5} as={isOpen ? TbChevronUp : TbChevronDown} />
            </Flex>
          </MenuButton>
          <MenuList>
            <RadioGroup variant={'plentyStyle'} value={selectedValue} onChange={handleOnChange}>
              <MenuItem _hover={{ backgroundColor: 'beigeLight.500' }} _focus={{ backgroundColor: 'beigeLight.500' }}>
                <Radio value='Most popular'> {t('components.button.sort_by_popular')}</Radio>
              </MenuItem>
              {/* <MenuItem>
                <Radio value='Newest'> {t('components.button.sort_by_new')}</Radio>
              </MenuItem> */}
              <MenuItem _hover={{ backgroundColor: 'beigeLight.500' }}>
                <Radio value='Lowest price'> {t('components.button.sort_by_price_low')}</Radio>
              </MenuItem>
              <MenuItem _hover={{ backgroundColor: 'beigeLight.500' }}>
                <Radio value='Highest price'> {t('components.button.sort_by_price_high')}</Radio>
              </MenuItem>
              {/* <MenuItem>
                <Radio value='Sale'> {t('components.button.sort_by_sale')}</Radio>
              </MenuItem> */}
            </RadioGroup>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
