export const metersToKilometersFormatted = (distance: number, digits: number) => {
  return (distance / 1000).toFixed(digits) + ' km'
}

export const numberToDayString = (day: number) => {
  switch (day) {
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    case 7:
      return 'Sunday'
    default:
      return 'Unknown day'
  }
}

export const getDeliveryMethod = (deliveryMethodName: string | undefined) => {
  switch (deliveryMethodName) {
    case 'Pickup':
      return 'Pickup'
    case 'PostnordParcelShopDelivery':
      return 'Shipping'
    case 'PostnordHomeDelivery':
      return 'Shipping'
    case 'InStore':
      return 'In Store'
    case 'ParcelShopDelivery':
      return 'Delivery'
    default:
      return 'No shipping method'
  }
}
