import { useApolloClient } from '@apollo/client'
import { Box, Button, ButtonGroup, Checkbox, Flex, Icon, Img, ModalFooter, Stack, Tag, Text, useToast } from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import { useMemo, useState } from 'react'
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { Toast } from '../../../../components/PlentyUI/Toast'
import { useUser } from '../../../../context/Auth'
import { useDiscountCreation } from '../../../../context/DiscountCreationContext'
import { CREATE_CAMPAIGN_DISCOUNT, GET_CAMPAIGN_DISCOUNTS } from '../../../../gql/discount'
import { BundleDiscountTool } from '../../../../types/BundleDiscountTool'
import { PercentageDiscountTool } from '../../../../types/PercentageDiscountTool'
import { TableEntry } from '../../../../types/TableEntry'
import { VariantEntry } from '../../../../types/VariantEntry'
import { ApplyDiscountTable } from './ApplyDiscountTable'
import { SummaryBar } from './SummaryBar'

type CreateDiscountApplyDiscountStepProps = {
  wizard: any
  setOpen: (value: boolean) => any
}

export const CreateDiscountApplyDiscountStep = (props: CreateDiscountApplyDiscountStepProps) => {
  const discountCreation = useDiscountCreation()
  const user = useUser()
  const client = useApolloClient()
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const data = useMemo(() => discountCreation.tableEntries, [discountCreation.tableEntries])
  const columns: any = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => <Checkbox isChecked={getToggleAllRowsSelectedProps().checked} isIndeterminate={getToggleAllRowsSelectedProps().indeterminate} variant={'baseStyle'} {...getToggleAllRowsSelectedProps()} />,
        Cell: ({ row }: any) => <Checkbox isChecked={row.getToggleRowSelectedProps().checked} variant={'baseStyle'} {...row.getToggleRowSelectedProps()} />,
      },
      {
        id: 'expander',
        Cell: ({ row }: any) =>
          row.original.productEntry ? <Icon {...row.getToggleRowExpandedProps()} w={5} h={5} as={row.isExpanded ? IconChevronDown : IconChevronRight} /> : null,
      },
      {
        accessor: 'imageUrl',
        Cell: ({ row, value }: any) => {
          if (value) {
            return (
              <Flex position={'relative'} w={10}>
                <Img borderRadius={8} objectFit={'contain'} w={'40px'} h={'54px'} src={value} />
                {row.original.productEntry ? (
                  <Tag
                    position={'absolute'}
                    bottom={'12.5%'}
                    right={'0'}
                    size={'sm'}
                    h={'fit-content'}
                    w={'fit-content'}
                    variant={'subtle'}
                    colorScheme={'green'}
                  >
                    {row.original.productEntry.variantEntries.filter((ve: VariantEntry) => ve.isIncluded == true).length}
                  </Tag>
                ) : null}
              </Flex>
            )
          } else {
            return <Box w={'10px'} h={'30px'}></Box>
          }
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Category',
        Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.category}</Text> : null),
      },
      {
        Header: 'Price',
        Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.price}</Text> : null),
      },
      {
        Header: 'Style/ean number',
        Cell: ({ row }: any) => (row.original.productEntry ? <Text>{row.original.productEntry.styleNumber}</Text> : null),
      },
      {
        id: 'discount',
        Header: 'Discount',
        sortType: (rowA: any, rowB: any) => {
          const discountA = rowA.original.discount // Access discount property, handling undefined with optional chaining
          const discountB = rowB.original.discount // Access discount property, handling undefined with optional chaining

          // Custom sorting logic
          if (discountA === undefined && discountB !== undefined) {
            return 1 // Put rows with undefined discount at the bottom
          }
          if (discountA !== undefined && discountB === undefined) {
            return -1 // Put rows with defined discount at the bottom
          }
          // Continue with your sorting logic (e.g., sorting by discount values)
          // Example: sorting by discount values in ascending order
          if (discountA < discountB) {
            return -1
          }
          if (discountA > discountB) {
            return 1
          }
          return 0
        },
        Cell: ({ row }: any) => (row.original.discount ? <Tag colorScheme={'green'}>{discountCreation.renderDiscountLabel(row.original.discount)}</Tag> : null),
      },
    ],
    [data, discountCreation.tableEntries],
  )

  const tableOptions = {
    autoResetExpanded: false,
  }

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          // You can specify the sorting rule here
          {
            id: 'discount', // Use the accessor of the "Discount" column
            desc: true, // Set to true if you want descending order, false for ascending
          },
        ],
      },
      ...tableOptions,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  )

  const didNotDiscountEverything = () => {
    return discountCreation.tableEntries.filter((te: TableEntry) => te.discount !== undefined).length < discountCreation.tableEntries.length
  }

  const applyDiscount = (discountTool: PercentageDiscountTool | BundleDiscountTool) => {
    const entriesToDiscount = table.selectedFlatRows.map((row: any) => row.original)

    const updatedEntries = discountCreation.tableEntries.map((te: TableEntry) => {
      if (entriesToDiscount.includes(te)) {
        return {
          ...te,
          discount: discountTool,
        }
      }
      return te
    })

    discountCreation.setTableEntries(updatedEntries)
  }

  const createCampaignDiscountDocument = () => {
    if (user.distributionChannelKey) {
      setIsLoading(true)

      const json = discountCreation.generateCampaignDiscountJSONOutout(user.distributionChannelKey)

      client.mutate({
        mutation: CREATE_CAMPAIGN_DISCOUNT,
        variables: {
          campaignDiscount: json,
        },
      }).then(() => {
        props.setOpen(false)
        discountCreation.resetDiscountCreation()
        props.wizard.setActiveStepIndex(0)
        setIsLoading(false)
        toast({
          position: 'top',
          render: () => <Toast type='success' label={discountCreation.campaignName + ' created with success'} />,
        })
        client.refetchQueries({ include: [GET_CAMPAIGN_DISCOUNTS] })
      })
    }
  }

  return (
    <Flex direction={'column'} position={'relative'}>
      <SummaryBar
        info={[
          discountCreation.campaignName,
          discountCreation.validFrom.toDateString() + ' to ' + (discountCreation.validTo ? discountCreation.validTo.toDateString() : 'continuous'),
        ]}
      />
      <Flex p={6}>
        <Stack spacing={6} flex={1} marginBottom={'50px'}>
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex alignItems={'center'} gap={2}>
                <Text fontSize={'2xl'} fontWeight={'bold'}>
                  Apply discounts
                </Text>
              </Flex>
              <Text>Select multiple products and your preferred discount type.</Text>
            </Flex>
          </Flex>
          <ApplyDiscountTable columns={columns} discountCreation={discountCreation} subRow={<></>} table={table} />
        </Stack>
        <Flex
          hidden={table.selectedFlatRows.length == 0}
          borderRadius={'full'}
          position={'absolute'}
          bottom={'10px'}
          backgroundColor={'white.500'}
          left={'50%'}
          style={{ WebkitTransform: 'translateX(-50%)' }}
          transform={'translateX("-50%")'}
          padding={3}
          boxShadow={'lg'}
        >
          <ButtonGroup size='sm' isAttached variant='solid'>
            {discountCreation.discountTools.map((value: PercentageDiscountTool | BundleDiscountTool, index: number) => (
              <Button key={index} variant={'solid'} _hover={{ bgColor: 'mintGreen.500', color: 'darkGreen.500' }} fontWeight={'normal'} size={'lg'} onClick={() => applyDiscount(value)}>
                {discountCreation.renderDiscountLabel(value)}
              </Button>
            ))}
          </ButtonGroup>
        </Flex>
      </Flex>

      <ModalFooter borderTop={'1px'} borderTopColor={'beigeLight.500'}>
        <Button isDisabled={!props.wizard.canReduce} mr={3} onClick={() => props.wizard.reduceStep()}>
          Previous
        </Button>
        <Button isLoading={isLoading} isDisabled={didNotDiscountEverything()} mr={3} onClick={() => createCampaignDiscountDocument()}>
          Create campaign
        </Button>
      </ModalFooter>
    </Flex>
  )
}
