import { gql } from '@apollo/client'

export const CREATE_TRANSACTION_SESSION = gql`
  mutation CreateTransactionSession($cartId: String, $shippingMethod: String, $phoneNumber: String) {
    createTransactionSession(cartId: $cartId, shippingMethod: $shippingMethod, phoneNumber: $phoneNumber) {
      id
      sessionData
    }
  }
`
