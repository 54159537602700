import { Button, Divider, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { t } from 'i18next'

import { IconExclamationMark } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { getInventoryForProduct } from '../../../commercetools/requests'
import { useCart } from '../../../context/CheckoutCartContext'
import { LineItem, Maybe } from '../../../generated/graphql'
import { calculateTotalOriginalPriceAmount, centPriceToPrice, moneyFormat } from '../../../utils/MoneyUtil'
import { CartItemContainer } from '../components/CartItemContainer'

export const ReviewStep = () => {
  const { cart, setStep } = useCart()

  const [allProductsInCartIsInStock, setAllProductsInCartIsInStock] = useState(true)

  useEffect(() => {
    if (cart.lineItems) {
      cart.lineItems.forEach((value: Maybe<LineItem>) => {
        getInventoryForProduct(value!.distributionChannel!.id, value?.variant?.sku).then((res) => {
          if (res) {
            if (res[0].availableQuantity < 1 || res[0].availableQuantity - value!.quantity! < 0) {
              setAllProductsInCartIsInStock(false)
            }
          } else {
            setAllProductsInCartIsInStock(false)
          }
        })
      })
    }
  }, [])

  const totalOriginalAmount = calculateTotalOriginalPriceAmount(cart)
  const totalDiscountAmount = totalOriginalAmount - (cart.totalPrice?.centAmount ?? 0)

  return (
    <Flex direction={'column'} gap={'24px'}>
      <Heading fontWeight={'normal'} fontSize={'2xl'}>
        {t('components.cart.steps.heading_shopping_cart')}
      </Heading>

      <CartItemContainer cart={cart} />
      <Flex direction={'column'} gap={'24px'}>
        <Flex
          bgColor={(cart.totalPrice?.centAmount ?? 0) >= 39900 ? 'mintGreen.300' : 'yellow.300'}
          padding={'8px'}
          borderRadius={'8px'}
          alignItems={'center'}
          gap={'4px'}
        >
          <Icon h={'24px'} w={'24px'} as={IconExclamationMark} />
          <Text>
            {(cart.totalPrice?.centAmount ?? 0) >= 39900
              ? "You've unlocked free delivery to a parcel shop by spending over 399 kr."
              : 'Items in this bag are not reserved'}
          </Text>
        </Flex>
        <Flex direction={'column'} gap={'16px'}>
          <Divider borderColor={'beigeDark.500'} />
          <Flex direction={'column'}>
            <Flex gap={'64px'}>
              <Text w={'100px'}>Order value</Text>
              <Text>{moneyFormat(Math.abs(centPriceToPrice(totalOriginalAmount ?? 0, cart.totalPrice?.fractionDigits ?? 0)), 'DKK', 'da-DK')}</Text>
            </Flex>
            <Flex gap={'64px'}>
              <Text w={'100px'}>Discount</Text>
              <Text color={'red.600'}>
                {moneyFormat(Math.abs(centPriceToPrice(totalDiscountAmount ?? 0, cart.totalPrice?.fractionDigits ?? 0)), 'DKK', 'da-DK')}
              </Text>
            </Flex>
          </Flex>
          <Divider borderColor={'beigeDark.500'} />
          <Flex gap={'64px'} fontWeight={'bold'} fontSize={'lg'}>
            <Text w={'100px'}>Total</Text>
            <Text>{moneyFormat(Math.abs(centPriceToPrice(cart.totalPrice?.centAmount ?? 0, cart.totalPrice?.fractionDigits ?? 0)), 'DKK', 'da-DK')}</Text>
          </Flex>
        </Flex>
        <Flex direction={'column'} gap={'12px'} alignItems={'center'}>
          <Button isDisabled={cart?.lineItems.length == 0 || !allProductsInCartIsInStock} onClick={() => setStep(1)} variant={'solid'} w={'100%'}>
            {t('components.button.continue_to_checkout')}
          </Button>
          <Text fontSize={'xs'}>Prices and shipping costs will be confirmed at checkout.</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
