import { gql } from '@apollo/client'

export const CREATE_CART = gql`
  mutation createCart {
    createCart {
      id
      version
      createdAt
      lastModifiedAt
      totalPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      lineItems {
        id
        name
        price {
          id
          value {
            centAmount
            currencyCode
            fractionDigits
          }
        }
        quantity
        variant {
          id
          sku
          images {
            url
          }
          attributes {
            name
            value
          }
        }
      }
    }
  }
`

export const GET_CART = gql`
  query getCart($cartId: String) {
    getCart(cartId: $cartId) {
      id
      cartState
      createdAt
      lastModifiedAt
      directDiscounts {
        value
      }
      lineItems {
        id
        name
        productKey
        discountedPricePerQuantity {
          quantity
          discountedPrice {
            value {
              centAmount
              currencyCode
              fractionDigits
            }
            includedDiscounts {
              discount {
                id
                obj {
                  target {
                    triggerQuantity
                    discountedQuantity
                  }
                }
              }
            }
          }
        }
        price {
          id
          discounted {
            value {
              centAmount
              currencyCode
              fractionDigits
            }
          }
          value {
            centAmount
            currencyCode
            fractionDigits
          }
        }
        quantity
        totalPrice {
          centAmount
          currencyCode
          fractionDigits
        }
        variant {
          id
          sku
          images {
            url
          }
          attributes {
            name
            value
          }
          prices {
            value {
              centAmount
              currencyCode
              fractionDigits
            }
            channel {
              id
              obj {
                name
                key
                address {
                  country
                  city
                  streetName
                  streetNumber
                  postalCode
                }
              }
            }
            discounted {
              value {
                centAmount
                currencyCode
                fractionDigits
              }
              discount {
                id
                obj {
                  value {
                    type
                    permyriad
                  }
                }
              }
            }
          }
        }
        distributionChannel {
          id
          obj {
            name
            key
            address {
              country
              city
              streetName
              streetNumber
              postalCode
            }
          }
        }
        custom {
          fields
        }
      }
      totalPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      version
    }
  }
`

export const UPDATE_CART_LINE_ITEM = gql`
  mutation updateLineItemQuantity($cartId: String, $lineItemId: String, $quantity: Int) {
    updateLineItemQuantity(cartId: $cartId, lineItemId: $lineItemId, quantity: $quantity) {
      id
      createdAt
      lastModifiedAt
      lineItems {
        id
        name
        price {
          id
          value {
            centAmount
            currencyCode
            fractionDigits
          }
        }
        quantity
        totalPrice {
          centAmount
          currencyCode
          fractionDigits
        }
        variant {
          id
          sku
          images {
            url
          }
          attributes {
            name
            value
          }
        }
      }
      totalPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      version
    }
  }
`
