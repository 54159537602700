import { Divider, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { TbCheck } from 'react-icons/tb'
import { t } from 'i18next'
import { ReturnInStoreConfirmation } from '../components/ReturnInStoreConfirmation'

export const ReturnConfirmation = () => {
  const initialWidth = window.innerWidth
  const isLargerThan768 = initialWidth >= 768
  return (
    <Flex alignItems={'flex-start'} gap={6} mx={isLargerThan768 ? 'auto' : 4} direction={'column'} w={isLargerThan768 ? '50%' : '100%'}>
      <Flex gap={2}>
        <Icon bgColor={'transparent'} fontSize={'30px'} color={'mintGreen.500'} as={TbCheck} />
        <Heading>{t('main_app.return.components.steps.heading_return_submitted')}</Heading>
      </Flex>
      {/*{returnContext?.parcelResponse?.parcelShop?.carrier === 'POSTNORD' ? (*/}
      {/*  <Flex direction={'column'}>*/}
      {/*    <ReturnByParcel />*/}
      {/*  </Flex>*/}
      {/*) : (*/}
      <Flex direction={'column'}>
        <ReturnInStoreConfirmation />
      </Flex>
      {/*)}*/}
      <Divider style={{ borderWidth: 1, borderColor: 'rgba(211, 211, 202, 1)' }} />
      <Flex gap={4} flexDirection={'column'}>
        <Text fontSize={'xl'} fontWeight={'bold'}>
          {t('main_app.return.components.steps.text_return_question')}
        </Text>
        <Text fontSize={'14px'}>{t('main_app.return.components.steps.text_customer_care')}</Text>
      </Flex>
    </Flex>
  )
}
