import { useState, useLayoutEffect } from 'react';

export function useScrollbarWidth() {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const measureScrollbarWidth = () => {
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; 
      document.body.appendChild(outer);

      const inner = document.createElement('div');
      outer.appendChild(inner);

      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      outer.parentNode!.removeChild(outer);

      setScrollbarWidth(scrollbarWidth);
    };

    window.addEventListener('resize', measureScrollbarWidth);
    measureScrollbarWidth();

    return () => {
      window.removeEventListener('resize', measureScrollbarWidth);
    };
  }, []);

  return scrollbarWidth;
}
