import { Flex, Img } from '@chakra-ui/react'
import { IconBallpen, IconHome, IconStar } from '@tabler/icons-react'
import Logo from '../../../../assets/svg/Plenty&Svg/Plenty_Logo_Black.svg'
import { MenuItem } from '../merchant/MenuItem'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { t } from 'i18next'

export const AdminSideNav = () => {
  const navigate = useNavigate()

  return (
    <Flex
      boxShadow={'lg'}
      flexShrink={0}
      direction={'column'}
      w={'275px'}
      h={'100vh'}
      bgColor={'white.500'}
      borderTopRightRadius={30}
      borderBottomRightRadius={30}
      px={5}
      justifyContent={'space-between'}
    >
      <Flex direction={'column'}>
        <NavLink to={'/admin'}>
          <Flex alignItems={'center'} justifyContent={'center'} h={'36'} w={'full'}>
            <Img w={'60%'} src={Logo} />
          </Flex>
        </NavLink>

        <Flex direction={'column'} gap={4}>
          <MenuItem icon={IconHome} label={t('components.layout.label_home')} route={'/admin'} />
          <MenuItem icon={IconBallpen} label={t('components.layout.label_onboarding')} route={'/admin/onboarding'} />
        </Flex>
      </Flex>

      <Flex h={40} direction={'column'} justifyContent={'space-between'} py={8} alignItems={'center'} color={'ActiveBorder'}>
        <Flex direction={'column'}>
          <Flex>{t('components.layout.text_company_agreement')}</Flex>
        </Flex>
        <Flex>{t('components.layout.text_plenty_year')}</Flex>
      </Flex>
    </Flex>
  )
}
