import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Img,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { TbArrowLeft } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { Theme } from '../../../ThemeVariables'

export const SubSubSubCategoryDrawer = ({
  isOpen,
  onClose,
  selectedGender,
  subCategory,
  onCloseParentDrawer,
  subSubSubCategories,
  selectedSubSubCategory,
}: any) => {
  const FooterIcon = 'https://storage.googleapis.com/bucket-plenty-dev/Header%26Footer/FooterIcon.png'
  const navigate = useNavigate()
  const formatUrlSegment = (segment: any) => {
    return segment
      .toLowerCase()
      .replace(/&/g, 'and')
      .replace(/\s+/g, '_')
      .replace(/[^a-z0-9_]/g, '')
  }

  const formatCategorySegment = (segment: any) => {
    return segment
      .toLowerCase()
      .replace(/&/g, 'and')
      .replace(/\s+/g, '')
      .replace(/[^a-z0-9]/g, '')
  }

  const getCategoryPath = (selectedGender: any, subCategory: any, selectedSubSubCategory: any, categoryNameEn: any) => {
    const genderSegment = formatUrlSegment(selectedGender)
    const subCategorySegment = formatUrlSegment(subCategory)

    const subSubCategorySegment = formatUrlSegment(selectedSubSubCategory.categoryNameEn)
    const categorySegment = formatCategorySegment(categoryNameEn)

    return `/products/${genderSegment}/${subCategorySegment}/${subSubCategorySegment}/${categorySegment}`
  }

  const handleCategoryClick = (categoryNameEn: any) => {
    if (!selectedSubSubCategory) {
      console.error('Sub-sub-category is not selected')
      return
    }

    const routePath = getCategoryPath(selectedGender, subCategory, selectedSubSubCategory, categoryNameEn)

    if (onCloseParentDrawer) {
      onCloseParentDrawer()
    }

    onClose()

    navigate(routePath)
  }
  if (!selectedSubSubCategory) {
    return null
  }
  const handleAllCategoryClick = () => {
    const genderSegment = formatUrlSegment(selectedGender)
    const subCategorySegment = formatUrlSegment(subCategory)
    const subSubCategorySegment = formatUrlSegment(selectedSubSubCategory.categoryNameEn)

    const routePath = `/products/${genderSegment}/${subCategorySegment}/${subSubCategorySegment}`
    if (onCloseParentDrawer) {
      onCloseParentDrawer()
    }
    onClose()
    navigate(routePath)
  }

  const categoriesArray = Array.isArray(subSubSubCategories) ? subSubSubCategories : Array.from(subSubSubCategories.values()).flat()

  const filteredCategories = categoriesArray.filter((category) => category.parentCategoryId === selectedSubSubCategory.categoryId)
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='xs'>
      <DrawerOverlay />
      <DrawerContent bgColor={'beigeLight.500'}>
        <DrawerBody p={0}>
          <Flex p={Theme.spacing.padding.mobile} justifyContent={'space-between'}>
            <Img src={FooterIcon} width={'30px'} height={'40px'} />
            <DrawerCloseButton onClick={onClose} position={'relative'} fontSize={'20px'} />
          </Flex>
          <Flex px={Theme.spacing.padding.mobile} onClick={onClose} gap={Theme.gap.mobile} fontSize={'xl'} alignItems={'center'}>
            <TbArrowLeft />
            <Flex>{selectedGender === 'Men' ? 'Men' : selectedGender === 'Women' ? 'Women' : 'Kids'}</Flex>
          </Flex>
          <Accordion p={4} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton px={0} justifyContent={'space-between'} fontSize={'xl'} onClick={() => handleAllCategoryClick()}>
                  {t('components.burger_menu.text_all')} {selectedSubSubCategory ? selectedSubSubCategory.categoryName : 'Category'}
                </AccordionButton>
              </h2>
            </AccordionItem>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((category) => (
                <AccordionItem key={category.categoryId}>
                  <h2>
                    <AccordionButton px={0} justifyContent={'space-between'} fontSize={'xl'} onClick={() => handleCategoryClick(category.categoryNameEn)}>
                      <Box flex='1' textAlign='left'>
                        {category.categoryName}
                      </Box>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
              ))
            ) : (
              <Box>No categories available.</Box>
            )}
          </Accordion>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
