import { Tag } from '@chakra-ui/react'
import { BundleDiscount } from '../../../../generated/graphql'
import { getCheapestProductPrice, getPriceByChannel, permyriadToPercent } from '../../../../utils/PDPUtil'
import { PlentyStore } from '../../PDP/types'
import { PlentyProduct, PlentyVariant } from '../types'

type OptionAndStore = {
  option: PlentyVariant
  store: PlentyStore
}

type Placement = {
  top: number | undefined
  left: number | undefined
  right: number | undefined
  bottom: number | undefined
}

type DiscountTagProps = {
  product: PlentyProduct
  bundle: BundleDiscount | null
  optionAndStore?: OptionAndStore
  placement: Placement
}

const RelativeTag = (props: { price: any; placement: Placement }) => {
  return (
    <Tag
      color={'darkGreen.500'}
      colorScheme={'green'}
      position={'absolute'}
      left={props.placement.left}
      bottom={props.placement.bottom}
      top={props.placement.top}
      right={props.placement.right}
    >
      {permyriadToPercent(props.price.discounted.discount.obj.value.permyriad ?? 0)}%
    </Tag>
  )
}

const BundleTag = (props: { bundle: BundleDiscount | null; placement: Placement }) => {
  if (props.bundle?.bundle.__typename == 'QuantityBundle') {
    return (
      <Tag
        color={'darkGreen.500'}
        colorScheme={'green'}
        position={'absolute'}
        left={props.placement.left}
        bottom={props.placement.bottom}
        top={props.placement.top}
        right={props.placement.right}
      >
        Buy {props.bundle.bundle.discountedQuantity} get {props.bundle.bundle.triggerQuantity}
      </Tag>
    )
  } else if (props.bundle?.bundle.__typename == 'PriceBundle') {
    return (
      <Tag
        color={'darkGreen.500'}
        colorScheme={'green'}
        position={'absolute'}
        left={props.placement.left}
        bottom={props.placement.bottom}
        top={props.placement.top}
        right={props.placement.right}
      >
        Buy {props.bundle.bundle.triggerQuantity} for {props.bundle.bundle.totalPriceAfterDiscount} Kr
      </Tag>
    )
  } else {
    return <></>
  }
}

export const DiscountTag = (props: DiscountTagProps) => {
  const price = props.optionAndStore
    ? getPriceByChannel(props.optionAndStore.option, props.optionAndStore.store.channelId)
    : getCheapestProductPrice(props.product)

  if (!price) return null

  if (price.discounted?.discount?.obj?.value?.type === 'relative') {
    return <RelativeTag price={price} placement={props.placement} />
  } else if (props.bundle != null) {
    return <BundleTag bundle={props.bundle} placement={props.placement} />
  }

  return null
}
