import { Flex, Heading, Img, Stack } from '@chakra-ui/react'
import React from 'react'
import { t } from 'i18next'
import { findStoreBanner } from '../../utils/GoogleCloudBucketUtil'

type StoreProps = {
  // category: 'BestSeller' | 'SelectedRyesgade' | 'SelectedBruun' | 'VeroModa' | 'NameItBruun' | 'NameItNord' | string
  storeName: any
  storeAddress?: string
  to?: string
  scrollRef?: any
  size: string
  storeIndex: number
  dragging?: any
  imageActive?: any
  onStoreClick?: (store: any) => void
  storeDisplayNameUrl: any
}

export const StoreVerticalSelector = (props: StoreProps) => {
  const OpeningHours = () => {
    const currentTime = new Date()
    const currentHour = currentTime.getHours()
    const isOpen = currentHour >= 10 && currentHour < 19
    const openStyle = { color: 'green' }
    const closeStyle = { color: 'red' }

    return (
      <Flex>
        {isOpen ? (
          <React.Fragment>
            <Flex style={openStyle} mr={1}>
              {t('components.store_card.text_open')}{' '}
            </Flex>

            <Flex>{t('components.store_card.text_closes_at')}</Flex>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Flex style={closeStyle} mr={1}>
              {t('components.store_card.text_closed')}
            </Flex>

            <Flex>{t('components.store_card.text_opens_at')}</Flex>
          </React.Fragment>
        )}
      </Flex>
    )
  }

  const formatStoreName = (storeName: string) => {
    if (/^NAME IT/i.test(storeName)) {
      return 'NAME IT'
    }

    if (/^VERO MODA/i.test(storeName)) {
      return 'VERO MODA'
    }

    const nameArray = storeName.split(' ')
    const firstWord = nameArray[0]

    return firstWord.toUpperCase()
  }
  const handleStoreClick = (e: React.MouseEvent) => {
    e.preventDefault() // Prevent default behavior

    props.onStoreClick &&
      props.onStoreClick({
        storeName: props.storeName,
        storeDisplayNameUrl: props.storeDisplayNameUrl,
        streetAndNumber: props.storeAddress,
      })
  }

  return (
    <Flex direction={'column'} _hover={{ filter: 'brightness(60%)', cursor: 'pointer' }} onClick={handleStoreClick}>
      <Stack>
        <Flex position={'relative'} h={'auto'} cursor={'pointer'}>
          <Img
            draggable={false}
            maxWidth={props.size}
            width={props.size}
            borderRadius={'24px'}
            objectFit={'contain'}
            src={findStoreBanner(props.storeDisplayNameUrl)}
            filter={props.imageActive}
          />{' '}
        </Flex>
        <Flex maxW={props.size} textAlign={'start'} direction={'column'} wrap={'wrap'}>
          <Heading fontWeight={'600'} fontSize={'xl'}>
            {formatStoreName(props.storeName)}
          </Heading>
          <Flex fontWeight={'400'}>{props.storeAddress}</Flex>
          <OpeningHours />
        </Flex>
      </Stack>
    </Flex>
  )
}
