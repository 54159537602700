import { Card, CardBody, CardHeader, Flex, Grid, GridItem, Heading, Icon, IconButton, Stack, StackDivider, Text } from '@chakra-ui/react'
import { IconChevronRight, IconShoppingBag, IconShoppingCart } from '@tabler/icons-react'
import { t } from 'i18next'
import moment from 'moment'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import { useBreadcrumbs } from '../../../../context/BreadcrumbsContext'
import { MainRole, Maybe, Order, useGetAnalyticsQuery, useGetOrdersByAuthenticatedMerchantQuery, useWhoAmIQuery } from '../../../../generated/graphql'
import { Theme } from '../../../../ThemeVariables'
import { getDeliveryMethod } from '../../../../utils/LogisticsUtil'
import { moneyFormat } from '../../../../utils/MoneyUtil'
import withAuth from '../../../../utils/WithAuth'
import { LandingPageOrderLine } from './components/LandingPageOrderLine'
import { StatCard } from './components/StatCard'

const MerchantLandingPage = () => {
  const navigate = useNavigate()
  const { clearBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    clearBreadcrumbs()
  }, [])
  const orders = useGetOrdersByAuthenticatedMerchantQuery()
  const whoAmI = useWhoAmIQuery()

  const analytics = useGetAnalyticsQuery({
    variables: {
      fromDate: '2000-01-01',
      toDate: moment().add(1, 'days').format('yyyy-MM-DD'),
    },
  })

  const sumOrdersByDeliveryMethod = (deliveryMethodName: 'Pickup' | 'Delivery') => {
    switch (deliveryMethodName) {
      case 'Pickup':
        return orders.data?.getOrdersByAuthenticatedMerchant?.filter(
          (order: any) => getDeliveryMethod(order.shippingInfo.shippingMethodName) === 'Pickup' && order?.orderState == 'Open',
        ).length
      case 'Delivery':
        return orders.data?.getOrdersByAuthenticatedMerchant?.filter(
          (order: any) => getDeliveryMethod(order.shippingInfo.shippingMethodName) === 'Shipping' && order?.orderState == 'Open',
        ).length
      default:
        return 0
    }
  }

  const getNewOrdersByShippingMethod = (shippingMethod: string): any => {
    return orders.data?.getOrdersByAuthenticatedMerchant?.filter(
      (order: any) => getDeliveryMethod(order.shippingInfo.shippingMethodName) == shippingMethod && order?.orderState == 'Open',
    )
  }

  const NoNewOrders = () => {
    return (
      <Text fontStyle={'italic'} color={'ActiveBorder'}>
        {t('partner_portal.merchant.landing.text_nothing_to_show')}
      </Text>
    )
  }

  //  Should be <LinkButton onClick={() => alert('This is not implemented yet')} linkColor={'black.500'} underlineColor={'mintGreen.500'} /> when implemented
  const leftHeaderElement = <></>
  // Should be <ValueChangeIndicator newValue={100} priorValue={75} since={'yesterday'} /> when implemented
  const additionalValueElement = <></>

  return (
    <PartnerPortalPage pageHeaderLabel={whoAmI.loading ? '' : 'Hi ' + whoAmI.data?.whoAmI?.merchantName}>
      <Grid templateColumns='repeat(4, 1fr)' gap={8}>
        <GridItem w={'100%'} colSpan={1}>
          <StatCard
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={IconShoppingCart}
            action={() => navigate('/merchant.landing/orders')}
            loading={analytics.loading}
            title={t('partner_portal.merchant.landing.title_new_orders')}
            value={analytics.data?.getAnalytics?.newOrders || 0}
            textPlacement={'Right'}
          />
        </GridItem>
        <GridItem w={'100%'} colSpan={1}>
          <StatCard
            colorScheme={Theme.colors.colorSchemes.blackMint}
            icon={IconShoppingBag}
            loading={analytics.loading}
            title={t('partner_portal.merchant.landing.title_awaiting_fulfillment')}
            value={analytics.data?.getAnalytics?.returnAmount || 0}
            textPlacement={'Right'}
          />
        </GridItem>
        <GridItem w={'100%'} colSpan={2}>
          <StatCard
            colorScheme={Theme.colors.colorSchemes.blackWhite}
            textPlacement={'Left'}
            loading={analytics.loading}
            title={t('partner_portal.merchant.landing.title_total_revenue')}
            value={moneyFormat(analytics.data?.getAnalytics?.netSales || 0, 'DKK', 'da-DK')}
            leftHeaderElement={leftHeaderElement}
            additionalValueElement={additionalValueElement}
          />
        </GridItem>
        <GridItem w={'100%'} colSpan={2}>
          <Card>
            <CardHeader>
              <Flex justifyContent={'space-between'}>
                <Flex direction={'column'}>
                  <Heading size={'md'}>{t('partner_portal.merchant.landing.heading_pick_up')}</Heading>
                  <Text>
                    {sumOrdersByDeliveryMethod('Pickup')} {t('partner_portal.merchant.landing.text_new_orders')}{' '}
                  </Text>
                </Flex>
                <IconButton onClick={() => navigate('/merchant/orders')} variant={'ghost'} aria-label='goto' icon={<Icon as={IconChevronRight} />} />
              </Flex>
            </CardHeader>
            <CardBody>
              {orders.data && getNewOrdersByShippingMethod('Pickup').length > 0 ? (
                <Stack gap={4} divider={<StackDivider />}>
                  {getNewOrdersByShippingMethod('Pickup').map((value: Maybe<Order>, index: number) => {
                    return <LandingPageOrderLine order={value} key={index} />
                  })}
                </Stack>
              ) : (
                <NoNewOrders />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem w={'100%'} colSpan={2}>
          <Card>
            <CardHeader>
              <Flex justifyContent={'space-between'}>
                <Flex direction={'column'}>
                  <Heading size={'md'}>{t('partner_portal.merchant.landing.heading_shipping')}</Heading>
                  <Text>
                    {sumOrdersByDeliveryMethod('Delivery')} {t('partner_portal.merchant.landing.text_new_orders')}
                  </Text>
                </Flex>
                <IconButton onClick={() => navigate('/merchant/orders')} variant={'ghost'} aria-label='goto' icon={<Icon as={IconChevronRight} />} />
              </Flex>
            </CardHeader>
            <CardBody>
              {orders.data && getNewOrdersByShippingMethod('Shipping').length > 0 ? (
                <Stack gap={4} divider={<StackDivider />}>
                  {orders.data?.getOrdersByAuthenticatedMerchant
                    ?.filter((order: any) => getDeliveryMethod(order.shippingInfo.shippingMethodName) == 'Shipping' && order?.orderState == 'Open')
                    .map((value: any, index: number) => {
                      return <LandingPageOrderLine order={value} key={index} />
                    })}
                </Stack>
              ) : (
                <NoNewOrders />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </PartnerPortalPage>
  )
}

export default withAuth(MerchantLandingPage, [MainRole.RoleAdmin])
