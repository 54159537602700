/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Store } from '@commercetools/platform-sdk'
import React, { useEffect, useState } from 'react'
import { getAllStoresWithExpand } from '../../commercetools/requests'
import { PlentySlider } from '../layout/PlentySlider/PlentySlider'
import { StoreCard } from '../StoreSlider/StoreCard'
type NewStoreSliderProps = {
  gender?: 'kids' | 'women' | 'men'
}

const storeGenderMap: { [key: string]: string[] } = {
  kids: ['NAME IT Storcenter Nord', 'NAME IT Bruuns Galleri'],
  women: ['Bestseller Rømerhus', 'Selected Bruuns Galleri', 'Selected Ryesgade', 'Vero Moda Bruuns Galleri'],
  men: ['Bestseller Rømerhus', 'Selected Ryesgade', 'Selected Bruuns Galleri'],
}

export const NewStoreSlider = (props: NewStoreSliderProps) => {
  const [stores, setStores] = useState<Store[]>([])

  const formatAddress = (distributionChannels: any[]) => {
    if (!distributionChannels || distributionChannels.length === 0) return 'Address not available'

    const firstChannel = distributionChannels[0]
    const address = firstChannel.obj?.address

    if (!address) return 'Address not available'

    const { streetName, streetNumber, city } = address
    let formattedAddress = ''

    if (streetName) formattedAddress += streetName
    if (streetNumber) formattedAddress += formattedAddress ? ' ' + streetNumber : streetNumber
    if (city) formattedAddress += formattedAddress ? ', ' + city : city

    return formattedAddress || 'Address not available'
  }
  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllStoresWithExpand()
      if (result?.results) {
        setStores(result.results)
      }
    }

    fetchData()
  }, [])

  const sortedStores = stores.slice().sort((a, b) => (a.name?.en || '').localeCompare(b.name?.en || ''))

  const filteredStores = React.useMemo(() => {
    const gender = props.gender
    if (gender) {
      return sortedStores.filter((store) => {
        const storeName = store.name?.en
        return storeName ? storeGenderMap[gender].includes(storeName) : false
      })
    }
    return sortedStores
  }, [sortedStores, props.gender])

  return (
    <PlentySlider fontSize='3xl'>
      {filteredStores.map((store, index) => (
        <StoreCard
          key={store.id}
          size={'300px'}
          storeDisplayNameUrl={store.name?.en}
          storeName={store.name?.en} // Proper syntax to access the 'en' property
          storeAddress={formatAddress(store.distributionChannels)}
          to={`/stores/${store.key}`}
          storeIndex={index}
          // other props
        />
      ))}
    </PlentySlider>
  )
}
