import { Flex } from "@chakra-ui/react"

export const VerticalBackgroundPill = ({top}: any) => {
    return (
        <Flex
          width="250px"
          height="897px"
          flexShrink={0}
          borderRadius="284px 0px 0px 284px"
          bg="var(--primary-beige-10, #EDEDE7)"
          position="absolute"
          zIndex="-1" 
          right="0" 
          top={top}
          display={{ base: 'none', lg: 'flex' }} 
        />
      );
  };