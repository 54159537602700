import { useApolloClient } from '@apollo/client'
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex } from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useCart } from '../../context/CheckoutCartContext'

import { GET_CART } from '../../gql/cart'
import { ErrorBox } from '../ErrorBox'
import { CheckoutStep } from './CheckoutStep/CheckoutStep'
import { ReviewStep } from './ReviewStep/ReviewStep'

export const CartDrawer = () => {
  const { cartOpened, step, toggleCartDrawer } = useCart()
  const client = useApolloClient()

  //TODO: Casting the graphQL in console.log
  useEffect(() => {
    client.refetchQueries({
      include: [GET_CART],
    })
  }, [])

  const ShowStep = () => {
    switch (step) {
      case 0:
        return <ReviewStep />
      case 1:
        return <CheckoutStep />
      default:
        return <ErrorBox message={t('components.cart.error_unknown_step')} />
    }
  }

  return (
    <Drawer size={'sm'} isOpen={cartOpened} placement='right' onClose={() => toggleCartDrawer()}>
      <DrawerOverlay />
      <DrawerContent bgColor={'beigeLight.500'}>
        <DrawerCloseButton />
        <DrawerHeader></DrawerHeader>

        <DrawerBody p={4}>
          <Flex h={'full'} direction={'column'}>
            <ShowStep />
          </Flex>
        </DrawerBody>

        <DrawerFooter></DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
