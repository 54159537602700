import { Button, Flex, HStack, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import moment from 'moment'
import { useState } from 'react'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import DiscountCreationProvider from '../../../../context/DiscountCreationContext'
import { CampaignDiscount, MainRole, useGetCampaignDiscountsQuery } from '../../../../generated/graphql'
import withAuth from '../../../../utils/WithAuth'
import { CreateBulkDiscountModal } from '../../CreateBulkDiscountModal'
import { CreateDiscountModal } from '../../CreateDiscountModal'
import { CampaignDiscountCart } from '../components/CampaignDiscountCard'
import { FilterButton } from '../orders_page/components/FilterButton'

const MerchantDiscountsPage = () => {
  const [filterSelected, setFilterSelected] = useState<'All' | 'Active' | 'Scheduled' | 'Expired'>('All')
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)
  const [isBulkDiscountModalOpen, setIsBulkDiscountModalOpen] = useState(false)

  const discounts = useGetCampaignDiscountsQuery()
  const discountsData = discounts.data?.getCampaignDiscounts

  const isActive = (campaignDiscount: CampaignDiscount) => {
    const today = moment()
    const validFrom = moment(campaignDiscount.validFrom)
    const validTo = moment(campaignDiscount.validTo)

    return validFrom.isBefore(today) && validTo.isAfter(today)
  }

  const isScheduled = (campaignDiscount: CampaignDiscount) => {
    const today = moment()
    const validFrom = moment(campaignDiscount.validFrom)

    return validFrom.isAfter(today)
  }

  const isExpired = (campaignDiscount: CampaignDiscount) => {
    const today = moment()
    const validTo = moment(campaignDiscount.validTo)

    return validTo.isBefore(today)
  }

  const getActiveCampaignDiscountsCount = () => {
    if (discountsData != null) {
      return discountsData?.filter((campaignDiscount: CampaignDiscount) => isActive(campaignDiscount)).length
    } else {
      return 0
    }
  }

  const getScheduledCampaignDiscountsCount = () => {
    if (discountsData != null) {
      return discountsData?.filter((campaignDiscount: CampaignDiscount) => isScheduled(campaignDiscount)).length
    } else {
      return 0
    }
  }

  const getExpiredCampaignDiscountsCount = () => {
    if (discountsData != null) {
      return discountsData?.filter((campaignDiscount: CampaignDiscount) => isExpired(campaignDiscount)).length
    } else {
      return 0
    }
  }

  return (
    <PartnerPortalPage pageHeaderLabel={t('partner_portal.merchant.discounts.page_header') as string}>
      <DiscountCreationProvider>
        <CreateDiscountModal isOpen={isDiscountModalOpen} setOpen={setIsDiscountModalOpen} />
      </DiscountCreationProvider>
      <DiscountCreationProvider>
        <CreateBulkDiscountModal isOpen={isBulkDiscountModalOpen} setOpen={setIsBulkDiscountModalOpen} />
      </DiscountCreationProvider>

      <Flex direction={'column'} gap={6}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Flex>
            <HStack spacing={2}>
              <FilterButton
                amount={discountsData != null ? discountsData.length : 0}
                isToggled={filterSelected == 'All'}
                label={'All'}
                action={() => setFilterSelected('All')}
                size={'md'}
                variant={'Solid'}
              />
              <FilterButton
                amount={getActiveCampaignDiscountsCount()}
                isToggled={filterSelected == 'Active'}
                label={'Active'}
                action={() => setFilterSelected('Active')}
                size={'md'}
                variant={'Solid'}
                underlineColor={'green.500'}
              />
              <FilterButton
                amount={getScheduledCampaignDiscountsCount()}
                isToggled={filterSelected == 'Scheduled'}
                label={'Scheduled'}
                action={() => setFilterSelected('Scheduled')}
                size={'md'}
                variant={'Solid'}
                underlineColor={'blue.500'}
              />
              <FilterButton
                amount={getExpiredCampaignDiscountsCount()}
                isToggled={filterSelected == 'Expired'}
                label={'Expired'}
                action={() => setFilterSelected('Expired')}
                size={'md'}
                variant={'Solid'}
                underlineColor={'red.500'}
              />
            </HStack>
          </Flex>
          <Flex>
            <Button variant={'solid'} onClick={() => setIsBulkDiscountModalOpen(true)}>Bulk upload discounts</Button>
          </Flex>
          <Flex>
            <Button variant={'solid'} onClick={() => setIsDiscountModalOpen(true)}>Create discount</Button>
          </Flex>
        </Flex>
        <Flex gap={6} flexWrap={'wrap'}>
          {discountsData && discountsData.length == 0 ? (
            <Flex>
              <Text fontStyle={'italic'} fontWeight={'normal'}>
                No discounts created...
              </Text>
            </Flex>
          ) : null}

          {discountsData &&
            discountsData.map((campaignDiscount: CampaignDiscount, index: number) => {
              switch (filterSelected) {
                case 'All':
                  return <CampaignDiscountCart key={index} campaignDiscount={campaignDiscount} />
                case 'Active':
                  return isActive(campaignDiscount) ? <CampaignDiscountCart key={index} campaignDiscount={campaignDiscount} /> : null
                case 'Scheduled':
                  return isScheduled(campaignDiscount) ? <CampaignDiscountCart key={index} campaignDiscount={campaignDiscount} /> : null
                case 'Expired':
                  return isExpired(campaignDiscount) ? <CampaignDiscountCart key={index} campaignDiscount={campaignDiscount} /> : null
              }
            })}
        </Flex>
      </Flex>
    </PartnerPortalPage>
  )
}

export default withAuth(MerchantDiscountsPage, [MainRole.RoleAdmin])
