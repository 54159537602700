import { useApolloClient } from '@apollo/client'
import { Box, Button, Flex, Grid, GridItem, Heading, HStack, Tag, Text, useBreakpointValue } from '@chakra-ui/react'
import { CategoryReference, Store } from '@commercetools/platform-sdk'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { default as ImageGallery, default as ReactImageGallery } from 'react-image-gallery'
import { useParams } from 'react-router-dom'
import { getProductProjectionSearchByStyleNumber } from '../../../commercetools/requests'
import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { useBreadcrumbs } from '../../../context/BreadcrumbsContext'
import { useCart } from '../../../context/CheckoutCartContext'
import { BundleDiscount, MainRole, ProductInfo, useGetStoresLazyQuery } from '../../../generated/graphql'
import { GET_ALL_BUNDLE_DICSOUNTS_BY_PRODUCT_KEY } from '../../../gql/discount'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { useScrollbarWidth } from '../../../hooks/useScrollBarWidth'
import i18n from '../../../I18n/i18n'
import { Theme } from '../../../ThemeVariables'
import { allSizesAvailableAcrossStoresByColor, getCheapestStoreByVariant, getFirstAvailbleOptionByStore, getPriceByChannel } from '../../../utils/PDPUtil'
import { findProductVariantAttributeDefaultEnglish } from '../../../utils/ProductUtil'
import withAuth from '../../../utils/WithAuth'
import { NotFoundPageDesktop } from '../not_found_page/desktop/NotFoundPageDesktop'
import { DiscountTag } from '../PLP/components/DiscountTag'
import { PlentyProduct, PlentyVariant } from '../PLP/types'
import { ColorStyleSelector } from './components/ColorStyleSelector'
import { ProductInformation } from './components/ProductInformation'
import { SimilarItemsSlider } from './components/SimilarItemsSlider'
import { SizeSelector } from './components/SizeSelector'
import { StoreSelector } from './components/StoreSelector'
import { VariantPrice } from './components/VariantPrice'
import './ImageGallery.css'
import { ColorPlentyVariant, PlentyStore } from './types'
import { buildImages, formatPlentyProduct, getAvailableStores, getAvailableVariantsAcrossAllStoresByColor, sortPlentyVariantsBySize } from './utils'

type ProductHeaderInfoProps = {
  product: PlentyProduct | undefined
  selectedOption: PlentyVariant | undefined
  selectedStore: PlentyStore | undefined
}

export const ProductHeaderInfo = ({ product, selectedOption, selectedStore }: ProductHeaderInfoProps) => {
  return (
    <Flex px={{ base: '1rem', sm: '1rem', md: '0' }} pt={{ base: '1rem', sm: '1rem', md: '0' }} direction={'column'}>
      <Text fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }}>{findProductVariantAttributeDefaultEnglish(product?.masterVariant, 'brand').toUpperCase()}</Text>
      <Heading fontSize={{ base: '2xl', sm: '2xl', md: '5xl' }}>{product?.name.en}</Heading>
      {selectedOption && selectedStore && getPriceByChannel(selectedOption, selectedStore?.channelId) ? (
        <VariantPrice variant={selectedOption} channelId={selectedStore.channelId} normalFontWeight livePriceFontSize='xl' oldPriceFontSize='lg' />
      ) : (
        <Tag w={'fit-content'} colorScheme={'yellow'}>
          Size not sold in this store, but is in stock elsewhere
        </Tag>
      )}
    </Flex>
  )
}

const PDP = () => {
  const { styleNumber } = useParams()
  const params = useQueryParam()
  const client = useApolloClient()
  const { addLineItems } = useCart()
  const [product, setProduct] = useState<PlentyProduct>()
  const [category, setCategory] = useState<string>()
  const [bundleDiscounts, setBundleDiscounts] = useState<BundleDiscount[]>([])

  const [filteredStores, setFilteredStores] = useState<PlentyStore[] | undefined>([])
  const [filteredVariants, setFilteredVariants] = useState<ColorPlentyVariant[] | undefined>([])
  const [filteredSizes, setFilteredSizes] = useState<PlentyVariant[] | undefined>([])

  const [selectedOption, setSelectedOption] = useState<PlentyVariant>()
  const [selectedStore, setSelectedStore] = useState<PlentyStore>()
  const [selectedColor, setSelectedColor] = useState<ColorPlentyVariant>()

  const imageGalleryRef = useRef<ReactImageGallery | null>(null)
  const scrollbarWidth = useScrollbarWidth()

  const [buttonText, setButtonText] = useState(t('components.button.add_to_cart'))
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [buttonColor, setButtonColor] = useState('darkGreen.500') // Initial color of the button
  const [textColor, setTextColor] = useState('white')
  const [hoverColor, setHoverColor] = useState('darkGreen.500')
  const isMobile = useBreakpointValue({ base: true, sm: false })
  const showPropOnDesktop = useBreakpointValue({ base: false, sm: true, md: true })
  const thumbnailPosition = useBreakpointValue({ base: 'left', sm: 'bottom', md: 'bottom', lg: 'left' }) as 'top' | 'right' | 'bottom' | 'left' | undefined
  const { setActiveBreadcrumbs } = useBreadcrumbs()

  const [isLoading, setIsLoading] = useState(true)

  const [getStores] = useGetStoresLazyQuery()

  const handleColorChange = (newColorOption: ColorPlentyVariant, product: PlentyProduct) => {
    setSelectedColor(newColorOption)

    const sizes = allSizesAvailableAcrossStoresByColor(product, newColorOption)
    setFilteredSizes(sortPlentyVariantsBySize(sizes))

    if (selectedStore) {
      const firstAvailableOptionByStore = getFirstAvailbleOptionByStore(selectedStore, sizes)
      setSelectedOption(firstAvailableOptionByStore)
    }
  }

  const setCategoryToLastInArrayFromProudctCategoryAncestors = (ancestors: CategoryReference[] | undefined) => {
    if (ancestors) {
      setCategory(ancestors[ancestors.length - 1].obj?.slug.en)
    }
  }

  const getVariantToSelect = (sizes: PlentyVariant[]) => {
    if (params.get('variant')) {
      return sizes.find((variant: PlentyVariant) => variant.sku == params.get('variant')) ?? sizes[0]
    } else {
      return sizes[0]
    }
  }

  const getStoreToSelect = (variant: PlentyVariant, stores: PlentyStore[]) => {
    if (params.get('store')) {
      return stores.find((it) => it.key == params.get('store') && it.variantsAvailable.includes(variant.key)) ?? getCheapestStoreByVariant(variant, stores)
    } else {
      const storeToSelect = getCheapestStoreByVariant(variant, stores)

      return storeToSelect
    }
  }

  const selectVariantAndStore = (stores: PlentyStore[], sizes: PlentyVariant[]) => {
    const variantToSelect = getVariantToSelect(sizes)
    setSelectedOption(variantToSelect)

    const storeToSelect = getStoreToSelect(variantToSelect, stores)
    setSelectedStore(storeToSelect)

    setSelectedOption(variantToSelect)
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)

    const variantId = params.get('variant')

    if (styleNumber) {
      getProductProjectionSearchByStyleNumber(styleNumber, variantId).then((resProjection) => {
        const formattedProduct = formatPlentyProduct(resProjection.results[0])
        setProduct(formattedProduct)

        getBundleDiscount(formattedProduct.key)

        const categoryAncestors = resProjection.results[0].categories[0].obj?.ancestors
        setCategoryToLastInArrayFromProudctCategoryAncestors(categoryAncestors)

        const colorVariants = getAvailableVariantsAcrossAllStoresByColor(formattedProduct)

        if (variantId) {
          const colorToSelect = colorVariants.find((variant) => variant.variants.some((pVariant) => pVariant.sku === variantId)) ?? colorVariants[0]

          handleColorChange(colorToSelect, formattedProduct)

          setFilteredVariants(colorVariants)

          const sizes = allSizesAvailableAcrossStoresByColor(formattedProduct, colorToSelect)

          getStores().then((resStores: any) => {
            const stores = resStores.data.getStores as Store[]
            const allStoresThatSellProduct = getAvailableStores(formattedProduct, stores)
            setFilteredStores(allStoresThatSellProduct)

            selectVariantAndStore(allStoresThatSellProduct, sizes)
          })
        }
      })
    }
  }, [params])

  const getBundleDiscount = (productKey: string) => {
    client
      .query({
        query: GET_ALL_BUNDLE_DICSOUNTS_BY_PRODUCT_KEY,
        variables: {
          productKey: productKey,
        },
      })
      .then((response: any) => {
        setBundleDiscounts(response.data.getAllBundleDiscountByProductKey)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const addLineItemToCart = async () => {
    setIsButtonDisabled(true)
    setButtonText(`${t('components.button.adding_to_cart')}`)
    if (!selectedOption || !product) {
      setButtonText(`${t('components.button.failed_adding_to_cart')}`)
      setButtonColor('red') // Color on failure
      setTextColor('darkGreen.500')
      setHoverColor('red') // Hover color on failure
      return
    }

    try {
      await addLineItems({
        styleNumber: product.key,
        ean: selectedOption.key,
        quantity: 1,
        storeKey: selectedStore ? selectedStore.key : '',
      })
      setButtonText(t('components.button.added_to_cart'))
      setButtonColor('mintGreen.500') // Color on success
      setTextColor('darkGreen.500')
      setHoverColor('mintGreen.500') // Hover color on success
      // Facebook Pixel Track Event for AddToCart
      window.fbq('track', 'AddToCart', {
        value: MetaTagRetailPrice,
        currency: currency,
        content_name: product.name,
        content_type: 'product',
        content_ids: [productOptionId],
      })

      setTimeout(() => {
        setButtonText(t('components.button.add_to_cart'))
        setButtonColor('darkGreen.500') // Reset to initial color
        setTextColor('white')
        setHoverColor('darkGreen.500') // Reset to initial hover color
      }, 1000)
    } catch (error) {
      console.log(error)
      setButtonText(`${t('components.button.failed_adding_to_cart')}`)
      setButtonColor('red') // Color on failure
      setTextColor('darkGreen.500')
      setHoverColor('red') // Hover color on failure

      // Revert the button text back to 'Add to cart' after 1 second
      setTimeout(() => {
        setButtonText(t('components.button.add_to_cart'))
        setButtonColor('darkGreen.500') // Reset to initial color
        setTextColor('white')
        setHoverColor('darkGreen.500') // Reset to initial hover color
      }, 500)
    }

    setIsButtonDisabled(false)
  }

  useEffect(() => {
    const eventData = {
      content_name: product?.name || 'Unknown product',
      content_category: product?.masterVariant.attributes[7].value || 'Unknown category',
      content_id: product?.id,
      content_type: 'product',
    }

    window.fbq('track', 'ViewContent', eventData)
  }, [])

  useEffect(() => {
    if (selectedStore?.name) {
      setActiveBreadcrumbs([
        {
          title: <strong>{t('global.text_home')}</strong>,
          clickablePath: `/`,
          key: 0,
        },

        {
          title: `${selectedStore.name}`,
          clickablePath: `/stores/${selectedStore.key}`,
          key: 1,
        },
      ])
    } else {
      setActiveBreadcrumbs([
        {
          title: <strong>{t('global.text_home')}</strong>,
          clickablePath: `/`,
          key: 0,
        },
        {
          title: t('components.breadcrumbs.all_stores'),
          key: 1,
        },
      ])
    }
  }, [i18n.language, location.pathname])

  const getAvailabilityStatus = (quantity: number | null | undefined): string => {
    if (quantity == null) {
      // Checks for both null and undefined
      return 'pending' // or any other default status you prefer
    }
    if (quantity >= 1) {
      return 'in stock'
    }
    return 'out of stock'
  }

  if (isLoading) {
    return <div></div>
  }

  if (!isLoading && !selectedOption) {
    return <NotFoundPageDesktop />
  }

  const getMostValueableBundleDiscountBySelectedStore = () => {
    const bundleDiscountsBySelectedStore = bundleDiscounts.filter(
      (bundleDiscount: BundleDiscount) => bundleDiscount.channelKey == selectedStore?.distributionChannel.key,
    )

    if (bundleDiscountsBySelectedStore.length > 0 && selectedOption) {
      return (
        bundleDiscountsBySelectedStore.find((bundleDiscount) => !(bundleDiscount.entityInfo as ProductInfo).excludedSkus.includes(selectedOption.sku)) || null
      )
    } else {
      return null
    }
  }

  const storeHasBundle = () => {
    const bundleDiscount = getMostValueableBundleDiscountBySelectedStore()

    if (bundleDiscount) {
      const productInfo = bundleDiscount.entityInfo as ProductInfo

      if (selectedOption) {
        return !productInfo?.excludedSkus?.includes(selectedOption.sku) && bundleDiscount?.channelKey == selectedStore?.distributionChannel.key
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const channels = selectedOption?.availability?.channels
  const firstKey = Object.keys(channels || {})[0]
  const quantity = channels?.[firstKey]?.availableQuantity
  const availability = getAvailabilityStatus(quantity)

  const productImages = product ? product.masterVariant?.images : []

  const MetaTagRetailPrice = product?.masterVariant?.prices?.[0]?.value?.centAmount
    ? (product.masterVariant.prices[0].value.centAmount / 100).toFixed(2).toString()
    : 'Product price'
  const currency = product?.masterVariant.prices[0].value.currencyCode || 'DKK'
  const productOptionId = product?.masterVariant.sku || 'Product id'
  const productImageUrl = product?.masterVariant.images[0].url || 'Product image link'
  const productAdditionalImageUrl = productImages?.length ? productImages[productImages.length - 1].url : 'Product image link'

  const brand = product?.masterVariant.attributes[5].value['en'] || 'Brand'
  const itemGroupId = product?.masterVariant.attributes[8].value['en'] || 'Cloth'
  // DO NOT DELETE it is for loggin the stuff we send to meta for the instagram catalog
  // const logHelmetContent = () => {
  //   const helmetContent = {
  //     title: product?.name.en || 'Product',
  //     'og:title': (product?.name.en || 'Product').toLowerCase(),
  //     'og:description': (product?.masterVariant.attributes[13].value || 'Product Description is none existing on this product since its new').toLowerCase(),
  //     'product:price:amount': MetaTagRetailPrice,
  //     'product:price:currency': currency,
  //     'product:retailer_item_id': productOptionId,
  //     'og:image': productImageUrl,
  //     'og:type': 'plentyAnd_sharing:product',
  //     'og:site': 'https://plentyand.dk' || 'https://www.plentyand.dk',
  //     'product:condition': 'New',
  //     'product:item_group_id': itemGroupId,
  //     'product:brand': brand,
  //     'og:site_name': 'PlentyAnd Shop',
  //     'og:url': `https://www.plentyand.dk/product/${product?.key || ' url'}`,
  //     'og:availability': availability,
  //     'product:additional_image_link': productAdditionalImageUrl,
  //   }
  //   console.log(helmetContent)
  // }

  // logHelmetContent()

  return (
    <MainPageResponsive justify={'center'} mx={'auto'} loading={false}>
      <>
        <Helmet>
          <title>{product?.name.en || 'Product'}</title>
          <meta property='og:title' content={(product?.name.en || 'Product').toLowerCase()} />
          <meta
            property='og:description'
            content={(product?.masterVariant.attributes[13].value['en'] || 'Product Description is none existing on this product since its new').toLowerCase()}
          />
          <meta property='product:price:amount' content={MetaTagRetailPrice} />
          <meta property='product:price:currency' content={currency} />
          <meta property='product:retailer_item_id' content={productOptionId} />
          <meta property='og:image' content={productImageUrl} />
          <meta property='og:type' content={'plentyAnd_sharing:product'} />
          <meta property='og:site' content={'https://www.plentyand.dk'} />
          <meta property='product:condition' content={'New'} />
          <meta property='product:item_group_id' content={itemGroupId} />
          <meta property='product:brand' content={brand} />
          <meta property='og:site_name' content={'PlentyAnd Shop'} />
          <meta property='og:url' content={`https://www.plentyand.dk/product/${product?.key || ' url'}`} />
          <meta property='og:availability' content={availability} />
          <meta property='product:additional_image_link' content={productAdditionalImageUrl} />

          {/* Google Product Category */}
          <meta property='product:category' content='Apparel &amp; Accessories &gt; Clothing' />
        </Helmet>

        <Flex maxWidth={'100%'} direction={'column'} gap={'4rem'} mt={'1rem'} pb={'2.5rem'}>
          <Grid templateColumns={['repeat(1, 1fr)', 'repeat(6, 1fr)']} px={Theme.PaddingOnSides.PDP} gap={{ base: '0rem', sm: '4rem' }}>
            <GridItem
              ml={'1rem'}
              borderRadius={{ base: '24px 0 0 24px', sm: '0' }}
              backgroundColor={{ base: 'white', sm: 'transparent', md: 'transparent', lg: 'transparent' }}
              colSpan={3}
            >
              {isMobile && <ProductHeaderInfo product={product} selectedOption={selectedOption} selectedStore={selectedStore} />}

              <Flex position={'sticky'} top={'120px'} zIndex={400}>
                {selectedOption && (
                  <Box position='relative' pb={'0.5rem'} borderRadius={{ base: '0 0 0 24px', sm: '0' }} overflow='hidden'>
                    <Flex>
                      <ImageGallery
                        showBullets={!showPropOnDesktop}
                        ref={imageGalleryRef}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showNav={false}
                        showThumbnails={showPropOnDesktop}
                        thumbnailPosition={thumbnailPosition}
                        slideOnThumbnailOver={false}
                        items={buildImages(selectedColor?.variants[0].images ?? [])}
                      />
                      {product && selectedStore ? (
                        <DiscountTag
                          product={product}
                          bundle={storeHasBundle() ? getMostValueableBundleDiscountBySelectedStore() : null}
                          optionAndStore={{ option: selectedOption, store: selectedStore }}
                          placement={{
                            bottom: isMobile ? 10 : undefined,
                            left: isMobile ? 7 : undefined,
                            right: isMobile ? undefined : 5,
                            top: isMobile ? undefined : 5,
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Box>
                )}
              </Flex>
            </GridItem>
            <GridItem mx={'1rem'} colSpan={3} pt={Theme.spacing.padding.mobile}>
              <Flex direction={'column'} gap={6}>
                {!isMobile && <ProductHeaderInfo product={product} selectedOption={selectedOption} selectedStore={selectedStore} />}
                {product && (
                  <ColorStyleSelector
                    product={product}
                    filteredVariants={filteredVariants}
                    selectedColor={selectedColor}
                    handleColorChange={handleColorChange}
                  />
                )}
                <Flex zIndex={11} position={'relative'} h={'60px'}>
                  {filteredSizes && selectedOption && filteredStores && selectedStore ? (
                    <SizeSelector
                      filteredSizes={filteredSizes}
                      filteredStores={filteredStores}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      setSelectedStore={setSelectedStore}
                      selectedStoreId={selectedStore?.channelId || ''}
                      selectedStoreChannelKey={selectedStore?.distributionChannel.key}
                      bundleDiscounts={bundleDiscounts}
                    />
                  ) : null}
                </Flex>
                <HStack width={'100%'} spacing={Theme.spacing.padding.desktop} flex={1}>
                  <Button
                    backgroundColor={buttonColor}
                    color={textColor}
                    isDisabled={!selectedOption || isButtonDisabled}
                    flex={4}
                    onClick={() => addLineItemToCart()}
                    size={'lg'}
                    variant={'solid'}
                    _hover={{
                      backgroundColor: hoverColor, // Use hoverColor here
                    }}
                  >
                    {buttonText}
                  </Button>
                </HStack>
                <Flex zIndex={10} position={'relative'} h={'92px'}>
                  {filteredStores && selectedStore && selectedOption && filteredSizes ? (
                    <StoreSelector
                      filteredStores={filteredStores}
                      filteredSizes={filteredSizes}
                      selectedStore={selectedStore}
                      selectedOption={selectedOption}
                      storeFromURL={params.get('store')}
                      setSelectedStore={setSelectedStore}
                      setSelectedOption={setSelectedOption}
                      bundleDiscounts={bundleDiscounts}
                    />
                  ) : null}
                </Flex>
                <Flex width={'100%'}>
                  <ProductInformation product={product} variantHasBundle={storeHasBundle()} />
                </Flex>
              </Flex>
            </GridItem>
          </Grid>

          <Box maxWidth={`calc(100vw - ${scrollbarWidth}px)`} px={Theme.PaddingOnSides.PDP} alignItems={'center'} overflow={'auto'}>
            <SimilarItemsSlider product={product} category={category} />
          </Box>
          {/* <Flex mx={'auto'} px={{ base: '1rem', md: '0' }}>
            <StoreAboutResponsive
              storeKey={selectedStore?.key}
              onClick={() => navigate(`/stores/${selectedStore?.key}`)}
            />
          </Flex> */}
        </Flex>
      </>
    </MainPageResponsive>
  )
}
export default withAuth(PDP, [MainRole.RoleGuest])
