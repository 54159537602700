import { ApolloProvider } from '@apollo/client'
import { ChakraProvider, Flex, Text } from '@chakra-ui/react'
import { Amplify } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import client from './apollo-client'
import './App.css'
import awsmobile from './aws-export'
import { theme } from './chakraTheme/ChakraThemeExtension'
import { Fonts } from './chakraTheme/components/Fonts'
import { CartDrawer } from './components/CartDrawer/CartDrawer'
import { ResponsiveDecider } from './components/layout/ResponsiveDecider'
import AuthContext from './context/Auth'
import LocationPillContext from './context/LocationPillContext'
import ReturnContext from './context/OrderReturnContext'
import { Login } from './pages/login_page/login/Login'
import OrderConfirmationPageDesktop from './pages/main_app/checkout_page/order_confirmation/desktop/OrderConfirmationPageDesktop'
import OrderConfirmationPageMobile from './pages/main_app/checkout_page/order_confirmation/mobile/OrderConfirmationPageMobile'

import OnboardingConfirmationPage from './pages/main_app/onboarding/OnboardingConfirmationPage'
import OnboardingPage from './pages/main_app/onboarding/OnboardingPage'
import { CookiePolicyDesktop } from './pages/main_app/site_politics/desktop/CookiePolicyDesktop'
import { PrivacyTermsDesktop } from './pages/main_app/site_politics/desktop/PrivacyTermsDesktop'
import { TermsOfUseDesktop } from './pages/main_app/site_politics/desktop/TermsOfUseDesktop'

import { CustomerService } from './pages/main_app/FAQ_pages/CustomerService'
import AdminOnboardingOverviewPage from './pages/partner_portal/admin/onboarding_page/AdminOnboardingOverviewPage'
import AdminOnboardingPage from './pages/partner_portal/admin/onboarding_page/AdminOnboardingPage'
import MerchantAnalyticsPage from './pages/partner_portal/merchant/analytics_page/MerchantAnalyticsPage'
import MerchantLandingPage from './pages/partner_portal/merchant/landing_page/MerchantLandingPage'
import SalePage from './pages/partner_portal/merchant/sale_page/SalePage'
import MerchantSocialMediaPage from './pages/partner_portal/merchant/some_page/MerchantSocialMediaPage'
import MerchantSupportPage from './pages/partner_portal/merchant/support_page/MerchantSupportPage'
import { Theme } from './ThemeVariables'

import './I18n/i18n'
import { AboutUsDesktop } from './pages/main_app/about_us_page/desktop/AboutUsDesktop'

import { useTranslation } from 'react-i18next'

import CheckoutCartContext from './context/CheckoutCartContext'
import { CheckoutRedirect } from './pages/main_app/checkout_page/CheckoutRedirect'
import { NotFoundPageDesktop } from './pages/main_app/not_found_page/desktop/NotFoundPageDesktop'
import ReturnLoginPage from './pages/main_app/return_login_page/ReturnLoginPage'
import ReturnPage from './pages/main_app/return_page/ReturnPage'
import OrdersPage from './pages/partner_portal/merchant/orders/OrdersPage'
import MerchantProductsPage from './pages/partner_portal/merchant/products_page/MerchantProductsPage'
import MerchantReturnsPage from './pages/partner_portal/merchant/returns_page/MerchantReturnsPage'

import { HelmetProvider } from 'react-helmet-async'
import BreadcrumbsProvider from './context/BreadcrumbsContext'
import CTFilterContext from './context/CTFilterContext'
import ProductSearchContext from './context/ProductSearchContext'
import GenderHomePage from './pages/main_app/gender_home_page/Index'
import PDP from './pages/main_app/PDP/PDP'
import PLP from './pages/main_app/PLP'
import { FilterDrawer } from './pages/main_app/PLP/components/FilterDrawer'
import SearchPage from './pages/main_app/search_page'
import StoreLocator from './pages/main_app/store_locator'
import NewStoreLandingPage from './pages/main_app/store_page_2.0/NewStoreLandingPage'
import MerchantDiscountsPage from './pages/partner_portal/merchant/discounts_page/MerchantDiscountsPage'
import MerchantProductPage from './pages/partner_portal/merchant/product_page/MerchantProductPage'
import LandingPage from './pages/main_app/landing_page'
import { AdyenDomainPage } from './pages/adyen_domain_page'
Amplify.configure({ ...awsmobile })
export const App = () => {
  const { t, i18n } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const guestId = localStorage.getItem('guestId')

    if (!guestId) {
      const id = Math.random().toString(36).substring(2, 18)
      localStorage.setItem('guestId', id)
    }
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('languageSelected')) {
      setShowModal(true)
    } else {
      i18n.changeLanguage(localStorage.getItem('languageSelected')!)
    }
  }, [i18n])

  return (
    <LocationPillContext>
      <AuthContext>
        <ChakraProvider theme={theme}>
          <Fonts />
          <ApolloProvider client={client}>
            <BreadcrumbsProvider>
              <CheckoutCartContext>
                <CartDrawer />
                <ReturnContext>
                  <CTFilterContext>
                    <ProductSearchContext>
                      <Flex minHeight={'100vh'} bgColor={Theme.colors.bg}>
                        <Routes>
                          <Route
                            path='/order/:orderNumber'
                            element={<ResponsiveDecider desktopComp={<OrderConfirmationPageDesktop />} mobileComp={<OrderConfirmationPageMobile />} />}
                          />
                          <Route path='/brands' element={<></>} />
                          <Route path='/brands/:brandName' element={<></>} />
                          <Route
                            path='/product/:styleNumber'
                            element={
                              <HelmetProvider>
                                <PDP />
                              </HelmetProvider>
                            }
                          />
                          <Route
                            path='/products/*'
                            element={
                              <>
                                <FilterDrawer />
                                <PLP />
                              </>
                            }
                          />

                          <Route path='/search' element={<SearchPage />} />
                          <Route path='/checkout' element={<CheckoutRedirect />} />
                          <Route path='/merchant' element={<MerchantLandingPage />} />
                          <Route path='/merchant/orders' element={<OrdersPage />} />
                          <Route path='/merchant/returns' element={<MerchantReturnsPage />} />
                          <Route path='/merchant/products' element={<MerchantProductsPage />} />
                          <Route path='/merchant/products/:productId' element={<MerchantProductPage />} />
                          <Route path='/merchant/discounts' element={<MerchantDiscountsPage />} />
                          <Route path='/merchant/sale' element={<SalePage />} />
                          <Route path='/merchant/analytics' element={<MerchantAnalyticsPage />} />
                          <Route path='/merchant/some' element={<MerchantSocialMediaPage />} />
                          <Route
                            path='/merchant/support'
                            element={<ResponsiveDecider mobileComp={<Text>LMAO</Text>} desktopComp={<MerchantSupportPage />} />}
                          />
                          <Route path='/admin/onboarding' element={<AdminOnboardingOverviewPage />}></Route>
                          <Route path='/admin/onboarding/:onboardingNumber' element={<AdminOnboardingPage />}></Route>
                          <Route path='/politics/privacy_policy' element={<PrivacyTermsDesktop />} />
                          <Route path='/politics/terms_of_use_policy' element={<TermsOfUseDesktop />} />
                          <Route path='/politics/cookie_policy' element={<CookiePolicyDesktop />} />
                          <Route path='/return/login' element={<ReturnLoginPage />} />
                          <Route path='/return/item_selection' element={<ReturnPage />} />
                          <Route path='/stores/:storeDisplayNameParam' element={<NewStoreLandingPage />} />
                          <Route path='/onboarding' element={<OnboardingPage />} />
                          <Route path='/onboarding/:onboardingNumber' element={<OnboardingConfirmationPage />} />
                          <Route path='/customer_service/' element={<CustomerService />} />
                          <Route path='/about' element={<AboutUsDesktop />} />
                          <Route path='/*' element={<NotFoundPageDesktop />} />
                          <Route path='/store-map' element={<StoreLocator />} />
                          <Route path='/landing-page/:gender' element={<GenderHomePage />} />
                          <Route path='/login' element={<Login />} />
                          <Route path='/' element={<LandingPage />} />
                          <Route path='/.well-known/apple-developer-merchantid-domain-association' element={<AdyenDomainPage />} />
                        </Routes>
                      </Flex>
                    </ProductSearchContext>
                  </CTFilterContext>
                </ReturnContext>
              </CheckoutCartContext>
            </BreadcrumbsProvider>
          </ApolloProvider>
        </ChakraProvider>
      </AuthContext>
    </LocationPillContext>
  )
}
