import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, ListItem, UnorderedList } from '@chakra-ui/react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

export const OrdersAccordion = () => {
  const navigate = useNavigate()

  return (
    <Flex direction='column' w='full' alignItems={'start'} alignContent={'start'}>
      <Heading borderRadius={'20px'} fontSize={'3xl'} padding={4} width={'full'}>
        {t('main_app.faq.orders.headline_orders')}
      </Heading>

      <Accordion allowToggle w={'full'}>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_change')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>
          <AccordionPanel>
            <UnorderedList>
              <ListItem>
                {t('main_app.faq.orders.accordion_panel_1.0')}
                <ListItem>{t('main_app.faq.orders.accordion_panel_1.1')} </ListItem>
                <ListItem>{t('main_app.faq.orders.accordion_panel_1.2')}</ListItem>
                <ListItem>{t('main_app.faq.orders.accordion_panel_1.3')}</ListItem>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_cancel')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>
            <UnorderedList>
              <ListItem>
                {t('main_app.faq.orders.accordion_panel_2.0')}
                <ListItem>{t('main_app.faq.orders.accordion_panel_2.1')}</ListItem>
                <ListItem>{t('main_app.faq.orders.accordion_panel_2.2')} </ListItem>
                <ListItem>{t('main_app.faq.orders.accordion_panel_2.3')}</ListItem>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_received_order')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>{t('main_app.faq.orders.accordion_panel_3')}</AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_track_package')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>
            <Box display='inline' mr={1}>
              {t('main_app.faq.orders.accordion_panel_4.0')}
            </Box>
            <Box as='span' onClick={() => navigate('/politics/privacy_policy')} cursor='pointer' color='#CC5F5F' display='inline'>
              {t('main_app.faq.orders.accordion_panel_4.1')}
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_incorrect')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>
            <Box display='inline'>{t('main_app.faq.orders.accordion_panel_5.0')}</Box>
            <Box as='span' onClick={() => navigate('/return/login')} cursor='pointer' color='#CC5F5F' display='inline'>
              {t('main_app.faq.orders.accordion_panel_5.1')}
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_damaged')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>
            <UnorderedList>
              {t('main_app.faq.orders.accordion_panel_6.0')}
              <ListItem>{t('main_app.faq.orders.accordion_panel_6.1')}</ListItem>
              <ListItem>{t('main_app.faq.orders.accordion_panel_6.2')}</ListItem>
              <ListItem>{t('main_app.faq.orders.accordion_panel_6.3')}</ListItem>
              <ListItem>
                <Box display='inline'>{t('main_app.faq.orders.accordion_panel_6.4')} </Box>
                <Box as='span' onClick={() => navigate('/return/login')} cursor='pointer' color='#CC5F5F' display='inline'>
                  {t('main_app.faq.orders.accordion_panel_6.5')}
                </Box>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_missing')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>{t('main_app.faq.orders.accordion_panel_7')}</AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_place_an_order')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>

          <AccordionPanel>
            <UnorderedList>
              <ListItem>{t('main_app.faq.orders.accordion_panel_8.0')}</ListItem>
              <ListItem>{t('main_app.faq.orders.accordion_panel_8.1')}</ListItem>
              <ListItem>{t('main_app.faq.orders.accordion_panel_8.2')}</ListItem>
              <ListItem>{t('main_app.faq.orders.accordion_panel_8.3')}</ListItem>
              <ListItem>{t('main_app.faq.orders.accordion_panel_8.4')}</ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem py={2} borderBottomColor={'#434343'} borderTopColor={'transparent'} borderBottomWidth={1}>
          <Flex alignItems={'center'}>
            <AccordionButton textAlign={'left'} justifyContent={'flex-start'}>
              {t('main_app.faq.orders.accordion_button_personal_information')}
            </AccordionButton>
            <AccordionIcon />
          </Flex>
          <AccordionPanel>
            <UnorderedList>
              <ListItem>{t('main_app.faq.orders.accordion_panel_9.0')}</ListItem>

              <ListItem>
                <Box display='inline'>{t('main_app.faq.orders.accordion_panel_9.1')}</Box>
                <Box as='span' onClick={() => navigate('/politics/privacy_policy')} cursor='pointer' color='#CC5F5F' display='inline'>
                  {t('main_app.faq.orders.accordion_panel_9.2')}
                </Box>
                <Box display='inline'>{t('main_app.faq.orders.accordion_panel_9.3')}</Box>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}
