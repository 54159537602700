import { Button, Flex, Img, Stack, Text } from '@chakra-ui/react'
import { IconLock, IconMail } from '@tabler/icons-react'
import { Auth } from 'aws-amplify'
import { Field, Form, Formik } from 'formik'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Logo from '../../../assets/svg/LoginSvg/Plenty_Logo_Green_On_Black.svg'
import { AuthTextField } from '../../../components/TextField/AuthTextField'

type IFormInput = {
  email: string
  password: string
}

export const SignIn = () => {
  const navigate = useNavigate()

  const [error, setError] = useState<string>('')
  const [rememberMe, setRememberMe] = useState<boolean>(false) // change to false here

  const LoginFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('components.cart.components.info_form_invalid_email') as string)
      .required(t('components.cart.components.info_form_email') as string),
    password: Yup.string().required(t('components.cart.components.info_form_password') as string),
  })

  useEffect(() => {
    const email = localStorage.getItem('email')
    const password = localStorage.getItem('password')
    if (email && password) {
      setRememberMe(true)
    }
  }, [])

  return (
    <Formik
      initialValues={{
        email: rememberMe ? localStorage.getItem('email') || '' : '',
        password: rememberMe ? localStorage.getItem('password') || '' : '',
      }}
      validationSchema={LoginFormSchema}
      onSubmit={async (values, actions) => {
        try {
          await Auth.signIn(values.email, values.password)
          if (rememberMe) {
            localStorage.setItem('email', values.email)
            localStorage.setItem('password', values.password)
          } else {
            localStorage.removeItem('email')
            localStorage.removeItem('password')
          }
          navigate(`/merchant`)
        } catch (e) {
          console.error(e)
          setError('The username or password is incorrect')
        }
        actions.setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => (
        <Form style={{ display: 'flex', flex: 1 }}>
          <Flex flex={1} direction={'column'} justifyContent={'space-between'}>
            <Stack gap={8} justifyContent={'center'} alignItems={'center'}>
              <Img w={'60px'} h={'60px'} src={Logo} />
              <Stack alignItems={'center'}>
                <Text fontSize={'3xl'}>{t('login_page.login.heading_log_in')}</Text>
                <Text color={'#8B8D97'} fontSize={'lg'}>
                  {t('login_page.login.sub_heading_log_in')}
                </Text>
              </Stack>
            </Stack>
            <Stack>
              <Field name='email'>
                {({ field, form }: any) => (
                  <AuthTextField field={field} form={form} type='email' placeholder={t('components.auth_text_field.placeholder_email')} icon={IconMail} />
                )}
              </Field>
              <Field id='password' name='password'>
                {({ field, form }: any) => (
                  <AuthTextField field={field} form={form} type='password' placeholder={t('components.auth_text_field.placeholder_password')} icon={IconLock} />
                )}
              </Field>
              <Text color={'red.500'}>{error}</Text>
            </Stack>
            <Button w={'full'} variant={'solid'} isLoading={isSubmitting} type='submit'>
              {t('components.button.log_in')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
