import { Button, Flex, Heading, HStack, Img, StackDivider, Text, useToast } from '@chakra-ui/react'
import { ErrorBox } from '../../../../components/ErrorBox'
import { Toast } from '../../../../components/PlentyUI/Toast'
import { useDiscountCreation } from '../../../../context/DiscountCreationContext'
import { CategorySearchResult, ProductAndCategorySearchResult, ProductSearchResult, VariantSearchResult } from '../../../../generated/graphql'
import { TableEntry } from '../../../../types/TableEntry'

type SearchResultProps = {
  value: ProductAndCategorySearchResult
  searchTerm: string
  clearInput: () => any
  focusSearchInput: () => any
}

export const SearchResult = (props: SearchResultProps) => {
  const discountCreation = useDiscountCreation()
  const toast = useToast()

  const addCategoryTableEntry = () => {
    const categorySearchResult = props.value as CategorySearchResult

    discountCreation.addTableEntry({
      name: categorySearchResult.name,
      imageUrl: categorySearchResult.imageUrl,
      categoryEntry: {
        categoryKey: categorySearchResult.categoryKey,
        isSelectedForDiscountAppliance: false,
      },
    })

    props.clearInput()
    props.focusSearchInput()

    toast({
      position: 'top',
      render: () => <Toast type='success' label={'Category ' + categorySearchResult.name + ' added'} />,
    })
  }

  const addSingleVariant = (ean: string) => {
    const productSearchResult = props.value as ProductSearchResult

    if (!discountCreation.tableEntries.some((te: TableEntry) => te.productEntry?.productKey === productSearchResult.productKey)) {
      discountCreation.addTableEntry({
        name: productSearchResult.name,
        imageUrl: productSearchResult.imageUrl,
        productEntry: {
          productKey: productSearchResult.productKey,
          category: productSearchResult.category,
          price: productSearchResult.price,
          styleNumber: productSearchResult.styleNumber,
          discount: undefined,
          variantEntries: productSearchResult.variants.map((value: VariantSearchResult) => {
            return {
              ean: value.ean,
              imageUrl: value.imageUrl,
              name: value.name,
              isIncluded: ean === value.ean,
              price: value.price,
              size: value.size,
            }
          }),
        },
      })

      props.clearInput()
      props.focusSearchInput()

      toast({
        position: 'top',
        render: () => <Toast type='success' label={'Variant with EAN ' + ean + ' added'} />,
      })
    } else {
      discountCreation.showVariantByEan(ean)
    }
  }

  const addAllVariants = () => {
    const productSearchResult = props.value as ProductSearchResult

    if (!discountCreation.tableEntries.some((te: TableEntry) => te.productEntry?.productKey === productSearchResult.productKey)) {
      discountCreation.addTableEntry({
        name: productSearchResult.name,
        imageUrl: productSearchResult.imageUrl,
        productEntry: {
          productKey: productSearchResult.productKey,
          category: productSearchResult.category,
          price: productSearchResult.price,
          styleNumber: productSearchResult.styleNumber,
          discount: undefined,
          variantEntries: productSearchResult.variants.map((value: VariantSearchResult) => {
            return {
              ean: value.ean,
              imageUrl: value.imageUrl,
              name: value.name,
              isIncluded: true,
              price: value.price,
              size: value.size,
            }
          }),
        },
      })

      props.clearInput()
      props.focusSearchInput()

      toast({
        position: 'top',
        render: () => <Toast type='success' label={'All variants of product ' + productSearchResult.name + ' added'} />,
      })
    } else {
      discountCreation.showAllVariantsByProductKey(productSearchResult.productKey)
    }
  }

  const Buttons = () => {
    switch (props.value.__typename) {
      case 'CategorySearchResult':
        return (
          <Flex>
            <Button onClick={() => addCategoryTableEntry()} minW={'165px'} variant={'simple'} bgColor={'beigeDark.500'} color={'black'}>
              Select category
            </Button>
          </Flex>
        )
      case 'ProductSearchResult':
        return (
          <Flex gap={3}>
            <Button onClick={() => addAllVariants()} minW={'165px'} variant={'simple'} bgColor={'beigeDark.500'} color={'black'}>
              <Text>Select product </Text>
              &nbsp;
              <Text color={'GrayText'}>(all variants)</Text>
            </Button>
            {(props.value as ProductSearchResult).variants.some((value: VariantSearchResult) => value.ean === props.searchTerm) ? (
              <Button onClick={() => addSingleVariant(props.searchTerm)} minW={'165px'} variant={'simple'} bgColor={'beigeDark.500'} color={'black'}>
                <Text>Select variant</Text>
                &nbsp;
                <Text color={'GrayText'}>({props.searchTerm})</Text>
              </Button>
            ) : null}
          </Flex>
        )
      default:
        return <ErrorBox message='Unknown search result type' />
    }
  }

  return (
    <Flex px={6} py={1} justifyContent={'space-between'} alignItems={'center'} flex={1}>
      <Flex gap={6}>
        <Img
          borderRadius={10}
          w={'50px'}
          height={'50px'}
          objectFit={'cover'}
          style={{ overflowClipMargin: 'unset' }}
          overflow={'hidden'}
          src={props.value.imageUrl}
        ></Img>
        <Flex direction={'column'}>
          <Heading size={'md'}>{props.value.name}</Heading>
          <HStack fontSize={'sm'} color={'#999FAA'} divider={<StackDivider />}>
            {props.value.attributes.map((value: string, index: number) => {
              return <Text key={index}>{value}</Text>
            })}
          </HStack>
        </Flex>
      </Flex>
      <Buttons />
    </Flex>
  )
}
