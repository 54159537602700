import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { ErrorBox } from "../../components/ErrorBox"
import { Step } from "../../components/PlentyUI/wizard/Step"
import { useWizard } from "../../hooks/useWizard"
import { Dispatch, SetStateAction, useState } from "react"
import { CreateBulkDiscountCampaignStep } from "./merchant/components/discounts/bulk/CreateBulkDiscountCampgaignStep"
import { CreateBulkDiscountUploadStep } from "./merchant/components/discounts/bulk/CreateBulkDiscountUploadStep"
import { CreateBulkDiscountUploadingStep } from "./merchant/components/discounts/bulk/CreateBulkDiscountUploadingStep"
import { CreateBulkDiscountConfirmUploadStep } from "./merchant/components/discounts/bulk/CreateBulkDiscountConfirmUploadStep"
import { BulkStepIndicator } from "../../components/PlentyUI/wizard/BulkStepIndicator"
import { ProductEntry } from "../../types/ProductEntry"
import { UploadStatusTernary } from "../../utils/enums/UploadStatusTernary"
import { ProductAndCategorySearchResult } from "../../generated/graphql"

const stepsToParse: Step[] = [
  {
    key: 'campaign',
    label: 'Campaign',
  },
  {
    key: 'upload',
    label: 'Upload',
  },
  {
    key: 'uploading',
    label: 'Uploading',
  },
  {
    key: 'confirm upload',
    label: 'Confirm upload'
  }
]

export const CreateBulkDiscountModal = (props: { isOpen: boolean, setOpen: Dispatch<SetStateAction<boolean>>}) => {
  const wizard = useWizard(stepsToParse)

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadState, setUploadState] = useState<UploadStatusTernary>(UploadStatusTernary.Uploading)
  const [responseError, setResponseError] = useState<string>("")
  const [campaignId, setCampaignId] = useState<string>("")

  const handleFileSelect = (file: File) => {
    setSelectedFile(file)
  }

  const ActiveStepContent = () => {
    switch (wizard.activeStep.key) {
      case 'campaign':
        return <CreateBulkDiscountCampaignStep wizard={wizard} />
      case 'upload':
        return <CreateBulkDiscountUploadStep wizard={wizard}
          onSelect={handleFileSelect}
          file={selectedFile}
          setUploadState={setUploadState}
          setResponseError={setResponseError}
          setCampaignId={setCampaignId} />
      case 'uploading':
        return <CreateBulkDiscountUploadingStep wizard={wizard}
          selectedFile={selectedFile}
          uploadState={uploadState}
          responseError={responseError}
          setCampaignId={setCampaignId} />
      case 'confirm upload':
        return <CreateBulkDiscountConfirmUploadStep wizard={wizard} setOpen={props.setOpen} campaignId={campaignId} setCampaignId={setCampaignId} />
      default:
        return <ErrorBox message={'No content for this step'} />
    }
  }

  return (
    <Modal size={'6xl'} isOpen={props.isOpen} onClose={() => props.setOpen(false)}>
      <ModalOverlay />
      <ModalContent p={0}>
        <ModalHeader position={'relative'} borderBottom={'1px'} borderBottomColor={'beigeLight.500'}>
          <Flex alignItems={'center'} justifyContent={'center'} direction={'column'} gap={3}>
            <Flex mb={3}>
              <BulkStepIndicator wizard={wizard} />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <ActiveStepContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}