import { Button, Flex, Grid, HStack, Icon, Spinner, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { TbAdjustmentsHorizontal } from 'react-icons/tb'
import { SortBy } from './SortBy'
import { PlentyProduct, ProductsListProps } from '../types'
import { ProductCard } from './ProductCard'
import { Theme } from '../../../../ThemeVariables'

const defineSortType = (priceSort: string | undefined) => {
  if (priceSort === 'asc') {
    return 'Lowest price'
  } else if (priceSort === 'desc') {
    return 'Highest price'
  } else {
    return 'Most popular'
  }
}
export const ProductsList = ({
  products,
  showMoreProducts,
  moreProducts,
  onOpen,
  storeKey,
  isLoadingMoreProducts,
  priceSort,
  setPriceSort,
  channelId,
}: ProductsListProps) => {
  const [showFilters, setShowFilters] = useState(false)
  const [sortType, setSortType] = useState(defineSortType(priceSort))

  const handleSortChange = (sortType: string) => {
    switch (sortType) {
      case 'Most popular':
        setPriceSort('none')
        // setSortType('Most popular')
        break
      case 'Lowest price':
        setPriceSort('asc')
        // setSortType('low to high')
        break
      case 'Highest price':
        setPriceSort('desc')
        // setSortType('high to low')
        break

      default:
        break
    }
  }

  return (
    <Flex px={Theme.PaddingOnSides.PLP} direction={'column'} justifyContent={'center'} maxW={'100%'} width={'100%'}>
      <Flex
        my={4}
        alignItems={{ base: 'flex-start', md: 'center' }}
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Text marginBottom={4} fontSize={'md'}>
          <Trans i18nKey='main_app.plp.text_number_of_shops' count={products?.length ?? 0} components={[<b key='0' />, <b key='1' />]} />
        </Text>
        <HStack spacing={4}>
          <SortBy sortType={sortType} setSortType={handleSortChange} />
          {!showFilters && (
            <Button onClick={onOpen} variant='solid'>
              {t('main_app.plp.show_filters')} <Icon ml={2} w={5} h={5} as={TbAdjustmentsHorizontal} />
            </Button>
          )}
        </HStack>
      </Flex>

      <Grid templateColumns={{ base: 'repeat(2, 2fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={'1rem'}>
        {products?.map((product) => (
          <ProductCard key={product.masterVariant.key} product={product} storeKey={storeKey} channelId={channelId} />
        ))}
      </Grid>
      <Flex py={'2.5rem'} justifyContent={'center'}>
        {moreProducts && <Button onClick={() => showMoreProducts()}>{isLoadingMoreProducts ? <Spinner /> : 'Show more'}</Button>}
      </Flex>
    </Flex>
  )
}
