import { MouseEventHandler, ReactNode } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

interface CustomLinkProps {
  to?: any
  children: ReactNode
  dragging?: boolean
  [key: string]: any
  draggable: any
}
// This component is made for using the correct right click.
// It is a temporary component since we have made the wrong use of chakra ui for links and clicks on images.
// We need to use LinkOverlay and LinkBox, but the code and time required to do the correct fix with using those two instead of this component is very time comsuming and we having new design for after 1 of august.
// So for now we using this where we want people to be able to open page in new tab
const CustomLink = ({ to, children, dragging, draggable, ...props }: CustomLinkProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const currentUrl = new URL(location.pathname + location.search, window.location.origin)
  const destinationUrl = new URL(to, window.location.origin)

  const keys = Array.from(currentUrl.searchParams.keys())
  keys.forEach((key) => {
    const value: any = currentUrl.searchParams.get(key)
    destinationUrl.searchParams.set(key, value)
  })

  const toWithQuery = destinationUrl.pathname + destinationUrl.search

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (event.button !== 0 || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey || dragging) {
      event.preventDefault()
      return
    }

    event.preventDefault()
    navigate(toWithQuery)
  }

  return (
    <a href={toWithQuery} draggable={draggable ? 'false' : undefined} onClick={onLinkClick} {...props}>
      {children}
    </a>
  )
}

export default CustomLink
