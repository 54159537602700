import { Box, IconButton } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { TbPlayerPlay } from 'react-icons/tb'

interface VideoProps {
  src: string
  title?: string
}

const Video: React.FC<VideoProps> = ({ src, title }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [playing, setPlaying] = useState(true)
  const [showPlayButton, setShowPlayButton] = useState(false)

  useEffect(() => {
    const videoElement = videoRef.current
    if (videoElement) {
      const handleLoadedMetadata = () => {
        videoElement
          .play()
          .then(() => {
            // Video played
            setPlaying(true)
          })
          .catch((error) => {
            // Auto-play was prevented
            console.error('Auto-play failed:', error)
            setPlaying(false)
            setShowPlayButton(true)
          })
      }

      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata)

      // Cleanup the event listener
      return () => {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
      }
    }
  }, [])

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause()
        setShowPlayButton(true)
      } else {
        videoRef.current.play()
        setShowPlayButton(false)
      }
      setPlaying(!playing)
    }
  }

  const handleVideoEnd = () => {
    setPlaying(false)
    setShowPlayButton(true)
  }

  return (
    <Box width='100%' height='100%' position='relative'>
      <video ref={videoRef} autoPlay playsInline muted loop={true} style={{ width: '100%', height: '100%' }} onClick={togglePlayPause} onEnded={handleVideoEnd}>
        <source src={src} type='video/mp4' />
        {title ? <track kind='captions' /> : null}
        Your browser does not support the video tag.
      </video>
    </Box>
  )
}

export default Video
