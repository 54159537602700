import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { ChakraThemeConstants } from '../ChakraThemeConstants'

const solid = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'white',
  backgroundColor: 'darkGreen.500',
  fontWeight: 'normal',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    '&:not(:active)': {
      backgroundColor: 'darkGreen.500',
    },
    _disabled: {
      backgroundColor: 'darkGreen.400',
    },
  },
  _active: {
    fontWeight: 'normal',
    backgroundColor: 'blackAlpha.50',
  },
})

const simple = defineStyle({
  borderRadius: 'full',
  height: '40px',
  color: 'white',
  backgroundColor: 'darkGreen.500',
  fontWeight: 'normal',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    '&:not(:active)': {
      backgroundColor: 'darkGreen.300',
    },
    _disabled: {
      backgroundColor: 'darkGreen.400',
    },
  },
  _active: {
    fontWeight: 'normal',
    backgroundColor: 'blackAlpha.50',
  },
})

const solidBlackSelect = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'white',
  backgroundColor: 'black.500',
  fontWeight: 'normal',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    backgroundColor: 'black.500',
    _disabled: {
      backgroundColor: 'black.500',
    },
  },
  _active: {
    backgroundColor: 'black.500',
  },
})

const solidGreen = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'black.500',
  backgroundColor: 'mintGreen.500',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    backgroundColor: 'mintGreen.400',
    _disabled: {
      backgroundColor: 'mintGreen.300',
    },
  },
  _active: {
    backgroundColor: 'mintGreen.400',
  },
})

const solidRed = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'white.500',
  backgroundColor: 'red.500',
  px: ChakraThemeConstants.buttonPaddingX,
  fontWeight: 'normal',
  minW: 12,
  _hover: {
    backgroundColor: 'red.400',
    _disabled: {
      backgroundColor: 'red.300',
    },
  },
  _active: {
    backgroundColor: 'red.400',
  },
})

const solidWhite = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'black.500',
  backgroundColor: 'white.500',
  fontWeight: 'normal',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    backgroundColor: 'blackAlpha.50',
    _disabled: {
      backgroundColor: 'blackAlpha.50',
    },
  },
  _active: {
    fontWeight: 'normal',
    backgroundColor: 'blackAlpha.50',
  },
})

const solidGray = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'black.500',
  backgroundColor: 'blackAlpha.100',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    backgroundColor: 'blackAlpha.50',
    _disabled: {
      backgroundColor: 'blackAlpha.50',
    },
  },
  _active: {
    backgroundColor: 'blackAlpha.50',
  },
})

const solidBeigeDark = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'black.500',
  backgroundColor: 'beigeDark.500',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    backgroundColor: 'beigeDark.50',
    _disabled: {
      backgroundColor: 'beigeDark.50',
    },
  },
  _active: {
    backgroundColor: 'beigeDark.50',
  },
})

const outline = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'darkGreen.500',
  backgroundColor: 'darkGreen.200',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  border: '1px solid',
  borderColor: 'darkGreen.500',
  _hover: {
    backgroundColor: 'darkGreen.100',
  },
  _active: {
    backgroundColor: 'darkGreen.100',
  },
})

const outlineWhite = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'darkGreen.500',
  backgroundColor: 'white',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  fontWeight: 'normal',
  border: '1px solid',
  borderColor: 'darkGreen.500',
  _hover: {
    backgroundColor: 'gray.100',
  },
  _active: {
    backgroundColor: 'gray.100',
  },
})

const outlineGrey = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'blackAlpha.500',
  backgroundColor: 'blackAlpha.200',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  border: '1px solid',
  borderColor: 'blackAlpha.500',
  _hover: {
    backgroundColor: 'blackAlpha.100',
  },
  _active: {
    backgroundColor: 'blackAlpha.100',
  },
})

const outlineGreen = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'mintGreen.900',
  backgroundColor: 'mintGreen.200',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  border: '1px solid',
  borderColor: 'mintGreen.500',
  _hover: {
    backgroundColor: 'mintGreen.100',
  },
  _active: {
    backgroundColor: 'mintGreen.100',
  },
})

const outlineRed = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'red.900',
  backgroundColor: 'red.200',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  border: '1px solid',
  borderColor: 'red.500',
  _hover: {
    backgroundColor: 'red.100',
  },
  _active: {
    backgroundColor: 'red.100',
  },
})

const ghost = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'darkGreen.500',
  backgroundColor: 'transparent',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    backgroundColor: 'transparent',
    _disabled: {
      backgroundColor: 'darkGreen.50',
    },
  },
  _active: {
    backgroundColor: 'transparent',
  },
  _focus: {
    boxShadow: 'none',
  },
})

const smallTransparentButtonWithUnderline = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'darkGreen.500',
  backgroundColor: 'transparent',
  px: ChakraThemeConstants.buttonPaddingX,
  minW: 12,
  _hover: {
    textDecoration: 'underline',
    textDecorationThickness: '0.2rem',
    textDecorationColor: 'mintGreen.500',
    textUnderlineOffset: '0.5rem',

    _disabled: {
      backgroundColor: 'darkGreen.50',
    },
  },
  _active: {
    backgroundColor: 'transparent',
  },
  _focus: {
    boxShadow: 'none',
  },
})

const mediumTransparentButtonWithUnderline = defineStyle({
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: 'darkGreen.500',
  backgroundColor: 'transparent',
  _hover: {
    textDecoration: 'underline',
    textDecorationThickness: '0.2rem',
    textDecorationColor: 'mintGreen.500',
    textUnderlineOffset: '0.5rem', // Adjust this value as needed
  },
})

const transparentWithUnderline = defineStyle({
  width: '25vw',
  borderRadius: 'full',
  height: ChakraThemeConstants.componentHeight,
  color: '#ABAFB1',
  backgroundColor: 'transparent',
  minW: 12,
  position: 'relative',
  _hover: {
    backgroundColor: 'transparent',
    _disabled: {
      backgroundColor: 'transparent',
    },
  },
  _focus: {
    boxShadow: 'none',
  },
  _active: {
    backgroundColor: 'transparent',
    textDecoration: 'none',
    _before: {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 2,
      width: '100%',
      height: '4px',
      backgroundColor: 'mintGreen.500',
    },
  },
})

const selectedTransparentWithUnderline = defineStyle({
  ...transparentWithUnderline,
  textDecoration: 'none',
  color: 'black',
  _before: {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 2,
    width: '100%',
    height: '4px',
    backgroundColor: 'mintGreen.500',
  },
})

export const buttonTheme = defineStyleConfig({
  variants: {
    solid,
    solidGreen,
    solidRed,
    solidWhite,
    solidGray,
    solidBeigeDark,
    outline,
    outlineWhite,
    outlineGrey,
    outlineGreen,
    outlineRed,
    ghost,
    solidBlackSelect,
    transparentWithUnderline,
    selectedTransparentWithUnderline,
    simple,
    smallTransparentButtonWithUnderline,
    mediumTransparentButtonWithUnderline,
  },
})
