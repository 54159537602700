import { Flex, VStack, Text, Heading, Stack, UnorderedList, ListItem, Container } from '@chakra-ui/react'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

export const TermsOfUseInformationText = () => {
  return (
    <Container py={"2.5rem"}  maxW={'100rem'}>
      <Stack spacing={14}>
        <Heading>{t('main_app.site_politics.terms_of_use.heading_terms_of_use')}</Heading>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_conditions_for_use_of_website')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_conditions_for_use_of_website.0')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_conditions_for_use_of_website.1')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_conditions_for_use_of_website.2')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_conditions_for_use_of_website.3')}</Text>
            <Text>
              {t('main_app.site_politics.terms_of_use.text_conditions_for_use_of_website.4')}
              <Link style={{ textDecoration: 'underline' }} to='/politics/privacy_policy'>
                {t('main_app.site_politics.terms_of_use.text_conditions_for_use_of_website.5')}
              </Link>{' '}
            </Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_terms_for_sale')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_terms_for_sale.0')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_terms_for_sale.1')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_conclusion_of_contract')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_conclusion_of_contract.0')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_conclusion_of_contract.1')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_conclusion_of_contract.2')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_conclusion_of_contract.3')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_conclusion_of_contract.4')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_prices_taxes_delivery')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_prices_taxes_delivery.0')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_prices_taxes_delivery.1')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_prices_taxes_delivery.2')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_prices_taxes_delivery.3')}</Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.text_prices_taxes_delivery.4')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.text_prices_taxes_delivery.5')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.text_prices_taxes_delivery.6')}</ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.terms_of_use.text_prices_taxes_delivery.7')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_payment')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_payment.0')}</Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.text_payment.1')}</ListItem>
              <Text>{t('main_app.site_politics.terms_of_use.text_payment.2')}</Text>
              <ListItem>
                {t('main_app.site_politics.terms_of_use.text_payment.3')}
                <Text>{t('main_app.site_politics.terms_of_use.text_payment.4')}</Text>
              </ListItem>

              <ListItem>
                {t('main_app.site_politics.terms_of_use.text_payment.5')}
                <Text>{t('main_app.site_politics.terms_of_use.text_payment.6')}</Text>
              </ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.terms_of_use.text_payment.7')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_delivery')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_delivery.0')}</Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.text_delivery.1')}</ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.terms_of_use.text_delivery.2')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_right_of_withdrawal')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_right_of_withdrawal')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_cooling_off_period')}</Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_cooling_off_period.0')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_cooling_off_period.1')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_cooling_off_period.2')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_cooling_off_period.3')}</ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.terms_of_use.text_withdraw_from_the_content')} </Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_withdraw_from_the_content.0')}</ListItem>
              <ListItem>
                {t('main_app.site_politics.terms_of_use.list_withdraw_from_the_content.1')}{' '}
                <Link to={'return/login'}>{t('main_app.site_politics.terms_of_use.list_withdraw_from_the_content.2')}</Link>{' '}
              </ListItem>
              <ListItem>
                {t('main_app.site_politics.terms_of_use.list_withdraw_from_the_content.3')}
                <a
                  style={{ textDecoration: 'underline' }}
                  href={process.env.PUBLIC_URL + '/StandardCancellationForm/Standardfortrydelsesformular(Plenty&).pdf'}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('main_app.site_politics.terms_of_use.list_withdraw_from_the_content.4')}
                </a>
                <br />
                {t('main_app.site_politics.terms_of_use.list_withdraw_from_the_content.5')}
              </ListItem>

              <ListItem>{t('main_app.site_politics.terms_of_use.list_withdraw_from_the_content.6')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_withdraw_from_the_content.7')}</ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.terms_of_use.text_repayment')}</Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_repayment.0')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_repayment.1')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_repayment.2')}</ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.terms_of_use.text_return_of_goods')}</Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_return_of_goods.0')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_return_of_goods.1')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_return_of_goods.2')} </ListItem>
              <ListItem>
                {t('main_app.site_politics.terms_of_use.list_return_of_goods.3')}
                <Link to={'/customer_service/return_and_refunds'} style={{ textDecoration: 'underline' }}>
                  {t('main_app.site_politics.terms_of_use.list_return_of_goods.4')}
                </Link>{' '}
              </ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.terms_of_use.text_used_impaired_goods')}</Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_used_impaired_goods.0')}</ListItem>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_used_impaired_goods.1')}</ListItem>
            </UnorderedList>
            <Text>{t('main_app.site_politics.terms_of_use.text_specially_made_goods')}</Text>
            <UnorderedList style={{ marginInlineStart: 16 }}>
              <ListItem>{t('main_app.site_politics.terms_of_use.list_specially_made_goods')}</ListItem>
            </UnorderedList>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_notification_case_of_non_conformormity_of_goods')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_notification_case_of_non_conformormity_of_goods.0')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_notification_case_of_non_conformormity_of_goods.1')}</Text>
            <Text>{t('main_app.site_politics.terms_of_use.text_notification_case_of_non_conformormity_of_goods.2')}</Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_customer_complaints')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_customer_complaints.0')}</Text>
            <Text>
              {t('main_app.site_politics.terms_of_use.text_customer_complaints.1')}
              <a href={'https://naevneneshus.dk/'} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline' }}>
                {t('main_app.site_politics.terms_of_use.text_customer_complaints.2')}
              </a>
              {t('main_app.site_politics.terms_of_use.text_customer_complaints.3')}
              <a
                href={'https://kpo.naevneneshus.dk/Public/Home/ChooseLoginProvider?returnUrl=https://kpo.naevneneshus.dk/External'}
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: 'underline' }}
              >
                {t('main_app.site_politics.terms_of_use.text_customer_complaints.4')}
              </a>
            </Text>
            <Text>
              {t('main_app.site_politics.terms_of_use.text_customer_complaints.5')}
              <a
                href={'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'}
                style={{ textDecoration: 'underline' }}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('main_app.site_politics.terms_of_use.text_customer_complaints.6')}
              </a>
            </Text>
          </VStack>
        </Flex>
        <Flex>
          <VStack alignItems={'flex-start'} spacing={4}>
            <Heading>{t('main_app.site_politics.terms_of_use.heading_liability')}</Heading>
            <Text>{t('main_app.site_politics.terms_of_use.text_liability')}</Text>
          </VStack>
        </Flex>
      </Stack>
    </Container>
  )
}
