import { Alert, AlertDescription, AlertIcon, AlertTitle, Container, Flex, Heading, Img, Text } from '@chakra-ui/react'
import moment from 'moment'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Logo from '../../../assets/svg/Plenty&Svg/Plenty_Logo_Black.svg'
import { PageSpinner } from '../../../components/PageSpinner'
import { MainRole, useGetMerchantApplicationQuery } from '../../../generated/graphql'
import withAuth from '../../../utils/WithAuth'

const OnboardingConfirmationPage = () => {
  const params = useParams()
  const navigate = useNavigate()

  const merchantApplication = useGetMerchantApplicationQuery({
    variables: {
      onboardingNumber: params.onboardingNumber,
    },
  })

  useEffect(() => {
    if (merchantApplication.error) {
      navigate('/')
    }
  })

  if (merchantApplication.loading) return <PageSpinner />

  return (
    <Container py={10}>
      <Flex direction={'column'} gap={14}>
        <Flex direction={'column'} justifyContent={'center'} alignItems={'center'} gap={2}>
          <Img w={'50%'} src={Logo} />
          <Heading size={'md'}>Onboarding application form</Heading>
        </Flex>
        <Alert status='info' variant='subtle' flexDirection='column' alignItems='center' justifyContent='center' textAlign='center' height='200px'>
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Application submitted on {moment(merchantApplication.data?.getMerchantApplication?.createdAt).format('LLL')}
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Updates regarding your application will be sent to{' '}
            <b>{merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.accountOwnerEmail}</b>
          </AlertDescription>
        </Alert>

        <Flex direction={'column'} gap={10}>
          <Heading size={'md'}>Your provided information</Heading>
          <Flex direction={'column'} gap={2}>
            <Heading size={'sm'}>Store information</Heading>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>Store name</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.storeName}
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>Store number</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.storeNumber}
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>Street & Number</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.streetAndNumber}
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>Postal code</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.postalCode}
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>City</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.city}
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>Country</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.countryName}
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>Store email</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.storeEmail}
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>Store phone</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.storePhone}
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
              <Text fontSize={'sm'}>Account owner email</Text>
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {merchantApplication.data?.getMerchantApplication?.onboardingApplication.storeInformation.accountOwnerEmail}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}

export default withAuth(OnboardingConfirmationPage, [MainRole.RoleAdmin, MainRole.RoleGuest])
