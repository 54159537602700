import { Button, Divider, Flex, IconButton, Radio, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import moment from 'moment'
import { useState } from 'react'
import { Maybe, OpeningTime, ParcelResponse } from '../../../../generated/graphql'
import { Theme } from '../../../../ThemeVariables'
import { metersToKilometersFormatted, numberToDayString } from '../../../../utils/LogisticsUtil'
import { useReturn } from '../../../../context/OrderReturnContext'

type ReturnParcelShopCardProps = {
  parcelShopResponse: ParcelResponse
}

export const ReturnParcelShopCard = (props: ReturnParcelShopCardProps) => {
  const { setParcelResponse, advanceStep, setDeliveryOption } = useReturn()
  const [showOpeningTimes, setShowOpeningTimes] = useState(false)

  return (
    <Flex
      onClick={() => setShowOpeningTimes(!showOpeningTimes)}
      cursor={'pointer'}
      border={'2px'}
      borderColor={'transparent'}
      borderRadius={Theme.spacing.borderRadius}
      bgColor={'white'}
      p={Theme.spacing.padding.mobile}
      gap={4}
      justifyContent={'space-between'}
      direction={'column'}
      w={'300px'}
    >
      <Divider borderColor={'beigeDark.500'} />

      <Flex direction={'column'} justifyContent={'space-between'}>
        <Flex gap={4}>
          <Flex direction={'column'}>
            <Text fontWeight={'bold'}>
              {props.parcelShopResponse.parcelShop?.name} - {metersToKilometersFormatted(props.parcelShopResponse.distance || 0, 1)}
            </Text>
            <Text fontSize={'sm'}>
              {props.parcelShopResponse.parcelShop?.addressLine1 || t('main_app.return.components.return_by_parcel_shop.text_unknown_address')}{' '}
            </Text>
          </Flex>
        </Flex>

        <Flex direction={'column'}>
          {props.parcelShopResponse.parcelShop
            ?.openingTimes!.filter((e: Maybe<OpeningTime>) => parseInt(e?.day || '0') === moment().day())
            .map((value: Maybe<OpeningTime>, index: number) => {
              const currentTime = moment()
              const openingTime = moment(value?.from, 'HH:mm')
              const closingTime = moment(value?.to, 'HH:mm')

              if (currentTime.isBetween(openingTime, closingTime)) {
                return (
                  <Flex key={index}>
                    <Flex gap={1} minW={'100px'}>
                      <Text color={'mintGreen.500'}>{t('main_app.return.components.return_by_parcel_shop.text_open')}</Text>
                      <Text> {' - ' + t('main_app.return.components.return_by_parcel_shop.text_closes') + ' ' + value?.to}</Text>
                    </Flex>
                  </Flex>
                )
              } else {
                return (
                  <Flex key={index}>
                    <Text color={'red.500'}>{t('main_app.return.components.return_by_parcel_shop.text_closed')}</Text>
                  </Flex>
                )
              }
            })}
        </Flex>
      </Flex>
      <Flex direction={'column'} gap={1}>
        {showOpeningTimes && (
          <>
            <Flex fontWeight={'bold'}>{t('main_app.return.components.return_by_parcel_shop.text_opening_hours')}</Flex>
            {Array.from(Array(8).keys())
              .slice(1)
              .map((day: number) => {
                const openingTime = props.parcelShopResponse.parcelShop?.openingTimes?.find((e: Maybe<OpeningTime>) => parseInt(e?.day || '0') === day)
                return (
                  <Flex key={day} gap={4}>
                    <Text minW={'100px'}>{numberToDayString(day)}:</Text>
                    {openingTime ? (
                      <Text>{openingTime?.from + ' - ' + openingTime?.to}</Text>
                    ) : (
                      <Text>{t('main_app.return.components.return_by_parcel_shop.text_closed')}</Text>
                    )}
                  </Flex>
                )
              })}
            <Button
              mt={3}
              bgColor={'blackAlpha.900'}
              onClick={() => {
                setParcelResponse(props.parcelShopResponse)
                setDeliveryOption('ParcelShop')
                advanceStep()
              }}
            >
              {t('components.button.choose')}
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  )
}
