import { MainPageResponsive } from '../../../components/layout/newResponsiveHeaderAndFooter/MainPageResponsive'
import { StoreLocationList } from '../../../components/map/StoreLocationList'
import { MainRole } from '../../../generated/graphql'
import withAuth from '../../../utils/WithAuth'

export const StoreLocator = () => {
  return (
    <MainPageResponsive loading={false}>
      <StoreLocationList />
    </MainPageResponsive>
  )
}
export default withAuth(StoreLocator, [MainRole.RoleGuest])
