import { Flex, Container, Text, Heading, VStack, Stack } from '@chakra-ui/react'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
declare global {
  interface Window {
    CookieInformation: any
  }
}
export const CookiePoliticInformationText = () => {
  return (
    <>
      <Container py={"2.5rem"}  maxW={'100rem'}>
        <Stack spacing={14}>
          <Heading>{t('main_app.site_politics.cookie.heading_cookie_header')}</Heading>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'22px'}>{t('main_app.site_politics.cookie.heading_what_is_a_cookie')}</Heading>
              <Text>{t('main_app.site_politics.cookie.text_what_is_a_cookie.0')}</Text>
              <Text>{t('main_app.site_politics.cookie.text_what_is_a_cookie.1')}</Text>
              <Text>
                {t('main_app.site_politics.cookie.text_what_is_a_cookie.2')}
                <Link style={{ textDecoration: 'underline' }} to='/politics/privacy_policy'>
                  {t('main_app.site_politics.cookie.text_what_is_a_cookie.3')}
                </Link>
              </Text>
              <Text>{t('main_app.site_politics.cookie.text_what_is_a_cookie.4')}</Text>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'22px'}>{t('main_app.site_politics.cookie.heading_why_we_use_cookies')}</Heading>
              <Text>{t('main_app.site_politics.cookie.text_why_we_use_cookies')}</Text>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'18px'}>{t('main_app.site_politics.cookie.heading_cookie_consent')}</Heading>
              <Text>{t('main_app.site_politics.cookie.text_cookie_consent.0')}</Text>
              <Text>{t('main_app.site_politics.cookie.text_cookie_consent.1')}</Text>
              <Text>{t('main_app.site_politics.cookie.text_cookie_consent.2')}</Text>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'18px'}>{t('main_app.site_politics.cookie.heading_necessary_cookies')}</Heading>
              <Text>{t('main_app.site_politics.cookie.text_necessary_cookies')}</Text>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'18px'}>{t('main_app.site_politics.cookie.header_functional_cookies')} </Heading>
              <Text>{t('main_app.site_politics.cookie.text_functional_cookies')}</Text>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'18px'}>{t('main_app.site_politics.cookie.header_analytical_cookies')}</Heading>
              <Text>{t('main_app.site_politics.cookie.text_analytical_cookies.0')}</Text>
              <Text>{t('main_app.site_politics.cookie.text_analytical_cookies.1')}</Text>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'18px'}>{t('main_app.site_politics.cookie.header_marketing_cookies')}</Heading>
              <Text>{t('main_app.site_politics.cookie.text_marketing_cookies.0')}</Text>
              <Text>{t('main_app.site_politics.cookie.text_marketing_cookies.1')}</Text>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'22px'}>{t('main_app.site_politics.cookie.header_how_to_delete_cookies')}</Heading>
              <Text>{t('main_app.site_politics.cookie.text_how_to_delete_cookies')}</Text>
            </VStack>
          </Flex>

          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <a
                style={{ textDecoration: 'underline' }}
                href={'https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Internet Explore
              </a>
              <a
                style={{ textDecoration: 'underline' }}
                href={'https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Microsoft edge
              </a>
              <a
                style={{ textDecoration: 'underline' }}
                href={
                  'https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?esab=a&s=cookies&r=3&as=s&redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US'
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                Mozilla Firefox
              </a>
              <a
                style={{ textDecoration: 'underline' }}
                href={'https://support.google.com/chrome/answer/2392709?hl=en'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Google Chrome
              </a>
              <a style={{ textDecoration: 'underline' }} href={'https://help.opera.com/en/latest/web-preferences/'} target='_blank' rel='noopener noreferrer'>
                Opera
              </a>
              <a style={{ textDecoration: 'underline' }} href={'https://support.apple.com/en-us/HT201265'} target='_blank' rel='noopener noreferrer'>
                Safari
              </a>
              <a
                style={{ textDecoration: 'underline' }}
                href={'https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Flash Cookies
              </a>
              <a style={{ textDecoration: 'underline' }} href={'https://support.apple.com/en-us/HT201265'} target='_blank' rel='noopener noreferrer'>
                Apple
              </a>
              <a
                style={{ textDecoration: 'underline' }}
                href={'https://turbofuture.com/cell-phones/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Android
              </a>
              <a
                style={{ textDecoration: 'underline' }}
                href={'https://support.microsoft.com/en-us/windows/windows-phone-7-3ebc303c-59c0-d367-3995-f258b184fabb'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Windows 7
              </a>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'22px'}>{t('main_app.site_politics.cookie.header_change_of_consent')}</Heading>
              <Text>{t('main_app.site_politics.cookie.text_change_of_consent.0')}</Text>
              <Text>
                {t('main_app.site_politics.cookie.text_change_of_consent.1')}
                <a href='javascript:CookieConsent.renew()' style={{ textDecoration: 'underline' }}>
                  {' '}
                  {t('main_app.site_politics.cookie.text_change_of_consent.2')}
                </a>
              </Text>
              <Text>{t('main_app.site_politics.cookie.text_change_of_consent.3')}</Text>
            </VStack>
          </Flex>
          <Flex>
            <VStack alignItems={'flex-start'} spacing={4}>
              <Heading fontSize={'22px'}>{t('main_app.site_politics.cookie.header_any_questions')}</Heading>
              <Text>
                {t('main_app.site_politics.cookie.text_any_questions.0')}
                <a style={{ textDecoration: 'underline' }} href={'https://cookieinformation.com/'} target='_blank' rel='noopener noreferrer'>
                  {t('main_app.site_politics.cookie.text_any_questions.1')}
                </a>
              </Text>
            </VStack>
          </Flex>
        </Stack>
      </Container>
    </>
  )
}
