import AdyenCheckout from '@adyen/adyen-web'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageSpinner } from '../../../components/PageSpinner'
import { useQueryParam } from '../../../hooks/useQueryParam'

export const CheckoutRedirect = () => {
  const navigate = useNavigate()
  const params = useQueryParam()

  const handleCheckout = async () => {
    const configuration = {
      environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      session: {
        id: params.get('sessionId'),
      },
      onPaymentCompleted: (result: any) => {
        const orderNumber = localStorage.getItem('tempOrderNumber')
        switch (result.resultCode) {
          case 'Authorised':
            navigate('/order/' + orderNumber)
            break
          case 'Cancelled':
            navigate('/order/' + orderNumber + '?err=' + 'cancelled')
            break
          case 'Error':
            navigate('/order/' + orderNumber + '?err=' + 'failure')
            break
          case 'Pending':
            navigate('/order/' + orderNumber + '?err=' + 'pending')
            break
          case 'Refused':
            navigate('/order/' + orderNumber + '?err=' + 'refused')
            break
        }
      },
      onError: (error: any, component: any) => {
        console.log(error.name)
      },
    }
    const checkout = await AdyenCheckout(configuration)
    checkout.submitDetails({ details: { redirectResult: params.get('redirectResult') } })
  }

  useEffect(() => {
    handleCheckout()
  }, [params])

  return <PageSpinner />
}
