import { Box, Button, Flex, Text } from '@chakra-ui/react'
import i18n from '../../../../I18n/i18n'
import { CategorySelectorProps } from '../types'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'

export const CategorySelector = ({ currentCategories, handleSubCategoryClick }: CategorySelectorProps) => {
  const selectedLanguage = localStorage.getItem('languageSelected') || i18n.language
  const langKey = selectedLanguage === 'DK' ? 'da-DK' : 'en'
  const filteredSubCategories = currentCategories?.subCategories?.filter((subCategory) => subCategory.name[langKey])

  // Function to format the category name
  const formatCategoryName = (name: any) => {
    return name.replace(/(\d+)\s+(\d+)/, '$1-$2')
  }

  return (
    <Flex direction={'column'} alignItems={'center'}>
      {currentCategories?.category.name[langKey] ? (
        <>
          <Text my={2} fontSize={{ base: '36px', md: '56px' }}>
            {formatCategoryName(currentCategories?.category.name[langKey])}
          </Text>

          <Box w='100%' textAlign='center' whiteSpace={{ base: 'nowrap', sm: 'normal' }} overflowX='auto' overflowY='hidden'>
            {filteredSubCategories?.map((subCategory) => {
              if (subCategory.productCount < 5) return null
              return (
                <ChakraLink key={subCategory.id} as={ReactRouterLink} to={handleSubCategoryClick(subCategory)}>
                  <Button fontSize='lg' fontWeight='normal' px={3} variant={'smallTransparentButtonWithUnderline'}>
                    {formatCategoryName(subCategory.name[langKey])}
                  </Button>
                </ChakraLink>
              )
            })}
          </Box>
        </>
      ) : null}
    </Flex>
  )
}
