import { Card, CardBody, CardHeader, Flex, Heading, Icon, IconButton, Text } from '@chakra-ui/react'
import { IconClockPause, IconX } from '@tabler/icons-react'
import { useNavigate, useParams } from 'react-router-dom'
import { PartnerPortalPage } from '../../../../components/layout/partnerPortal/merchant/PartnerPortalPage'
import { useUser } from '../../../../context/Auth'
import { MainRole, useGetProductByChannelKeyAndProductIdQuery } from '../../../../generated/graphql'
import withAuth from '../../../../utils/WithAuth'

const MerchantProductPage = () => {
  const navigate = useNavigate()
  const param = useParams()
  const user = useUser()

  const productQuery = useGetProductByChannelKeyAndProductIdQuery({
    variables: {
      channelKey: user.distributionChannelKey,
      productId: param.productId,
    },
  })

  const product = productQuery.data?.getProductByChannelKeyAndProductId

  if (product == undefined) return <Text>Loading...</Text>

  return (
    <PartnerPortalPage pageHeaderLabel='JXANDREA T-Shirt' back={() => navigate('/merchant/products')} >
      <Card>
        <CardBody>
          <Flex direction={'row'} justifyContent={'space-between'}>
            {/* <Flex direction={'column'}>
              <Heading fontWeight={'normal'} size={'md'}>Brand</Heading>
              <Heading fontWeight={'normal'}>Product name</Heading>
            </Flex>
            <Flex>
              <IconButton variant={'ghost'} aria-label='go back' icon={<Icon h={6} w={6} as={IconX} />} />
            </Flex> */}
          </Flex>
        </CardBody>
      </Card>
    </PartnerPortalPage>
  )
}

export default withAuth(MerchantProductPage, [MainRole.RoleAdmin])
