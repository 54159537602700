import { Flex, Text } from '@chakra-ui/react'
import { centPriceToPrice, moneyFormat } from '../../../../utils/MoneyUtil'
import { getLowestCentAmountFromPrice, getPriceByChannel } from '../../../../utils/PDPUtil'
import { PlentyVariant, ProductVariantPrice } from '../../PLP/types'

type VariantPriceProps = {
  variant: PlentyVariant
  channelId: string
  normalFontWeight?: boolean
  livePriceFontSize?: string
  oldPriceFontSize?: string
  customDiscountedPrice?: number
  alwaysShowNonDiscountedprice?: boolean
}

export const VariantPrice = (props: VariantPriceProps) => {
  const price: ProductVariantPrice | null = getPriceByChannel(props.variant, props.channelId)

  if (price == null) {
    return <Text fontStyle={'italic'}>No price found</Text>
  }

  const lowestCentAmount = getLowestCentAmountFromPrice(price)
  const centPrice = centPriceToPrice(lowestCentAmount, 2)
  const absoluteCentPrice = Math.abs(centPrice)
  const priceFormatted = moneyFormat(absoluteCentPrice, 'DKK', 'da-DK')

  const discount = props.customDiscountedPrice ? true :  price.discounted
 
  if (discount && !props.alwaysShowNonDiscountedprice) {
    return (
      <Flex gap={2} alignItems={'center'}>
        <Text fontSize={props.livePriceFontSize ? props.livePriceFontSize :'xl'} fontWeight={props.normalFontWeight ? 'normal' : 'bold'} color={'red.600'}>
          {props.customDiscountedPrice ? moneyFormat(props.customDiscountedPrice, 'DKK', 'da-DK') : priceFormatted}
        </Text>
        <Text fontSize={props.oldPriceFontSize ? props.oldPriceFontSize :'md'} decoration={'line-through'} color={'gray.500'}>
          {moneyFormat(Math.abs(centPriceToPrice(price.value.centAmount, price.value.fractionDigits)), 'DKK', 'da-DK')}
        </Text>
      </Flex>
    )
  } else {
    return (
      <Text fontSize={props.livePriceFontSize ? props.livePriceFontSize :'xl'} fontWeight={props.normalFontWeight ? 'normal' : 'bold'} color={'darkGreen.500'}>
        {priceFormatted}
      </Text>
    )
  }
}
