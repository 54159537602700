import { Flex, Img, Input, Text, Button } from '@chakra-ui/react'

import { t } from 'i18next'
import { useReturn } from '../../../../context/OrderReturnContext'
import { centPriceToPrice } from '../../../../utils/MoneyUtil'
import { ReturnItemQuantity } from '../../../../types/ReturnItemQuantity'

type ReturnOrderItemCardFinalProps = {
  returnItemQuantity: ReturnItemQuantity
  itemBroughtFrom: any
}
export const ReturnOrderItemCardFinal = (props: ReturnOrderItemCardFinalProps) => {
  const { order } = useReturn()

  const initialWidth = window.innerWidth
  const isLargerThan768 = initialWidth >= 768

  return (
    <Flex direction={'column'}>
      <Flex gap={4} alignItems='center'>
        <Flex maxW={isLargerThan768 ? '150px' : '75px'} position='relative'>
          <Img
            borderRadius={'14px'}
            src={
              props.returnItemQuantity.orderLineItem.imageUrl ||
              'https://images.jackjones.com/12212256/3910070/003/jackjones-uldblandingjakke-graa.jpg?v=f81f8a04c6789b46cdf14ba20125f606&width=1920&quality=90'
            }
          />
          <Flex
            borderRadius={'full'}
            bgColor={'mintGreen.500'}
            position={'absolute'}
            top={-3}
            right={-3}
            w={6}
            h={6}
            alignItems='center'
            justifyContent='center'
          >
            <Text fontSize='md'>{props.returnItemQuantity.quantity}</Text>
          </Flex>
        </Flex>

        <Flex direction={'column'} gap={4}>
          <Flex direction='column'>
            <Flex fontWeight={'bold'} direction={'column'}>
              {t('main_app.return.components.order_item_card.text_product_name')}

              <Text>{props.returnItemQuantity.orderLineItem.price ? centPriceToPrice(props.returnItemQuantity.orderLineItem.price, 2) + ' ' + 'DKK' : ''}</Text>
            </Flex>
            <Text>{props.returnItemQuantity.orderLineItem.name}</Text>
            <Text>Color: {props.returnItemQuantity.orderLineItem.color}</Text>
            <Text>Size: {props.returnItemQuantity.orderLineItem.size}</Text>
            <Text>Quantity: {props.returnItemQuantity.orderLineItem.quantity}</Text>
            <Text>
              {' '}
              {t('main_app.return.components.order_item_card.text_store')}: {props.itemBroughtFrom}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
