import { Text } from '@chakra-ui/react'
import { t } from 'i18next'

export const Capitalize = (value?: string) => {
  if (value) {
    return value[0].toUpperCase() + value.slice(1)
  } else {
    return ''
  }
}

export const normalizeGender = (value: string) => {
  switch (value) {
    case 'Boy':
      return 'Men'
    case 'Girl':
      return 'Women'
    default:
      return value
  }
}

export const findStoreAboutText = (storeKey: string) => {
  switch (storeKey) {
    case 'bestseller_romerhus':
      return <Text fontSize={'lg'}>{t('components.store_text.bestseller_romerhus')}</Text>

    case 'selected_ryesgade':
      return <Text fontSize={'lg'}>{t('components.store_text.selected_ryesgade')}</Text>
    case 'selected_bruuns':
      return <Text fontSize={'lg'}>{t('components.store_text.selected_bruuns')}</Text>
    case 'veromoda_bruuns':
      return <Text fontSize={'lg'}>{t('components.store_text.vero_moda')}</Text>
    case 'nameit_bruuns':
      return <Text fontSize={'lg'}>{t('components.store_text.name_it_bruuns')}</Text>

    case 'nameit_storcenternord':
      return <Text fontSize={'lg'}>{t('components.store_text.name_it_nord')}</Text>
    default:
      return <Text fontSize={'lg'}>{t('components.store_text.bestseller_romerhus')}</Text>
  }
}
// delete this and use the channels key endpoint instead.
export const findStoreAddress = (storeName: string) => {
  switch (storeName) {
    case 'veromoda_bruuns':
      return 'M. P. Bruuns Gade 5, 8000 Aarhus'
    case 'bestseller_romerhus':
      return 'Sankt Clemens Torv 8, 8000 Aarhus'
    case 'selected_ryesgade':
      return 'Ryesgade 9, 8000 Aarhus'
    case 'selected_bruuns':
      return 'M. P. Bruuns Gade 5, 8000 Aarhus'
    case 'nameit_bruuns':
      return 'M. P. Bruuns Gade 5, 8000 Aarhus'
    case 'nameit_storcenternord':
      return 'Findlandsgade 17, 8000 Aarhus'
    default:
      return 'No adress is found'
  }
}
